//
// ProjectIcon
//
// Show projects' icon or initials

import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Bar } from '../components'
import { Transform } from '../stores/imageservers/utils'

export const ProjectIcon = observer(
    React.forwardRef(function ProjectIcon({ project, selected, ...other }, ref) {
        const { ccimageserver } = useStore()

        const [iconSrc, setIconSrc] = useState(null)
        useEffect(() => {
            let isMounted = true
            let controller = new AbortController()
            async function asyncEffect() {
                setIconSrc(null)
                let transform = new Transform('fill', 160, 160)
                const transformurl = ccimageserver.transformProjectIconUrl(
                    project.icon,
                    transform
                )
                const imageurl = await ccimageserver.transformedProjectIconUrl(
                    transformurl,
                    { signal: controller.signal }
                )
                if (isMounted) {
                    setIconSrc(imageurl)
                }
            }
            asyncEffect()
            return () => {
                controller.abort('Unmounted')
                isMounted = false
                return isMounted
            }
        }, [project.icon, ccimageserver])

        let className = 'projecticon'
        if (selected) {
            className += ' cc-selected'
        }

        return (
            <Bar raised className={className} {...other} ref={ref}>
                {iconSrc ? (
                    <img alt={project.initials} title={project.name} src={iconSrc} />
                ) : (
                    <div title={project.name}>{project.initials}</div>
                )}
            </Bar>
        )
    })
)

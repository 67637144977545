//
// RecordChannelsPanel
//
// List of available channels for a record, plus manipulation of channels per record

import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'
import { keyboard } from '../utils/keyboard'

import { ChannelsMenu } from '../menus'
import { useMousePopover } from '../hooks/useMousePopover'
import { Header, Property, Text, Toggle } from '../components'

const Channel = observer(function Channel({
    record,
    channelkey,
    is_selected,
    onSelectionClick,
    onToggleChange,
    onContextMenu,
}) {
    const { project } = useStore()

    const channel = record.channels.includes(channelkey)
    const channelname = project.channels.get(channelkey)

    let className = 'cc-packed cc-toggle-label'
    if (is_selected) {
        className += ' cc-selected'
    }

    return (
        <Property
            className={className}
            label={<Toggle onChange={onToggleChange} value={channel === true} />}
            onClick={onSelectionClick}
            onContextMenu={onContextMenu}
        >
            <Text>{channelname}</Text>
        </Property>
    )
})

export const RecordChannelsPanel = observer(function RecordChannelsPanel({ record }) {
    const { app, project } = useStore()

    // ContextMenus
    const ChannelsContextMenu = useMousePopover(ChannelsMenu)

    let initialchannelselection = {}
    project.channels.forEach((value, key) => {
        if (key !== '__all__') initialchannelselection[key] = false
    })
    const [channelselection, setChannelselection] = useState(initialchannelselection)
    const [lastselectedchannel, setLastselectedchannel] = useState(null)
    const channelkeys = Object.keys(channelselection).filter(
        key => project.channels.has(key) && channelselection[key]
    )

    useEffect(() => {
        // check if there's any new items that aren't in the selection yet
        // this means a channel is created or deleted
        let validselectedchannels = {}
        project.channels.forEach((value, key) => {
            validselectedchannels[key] = !!channelselection[key]
        })
        if (
            Object.keys(validselectedchannels).length !==
            Object.keys(channelselection).length
        ) {
            setChannelselection(validselectedchannels)
        }
    }, [project.channels, project.orderedchannels, channelselection])

    const Channels = project.orderedchannels.map(key => {
        if (key === '__all__') return null
        if (!project.channels.has(key)) return null

        const is_selected = channelselection[key]

        const onSelectionClick = e => {
            // supports multiple selections
            // single click sets selection to single item (single click when it is the only item: deselect)
            // command-click (or ctrl-click) toggles selection for that item item
            // shift-click selects range from last item to new item
            // command-click toggles item in selection
            if (keyboard.testCommand(e)) {
                let newselectedchannels = { ...channelselection }
                newselectedchannels[key] = !channelselection[key]
                setChannelselection(newselectedchannels)
            } else if (keyboard.testShift(e) && lastselectedchannel !== null) {
                let newselectedchannels = { ...channelselection }
                let startenabling = false
                project.orderedchannels.forEach(testkey => {
                    if (
                        !startenabling &&
                        (testkey === key || testkey === lastselectedchannel)
                    ) {
                        startenabling = true
                    } else if (
                        startenabling &&
                        (testkey === key || testkey === lastselectedchannel)
                    ) {
                        newselectedchannels[testkey] = true
                        startenabling = false
                    }
                    if (startenabling) newselectedchannels[testkey] = true
                })
                setChannelselection(newselectedchannels)
            } else {
                if (channelkeys.length === 1 && channelkeys.includes(key)) {
                    setChannelselection({})
                } else {
                    let newselectedchannels = { ...initialchannelselection }
                    newselectedchannels[key] = true
                    setChannelselection(newselectedchannels)
                }
            }
            setLastselectedchannel(key)
        }

        // toggle on not-selected channel: apply toggle-value to that channel only
        // toggle on selected channel: apply toggle-value to all channels in selection
        const onToggleChange = new_value => {
            if (is_selected) {
                record.setChannels(channelkeys, new_value)
            } else {
                record.setChannels([key], new_value)
            }
        }

        return (
            <Channel
                key={key}
                record={record}
                channelkey={key}
                is_selected={is_selected}
                onSelectionClick={onSelectionClick}
                onToggleChange={onToggleChange}
                onContextMenu={e => {
                    if (!channelkeys.includes(key)) {
                        let newselectedchannels = { ...initialchannelselection }
                        newselectedchannels[key] = true
                        setChannelselection(newselectedchannels)
                    }
                    ChannelsContextMenu.onShow(e)
                }}
            />
        )
    })

    const onCopyFromParent = () => {
        if (record) {
            record.copyChannelsFromParent(channelkeys)
        }
    }

    const onCopyDown = () => {
        if (record) {
            record.copyChannelsDown(channelkeys)
        }
    }

    const onCopyUp = () => {
        if (record) {
            record.copyChannelsUp(channelkeys)
        }
    }

    const onChannelAction = action => {
        const actions = {
            copy_from_parent: onCopyFromParent,
            copy_down: onCopyDown,
            copy_enabled_up: onCopyUp,
        }
        if (!(action in actions)) {
            console.log(`onChannelAction: unhandled action '${action}'`)
            return
        }
        ChannelsContextMenu.hide()
        actions[action]()
    }

    return (
        <div className="panel channels-panel">
            <ChannelsContextMenu.Panel
                selectedchannelkeys={channelkeys}
                onAction={onChannelAction}
            />
            <Header>{app.text('Channels')}</Header>
            {Channels}
        </div>
    )
})

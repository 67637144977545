//
// RecordInspectorExtensions
//
// An inspector for the extended definition of a PIM record

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../stores'
import { useMousePopover } from '../../../hooks/useMousePopover'

import { Spacer } from '../../../appview'
import {
    Header,
    Icon,
    Menu,
    MenuItem,
    TreeItem,
    Item,
    usePopoverContext,
} from '../../../components'
import {
    RecordDefinitionAddClassPanel,
    RecordDefinitionAddFieldPanel,
} from '../../../panels'
import { maxChars } from '../../../utils/text'

import { DragDropDefinitionClassTreeItem } from '../../../dragdrop/DragDropDefinitionClassTreeItem'
import { DragDropDefinitionFieldItem } from '../../../dragdrop/DragDropDefinitionFieldItem'

import { RecordInspectorFieldlists } from './RecordInspectorFieldlists'

const DefinitionClassMenu = observer(function DefinitionClassMenu({ onAction }) {
    const { app } = useStore()

    const _onAction = (action, parameter) => {
        onAction && onAction(action, parameter)
    }

    return (
        <Menu>
            <MenuItem
                onClick={() => {
                    _onAction('remove_definition_class')
                }}
            >
                {app.text('Remove from definition')}
            </MenuItem>
        </Menu>
    )
})

const DefinitionFieldMenu = observer(function DefinitionFieldMenu({ onAction }) {
    const { app } = useStore()

    const _onAction = (action, parameter) => {
        onAction && onAction(action, parameter)
    }

    return (
        <Menu>
            <MenuItem
                onClick={() => {
                    _onAction('remove_definition_field')
                }}
            >
                {app.text('Remove from definition')}
            </MenuItem>
        </Menu>
    )
})

export const RecordInspectorExtensions = observer(function RecordInspectorExtensions({
    worksheet,
    viewkey,
}) {
    const { app, data } = useStore()

    const DefinitionClassContextMenu = useMousePopover(DefinitionClassMenu)
    const DefinitionFieldContextMenu = useMousePopover(DefinitionFieldMenu)
    const AddClassContextMenu = useMousePopover(RecordDefinitionAddClassPanel)
    const AddFieldContextMenu = useMousePopover(RecordDefinitionAddFieldPanel)
    const popoverContext = usePopoverContext()

    const language = worksheet.language
    const record = worksheet.record
    const definition = worksheet.definitions.get(record.definition)
    const original_definition =
        definition && definition.is_extended
            ? data.definitions.get(definition.original)
            : null

    const [selectedGid, setSelectedGid] = useState(null)

    let initialtreestate = {}
    if (definition) {
        definition.classes.forEach(class_gid => {
            const class_ = data.classes.get(class_gid)
            initialtreestate[class_gid] =
                class_ && class_.fields.length ? 'collapsed' : 'empty'
        })
    }
    const [treeState, setTreeState] = useState(initialtreestate)
    const onExpandTreeState = key => {
        let newtreestate = { ...treeState }
        newtreestate[key] = 'expanded'
        setTreeState(newtreestate)
    }
    const onCollapseTreeState = key => {
        let newtreestate = { ...treeState }
        newtreestate[key] = 'collapsed'
        setTreeState(newtreestate)
    }
    const onAddTreeState = key => {
        if (key in treeState) return
        let newtreestate = { ...treeState }
        newtreestate[key] = 'collapsed'
        setTreeState(newtreestate)
    }
    const onRemoveTreeState = key => {
        if (!(key in treeState)) return
        let newtreestate = { ...treeState }
        delete newtreestate[key]
        setTreeState(newtreestate)
    }

    if (!definition) return null

    const onAddClass = class_gid => {
        record.addDefinitionClass(class_gid).then(result => {
            onAddTreeState(class_gid)
        })
    }
    const onRemoveClass = () => {
        record.removeDefinitionClass(selectedGid).then(result => {
            onRemoveTreeState(selectedGid)
            record.revertDefinitionWithoutExtensions()
        })
    }
    const onAddField = field_gid => {
        record.addDefinitionField(field_gid).then(result => {
            onAddTreeState(field_gid)
        })
    }
    const onRemoveField = () => {
        record.removeDefinitionField(selectedGid).then(result => {
            onRemoveTreeState(selectedGid)
            record.revertDefinitionWithoutExtensions()
        })
    }

    const onCopyToSiblings = () => {
        record.copyDefinitionToSiblings()
    }

    const onRevertDefinition = () => {
        record.revertDefinition()
    }
    const onDefinitionInspectorAction = (action, parameter) => {
        const actions = {
            copy_to_siblings: onCopyToSiblings,
            revert_definition: onRevertDefinition,
            add_definition_class: onAddClass,
            add_definition_field: onAddField,
            remove_definition_class: onRemoveClass,
            remove_definition_field: onRemoveField,
        }
        if (!(action in actions)) {
            console.log(
                `onDefinitionInspectorAction: unhandled action '${action}' ('${parameter}')`
            )
            return
        }
        popoverContext.store.show([...popoverContext.stack])
        actions[action](parameter)
    }

    const onDropClass = (dragitem, dropgid, dropzone) => {
        // if dropped on class -> check dropzone
        // else dropped on class.add -> move/add at end
        let before_class = null
        let use_next_class = false
        definition.classes.forEach(classgid => {
            if (classgid === dropgid && dropzone === 'top') before_class = classgid
            if (use_next_class) {
                before_class = classgid
                use_next_class = false
            }
            if (classgid === dropgid && dropzone === 'bottom') use_next_class = true
        })
        // if class is in definition -> move it
        // else, add it
        if (definition.classes.includes(dragitem.id)) {
            record.moveDefinitionClass(dragitem.id, before_class)
        } else {
            record.addDefinitionClass(dragitem.id, before_class).then(result => {
                onAddTreeState(dragitem.id)
            })
        }
    }

    const onDropField = (dragitem, dropgid, dropzone) => {
        // if dropped on field -> check dropzone
        // else dropped on field.add -> move/add at end
        let before_field = null
        let use_next_field = false
        definition.fields.forEach(fieldgid => {
            if (fieldgid === dropgid && dropzone === 'top') before_field = fieldgid
            if (use_next_field) {
                before_field = fieldgid
                use_next_field = false
            }
            if (fieldgid === dropgid && dropzone === 'bottom') use_next_field = true
        })
        // if field is in definition -> move it
        // else, add it
        if (definition.fields.includes(dragitem.id)) {
            record.moveDefinitionField(dragitem.id, before_field)
        } else {
            record.addDefinitionField(dragitem.id, before_field)
        }
    }

    let classes_treeitems = []
    definition.classes.forEach(class_gid => {
        const class_ = data.classes.get(class_gid)
        if (!class_) return null
        const from_original_classes = original_definition
            ? original_definition.classes.includes(class_.gid)
            : true
        if (from_original_classes) {
            return
        }
        const title = worksheet.layoutstore.getItemTitle(class_, language)
        onAddTreeState(class_.gid)
        const onContextMenu = e => {
            setSelectedGid(class_.gid)
            DefinitionClassContextMenu.onShow(e)
        }

        const treeitem = (
            <TreeItem
                key={class_.gid}
                state={treeState[class_.gid]}
                emptyicon={'empty'}
                indent={0}
                selected={false}
                onExpand={() => {
                    onExpandTreeState(class_.gid)
                }}
                onCollapse={() => {
                    onCollapseTreeState(class_.gid)
                }}
                onContextMenu={onContextMenu}
            >
                <Icon className="itemtype" name="class" size="text" />
                {maxChars(title, 40)}
            </TreeItem>
        )
        if (!record.is_link) {
            classes_treeitems.push(
                <DragDropDefinitionClassTreeItem
                    key={class_.gid}
                    itemgid={class_.gid}
                    onDrop={onDropClass}
                >
                    {treeitem}
                </DragDropDefinitionClassTreeItem>
            )
        } else {
            classes_treeitems.push(treeitem)
        }
        if (treeState[class_.gid] === 'expanded') {
            class_.fields.forEach(field_gid => {
                const field = data.fields.get(field_gid)
                if (!field) return null
                const title = worksheet.layoutstore.getItemTitle(field, language)
                const titlefield_tag =
                    field.gid === definition.titlefield ? (
                        <span className="role">{app.text('titlefield')}</span>
                    ) : undefined
                const treeitem = (
                    <TreeItem
                        key={class_.gid + '.' + field.gid}
                        state={'empty'}
                        emptyicon={'empty'}
                        indent={1}
                        selected={false}
                    >
                        <Icon className="itemtype" name="field" size="text" />
                        {maxChars(title, 40)}
                        {titlefield_tag}
                    </TreeItem>
                )
                if (from_original_classes || record.is_link) {
                    classes_treeitems.push(treeitem)
                } else {
                    classes_treeitems.push(
                        <DragDropDefinitionFieldItem
                            key={class_.gid + '.' + field.gid}
                            itemgid={field.gid}
                        >
                            {treeitem}
                        </DragDropDefinitionFieldItem>
                    )
                }
            })
        }
    })
    if (!record.is_link) {
        classes_treeitems.push(
            <Item
                key="class.add"
                className="cc-TreeItem"
                indent={0}
                selected={false}
                onClick={e => {
                    AddClassContextMenu.onShow(e)
                }}
                onContextMenu={e => {
                    AddClassContextMenu.onShow(e)
                }}
            >
                <Icon className="itemtype" name="plus" size="text" />
                <span className="cc-dimmed">{app.text('Add a class')}</span>
            </Item>
        )
    }

    let fields_items = []
    definition.fields.forEach(field_gid => {
        const field = data.fields.get(field_gid)
        if (!field) return null
        const from_original_fields = original_definition
            ? original_definition.fields.includes(field.gid)
            : true
        if (from_original_fields) {
            return
        }
        const title = worksheet.layoutstore.getItemTitle(field, language)
        const onContextMenu = e => {
            setSelectedGid(field.gid)
            DefinitionFieldContextMenu.onShow(e)
        }
        const item = (
            <Item
                key={field.gid}
                className="cc-TreeItem"
                indent={0}
                selected={false}
                onContextMenu={onContextMenu}
            >
                <Icon className="itemtype" name="field" size="text" />
                {maxChars(title, 40)}
            </Item>
        )
        if (!record.is_link) {
            fields_items.push(
                <DragDropDefinitionFieldItem
                    key={field.gid}
                    itemgid={field.gid}
                    onDrop={onDropField}
                >
                    {item}
                </DragDropDefinitionFieldItem>
            )
        } else {
            fields_items.push(item)
        }
    })
    if (!record.is_link) {
        fields_items.push(
            <Item
                key="field.add"
                className="cc-TreeItem"
                indent={0}
                selected={false}
                onClick={e => {
                    AddFieldContextMenu.onShow(e)
                }}
                onContextMenu={e => {
                    AddFieldContextMenu.onShow(e)
                }}
            >
                <Icon className="itemtype" name="plus" size="text" />
                <span className="cc-dimmed">{app.text('Add a field')}</span>
            </Item>
        )
    }

    return (
        <>
            <AddClassContextMenu.Panel
                onAddClass={class_gid =>
                    onDefinitionInspectorAction('add_definition_class', class_gid)
                }
            />
            <DefinitionClassContextMenu.Panel onAction={onDefinitionInspectorAction} />
            <Header>{app.text('Classes')}</Header>
            {classes_treeitems}

            <AddFieldContextMenu.Panel
                onAddField={class_gid =>
                    onDefinitionInspectorAction('add_definition_field', class_gid)
                }
            />
            <DefinitionFieldContextMenu.Panel onAction={onDefinitionInspectorAction} />
            <Header>{app.text('Fields')}</Header>
            {fields_items}

            <Spacer size={5} />
            <Header>{app.text('Fieldlists')}</Header>
            <RecordInspectorFieldlists worksheet={worksheet} viewkey={viewkey} />
        </>
    )
})

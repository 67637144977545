//
// TreeSchemaDefinitionMenu & TreeSchemaDefinitionHeaderMenu
//
// Actions on definitions in the TreeNavigator
//
// TreeSchemaDefinitionHeaderMenu
// - create_definition
//
// TreeSchemaDefinitionMenu
// - commit_definition
// - revert_definition
// - save_as_new_definition
// - delete_definition

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Menu, MenuDivider, MenuItem } from '../components'

export const TreeSchemaDefinitionHeaderMenu = observer(
    function TreeSchemaDefinitionHeaderMenu({ onAction }) {
        const { app } = useStore()

        const _onAction = action => {
            onAction && onAction(action)
        }

        return (
            <Menu>
                <MenuItem onClick={() => _onAction('create_definition')}>
                    {app.text('New definition')}
                </MenuItem>
            </Menu>
        )
    }
)

export const TreeSchemaDefinitionMenu = observer(function TreeSchemaDefinitionMenu({
    definition,
    treeitem,
    onAction,
}) {
    const { app } = useStore()

    const _onAction = action => {
        onAction && onAction(action)
    }

    if (!definition) return null

    // already committed? can't commit again
    const disableCommit = definition.is_committed

    // already committed? can't revert anymore (revert new is same as delete, so
    // does work, but we disable it for clarity -> use delete_definition instead)
    const disableRevert = definition.is_committed || definition.is_new

    // new or committed? can't save as new then
    const disableSaveAsNew = definition.is_committed || definition.is_new

    // can only copy committed
    const disableCopy = !definition.is_committed

    // part of child definition somewhere?
    // modified (but not new)? can't delete (but you can revert)
    const disableDelete = treeitem
        ? treeitem.depth > 1 || (definition.is_working_copy && !definition.is_new)
        : definition.is_working_copy && !definition.is_new

    return (
        <Menu>
            <MenuItem
                disabled={disableCommit}
                onClick={() => _onAction('commit_definition')}
            >
                {app.text('Commit definition')}
            </MenuItem>
            <MenuItem
                disabled={disableRevert}
                onClick={() => _onAction('revert_definition')}
            >
                {app.text('Revert changes')}
            </MenuItem>
            <MenuItem
                disabled={disableSaveAsNew}
                onClick={() => _onAction('save_as_new_definition')}
            >
                {app.text('Save as new')}
            </MenuItem>
            <MenuDivider />
            <MenuItem
                disabled={disableCopy}
                onClick={() => _onAction('copy_definition')}
            >
                {app.text('Duplicate definition')}
            </MenuItem>
            <MenuItem
                disabled={disableDelete}
                onClick={() => _onAction('delete_definition')}
            >
                {app.text('Erase definition (if unused)')}
            </MenuItem>
        </Menu>
    )
})

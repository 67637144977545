//
// ProjectChannelsMenu
//
// Actions on project channels in the project settings

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { VView } from '../appview'
import { Header, TextInput, Menu, MenuDivider, MenuItem } from '../components'

const RenameChannelMenu = observer(function RenameChannelMenu({
    name: current_name,
    onConfirm,
}) {
    const { app } = useStore()

    const [name, setName] = useState(current_name)
    const isValidName = name.trim().length > 0

    const _onConfirm = () => {
        onConfirm && onConfirm(name.trim())
    }
    return (
        <VView className="actions-panel">
            <div className="inspector">
                <Header>{app.text('Rename channel')}</Header>
                <VView
                    style={{
                        marginLeft: 5,
                        marginRight: 5,
                        marginBottom: 5,
                    }}
                >
                    <TextInput onChange={e => setName(e.target.value)} value={name} />
                </VView>
            </div>
            <Menu>
                <MenuItem disabled={!isValidName} onClick={_onConfirm}>
                    {app.text('Rename channel')}
                </MenuItem>
            </Menu>
        </VView>
    )
})

export const ProjectChannelsMenu = observer(function ProjectChannelsMenu({
    project,
    channelkey,
    onAction,
}) {
    const { app } = useStore()

    const _onAction = (e, action, value) => {
        onAction && onAction(e, channelkey, action, value)
    }

    if (!project) return null
    const channel = project.channels.get(channelkey)
    if (!channel) return null

    const onConfirmRename = value => {
        _onAction(null, 'rename_channel', value.trim())
    }

    // can't delete last remaining channel
    // note there is always channelkey '__all__', for [all records], but it isn't listed
    // hence the <= 2
    const disableDelete = !channelkey || project.orderedchannels.length <= 2

    return (
        <Menu>
            <MenuItem
                submenu={
                    <RenameChannelMenu name={channel} onConfirm={onConfirmRename} />
                }
            >
                {app.text('Rename')}
            </MenuItem>
            <MenuDivider />
            <MenuItem
                className="cc-danger"
                disabled={disableDelete}
                onClick={e => _onAction(e, 'delete_channel')}
            >
                {app.text('Delete channel')}
            </MenuItem>
        </Menu>
    )
})

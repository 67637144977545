//
// ClassFieldSubmenu
//
// Select a subfield from a classfield (recursive).

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Menu, MenuItem } from '../components'
import { fieldtitle } from '../stores/data/utils'

export const ClassFieldSubmenu = observer(function ClassFieldSubmenu({
    language,
    prefix,
    classfield,
    onSelectClassField,
}) {
    const { data } = useStore()

    const classgid = classfield.options.get('class')
    const class_ = classgid ? data.classes.get(classgid) : undefined
    if (!class_) return null

    const selectClassField = classfieldgid => {
        onSelectClassField && onSelectClassField(prefix + '.' + classfieldgid)
    }

    const fields = class_.fields
        .map(fieldgid => data.fields.get(fieldgid))
        .filter(field => field !== null)
    const menuitems = fields.map(field => {
        const name = fieldtitle(field, language)
        const classfieldsubmenu = ['class', 'classlist'].includes(field.type) ? (
            <ClassFieldSubmenu
                language={language}
                prefix={prefix + '.' + field.gid}
                classfield={field}
                onSelectClassField={onSelectClassField}
            />
        ) : undefined

        return (
            <MenuItem
                key={field.gid}
                submenu={classfieldsubmenu}
                onClick={e => selectClassField(field.gid)}
            >
                {name}
            </MenuItem>
        )
    })

    return <Menu>{menuitems}</Menu>
})

//
// DragSchemaSearchItem
//
// Drag wrapper for Schema SearchItem

import React, { useState, useRef, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useDrag } from 'react-dnd'

import { DragTypes } from './DragTypes'
import { DefinitionData } from '../stores/data/DefinitionData'
import { ClassData } from '../stores/data/ClassData'
import { FieldData } from '../stores/data/FieldData'

export const DragSchemaSearchItem = observer(function DragSchemaSearchItem({
    schemaitem,
    querystore,
    children,
    ...other
}) {
    const ref = useRef(null)

    const multiselect = querystore.multiselection.size > 1

    const itemtype =
        schemaitem instanceof DefinitionData
            ? DragTypes.PIM_DEFINITION
            : schemaitem instanceof ClassData
            ? DragTypes.PIM_CLASS
            : schemaitem instanceof FieldData
            ? DragTypes.PIM_FIELD
            : DragTypes.UNDEFINED

    const [isMousedown, setIsMousedown] = useState(false)

    const [{ isDragging }, drag] = useDrag({
        type: itemtype,
        item: {
            type: itemtype,
            id: schemaitem ? schemaitem.gid : null,
            gid: schemaitem ? schemaitem.gid : null,
        },
        canDrag: monitor => !multiselect,
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
        end: (item, monitor) => {
            if (ref.current) {
                setIsMousedown(false)
            }
        },
    })

    const onMouseDown = e => {
        if (e.button === 0) {
            setIsMousedown(true)
        }
    }
    const onMouseUp = e => {
        setIsMousedown(false)
    }

    drag(ref)

    let dragclassname = ''
    if (isMousedown && !multiselect) dragclassname += ' maybe-start-dragging'

    useEffect(() => {
        // set style later - cloned drag preview has original style
        if (ref && ref.current) {
            ref.current.style.opacity = isDragging ? 0.1 : 1
        }
    }, [isDragging])

    return (
        <div
            ref={ref}
            className={dragclassname}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            {...other}
        >
            {children}
        </div>
    )
})

//
// useLazyScrollingRecordStore
//

import { useState, useEffect } from 'react'
import { useStore } from '../stores'

export const useLazyScrollingRecordStore = (
    parentgid,
    initialchildren,
    environment,
    initialfirstindex,
    initialwindowsize
) => {
    const store = useStore()

    const ordering = environment.get('ordering')
    const [firstindex, setFirstindex] = useState(initialfirstindex)
    const [windowsize, setWindowsize] = useState(initialwindowsize)
    const [totalsize, setTotalsize] = useState(
        initialchildren ? initialchildren.length : 0
    )
    const [children, setChildren] = useState(initialchildren ? initialchildren : [])

    useEffect(() => {
        let isMounted = true
        let controller = new AbortController()
        async function asyncEffect() {
            store
                ._fetch(
                    '/records/children',
                    {
                        record: parentgid,
                        ordering: ordering,
                        'page.top': firstindex,
                        'page.size': windowsize,
                    },
                    { signal: controller.signal }
                )
                .then(result => {
                    if (isMounted) {
                        setChildren(
                            result['children'].map(gid => store.data.records.get(gid))
                        )
                        setTotalsize(result['children.size'])
                    }
                    return result
                })
        }
        asyncEffect()
        return () => {
            controller.abort('Unmounted')
            isMounted = false
            return isMounted
        }
    }, [store, parentgid, ordering, firstindex, windowsize])

    const onUpdate = (newfirstindex, newwindowsize) => {
        if (!parentgid) {
            return Promise.resolve(false) // nothing to fetch
        }
        if (newfirstindex === firstindex && newwindowsize === windowsize) {
            return Promise.resolve(true) // no need to refetch
        }
        setFirstindex(newfirstindex)
        setWindowsize(newwindowsize)
    }

    return {
        children,
        totalsize,
        firstindex,
        onUpdate,
    }
}

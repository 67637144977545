//
// SchemaTreeStore
//
// The SchemaTreeStore handles interaction with the Definition Navigation Tree
// (TreeStore)
//
// The exposed store is the actual TreeStore (.tree), but that is a generic element
// and the SchemaTreeStore handles storing view choices like expandedItems and such,
// and fetching data based on that.
//
// In a store, we use it like this:
//
//     this._schematree = new SchemaTreeStore(this._rootstore)
//      ...
//     get schematree() {
//         if (!this.user && !this.project) return null
//         this._schematree.loadOnce()
//         return this._schematree.tree // NOTE the .tree!
//     }
//      ...
//     _schematree is observable, schematree is computed
//
// Have a look at the TreeNavigator component to see how it is used in rendering.
//
// SIDE-EFFECT: when a user selects a treeitem, we update the schemaworksheet with that
//              same item

import {
    makeObservable,
    observable,
    action,
    computed,
    runInAction,
    reaction,
} from 'mobx'

import { TreeStore, TreeStoreItem } from '../components/TreeStore'
import { DefinitionData } from '../data/DefinitionData'
import { ClassData } from '../data/ClassData'
import { FieldData } from '../data/FieldData'

export const sortSchemaItems = language => {
    const _sort = (a, b) => {
        let alabel = a ? (a.label ? a.label.get(language) : a.name) : '???'
        if (!alabel || !alabel.length) alabel = a.name
        if (a && a.unit) {
            alabel += ' [' + a.unit + ']'
        }
        let blabel = b ? (b.label ? b.label.get(language) : b.name) : '???'
        if (!blabel || !blabel.length) blabel = b.name
        if (b && b.unit) {
            blabel += ' [' + b.unit + ']'
        }
        if (a && a.is_new) {
            alabel = '!!!' + alabel
        }
        if (b && b.is_new) {
            blabel = '!!!' + blabel
        }
        return alabel.localeCompare(blabel)
    }
    return _sort
}

export const sortSchemaTreeItems = language => {
    const sort = sortSchemaItems(language)
    const _sort = (a, b) => {
        return sort(a.item, b.item)
    }
    return _sort
}

export class SchemaTreeStore {
    __init = false
    _key = null
    _rootstore = null
    tree = null

    constructor(key, rootstore) {
        makeObservable(this, {
            __init: observable,
            _key: observable,
            _rootstore: observable,
            language: computed,
            tree: observable,
            fetch: action.bound,
            refetch: action.bound,
            _fetch: action.bound,
            select: action.bound,
            selectItem: action.bound,
            setItem: action.bound,
            syncdataitem_callback: action.bound,
        })

        this._key = key
        this._rootstore = rootstore
        this.tree = new TreeStore(this)

        this._syncdebounce = null
        this._syncdelay = 100
    }

    loadOnce = () => {
        if (this.__init) return
        const parentIds = this._rootstore.view.loadPerProject(
            this._key + '/expandedIds',
            []
        )
        const selectedId = this._rootstore.view.loadPerProject(
            this._key + '/selectedId'
        )
        this.tree._expandedIds.replace(parentIds)
        this._fetch(parentIds).then(() => {
            runInAction(() => {
                this.tree._selectedId = selectedId
                this.tree.multiselection.rangeStart(selectedId)
            })
            reaction(
                () => ({
                    selectedGid: this.tree._selectedId,
                    selectionsize: this.tree.multiselection.size,
                }),
                ({ selectedGid, selectionsize }) => {
                    if (selectedGid && !selectionsize) {
                        this.tree.multiselection.rangeStart(selectedGid)
                    }
                }
            )
            reaction(
                () => this._rootstore.view._environment.get('language'),
                language => {
                    this._fetch(this.tree._expandedIds)
                }
            )
        })
        this._rootstore.api.register_syncdataitem_callback(this.syncdataitem_callback)
        this.__init = true
    }

    fetch(parentIds) {
        this._rootstore.view.savePerProject(this._key + '/expandedIds', parentIds)
        return this._fetch(parentIds)
    }

    refetch() {
        return this._fetch(this.tree._expandedIds)
    }

    get language() {
        if (!this._rootstore.view.environment) return null
        return this._rootstore.view.environment.get('language')
    }

    _id_from_definiton(definition) {
        return definition.is_working_copy && !definition.is_new
            ? definition.original
            : definition.gid
    }

    _subtreelist(parentitem, definitions, parentIds, path) {
        let treelist = []
        const definition = definitions.get(this._id_from_definiton(parentitem.item))
        if (definition) {
            definition.childdefinitions.forEach(childgid => {
                const childdefinition = definitions.get(childgid)
                if (!childdefinition) return
                const childitem = new TreeStoreItem(
                    this._id_from_definiton(childdefinition),
                    parentitem.id,
                    parentitem.depth + 1,
                    childdefinition.childdefinitions.length,
                    childdefinition,
                    { is_link: false }
                )
                treelist.push(childitem)
                if (
                    childdefinition.childdefinitions.length &&
                    parentIds.includes(childitem.id) &&
                    !path.includes(childitem.item.gid)
                ) {
                    treelist.push(
                        ...this._subtreelist(childitem, definitions, parentIds, [
                            ...path,
                            childitem.item.gid,
                        ])
                    )
                }
            })
        }
        return treelist
    }

    treelist_from_definitions(definitions, parentIds) {
        // from a dict of defs, build an ordered list of Treeitems (gid, parentgid, indent)
        // first i need to find the roots and order them alphabetically
        // then for each root, create a tree and add those as treeitems
        //
        // definitions is not the complete map, only those that are not extended, and the
        // modified versions if available - mapped by original first, then gid
        let childdefinitions = new Set()
        definitions.forEach(definition => {
            definition.childdefinitions.forEach(childgid => {
                if (definition.gid !== childgid && definition.original !== childgid) {
                    childdefinitions.add(childgid)
                }
            })
        })
        let roots = []
        definitions.forEach(definition => {
            if (
                !childdefinitions.has(
                    definition.is_working_copy && !definition.is_new
                        ? definition.original
                        : definition.gid
                )
            ) {
                roots.push(
                    new TreeStoreItem(
                        this._id_from_definiton(definition),
                        'definition',
                        1,
                        definition.childdefinitions.length,
                        definition,
                        { is_link: false }
                    )
                )
            }
        })
        const _sort = sortSchemaTreeItems(this.language)
        roots.sort(_sort)

        let treelist = []
        roots.forEach(root => {
            treelist.push(root)
            if (root.item.childdefinitions.length && parentIds.includes(root.id)) {
                treelist.push(
                    ...this._subtreelist(root, definitions, parentIds, [root.item.gid])
                )
            }
        })
        let useddefs = new Set()
        treelist.forEach(treeitem => {
            if (!useddefs.has(treeitem.id)) {
                useddefs.add(treeitem.id)
            } else {
                treeitem.data.is_link = true
            }
        })
        return treelist
    }

    _fetch(parentIds) {
        const _sort = sortSchemaItems(this.language)
        let treeitems = []
        treeitems.push(
            new TreeStoreItem(
                'definition',
                'root',
                0,
                this._rootstore.data.definitions.size,
                'definitions'
            )
        )
        const definitions = Array.from(this._rootstore.data.definitions.values())
        const with_modifications = new Set()
        const definitiontreemap = definitions
            .filter(definition => {
                if (definition.is_working_copy) {
                    with_modifications.add(definition.original)
                }
                return (
                    definition.is_committed ||
                    definition.is_new ||
                    definition.is_working_copy
                )
            })
            .filter(definition => {
                return !with_modifications.has(definition.gid)
            })
            .reduce((map, definition) => {
                map.set(
                    definition.is_working_copy && !definition.is_new
                        ? definition.original
                        : definition.gid,
                    definition
                )
                return map
            }, new Map())
        if (!parentIds.length && !this.__init) {
            // no prior interaction ? make sure defintions all expand
            parentIds.push(...Array.from(definitiontreemap.keys()))
        }
        if (parentIds.includes('definition')) {
            const definitiontreelist = this.treelist_from_definitions(
                definitiontreemap,
                parentIds
            )
            definitiontreelist.forEach(treeitem => {
                treeitems.push(treeitem)
            })
        }
        treeitems.push(
            new TreeStoreItem(
                'class',
                'root',
                0,
                this._rootstore.data.classes.size,
                'classes'
            )
        )
        if (parentIds.includes('class')) {
            const classes = Array.from(this._rootstore.data.classes.values())
            const with_modifications = new Set()
            treeitems.push(
                ...classes
                    .filter(class_ => {
                        if (class_.is_working_copy) {
                            with_modifications.add(class_.original)
                        }
                        return true
                    })
                    .filter(class_ => {
                        return !with_modifications.has(class_.gid)
                    })
                    .sort(_sort)
                    .map(class_ => {
                        return new TreeStoreItem(class_.gid, 'class', 1, 0, class_)
                    })
            )
        }
        treeitems.push(
            new TreeStoreItem(
                'field',
                'root',
                0,
                this._rootstore.data.fields.size,
                'fields'
            )
        )
        if (parentIds.includes('field')) {
            const fields = Array.from(this._rootstore.data.fields.values())
            const with_modifications = new Set()
            treeitems.push(
                ...fields
                    .filter(field => {
                        if (field.is_working_copy) {
                            with_modifications.add(field.original)
                        }
                        return true
                    })
                    .filter(field => {
                        return !with_modifications.has(field.gid)
                    })
                    .sort(_sort)
                    .map(field => {
                        return new TreeStoreItem(field.gid, 'field', 1, 0, field)
                    })
            )
        }
        this.tree._expandedIds.replace(parentIds)
        this.tree.setTreeItems(treeitems, treeitems.length)
        return Promise.resolve(true)
    }

    deselect() {
        this._rootstore.view.savePerProject(this._key + '/selectedId', null)
        this.selectItem(null)
    }

    select(treeitem) {
        if (treeitem) {
            this._rootstore.view.savePerProject(this._key + '/selectedId', treeitem.id)
            this.selectItem(treeitem.item)
        }
    }

    selectItem(item) {
        this._rootstore.view.schemaworksheet.setItem(item)
    }

    setItem(item) {
        // this is external; when we select an item, we need to make sure it appears in
        // the expanded tree, and that it will be the selected treeitem
        const parentname =
            item instanceof DefinitionData
                ? 'definition'
                : item instanceof ClassData
                ? 'class'
                : item instanceof FieldData
                ? 'field'
                : 'root'

        const newExpandedIds = Array.from(
            new Set([...this.tree._expandedIds, parentname])
        )
        this.tree._setExpandedIds(newExpandedIds)

        if (parentname === 'definition') {
            this.tree.setSelectedId(item ? this._id_from_definiton(item) : null)
        } else {
            this.tree.setSelectedId(item ? item.gid : null)
        }

        this.tree.multiselection.deselectAll()
        if (item) {
            this.tree.multiselection.rangeStart(item.gid)
        }
    }

    schedule_refetch = () => {
        if (this._syncdebounce) {
            clearTimeout(this._syncdebounce)
            this._syncdebounce = null
        }
        this._syncdebounce = setTimeout(() => {
            this._syncdebounce = null
            this.refetch()
        }, this._syncdelay)
    }

    syncdataitem_callback = (syncdataitem, data_before, data_after) => {
        // console.log('SchemaTreeStore sync', syncdataitem, data_before, data_after)
        // we're only interested in schema data_types
        // then, we're only interested in all additions and deletions, plus definition
        // updates
        if (!['definitions', 'classes', 'fields'].includes(syncdataitem['data_type'])) {
            return
        }
        if (syncdataitem['data_type'] === 'definitions') {
            const data = data_after ? data_after : data_before ? data_before : null
            if (!data) return
            // don't update for extended definitions
            if (data && data.is_extended) {
                return
            }
        }
        if (
            syncdataitem['data_type'] !== 'definitions' &&
            !['INSERT', 'DELETE'].includes(syncdataitem['action'])
        ) {
            return
        }
        this.schedule_refetch()
    }
}

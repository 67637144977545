//
// DragDropAssetTreeItem
//
// Drag and Drop wrapper for Asset TreeItem
//
// If you set dropData, you can 'postpone' the onDrop, e.g. when you want to present an
// action sheet (like move/copy/link) after dragging ends, but you still want the visual
// feedback from where you dropped it

import React, { useState, useRef, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'
import { useDrag, useDrop, DragPreviewImage } from 'react-dnd'

import { DragTypes } from './DragTypes'
import { NativeTypes } from 'react-dnd-html5-backend'
import { useDebounce } from '../hooks/useDebounce'
import { selectionsrc } from '../utils/icon'
import { gid } from '../utils/gid'
import {
    MultiselectionTypes,
    determineAssetsMultiselectionType,
} from '../utils/multiselection'

export const DragDropAssetTreeItem = observer(function DragDropAssetTreeItem({
    tree,
    treeitem,
    dropData,
    onDrop,
    children,
    ...other
}) {
    const { app, data } = useStore()
    const ref = useRef(null)

    const multiselect = tree.multiselection.size > 1
    const dragtype = multiselect ? DragTypes.ASSET_SELECTION : DragTypes.DAM_ASSET
    const draggid = multiselect ? gid() : treeitem.item.gid

    const selectiontype = determineAssetsMultiselectionType(tree.multiselection, data)
    const isFlat = selectiontype === MultiselectionTypes.FLAT

    const [isoverzone, setIsoverzone] = useState('item')
    const [isMousedown, setIsMousedown] = useState(false)

    const debouncedIsoverzone = useDebounce(isoverzone, 50)

    const [{ isDragging }, drag, preview] = useDrag({
        type: dragtype,
        item: {
            type: dragtype,
            id: treeitem.id,
            gid: draggid,
            selection: tree.actionSelection,
            selectionsize: tree.multiselection.size,
            isFlat,
        },
        canDrag: () => isFlat,
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
        end: (item, monitor) => {
            if (ref.current) {
                setIsMousedown(false)
            }
        },
    })

    const onMouseDown = e => {
        if (e.button === 0) {
            setIsMousedown(true)
        }
    }
    const onMouseUp = e => {
        setIsMousedown(false)
    }

    const canDrop = dragitem => {
        if (!ref.current || !dragitem) {
            return false
        }
        if (dragitem.hasOwnProperty('files')) {
            if (treeitem.item.is_file || treeitem.item.status === 'deleted') {
                return false
            }
            return true
        } else if (dragitem.type === DragTypes.DAM_ASSET) {
            if (
                treeitem.item.is_file ||
                dragitem.id === '/' ||
                dragitem.id === treeitem.id ||
                treeitem.item.status === 'deleted' ||
                treeitem.item.pathname.startsWith(dragitem.id + '/') // is Descendant
            ) {
                return false
            }
            return true
        } else if (dragitem.type === DragTypes.ASSET_SELECTION) {
            if (
                treeitem.item.is_file ||
                treeitem.item.status === 'deleted' ||
                tree.multiselection.isSelected(treeitem.item.gid)
            ) {
                return false
            }
            return true
        }
        return false
    }

    const [{ isOver }, drop] = useDrop({
        accept: [DragTypes.DAM_ASSET, DragTypes.ASSET_SELECTION, NativeTypes.FILE],
        canDrop: dragitem => canDrop(dragitem),

        drop(dragitem, monitor) {
            onDrop(dragitem, treeitem, isoverzone)
            return {
                receiver: 'DragDropAssetTreeItem',
                dragitem: dragitem,
                treeitem: treeitem,
                dropzone: isoverzone,
            }
        },

        collect: monitor => {
            let isOver = !!monitor.isOver()
            const dragitem = monitor.getItem()
            if (!canDrop(dragitem)) {
                isOver = false
            }
            return {
                isOver: isOver,
            }
        },

        hover(dragitem, monitor) {
            if (!canDrop(dragitem)) {
                return
            }
            setIsoverzone('item')
        },
    })

    drag(drop(ref))

    let dragclassname = 'cc-grow'
    if (isOver && debouncedIsoverzone === 'item') dragclassname += ' drag-over-inside'
    if (isMousedown && !multiselect) dragclassname += ' maybe-start-dragging'

    useEffect(() => {
        // set style later - cloned drag preview has original style
        if (ref && ref.current && !multiselect) {
            ref.current.style.opacity =
                isDragging ||
                (dropData && dropData.dragitem && dropData.dragitem.id === treeitem.id)
                    ? 0.1
                    : 1
        }
    }, [isDragging, dropData, treeitem.id, multiselect])

    const Preview = multiselect ? (
        <DragPreviewImage
            connect={preview}
            src={selectionsrc(tree.multiselection.size + app.text('assets'))}
        />
    ) : undefined

    return (
        <>
            {Preview}
            <div
                ref={ref}
                className={dragclassname}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                {...other}
            >
                {children}
            </div>
        </>
    )
})

//
// ProjectData
//
// A plain ProjectData object to hold properties

import {
    makeObservable,
    observable,
    computed,
    action,
    autorun,
    runInAction,
} from 'mobx'

import { updateInstanceWithData } from './utils'
import { initialsFromName } from '../../utils/text'
import CCDamImageserver from '../imageservers/CCDamImageserver'
import StentorImageserver from '../imageservers/StentorImageserver'

export class ProjectData {
    gid = null
    name = ''
    icon = null
    owner = null
    status = ''
    build = 0
    options = new Map()
    backups = new Map()

    _imageserver = null
    _uneditedname = ''

    get uneditedname() {
        return this._uneditedname
    }

    get timezone() {
        if (!this.options || !this.options.has('timezone')) return null
        return this.options.get('timezone')
    }

    get imageserver() {
        if (!this.options || !this.options.has('imageserver')) return null
        return this.options.get('imageserver')
    }

    get languages() {
        if (!this.options || !this.options.has('languages')) return null
        return this.options.get('languages')
    }

    get channels() {
        if (!this.options || !this.options.has('channels')) return null
        return new Map(Object.entries(this.options.get('channels')))
    }

    get orderedchannels() {
        if (!this.options || !this.options.has('orderedchannels')) return null
        return this.options.get('orderedchannels')
    }

    get orderings() {
        if (!this.options || !this.options.has('orderings')) return null
        return new Map(Object.entries(this.options.get('orderings')))
    }

    get orderedorderings() {
        if (!this.options || !this.options.has('orderedorderings')) return null
        return this.options.get('orderedorderings')
    }

    get fieldlists() {
        if (!this.options || !this.options.has('fieldlists')) return null
        return new Map(Object.entries(this.options.get('fieldlists')))
    }

    get orderedfieldlists() {
        if (!this.options || !this.options.has('orderedfieldlists')) return null
        return this.options.get('orderedfieldlists')
    }

    get initials() {
        return this.name ? initialsFromName(this.name) : '?'
    }

    get backup_versions() {
        if (!this.backups || !this.backups.has('versions')) return null
        return this.backups.get('versions')
    }

    constructor(projectdata, rootstore) {
        makeObservable(this, {
            name: observable,
            icon: observable,
            owner: observable,
            status: observable,
            build: observable,
            options: observable,
            backups: observable,
            _imageserver: observable,
            _uneditedname: observable,

            uneditedname: computed,
            timezone: computed,
            imageserver: computed,
            languages: computed,
            channels: computed,
            orderedchannels: computed,
            orderings: computed,
            orderedorderings: computed,
            fieldlists: computed,
            orderedfieldlists: computed,
            initials: computed,
            backup_versions: computed,

            url: computed,

            update: action.bound,
            createChannel: action.bound,
            deleteChannels: action.bound,
            renameChannel: action.bound,
            moveChannel: action.bound,

            createOrdering: action.bound,
            deleteOrdering: action.bound,
            renameOrdering: action.bound,
            moveOrdering: action.bound,

            createFieldlist: action.bound,
            deleteFieldlist: action.bound,
            renameFieldlist: action.bound,
            moveFieldlist: action.bound,

            moveLanguage: action.bound,
            addLanguage: action.bound,
            removeLanguage: action.bound,

            renameProject: action.bound,
            commitRenameProject: action.bound,
            deleteProject: action.bound,
            undeleteProject: action.bound,
            permanentDeleteProject: action.bound,

            cancelMembership: action.bound,

            inviteMember: action.bound,
            resendInvite: action.bound,
            deactivateMember: action.bound,
            reactivateMember: action.bound,
            removeMember: action.bound,

            createAPIKey: action.bound,
            getAPIKey: action.bound,
            updateAPIKey: action.bound,
            deactivateAPIKey: action.bound,
            reactivateAPIKey: action.bound,
            deleteAPIKey: action.bound,
        })

        this._rootstore = rootstore
        this._imageserver = new CCDamImageserver({}, rootstore)
        autorun(() => {
            let supportedtype = 'ccdam'
            if (
                this.imageserver &&
                ['ccdam', 'stentor'].includes(this.imageserver.type)
            ) {
                supportedtype = this.imageserver.type
            }
            switch (supportedtype) {
                case 'stentor':
                    this._imageserver = new StentorImageserver(
                        this.imageserver ? this.imageserver.options : {},
                        rootstore
                    )
                    break
                case 'ccdam':
                default:
                    this._imageserver = new CCDamImageserver(
                        this.imageserver ? this.imageserver.options : {},
                        rootstore
                    )
                    break
            }
        })

        this.update(projectdata)
    }

    get url() {
        if (!this.gid) return null
        return '/p/' + this.gid
    }

    update = projectdata => {
        updateInstanceWithData(
            this,
            projectdata,
            ['gid', 'name', 'icon', 'owner', 'status', 'build', 'options'],
            ['backups']
        )
        this._uneditedname = this.name
    }

    createChannel = name => {
        return this._rootstore
            ._fetch('/channels/create', { name: name })
            .then(result => {
                return result['channel']
            })
        // .catch(error => {})
    }

    deleteChannels = keys => {
        return this._rootstore
            ._fetch('/channels/delete', { channels: keys })
            .then(result => {
                if (keys.includes(this._rootstore.view.environment.get('channel'))) {
                    this._rootstore.view.updateEnvironment({
                        channel: this.orderedchannels[0],
                    })
                }
                return result
            })
        // .catch(error => {})
    }

    renameChannel = (key, name) => {
        this.options.get('channels')[key] = name
    }

    commitRenameChannel = key => {
        const name = this.channels.get(key)
        // basically fire & forget updates
        return this._rootstore._fetch('/channels/update', {
            channel: key,
            name: name,
        })
        // .catch(error => {})
    }

    moveChannel = (key, before_key) => {
        return this._rootstore._fetch('/channels/move', {
            channel: key,
            before_channel: before_key,
        })
        // .catch(error => {})
    }

    createOrdering = name => {
        return this._rootstore
            ._fetch('/orderings/create', { name: name })
            .then(result => {
                return result['ordering']
            })
        // .catch(error => {})
    }

    deleteOrdering = key => {
        return this._rootstore
            ._fetch('/orderings/delete', { ordering: key })
            .then(result => {
                if (key === this._rootstore.view.environment.get('ordering')) {
                    this._rootstore.view.updateEnvironment({
                        ordering: this.orderedorderings[0],
                    })
                }
                return result
            })
        // .catch(error => {})
    }

    renameOrdering = (key, name) => {
        this.options.get('orderings')[key] = name
    }

    commitRenameOrdering = key => {
        const name = this.orderings.get(key)
        // basically fire & forget updates
        return this._rootstore._fetch('/orderings/update', {
            ordering: key,
            name: name,
        })
        // .catch(error => {})
    }

    moveOrdering = (key, before_key) => {
        return this._rootstore._fetch('/orderings/move', {
            ordering: key,
            before_ordering: before_key,
        })
        // .catch(error => {})
    }

    createFieldlist = name => {
        return this._rootstore
            ._fetch('/fieldlists/create', { name: name })
            .then(result => {
                return result['fieldlist']
            })
        // .catch(error => {})
    }

    deleteFieldlist = key => {
        return this._rootstore
            ._fetch('/fieldlists/delete', { fieldlist: key })
            .then(result => {
                if (key === this._rootstore.view.environment.get('fieldlist')) {
                    this._rootstore.view.updateEnvironment({
                        fieldlist: this.orderedfieldlists[0],
                    })
                }
                return result
            })
        // .catch(error => {})
    }

    renameFieldlist = (key, name) => {
        this.options.get('fieldlists')[key] = name
    }

    commitRenameFieldlist = key => {
        const name = this.fieldlists.get(key)
        // basically fire & forget updates
        return this._rootstore._fetch('/fieldlists/update', {
            fieldlist: key,
            name: name,
        })
        // .catch(error => {})
    }

    moveFieldlist = (key, before_key) => {
        return this._rootstore._fetch('/fieldlists/move', {
            fieldlist: key,
            before_fieldlist: before_key,
        })
        // .catch(error => {})
    }

    moveLanguage = (language, before_language) => {
        return this._rootstore._fetch('/languages/move', {
            language: language,
            before_language: before_language,
        })
        // .catch(error => {})
    }

    addLanguage = language => {
        return this._rootstore._fetch('/languages/add', {
            language: language,
        })
        // .catch(error => {})
    }

    _removeLanguage = language => {
        return this._rootstore._fetch('/languages/remove', {
            language: language,
        })
        // .catch(error => {})
    }

    removeLanguage = language => {
        const selectedrecord = this._rootstore.view.pimworksheet.selected.record
        if (this._rootstore.view.environment.get('language') === language) {
            this._rootstore.view.updateEnvironment({
                language:
                    language !== this.languages[0]
                        ? this.languages[0]
                        : this.languages[1],
            })
            if (!selectedrecord) {
                return this._removeLanguage(language)
            } else {
                return this._rootstore
                    ._fetch('/records/get', {
                        record: selectedrecord.gid,
                        language: this._rootstore.view.environment.get('language'),
                    })
                    .then(result => {
                        runInAction(() => {
                            // is runInAction necessary?
                            // store data in store.data.pimrecords
                            this._rootstore.view.pimworksheet.setRecord(
                                result['record']
                            )
                            this._removeLanguage(language)
                        })
                        return result
                    })
                    .catch(error => {})
            }
        } else {
            return this._removeLanguage(language)
        }
    }

    renameProject = name => {
        if (name.length) {
            this.name = name
        } else {
            this.name = this._rootstore.app.text('[untitled project]')
        }
    }

    commitRenameProject = () => {
        // basically fire & forget updates
        return this._rootstore
            ._globalfetch('/projects/update', {
                project: this.gid,
                name: this.name,
            })
            .then(result => {
                this._uneditedname = this.name
                return result
            })
        // .catch(error => {})
    }

    deleteProject = () => {
        return this._rootstore._globalfetch('/projects/delete', {
            project: this.gid,
        })
        // .then(result => {
        //     this.status = "deleted"
        //     return result
        // })
        // .catch(error => {})
    }

    undeleteProject = () => {
        return this._rootstore._globalfetch('/projects/undelete', {
            project: this.gid,
        })
        // .then(result => {
        //     this.status = "active"
        //     return result
        // })
        // .catch(error => {})
    }

    permanentDeleteProject = () => {
        return this._rootstore
            ._globalfetch('/projects/permanent_delete', {
                project: this.gid,
            })
            .then(result => {
                window.location.reload()
                return result
            })
            .catch(error => {})
    }

    cancelMembership = () => {
        return this._rootstore
            ._globalfetch('/account/leave_project', {
                project: this.gid,
            })
            .then(result => {
                window.location.reload()
                return result
            })
        // .catch(error => {})
    }

    inviteMember = username_or_email => {
        return this._rootstore._fetch('/members/invite', {
            username_or_email,
        })
        // .catch(error => {})
    }

    resendInvite = user_gid => {
        return this._rootstore._fetch('/members/resend_invite', {
            user: user_gid,
        })
        // .catch(error => {})
    }

    deactivateMember = user_gid => {
        return this._rootstore._fetch('/members/deactivate', {
            user: user_gid,
        })
        // .catch(error => {})
    }

    reactivateMember = user_gid => {
        return this._rootstore._fetch('/members/reactivate', {
            user: user_gid,
        })
        // .catch(error => {})
    }

    removeMember = user_gid => {
        return this._rootstore
            ._fetch('/members/remove', {
                user: user_gid,
            })
            .then(result => {
                this._rootstore.data.removeProjectMember({
                    project_gid: this.gid,
                    user_gid,
                })
                return result
            })
        // .catch(error => {})
    }

    createAPIKey = name => {
        return this._rootstore._fetch('/apikeys/create', { name })
        // .catch(error => {})
    }

    getAPIKey = user_gid => {
        return this._rootstore._fetch('/apikeys/get', {
            user: user_gid,
        })
        // .catch(error => {})
    }

    updateAPIKey = (user_gid, name) => {
        return this._rootstore._fetch('/apikeys/update', {
            user: user_gid,
            name,
        })
        // .catch(error => {})
    }

    deactivateAPIKey = user_gid => {
        return this._rootstore._fetch('/apikeys/deactivate', {
            user: user_gid,
        })
        // .catch(error => {})
    }

    reactivateAPIKey = user_gid => {
        return this._rootstore._fetch('/apikeys/reactivate', {
            user: user_gid,
        })
        // .catch(error => {})
    }

    deleteAPIKey = user_gid => {
        return this._rootstore
            ._fetch('/apikeys/delete', {
                user: user_gid,
            })
            .then(result => {
                this._rootstore.data.removeProjectMember({
                    project_gid: this.gid,
                    user_gid,
                })
                return result
            })
        // .catch(error => {})
    }
}

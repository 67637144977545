//
// AssetActions
//
// Actions on assets

import { makeObservable, action } from 'mobx'

export class AssetActions {
    constructor(rootstore) {
        makeObservable(this, {
            // single asset
            createSubfolder: action.bound,
            move: action.bound,
            uploadUpdate: action.bound,
            delete: action.bound,
            undelete: action.bound,
            permanentDelete: action.bound,
            // assets selection
            bulkDelete: action.bound,
            bulkUndelete: action.bound,
            bulkPermanentDelete: action.bound,
            // other asset-related
            upload: action.bound,
            uploadToFolder: action.bound,
            autocomplete_asset_filter: action.bound,
        })

        this._rootstore = rootstore
    }

    get language() {
        return this._rootstore.view.environment.get('language')
    }

    createSubfolder = parentgid =>
        this._rootstore._fetch('/assets/create', {
            parent: parentgid,
        })

    move = (assetgid, parentgid) =>
        this._rootstore._fetch('/assets/move', {
            asset: assetgid,
            parent: parentgid,
        })

    uploadUpdate = (file, assetgid) =>
        this._rootstore.api.upload('/assets/upload_update', file, {
            asset: assetgid,
        })

    delete = assetgid =>
        this._rootstore._fetch('/assets/delete', {
            asset: assetgid,
        })

    undelete = assetgid =>
        this._rootstore._fetch('/assets/undelete', {
            asset: assetgid,
        })

    permanentDelete = assetgid =>
        this._rootstore._fetch('/assets/permanent_delete', {
            asset: assetgid,
        })

    upload = (file, parentgid) =>
        this._rootstore.api.upload('/assets/upload', file, {
            parent: parentgid,
        })

    uploadToFolder = (file, folderpath) =>
        this._rootstore.api.upload('/assets/upload', file, {
            pathname: folderpath,
        })

    bulkMove = (selection, parentgid) =>
        this._rootstore._fetch('/assets/bulk/move', {
            selection: selection,
            parent: parentgid,
        })

    bulkDelete = selection =>
        this._rootstore._fetch('/assets/bulk/delete', {
            selection: selection,
        })

    bulkUndelete = selection =>
        this._rootstore._fetch('/assets/bulk/undelete', {
            selection: selection,
        })

    bulkPermanentDelete = selection =>
        this._rootstore._fetch('/assets/bulk/permanent_delete', {
            selection: selection,
        })

    autocomplete_asset_filter = (name, q) =>
        this._rootstore._fetch('/assets/search/autocomplete', {
            field: name,
            fieldq: q,
            'completions.size': 25,
            language: this.language,
        })
}

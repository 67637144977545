//
// TextInput lab
//
// Show plain text input, both single and multiline

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

import { HView, VView } from '../../appview'
import { TextInput as TextInputComponent, TextListInput } from '../../components'

// outside rendering, so doesn't trigger rerender
const textinputstyle = {
    border: '1px solid #ddd',
    maxWidth: 400,
    marginTop: 5,
}

export const TextInput = observer(function TextInput() {
    const [textValue, setTextValue] = useState('CMS')
    const [multilineTextValue, setMultilineTextValue] = useState('CMS\nCMS2\nCMS3')
    const [listTextValue, setListTextValue] = useState(['CMS', 'CMS2', 'CMS3'])

    const onChange = event => {
        setTextValue(event.target.value)
    }
    const onBlur = event => {
        setTextValue(event.target.value.trim())
    }
    const onChangeMultiline = event => {
        setMultilineTextValue(event.target.value)
    }
    const onBlurMultiline = event => {
        setMultilineTextValue(event.target.value.trim())
    }
    const onChangeList = newValue => {
        setListTextValue(newValue)
    }
    const onBlurList = newValue => {
        setListTextValue(newValue)
    }

    return (
        <HView style={{ padding: 20 }} gap={20}>
            <VView>
                <h3>single line, plain text</h3>
                <p>both inputs are connected to the same data</p>
                <br />
                <TextInputComponent
                    className="ws-text"
                    multiline={false}
                    enabled={true}
                    value={textValue}
                    placeholder={'text value'}
                    style={textinputstyle}
                    onChange={onChange}
                    onBlur={onBlur}
                />
                <TextInputComponent
                    className="ws-text"
                    multiline={false}
                    enabled={true}
                    value={textValue}
                    style={textinputstyle}
                    onChange={onChange}
                    onBlur={onBlur}
                />
            </VView>
            <VView>
                <h3>multiline, plain text</h3>
                <p>both inputs are connected to the same data</p>
                <br />
                <TextInputComponent
                    className="ws-text"
                    multiline={true}
                    enabled={true}
                    value={multilineTextValue}
                    placeholder={'multiline text value'}
                    style={textinputstyle}
                    onChange={onChangeMultiline}
                    onBlur={onBlurMultiline}
                />
                <TextInputComponent
                    className="ws-text"
                    multiline={true}
                    enabled={true}
                    value={multilineTextValue}
                    style={textinputstyle}
                    onChange={onChangeMultiline}
                    onBlur={onBlurMultiline}
                />
            </VView>
            <VView>
                <h3>plain text list</h3>
                <p>both inputs are connected to the same data</p>
                <br />
                <TextListInput
                    enabled={true}
                    listvalue={listTextValue}
                    placeholder={'list text value'}
                    style={textinputstyle}
                    onChange={onChangeList}
                    onBlur={onBlurList}
                />
                <TextListInput
                    enabled={true}
                    listvalue={listTextValue}
                    style={textinputstyle}
                    onChange={onChangeList}
                    onBlur={onBlurList}
                />
            </VView>
        </HView>
    )
})

import { DateTime } from 'luxon'

export const isString = maybe_string => {
    return maybe_string instanceof String || typeof maybe_string === 'string'
}

export const maxChars = (text, max) => {
    if (!text || text.length <= max) return text
    return text.substring(0, max - 1) + '…'
}

export const normalize = text => {
    // a normalized text consists only of lower case letters, digits, and underscores
    return text
        .trim()
        .toLowerCase()
        .replace(/[^a-z0-9_]/g, '_')
}

export const validName = text => {
    // a valid name consists only of lower or upper case letters, digits, and underscores
    // it also can't start with a digit, in that case an underscore replaces the first
    // digit
    return text.replace(/[^a-zA-Z0-9_]/g, '_').replace(/^[0-9]/, '_')
}

export const validAssetName = text => {
    // a valid name consists only of lower or upper case letters, digits, underscores,
    // dashes, spaces, and dots
    // it also can't start with a dot, in that case an underscore replaces the first
    // dot
    // we'll also allow a non-breaking-space character ' ', because when you type
    // multiple successive spaces, you'll get some nbsp's in between
    return text.replace(/[^-a-zA-Z0-9_.  ]/g, '_').replace(/^\./, '_')
} //                                    ^ nbsp!

export const breakingspaces = text => {
    // breakingspaces replaces all non-breaking-spaces with actual spaces
    return text.replace(/ /g, ' ')
} //                     ^ nbsp!

export const visualizeWhitespace = text => {
    // transform spaces to =, nonbreakingspaces to ~ (they have the same width in my
    // web inspector console), and newlines to ⏎ followed by a newline)
    return text.replace(/ /g, '=').replace(/ /g, '~').replace(/\n/g, '⏎\n')
}

// not for crypto, but useful for making a long string into a unique(-ish) short one
export const hashString = s => {
    var hash = 0,
        l = s.length,
        char
    if (l === 0) return hash
    while (l--) {
        char = s.charCodeAt(l)
        hash = (hash << 5) - hash + char
        hash |= 0 // Convert to 32bit integer
    }
    hash &= 0xffffffff
    var hex = hash.toString(16).toLowerCase()
    return ('00000000' + hex).slice(-8)
}

export const humanReadableBytes = bytes => {
    if (typeof bytes !== 'number') return undefined
    bytes = Math.abs(bytes)
    var threshold = 1024
    if (bytes < threshold) {
        return bytes + ' B'
    }
    var units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    var u = -1
    do {
        bytes /= threshold
        ++u
    } while (bytes >= threshold && u < units.length - 1)
    return bytes.toFixed(1) + ' ' + units[u]
}

export const humanReadableNumber = number => {
    if (typeof number !== 'number') return undefined
    return number.toLocaleString()
}

export const shortDateTime = date => {
    return DateTime.fromJSDate(date).toRelative()
}

export const initialsFromName = name => {
    const words = name
        .replace(/[-.,/#!?$%^&*;:{}+=_`~()[\]'"']/g, '') // remove punctuation
        .replace(/\s{2,}/g, ' ') // and multiple whitespace characters
        .trim()
        .toUpperCase()
        .split(' ')
    let initials = words[0][0]
    if (words.length > 1) {
        initials += words[words.length - 1][0]
    }
    return initials
}

export const initialsFromEmail = email => {
    const name = email.split('@')[0]
    const words = name
        .replace(/[-.,/#!?$%^&*;:{}+=_`~()[\]'"']/g, ' ') // replace punctuation
        .replace(/\s{2,}/g, ' ') // remove multiple whitespace characters
        .trim()
        .toUpperCase()
        .split(' ')
    let initials = words[0][0]
    if (words.length > 1) {
        initials += words[words.length - 1][0]
    }
    return initials
}

export const htmlSafe = unsafeHtml =>
    unsafeHtml
        .replaceAll(`&`, '&amp;')
        .replaceAll(`<`, '&lt;')
        .replaceAll(`>`, '&gt;')
        .replaceAll(`"`, '&quot;')
        .replaceAll(`'`, '&#039;')

export const regexSafe = unsafeRegex =>
    unsafeRegex.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')

//
// MenuItem
//
// A MenuItem can be used in a menu. It has a name, an optional icon, an action or
// submenu, perhaps a hotkey later?

import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Icon } from './Icon'
import { useSubmenuPopover } from '../hooks/useSubmenuPopover'

export const MenuItem = React.forwardRef(
    (
        {
            className,
            submenu,
            active,
            disabled,
            divider,
            children,
            onClick,
            dismissAction,
            ...other
        },
        ref
    ) => {
        let classes = 'cc-MenuItem'
        if (className) classes += ' ' + className
        if (active) classes += ' cc-selected'
        if (disabled) classes += ' cc-disabled'
        if (divider) classes += ' cc-divider'

        const SubMenuPanel = submenu
            ? observer(function SubMenuPanel(props) {
                  return submenu
              })
            : undefined

        const SubmenuPopover = useSubmenuPopover(SubMenuPanel, { dismissAction })

        let submenuRef
        let SubmenuPanel
        let submenuIndicator
        if (SubmenuPopover) {
            submenuRef = SubmenuPopover.anchorRef
            SubmenuPanel = <SubmenuPopover.Panel />
            submenuIndicator = (
                <Icon
                    name="collapsed"
                    size={'text'}
                    className="cc-MenuItem-Indicator"
                />
            )
            classes += ' has-submenu'
        }

        useEffect(() => {
            if (!SubmenuPopover) return
            let isMounted = true
            async function asyncEffect() {
                if (isMounted) {
                    if (active) SubmenuPopover.show()
                    else SubmenuPopover.hide()
                }
            }
            asyncEffect()
            return () => (isMounted = false)
        }, [active, SubmenuPopover])

        return (
            <>
                {SubmenuPanel}
                <div ref={ref}>
                    <div
                        className={classes}
                        {...other}
                        ref={submenuRef}
                        onClick={e => !disabled && onClick && onClick(e)}
                    >
                        {submenuIndicator}
                        {children}
                    </div>
                </div>
            </>
        )
    }
)

//
// TreeRecordMenu
//
// Actions on records in the TreeNavigator
// Only feed it not-deleted records that are not links.

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Menu, MenuDivider, MenuItem } from '../components'
import { TreeRecordWithLinksSubmenu } from './TreeRecordWithLinksSubmenu'
import { ExcelExportImportMenu } from '../menus/ExcelExportImportMenu'

export const TreeRecordMenu = observer(function TreeRecordMenu({ record, onAction }) {
    const { app } = useStore()

    const _onAction = action => {
        onAction && onAction(action)
    }

    if (!record) return null

    // can't delete root
    const disableDelete = record.parent === null

    return (
        <Menu>
            <MenuItem onClick={() => _onAction('create_nested')}>
                {app.text('New child')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('duplicate')}>
                {app.text('Duplicate record')}
            </MenuItem>
            <MenuDivider />
            <MenuItem
                submenu={<ExcelExportImportMenu record={record} onAction={onAction} />}
            >
                {app.text('Excel')}
            </MenuItem>
            <MenuDivider />
            <MenuItem
                disabled={disableDelete}
                onClick={() =>
                    record.backlinks.length ? undefined : _onAction('delete')
                }
                submenu={
                    record.backlinks.length && !disableDelete ? (
                        <TreeRecordWithLinksSubmenu
                            record={record}
                            onConfirm={() => _onAction('delete')}
                        />
                    ) : undefined
                }
            >
                {app.text('Delete')}
            </MenuItem>
        </Menu>
    )
})

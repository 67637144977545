//
// ClassDataWorksheet
//
// Work on a Class

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../stores'
import { useMousePopover } from '../../../hooks/useMousePopover'

import { View, VView, HView, Spacer } from '../../../appview'
import { Header, Icon, Text, TextInput, Item } from '../../../components'
import { DragDropDefinitionFieldItem } from '../../../dragdrop/DragDropDefinitionFieldItem'
import { maxChars } from '../../../utils/text'
import { LabeledField } from '../../components'
import { ClassFieldMenu } from '../../../menus'
import { RecordDefinitionAddFieldPanel, LayoutPanel } from '../../../panels'
import { VALIDATION } from '../../../utils/validation'
import { duckField, validateField } from '../../../stores/data/validators'
import { validName } from '../../../utils/text'

import { LayoutBuilder } from '.'

export const ClassDataWorksheet = observer(function ClassDataWorksheet({ worksheet }) {
    const { app, data, view } = useStore()

    const class_ = worksheet.item
    const language = view.environment.get('language')
    const namerenderkey = class_.gid + '.name'
    const labelrenderkey = class_.gid + '.label.' + language

    const [selectedGid, setSelectedGid] = useState(null)

    const ClassFieldContextMenu = useMousePopover(ClassFieldMenu)
    const AddFieldContextMenu = useMousePopover(RecordDefinitionAddFieldPanel)

    const selectModified = result => {
        const modified_class = data.classes.get(result['class'])
        if (modified_class) {
            view.schematree.selectItem({ id: modified_class.gid, item: modified_class })
            view.schemaquery.refetch()
        }
        return result
    }

    const duckRequiredTextField = duckField('text', {}, true)
    const requiredValidator = value => {
        return validateField(value, duckRequiredTextField, worksheet.language)
    }

    const onNameChange = value => {
        class_.setName(value)
    }
    const onNameBlur = value => {
        if (requiredValidator(value).result !== VALIDATION.SUCCESS) {
            // TODO: CC-204 resetName
        } else {
            class_.setName(value.trim())
            class_.commitIfModified().then(result => selectModified(result))
        }
    }

    const onLabelChange = value => {
        class_.setLabel(value, language)
    }
    const onLabelBlur = value => {
        class_.setLabel(value.trim(), language)
        class_.commitIfModified().then(result => selectModified(result))
    }

    const onDropField = (dragitem, dropgid, dropzone) => {
        // if dropped on field -> check dropzone
        // else dropped on field.add -> move/add at end
        let before_field = null
        let use_next_field = false
        class_.fields.forEach(fieldgid => {
            if (fieldgid === dropgid && dropzone === 'top') before_field = fieldgid
            if (use_next_field) {
                before_field = fieldgid
                use_next_field = false
            }
            if (fieldgid === dropgid && dropzone === 'bottom') use_next_field = true
        })
        // if field is in class -> move it
        // else, add it
        if (class_.fields.includes(dragitem.id)) {
            class_
                .moveField(dragitem.id, before_field)
                .then(result => selectModified(result))
        } else {
            class_
                .addField(dragitem.id, before_field)
                .then(result => selectModified(result))
        }
    }
    const onAddField = fieldgid => {
        AddFieldContextMenu.hide()
        class_.addField(fieldgid).then(result => selectModified(result))
    }
    const onRemoveField = () => {
        class_.removeField(selectedGid).then(result => selectModified(result))
    }
    const onGotoField = () => {
        view.schematree.expandItem({ id: 'field' })
        const fielditem = view.schematree.findItemById(selectedGid)
        if (fielditem) {
            view.schematree.selectItem(fielditem)
        }
    }

    const onClassAction = action => {
        const actions = {
            remove_field: onRemoveField,
            goto_field: onGotoField,
        }
        if (!(action in actions)) {
            console.log(`onClassAction: unhandled action '${action}'`)
            return
        }
        ClassFieldContextMenu.hide()
        if (actions[action]) {
            actions[action]()
        }
    }

    const all_fields = worksheet.getClassFields(class_)

    let fields_items = []
    all_fields.forEach(field => {
        const title = worksheet.layoutstore.getItemTitle(field, language)
        fields_items.push(
            <DragDropDefinitionFieldItem
                key={field.gid}
                itemgid={field.gid}
                onDrop={onDropField}
            >
                <Item
                    className="cc-TreeItem"
                    indent={0}
                    selected={selectedGid === field.gid}
                    onMouseDown={() => {
                        setSelectedGid(selectedGid === field.gid ? null : field.gid)
                    }}
                    onContextMenu={e => {
                        setSelectedGid(field.gid)
                        ClassFieldContextMenu.onShow(e)
                    }}
                    title={field.name}
                >
                    <Icon className="itemtype" name={'field'} size={'text'} />
                    {maxChars(title, 40)}
                </Item>
            </DragDropDefinitionFieldItem>
        )
    })
    fields_items.push(
        <DragDropDefinitionFieldItem
            key="field.add"
            itemgid="field.add"
            onDrop={onDropField}
        >
            <Item
                className="cc-TreeItem"
                indent={0}
                selected={false}
                onClick={e => {
                    setSelectedGid(null)
                    AddFieldContextMenu.onShow(e)
                }}
                onContextMenu={e => {
                    setSelectedGid(null)
                    AddFieldContextMenu.onShow(e)
                }}
            >
                <Icon className="itemtype" name="plus" size="text" />
                <span className="cc-dimmed">{app.text('Add a field')}</span>
            </Item>
        </DragDropDefinitionFieldItem>
    )

    const Fields = <div className="cc-Field cc-Fieldsfield">{fields_items}</div>

    const onUpdateLayoutComponentInMemory = (component_gid, name, value) => {
        worksheet.layoutstore.updateComponentInMemory(component_gid, name, value)
    }

    const onUpdateLayoutComponent = (component_gid, name, value) => {
        const updated_component = worksheet.layoutstore.updateComponentInMemory(
            component_gid,
            name,
            value
        )
        class_
            .modifyLayoutComponent(component_gid, updated_component)
            .then(result => {
                worksheet.layoutstore.refetch()
                return result
            })
            .then(result => selectModified(result))
    }

    const onCreateLayoutComponent = (type, parent_gid, before_gid, options) => {
        const new_component = worksheet.layoutstore.newComponent(type, options)
        class_
            .createLayoutComponent(
                new_component.type,
                parent_gid,
                before_gid,
                null, // name
                new_component.options
            )
            .then(result => {
                worksheet.layoutstore.refetch()
                return result
            })
            .then(result => selectModified(result))
    }

    const onMoveLayoutComponent = (component_gid, parent_gid, before_gid) => {
        class_
            .moveLayoutComponent(component_gid, parent_gid, before_gid)
            .then(result => {
                worksheet.layoutstore.refetch()
                return result
            })
            .then(result => selectModified(result))
    }

    const onDeleteLayoutComponent = component_gid => {
        class_
            .deleteLayoutComponent(component_gid)
            .then(result => {
                worksheet.layoutstore.refetch()
                return result
            })
            .then(result => selectModified(result))
    }

    const onLayoutAction = (action, params) => {
        const actions = {
            update_component_property_in_memory: onUpdateLayoutComponentInMemory,
            update_component_property: onUpdateLayoutComponent,
            create_component: onCreateLayoutComponent,
            move_component: onMoveLayoutComponent,
            delete_component: onDeleteLayoutComponent,
        }
        if (!(action in actions)) {
            console.log(`onLayoutAction: unhandled action '${action}'`)
            return
        }
        if (actions[action]) {
            actions[action](...params)
        }
    }

    const WorksheetLayoutPreview = worksheet.layoutstore.layout ? (
        <LayoutBuilder
            component={worksheet.layoutstore.layout.components.get(
                worksheet.layoutstore.layout.root
            )}
            components={worksheet.layoutstore.layout.components}
            layout={worksheet.layoutstore.layout}
            record={null}
            worksheet={worksheet}
        />
    ) : (
        <Text>{app.text('No layout assigned.')}</Text>
    )

    return (
        <View className="worksheet">
            <HView>
                <VView>
                    <Header>{app.text('Class properties')}</Header>
                    <LabeledField
                        label={app.text('name')}
                        is_localized={false}
                        language={language}
                        placeholder={app.text(
                            'Reference name, only lower-case letters, digits, and underscore allowed.'
                        )}
                    >
                        <div className="ws-text">
                            <TextInput
                                multiline={false}
                                enabled={true}
                                validate={requiredValidator}
                                autoConvert={validName}
                                value={class_.name}
                                onChange={e => onNameChange(e.target.value)}
                                onBlur={e => onNameBlur(e.target.value)}
                                renderkey={namerenderkey}
                            />
                        </div>
                    </LabeledField>
                    <LabeledField
                        className="lc-subtitle"
                        label={app.text('label')}
                        is_localized={true}
                        language={language}
                        placeholder={app.text('Label, localized.')}
                    >
                        <div className="ws-text">
                            <TextInput
                                multiline={false}
                                enabled={true}
                                value={class_.label.get(language)}
                                language={language}
                                onChange={e => onLabelChange(e.target.value)}
                                onBlur={e => onLabelBlur(e.target.value)}
                                renderkey={labelrenderkey}
                            />
                        </div>
                    </LabeledField>
                    <ClassFieldContextMenu.Panel onAction={onClassAction} />
                    <AddFieldContextMenu.Panel onAddField={onAddField} />
                    <LabeledField
                        label={app.text('fields')}
                        is_localized={false}
                        language={language}
                        placeholder={app.text('No fields added yet.')}
                        show_placeholder={fields_items.length === 0}
                    >
                        {Fields}
                    </LabeledField>
                </VView>
                <Spacer size={20} />
                <VView grow>
                    <Header>{app.text('Layout')}</Header>
                    <div className="ws-field layout-component">
                        <div className="ws-label">
                            <span className="label">{app.text('layout')}</span>
                        </div>
                        <div className="cc-Field cc-Layoutfield">
                            <HView>
                                <LayoutPanel
                                    className="layout-tree"
                                    worksheetstore={worksheet}
                                    onAction={onLayoutAction}
                                />
                                <Spacer size={20} />
                                <VView grow className="layout-preview">
                                    {WorksheetLayoutPreview}
                                </VView>
                            </HView>
                        </div>
                    </div>
                </VView>
            </HView>
        </View>
    )
})

//
// FileWorksheet
//
// Files show only name, path, and icon if they can't be transformed.

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../stores'

import { HView, VView, View, VScrollView } from '../../../appview'
import { Icon, Header, Text, TextInput } from '../../../components'
import { validAssetName } from '../../../utils/text'

export const FileWorksheet = observer(function FileWorksheet({ worksheet }) {
    const { app } = useStore()

    const onChangeAssetName = event => {
        worksheet.asset.setProperty('name', event.target.value + assetext)
    }
    const onBlurAssetName = event => {
        let newname = event.target.value.trim() + assetext
        worksheet.asset.setProperty('name', newname)
        worksheet.asset.commitIfModified()
    }
    const renderkey = worksheet.asset.gid + '.' + worksheet.language
    const _assetnameparts = worksheet.asset.publicname.split('.')
    const assetext = _assetnameparts.length > 1 ? '.' + _assetnameparts.pop() : ''
    const assetname = _assetnameparts.join('.')

    const Worksheet = (
        <>
            <div
                className="dam-preview-file"
                style={{
                    width: 240,
                    height: 240,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Icon size={80} name={worksheet.asset.iconname} />
            </div>
        </>
    )

    return (
        <VScrollView>
            <VView className="worksheet">
                <Header>{app.text('Asset properties')}</Header>
                <HView>
                    <View grow className="cc-Field ws-field">
                        <div className="ws-label">
                            <span className="label">{app.text('name')}</span>
                        </div>
                        <div className="ws-inline">
                            <TextInput
                                autoConvert={validAssetName}
                                onChange={onChangeAssetName}
                                onBlur={onBlurAssetName}
                                value={assetname}
                                renderkey={renderkey}
                                style={{ minWidth: 200 }}
                            />
                            <Text>{assetext}</Text>
                        </div>
                    </View>
                </HView>
                <HView>{Worksheet}</HView>
            </VView>
        </VScrollView>
    )
})

//
// Text
//
// A text-input.

import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import { TextInput } from '../../../../components'
import { VALIDATION } from '../../../../utils/validation'
import { validateField } from '../../../../stores/data/validators'
import { autoCompleter } from '../../../../stores/data/autocompleters'

export const TextField = observer(function TextField(props) {
    const {
        className,
        record,
        field,
        fromClass,
        component,
        enabled,
        force_readonly,
        renderkey,
        worksheet,
        ...other
    } = props

    let classes = 'cc-Field cc-Textfield ws-text'
    if (!enabled) classes += ' cc-disabled'
    if (field.type === 'code') classes += ' ws-code'

    if (className) classes += ' ' + className
    const fieldvalue =
        record.localized_fields && record.localized_fields.get(field.name)
    const multiline = field.is_multiline

    let formattedfieldvalue =
        fieldvalue !== null && fieldvalue !== undefined ? fieldvalue.toString() : null
    if (
        ['decimal'].includes(field.type) &&
        fieldvalue !== null &&
        fieldvalue !== undefined &&
        field.options.has('decimals')
    ) {
        if (typeof fieldvalue === 'string') {
            if (fieldvalue.length) {
                formattedfieldvalue = parseFloat(fieldvalue).toFixed(
                    parseInt(field.options.get('decimals'))
                )
            }
        } else {
            formattedfieldvalue = fieldvalue.toFixed(
                parseInt(field.options.get('decimals'))
            )
        }
    }

    const validator = fieldvalue => {
        return validateField(fieldvalue, field, record.language)
    }

    const fieldAutoCompleter = autoCompleter(field, record.language)
    const autoComplete = useMemo(() => {
        return fieldAutoCompleter
            ? async q => {
                  return fieldAutoCompleter(q)
              }
            : null
    }, [fieldAutoCompleter])

    const onChange = event => {
        record.setField(field, event.target.value)
    }
    const onBlur = event => {
        if (
            validateField(event.target.value, field, record.language).result ===
            VALIDATION.ERROR
        ) {
            record.resetField(field)
        } else {
            record.setField(field, event.target.value.trim())
            record.commitIfModified()
        }
    }

    const lang = field.is_localized ? { language: worksheet.language } : {}

    return (
        <div className={classes} {...other}>
            <TextInput
                multiline={multiline}
                enabled={enabled}
                value={formattedfieldvalue}
                {...lang}
                onChange={onChange}
                onBlur={onBlur}
                renderkey={renderkey}
                validate={validator}
                autoComplete={autoComplete}
            />
        </div>
    )
})

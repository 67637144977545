//
// InitialFieldOption
//
// Field option 'initial'

import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'

import { TextInput } from '../../../../components'
import { LabeledField } from '../../../components'

import { VALIDATION } from '../../../../utils/validation'
import { validateField } from '../../../../stores/data/validators'
import { autoCompleter } from '../../../../stores/data/autocompleters'

export const InitialFieldOption = observer(function InitialFieldOption({
    field,
    initialField,
    selectModified,
    worksheet,
    onAction,
    setSelected,
    renderkeyprefix,
}) {
    const { app } = useStore()

    const initialValidator = fieldvalue => {
        return validateField(fieldvalue, initialField, worksheet.language)
    }

    const fieldAutoCompleter = autoCompleter(initialField, worksheet.language)
    const autoComplete = useMemo(() => {
        return fieldAutoCompleter
            ? async q => {
                  return fieldAutoCompleter(q)
              }
            : null
    }, [fieldAutoCompleter])

    if (!field) return null
    if (!initialField) return null

    const onChangeFieldInitial = event => {
        field.setProperty('initial', event.target.value, worksheet.language)
    }
    const onBlurFieldInitial = event => {
        if (
            validateField(event.target.value, initialField, worksheet.language)
                .result === VALIDATION.ERROR
        ) {
            field.resetInitial(worksheet.language)
        } else {
            field.setProperty('initial', event.target.value.trim(), worksheet.language)
            field.commitIfModified().then(result => selectModified(result))
        }
    }

    return (
        <LabeledField label={app.text('initial value')}>
            <TextInput
                onChange={onChangeFieldInitial}
                onBlur={onBlurFieldInitial}
                value={field.initial.get(worksheet.language)}
                language={worksheet.language}
                renderkey={renderkeyprefix + '.initial'}
                validate={initialValidator}
                autoComplete={autoComplete}
            />
        </LabeledField>
    )
})

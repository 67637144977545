//
// useElementWidth
//
// When you need the width of the current element, e.g. to resize an image.
//
// Usage:
//
//     const [ref, width] = useElementWidth(600)
//     return (
//         <View ref={ref}>
//             Width: {width}
//         </View>
//     )

import { useRef, useState, useLayoutEffect } from 'react'

export const useElementWidth = initialwidth => {
    const [width, setWidth] = useState(initialwidth)
    const ref = useRef(null)

    const resizeobserver = useRef(null)

    useLayoutEffect(() => {
        if (!ref.current) return

        if (resizeobserver.current && ref.current) {
            resizeobserver.current.unobserve(ref.current)
        }

        resizeobserver.current = new ResizeObserver(entries => {
            // window.requestAnimationFrame tries to prevent
            // 'ResizeObserver loop completed with undelivered notifications.'
            window.requestAnimationFrame(() => {
                if (!ref.current) return
                for (let entry of entries) {
                    setWidth(Math.floor(entry.contentRect.width))
                }
            })
        })
        const element = ref.current
        resizeobserver.current.observe(element)

        return () => {
            resizeobserver.current.unobserve(element)
        }
    }, [])

    return [ref, width]
}

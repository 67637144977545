//
// DeletedTreeRecordMenu
//
// Actions on deleted records in the TreeNavigator
// Only feed it deleted records that are not links.

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Menu, MenuDivider, MenuItem } from '../components'
import { TreeRecordWithLinksSubmenu } from './TreeRecordWithLinksSubmenu'

export const DeletedTreeRecordMenu = observer(function DeletedTreeRecordMenu({
    record,
    onAction,
}) {
    const { app, data } = useStore()

    if (!record) return null

    // can't undelete when parent is still deleted
    const parentrecord = data.records.get(record.parent)
    const disableUndelete = parentrecord && parentrecord.status === 'deleted'

    const _onAction = action => {
        onAction && onAction(action)
    }

    return (
        <Menu>
            <MenuItem
                disabled={disableUndelete}
                onClick={() =>
                    record.backlinks.length ? undefined : _onAction('undelete')
                }
                submenu={
                    record.backlinks.length && !disableUndelete ? (
                        <TreeRecordWithLinksSubmenu
                            record={record}
                            onConfirm={() => _onAction('undelete')}
                        />
                    ) : undefined
                }
            >
                {app.text('Undelete')}
            </MenuItem>
            <MenuDivider />
            <MenuItem
                className="cc-danger"
                onClick={() =>
                    record.backlinks.length ? undefined : _onAction('permanent_delete')
                }
                submenu={
                    record.backlinks.length ? (
                        <TreeRecordWithLinksSubmenu
                            className="cc-danger"
                            record={record}
                            onConfirm={() => _onAction('permanent_delete')}
                        />
                    ) : undefined
                }
            >
                {app.text('Permanent delete')}
            </MenuItem>
        </Menu>
    )
})

//
// FieldpickerFieldOptions
//
// Field options for type 'fieldpicker'

import React from 'react'
import { useStore } from '../../../../stores'
import { observer } from 'mobx-react-lite'
import { Select } from '../../../../components'
import { LabeledField } from '../../../components'

export const FieldpickerFieldOptions = observer(function FieldpickerFieldOptions({
    field,
    selectModified,
    worksheet,
    onAction,
    setSelected,
    renderkeyprefix,
}) {
    const { app } = useStore()

    if (!field) return null

    let fields = field ? field.options.get('fields') : undefined
    if (!fields) fields = 'all'

    const onSelect = fieldsoption => {
        field.setOption('fields', fieldsoption)
        field.commitIfModified().then(result => selectModified(result))
    }

    return (
        <LabeledField label={app.text('fields')} is_localized={false}>
            <Select
                value={fields}
                options={{
                    all: app.text('All fields'),
                    record: app.text('Record fields'),
                    child: app.text('Child fields'),
                }}
                onChange={onSelect}
            />
        </LabeledField>
    )
})

//
// InviteScreen
//
// Invited? Log in or sign up for an account

import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'
import { projectMemberKey } from '../stores/data/ProjectMemberData'

import { SiteScreen } from './SiteScreen'
import { UnicatSitePanel, LoginPanel, SignUpPanel } from '../panels'
import { VView, Spacer } from '../appview'
import { Bar, Button, Property, Header, Text } from '../components'

export const InviteScreen = observer(function InviteScreen() {
    const store = useStore()
    const { app, data } = store

    const invitee_gid = store.location_info['usergid']
    const project_gid = store.location_info['projectgid']

    const [inviteeGid, setInviteeGid] = useState(null)
    const [projectGid, setProjectGid] = useState(null)

    // title can change to forgot password/reset password
    const [title, setTitle] = useState(app.text('Unicat Invite'))

    const STATES = {
        INIT: 0,
        INVALID: 1,
        LOGOUT_OR_IGNORE: 2, // logged in, invite is for different user
        ACCEPT_OR_DECLINE: 3,
        DECLINE: 4,
        DECLINED: 5,
        ACCEPT_LOGIN: 6,
        ACCEPT_SIGNUP: 7,
        ACCEPT_DIRECT: 8, // logged in, user is invitee
        ACCEPTED: 9,
    }
    const [state, setState] = useState(STATES.INIT)

    useEffect(() => {
        let isMounted = true
        async function asyncEffect() {
            store
                .initInvite(invitee_gid, project_gid)
                .then(result => {
                    if (isMounted) {
                        setInviteeGid(result['invitee'])
                        setProjectGid(result['project'])
                        if (store.user && store.user.gid !== result['invitee']) {
                            setState(STATES.LOGOUT_OR_IGNORE)
                        } else {
                            const invitee_project_meta = store.data.project_members.get(
                                projectMemberKey({
                                    project_gid: result['project'],
                                    user_gid: result['invitee'],
                                })
                            )
                            if (invitee_project_meta.status === 'active') {
                                if (store.user) {
                                    setState(STATES.ACCEPTED)
                                } else {
                                    setState(STATES.INVALID)
                                }
                            } else {
                                setState(STATES.ACCEPT_OR_DECLINE)
                            }
                        }
                    }
                })
                .catch(error => {
                    if (isMounted) {
                        setState(STATES.INVALID)
                    }
                })
        }
        if (invitee_gid && project_gid) asyncEffect()
        return () => (isMounted = false)
    }, [store, store.user, invitee_gid, project_gid, STATES.LOGOUT_OR_IGNORE, STATES.ACCEPT_OR_DECLINE, STATES.ACCEPTED, STATES.INVALID])

    const user = store.user
    const invitee = inviteeGid ? data.users.get(inviteeGid) : null
    const project = projectGid ? data.projects.get(projectGid) : null
    const owner = project ? data.users.get(project.owner) : null
    const invitee_project_meta = data.project_members.get(
        projectMemberKey({ project_gid: projectGid, user_gid: inviteeGid })
    )
    // bail early, shouldn't happen
    if (
        state !== STATES.INIT &&
        state !== STATES.INVALID &&
        (!invitee || !project || !owner || !invitee_project_meta)
    ) {
        throw new Error('Invite screen validity/data mismatch.')
    }

    const isLoggedIn = user ? true : false
    const userIsInvitee = isLoggedIn && invitee && invitee.gid === user.gid

    const acceptInvitation = () => {
        data.actions
            .accept_invite(projectGid)
            .then(result => store.setLocation(store.LOCATIONS.PROJECT, projectGid))
    }

    const declineInvitation = () => {
        data.actions
            .anonymous_decline_invite(inviteeGid, projectGid)
            .then(result => {
                setState(STATES.DECLINED)
            })
            .catch(error => {})
    }

    const onAccept = () => {
        if (state === STATES.ACCEPT_OR_DECLINE) {
            setTitle(app.text('Unicat Accept invite'))
            if (!isLoggedIn) {
                if (invitee.status === 'invited') {
                    setState(STATES.ACCEPT_SIGNUP)
                } else {
                    setState(STATES.ACCEPT_LOGIN)
                }
            } else if (userIsInvitee) {
                setState(STATES.ACCEPT_DIRECT)
                acceptInvitation()
            }
        }
    }

    const onDecline = () => {
        if (state === STATES.ACCEPT_OR_DECLINE) {
            setTitle(app.text('Unicat Decline invite'))
            setState(STATES.DECLINE)
            declineInvitation()
        }
    }

    const onGoBack = () => {
        if (state !== STATES.ACCEPT_OR_DECLINE) {
            setTitle(app.text('Unicat Invite'))
            setState(STATES.ACCEPT_OR_DECLINE)
        }
    }

    const onLogout = () => {
        store.logout()
    }

    const onGotoProject = () => {
        if (!isLoggedIn) {
            store.setLocation(store.LOCATIONS.WELCOME)
        } else if (project) {
            store.setLocation(store.LOCATIONS.PROJECT, project.gid)
        } else {
            store.setLocation(store.LOCATIONS.ACCOUNT)
        }
    }

    const onGotoUnicat = () => {
        if (!isLoggedIn) {
            store.setLocation(store.LOCATIONS.WELCOME)
        } else {
            store.autoLocation()
        }
    }

    let Activity
    let inviteMessage =
        project && owner
            ? app.text('You have been invited to the project "{project}" by {owner}.', {
                  project: project.name,
                  owner: owner.displayname,
              })
            : undefined
    switch (state) {
        case STATES.INIT:
            Activity = (
                <Property label={''}>
                    <Text>
                        {app.text('Checking the invite link, one moment please…')}
                    </Text>
                </Property>
            )
            break
        case STATES.LOGOUT_OR_IGNORE:
            Activity = (
                <>
                    <Property label={''}>
                        <Text>{inviteMessage}</Text>
                    </Property>
                    <Spacer size={10} />
                    <Property label={''}>
                        <VView>
                            <Header>
                                {app.text('This invitation is for a different user.')}
                            </Header>

                            <Text>
                                {app.text(
                                    `If you want to accept this invitation, please log
                                     out of this account.`
                                )}
                            </Text>
                            <Spacer size={10} />
                            <Bar raised>
                                <Button action onClick={onLogout}>
                                    {app.text('Log out')}
                                </Button>
                            </Bar>
                            <Spacer size={20} />
                            <Text>
                                {app.text(
                                    `If you want to ignore this invitation, you can
                                     continue to the workspace for this account.`
                                )}
                            </Text>
                            <Spacer size={10} />
                            <Bar raised>
                                <Button action onClick={onGotoUnicat}>
                                    {app.text('Continue to Unicat')}
                                </Button>
                            </Bar>
                        </VView>
                    </Property>
                </>
            )
            break
        case STATES.ACCEPT_OR_DECLINE:
            Activity = (
                <>
                    <Property label={''}>
                        <Text>{inviteMessage}</Text>
                    </Property>
                    <Spacer size={10} />
                    <Property label={''}>
                        <Bar raised>
                            <Button action onClick={onAccept}>
                                {app.text('Accept invitation')}
                            </Button>
                        </Bar>
                    </Property>
                    <Spacer size={20} />
                    <Property label={''}>
                        <Bar raised>
                            <Button action onClick={onDecline}>
                                {app.text('Decline invitation')}
                            </Button>
                        </Bar>
                    </Property>
                </>
            )
            break
        case STATES.DECLINE:
            Activity = (
                <>
                    <Property label={''}>
                        <Text>{inviteMessage}</Text>
                    </Property>
                    <Property label={''}>
                        <Text>
                            {app.text('Declining the invitation, one moment please…')}
                        </Text>
                    </Property>
                </>
            )
            break
        case STATES.DECLINED:
            Activity = (
                <>
                    <Property label={''}>
                        <Text>
                            {app.text('The invitation was declined, thank you.')}
                        </Text>
                    </Property>
                    <Spacer size={20} />
                    <Property label={''}>
                        <Bar raised>
                            <Button action onClick={onGotoUnicat}>
                                {app.text('Continue to Unicat')}
                            </Button>
                        </Bar>
                    </Property>
                </>
            )
            break
        case STATES.ACCEPT_LOGIN:
            Activity = (
                <>
                    <Property label={''}>
                        <Text>{inviteMessage}</Text>
                    </Property>
                    <Spacer size={10} />
                    <Property label={''}>
                        <Text>
                            {app.text('Please log in to accept this invitation.')}
                        </Text>
                    </Property>
                    <Spacer size={10} />
                    <LoginPanel
                        username={invitee.username}
                        onSuccessfulLogin={acceptInvitation}
                    />
                    <Spacer size={20} />
                    <Property label={''}>
                        <Text className="link" onClick={() => onGoBack()}>
                            {app.text('Go back to invite screen.')}
                        </Text>
                    </Property>
                </>
            )
            break
        case STATES.ACCEPT_SIGNUP:
            Activity = (
                <>
                    <Property label={''}>
                        <Text>{inviteMessage}</Text>
                    </Property>
                    <Spacer size={10} />
                    <Property label={''}>
                        <Text>
                            {app.text(
                                `Please sign up to accept this invitation. Signing up is
                                 free.`
                            )}
                        </Text>
                    </Property>
                    <Spacer size={10} />
                    <SignUpPanel
                        email={invitee.email}
                        emailReadonly={true}
                        onSuccessfulSignUp={acceptInvitation}
                    />
                    <Spacer size={20} />
                    <Property label={''}>
                        <Text className="link" onClick={() => onGoBack()}>
                            {app.text('Go back to invite screen.')}
                        </Text>
                    </Property>
                </>
            )
            break
        case STATES.ACCEPT_DIRECT:
            Activity = (
                <>
                    <Property label={''}>
                        <Text>{inviteMessage}</Text>
                    </Property>
                    <Property label={''}>
                        <Text>
                            {app.text('Accepting the invitation, one moment please…')}
                        </Text>
                        <Text>{app.text('Redirecting to the project…')}</Text>
                    </Property>
                </>
            )
            break
        case STATES.ACCEPTED:
            Activity = (
                <>
                    <Property label={''}>
                        <Text>
                            {app.text(
                                'The invitation was already accepted, thank you.'
                            )}
                        </Text>
                    </Property>
                    <Spacer size={20} />
                    <Property label={''}>
                        <Bar raised>
                            <Button action onClick={onGotoProject}>
                                {app.text('Continue to Unicat')}
                            </Button>
                        </Bar>
                    </Property>
                </>
            )
            break
        case STATES.INVALID:
        default:
            Activity = (
                <>
                    <Property label={''}>
                        <Text>
                            {app.text(
                                'The link you followed does not match any invitation, sorry.'
                            )}
                        </Text>
                    </Property>
                    <Spacer size={20} />
                    <Property label={''}>
                        <Text className="link" onClick={() => onGotoUnicat()}>
                            {app.text('Continue to Unicat')}
                        </Text>
                    </Property>
                </>
            )
            break
    }

    return (
        <SiteScreen>
            <VView className="form">
                <UnicatSitePanel has_link={true} title={title} />
                {Activity}
            </VView>
        </SiteScreen>
    )
})

//
// PimWorkspace
//
// Manage Product Information, we use a navigator/worksheet/inspector setup.

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useDrop } from 'react-dnd'
import { useStore } from '../stores'
import { usePersistentState } from '../hooks/usePersistentState'

import { DragTypes } from '../dragdrop/DragTypes'
import { NativeTypes } from 'react-dnd-html5-backend'

import { VView, HView, Spacer, Divider, VScrollView } from '../appview'
import { WorkspaceView } from './WorkspaceView'
import { Button, Icon } from '../components'
import {
    EnvironmentPanel,
    RecordPinboardPanel,
    RecordSearchPanel,
    AssetPinboardPanel,
    ExcelImportPanel,
} from '../panels'
import { TreeNavigator, RecordWorksheet, RecordInspectorSwitcher } from './pim'
import { maxChars } from '../utils/text'

export const PimWorkspace = observer(function PimWorkspace({
    user,
    project,
    viewkey,
    grow,
    ...props
}) {
    const { app, data, view } = useStore()

    const sep = (
        <span key="_" className="breadcrumbs-separator">
            {app.text('›')}
        </span>
    )

    const tree = view.pimtree
    const worksheet = view.pimworksheet
    const pimquery = view.pimquery

    const [recordPinboardVisible, setRecordPinboardVisible] = usePersistentState(
        viewkey + '/recordPinboardVisible',
        true
    )

    const [assetPinboardVisible, setAssetPinboardVisible] = usePersistentState(
        viewkey + '/assetPinboardVisible',
        true
    )

    const [navigatorTab, setNavigatorTab] = usePersistentState(
        viewkey + '/navigatorTab',
        'tree'
    )

    const [inspectorTab, setInspectorTab] = usePersistentState(
        viewkey + '/inspectorTab',
        'channels'
    )

    let NavigationToolbar
    NavigationToolbar = (
        <>
            <Button
                icon
                active={navigatorTab === 'tree' ? true : false}
                onClick={() => setNavigatorTab('tree')}
                title={app.text('Tree')}
            >
                <Icon name="tree" size={1} />
            </Button>
            <Button
                icon
                active={navigatorTab === 'search' ? true : false}
                onClick={() => setNavigatorTab('search')}
                title={app.text('Search')}
            >
                <Icon name="search" size={1} />
            </Button>
        </>
    )

    const onSelect = recordgid => {
        const selectedRecord = data.records.get(recordgid)
        if (selectedRecord) {
            worksheet.setRecord(selectedRecord)
            tree.datamanager.setRecord(selectedRecord)
        }
    }

    let Navigator
    switch (navigatorTab) {
        case 'tree':
            Navigator = (
                <TreeNavigator
                    tree={tree}
                    firstindex={view._pimtree.topindex}
                    windowsize={view._pimtree.windowsize}
                />
            )
            break
        case 'search':
            Navigator = (
                <RecordSearchPanel
                    querystore={pimquery}
                    preview={false}
                    showMatches={true}
                    recordactions={true}
                    multiselect={true}
                    onSelect={onSelect}
                    selectedRecordGid={worksheet.record ? worksheet.record.gid : null}
                    style={{ marginTop: 5 }}
                />
            )
            break
        default:
            Navigator = <VView grow />
            break
    }

    let WorksheetToolbar
    if (worksheet.path.length) {
        WorksheetToolbar = worksheet.path
            .map(pathrecord => {
                const name =
                    pathrecord.localized_title && pathrecord.localized_title.length
                        ? pathrecord.localized_title
                        : app.text('[empty]')
                return (
                    <span
                        key={pathrecord.gid}
                        onClick={() => worksheet.setRecord(pathrecord)}
                    >
                        {maxChars(name, 30)}
                    </span>
                )
            })
            .reduce((prev, curr) => [prev, sep, curr])
    }

    let InspectorToolbar
    InspectorToolbar = (
        <>
            <Button
                icon
                active={inspectorTab === 'record' ? true : false}
                onClick={() => setInspectorTab('record')}
                title={app.text('Record info')}
            >
                <Icon name="product" size={1} />
            </Button>
            <Button
                icon
                active={inspectorTab === 'channels' ? true : false}
                onClick={() => setInspectorTab('channels')}
                title={app.text('Channels')}
            >
                <Icon name="channels" size={1} />
            </Button>
            <Button
                icon
                active={inspectorTab === 'fields' ? true : false}
                onClick={() => setInspectorTab('fields')}
                title={app.text('Fields')}
            >
                <Icon name="class" size={1} />
            </Button>
        </>
    )

    const Inspector = (
        <RecordInspectorSwitcher
            inspector={inspectorTab}
            worksheet={worksheet}
            viewkey={viewkey}
        />
    )

    const [
        { isOver: isOverRecordPinboardToggle, canDrop: canDropRecordPinboardToggle },
        dropRecordPinboardToggle,
    ] = useDrop({
        accept: [DragTypes.PIM_RECORD, DragTypes.RECORD_SELECTION],
        canDrop: () => view.pimpinboard.canAdd(),
        collect: monitor => {
            return {
                isOver: !!monitor.isOver(),
                canDrop: !!monitor.canDrop(),
            }
        },

        drop: item => {
            if (item.type === DragTypes.PIM_RECORD) {
                view.pimpinboard.addRecord(item.gid)
            } else if (item.type === DragTypes.RECORD_SELECTION) {
                if (!view.pimpinboard.exists(item.gid)) {
                    view.pimpinboard.addSelection(
                        item.selection,
                        item.selectionsize,
                        item.isFlat
                    )
                }
            }
            setRecordPinboardVisible(true)
            return { receiver: 'RecordPinboardToggle', item: item }
        },
    })

    const RecordPinboard = recordPinboardVisible ? (
        <>
            <Divider />
            <RecordPinboardPanel
                pinboard={view.pimpinboard}
                selectedRecord={worksheet.record}
            />
        </>
    ) : undefined

    let classesRecordPinboardToggle = 'pinboardtoggle-drop'
    if (isOverRecordPinboardToggle) {
        classesRecordPinboardToggle += ' drag-over'
        if (!canDropRecordPinboardToggle)
            classesRecordPinboardToggle += ' drop-rejected'
    }
    if (!recordPinboardVisible && !view.pimpinboard.canAdd()) {
        classesRecordPinboardToggle += ' pinboard-full'
    }

    const RecordPinboardToggle = (
        <div className={classesRecordPinboardToggle} ref={dropRecordPinboardToggle}>
            <Button
                icon
                active={recordPinboardVisible}
                title={app.text(
                    recordPinboardVisible
                        ? 'Hide record pinboard panel'
                        : 'Show record pinboard panel'
                )}
                onClick={() => setRecordPinboardVisible(!recordPinboardVisible)}
            >
                <Icon name="product" size={1} />
                <Icon name="pin" size={1} />
            </Button>
        </div>
    )

    const [
        { isOver: isOverAssetPinboardToggle, canDrop: canDropAssetPinboardToggle },
        dropAssetPinboardToggle,
    ] = useDrop({
        accept: [DragTypes.DAM_ASSET, DragTypes.ASSET_SELECTION],
        canDrop: () => view.dampinboard.canAdd(),
        collect: monitor => {
            return {
                isOver: !!monitor.isOver(),
                canDrop: !!monitor.canDrop(),
            }
        },

        drop: item => {
            if (item.type === DragTypes.DAM_ASSET) {
                view.dampinboard.addAsset(item.gid)
            } else if (item.type === DragTypes.ASSET_SELECTION) {
                if (!view.dampinboard.exists(item.gid)) {
                    view.dampinboard.addSelection(
                        item.selection,
                        item.selectionsize,
                        item.isFlat
                    )
                }
            }
            setAssetPinboardVisible(true)
            return { receiver: 'AssetPinboardToggle', item: item }
        },
    })

    const AssetPinboard = assetPinboardVisible ? (
        <>
            <Divider />
            <AssetPinboardPanel pinboard={view.dampinboard} selectedAsset={null} />
        </>
    ) : undefined

    let classesAssetPinboardToggle = 'pinboardtoggle-drop'
    if (isOverAssetPinboardToggle) {
        classesAssetPinboardToggle += ' drag-over'
        if (!canDropAssetPinboardToggle) classesAssetPinboardToggle += ' drop-rejected'
    }
    if (!assetPinboardVisible && !view.dampinboard.canAdd()) {
        classesAssetPinboardToggle += ' pinboard-full'
    }

    const AssetPinboardToggle = (
        <div className={classesAssetPinboardToggle} ref={dropAssetPinboardToggle}>
            <Button
                icon
                active={assetPinboardVisible}
                title={app.text(
                    assetPinboardVisible
                        ? 'Hide asset pinboard panel'
                        : 'Show asset pinboard panel'
                )}
                onClick={() => setAssetPinboardVisible(!assetPinboardVisible)}
            >
                <Icon name="image" size={1} />
                <Icon name="pin" size={1} />
            </Button>
        </div>
    )

    const [
        { isOver: isOverExcelImportToggle, canDrop: canDropExcelImportToggle },
        dropExcelImportToggle,
    ] = useDrop({
        accept: NativeTypes.FILE,
        collect: monitor => {
            return {
                isOver: !!monitor.isOver(),
            }
        },

        drop: (item, monitor) => {
            // onFileSelection(item.files[0])
            // view.excelimport.upload(item.files[0])
            view.excelimport.setPanelVisible(true)
            return { receiver: 'ExcelImportToggle' }
        },
    })

    const ExcelImport = view.excelimport.visible ? (
        <>
            <Divider />
            <ExcelImportPanel excelimport={view.excelimport} />
        </>
    ) : undefined

    let classesExcelImportToggle = 'excelimporttoggle-drop'
    if (isOverExcelImportToggle) {
        classesExcelImportToggle += ' drag-over'
        if (!canDropExcelImportToggle) classesExcelImportToggle += ' drop-rejected'
    }

    const ExcelImportToggle = (
        <div className={classesExcelImportToggle} ref={dropExcelImportToggle}>
            <Button
                icon
                active={view.excelimport.visible}
                title={app.text(
                    view.excelimport.visible
                        ? 'Hide Excel import panel'
                        : 'Show Excel import panel'
                )}
                onClick={() => view.excelimport.setVisible(!view.excelimport.visible)}
            >
                <Icon name="table" size={1} />
                <Icon name="pin" size={1} />
            </Button>
        </div>
    )

    const NavigatorView = (
        <>
            <HView className="toolbar">{NavigationToolbar}</HView>
            <Divider />
            {Navigator}
            {RecordPinboard}
            {AssetPinboard}
            {ExcelImport}
        </>
    )
    const WorksheetView = (
        <>
            <div className="toolbar">{WorksheetToolbar}</div>
            <Divider />
            <VScrollView>
                <RecordWorksheet worksheet={worksheet} />
            </VScrollView>
        </>
    )
    const InspectorView = (
        <>
            <HView className="toolbar">{InspectorToolbar}</HView>
            <Divider />
            <VScrollView>{Inspector}</VScrollView>
        </>
    )
    const StatusbarView = (
        <>
            {RecordPinboardToggle}
            {AssetPinboardToggle}
            {ExcelImportToggle}
            <Spacer size={15} />
            <EnvironmentPanel
                project={project}
                environment={view.environment}
                updateEnvironment={view.updateEnvironment}
            />
        </>
    )

    return (
        <WorkspaceView
            grow={grow}
            className="workspace-pim"
            viewkey={viewkey}
            navigator={NavigatorView}
            worksheet={WorksheetView}
            inspector={InspectorView}
            statusbar={StatusbarView}
        />
    )
})

//
// Item
//
// An Item can be used in a list. It can be indented.

import React from 'react'

export const Item = React.forwardRef((props, ref) => {
    const { className, indent, selected, ...other } = props
    let classes = 'cc-Item'
    if (className) classes += ' ' + className
    if (indent) classes += ' cc-indent' + indent
    if (selected) classes += ' cc-selected'
    return <div className={classes} ref={ref} {...other} />
})

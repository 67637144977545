//
// QueryData
//
// A plain QueryData object to hold properties

import { makeObservable, observable, action } from 'mobx'

import { updateInstanceWithData } from './utils'

export class QueryData {
    gid = null
    type = null
    name = ''
    q = ''
    filter = ['and', '', []]

    _modifications = new Set()

    constructor(classdata, rootstore) {
        makeObservable(this, {
            type: observable,
            name: observable,
            q: observable,
            filter: observable,
            update: action.bound,
            updateQuery: action.bound,
            copyQuery: action.bound,
            deleteQuery: action.bound,
        })

        this._rootstore = rootstore
        this.update(classdata)
    }

    update = querydata => {
        updateInstanceWithData(this, querydata, ['gid', 'type', 'name', 'q', 'filter'])
    }

    updateQuery = () => {
        return this._rootstore._fetch('/queries/update', {
            query: this.gid,
            name: this.name,
            q: this.q,
            filter: this.filter,
        })
    }

    copyQuery = () => {
        return this._rootstore._fetch('/queries/copy', {
            query: this.gid,
        })
    }

    deleteQuery = () => {
        return this._rootstore
            ._fetch('/queries/delete', { query: this.gid })
            .then(result => {
                this._rootstore.data.queries.delete(this.gid)
                return result
            })
            .catch(error => {
                // in use - report to user?
            })
    }
}

//
// TreeAssetMenu
//
// Actions on assets in the TreeNavigator
// Only feed it not-deleted assets.

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'
import { useFileUpload } from '../hooks/useFileUpload'

import { Menu, MenuDivider, MenuItem } from '../components'

export const TreeAssetMenu = observer(function TreeAssetMenu({ asset, onAction }) {
    const { app, data } = useStore()

    const FileUpload = useFileUpload()

    const _onAction = action => {
        onAction && onAction(action)
    }

    const onFileSelection = file => {
        if (asset && !asset.is_file && file.type) {
            data.actions.assets
                .upload(file, asset.gid)
                .then(newassetgid => {
                    onAction && onAction('uploaded', newassetgid)
                })
                .catch(error => {})
        }
    }

    if (!asset) return null

    // can't delete root
    const disableDelete = asset.pathname === '/'

    return asset.is_file ? (
        <Menu>
            <MenuItem onClick={() => _onAction('download')}>
                {app.text('Download')}
            </MenuItem>
            <MenuDivider />
            <MenuItem disabled={disableDelete} onClick={() => _onAction('delete')}>
                {app.text('Delete')}
            </MenuItem>
        </Menu>
    ) : (
        <>
            <FileUpload.Input onFileChange={onFileSelection} />
            <Menu>
                <MenuItem onClick={() => _onAction('create_asset')}>
                    {app.text('New subfolder')}
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={e => FileUpload.openFileDialog()}>
                    {app.text('Upload…')}
                </MenuItem>
                <MenuDivider />
                <MenuItem disabled={disableDelete} onClick={() => _onAction('delete')}>
                    {app.text('Delete')}
                </MenuItem>
            </Menu>
        </>
    )
})

//
// AssetThumb
//
// Show icon or image preview for an asset

import React, { useState, useMemo, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Image } from '../components'
import { IconTile } from '../panels'
import { Transform } from '../stores/imageservers/utils'

export const AssetThumb = observer(function AssetThumb({
    asset,
    transform,
    iconplaceholder,
    className,
    ...other
}) {
    const { imageserver } = useStore() // should I inject this instead?

    const cachedTransform = useMemo(() => {
        const _transform = new Transform(
            transform.resize,
            transform.width,
            transform.height
        )
        _transform.setType(transform.type)
        if (transform.hotspot) {
            _transform.setHotspot(transform.hotspot[0], transform.hotspot[1])
        }
        if (transform.crop) {
            _transform.setCrop(transform.crop[0], transform.crop[1])
        }
        if (transform.padding) {
            _transform.setPadding(
                transform.padding[0],
                transform.padding[1],
                transform.padding[2],
                transform.padding[3]
            )
        }
        _transform.setQuality(transform.quality)
        _transform.setBackground(transform.background)
        return _transform
    }, [
        transform.resize,
        transform.width,
        transform.height,
        transform.type,
        transform.hotspot,
        transform.crop,
        transform.padding,
        transform.quality,
        transform.background,
    ])

    let classes = 'cc-AssetThumb'
    if (className) classes += ' ' + className
    if (!asset) classes += ' cc-dimmed'

    const [previewLoaded, updatePreviewLoaded] = useState(0) // height updates after load
    const handlePreviewLoad = () => {
        updatePreviewLoaded(previewLoaded + 1)
    }

    const asset_updated_on = asset ? asset.updated_on : null
    const [previewSrc, setPreviewSrc] = useState(null)
    useEffect(() => {
        if (!asset) return
        let isMounted = true
        let controller = new AbortController()
        async function asyncEffect() {
            setPreviewSrc(null)
            const transformurl = imageserver.transformAssetUrl(asset, cachedTransform)
            const imageurl = await imageserver.transformedAssetUrl(
                asset,
                transformurl,
                { signal: controller.signal }
            )
            if (isMounted) {
                setPreviewSrc(imageurl)
            }
        }
        asyncEffect()
        return () => {
            controller.abort('Unmounted')
            isMounted = false
            return isMounted
        }
    }, [asset, asset_updated_on, cachedTransform, imageserver])

    const width =
        !cachedTransform || cachedTransform.width === null
            ? 'auto'
            : cachedTransform.width
    const height =
        !cachedTransform || cachedTransform.height === null
            ? 'auto'
            : cachedTransform.height
    const iconname = asset ? asset.iconname : iconplaceholder ? iconplaceholder : 'plus'

    if (!imageserver.canTransform(asset)) {
        return <IconTile className={classes} width={width} icon={iconname} {...other} />
    }

    return (
        <Image
            className={classes}
            alt={asset.pathname}
            title={asset.name}
            value={previewSrc}
            onLoad={handlePreviewLoad}
            style={{ width, height }}
            {...other}
        />
    )
})

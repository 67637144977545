//
// DefinitionDataWorksheet
//
// Work on a Definition

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../stores'
import { useMousePopover } from '../../../hooks/useMousePopover'

import { View, VView, HView, Spacer } from '../../../appview'
import { Header, Icon, Text, TextInput, TreeItem, Item } from '../../../components'
import { DragDropDefinitionClassTreeItem } from '../../../dragdrop/DragDropDefinitionClassTreeItem'
import { DragDropDefinitionFieldItem } from '../../../dragdrop/DragDropDefinitionFieldItem'
import { DragDropDefinitionChildDefinitionItem } from '../../../dragdrop/DragDropDefinitionChildDefinitionItem'
import { maxChars } from '../../../utils/text'
import { LabeledField } from '../../components'
import {
    DefinitionClassMenu,
    DefinitionClassFieldMenu,
    DefinitionFieldMenu,
    DefinitionChildDefinitionMenu,
} from '../../../menus'
import {
    RecordDefinitionAddClassPanel,
    RecordDefinitionAddFieldPanel,
    RecordDefinitionAddChildDefinitionPanel,
    LayoutPanel,
} from '../../../panels'
import { VALIDATION } from '../../../utils/validation'
import { duckField, validateField } from '../../../stores/data/validators'
import { validName } from '../../../utils/text'

import { LayoutBuilder } from '.'

export const DefinitionDataWorksheet = observer(function DefinitionDataWorksheet({
    worksheet,
}) {
    const { app, data, view } = useStore()

    const definition = worksheet.item
    const language = view.environment.get('language')
    const namerenderkey = definition.gid + '.name'
    const labelrenderkey = definition.gid + '.label.' + language

    const [selectedGid, setSelectedGid] = useState(null)

    const DefinitionClassContextMenu = useMousePopover(DefinitionClassMenu)
    const DefinitionClassFieldContextMenu = useMousePopover(DefinitionClassFieldMenu)
    const DefinitionFieldContextMenu = useMousePopover(DefinitionFieldMenu)
    const DefinitionChildDefinitionContextMenu = useMousePopover(
        DefinitionChildDefinitionMenu
    )
    const AddClassContextMenu = useMousePopover(RecordDefinitionAddClassPanel)
    const AddFieldContextMenu = useMousePopover(RecordDefinitionAddFieldPanel)
    const AddChildDefinitionContextMenu = useMousePopover(
        RecordDefinitionAddChildDefinitionPanel
    )

    const selectModified = result => {
        const modified_definition = data.definitions.get(result['definition'])
        if (modified_definition) {
            view.schematree.selectItem({
                id: modified_definition.gid,
                item: modified_definition,
            })
            view.schemaquery.refetch()
        }
        return result
    }

    const duckRequiredTextField = duckField('text', {}, true)
    const requiredValidator = value => {
        return validateField(value, duckRequiredTextField, worksheet.language)
    }

    const onNameChange = value => {
        definition.setName(value)
    }
    const onNameBlur = value => {
        if (requiredValidator(value).result !== VALIDATION.SUCCESS) {
            // TODO: CC-204 resetName
        } else {
            definition.setName(value.trim())
            definition.commitIfModified().then(result => selectModified(result))
        }
    }

    const onLabelChange = value => {
        definition.setLabel(value, language)
    }
    const onLabelBlur = value => {
        definition.setLabel(value.trim(), language)
        definition.commitIfModified().then(result => selectModified(result))
    }

    let initialtreestate = {}
    worksheet.getDefinitionClasses(definition).forEach(class_ => {
        const fields = worksheet.getClassFields(class_)
        initialtreestate[class_.gid] = fields.length ? 'collapsed' : 'empty'
    })
    const [treeState, setTreeState] = useState(initialtreestate)
    const onExpandTreeState = key => {
        let newtreestate = { ...treeState }
        newtreestate[key] = 'expanded'
        setTreeState(newtreestate)
    }
    const onCollapseTreeState = key => {
        let newtreestate = { ...treeState }
        newtreestate[key] = 'collapsed'
        setTreeState(newtreestate)
    }
    const onAddTreeState = key => {
        if (key in treeState) return
        let newtreestate = { ...treeState }
        newtreestate[key] = 'collapsed'
        setTreeState(newtreestate)
    }
    const onRemoveTreeState = key => {
        if (!(key in treeState)) return
        let newtreestate = { ...treeState }
        delete newtreestate[key]
        setTreeState(newtreestate)
    }

    const onDropClass = (dragitem, dropgid, dropzone) => {
        // if dropped on class -> check dropzone
        // else dropped on class.add -> move/add at end
        let before_class = null
        let use_next_class = false
        definition.classes.forEach(classgid => {
            if (classgid === dropgid && dropzone === 'top') before_class = classgid
            if (use_next_class) {
                before_class = classgid
                use_next_class = false
            }
            if (classgid === dropgid && dropzone === 'bottom') use_next_class = true
        })
        // if class is in definition -> move it
        // else, add it
        if (definition.classes.includes(dragitem.id)) {
            definition
                .moveClass(dragitem.id, before_class)
                .then(result => selectModified(result))
        } else {
            definition
                .addClass(dragitem.id, before_class)
                .then(result => selectModified(result))
                .then(result => {
                    onAddTreeState(dragitem.id)
                })
        }
    }
    const onAddClass = classgid => {
        AddClassContextMenu.hide()
        definition
            .addClass(classgid)
            .then(result => selectModified(result))
            .then(result => {
                onAddTreeState(classgid)
            })
    }
    const onRemoveClass = () => {
        definition
            .removeClass(selectedGid)
            .then(result => selectModified(result))
            .then(result => {
                onRemoveTreeState(selectedGid)
            })
    }
    const onGotoClass = () => {
        view.schematree.expandItem({ id: 'class' })
        const classitem = view.schematree.findItemById(selectedGid)
        if (classitem) {
            view.schematree.selectItem(classitem)
        }
    }

    const onDropField = (dragitem, dropgid, dropzone) => {
        // if dropped on field -> check dropzone
        // else dropped on field.add -> move/add at end
        let before_field = null
        let use_next_field = false
        definition.fields.forEach(fieldgid => {
            if (fieldgid === dropgid && dropzone === 'top') before_field = fieldgid
            if (use_next_field) {
                before_field = fieldgid
                use_next_field = false
            }
            if (fieldgid === dropgid && dropzone === 'bottom') use_next_field = true
        })
        // if field is in definition -> move it
        // else, add it
        if (definition.fields.includes(dragitem.id)) {
            definition
                .moveField(dragitem.id, before_field)
                .then(result => selectModified(result))
        } else {
            definition
                .addField(dragitem.id, before_field)
                .then(result => selectModified(result))
        }
    }
    const onAddField = fieldgid => {
        AddFieldContextMenu.hide()
        definition.addField(fieldgid).then(result => selectModified(result))
    }
    const onRemoveField = () => {
        definition.removeField(selectedGid).then(result => selectModified(result))
    }
    const onSetTitlefield = () => {
        definition.setTitlefield(selectedGid)
        definition.commitIfModified().then(result => selectModified(result))
    }
    const onGotoField = () => {
        view.schematree.expandItem({ id: 'field' })
        const fielditem = view.schematree.findItemById(selectedGid)
        if (fielditem) {
            view.schematree.selectItem(fielditem)
        }
    }

    const onDropChildDefinition = (dragitem, dropgid, dropzone) => {
        // if dropped on field -> check dropzone
        // else dropped on field.add -> move/add at end
        let before_childdefinition = null
        let use_next_childdefinition = false
        definition.childdefinitions.forEach(childdefinitiongid => {
            if (childdefinitiongid === dropgid && dropzone === 'top')
                before_childdefinition = childdefinitiongid
            if (use_next_childdefinition) {
                before_childdefinition = childdefinitiongid
                use_next_childdefinition = false
            }
            if (childdefinitiongid === dropgid && dropzone === 'bottom')
                use_next_childdefinition = true
        })
        // if field is in definition -> move it
        // else, add it
        if (definition.childdefinitions.includes(dragitem.id)) {
            definition
                .moveChilddefinition(dragitem.id, before_childdefinition)
                .then(result => selectModified(result))
        } else {
            definition
                .addChilddefinition(dragitem.id, before_childdefinition)
                .then(result => selectModified(result))
        }
    }
    const onAddChildDefinition = childdefinitiongid => {
        AddChildDefinitionContextMenu.hide()
        definition
            .addChilddefinition(childdefinitiongid)
            .then(result => selectModified(result))
    }
    const onRemoveChildDefinition = () => {
        definition
            .removeChilddefinition(selectedGid)
            .then(result => selectModified(result))
    }
    const onGotoChildDefinition = () => {
        view.schematree.expandItem({ id: 'definition' })
        const definitionitem = view.schematree.findItemById(selectedGid)
        if (definitionitem) {
            view.schematree.selectItem(definitionitem)
        }
    }

    const onDefinitionAction = action => {
        const actions = {
            remove_class: onRemoveClass,
            goto_class: onGotoClass,
            remove_field: onRemoveField,
            set_titlefield: onSetTitlefield,
            goto_field: onGotoField,
            remove_childdefinition: onRemoveChildDefinition,
            goto_childdefinition: onGotoChildDefinition,
        }
        if (!(action in actions)) {
            console.log(`onDefinitionAction: unhandled action '${action}'`)
            return
        }
        DefinitionClassContextMenu.hide()
        DefinitionClassFieldContextMenu.hide()
        DefinitionFieldContextMenu.hide()
        DefinitionChildDefinitionContextMenu.hide()
        if (actions[action]) {
            actions[action]()
        }
    }

    let classes_treeitems = []
    worksheet.getDefinitionClasses(definition).forEach(class_ => {
        const title = worksheet.layoutstore.getItemTitle(class_, language)
        onAddTreeState(class_.gid)
        classes_treeitems.push(
            <DragDropDefinitionClassTreeItem
                key={class_.gid}
                itemgid={class_.gid}
                onDrop={onDropClass}
            >
                <TreeItem
                    state={treeState[class_.gid]}
                    emptyicon={'empty'}
                    indent={0}
                    selected={selectedGid === class_.gid}
                    onMouseDown={() => {
                        setSelectedGid(selectedGid === class_.gid ? null : class_.gid)
                    }}
                    onExpand={() => {
                        onExpandTreeState(class_.gid)
                    }}
                    onCollapse={() => {
                        onCollapseTreeState(class_.gid)
                    }}
                    onContextMenu={e => {
                        setSelectedGid(class_.gid)
                        DefinitionClassContextMenu.onShow(e)
                    }}
                    title={class_.name}
                >
                    <Icon className="itemtype" name="class" size="text" />
                    {maxChars(title, 40)}
                </TreeItem>
            </DragDropDefinitionClassTreeItem>
        )
        if (treeState[class_.gid] === 'expanded') {
            const fields = worksheet.getClassFields(class_)
            fields.forEach(field => {
                const title = worksheet.layoutstore.getItemTitle(field, language)
                const titlefield_tag =
                    field.gid === definition.titlefield ? (
                        <span className="role">{app.text('titlefield')}</span>
                    ) : undefined
                classes_treeitems.push(
                    <DragDropDefinitionFieldItem
                        key={class_.gid + '.' + field.gid}
                        itemgid={field.gid}
                    >
                        <TreeItem
                            state={'empty'}
                            emptyicon={'empty'}
                            indent={1}
                            selected={selectedGid === field.gid}
                            onMouseDown={() => {
                                setSelectedGid(
                                    selectedGid === field.gid ? null : field.gid
                                )
                            }}
                            onContextMenu={e => {
                                setSelectedGid(field.gid)
                                DefinitionClassFieldContextMenu.onShow(e)
                            }}
                            title={field.name}
                        >
                            <Icon className="itemtype" name="field" size="text" />
                            {maxChars(title, 40)}
                            {titlefield_tag}
                        </TreeItem>
                    </DragDropDefinitionFieldItem>
                )
            })
        }
    })
    classes_treeitems.push(
        <DragDropDefinitionClassTreeItem
            key="class.add"
            itemgid="class.add"
            onDrop={onDropClass}
        >
            <Item
                className="cc-TreeItem"
                indent={0}
                selected={false}
                onClick={e => {
                    setSelectedGid(null)
                    AddClassContextMenu.onShow(e)
                }}
                onContextMenu={e => {
                    setSelectedGid(null)
                    AddClassContextMenu.onShow(e)
                }}
            >
                <Icon className="itemtype" name="plus" size="text" />
                <span className="cc-dimmed">{app.text('Add a class')}</span>
            </Item>
        </DragDropDefinitionClassTreeItem>
    )

    const Classes = <div className="cc-Field cc-Classesfield">{classes_treeitems}</div>

    let fields_items = []
    worksheet.getDefinitionFields(definition).forEach(field => {
        const title = worksheet.layoutstore.getItemTitle(field, language)
        const titlefield_tag =
            field.gid === definition.titlefield ? (
                <span className="role">{app.text('titlefield')}</span>
            ) : undefined
        fields_items.push(
            <DragDropDefinitionFieldItem
                key={field.gid}
                itemgid={field.gid}
                onDrop={onDropField}
            >
                <Item
                    className="cc-TreeItem"
                    indent={0}
                    selected={selectedGid === field.gid}
                    onMouseDown={() => {
                        setSelectedGid(selectedGid === field.gid ? null : field.gid)
                    }}
                    onContextMenu={e => {
                        setSelectedGid(field.gid)
                        DefinitionFieldContextMenu.onShow(e)
                    }}
                    title={field.name}
                >
                    <Icon className="itemtype" name="field" size="text" />
                    {maxChars(title, 40)}
                    {titlefield_tag}
                </Item>
            </DragDropDefinitionFieldItem>
        )
    })
    fields_items.push(
        <DragDropDefinitionFieldItem
            key="field.add"
            itemgid="field.add"
            onDrop={onDropField}
        >
            <Item
                className="cc-TreeItem"
                indent={0}
                selected={false}
                onClick={e => {
                    setSelectedGid(null)
                    AddFieldContextMenu.onShow(e)
                }}
                onContextMenu={e => {
                    setSelectedGid(null)
                    AddFieldContextMenu.onShow(e)
                }}
            >
                <Icon className="itemtype" name="plus" size="text" />
                <span className="cc-dimmed">{app.text('Add a field')}</span>
            </Item>
        </DragDropDefinitionFieldItem>
    )

    const Fields = <div className="cc-Field cc-Fieldsfield">{fields_items}</div>

    let childdefinitions_items = []
    worksheet
        .getDefinitionChildDefinitions(definition)
        .forEach((childdefinition, i) => {
            const title = worksheet.layoutstore.getItemTitle(childdefinition, language)
            const default_tag =
                i === 0 ? (
                    <span className="role">{app.text('default')}</span>
                ) : undefined

            childdefinitions_items.push(
                <DragDropDefinitionChildDefinitionItem
                    key={childdefinition.gid}
                    itemgid={childdefinition.gid}
                    onDrop={onDropChildDefinition}
                >
                    <Item
                        className="cc-TreeItem"
                        indent={0}
                        selected={selectedGid === childdefinition.gid}
                        onMouseDown={() => {
                            setSelectedGid(
                                selectedGid === childdefinition.gid
                                    ? null
                                    : childdefinition.gid
                            )
                        }}
                        onContextMenu={e => {
                            setSelectedGid(childdefinition.gid)
                            DefinitionChildDefinitionContextMenu.onShow(e)
                        }}
                        title={childdefinition.name}
                    >
                        <Icon className="itemtype" name="definition" size="text" />
                        {maxChars(title, 40)}
                        {default_tag}
                    </Item>
                </DragDropDefinitionChildDefinitionItem>
            )
        })
    childdefinitions_items.push(
        <DragDropDefinitionChildDefinitionItem
            key="childdefinition.add"
            itemgid="childdefinition.add"
            onDrop={onDropChildDefinition}
        >
            <Item
                className="cc-TreeItem"
                indent={0}
                selected={false}
                onClick={e => {
                    setSelectedGid(null)
                    AddChildDefinitionContextMenu.onShow(e)
                }}
                onContextMenu={e => {
                    setSelectedGid(null)
                    AddChildDefinitionContextMenu.onShow(e)
                }}
            >
                <Icon className="itemtype" name="plus" size="text" />
                <span className="cc-dimmed">{app.text('Add a child definition')}</span>
            </Item>
        </DragDropDefinitionChildDefinitionItem>
    )

    const ChildDefinitions = (
        <div className="cc-Field cc-Fieldsfield">{childdefinitions_items}</div>
    )

    const onUpdateLayoutComponentInMemory = (component_gid, name, value) => {
        worksheet.layoutstore.updateComponentInMemory(component_gid, name, value)
    }

    const onUpdateLayoutComponent = (component_gid, name, value) => {
        const updated_component = worksheet.layoutstore.updateComponentInMemory(
            component_gid,
            name,
            value
        )
        definition
            .modifyLayoutComponent(component_gid, updated_component)
            .then(result => {
                worksheet.layoutstore.refetch()
                return result
            })
            .then(result => selectModified(result))
    }

    const onCreateLayoutComponent = (type, parent_gid, before_gid, options) => {
        const new_component = worksheet.layoutstore.newComponent(type, options)
        definition
            .createLayoutComponent(
                new_component.type,
                parent_gid,
                before_gid,
                null, // name
                new_component.options
            )
            .then(result => {
                worksheet.layoutstore.refetch()
                return result
            })
            .then(result => selectModified(result))
    }

    const onMoveLayoutComponent = (component_gid, parent_gid, before_gid) => {
        definition
            .moveLayoutComponent(component_gid, parent_gid, before_gid)
            .then(result => {
                worksheet.layoutstore.refetch()
                return result
            })
            .then(result => selectModified(result))
    }

    const onDeleteLayoutComponent = component_gid => {
        definition
            .deleteLayoutComponent(component_gid)
            .then(result => {
                worksheet.layoutstore.refetch()
                return result
            })
            .then(result => selectModified(result))
    }

    const onLayoutAction = (action, params) => {
        const actions = {
            update_component_property_in_memory: onUpdateLayoutComponentInMemory,
            update_component_property: onUpdateLayoutComponent,
            create_component: onCreateLayoutComponent,
            move_component: onMoveLayoutComponent,
            delete_component: onDeleteLayoutComponent,
        }
        if (!(action in actions)) {
            console.log(`onLayoutAction: unhandled action '${action}'`)
            return
        }
        if (actions[action]) {
            actions[action](...params)
        }
    }

    const WorksheetLayoutPreview = worksheet.layoutstore.layout ? (
        <LayoutBuilder
            component={worksheet.layoutstore.layout.components.get(
                worksheet.layoutstore.layout.root
            )}
            components={worksheet.layoutstore.layout.components}
            layout={worksheet.layoutstore.layout}
            record={null}
            worksheet={worksheet}
        />
    ) : (
        <Text>{app.text('No layout assigned.')}</Text>
    )

    return (
        <View className="worksheet">
            <HView>
                <VView>
                    <Header>{app.text('Definition properties')}</Header>
                    <LabeledField
                        label={app.text('name')}
                        is_localized={false}
                        language={language}
                        placeholder={app.text(
                            'Reference name, only lower-case letters, digits, and underscore allowed.'
                        )}
                    >
                        <div className="ws-text">
                            <TextInput
                                multiline={false}
                                enabled={true}
                                validate={requiredValidator}
                                autoConvert={validName}
                                value={definition.name}
                                onChange={e => onNameChange(e.target.value)}
                                onBlur={e => onNameBlur(e.target.value)}
                                renderkey={namerenderkey}
                            />
                        </div>
                    </LabeledField>
                    <LabeledField
                        className="lc-subtitle"
                        label={app.text('label')}
                        is_localized={true}
                        language={language}
                        placeholder={app.text('Label, localized.')}
                    >
                        <div className="ws-text">
                            <TextInput
                                multiline={false}
                                enabled={true}
                                value={definition.label.get(language)}
                                language={language}
                                onChange={e => onLabelChange(e.target.value)}
                                onBlur={e => onLabelBlur(e.target.value)}
                                renderkey={labelrenderkey}
                            />
                        </div>
                    </LabeledField>
                    <DefinitionClassContextMenu.Panel onAction={onDefinitionAction} />
                    <DefinitionClassFieldContextMenu.Panel
                        fieldGid={selectedGid}
                        onAction={onDefinitionAction}
                    />
                    <DefinitionFieldContextMenu.Panel
                        fieldGid={selectedGid}
                        onAction={onDefinitionAction}
                    />
                    <DefinitionChildDefinitionContextMenu.Panel
                        onAction={onDefinitionAction}
                    />
                    <AddClassContextMenu.Panel onAddClass={onAddClass} />
                    <AddFieldContextMenu.Panel onAddField={onAddField} />
                    <AddChildDefinitionContextMenu.Panel
                        onAddChildDefinition={onAddChildDefinition}
                    />
                    <LabeledField
                        label={app.text('fields')}
                        is_localized={false}
                        language={language}
                        placeholder={app.text('No fields added.')}
                        show_placeholder={fields_items.length === 0}
                    >
                        {Fields}
                    </LabeledField>
                    <LabeledField
                        label={app.text('classes')}
                        is_localized={false}
                        language={language}
                        placeholder={app.text('No classes added yet.')}
                        show_placeholder={classes_treeitems.length === 0}
                    >
                        {Classes}
                    </LabeledField>
                    <LabeledField
                        label={app.text('child definitions')}
                        is_localized={false}
                        language={language}
                        placeholder={app.text('No child definitions added.')}
                        show_placeholder={childdefinitions_items.length === 0}
                    >
                        {ChildDefinitions}
                    </LabeledField>
                </VView>
                <Spacer size={20} />
                <VView grow>
                    <Header>{app.text('Layout')}</Header>
                    <div className="ws-field layout-component">
                        <div className="ws-label">
                            <span className="label">{app.text('layout')}</span>
                        </div>
                        <div className="cc-Field cc-Layoutfield">
                            <HView>
                                <LayoutPanel
                                    className="layout-tree"
                                    worksheetstore={worksheet}
                                    onAction={onLayoutAction}
                                />
                                <Spacer size={20} />
                                <VView grow className="layout-preview">
                                    {WorksheetLayoutPreview}
                                </VView>
                            </HView>
                        </div>
                    </div>
                </VView>
            </HView>
        </View>
    )
})

//
// AssetPinboardPanel
//
// Use to pin assets, e.g. while dragging and dropping but you want to scroll in
// between. Can hold more than one asset, but shouldn't display more than e.g. 5 at
// a time. And maybe max out at ten.
//
// If we drag-n-drop _from_ here, remove that item.
// We can also directly remove the item with an (x) as inline-action
// perhaps clear pinboard in one go?

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useDrop } from 'react-dnd'
import { useStore } from '../stores'

import { DragTypes } from '../dragdrop/DragTypes'
import { DragAsset } from '../dragdrop/DragAsset'

import { VView, Spacer } from '../appview'
import { Header, Text, TreeItem, Button, Icon } from '../components'
import { maxChars } from '../utils/text'

export const AssetPinboardPanel = observer(function AssetPinboardPanel({
    pinboard,
    selectedAsset,
}) {
    const { app, data } = useStore()

    const [{ isOver, canDrop }, drop] = useDrop({
        accept: [DragTypes.DAM_ASSET, DragTypes.ASSET_SELECTION],
        canDrop: () => pinboard.canAdd(),
        collect: monitor => {
            return {
                isOver: !!monitor.isOver(),
                canDrop: !!monitor.canDrop(),
            }
        },

        drop: item => {
            if (item.type === DragTypes.DAM_ASSET) {
                pinboard.addAsset(item.gid)
            } else if (item.type === DragTypes.ASSET_SELECTION) {
                if (!pinboard.exists(item.gid)) {
                    pinboard.addSelection(item.selection, item.selectionsize)
                }
            }
            return { receiver: 'AssetPinboardPanel', item: item }
        },
    })

    let classes = 'panel pinboard-panel asset-pinboard-panel pinboard-drop'
    if (isOver) {
        classes += ' drag-over-inside'
        if (!canDrop) classes += ' drop-rejected'
    }

    const pinnedcount = pinboard.pinnedItems.length
    const Message = !pinnedcount ? (
        <Text className="ws-helptext">
            {app.text(
                'Drag an asset here for easy access. This pinboard can hold up to ' +
                    pinboard.MAX_ITEMS +
                    ' items.'
            )}
        </Text>
    ) : undefined

    const Assets = pinboard.pinnedItems.map(item => {
        let name = ''
        let classes = ''
        let iconname = ''
        let asset = null
        let selection = null

        if (item.type === 'asset') {
            asset = data.assets.get(item.gid)
            if (!asset) {
                return null
            }
            name = asset.publicname ? asset.publicname : app.text('[empty]')
            if (asset.path) {
                name = name + ' - ' + asset.path
            }
            classes = 'status-' + asset.status
            iconname = asset.iconname
        } else if (item.type === 'selection') {
            selection = item.selection
            name = app.text('{count} assets', { count: item.size })
            iconname = 'selection'
        } else {
            return null
        }

        const action = (
            <div className="inline-actions">
                <Button
                    icon
                    title={app.text('Remove from pinboard')}
                    onClick={() => pinboard.remove(item.gid)}
                >
                    <Icon name="remove" size="text" />
                </Button>
            </div>
        )

        const onDropped = dropresult => {
            if (dropresult && dropresult.receiver !== 'AssetPinboardPanel') {
                pinboard.remove(item.gid)
            }
        }

        return (
            <DragAsset
                key={item.gid}
                gid={item.gid}
                asset={asset}
                selection={selection}
                selectionsize={item.size}
                onDropped={onDropped}
            >
                <TreeItem
                    state={'empty'}
                    emptyicon={iconname}
                    selected={selectedAsset && asset && selectedAsset.gid === asset.gid}
                    indent={0}
                    className={classes}
                    onClick={() => asset && pinboard.selectAsset(asset)}
                >
                    <span title={name}>{maxChars(name, 40)}</span>
                    {action}
                </TreeItem>
            </DragAsset>
        )
    })

    return (
        <VView ref={drop} className={classes}>
            <Header>{app.text('Asset pinboard')}</Header>
            {Assets}
            {Assets && !Message ? <Spacer size="5" /> : undefined}
            {Message}
        </VView>
    )
})

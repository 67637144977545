//
// UploadProjectIconPanel
//
// drop an image to update the project avatar

import React, { useState, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'
import { useStore } from '../stores'

import { ProjectIcon } from '.'
import { VALIDATION, ValidationResult } from '../utils/validation'
import { ValidationMessage } from '../components'

export const UploadProjectIconPanel = observer(
    React.forwardRef(function UploadProjectIconPanel({ project, ...other }, ref) {
        const { data } = useStore()

        const iconRef = useRef(null)

        const [dropvalidation, setDropvalidation] = useState(null)

        const canDrop = dragitem => {
            if (!ref.current || !dragitem) {
                return false
            }
            if (dragitem.hasOwnProperty('files')) {
                return true
            }
            return false
        }

        const [{ isOver }, drop] = useDrop({
            accept: NativeTypes.FILE,
            canDrop: dragitem => canDrop(dragitem),
            collect: monitor => {
                const isOver = !!monitor.isOver()
                return {
                    isOver: isOver,
                }
            },
            drop: (item, monitor) => {
                const droppedfile = item.files[0]
                if (
                    droppedfile.size &&
                    ['image/jpeg', 'image/gif', 'image/png', 'image/svg+xml'].includes(
                        droppedfile.type
                    )
                ) {
                    setDropvalidation(
                        new ValidationResult(VALIDATION.WARNING, 'Uploading...')
                    )
                    return data.actions
                        .upload_project_icon(project['gid'], droppedfile)
                        .then(projectgid => {
                            setDropvalidation(
                                new ValidationResult(VALIDATION.SUCCESS, 'Icon updated')
                            )
                            window.setTimeout(
                                () =>
                                    setDropvalidation(
                                        new ValidationResult(VALIDATION.NA)
                                    ),
                                500
                            )
                            return projectgid
                        })
                        .catch(() => {
                            setDropvalidation(
                                new ValidationResult(VALIDATION.ERROR, 'Upload failed')
                            )
                            window.setTimeout(
                                () =>
                                    setDropvalidation(
                                        new ValidationResult(VALIDATION.NA)
                                    ),
                                1500
                            )
                        })
                } else {
                    setDropvalidation(
                        new ValidationResult(VALIDATION.ERROR, 'Unsupported type')
                    )
                    window.setTimeout(
                        () => setDropvalidation(new ValidationResult(VALIDATION.NA)),
                        1500
                    )
                }
            },
        })

        drop(iconRef)

        let dropclassname = 'projecticon-upload'
        if (isOver) dropclassname += ' drag-over-inside'

        return (
            <div className={dropclassname}>
                <ProjectIcon
                    project={project}
                    ref={node => {
                        if (ref) ref.current = node
                        if (iconRef) iconRef.current = node
                    }}
                    {...other}
                />
                {dropvalidation ? (
                    <ValidationMessage validation={dropvalidation} />
                ) : undefined}
            </div>
        )
    })
)

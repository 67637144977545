//
// DragDropFieldlistFieldTreeItem & DragDropFieldlistTreeItem
//
// Drag and Drop wrapper for Fieldlist and FieldlistField TreeItems

import React, { useState, useRef, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useDrag, useDrop } from 'react-dnd'

import { DragTypes } from './DragTypes'
import { useDebounce } from '../hooks/useDebounce'

export const DragDropFieldlistFieldTreeItem = observer(
    function DragDropFieldlistFieldTreeItem({
        fieldlistkey,
        fieldgid,
        onDrop,
        children,
    }) {
        const ref = useRef(null)

        const [isoverzone, setIsoverzone] = useState('top')
        const [isMousedown, setIsMousedown] = useState(false)

        const debouncedIsoverzone = useDebounce(isoverzone, 50)

        const [{ isDragging }, drag] = useDrag({
            type: DragTypes.PIM_FIELD,
            item: {
                type: DragTypes.PIM_FIELD,
                id: fieldgid,
                fieldgid: fieldgid,
                fieldlistkey: fieldlistkey,
            },
            collect: monitor => ({
                isDragging: !!monitor.isDragging(),
            }),
            end: (item, monitor) => {
                if (ref.current) {
                    setIsMousedown(false)
                }
            },
        })

        const onMouseDown = e => {
            if (e.button === 0) {
                setIsMousedown(true)
            }
        }
        const onMouseUp = e => {
            setIsMousedown(false)
        }

        const canDrop = dragitem => {
            if (!ref.current || !dragitem) {
                return false
            }
            if (dragitem.type === DragTypes.PIM_FIELD) {
                if (
                    fieldgid === dragitem.fieldgid &&
                    fieldlistkey === dragitem.fieldlistkey
                ) {
                    return false
                }
                return true
            }
            return false
        }

        const [{ isOver }, drop] = useDrop({
            accept: DragTypes.PIM_FIELD,
            canDrop: dragitem => canDrop(dragitem),

            drop(dragitem, monitor) {
                onDrop(
                    dragitem,
                    { fieldgid: fieldgid, fieldlistkey: fieldlistkey },
                    isoverzone
                )
                return {
                    receiver: 'DragDropFieldlistFieldTreeItem',
                    dragitem: dragitem,
                    dropitem: {
                        fieldgid: fieldgid,
                        fieldlistkey: fieldlistkey,
                    },
                    dropzone: isoverzone,
                }
            },

            collect: monitor => {
                let isOver = !!monitor.isOver()
                const dragitem = monitor.getItem()
                if (!canDrop(dragitem)) {
                    isOver = false
                }
                return {
                    isOver: isOver,
                }
            },

            hover(dragitem, monitor) {
                if (!canDrop(dragitem)) {
                    return
                }
                // determine top or bottom
                const itemBoundingRect = ref.current.getBoundingClientRect()
                const itemTopY = Math.floor(
                    (itemBoundingRect.bottom - itemBoundingRect.top) / 2
                )
                const mousePosition = monitor.getClientOffset()
                const itemMouseY = mousePosition.y - itemBoundingRect.top
                if (itemMouseY < itemTopY) {
                    setIsoverzone('top')
                } else {
                    setIsoverzone('bottom')
                }
            },
        })

        drag(drop(ref))

        let dragclassname = ''
        if (isOver && debouncedIsoverzone === 'top') dragclassname = 'drag-over-top'
        else if (isOver && debouncedIsoverzone === 'bottom')
            dragclassname = 'drag-over-bottom'
        if (isMousedown) dragclassname += ' maybe-start-dragging'

        useEffect(() => {
            // set style later - cloned drag preview has original style
            if (ref && ref.current) {
                ref.current.style.opacity = isDragging ? 0.1 : 1
            }
        }, [isDragging])

        return (
            <div
                ref={ref}
                className={dragclassname}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
            >
                {children}
            </div>
        )
    }
)

export const DragDropFieldlistTreeItem = observer(function DragDropFieldlistTreeItem({
    fieldlistkey,
    onDrop,
    children,
}) {
    const ref = useRef(null)

    const [isoverzone] = useState('inside')

    const canDrop = dragitem => {
        if (!ref.current || !dragitem) {
            return false
        }
        if (dragitem.type === DragTypes.PIM_FIELD) {
            return true
        }
        return false
    }

    const [{ isOver }, drop] = useDrop({
        accept: DragTypes.PIM_FIELD,
        canDrop: dragitem => canDrop(dragitem),

        drop(dragitem, monitor) {
            onDrop(dragitem, { fieldgid: null, fieldlistkey: fieldlistkey }, isoverzone)
            return {
                receiver: 'DragDropFieldlistTreeItem',
                dragitem: dragitem,
                dropitem: {
                    fieldgid: null,
                    fieldlistkey: fieldlistkey,
                },
                dropzone: isoverzone,
            }
        },

        collect: monitor => {
            let isOver = !!monitor.isOver()
            const dragitem = monitor.getItem()
            if (!canDrop(dragitem)) {
                isOver = false
            }
            return {
                isOver: isOver,
            }
        },

        hover(dragitem, monitor) {
            if (!canDrop(dragitem)) {
                return
            }
        },
    })

    drop(ref)

    let dragclassname = ''
    if (isOver && isoverzone === 'inside') dragclassname = 'drag-over-inside'

    return (
        <div ref={ref} className={dragclassname}>
            {children}
        </div>
    )
})

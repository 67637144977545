//
// ClasslistFieldOptions
//
// Field options for type 'classlist'

import React from 'react'
import { useStore } from '../../../../stores'
import { observer } from 'mobx-react-lite'
import { ClassInput } from '../../../../components'
import { LabeledField } from '../../../components'
import { ListFieldOptions } from './ListFieldOptions'

export const ClasslistFieldOptions = observer(function ClasslistFieldOptions({
    field,
    selectModified,
    worksheet,
    onAction,
    setSelected,
    renderkeyprefix,
}) {
    const { app } = useStore()

    if (!field) return null

    const class_gid = field ? field.options.get('class') : undefined

    const onSelect = class_gid => {
        field.setOption('class', class_gid)
        field.commitIfModified().then(result => selectModified(result))
    }

    return (
        <>
            <LabeledField label={app.text('class')} is_localized={false}>
                <ClassInput onSelect={onSelect} value={class_gid} />
            </LabeledField>
            <ListFieldOptions
                field={field}
                selectModified={selectModified}
                worksheet={worksheet}
                onAction={onAction}
                setSelected={setSelected}
                renderkeyprefix={renderkeyprefix}
            />
        </>
    )
})

//
// NewChannelMenu
//
// Action to add a new channel in the RecordInspector

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Menu, MenuItem } from '../components'

export const NewChannelMenu = observer(function NewChannelMenu({ onAction }) {
    const { app } = useStore()

    const _onAction = action => {
        onAction && onAction(action)
    }

    return (
        <Menu>
            <MenuItem onClick={() => _onAction('new_channel')}>
                {app.text('New channel')}
            </MenuItem>
        </Menu>
    )
})

//
// LayoutBuilder
//
// Based on view info from a layout

import React from 'react'
import { observer } from 'mobx-react-lite'
import { prettyprint } from '../../../utils/utils'

import {
    SublayoutBuilder,
    ClassLayoutBuilder,
    DefinitionLayoutBuilder,
    AutoextendLayoutBuilder,
    AutogenerateLayoutBuilder,
    VerticalLayoutBuilder,
    HorizontalLayoutBuilder,
    DividerLayoutBuilder,
    SpacerLayoutBuilder,
    TextLayoutBuilder,
    ImageLayoutBuilder,
    FieldLayoutBuilder,
    FieldlistLayoutBuilder,
    TilesLayoutBuilder,
    RowsLayoutBuilder,
    TableLayoutBuilder,
} from './layoutbuilders'

export const LayoutBuilder = observer(function LayoutBuilder({
    component,
    components,
    layout,
    record,
    worksheet,
    active_class,
    active_field,
    visited_gids,
    force_readonly,
}) {
    if (!component) return null
    if (visited_gids === undefined) visited_gids = []
    let Builder
    let buildercomponent = component
    switch (component.type) {
        case 'layout':
            Builder = SublayoutBuilder
            // starts a new layoutbuilder, with the newly loaded layout components
            // and a new root component
            break
        case 'class':
            Builder = ClassLayoutBuilder
            break
        case 'definition':
            Builder = DefinitionLayoutBuilder
            break
        case 'autoextend':
            Builder = AutoextendLayoutBuilder
            break
        case 'autogenerate':
            Builder = AutogenerateLayoutBuilder
            break
        case 'root':
            Builder = LayoutBuilder
            buildercomponent = components.get(component.root) // this one
            break
        case 'vertical':
            Builder = VerticalLayoutBuilder
            break
        case 'horizontal':
            Builder = HorizontalLayoutBuilder
            break
        case 'divider':
            Builder = DividerLayoutBuilder
            break
        case 'spacer':
            Builder = SpacerLayoutBuilder
            break
        case 'text':
            Builder = TextLayoutBuilder
            break
        case 'image':
            Builder = ImageLayoutBuilder
            break
        case 'field':
            Builder = FieldLayoutBuilder
            break
        case 'fieldlist':
            Builder = FieldlistLayoutBuilder
            break
        case 'tiles':
            Builder = TilesLayoutBuilder
            break
        case 'rows':
            Builder = RowsLayoutBuilder
            break
        case 'table':
            Builder = TableLayoutBuilder
            break
        default:
            return (
                <pre className="layout-component lc-fallback">
                    {prettyprint(component)}
                </pre>
            )
    }
    return (
        <Builder
            component={buildercomponent}
            components={components}
            layout={layout}
            record={record}
            worksheet={worksheet}
            active_class={active_class}
            active_field={active_field}
            visited_gids={visited_gids}
            force_readonly={force_readonly}
        />
    )
})

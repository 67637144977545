//
// SublayoutBuilder
//
// Based on view info from a layout

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'

import { LayoutBuilder } from '..'

const RecursionMessage = observer(function RecursionMessage({ component }) {
    const { app } = useStore()
    return (
        <pre className="layout-component lc-recursion">
            {app.text('Circular or duplicate layouts detected: {name}', {
                name: component.name,
            })}
        </pre>
    )
})

export const SublayoutBuilder = observer(function SublayoutBuilder({
    className,
    component,
    components,
    layout,
    record,
    worksheet,
    active_class,
    visited_gids,
}) {
    const sublayout = worksheet._rootstore.data.layouts.get(component.layout)
    if (!sublayout) return null

    let attributes = { className: 'layout-component' }
    if (className) attributes['className'] += ' ' + className
    if (sublayout.gid === worksheet.layoutstore.selectedComponentGid) {
        attributes['className'] += ' cc-selected'
    }
    // attributes["onClick"] = e => onClickElement(e, component, layouttreestore)

    if (sublayout && visited_gids.includes(sublayout.gid)) {
        return <RecursionMessage component={sublayout} />
    }

    const SubLayout = sublayout ? (
        <LayoutBuilder
            component={sublayout.components.get(sublayout.root)}
            components={sublayout.components}
            layout={layout}
            record={record}
            worksheet={worksheet}
            active_class={active_class}
            visited_gids={[
                ...visited_gids,
                component.gid,
                component.original,
                sublayout.gid,
                sublayout.original,
            ]}
        />
    ) : undefined

    return <div {...attributes}>{SubLayout}</div>
})

//
// TextlineFieldOptions
//
// Field options for type 'textline'

import React from 'react'
import { observer } from 'mobx-react-lite'

import { duckField } from '../../../../stores/data/validators'
import { InitialFieldOption } from './InitialFieldOption'
import { TextLengthFieldOptions } from './TextLengthFieldOptions'
import { SelectFieldOptions } from './SelectFieldOptions'

export const TextlineFieldOptions = observer(function TextlineFieldOptions({
    field,
    selectModified,
    worksheet,
    onAction,
    setSelected,
    renderkeyprefix,
}) {
    if (!field) return null
    const values = field.options.get('values')
    const restrict_to_values = field.options.get('restrict_to_values')
    const min_length = field.options.get('min_length')
    const max_length = field.options.get('max_length')

    const duckInitialField = duckField(field.type, {
        values: values,
        restrict_to_values: restrict_to_values,
        min_length: min_length,
        max_length: max_length,
    })

    return (
        <>
            <InitialFieldOption
                field={field}
                initialField={duckInitialField}
                selectModified={selectModified}
                worksheet={worksheet}
                onAction={onAction}
                setSelected={setSelected}
                renderkeyprefix={renderkeyprefix}
            />
            <SelectFieldOptions
                field={field}
                selectModified={selectModified}
                worksheet={worksheet}
                onAction={onAction}
                setSelected={setSelected}
                renderkeyprefix={renderkeyprefix}
            />
            <TextLengthFieldOptions
                field={field}
                selectModified={selectModified}
                worksheet={worksheet}
                onAction={onAction}
                setSelected={setSelected}
                renderkeyprefix={renderkeyprefix}
            />
        </>
    )
})

//
// SpacerLayoutBuilder
//
// Based on view info from a layout

import React from 'react'
import { observer } from 'mobx-react-lite'

import { Spacer } from '../../../../appview'
import { sizetypeconverter } from '../../../../stores/data/LAYOUTCOMPONENTTYPECONFIG'

export const SpacerLayoutBuilder = observer(function SpacerLayoutBuilder({
    className,
    component,
    components,
    layout,
    record,
    worksheet,
    active_class,
    visited_gids,
}) {
    const scale = 2
    const size = sizetypeconverter(component.size) // old & new value for 'size' prop
    let attributes = { className: 'layout-component' }
    if (className) attributes['className'] += ' ' + className
    if (size.grow === 'auto') {
        attributes['grow'] = true
    } else if (size.grow === 'grow') {
        attributes['grow'] = size.value
    } else if (size.grow === 'fixed') {
        attributes['size'] = Math.round(size / scale)
    }
    if (component.gid === worksheet.layoutstore.selectedComponentGid) {
        attributes['className'] += ' cc-selected'
    }
    // attributes["onClick"] = e => onClickElement(e, component, layouttreestore)
    return <Spacer {...attributes} />
})

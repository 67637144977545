//
// DragSchemaItem
//
// Drag wrapper for SchemaItem

import React, { useRef, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useDrag } from 'react-dnd'
import { DragTypes } from './DragTypes'
import { DefinitionData } from '../stores/data/DefinitionData'
import { ClassData } from '../stores/data/ClassData'
import { FieldData } from '../stores/data/FieldData'

export const DragSchemaItem = observer(function DragSchemaItem({
    schemaitem,
    onDrop,
    children,
}) {
    const ref = useRef(null)

    const itemtype =
        schemaitem instanceof DefinitionData
            ? DragTypes.PIM_DEFINITION
            : schemaitem instanceof ClassData
            ? DragTypes.PIM_CLASS
            : schemaitem instanceof FieldData
            ? DragTypes.PIM_FIELD
            : undefined

    const [{ isDragging }, drag] = useDrag({
        type: itemtype,
        item: {
            type: itemtype,
            id: schemaitem ? schemaitem.gid : null,
            gid: schemaitem ? schemaitem.gid : null,
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
        end: (item, monitor) => {
            if (monitor.didDrop(monitor.getDropResult())) {
                onDrop && onDrop()
            }
        },
    })

    drag(ref)

    useEffect(() => {
        // set style later - cloned drag preview has original style
        if (ref && ref.current) {
            ref.current.style.opacity = isDragging ? 0.1 : 1
        }
    }, [isDragging])

    if (itemtype === undefined) {
        return <div>{children}</div>
    }
    return <div ref={ref}>{children}</div>
})

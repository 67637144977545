//
// useKeyboard(onKeyDown, onKeyUp)
//
// Listen for global keyboard events. You need to manage your own context to know
// whether the key press is relevant.

import { useEffect } from 'react'

export const useKeyboard = (onKeyDown, onKeyUp) => {
    function handleKeyDown(event) {
        onKeyDown && onKeyDown(event)
    }

    function handleKeyUp(event) {
        onKeyUp && onKeyUp(event)
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown)
        document.addEventListener('keyup', handleKeyUp)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
            document.removeEventListener('keyup', handleKeyUp)
        }
    })
}

//
// Button
//
// The Button is a, well, a button.

import React, { useState, useCallback } from 'react'
import { useEventListener } from '../hooks/useEventListener'
import { useGlobalEventListener } from '../hooks/useGlobalEventListener'

export const Button = React.forwardRef((props, ref) => {
    const { className, action, onClick, disabled, active, icon, text, ...other } = props
    let classes = 'cc-Button'
    if (className) classes += ' ' + className
    if (action) classes += ' cc-action'
    if (disabled) classes += ' cc-disabled'
    if (active) classes += ' cc-active'
    if (icon) classes += ' cc-with-icon'
    if (text) classes += ' cc-with-text'

    const clickevent = event => {
        // event.stopPropagation()
        // event.nativeEvent.stopImmediatePropagation()
        if (!disabled) {
            onClick && onClick(event)
        }
    }

    const [mousedown, setMousedown] = useState(false)
    const mousedownHandler = useCallback(() => {
        setMousedown(true)
    }, [setMousedown])
    const mouseupHandler = useCallback(() => {
        setMousedown(false)
    }, [setMousedown])
    const buttonRef = useEventListener('mousedown', mousedownHandler)
    useGlobalEventListener('mouseup', mouseupHandler)
    if (mousedown) classes += ' cc-mousedown'

    return (
        <div
            className={classes}
            onClick={clickevent}
            ref={node => {
                if (ref) ref.current = node
                buttonRef(node)
            }}
            {...other}
        />
    )
})

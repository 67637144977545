//
// BackupMenu
//
// Actions for the global backup menu

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Menu, MenuItem, Icon } from '../components'

export const BackupMenu = observer(function BackupMenu({ dismiss }) {
    const store = useStore()
    const { app, data } = store

    const onGotoBackups = () => {
        store.view.savePerProject('/settings/navigatorItem', 'backups')
        store.view.setWorkspace('settings')
        dismiss && dismiss()
    }

    return (
        <Menu>
            <MenuItem
                onClick={() => {
                    dismiss && dismiss()
                    data.actions.backups.createBackup(store.user.name)
                }}
            >
                {app.text('Create a new backup')}
            </MenuItem>
            <MenuItem
                onClick={() => {
                    dismiss && dismiss()
                    onGotoBackups()
                }}
            >
                <Icon size="text" name="navigate" /> {app.text('Go to Backups')}
            </MenuItem>
        </Menu>
    )
})

//
// ClassListField
//
// A class input for multiple instances.

import React from 'react'
import { observer } from 'mobx-react-lite'
import { LAYOUTCOMPONENTTYPECONFIG } from '../../../../stores/data/LAYOUTCOMPONENTTYPECONFIG'

import { PropertyTableClassListField } from './PropertyTableClassListField'
import { TableClassListField } from './TableClassListField'
import { TilesClassListField } from './TilesClassListField'

export const ClassListField = observer(function ClassListField(props) {
    const { field, component, propertytable } = props

    const config = LAYOUTCOMPONENTTYPECONFIG['field']['typeconfig'][field.type]
    const componentstyle = component
        ? component.style || config.style.default
        : config.style.default

    if (propertytable) {
        return <PropertyTableClassListField {...props} />
    } else if (componentstyle === 'table') {
        return <TableClassListField {...props} />
    } else {
        return <TilesClassListField {...props} />
    }
})

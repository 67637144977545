//
// ClasslistFieldRecords
//
// Class list fields are lists of ClassFieldRecords, used as an intermediate backing
// store for nested class/classlist field constructions.

import { makeObservable, observable, computed, action } from 'mobx'
import { hashData } from '../../utils/utils'

class ClasslistFieldRecord {
    _rootstore = null
    _classlist = null
    _field = null
    _class = null
    _index = null
    _data = null
    fields = new Map()

    constructor(classlist, field, index, fielddata) {
        makeObservable(this, {
            _rootstore: observable,
            _classlist: observable,
            _field: observable,
            _data: observable,
            fields: observable,

            gid: computed,
            version_gid: computed,
            language: computed,
            definition: computed,
            data_hash: computed,
            localized_fields: computed,

            updateFields: action.bound,

            resetField: action.bound,
            setField: action.bound,
            commitIfModified: action.bound,

            bump_version_gid: action.bound,
        })

        this._rootstore = classlist._rootstore
        this._classlist = classlist
        this._record = classlist._record
        this._field = field
        this._index = index
        this._data = fielddata

        const class_gid = this._field ? this._field.options.get('class') : undefined
        this._class = class_gid
            ? this._rootstore.data.classes.get(class_gid)
            : undefined

        this.updateFields()
    }

    get gid() {
        return this._classlist.gid + '[' + this._index + ']'
    }

    get version_gid() {
        return this._classlist._record.version_gid
    }

    get language() {
        return this._classlist.language
    }

    get definition() {
        return this._classlist.definition
    }

    get data_hash() {
        return hashData(this.fields)
    }

    get localized_fields() {
        return this.fields
    }

    bump_version_gid = () => {
        this._classlist._record.bump_version_gid()
    }

    updateFields = () => {
        let fields = new Map()
        if (this._class) {
            for (const field_gid of this._class.fields) {
                const field = this._rootstore.data.fields.get(field_gid)
                if (field) {
                    fields.set(
                        field.name,
                        this._data ? this._data.get(field.name) : undefined
                    )
                } else {
                    console.log('ClasslistFieldRecord field ERROR', field_gid)
                }
            }
        }
        this.fields.replace(fields)
    }

    setClassField = (key, value, recordfield) => {
        return this._classlist.setClassField(key, value, this._field)
    }

    setField = (field, value) => {
        this._data.set(field.name, value)
        this.updateFields()
        const key = this.gid + '.' + field.name
        return this._classlist.setClassField(key, value, this._field)
    }

    resetField = field => {
        return this._classlist.resetField()
    }

    commitIfModified = () => {
        return this._classlist.commitIfModified()
    }
}

export class ClasslistFieldRecords {
    _rootstore = null
    _record = null
    _field = null
    _class = null
    _data = null
    records = []

    constructor(record, field, fielddata) {
        makeObservable(this, {
            _rootstore: observable,
            _record: observable,
            _field: observable,
            _class: observable,
            _data: observable,
            records: observable,

            gid: computed,
            language: computed,
            definition: computed,
            data_hash: computed,

            updateRecords: action.bound,

            resetField: action.bound,
            setField: action.bound,
            commitIfModified: action.bound,

            newItemBefore: action.bound,
            removeItem: action.bound,
            removeAllItems: action.bound,
            moveItem: action.bound,
        })

        this._rootstore = record._rootstore
        this._record = record
        this._field = field
        this._data = fielddata

        const class_gid = this._field ? this._field.options.get('class') : undefined
        this._class = class_gid
            ? this._rootstore.data.classes.get(class_gid)
            : undefined

        if (!this._class) return
        if (!this._data || !this._data.length) return
        this.updateRecords()
    }

    get gid() {
        if (this._record._record) {
            // nested, no need to include version_gid twice
            return this._record.gid + '.' + this._field.name
        }
        return (
            this._record.gid + '.' + this._record.version_gid + '.' + this._field.name
        )
    }

    get language() {
        return this._record.language
    }

    get definition() {
        return this._record.definition
    }

    get data_hash() {
        return hashData(this.records)
    }

    updateRecords = () => {
        let records = []
        this._data.forEach((item, index) => {
            records.push(
                new ClasslistFieldRecord(this, this._field, index, this._data[index])
            )
        })
        this.records.replace(records)
    }

    setClassField = (key, value, recordfield) => {
        return this._record.setClassField(key, value, this._field)
    }

    setField = () => {
        this._record.bump_version_gid()
        return this._record.setClassField(this.gid, this._data, this._field)
    }

    resetField = () => {
        return this._record.resetField(this._field)
    }

    commitIfModified = () => {
        return this._record.commitIfModified()
    }

    newItemBefore = before_index => {
        let newitem = new Map()
        if (!this._class) return
        for (const field_gid of this._class.fields) {
            const field = this._rootstore.data.fields.get(field_gid)
            if (field) {
                newitem.set(field.name, field.newDataValue())
            } else {
                console.log('ClassFieldRecord field ERROR', field_gid)
            }
        }
        let items = []
        this._data.forEach((item, index) => {
            if (index === before_index) {
                items.push(newitem)
            }
            items.push(item)
        })
        if (items.length === this._data.length) {
            items.push(newitem)
        }
        this._data.replace(items)
        this.updateRecords()
        this.setField()
    }

    removeItem = index => {
        this._data.splice(index, 1)
        this.updateRecords()
        this.setField()
    }

    removeAllItems = () => {
        this._data.clear()
        this.updateRecords()
        this.setField()
    }

    moveItem = (moveindex, before_index) => {
        const moveitem = this._data[moveindex]
        let items = []
        this._data.forEach((item, index) => {
            if (index === before_index) {
                items.push(moveitem)
            }
            if (index === moveindex) {
                return
            }
            items.push(item)
        })
        if (items.length !== this._data.length) {
            items.push(moveitem)
        }
        this._data.replace(items)
        this.updateRecords()
        this.setField()
    }
}

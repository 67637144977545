//
// usePersistentState(key, initialValue = undefined)
//
// useState, but with localStorage backing to persist between browser refreshes
// Also listens for custom localStorage events triggered for the same key
// To get this to work, you must call addKeyedEventsToLocalStorage() at the top of
// your application. Optionally call restoreLocalStorage() to reset everything to how
// it was before.

import React from 'react'

const LocalStorageEvent = {
    key: 'LocalStorageKeyEvent',
    setItem: 'LocalStorageSetItemEvent',
    getItem: 'LocalStorageGetItemEvent',
    removeItem: 'LocalStorageRemoveItemEvent',
    clear: 'LocalStorageClearItemEvent',
}

const keyeventname = (eventname, key) => eventname + '.' + key

export const addKeyedEventsToLocalStorage = () => {
    window._previousStorage = {}
    for (let [method, eventname] of Object.entries(LocalStorageEvent)) {
        window._previousStorage[method] = Storage.prototype[method]
        Storage.prototype[method] = function () {
            let result
            if (this === window.localStorage) {
                let itemeventname = eventname
                if (method.endsWith('Item')) {
                    const [key] = arguments
                    itemeventname = keyeventname(eventname, key)
                }
                const event = new CustomEvent(itemeventname, { detail: arguments })
                result = window._previousStorage[method].apply(this, arguments)
                document.dispatchEvent(event) // dispatch after, so listeners can use localStorage
            } else {
                result = window._previousStorage[method].apply(this, arguments)
            }
            return result
        }
    }
}

export const restoreLocalStorage = () => {
    if (!window._previousLocalStorage) return
    for (let [method] of Object.entries(LocalStorageEvent)) {
        Storage.prototype[method] = window._previousStorage[method]
    }
}

export const usePersistentState = (key, initialValue = undefined) => {
    const [value, setValue] = React.useState(initialValue)

    // initialize once per key, immediately
    const initRef = React.useRef(null)
    if (initRef.current === null) {
        initRef.current = {
            setValueAndStorage: value => {
                window.localStorage.setItem(key, JSON.stringify(value))
                return setValue(value)
            },
        }
        const localvalue = window.localStorage.getItem(key)
        if (localvalue !== null) setValue(JSON.parse(localvalue))
    }

    // run once per key: add event listener for setItem, removeItem, and clear
    React.useEffect(() => {
        const handleSetItem = () => {
            setValue(JSON.parse(window.localStorage.getItem(key)))
        }
        const handleRemoveItem = () => {
            setValue(undefined)
        }
        const handleClear = () => {
            setValue(undefined)
        }

        document.addEventListener(
            keyeventname(LocalStorageEvent.setItem, key),
            handleSetItem,
            false
        )
        document.addEventListener(
            keyeventname(LocalStorageEvent.removeItem, key),
            handleRemoveItem,
            false
        )
        document.addEventListener(LocalStorageEvent.clear, handleClear, false)
        return () => {
            // cleanup
            document.removeEventListener(
                keyeventname(LocalStorageEvent.setItem, key),
                handleSetItem,
                false
            )
            document.removeEventListener(
                keyeventname(LocalStorageEvent.removeItem, key),
                handleRemoveItem,
                false
            )
            document.removeEventListener(LocalStorageEvent.clear, handleClear, false)
        }
    }, [key])

    return [value, initRef.current.setValueAndStorage]
}

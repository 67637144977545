//
// BackupActions
//
// Actions on backup items

import { makeObservable, action } from 'mobx'

export class BackupActions {
    constructor(rootstore) {
        makeObservable(this, {
            createBackup: action.bound,
            updateBackup: action.bound,
            restoreBackup: action.bound,
            deleteBackups: action.bound,
        })

        this._rootstore = rootstore
    }

    createBackup = created_by => {
        return this._rootstore
            ._fetch('/backups/create', {
                created_by: created_by,
            })
            .then(result => {
                const backup_versions = this._rootstore.project.backup_versions
                if (!backup_versions.length) {
                    return
                }
                const latest_backup = this._rootstore.project.backup_versions[0]
                this._rootstore.lastbackup = new Date(
                    latest_backup.get('timestamp') * 1000
                )
            })
    }

    updateBackup = (version, name) => {
        return this._rootstore._fetch('/backups/update', {
            version: version,
            name: name,
        })
    }

    restoreBackup = version => {
        return this._rootstore._fetch('/backups/restore', {
            version: version,
        })
    }

    deleteBackups = versions => {
        return this._rootstore
            ._fetch('/backups/delete', {
                versions: versions,
            })
            .then(result => {
                const backup_versions = this._rootstore.project.backup_versions
                if (!backup_versions.length) {
                    return
                }
                const latest_backup = this._rootstore.project.backup_versions[0]
                this._rootstore.lastbackup = new Date(
                    latest_backup.get('timestamp') * 1000
                )
            })
    }
}

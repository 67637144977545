//
// RowsLayoutBuilder
//
// Based on view info from a layout

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'

import { VView, HView } from '../../../../appview'
import { Text, Icon } from '../../../../components'
import { LayoutBuilder } from '..'
import { LAYOUTCOMPONENTTYPECONFIG } from '../../../../stores/data/LAYOUTCOMPONENTTYPECONFIG'

const MAGIC_LIMIT_BEFORE_IMPLEMENTING_SCROLLING = 21

export const RowsLayoutBuilder = observer(function RowsLayoutBuilder({
    className,
    component,
    components,
    layout,
    record,
    worksheet,
    active_class,
    visited_gids,
}) {
    const store = useStore()
    const { app } = useStore()

    const config = LAYOUTCOMPONENTTYPECONFIG['rows']

    const columns = component.columns ? component.columns : 4
    const name = component.name ? component.name : component.type
    const placeholder = component.placeholder
    const localizedPlaceholder = placeholder && placeholder[store.appLanguage]
    const editable =
        'editable' in component ? component.editable : config.editable.default
    const readonlyIcon = editable ? undefined : (
        <Icon className="cc-inline" name="lock" size={'text'} />
    )
    const navigation =
        'navigation' in component ? component.navigation : config.navigation.default
    const children = [{ gid: 1 }, { gid: 2 }, { gid: 3 }] //worksheet.children
    const navigationIcon = navigation ? (
        <Icon className="cc-inline" name="collapsed" size={'text'} />
    ) : undefined

    let Rows
    if (children && children.length) {
        Rows = children.map((child, index) => {
            if (index === MAGIC_LIMIT_BEFORE_IMPLEMENTING_SCROLLING - 1) {
                return null
            }
            const tileclasses = 'ws-tile status-' + child.status
            const ChildLayout =
                component.content && component.content.length
                    ? component.content.map(childcomponent_gid => {
                          const childcomponent = components.get(childcomponent_gid)
                          if (!childcomponent) return null
                          const childconfig =
                              LAYOUTCOMPONENTTYPECONFIG[childcomponent.type]
                          // for previews, don't propagate not-editable to prevent many lock icons
                          const childeditable = !editable
                              ? true
                              : 'editable' in childcomponent
                              ? childcomponent.editable
                              : childconfig.editable.default
                          childcomponent.editable = childeditable
                          return (
                              <LayoutBuilder
                                  key={childcomponent_gid}
                                  component={childcomponent}
                                  components={components}
                                  layout={layout}
                                  record={child}
                                  worksheet={worksheet}
                                  active_class={active_class}
                                  visited_gids={[
                                      ...visited_gids,
                                      component.gid,
                                      component.original,
                                  ]}
                              />
                          )
                      })
                    : undefined
            return (
                <HView className={tileclasses} key={child.gid}>
                    {ChildLayout}
                </HView>
            )
        })
        Rows = (
            <VView className="ws-record-navigator ws-rows" columns={columns}>
                {Rows}
            </VView>
        )
    }

    const More =
        children.length === MAGIC_LIMIT_BEFORE_IMPLEMENTING_SCROLLING ? (
            <Text>
                {app.text(
                    'There are more children than can be shown efficiently (for now). Please access them through the treeview.'
                )}
            </Text>
        ) : undefined

    const Placeholder = localizedPlaceholder ? (
        <div className="ws-placeholder">{localizedPlaceholder}</div>
    ) : undefined

    let attributes = {
        className: 'layout-component ws-record-navigator ws-rows ws-layoutpreview',
    }
    if (className) attributes['className'] += ' ' + className
    if (component.gid === worksheet.layoutstore.selectedComponentGid) {
        attributes['className'] += ' cc-selected'
    }
    // attributes["onClick"] = e => onClickElement(e, component, layouttreestore)

    if (!Rows) {
        attributes['className'] += ' ws-placeholder-visible'
        Rows = (
            <div>
                <br />
            </div>
        )
    }

    return (
        <div {...attributes}>
            {name}
            {readonlyIcon}
            {navigationIcon}
            <br />
            {Rows}
            {More}
            {Placeholder}
        </div>
    )
})

//
// Splitter
//
// Add a Splitter to an HView or VView to enable resizing. Set a ref on the views you
// want to resize, calculate the widths you need and set accordingly.
//
// Use a SimpleSplitter if you only have one target to manage. But for a workspace with
// a Navigator, Worksheet, Inspector, separated by 2 Splitters, we need a bit more
// control.
//
// Quick example:
//
//     const windowsize = useOnResize()
//     const windowWidth = windowsize[0]
//
//     const navRef = useRef(null)
//     const worksheetRef = useRef(null)
//     const inspectRef = useRef(null)
//
//     const [navWidth, setNavWidth] = useState(300) // initial value
//     const [inspectWidth, setInspectWidth] = useState(300) // initial value
//
//     const [splittingNavWidth, setSplittingNavWidth] = React.useState(navWidth)
//     const [splittingInspectWidth, setSplittingInspectWidth] = React.useState(inspectWidth)
//     const splittingWorksheetWidth = (
//         windowWidth - (splittingNavWidth + splittingInspectWidth + 2) // 1px splitters
//     )
//
//     const onSplitNavigator = newValue => {
//         setSplittingNavWidth(newValue)
//     }
//
//     const onSplitInspector = newValue => {
//         setSplittingInspectWidth(newValue)
//     }
//
//     const onSplitNavigatorEnd = newValue => {
//         setNavigatorWidth(newValue)
//     }
//
//     const onSplitInspectorEnd = newValue => {
//         setInspectWidth(newValue)
//     }
//
//     React.useEffect(() => {
//         setSplittingNavWidth(navWidth)
//         setSplittingInspectWidth(inspectWidth)
//     }, [navWidth, inspectWidth])
//
//     React.useEffect(() => {
//         if (navRef) {
//             navRef.current.style.flexBasis = splittingNavWidth + 'px'
//             navRef.current.style.maxWidth = splittingNavWidth + 'px'
//             navRef.current.style.minWidth = splittingNavWidth + 'px'
//         }
//         if (worksheetRef) {
//             worksheetRef.current.style.flexBasis = splittingWorksheetWidth + 'px'
//             worksheetRef.current.style.maxWidth = splittingWorksheetWidth + 'px'
//             worksheetRef.current.style.minWidth = splittingWorksheetWidth + 'px'
//         }
//         if (inspectRef) {
//             inspectRef.current.style.flexBasis = splittingInspectWidth + 'px'
//             inspectRef.current.style.maxWidth = splittingInspectWidth + 'px'
//             inspectRef.current.style.minWidth = splittingInspectWidth + 'px'
//         }
//     }, [splittingNavWidth, splittingInspectWidth, splittingWorksheetWidth])
//
//     return (
//       <HView>
//         <VView ref={navRef}>
//           This is my navigator, initially 300 px wide.
//         </VView>
//         <Splitter direction="end"
//                   initial={navWidth}
//                   onDragSplitter={newValue => onSplitNavigator(newValue)}
//                   onDragSplitterEnd={newValue => onSplitNavigatorEnd(newValue)}
//                   />
//         <VView grow>
//           My content view takes up the remaining space. I can use the Splitter to
//           resize the sidebar, and this content will then automatically fill the
//           remaining space.
//         </VView>
//         <Splitter direction="start"
//                   initial={inspectWidth}
//                   onDragSplitter={newValue => onSplitInspector(newValue)}
//                   onDragSplitterEnd={newValue => onSplitInspectorEnd(newValue)}
//                   />
//         <VView ref={inspectRef}>
//           This is my inspector, initially 300 px wide.
//         </VView>
//       </HView>
//     )
//
// If we have a sidebar on the right with a Splitter to the left of it, we want to grow
// the sidebar when we drag the Splitter to the left. This is accomplished by changing
// the direction to `start`. We use `start` and `end` here to support RTL.
// If the Splitter is in a VView, you will drag up and down, or towards the top and
// bottom; the direction values for these are `top` and `bottom`.
//
// The initial property is an offset for the mouse-drag delta.
//
// `onDragSplitter` is called while dragging the Splitter, while `onDragSplitterEnd` is
// called once the drag is complete, with the latest update to the value.
//
// We use splittingNav|Worksheet|InspectWidth properties as temporary storage while
// dragging the splitter, the actual widths are set when dragging ends.
//
// We can/should also do a fancy width calculation where navigator, inspector, and
// worksheet have min/max widths that can also be adjusted dynamically based on the
// available space -- see ../workspaces/WorkspaceView.jsx for a complete implementation.
//
// The background-color has a default, but you can override this in your stylesheet:
//     .av-Splitter { background-color: #eee; }
//     @media (prefers-color-scheme: dark) {
//       .av-Splitter { background-color: #535353; }
//     }
//
// You can also change the default line-width, for example to three pixels:
//     .av-HView > .av-Splitter { width: 3px; }
//     .av-VView > .av-Splitter { height: 3px; }

import React, { useRef, useState, useEffect } from 'react'

export const Splitter = React.forwardRef(function Splitter(
    { direction, initial, onDragSplitter, onDragSplitterEnd, ...other },
    ref
) {
    const splitterHandle = useRef(null)

    const [dragActive, setDragActive] = useState(false)
    const [dragOrigin, setDragOrigin] = useState({ x: 0, y: 0 })

    useEffect(() => {
        const computeNewValue = e => {
            let delta = 0
            switch (direction) {
                case 'top':
                    delta = dragOrigin.y - e.pageY
                    break
                case 'bottom':
                    delta = e.pageY - dragOrigin.y
                    break
                case 'start':
                    delta = dragOrigin.x - e.pageX
                    break
                case 'end':
                default:
                    delta = e.pageX - dragOrigin.x
                    break
            }
            return initial + delta
        }

        const onDragStart = e => {
            if (dragActive) return
            setDragOrigin({ x: e.pageX, y: e.pageY })
            setDragActive(true)
            // stop selecting text
            e.preventDefault()
            e.stopPropagation()
        }

        const onDragOver = e => {
            if (!dragActive) return
            const newValue = computeNewValue(e)
            onDragSplitter && onDragSplitter(newValue)
        }

        const onDragEnd = e => {
            if (!dragActive) return
            const newValue = computeNewValue(e)
            setDragActive(false)
            onDragSplitterEnd && onDragSplitterEnd(newValue)
        }

        const element = splitterHandle.current
        if (element) {
            element.addEventListener('mousedown', onDragStart)
            element.addEventListener('touchstart', onDragStart)
            document.addEventListener('mousemove', onDragOver)
            document.addEventListener('touchmove', onDragOver)
            document.addEventListener('mouseup', onDragEnd)
            document.addEventListener('touchend', onDragEnd)
            return () => {
                element.removeEventListener('mousedown', onDragStart)
                element.removeEventListener('touchstart', onDragStart)
                document.removeEventListener('mousemove', onDragOver)
                document.removeEventListener('touchmove', onDragOver)
                document.removeEventListener('mouseup', onDragEnd)
                document.removeEventListener('touchend', onDragEnd)
            }
        }
    }, [
        splitterHandle,
        dragActive,
        dragOrigin,
        direction,
        initial,
        onDragSplitter,
        onDragSplitterEnd,
    ])

    const { className, ...userprops } = other
    let classes = 'av-Splitter'
    if (className) classes += ' ' + className
    return (
        <div ref={ref} className={classes} {...userprops}>
            <div className="av-SplitterHandle" ref={splitterHandle} />
        </div>
    )
})

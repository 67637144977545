//
// DeletedTreeAssetMenu
//
// Actions on deleted assets in the TreeNavigator

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Menu, MenuDivider, MenuItem } from '../components'

export const DeletedTreeAssetMenu = observer(function DeletedTreeAssetMenu({
    asset,
    onAction,
}) {
    const { app, data } = useStore()

    if (!asset) return null

    // can't undelete when parent is still deleted
    const parent = data.getAssetByPathname(asset.path)
    const disableUndelete = parent && parent.status === 'deleted'

    const _onAction = action => {
        onAction && onAction(action)
    }

    return (
        <Menu>
            <MenuItem disabled={disableUndelete} onClick={() => _onAction('undelete')}>
                {app.text('Undelete')}
            </MenuItem>
            <MenuDivider />
            <MenuItem
                className="cc-danger"
                onClick={() => _onAction('permanent_delete')}
            >
                {app.text('Permanent delete')}
            </MenuItem>
        </Menu>
    )
})

//
// DragDropAsset
//
// Drag and Drop wrapper for Asset
//
// If you set dropData, you can 'postpone' the onDrop, e.g. when you want to present an
// action sheet (like move/copy/link) after dragging ends, but you still want the visual
// feedback from where you dropped it

import React, { useState, useRef, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useDrag, useDrop } from 'react-dnd'

import { DragTypes } from './DragTypes'
import { NativeTypes } from 'react-dnd-html5-backend'
import { useDebounce } from '../hooks/useDebounce'

export const DragDropAsset = observer(function DragDropAsset({
    asset,
    dropData,
    onDrop,
    children,
}) {
    const ref = useRef(null)

    const [isoverzone, setIsoverzone] = useState('item')
    const [isMousedown, setIsMousedown] = useState(false)

    const debouncedIsoverzone = useDebounce(isoverzone, 50)

    const [{ isDragging }, drag] = useDrag({
        type: DragTypes.DAM_ASSET,
        item: {
            type: DragTypes.DAM_ASSET,
            id: asset.pathname,
            gid: asset.gid,
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
        end: (item, monitor) => {
            if (ref.current) {
                setIsMousedown(false)
            }
        },
    })

    const onMouseDown = e => {
        if (e.button === 0) {
            setIsMousedown(true)
        }
    }
    const onMouseUp = e => {
        setIsMousedown(false)
    }

    const canDrop = dragitem => {
        if (!ref.current || !dragitem) {
            return false
        }
        if (dragitem.hasOwnProperty('files')) {
            if (asset.is_file || asset.status === 'deleted') {
                return false
            }
            return true
        } else if (dragitem.type === DragTypes.DAM_ASSET) {
            if (
                asset.is_file ||
                dragitem.id === '/' ||
                dragitem.id === asset.pathname ||
                asset.status === 'deleted' ||
                asset.pathname.startsWith(dragitem.id + '/') // is Descendant
            ) {
                return false
            }
            return true
        }
        return false
    }

    const [{ isOver }, drop] = useDrop({
        accept: [DragTypes.DAM_ASSET, NativeTypes.FILE],
        canDrop: dragitem => canDrop(dragitem),

        drop(dragitem, monitor) {
            onDrop(dragitem, asset, isoverzone)
            return {
                receiver: 'DragDropAsset',
                dragitem: dragitem,
                asset: asset,
                dropzone: isoverzone,
            }
        },

        collect: monitor => {
            let isOver = !!monitor.isOver()
            const dragitem = monitor.getItem()
            if (!canDrop(dragitem)) {
                isOver = false
            }
            return {
                isOver: isOver,
            }
        },

        hover(dragitem, monitor) {
            if (!canDrop(dragitem)) {
                return
            }
            setIsoverzone('item')
        },
    })

    drag(drop(ref))

    let dragclassname = ''
    if (isOver && debouncedIsoverzone === 'item') dragclassname = 'drag-over-inside'
    if (isMousedown) dragclassname += ' maybe-start-dragging'

    useEffect(() => {
        // set style later - cloned drag preview has original style
        if (ref && ref.current) {
            ref.current.style.opacity =
                isDragging ||
                (dropData &&
                    dropData.dragitem &&
                    dropData.dragitem.id === asset.pathname)
                    ? 0.1
                    : 1
        }
    }, [isDragging, dropData, asset.pathname])

    return (
        <div
            ref={ref}
            className={dragclassname}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
        >
            {children}
        </div>
    )
})

//
// AssetSearchPanel
//
// A search box with filters, and results.
// Results can be selected and/or dragged. Inputs are backed by the DamQueryStore,
// so they are preserved over reloads or worksheet and workspace switches. This means
// if you use the search for assets from within the PIM section, it starts where you
// left off searching the last time.

import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'
import { useDebounce } from '../hooks/useDebounce'
import { useTooltipPopover } from '../hooks/useTooltipPopover'
import { useMousePopover } from '../hooks/useMousePopover'
import { usePopoverContext } from '../components'

import { DragAssetSearchItem } from '../dragdrop/DragAssetSearchItem'
import { Transform } from '../stores/imageservers/utils'
import { DeletedTreeAssetMenu, TreeAssetMenu, AssetsContextmenu } from '../menus'
import { keyboard } from '../utils/keyboard'

import {
    HView,
    VView,
    View,
    LazyVScrollList,
    LazyScrollItem,
    Spacer,
    LayerView,
    Layer,
    Divider,
} from '../appview'
import { SearchInput, Item, Icon, Menu, MenuItem, Text, Header } from '../components'
import { AssetItem, AssetCard } from '../panels'

import { QueryMenu, ModifiedQueryMenu, QuickAssetQueries } from '../filters/FilterMenu'
import { FilterPanel } from '../filters/FilterPanel'
import { humanReadableNumber } from '../utils/text'

const SearchResultItem = observer(function SearchResultItem({
    asset,
    className,
    selected,
    onClick,
    ...other
}) {
    let classes = 'status-' + asset.status
    if (className) classes += ' ' + className
    return (
        <Item
            selected={selected}
            indent={0}
            className={classes}
            onClick={onClick}
            {...other}
        >
            <AssetItem asset={asset} />
        </Item>
    )
})

const SelectedAssetPanel = observer(function SelectedAssetPanel({
    assetgid,
    onSelect,
    onCancel,
}) {
    const { app, data } = useStore()
    const asset = data.assets.get(assetgid)

    const _onAction = action => {
        if (action === 'select') onSelect && onSelect(assetgid)
        if (action === 'cancel') onCancel && onCancel(assetgid)
    }

    if (!asset) return null

    const transform = new Transform('fit', 190, 190)

    return (
        <VView
            className="selectedasset-panel"
            onClick={e => e.nativeEvent.stopImmediatePropagation()}
        >
            <div className="inspector">
                <AssetCard asset={asset} transform={transform} />
            </div>
            <Menu>
                <MenuItem onClick={() => _onAction('select')}>
                    {app.text('Select')}
                </MenuItem>
                <MenuItem onClick={() => _onAction('cancel')}>
                    {app.text('Cancel')}
                </MenuItem>
            </Menu>
        </VView>
    )
})

export const AssetSearchPanel = observer(function AssetSearchPanel({
    querystore,
    selectedAssetGid,
    onSelect,
    preview,
    multiselect,
    assetactions,
    viewkey,
    showMatches,
    ...other
}) {
    const { app, data, view } = useStore()
    const popoverContext = usePopoverContext()

    const [selectedAsset, setSelectedAsset] = useState(null)
    const [isRefreshing, setIsRefreshing] = useState(false)
    const TreeAssetContextMenu = useMousePopover(TreeAssetMenu)
    const DeletedTreeAssetContextMenu = useMousePopover(DeletedTreeAssetMenu)
    const AssetsContextmenuPopover = useMousePopover(AssetsContextmenu)

    const onCreateAsset = () => {
        if (!assetactions || !selectedAsset) return
        view.damtree.create({ item: selectedAsset })
    }

    const onDownload = () => {
        if (!assetactions || !selectedAsset) return
        selectedAsset.download()
    }

    const onDelete = () => {
        if (!assetactions || !selectedAsset) return
        selectedAsset.delete()
    }

    const onUndelete = () => {
        if (!assetactions || !selectedAsset) return
        selectedAsset.undelete()
    }

    const onPermanentDelete = () => {
        if (!assetactions || !selectedAsset) return
        const parentasset = view.damworksheet.path[view.damworksheet.path.length - 2]
        selectedAsset.permanentDelete().then(successdata => {
            view.damworksheet.setAsset(parentasset)
            view.damtree.refetch()
        })
    }

    const onDeleteAssetsSelection = () => {
        if (!assetactions || !querystore.multiselection.size) return
        data.actions.assets.bulkDelete(querystore.actionSelection)
    }

    const onUndeleteAssetsSelection = () => {
        if (!assetactions || !querystore.multiselection.size) return
        data.actions.assets.bulkUndelete(querystore.actionSelection)
    }

    const onPermanentDeleteAssetsSelection = () => {
        if (!assetactions || !querystore.multiselection.size) return
        data.actions.assets.bulkPermanentDelete(querystore.actionSelection)
    }

    const onAssetAction = action => {
        popoverContext.store.show([...popoverContext.stack])
        const actions = {
            create_asset: onCreateAsset,
            download: onDownload,
            delete: onDelete,
            undelete: onUndelete,
            permanent_delete: onPermanentDelete,
            delete_assets_selection: onDeleteAssetsSelection,
            undelete_assets_selection: onUndeleteAssetsSelection,
            permanent_delete_assets_selection: onPermanentDeleteAssetsSelection,
        }
        if (!(action in actions) || !assetactions || !selectedAsset) {
            console.log(
                `onAssetAction: unhandled action '${action}' on '${selectedAsset.title}'`
            )
            return
        }
        actions[action]()
    }

    const QueryMenuPopover = useTooltipPopover(QueryMenu)
    const ModifiedQueryMenuPopover = useTooltipPopover(ModifiedQueryMenu)

    const SelectedAssetPopover = useTooltipPopover(SelectedAssetPanel, {
        preferredPosition: 'right',
        onClickOutside: e => {
            setSelectedGid(null)
        },
    })

    const itemheight = 60

    const onUpdate = (newtopindex, newwindowsize) => {
        if (
            newtopindex !== querystore.topindex ||
            newwindowsize !== querystore.windowsize
        ) {
            querystore.fetch(newtopindex, newwindowsize)
        }
    }

    const qref = React.useRef(querystore.q)
    const [searchtext, setSearchtext] = useState(querystore.q)
    const debouncedSearchtext = useDebounce(searchtext, 300)

    const [selectedGid, setSelectedGid] = useState(selectedAssetGid)

    const onSearchinputChange = event => {
        setSearchtext(event.target.value)
    }
    const onSearchinputBlur = event => {
        setSearchtext(event.target.value.trim())
    }

    const onSearchRefresh = event => {
        querystore.refetch()
    }

    useEffect(() => {
        if (querystore.q === qref.current) {
            qref.current = debouncedSearchtext
            querystore.q = debouncedSearchtext
        } else {
            qref.current = querystore.q
            setSearchtext(querystore.q)
        }
    }, [querystore, querystore.q, debouncedSearchtext])

    useEffect(() => {
        querystore.fetch(0, querystore.windowsize)
    }, [
        querystore,
        querystore.q,
        querystore.filterstore.refreshkey,
        querystore.windowsize,
    ])

    const showPreview = () => {
        if (preview) {
            SelectedAssetPopover.show()
        }
    }

    const hidePreview = () => {
        if (preview) {
            SelectedAssetPopover.hide()
        }
    }

    const onConfirmSelect = assetgid => {
        onSelect && onSelect(assetgid)
        setSelectedGid(null)
        hidePreview()
    }

    const onCancel = assetgid => {
        setSelectedGid(null)
        hidePreview()
    }

    const onSearchResultItemClicked = (event, assetgid) => {
        if (multiselect) {
            onSearchResultItemClickedMultiSelect(event, assetgid)
        } else {
            onSearchResultItemClickedSingleSelect(event, assetgid)
        }
    }

    const onSearchResultItemClickedSingleSelect = (event, assetgid) => {
        event.stopPropagation()
        event.nativeEvent.stopImmediatePropagation()
        popoverContext.store.show([...popoverContext.stack])
        if (selectedGid === assetgid) {
            setSelectedGid(null)
            hidePreview()
        } else {
            setSelectedGid(assetgid)
            showPreview()
        }
        if (!preview) {
            onSelect && onSelect(selectedGid === assetgid ? null : assetgid)
        }
    }

    const onSearchResultItemClickedMultiSelect = (event, assetgid) => {
        event.stopPropagation()
        event.nativeEvent.stopImmediatePropagation()
        popoverContext.store.show([...popoverContext.stack])
        if (keyboard.testCommand(event)) {
            if (selectedAssetGid === assetgid) {
                setSelectedGid(null)
                onSelect && onSelect(null)
            }
            if (querystore.multiselection.isSelected(assetgid)) {
                querystore.multiselection.deselect(assetgid)
            } else {
                querystore.multiselection.addRangeStart(assetgid)
            }
        } else if (keyboard.testShift(event)) {
            if (!querystore.multiselection.size && selectedGid) {
                querystore.multiselection.rangeStart(selectedGid)
            } else if (!querystore.multiselection.size && selectedAssetGid) {
                querystore.multiselection.rangeStart(selectedAssetGid)
            }
            querystore.multiselection.rangeEnd(assetgid)
        } else {
            querystore.multiselection.rangeStart(assetgid)
            setSelectedGid(assetgid)
            onSelect && onSelect(assetgid)
        }
    }

    const onSearchResultItemDoubleClicked = (event, assetgid) => {
        SelectedAssetPopover.hide()
        event.stopPropagation()
        event.nativeEvent.stopImmediatePropagation()
        popoverContext.store.show([...popoverContext.stack])
        onConfirmSelect(assetgid)
    }

    const onQueryMenuAction = (action, p1, p2, p3) => {
        switch (action) {
            case 'new':
                querystore.newQuery()
                break
            case 'reopen':
                querystore.reopenQuery()
                break
            case 'open':
                querystore.openQuery(p1) // gid
                break
            case 'cancel_open':
                break
            case 'save':
                querystore.saveQuery()
                break
            case 'save_as_new':
                querystore.saveNewQuery()
                break
            case 'delete':
                querystore.deleteQuery()
                break
            case 'quickquery':
                querystore.gid = null
                querystore.name = p1
                querystore.q = p2
                querystore.filter = p3
                break
            default:
                console.log(`onQueryMenuAction: unhandled action '${action}'`)
                break
        }
        QueryMenuPopover.hide()
        ModifiedQueryMenuPopover.hide()
    }

    const refreshResults = () => {
        if (isRefreshing) return
        setIsRefreshing(true)
        querystore.refetch().then(() => {
            window.setTimeout(() => {
                setIsRefreshing(false)
            }, 200)
        })
    }

    const SearchResultItems = querystore.totalsize ? (
        querystore.results
            .filter((assetgid, index) => {
                return !!data.assets.get(assetgid)
            })
            .map((assetgid, index) => {
                const asset = data.assets.get(assetgid)
                let selected = selectedGid
                    ? assetgid === selectedGid
                    : assetgid === selectedAssetGid
                let classes = ''
                if (querystore.multiselection.isSelected(assetgid) && !selected) {
                    classes += ' cc-multiselected'
                }
                if (
                    multiselect &&
                    querystore.multiselection.size > 0 &&
                    selected &&
                    !querystore.multiselection.isSelected(assetgid)
                ) {
                    selected = false
                }
                const contextmenu = !assetactions
                    ? null
                    : multiselect &&
                      querystore.multiselection.size > 1 &&
                      querystore.multiselection.isSelected(assetgid)
                    ? AssetsContextmenuPopover
                    : asset.status !== 'deleted'
                    ? TreeAssetContextMenu
                    : DeletedTreeAssetContextMenu
                return (
                    <LazyScrollItem key={assetgid} index={index + querystore.topindex}>
                        <div
                            ref={
                                assetgid === selectedGid
                                    ? SelectedAssetPopover.anchorRef
                                    : undefined
                            }
                            onContextMenu={e => {
                                if (contextmenu) {
                                    setSelectedAsset(asset)
                                    if (
                                        !(
                                            multiselect &&
                                            querystore.multiselection.size > 1 &&
                                            querystore.multiselection.isSelected(
                                                assetgid
                                            )
                                        )
                                    ) {
                                        querystore.multiselection.deselectAll()
                                        setSelectedGid(assetgid)
                                    }
                                    contextmenu.onShow(e)
                                }
                            }}
                        >
                            <DragAssetSearchItem asset={asset} querystore={querystore}>
                                <SearchResultItem
                                    className={classes}
                                    asset={asset}
                                    selected={selected}
                                    onClick={e =>
                                        onSearchResultItemClicked(e, assetgid)
                                    }
                                    onDoubleClick={e =>
                                        onSearchResultItemDoubleClicked(e, assetgid)
                                    }
                                />
                            </DragAssetSearchItem>
                        </div>
                    </LazyScrollItem>
                )
            })
    ) : (querystore.q && querystore.q.trim().length) ||
      querystore.filterstore.hasFilters() ? (
        multiselect ? (
            <Text className="cc-Placeholder">
                {app.text('Change the search terms or the filter.')}
            </Text>
        ) : (
            <LayerView>
                <Text>{app.text('No matches')}</Text>
                <Layer anchor="end" style={{ marginRight: 5 }}>
                    <HView vcenter>
                        {!querystore.totalsize &&
                        querystore.filterstore.hasFilters() &&
                        !querystore.showFilter ? (
                            <Text>
                                <span
                                    className="ws-tagbutton"
                                    onClick={() => querystore.clearQueryFilters()}
                                >
                                    {app.text('clear filters')}
                                </span>
                            </Text>
                        ) : undefined}
                        <div style={{ lineHeight: 0 }}>
                            <Icon
                                onClick={refreshResults}
                                name="refresh"
                                title={app.text('Refresh')}
                                spin={isRefreshing ? 'quickly' : false}
                                size={1}
                            />
                        </div>
                    </HView>
                </Layer>
            </LayerView>
        )
    ) : (
        <Text className="cc-Placeholder">
            {app.text('Type in the search bar or add a filter.')}
        </Text>
    )

    const activeFilterClassName = querystore.filterstore.hasFilters() ? 'cc-active' : ''

    const query = querystore.gid ? data.queries.get(querystore.gid) : null

    const Folder = (
        <>
            <QueryMenuPopover.Panel
                querystore={querystore}
                quickqueries={QuickAssetQueries}
                onAction={onQueryMenuAction}
            />
            <View
                style={{
                    flexBasis: '20px',
                    minWidth: 20,
                    maxWidth: 20,
                    position: 'relative',
                    top: '2px',
                }}
            >
                <div
                    style={{ backgroundColor: 'transparent' }}
                    ref={QueryMenuPopover.anchorRef}
                    onClick={e => {
                        if (querystore.filterstore.hasFilters()) {
                            querystore.setShowFilter(true)
                        }
                        ModifiedQueryMenuPopover.hide(e)
                        QueryMenuPopover.onShow(e)
                    }}
                    onContextMenu={e => {
                        if (querystore.filterstore.hasFilters()) {
                            querystore.setShowFilter(true)
                        }
                        ModifiedQueryMenuPopover.hide(e)
                        QueryMenuPopover.onShow(e)
                    }}
                >
                    <Icon name="folder" size={2} />
                </div>
            </View>
        </>
    )

    showMatches = true

    return (
        <VView grow className="panel asset-search-panel" {...other}>
            {query ? (
                <HView style={{ marginLeft: 5, marginRight: 5 }}>
                    <View>
                        <Header>{query.name}</Header>
                    </View>
                    {querystore.is_modified ? (
                        <>
                            <ModifiedQueryMenuPopover.Panel
                                querystore={querystore}
                                onAction={onQueryMenuAction}
                            />
                            <Text>
                                <span
                                    className="ws-tag cc-info"
                                    ref={ModifiedQueryMenuPopover.anchorRef}
                                    onClick={e => {
                                        if (querystore.filterstore.hasFilters()) {
                                            querystore.setShowFilter(true)
                                        }
                                        QueryMenuPopover.hide(e)
                                        ModifiedQueryMenuPopover.onShow(e)
                                    }}
                                    onContextMenu={e => {
                                        if (querystore.filterstore.hasFilters()) {
                                            querystore.setShowFilter(true)
                                        }
                                        QueryMenuPopover.hide(e)
                                        ModifiedQueryMenuPopover.onShow(e)
                                    }}
                                >
                                    {app.text('modified')}
                                </span>
                            </Text>
                        </>
                    ) : undefined}
                    <Spacer grow />
                    {Folder}
                    <Spacer size={5} />
                </HView>
            ) : undefined}
            <HView style={{ marginLeft: 5, marginRight: 5 }}>
                <View grow>
                    <LayerView>
                        <SearchInput
                            value={searchtext}
                            placeholder={app.text('Search')}
                            onChange={onSearchinputChange}
                            onBlur={onSearchinputBlur}
                            onIconClick={onSearchRefresh}
                            icontitle={app.text('Click to refresh')}
                        />
                        <Layer anchor="end">
                            <View
                                style={{
                                    flexBasis: '20px',
                                    minWidth: 20,
                                    maxWidth: 20,
                                    position: 'relative',
                                    top: '1px',
                                    left: '-6px',
                                }}
                            >
                                <div
                                    className={activeFilterClassName}
                                    style={{ backgroundColor: 'transparent' }}
                                    onClick={e => {
                                        querystore.setShowFilter(!querystore.showFilter)
                                    }}
                                >
                                    <Icon name="filter" size={2} />
                                </div>
                            </View>
                        </Layer>
                    </LayerView>
                </View>
                {query ? undefined : (
                    <>
                        <Spacer size={5} />
                        {Folder}
                    </>
                )}
            </HView>
            {querystore.showFilter ? (
                <>
                    <FilterPanel filterstore={querystore.filterstore} />
                    <Divider style={{ margin: '0 5px' }} />
                </>
            ) : undefined}
            {showMatches ? (
                <>
                    <HView vcenter>
                        <Text>
                            {app.pluralized_text(
                                {
                                    0: 'No matches',
                                    1: '{formatted_count} match',
                                    many: '{formatted_count} matches',
                                },
                                querystore.totalsize,
                                {
                                    formatted_count: humanReadableNumber(
                                        querystore.totalsize
                                    ),
                                }
                            )}
                        </Text>
                        <div style={{ lineHeight: 0 }}>
                            <Icon
                                onClick={refreshResults}
                                name="refresh"
                                title={app.text('Refresh')}
                                spin={isRefreshing ? 'quickly' : false}
                                size={1}
                            />
                        </div>
                        <Spacer grow />
                        {querystore.filterstore.hasFilters() ||
                        querystore.showFilter ? (
                            <Text>
                                <span
                                    className="ws-tagbutton"
                                    onClick={() => querystore.clearQueryFilters()}
                                >
                                    {app.text('reset filters')}
                                </span>
                            </Text>
                        ) : undefined}
                    </HView>
                </>
            ) : undefined}
            {showMatches ? <Divider style={{ margin: '0 5px' }} /> : undefined}

            <SelectedAssetPopover.Panel
                assetgid={querystore.results.includes(selectedGid) ? selectedGid : null}
                onSelect={onConfirmSelect}
                onCancel={onCancel}
            />
            <AssetsContextmenuPopover.Panel
                asset={selectedAsset}
                assetsselection={querystore.multiselection}
                onAction={onAssetAction}
            />
            <TreeAssetContextMenu.Panel
                asset={selectedAsset}
                onAction={onAssetAction}
            />
            <DeletedTreeAssetContextMenu.Panel
                asset={selectedAsset}
                onAction={onAssetAction}
            />
            <LazyVScrollList
                className="search-results"
                firstindex={querystore.topindex}
                itemheight={itemheight}
                totalitems={querystore.totalsize}
                onUpdate={onUpdate}
            >
                {SearchResultItems}
            </LazyVScrollList>
        </VView>
    )
})

//
// Select
//
// Select from a list of options.

import React from 'react'
import { observer } from 'mobx-react-lite'
import { Icon } from './Icon'
import { useSelectPopover } from '../hooks/useSelectPopover'

const OptionsPanel = observer(function OptionsPanel({
    options,
    selected,
    order,
    onSelect,
}) {
    let keys_in_order
    if (order) {
        var sortable = Array.from(options.entries())
        sortable.sort(function (a, b) {
            return a[1].localeCompare(b[1])
        })
        keys_in_order = sortable.map(entry => entry[0])
    } else {
        keys_in_order = Array.from(options.keys())
    }

    const Options = keys_in_order.map(function (key) {
        const option = options.get(key)
        let optionclasses = 'cc-Select-Option'
        if (key === selected) optionclasses += ' cc-active'
        return (
            <div
                key={key}
                className={optionclasses}
                onClick={e => {
                    onSelect && onSelect(key)
                    e.preventDefault()
                    e.stopPropagation()
                    e.nativeEvent.stopImmediatePropagation()
                }}
            >
                {option}
            </div>
        )
    })

    let style = {}
    if (options.size > 10) {
        style = { maxHeight: 180, overflowY: 'scroll' }
    }

    return (
        <div className="cc-Select-Options" style={style}>
            {Options}
        </div>
    )
})

export const Select = props => {
    let { className, onChange, value, options, order, placeholder, ...other } = props
    const Placeholder = placeholder ? (
        <div className="cc-Placeholder">{placeholder}</div>
    ) : undefined

    if (typeof options.keys === 'undefined') {
        options = new Map(Object.entries(options))
    }

    const selectOption = selectedOption => {
        setShowOptions(false)
        onChange && onChange(selectedOption)
    }

    let classes = 'cc-Select'
    if (className) classes += ' ' + className
    let displayvalue = value
    if (options.has(value)) {
        displayvalue = options.get(value)
    }
    if (
        displayvalue === null ||
        displayvalue === undefined ||
        !displayvalue.toString().length
    ) {
        displayvalue = Placeholder
    }

    const OptionsPopover = useSelectPopover(OptionsPanel)

    const setShowOptions = state => {
        if (state) OptionsPopover.show()
        else OptionsPopover.hide()
    }

    return (
        <>
            <div
                className={classes}
                {...other}
                tabIndex="0"
                ref={OptionsPopover.anchorRef}
                onClick={e => {
                    setShowOptions(!OptionsPopover.isOpen())
                    e.preventDefault()
                    e.stopPropagation()
                    e.nativeEvent.stopImmediatePropagation()
                }}
            >
                <div className="cc-Select-Value">{displayvalue}</div>
                <Icon name="expanded" size={'text'} className="cc-Select-Icon" />
            </div>
            <OptionsPopover.Panel
                options={options}
                selected={value}
                order={order}
                onSelect={selectOption}
            />
        </>
    )
}

//
// ProjectMemberMenu
//
// Actions on project members

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Menu, MenuDivider, MenuItem } from '../components'

export const ProjectMemberMenu = observer(function ProjectMemberMenu({
    member,
    onAction,
}) {
    const { app } = useStore()

    const _onAction = action => {
        onAction && onAction(member.user_gid, action)
    }

    const disableDeactivate =
        member.status === 'invited' ||
        member.status === 'inactive' ||
        member.status === 'declined'
    const disableReactivate =
        member.status === 'invited' ||
        member.status !== 'inactive' ||
        member.status === 'declined'

    return (
        <Menu>
            {member.status === 'invited' ? (
                <MenuItem onClick={() => _onAction('resend_invite')}>
                    {app.text('Resend invite')}
                </MenuItem>
            ) : undefined}
            {member.status === 'invited' ? <MenuDivider /> : undefined}
            <MenuItem
                disabled={disableDeactivate}
                onClick={() => _onAction('deactivate')}
            >
                {app.text('Deactivate')}
            </MenuItem>
            <MenuItem
                disabled={disableReactivate}
                onClick={() => _onAction('reactivate')}
            >
                {app.text('Reactivate')}
            </MenuItem>
            <MenuDivider />
            <MenuItem className="cc-danger" onClick={() => _onAction('remove')}>
                {app.text('Remove')}
            </MenuItem>
        </Menu>
    )
})

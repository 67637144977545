//
// ImageLayoutBuilder
//
// Based on view info from a layout

import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'
import { useElementWidth } from '../../../../hooks/useElementWidth'

import { AssetThumb, IconTile } from '../../../../panels'
import { Transform } from '../../../../stores/imageservers/utils'

export const ImageLayoutBuilder = observer(function ImageLayoutBuilder({
    className,
    component,
    components,
    layout,
    record,
    worksheet,
    active_class,
    visited_gids,
}) {
    const { data } = useStore()
    const [containerRef, containerWidth] = useElementWidth(20)

    let attributes = { className: 'layout-component' }
    if (className) attributes['className'] += ' ' + className
    if (component.style) attributes['className'] += ' lc-' + component.style
    if (component.gid === worksheet.layoutstore.selectedComponentGid) {
        attributes['className'] += ' cc-selected'
    }

    let value = null
    if ('image' in component) value = component['image']

    const [asset, setAsset] = useState(null)
    useEffect(() => {
        let isMounted = true
        let controller = new AbortController()
        async function asyncEffect() {
            setAsset(null)
            if (value) {
                if (!data.assets.has(value)) {
                    await data.fetchAssetByGid(value, { signal: controller.signal })
                }
                const asyncasset = data.assets.get(value)
                if (isMounted) {
                    setAsset(asyncasset)
                }
            }
        }
        asyncEffect()
        return () => {
            controller.abort('Unmounted')
            isMounted = false
            return isMounted
        }
    }, [value, data])

    const assetTransform = new Transform('width', containerWidth)

    return (
        <div {...attributes} ref={containerRef}>
            {!asset ? (
                <IconTile
                    width={250}
                    className="ws-tile cc-dimmed"
                    icon="image"
                    anchor="center"
                />
            ) : (
                <AssetThumb
                    asset={asset}
                    transform={assetTransform}
                    iconplaceholder="image"
                />
            )}
        </div>
    )
})

import React from 'react'

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { error: null }
    }

    static getDerivedStateFromError(error) {
        return { error }
    }

    componentDidCatch(error, errorInfo) {
        const reportError = this.props.reportError
        reportError && reportError(error, errorInfo)
    }

    render() {
        if (this.state.error) {
            const onReset = this.props.onReset
            const ErrorUI = this.props.errorUI
            if (ErrorUI) {
                return <ErrorUI error={this.state.error} onReset={onReset} />
            }
            return (
                <div>
                    <h1>Something went wrong.</h1>
                    <pre>{this.state.error.message}</pre>
                    <p>
                        <button onClick={() => onReset && onReset()}>Restore</button>
                    </p>
                </div>
            )
        } else {
            return this.props.children
        }
    }
}

export { ErrorBoundary }

//
// Popover
//
// A Popover is a kind of modal that is shown near it's origin, with an arrow pointing
// to the origin. The arrow is an svg triangle.
// You determine the placement in the onLayout function, where you'll get refs for the
// popover and the arrow.
//
// The popover needs a root element with id 'portal', so your index.html might look like
//
//    <body>
//        <div id="root"></div>
//        <div id="portal"></div>
//    </body>
//
// Popovers can be nested, and we manage the stack using a PopoverContext and a
// PopoverStore. At the top-level in your application, you need to have a
// PopoverProvider (PopoverContext.Provider) that defines the initial store, and an
// empty stack:
//
//    ReactDOM.render(
//        <PopoverProvider value={{ store: new PopoverStore(), stack: [] }}>
//            <App />
//        </PopoverProvider>,
//        document.getElementById('root')
//    )
//
// The PopoverStore maintains a global list of open popovers with the associated
// visibilityFunctions (setVisibility(true|false)). That store is passed around by
// each nested PopoverProvider. The stack is a copy for each, where a new key is added.
// E.g. the useMousePopover renders this when the popover is visible:
//
//    const popoverContext = usePopoverContext()
//    …
//    <PopoverProvider
//        value={{
//            store: popoverContext.store,
//            stack: [...popoverContext.stack, keyRef.current],
//        }}
//    >
//        <Popover ref={popoverRef}>{Panel}</Popover>
//    </PopoverProvider>
//
// The `show` function for the popover also adds that same key to the PopoverStore:
//
//    const show = () => {
//        popoverContext.store.show(
//            [...popoverContext.stack],
//            keyRef.current,
//            setVisibilityIfMounted
//        )
//    }
//
// Showing and hiding is usually done through the useMousePopover, useTooltipPopover,
// and useSubmenuPopover hooks. In some cases, there is the need to dismiss all the
// open popovers from some base panel. Since that base panel can be in a popover too,
// we only want to hide the popovers that are nested. We can use the usePopoverContext
// to achieve this:
//
//    const popoverContext = usePopoverContext()
//    …
//    popoverContext.store.show([...popoverContext.stack])
//
// Usage of the Popover: see useMousePopover, useTooltipPopover, and useSubmenuPopover.
// Do you have a different use-case? Create a new useXyzPopover hook, based on one of
// the available ones.

import React from 'react'
import { createPortal } from 'react-dom'

export const PopoverContext = React.createContext([])
export const usePopoverContext = () => React.useContext(PopoverContext)
export const PopoverProvider = PopoverContext.Provider

export class PopoverStore {
    _open = new Map()
    show = (pathkeys, newkey, visibilityFunction, dismissAction) => {
        for (var key of this._open.keys()) {
            if (!pathkeys.includes(key) && newkey !== key) {
                const actions = this._open.get(key)
                actions[0](false)
                // actions[1] && actions[1]()
                this._open.delete(key)
            }
        }
        if (newkey) {
            this._open.set(newkey, [visibilityFunction, dismissAction])
        }
        this._open.forEach(actions => actions[0](true))
    }
}

export const Popover = React.forwardRef(({ className, children }, ref) => {
    const portalRoot = document.getElementById('portal')

    // TODO: this prevents the main UI from scrolling, but all nested popovers can
    //       still scroll -- only the top one should be able to scroll
    function stopScroll(e) {
        if (!portalRoot.contains(e.target)) {
            e.preventDefault()
            e.stopPropagation()
        }
    }

    React.useEffect(() => {
        window.addEventListener('scroll', stopScroll, { passive: false })
        window.addEventListener('wheel', stopScroll, { passive: false })
        return () => {
            window.removeEventListener('scroll', stopScroll, { passive: false })
            window.removeEventListener('wheel', stopScroll, { passive: false })
        }
    })

    let classes = 'cc-Popover'
    if (className) {
        classes += ' ' + className
    }

    // const popoverContext = usePopoverContext()

    // we prevent user clicks from getting through to underlying elements
    return createPortal(
        <div
            ref={ref}
            className={classes}
            onClick={e => {
                // make sure fileupload-field clicks reach the browser
                if (
                    e.target.className.split &&
                    e.target.className.split(' ').includes('cc-FileUpload')
                ) {
                    return
                }
                // popoverContext.store.show([...popoverContext.stack])
                e.preventDefault()
                e.stopPropagation()
                e.nativeEvent.stopImmediatePropagation()
            }}
            onDragStart={e => {
                // popoverContext.store.show([...popoverContext.stack])
                e.preventDefault()
                e.stopPropagation()
                e.nativeEvent.stopImmediatePropagation()
            }}
            onContextMenu={e => {
                // popoverContext.store.show([...popoverContext.stack])
                e.preventDefault()
                e.stopPropagation()
                e.nativeEvent.stopImmediatePropagation()
            }}
        >
            {children}
        </div>,
        portalRoot
    )
})

export const PopoverArrow = React.forwardRef((props, ref) => {
    return (
        <svg
            ref={ref}
            className="cc-PopoverArrow"
            aria-label=""
            role="img"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 10 10"
        >
            <path vectorEffect="non-scaling-stroke" d="M0,8 L5,2 L10,8" />
        </svg>
    )
})

export const calculateGtinChecksum = text => {
    // The GTIN checksum digit is used for EAN-8, EAN-13, UPC-A
    // Calculating the GTIN checksum digit:
    // The digit are used from right to left (to determine even/odd-ness)
    // Calculate the sum of the numbers of odd and even positions
    // Example: 210987654321
    // Odd : 1 + 3 + 5 + 7 + 9 + 1 = 26
    // Even : 2 + 4 + 6 + 8 + 0 + 2 = 22
    // GTIN checksum formula :
    // ( 10 - [ (3 * Odd + Even) modulo 10 ] ) modulo 10
    // Since we're using 3*odd_total, we can also add 3*odd_individual and get
    // the same result.
    // The checksum of 210987654321 should be 0
    var multiplier = text.length % 2 ? 3 : 1 // starts at odd or even position
    var total = 0
    for (var digit of text) {
        total += multiplier * parseInt(digit, 10)
        multiplier = multiplier === 1 ? 3 : 1
    }
    return (10 - (total % 10)) % 10
}

export const calculateEAN8Checksum = text => {
    // First we check for digit-only and length 7
    if (!text.match(/^\d{7}$/)) {
        throw new Error(
            'Invalid input for calculateEAN8Checksum - we need a string with 7 digits'
        )
    }
    return calculateGtinChecksum(text)
}

export const calculateEAN13Checksum = text => {
    // First we check for digit-only and length 12
    if (!text.match(/^\d{12}$/)) {
        throw new Error(
            'Invalid input for calculateEAN13Checksum - we need a string with 12 digits'
        )
    }
    return calculateGtinChecksum(text)
}

export const calculateUPCAChecksum = text => {
    // First we check for digit-only and length 11
    if (!text.match(/^\d{11}$/)) {
        throw new Error(
            'Invalid input for calculateUPCAChecksum - we need a string with 11 digits'
        )
    }
    return calculateGtinChecksum(text)
}

export const isValidCode128CharacterSet = text => {
    // 0032-0095 ASCII (space, symbols, digits, symbols, A-Z, symbols)
    // 0096-0126 ASCII (symbol, a-z, symbols)
    // tab \9, lf \10, cr \13
    // other codes? assume ~009 encoding or some such?
    //
    // typed out character set:
    //  !"#$%&'()*+,-./:;<=>>?@[\]^_`{|}~
    // 0123456789
    // ABCDEFGHIJKLMNOPQRSTUVWXYZ
    // abcdefghijklmnopqrstuvwxyz
    // \t\r\n
    return text.match(/^[ !"#$%&'()*+,\-./:;<=>>?@[\\\]^_`{|}~0-9A-Za-z\n\r\t]+$/)
}

export const isValidCode39CharacterSet = text => {
    // uppercase letters (A through Z), numeric digits (0 through 9) and a number of
    // special characters (-, ., $, /, +, %, and space).
    return text.match(/^[A-Z0-9\-.$/+% ]+$/)
}

export const isValidInterleaved2Of5CharacterSet = text => {
    // pairs of digits only
    if (text.length % 2) {
        return false // odd lengths are invalid, they must have a leading 0 to even-ize
    } else {
        return text.match(/^[0-9]+$/)
    }
}

export const isValidPdf417TextCharacterSet = text => {
    // 0032-0095 ASCII (space, symbols, digits, symbols, A-Z, symbols)
    // 0096-0126 ASCII (symbol, a-z, symbols)
    // tab \9, lf \10, cr \13
    // This is the same ascii character set as code128
    return isValidCode128CharacterSet(text)
}

export const isValidDataMatrixCharacterSet = text => {
    // usually only ascii, but any byte can be encoded
    // still, scanner support for other bytes can be limited, so we
    // just check for ascii like in code128
    return isValidCode128CharacterSet(text)
}

export const isValidQRCharacterSet = text => {
    // usually only ascii, but any byte can be encoded
    // still, scanner support for other bytes can be limited, so we
    // just check for ascii like in code128
    return isValidCode128CharacterSet(text)
}

//
// AssetsContextmenu
//
// Actions on assets in the TreeNavigator or QueryNavigator
//
// The contextmenu is always triggered on an asset that is in the selection.

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Menu, MenuDivider, MenuItem, MenuText } from '../components'

import {
    MultiselectionTypes,
    determineAssetsMultiselectionType,
    multiselectionHasDeletedAssets,
} from '../utils/multiselection'

const DeletedAssetsSubmenu = observer(function DeletedAssetsSubmenu({
    asset,
    onAction,
}) {
    const { app } = useStore()

    const _onAction = action => {
        onAction && onAction(action)
    }

    return (
        <Menu>
            <MenuItem onClick={() => _onAction('undelete_assets_selection')}>
                {app.text('Undelete')}
            </MenuItem>
            <MenuDivider />
            <MenuItem
                className="cc-danger"
                onClick={() => _onAction('permanent_delete_assets_selection')}
            >
                {app.text('Permanent delete')}
            </MenuItem>
        </Menu>
    )
})

export const AssetsContextmenu = observer(function AssetsContextmenu({
    asset,
    assetsselection,
    onAction,
}) {
    const { app, data } = useStore()

    const selectiontype = determineAssetsMultiselectionType(assetsselection, data)
    const isNested = selectiontype === MultiselectionTypes.NESTED

    const _onAction = action => {
        onAction && onAction(action)
    }

    const empty = assetsselection.size === 0
    const singleselect = assetsselection.size === 1

    // can't create folder on multiselect
    // in fact, a different contextmenu is used when a single item is selected
    const disableCreateSubfolder = true

    // can delete multiple items (skips root && deleted ones)
    // can't delete root on single selection
    const disableDelete = empty || (singleselect && asset.is_root) || isNested

    // can undelete/permanent delete multiple items (skips root && not-deleted ones)
    // can't work on non-deleted asset on single selection
    const disableDeletedSubmenu =
        empty ||
        (singleselect && asset.status !== 'deleted') ||
        isNested ||
        !multiselectionHasDeletedAssets(assetsselection, data)

    return (
        <Menu>
            <MenuItem disabled={disableCreateSubfolder}>
                {app.text('New subfolder')}
            </MenuItem>
            <MenuDivider />
            <MenuItem
                disabled={disableDelete}
                onClick={() => _onAction('delete_assets_selection')}
            >
                {app.text('Delete')}
            </MenuItem>
            <MenuDivider />
            <MenuItem
                disabled={disableDeletedSubmenu}
                submenu={
                    <DeletedAssetsSubmenu
                        asset={asset}
                        assetsselection={assetsselection}
                        onAction={onAction}
                    />
                }
            >
                {app.text('Deleted assets')}
            </MenuItem>
            {isNested ? (
                <>
                    <MenuDivider />
                    <MenuText>
                        {app.text('No actions available for nested selections.')}
                    </MenuText>
                </>
            ) : undefined}
        </Menu>
    )
})

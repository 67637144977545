//
// PimPinboardStore
//
// The PimPinboardStore handles interaction with the pinboard area for pinning records.
// and record-selections (multi-select).
// Should work with treeview and search results.
//
// SIDE-EFFECT: when a user selects a record, we update the pimworksheet with that
//              same record
// TODO: remove side-effects
//
// A pinboard item can either be a record or a selection:
//
// item {
//     gid: <gid>,
//     type: 'record',
// }
//
// item {
//     gid: <gid>,
//     type: 'selection',
//     selection: [<recordgid>, <recordgid>, {'search':{…}}],
//     selectionsize: 17,
//     isFlat: true, // false means 'nested'
// }

import {
    makeObservable,
    observable,
    action,
    computed,
    reaction,
    runInAction,
} from 'mobx'
import { gid } from '../../utils/gid'

export class PimPinboardStore {
    __init = false
    _viewkey = null
    _rootstore = null
    _environment = null

    _pinned = new Map() // keeps insertion order

    constructor(key, rootstore, environment) {
        makeObservable(this, {
            __init: observable,
            _viewkey: observable,
            _rootstore: observable,
            _environment: observable,
            _pinned: observable,

            loadOnce: action.bound,
            fetch: action.bound,
            refetch: action.bound,
            _fetch: action.bound,

            pinnedItems: computed,

            addRecord: action.bound,
            addSelection: action.bound,
            remove: action.bound,
            clear: action.bound,
            selectRecord: action.bound,
            syncdataitem_callback: action.bound,
        })

        this.MAX_ITEMS = 5

        this._viewkey = key
        this._rootstore = rootstore
        this._environment = environment

        reaction(
            () => this._environment.get('language'),
            language => {
                if (this.__init) this._fetch(this._pinned)
            }
        )
    }

    get pinnedItems() {
        return Array.from(this._pinned.values())
    }

    loadOnce = () => {
        if (this.__init) return
        const pinned = this._rootstore.view.loadPerProject(
            this._viewkey + '/pinned',
            []
        )
        this.fetch(new Map(pinned))
        this._rootstore.api.register_syncdataitem_callback(this.syncdataitem_callback)
        this.__init = true
    }

    fetch(pinned) {
        this._rootstore.view.savePerProject(
            this._viewkey + '/pinned',
            Array.from(pinned.entries())
        )
        return this._fetch(pinned)
    }

    refetch() {
        return this._fetch(this._pinned)
    }

    _fetch(pinned) {
        if (!pinned.size) {
            this._pinned.clear()
            return
        }
        const recordgids = Array.from(pinned.values())
            .filter(item => item.type === 'record')
            .map(item => item.gid)
        return this._rootstore
            ._fetch('/records/get', {
                records: recordgids,
                language: this._environment.get('language'),
            })
            .then(result => {
                runInAction(() => {
                    this._pinned.replace(pinned)
                })
                return result
            })
            .catch(error => {})
    }

    exists(itemgid) {
        return this._pinned.has(itemgid)
    }

    canAdd() {
        return this._pinned.size < this.MAX_ITEMS
    }

    addRecord(recordgid) {
        if (!recordgid) return
        if (this.exists(recordgid)) return // no duplicates
        if (!this.canAdd()) return
        this._pinned.set(recordgid, { gid: recordgid, type: 'record' })
        this.fetch(this._pinned)
    }

    addSelection(selection, size, isFlat) {
        if (!this.canAdd()) return
        const itemgid = gid()
        this._pinned.set(itemgid, {
            gid: itemgid,
            type: 'selection',
            selection,
            size,
            isFlat,
        })
        this.fetch(this._pinned)
    }

    remove(itemgid) {
        this._pinned.delete(itemgid)
        this.fetch(this._pinned)
    }

    clear() {
        this._pinned.clear()
        this.fetch(this._pinned)
    }

    selectRecord(record) {
        this._rootstore.view.pimworksheet.setRecord(record)
    }

    syncdataitem_callback = (syncdataitem, data_before, data_after) => {
        // console.log('PimPinboardStore sync', syncdataitem, data_before, data_after)
        // we're only interested in data_type "records"
        // then, we're only interested in deletions (of pinned ids)
        // from those records, only for records that are in our store
        if (syncdataitem['data_type'] !== 'records') return
        if (syncdataitem['action'] !== 'DELETE') return
        if (!data_before || !this.exists(data_before.gid)) return
        this.remove(data_before.gid)
    }
}

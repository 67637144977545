//
// DeletedProjectMenu
//
// Actions on deleted projects in project management

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Menu, MenuDivider, MenuItem } from '../components'

export const DeletedProjectMenu = observer(function DeletedProjectMenu({
    projectgid,
    onAction,
}) {
    const store = useStore()
    const { app, _testdatabase_gid } = store

    const _onAction = action => {
        onAction && onAction(projectgid, action)
    }

    const disablePermanentDelete =
        !store.can('project.delete') || projectgid === _testdatabase_gid

    return (
        <Menu>
            <MenuItem onClick={() => _onAction('undelete')}>
                {app.text('Undelete')}
            </MenuItem>
            <MenuDivider />
            <MenuItem
                className="cc-danger"
                disabled={disablePermanentDelete}
                onClick={() => _onAction('permanent_delete')}
            >
                {app.text('Permanent delete')}
            </MenuItem>
        </Menu>
    )
})

//
// AssetField
//
// An asset-input (file or image).

import React, { useState, useEffect, useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'
import { useElementWidth } from '../../../../hooks/useElementWidth'
import { useMousePopover } from '../../../../hooks/useMousePopover'
import { useTooltipPopover } from '../../../../hooks/useTooltipPopover'
import { Transform, mergeTransforms } from '../../../../stores/imageservers/utils'
import { DragDropLinkedAsset } from '../../../../dragdrop/DragDropLinkedAsset'

import { AssetItem, AssetCard, AssetThumb, IconTile } from '../../../../panels'
import { AssetFieldMenu, UploadedAssetMenu } from '../../../../menus'
import { NavigationTileContext } from '../layoutbuilders/TilesLayoutBuilder'

import { VALIDATION } from '../../../../utils/validation'
import { validateField } from '../../../../stores/data/validators'
import { ValidationMessage } from '../../../../components'

export const AssetField = observer(function AssetField(props) {
    const {
        className,
        record,
        field,
        enabled,
        force_readonly,
        fromClass,
        component,
        renderkey,
        worksheet,
        ...other
    } = props

    const inNavigationTile = useContext(NavigationTileContext)
    const { data, view } = useStore()
    const [containerRef, containerWidth] = useElementWidth(20)

    const AssetFieldContextMenu = useMousePopover(AssetFieldMenu)
    const UploadedAssetPopoverMenu = useTooltipPopover(UploadedAssetMenu)
    const [droppedfile, setDroppedFile] = useState(null) // only drop on existing asset

    let classes = 'cc-Field cc-AssetField'
    if (!enabled) classes += ' cc-disabled'
    if (className) classes += ' ' + className

    const fieldvalue =
        record.localized_fields && record.localized_fields.get(field.name)

    const validation = validateField(fieldvalue, field, record.language)
    if (validation.result === VALIDATION.ERROR) {
        classes += ' validation-error'
    } else if (validation.result === VALIDATION.REPORT) {
        classes += ' validation-report'
    }

    const componentstyle =
        component && component.style
            ? component.style
            : field.type === 'image'
            ? 'thumb'
            : 'item'
    classes += ' cc-AssetField-' + componentstyle
    const componentformat =
        component && component.thumbformat ? component.thumbformat : undefined

    const [asset, setAsset] = useState(null)
    useEffect(() => {
        let isMounted = true
        let controller = new AbortController()
        async function asyncEffect() {
            setAsset(null)
            if (fieldvalue) {
                if (!data.assets.has(fieldvalue)) {
                    await data.fetchAssetByGid(fieldvalue, {
                        signal: controller.signal,
                    })
                }
                const asyncasset = data.assets.get(fieldvalue)
                if (isMounted) {
                    setAsset(asyncasset)
                }
            }
        }
        asyncEffect()
        return () => {
            controller.abort('Unmounted')
            isMounted = false
            return isMounted
        }
    }, [fieldvalue, data])

    const pad = componentstyle === 'card' ? 10 : 0

    // default: fit, square
    let transform_fit = 'fit'
    let transform_width = containerWidth - pad
    let transform_height = transform_width

    if (componentstyle === 'thumb' && componentformat) {
        if (componentformat.startsWith('fill_')) {
            transform_fit = 'fill'
        }
        if (componentformat.endsWith('_banner')) {
            transform_height = (6 / 20) * transform_width
        }
        if (componentformat.endsWith('_landscape')) {
            transform_height = (9 / 16) * transform_width
        }
        if (componentformat.endsWith('_portrait')) {
            transform_height = (16 / 9) * transform_width
        }
        if (componentformat.endsWith('_pillar')) {
            transform_height = (20 / 6) * transform_width
        }
        if (componentformat === 'proportional' && asset) {
            const asset_width = asset.info.get('width')
            const asset_height = asset.info.get('height')
            if (asset_width && asset_height) {
                transform_height = (asset_height / asset_width) * transform_width
            }
        }
    }

    const transformResize = new Transform(
        transform_fit,
        transform_width,
        transform_height
    )
    const transform = asset
        ? mergeTransforms(asset.mainTransform, transformResize)
        : transformResize

    if (asset) {
        classes += ' status-' + asset.status
    }

    const onAddAsset = (asset, otherassetgid) => {
        record.setField(field, otherassetgid)
        record.commitIfModified()
    }

    const onReplaceAsset = (asset, otherassetgid) => {
        record.setField(field, otherassetgid)
        record.commitIfModified()
    }

    const onUploadNewAsset = (asset, file) => {
        setDroppedFile(null)
        const folder_gid = field.options.get('folder')
        if (!file.type) {
            console.log(file.name + ' - folder upload not supported')
            return false
        }
        if (folder_gid) {
            return data.actions.assets.upload(file, folder_gid).then(result => {
                record.setField(field, result['asset'])
                record.commitIfModified()
                return result['asset']
            })
        } else {
            return data.actions.assets.uploadToFolder(file, '/').then(result => {
                record.setField(field, result['asset'])
                record.commitIfModified()
                return result['asset']
            })
        }
    }

    const onUploadUpdateAsset = (asset, file) => {
        setDroppedFile(null)
        if (!file.type) {
            console.log(file.name + ' - folder upload not supported')
            return false
        }
        return data.actions.assets.uploadUpdate(file, asset.gid).then(result => {
            // record.setField(field, result['asset'])
            // record.commitIfModified()
            return result['asset']
        })
    }

    const onCancel = () => {
        setDroppedFile(null)
    }

    const onRemoveAsset = () => {
        record.setField(field, '')
        record.commitIfModified()
    }

    const onRevealInDam = asset => {
        view.damworksheet.setAsset(asset)
        view.damtree.datamanager.setAsset(asset)
        // this is a hack to get out of a not-that-well-designed auto-updating
        // system - when lazyvscrolltiles does two refetches when it gets into focus,
        // it fetches the old asset, triggering a new setAsset call... if we wait a tiny
        // bit, it can refetch the newly set asset
        window.setTimeout(() => view.setWorkspace('dam'), 200)
    }

    const onAssetFieldAction = (action, asset, p1) => {
        const actions = {
            add_asset: onAddAsset,
            replace_asset: onReplaceAsset,
            upload_new: onUploadNewAsset,
            upload_update: onUploadUpdateAsset,
            cancel: onCancel,
            remove_asset: onRemoveAsset,
            reveal_in_dam: onRevealInDam,
        }
        AssetFieldContextMenu.hide()
        UploadedAssetPopoverMenu.hide()
        if (!(action in actions)) {
            console.log(`onAssetFieldAction: unhandled action '${action}'`)
            return
        }
        actions[action](asset, p1)
    }

    const onShowAssetFieldContextMenu = e => {
        UploadedAssetPopoverMenu.hide()
        AssetFieldContextMenu.onShow(e)
    }

    const onClickShowAssetFieldContextMenu = e => {
        if (enabled || !inNavigationTile) {
            onShowAssetFieldContextMenu(e)
        }
    }

    const icontileanchor = componentstyle === 'item' ? 'start' : 'center'
    const iconplaceholder = enabled ? 'plus' : 'image'

    const onDrop = (dragitem, linkedasset, isoverzone) => {
        if (dragitem.hasOwnProperty('files')) {
            const file = dragitem.files[0]
            // drop on add
            if (!linkedasset) {
                onUploadNewAsset(linkedasset, file)
            } else {
                // store dropped file, show UploadedAssetMenu first
                setDroppedFile(file)
                UploadedAssetPopoverMenu.show()
            }
        } else {
            // onDrop only 'inside'
            record.setField(field, dragitem.gid)
            record.commitIfModified()
        }
    }

    const onUploadAction = (action, p1) => {
        const actions = {
            upload_new: onUploadNewAsset,
            upload_update: onUploadUpdateAsset,
            cancel: onCancel,
        }
        AssetFieldContextMenu.hide()
        UploadedAssetPopoverMenu.hide()
        if (!(action in actions)) {
            console.log(`onUploadAction: unhandled action '${action}'`)
            return
        }
        actions[action](asset, p1)
    }

    return (
        <div ref={containerRef} className={classes} {...other}>
            <AssetFieldContextMenu.Panel
                asset={asset}
                enabled={enabled}
                list={false}
                onAction={onAssetFieldAction}
            />
            <UploadedAssetPopoverMenu.Panel
                file={droppedfile}
                onAction={onUploadAction}
            />
            <div ref={UploadedAssetPopoverMenu.anchorRef}>
                <DragDropLinkedAsset
                    field={field}
                    linkedasset={asset}
                    disabled={!enabled}
                    onDrop={onDrop}
                >
                    {!asset ? (
                        <IconTile
                            width={32}
                            className="cc-dimmed"
                            icon={iconplaceholder}
                            anchor={icontileanchor}
                            onClick={onClickShowAssetFieldContextMenu}
                        />
                    ) : componentstyle === 'card' ? (
                        <AssetCard
                            asset={asset}
                            transform={transform}
                            iconplaceholder={iconplaceholder}
                            onClick={onClickShowAssetFieldContextMenu}
                            onContextMenu={onShowAssetFieldContextMenu}
                        />
                    ) : componentstyle === 'thumb' ? (
                        <AssetThumb
                            asset={asset}
                            transform={transform}
                            iconplaceholder={iconplaceholder}
                            onClick={onClickShowAssetFieldContextMenu}
                            onContextMenu={onShowAssetFieldContextMenu}
                        />
                    ) : (
                        <AssetItem
                            asset={asset}
                            iconplaceholder={iconplaceholder}
                            onClick={onClickShowAssetFieldContextMenu}
                            onContextMenu={onShowAssetFieldContextMenu}
                        />
                    )}
                </DragDropLinkedAsset>
            </div>
            <ValidationMessage validation={validation} />
        </div>
    )
})

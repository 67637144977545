// Text
//
// Simple text.

import React from 'react'

export const Text = React.forwardRef((props, ref) => {
    const { className, ...other } = props
    let classes = 'cc-Text'
    if (className) classes += ' ' + className
    return <div ref={ref} className={classes} {...other} />
})

export const GROW_RANGE = { min: 1, max: 10 }
export const FIXED_RANGE = { min: 50, max: 800 }

export const sizetypeconverter = oldsize => {
    // size can be old-skool, meaning empty, 'grow', or a number
    // new-skool is a data object { 'grow': 'fixed', value: 250 }
    // 'grow' can have three values: 'auto' (content-based), 'grow' (remaining space
    // is divided between all 'grow' views according to their ratio 'value' 1…10), or
    // 'fixed' which is a pixel 'value' (50…800).
    let newsize = { grow: 'auto', value: null }
    if (
        oldsize === undefined ||
        oldsize === null ||
        oldsize === '' ||
        oldsize === 'auto'
    ) {
        // newsize is default
    } else if (oldsize === 'grow') {
        newsize.grow = 'grow'
        newsize.value = 1
    } else if (typeof oldsize !== 'object') {
        newsize.grow = 'fixed'
        newsize.value = typeof oldsize === 'number' ? oldsize : parseInt(oldsize)
    } else {
        // validate new-skool version
        if (oldsize.grow && ['auto', 'grow', 'fixed'].includes(oldsize.grow)) {
            newsize.grow = oldsize.grow
        }
        if (!oldsize.value) {
            if (newsize.grow === 'auto') {
                newsize.value = null
            } else if (newsize.grow === 'grow') {
                newsize.value = 1
            } else if (newsize.grow === 'fixed') {
                newsize.value = 250
            }
            newsize.grow = oldsize.grow
        } else {
            newsize.value =
                typeof oldsize.value === 'number'
                    ? oldsize.value
                    : parseInt(oldsize.value)
        }
    }
    if (newsize.grow === 'grow') {
        if (newsize.value < GROW_RANGE['min'] || newsize.value > GROW_RANGE['max']) {
            newsize.value = 1
        }
    } else if (newsize.grow === 'fixed') {
        newsize.value = Math.min(
            Math.max(newsize.value, FIXED_RANGE['min']),
            FIXED_RANGE['max']
        )
    }
    return newsize
}

export const LAYOUTCOMPONENTTYPECONFIG = {
    root: {
        name: { type: 'text', default: 'New layout <x>' },
        isComponentContainer: true,
    },
    vertical: {
        name: { type: 'text' },
        isContainer: true,
        size: { type: 'size' },
    },
    horizontal: {
        name: { type: 'text' },
        isContainer: true,
        size: { type: 'size' },
    },
    spacer: {
        size: { type: 'size' },
    },
    field: {
        field: { type: 'field' },
        typeconfig: {
            text: {
                style: {
                    type: 'select',
                    options: {
                        note: 'note',
                        regular: 'regular',
                        bold: 'bold',
                        subtitle: 'subtitle',
                        title: 'title',
                    },
                    default: 'regular',
                },
            },
            textline: {
                style: {
                    type: 'select',
                    options: {
                        note: 'note',
                        regular: 'regular',
                        bold: 'bold',
                        subtitle: 'subtitle',
                        title: 'title',
                    },
                    default: 'regular',
                },
            },
            textlist: {
                style: {
                    type: 'select',
                    options: {
                        note: 'note',
                        regular: 'regular',
                        bold: 'bold',
                        bullets: 'bullets',
                        subtitle: 'subtitle',
                        title: 'title',
                    },
                    default: 'bullets',
                },
            },
            boolean: {
                style: {
                    type: 'select',
                    options: {
                        note: 'note',
                        regular: 'regular',
                        bold: 'bold',
                        subtitle: 'subtitle',
                        title: 'title',
                    },
                    default: 'regular',
                },
            },
            number: {
                style: {
                    type: 'select',
                    options: {
                        note: 'note',
                        regular: 'regular',
                        bold: 'bold',
                        subtitle: 'subtitle',
                        title: 'title',
                    },
                    default: 'regular',
                },
            },
            decimal: {
                style: {
                    type: 'select',
                    options: {
                        note: 'note',
                        regular: 'regular',
                        bold: 'bold',
                        subtitle: 'subtitle',
                        title: 'title',
                    },
                    default: 'regular',
                },
            },
            code: {
                style: {
                    type: 'select',
                    options: {
                        note: 'note',
                        regular: 'regular',
                        bold: 'bold',
                        subtitle: 'subtitle',
                        title: 'title',
                    },
                    default: 'regular',
                },
            },
            barcode: {
                style: {
                    type: 'select',
                    options: {
                        plaintext: 'plain text',
                        preview: 'with preview',
                    },
                    default: 'preview',
                },
                layout: 'right',
            },
            image: {
                style: {
                    type: 'select',
                    options: {
                        card: 'card',
                        item: 'item',
                        thumb: 'thumb',
                    },
                    default: 'thumb',
                },
                thumbformat: {
                    type: 'select',
                    options: {
                        fit_square: 'fit square',
                        fit_banner: 'fit banner',
                        fit_landscape: 'fit landscape',
                        fit_portrait: 'fit portrait',
                        fit_pillar: 'fit pillar',
                        fill_square: 'fill square',
                        fill_banner: 'fill banner',
                        fill_landscape: 'fill landscape',
                        fill_portrait: 'fill portrait',
                        fill_pillar: 'fill pillar',
                        proportional: 'proportional',
                    },
                    default: 'fit_square',
                },
                layout: 'right',
            },
            file: {
                style: {
                    type: 'select',
                    options: {
                        card: 'card',
                        item: 'item',
                        thumb: 'thumb',
                    },
                    default: 'item',
                },
                thumbformat: {
                    type: 'select',
                    options: {
                        fit_square: 'fit square',
                        fit_banner: 'fit banner',
                        fit_landscape: 'fit landscape',
                        fit_portrait: 'fit portrait',
                        fit_pillar: 'fit pillar',
                        fill_square: 'fill square',
                        fill_banner: 'fill banner',
                        fill_landscape: 'fill landscape',
                        fill_portrait: 'fill portrait',
                        fill_pillar: 'fill pillar',
                        proportional: 'proportional',
                    },
                    default: 'fit_square',
                },
                layout: 'right',
            },
            record: {
                style: {
                    type: 'select',
                    options: {
                        card: 'card',
                        item: 'item',
                        thumb: 'thumb',
                    },
                    default: 'item',
                },
                layout: 'right',
            },
            imagelist: {
                style: {
                    type: 'select',
                    options: {
                        card: 'card',
                        item: 'item',
                        thumb: 'thumb',
                    },
                    default: 'thumb',
                },
                columns: {
                    type: 'number',
                    min: 1,
                    default: 3,
                },
                layout: 'right',
            },
            filelist: {
                style: {
                    type: 'select',
                    options: {
                        card: 'card',
                        item: 'item',
                        thumb: 'thumb',
                    },
                    default: 'item',
                },
                columns: {
                    type: 'number',
                    min: 1,
                    default: 1,
                },
                layout: 'right',
            },
            recordlist: {
                style: {
                    type: 'select',
                    options: {
                        card: 'card',
                        item: 'item',
                        thumb: 'thumb',
                    },
                    default: 'item',
                },
                columns: {
                    type: 'number',
                    min: 1,
                    default: 1,
                },
                layout: 'right',
            },
            fieldpicker: {
                layout: 'right',
            },
            class: {},
            classlist: {
                style: {
                    type: 'select',
                    options: {
                        table: 'table',
                        tiles: 'tiles',
                    },
                    default: 'table',
                },
                columns: {
                    type: 'number',
                    min: 1,
                    default: 3,
                },
            },
        },
        editable: { type: 'boolean', default: true },
        placeholder: { type: 'localtext' },
    },
    fieldlist: {
        editable: { type: 'boolean', default: true },
    },
    class: {
        class: { type: 'class' },
        editable: { type: 'boolean', default: true },
    },
    definition: {
        editable: { type: 'boolean', default: true },
    },
    autoextend: {
        editable: { type: 'boolean', default: true },
    },
    autogenerate: {
        editable: { type: 'boolean', default: true },
    },
    text: {
        name: { type: 'text' },
        text: { type: 'localtext' },
        style: {
            type: 'select',
            options: {
                note: 'note',
                regular: 'regular',
                bold: 'bold',
                subtitle: 'subtitle',
                title: 'title',
            },
            default: 'regular',
        },
    },
    image: {
        name: { type: 'text' },
        image: { type: 'image' },
    },
    divider: {},
    tiles: {
        name: { type: 'text' },
        isChildContainer: true,
        isContainer: true,
        itemheight: { type: 'number', min: 10, default: 200 },
        height: { type: 'number', min: 30, default: 320 }, // should really be larger than itemheight
        columns: { type: 'number', min: 1, default: 4 },
        editable: { type: 'boolean', default: false },
        navigation: { type: 'boolean', default: true },
        placeholder: {
            type: 'localtext',
            default: { en: '[no children yet]' },
        },
    },
    rows: {
        name: { type: 'text' },
        isChildContainer: true,
        isContainer: true,
        itemheight: { type: 'number', min: 10, default: 200 },
        height: { type: 'number', min: 30, default: 320 }, // should really be larger than itemheight
        editable: { type: 'boolean', default: false },
        navigation: { type: 'boolean', default: true },
        placeholder: {
            type: 'localtext',
            default: { en: '[no children yet]' },
        },
    },
    table: {
        name: { type: 'text' },
        isChildContainer: true,
        isFieldContainer: true,
        isContainer: true,
        headerheight: { type: 'number', min: 10, default: 40 },
        rowheight: { type: 'number', min: 10, default: 25 },
        height: { type: 'number', min: 20, default: 320 }, // should really be larger than headerheight + 1*rowheight
        editable: { type: 'boolean', default: true },
        navigation: { type: 'boolean', default: true },
        placeholder: {
            type: 'localtext',
            default: { en: '[no children yet]' },
        },
    },
}

//
// useGlobalEventListener(eventName, handler)
//
// Listens for the event in the whole window. Subsequent events will trigger
// the handler.
// E.g.
//
//    useGlobalEventListener('mouseup', mouseupHandler)

import { useRef, useEffect } from 'react'

export const useGlobalEventListener = (eventName, handler) => {
    const savedHandler = useRef()

    useEffect(() => {
        savedHandler.current = handler
    }, [handler])

    useEffect(() => {
        const isSupported = window && window.addEventListener
        if (!isSupported) return
        const eventListener = event => savedHandler.current(event)
        window.addEventListener(eventName, eventListener)
        return () => {
            window.removeEventListener(eventName, eventListener)
        }
    }, [eventName])
}

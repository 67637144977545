//
// SiblingDefinitionsMenu for RecordInspector
//

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../stores'

import { VView } from '../../../appview'
import { Header, Text, Menu, MenuHeader, MenuItem } from '../../../components'
import { RecordDefinitionChangePanel } from '../../../panels'

export const SiblingDefinitionsMenu = observer(function SiblingDefinitionsMenu({
    worksheet,
    onAction,
}) {
    const { app } = useStore()

    const _onAction = (action, parameter) => {
        onAction && onAction(action, parameter)
    }

    const ConfirmChangeSubmenu = observer(function ConfirmChangeSubmenu({
        onConfirm,
        onCancel,
    }) {
        const { app } = useStore()

        const _onConfirm = () => {
            onConfirm && onConfirm()
        }

        const _onCancel = () => {
            onCancel && onCancel()
        }

        return (
            <VView className="actions-panel recorddefinition-change-panel">
                <div className="inspector">
                    <Header className="cc-danger">{app.text('Warning')}</Header>
                    <Text>
                        {app.text(
                            `Changing the definitions can result in data loss
                             when fields are no longer available in the new
                             definition. This data cannot be recovered by
                             changing back to the previous definition.`
                        )}
                    </Text>
                </div>
                <Menu>
                    <MenuItem onClick={_onCancel}>{app.text('Cancel')}</MenuItem>
                    <MenuItem className="cc-danger" onClick={_onConfirm}>
                        {app.text('Yes, change the definitions')}
                    </MenuItem>
                </Menu>
            </VView>
        )
    })

    return (
        <Menu>
            <MenuHeader>{app.text('Sibling definitions')}</MenuHeader>
            <MenuItem
                className="cc-notice"
                submenu={
                    <ConfirmChangeSubmenu
                        onConfirm={() => _onAction('change_siblings_to_current')}
                        onCancel={() => _onAction('cancel')}
                    />
                }
            >
                {app.text('Change all to current')}
            </MenuItem>
            <MenuItem
                className="cc-notice"
                submenu={
                    <RecordDefinitionChangePanel
                        worksheet={worksheet}
                        alwaysConfirm={true}
                        onChangeDefinition={new_definition_gid => {
                            _onAction('change_siblings_to', new_definition_gid)
                        }}
                        onCancel={() => _onAction('cancel')}
                    />
                }
            >
                {app.text('Change all to …')}
            </MenuItem>
        </Menu>
    )
})

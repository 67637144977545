//
// ClassFieldMenu
//
// Actions on fields in the definition data worksheet
//
// ClassFieldMenu
// - remove_field
// - goto_field

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Menu, MenuDivider, MenuItem } from '../components'

export const ClassFieldMenu = observer(function ClassFieldMenu({
    definition,
    onAction,
}) {
    const { app } = useStore()

    const _onAction = action => {
        onAction && onAction(action)
    }

    return (
        <Menu>
            <MenuItem className="cc-notice" onClick={() => _onAction('remove_field')}>
                {app.text('Remove from class')}
            </MenuItem>
            <MenuDivider />
            <MenuItem onClick={() => _onAction('goto_field')}>
                {app.text('Go to field')}
            </MenuItem>
        </Menu>
    )
})

//
// TreeSchemaClassMenu & TreeSchemaClassHeaderMenu
//
// Actions on classes in the TreeNavigator
//
// TreeSchemaClassHeaderMenu
// - create_class
//
// TreeSchemaClassMenu
// - commit_class
// - revert_class
// - save_as_new_class
// - delete_class

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Menu, MenuDivider, MenuItem } from '../components'

export const TreeSchemaClassHeaderMenu = observer(function TreeSchemaClassHeaderMenu({
    onAction,
}) {
    const { app } = useStore()

    const _onAction = action => {
        onAction && onAction(action)
    }

    return (
        <Menu>
            <MenuItem onClick={() => _onAction('create_class')}>
                {app.text('New class')}
            </MenuItem>
        </Menu>
    )
})

export const TreeSchemaClassMenu = observer(function TreeSchemaClassMenu({
    class_,
    onAction,
}) {
    const { app } = useStore()

    const _onAction = action => {
        onAction && onAction(action)
    }

    if (!class_) return null

    // already committed? can't commit again
    const disableCommit = class_.is_committed

    // already committed? can't revert anymore (revert new is same as delete, so
    // does work, but we disable it for clarity -> use delete_class instead)
    const disableRevert = class_.is_committed || class_.is_new

    // new or committed? can't save as new then
    const disableSaveAsNew = class_.is_committed || class_.is_new

    // can only copy committed
    const disableCopy = !class_.is_committed

    // modified (but not new)? can't delete (but you can revert)
    const disableDelete = class_.is_working_copy && !class_.is_new

    return (
        <Menu>
            <MenuItem
                disabled={disableCommit}
                onClick={() => _onAction('commit_class')}
            >
                {app.text('Commit class')}
            </MenuItem>
            <MenuItem
                disabled={disableRevert}
                onClick={() => _onAction('revert_class')}
            >
                {app.text('Revert changes')}
            </MenuItem>
            <MenuItem
                disabled={disableSaveAsNew}
                onClick={() => _onAction('save_as_new_class')}
            >
                {app.text('Save as new')}
            </MenuItem>
            <MenuDivider />
            <MenuItem disabled={disableCopy} onClick={() => _onAction('copy_class')}>
                {app.text('Duplicate class')}
            </MenuItem>
            <MenuItem
                disabled={disableDelete}
                onClick={() => _onAction('delete_class')}
            >
                {app.text('Delete class (if unused)')}
            </MenuItem>
        </Menu>
    )
})

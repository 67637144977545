//
// ChannelsMenu
//
// Actions on one or more channels in the RecordInspector

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Menu, MenuItem } from '../components'

export const ChannelsMenu = observer(function ChannelsMenu({
    selectedchannelkeys,
    onAction,
}) {
    const { app } = useStore()

    const _onAction = action => {
        onAction && onAction(action)
    }

    return (
        <Menu>
            <MenuItem onClick={() => _onAction('copy_from_parent')}>
                {app.text('Copy from parent')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('copy_down')}>
                {app.text('Copy to subtree')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('copy_enabled_up')}>
                {app.text('Copy upwards')}
            </MenuItem>
        </Menu>
    )
})

//
// SignUpPanel
//
// Sign up for an account
import React, { useState, useEffect } from 'react'
import { useStore } from '../stores'
import { observer } from 'mobx-react-lite'
import { useDebounce } from '../hooks/useDebounce'
import { useKeyboard } from '../hooks/useKeyboard'

import { VView, Spacer } from '../appview'
import {
    Bar,
    Button,
    PasswordInput,
    Property,
    TextInput,
    Text,
    ValidationMessage,
} from '../components'
import { VALIDATION, ValidationResult } from '../utils/validation'
import {
    validateUsername,
    validateEmail,
    validatePassword,
} from '../stores/data/validators'
import { keyboard } from '../utils/keyboard'

const hasInput = value => {
    return !!value && value.trim().length > 0
}

export const SignUpPanel = observer(function SignUpPanel({
    email: initialEmail,
    emailReadonly,
    onSuccessfulSignUp,
    setTitle,
}) {
    const store = useStore()
    const app = store.app

    // signup fields
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState(initialEmail ? initialEmail : '')
    const [validationcode, setValidationcode] = useState(null)
    const [verificationcode, setVerificationcode] = useState(null)

    // field validation
    const [isValidUsername, setIsValidUsername] = useState(false)
    const [isValidNewPassword, setIsValidNewPassword] = useState(false)
    const [isValidEmail, setIsValidEmail] = useState(false)

    const debouncedUsername = useDebounce(username, 200)
    const debouncedPassword = useDebounce(password, 200)
    const debouncedEmail = useDebounce(email, 200)

    useEffect(() => {
        let isMounted = true
        // this works for returned Promises and for returned regular values
        new Promise(resolve => resolve(validateUsername(debouncedUsername))).then(
            validationresult => {
                if (isMounted) {
                    setIsValidUsername(validationresult.result === VALIDATION.SUCCESS)
                }
            }
        )
        // .catch(error => {})
        return () => (isMounted = false)
    }, [debouncedUsername])

    useEffect(() => {
        let isMounted = true
        // this works for returned Promises and for returned regular values
        new Promise(resolve => resolve(validatePassword(debouncedPassword))).then(
            validationresult => {
                if (isMounted) {
                    setIsValidNewPassword(
                        validationresult.result === VALIDATION.SUCCESS
                    )
                }
            }
        )
        // .catch(error => {})
        return () => (isMounted = false)
    }, [debouncedPassword])

    useEffect(() => {
        let isMounted = true
        // this works for returned Promises and for returned regular values
        new Promise(resolve => resolve(validateEmail(debouncedEmail))).then(
            validationresult => {
                if (isMounted) {
                    setIsValidEmail(validationresult.result === VALIDATION.SUCCESS)
                }
            }
        )
        // .catch(error => {})
        return () => (isMounted = false)
    }, [debouncedEmail])

    // form validation
    const [requestsignupvalidation, setRequestsignupvalidation] = useState(null)
    const [signupvalidation, setSignupvalidation] = useState(null)

    const ACTIVITIES = {
        SIGNUP_REQUEST: 0,
        SIGNUP: 1,
    }
    const [activity, setActivity] = useState(ACTIVITIES.SIGNUP_REQUEST)

    const onReenterFields = () => {
        setValidationcode('')
        setVerificationcode('')
        setRequestsignupvalidation(null)
        setSignupvalidation(null)
        setActivity(ACTIVITIES.SIGNUP_REQUEST)
    }

    const onSignupRequest = () => {
        setActivity(ACTIVITIES.SIGNUP)
        store
            .signupRequest(username.trim(), password.trim(), email.trim())
            .then(result => {
                setValidationcode(result['validation_code'])
            })
            .catch(() => {
                const validationerror = new ValidationResult(
                    VALIDATION.ERROR,
                    'Signup request failed, please try again.'
                )
                setRequestsignupvalidation(validationerror)
                setActivity(ACTIVITIES.SIGNUP_REQUEST)
            })
    }

    const onSignup = () => {
        store
            .signup(
                username.trim(),
                password.trim(),
                email.trim(),
                validationcode.trim(),
                verificationcode.trim()
            )
            .then(result => {
                if (result) {
                    setPassword('')
                    if (onSuccessfulSignUp) onSuccessfulSignUp()
                    else store.autoLocation()
                } else {
                    setValidationcode(null)
                }
            })
            .catch(() => {
                const validationerror = new ValidationResult(
                    VALIDATION.ERROR,
                    'Verification code incorrect or timed out, we could not sign you up.'
                )
                setSignupvalidation(validationerror)
            })
    }

    const signuprequestFieldsEnabled = activity === ACTIVITIES.SIGNUP_REQUEST
    const signuprequestEnabled =
        signuprequestFieldsEnabled &&
        isValidUsername &&
        isValidNewPassword &&
        isValidEmail

    const signupFieldsEnabled = activity === ACTIVITIES.SIGNUP
    const signupEnabled =
        signupFieldsEnabled &&
        isValidUsername &&
        isValidNewPassword &&
        isValidEmail &&
        hasInput(validationcode) &&
        hasInput(verificationcode)

    const [inInputField, setInInputField] = useState(false)

    const onFocus = e => {
        setInInputField(true)
    }

    const onBlur = e => {
        setInInputField(false)
    }

    useKeyboard(event => {
        if (inInputField && keyboard.test(event, keyboard.ENTER)) {
            if (activity === ACTIVITIES.SIGNUP_REQUEST && signuprequestEnabled) {
                onSignupRequest()
            } else if (activity === ACTIVITIES.SIGNUP && signupEnabled) {
                onSignup()
            }
        }
    })

    const Activity =
        activity === ACTIVITIES.SIGNUP_REQUEST ? (
            <>
                <Property label={app.text('Username')}>
                    <TextInput
                        enabled={signuprequestFieldsEnabled}
                        validate={validateUsername}
                        onChange={e => setUsername(e.target.value)}
                        value={username}
                        placeholder={app.text("You'll log in with this name")}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />
                </Property>
                <Property label={app.text('Password')}>
                    <PasswordInput
                        enabled={signuprequestFieldsEnabled}
                        validate={validatePassword}
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />
                </Property>
                <Property label={app.text('Email address')}>
                    <TextInput
                        enabled={!emailReadonly && signuprequestFieldsEnabled}
                        validate={validateEmail}
                        onChange={e => !emailReadonly && setEmail(e.target.value)}
                        value={email}
                        placeholder={app.text(
                            "Where you'll get your verification code"
                        )}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />
                </Property>
                <Spacer size={20} />
                <Property label={''}>
                    <Bar raised>
                        <Button
                            action
                            disabled={!signuprequestEnabled}
                            onClick={onSignupRequest}
                        >
                            {app.text('Send me a verification code at {email}', {
                                email: email.trim().length ? email : '...',
                            })}
                        </Button>
                    </Bar>
                    {requestsignupvalidation ? (
                        <ValidationMessage validation={requestsignupvalidation} />
                    ) : undefined}
                </Property>
            </>
        ) : (
            <>
                <Property label={app.text('Username')}>
                    <Text>{username}</Text>
                </Property>
                <Property label={app.text('Email address')}>
                    <Text>{email}</Text>
                </Property>
                <div style={{ display: 'none' }}>{verificationcode}</div>
                <Property label={app.text('Verification code')}>
                    <TextInput
                        enabled={signupFieldsEnabled}
                        onChange={e => {
                            setSignupvalidation(null)
                            setVerificationcode(e.target.value)
                        }}
                        value={verificationcode}
                        placeholder={app.text("It's in your email, we just sent it")}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />
                </Property>
                <Spacer size={20} />
                <Property label={''}>
                    <Bar raised>
                        <Button action disabled={!signupEnabled} onClick={onSignup}>
                            {app.text('Sign up as {username}', {
                                username: username.trim().length ? username : '...',
                            })}
                        </Button>
                    </Bar>
                    {signupvalidation ? (
                        <ValidationMessage validation={signupvalidation} />
                    ) : undefined}
                </Property>
                <Spacer size={20} />
                <Property label={''}>
                    <Text className="link" onClick={() => onReenterFields()}>
                        {app.text('Request a new verification code.')}
                    </Text>
                </Property>
            </>
        )

    return <VView className="form signup-form">{Activity}</VView>
})

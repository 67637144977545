//
// AssetWorksheet
//
// Switch to a specific worksheet based on worksheet asset type; file or folder

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../stores'

import { VView, VScrollView } from '../../appview'

import { FolderWorksheet, FileTransformWorksheet, FileWorksheet } from './worksheets'

export const AssetWorksheet = observer(function AssetWorksheet({ worksheet }) {
    const { app, imageserver } = useStore()

    if (!worksheet.asset) {
        return (
            <VScrollView>
                <VView className="worksheet">
                    {app.text('No folder or file selected.')}
                </VView>
            </VScrollView>
        )
    }

    if (worksheet.asset.is_file) {
        if (imageserver.canTransform(worksheet.asset)) {
            return <FileTransformWorksheet worksheet={worksheet} />
        } else {
            return <FileWorksheet worksheet={worksheet} />
        }
    } else {
        return <FolderWorksheet worksheet={worksheet} />
    }
})

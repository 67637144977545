//
// Divider
//
// The Divider is used as a single-pixel line to visually separate sibling views in
// either horizontal (HView) or vertical (VView) views.
//
// The background-color has a default, but you can override this in your stylesheet:
//     .av-Divider { background-color: #eee; }
//     @media (prefers-color-scheme: dark) {
//       .av-Divider { background-color: #535353; }
//     }
//
// You can also change the default line-width, for example to three pixels:
//     .av-HView > .av-Divider { width: 3px; }
//     .av-VView > .av-Divider { height: 3px; }

import React from 'react'

export const Divider = React.forwardRef(function Divider(props, ref) {
    var { className, ...other } = props
    var classes = 'av-Divider'
    if (className) classes += ' ' + className
    return <div ref={ref} className={classes} {...other} />
})

//
// UploadedAssetMenu
//
// Show fileinfo for uploaded file (dropped or picked from dialog)
// and add new/update/cancel buttons

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'
import { humanReadableBytes } from '../utils/text'

import { VView } from '../appview'
import { Header, Text, Menu, MenuDivider, MenuItem } from '../components'

const ConfirmUpdateSubmenu = observer(function ConfirmUpdateSubmenu({ onAction }) {
    const { app } = useStore()

    const _onAction = (e, action) => {
        onAction && onAction(action)
    }

    return (
        <Menu>
            <MenuItem
                className="cc-danger"
                onClick={e => _onAction(e, 'upload_update')}
            >
                {app.text('Yes, update the original asset')}
            </MenuItem>
        </Menu>
    )
})

export const UploadedAssetMenu = observer(
    React.forwardRef(function UploadedAssetMenu({ file, onAction, ...other }, ref) {
        const { app } = useStore()

        const _onAction = action => {
            onAction && onAction(action, file)
        }

        if (!file || !file.type) {
            return null
        }

        return (
            <VView
                ref={ref}
                className="uploaded-asset-menu"
                onClick={e => e.nativeEvent.stopImmediatePropagation()}
                style={{ width: 200 }}
                {...other}
            >
                <div className="inspector">
                    <Header>{app.text('Uploaded file')}</Header>
                    <Text>
                        {file.name}
                        <br />
                        {app.text('size: {size}...', {
                            size: humanReadableBytes(file.size),
                        })}
                    </Text>
                </div>
                <Menu>
                    <MenuItem onClick={() => _onAction('upload_new')}>
                        {app.text('Assign new asset')}
                    </MenuItem>
                    <MenuItem
                        className="cc-danger"
                        submenu={<ConfirmUpdateSubmenu onAction={_onAction} />}
                    >
                        {app.text('Update original asset')}
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem onClick={() => _onAction('cancel')}>
                        {app.text('Cancel')}
                    </MenuItem>
                </Menu>
            </VView>
        )
    })
)

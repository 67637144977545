//
// SimpleSplitter
//
// Add a SimpleSplitter to an HView or VView to enable resizing. Set a ref on the view
// you want to resize, use this ref as the target property on the SimpleSplitter.
// Quick example:
//
//     const sidebarRef = useRef(null)
//     const [sidebarWidth, setSidebarWidth] = useState(300) // initial value
//     return (
//       <HView>
//         <VView ref={sidebarRef}>
//           This is my sidebar, initially 300 px wide.
//         </VView>
//         <SimpleSplitter target={sidebarRef}
//                   direction="end"
//                   initial={treepanelWidth}
//                   min={200} max={500}
//                   afterResize={(newValue) => setSidebarWidth(newValue)}
//                   />
//         <VView grow>
//           My content view takes up the remaining space. I can use the Splitter to
//           resize the sidebar, and this content will then automatically fill the
//           remaining space.
//         </VView>
//       </HView>
//     )
//
// If we have a sidebar on the right with a Splitter to the left of it, we want to grow
// the sidebar when we drag the Splitter to the left. This is accomplished by changing
// the direction to `start`. We use `start` and `end` here to support RTL.
// If the Splitter is in a VView, you will drag up and down, or towards the top and
// bottom; the direction values for these are `top` and `bottom`.
//
// The initial, min, and max properties are pretty self-explanatory?
//
// If `afterResize` is provided it will be called with the last value when the user
// stopped dragging the Splitter.
//
// The background-color has a default, but you can override this in your stylesheet:
//     .av-Splitter { background-color: #eee; }
//     @media (prefers-color-scheme: dark) {
//       .av-Splitter { background-color: #535353; }
//     }
//
// You can also change the default line-width, for example to three pixels:
//     .av-HView > .av-Splitter { width: 3px; }
//     .av-VView > .av-Splitter { height: 3px; }

import React, { useRef, useState, useEffect } from 'react'

export const SimpleSplitter = React.forwardRef(function SimpleSplitter(props, ref) {
    const { target, direction, initial, min, max, sizeClasses, afterResize, ...other } =
        props

    const [size, setSize] = useState(initial)

    useEffect(() => {
        if (size > max) {
            setSize(max)
        } else if (size < min) {
            setSize(min)
        }
    }, [size, min, max])

    useEffect(() => {
        target.current.style.flexBasis = size + 'px'
        target.current.style.maxWidth = size + 'px'
        target.current.style.minWidth = size + 'px'
        if (sizeClasses) {
            let sizeclass = ''
            const sizes = Object.keys(sizeClasses)
            for (const sizeclassSize of sizes) {
                if (size >= sizeclassSize) {
                    sizeclass = sizeClasses[sizeclassSize]
                }
            }
            target.current.setAttribute('data-sizeclass', sizeclass)
        }
    }, [target, size, sizeClasses])

    const splitterHandle = useRef(null)

    const [dragActive, setDragActive] = useState(false)
    const [dragOrigin, setDragOrigin] = useState({ x: 0, y: 0 })

    useEffect(() => {
        const computeNewValue = e => {
            let delta = 0
            switch (direction) {
                case 'top':
                    delta = dragOrigin.y - e.pageY
                    break
                case 'bottom':
                    delta = e.pageY - dragOrigin.y
                    break
                case 'start':
                    delta = dragOrigin.x - e.pageX
                    break
                case 'end':
                default:
                    delta = e.pageX - dragOrigin.x
                    break
            }
            let newValue = initial + delta
            if (min !== undefined && newValue < min) newValue = min
            if (max !== undefined && newValue > max) newValue = max
            return newValue
        }

        const onDragStart = e => {
            if (dragActive) return
            setDragOrigin({ x: e.pageX, y: e.pageY })
            setDragActive(true)
            // stop selecting text
            e.preventDefault()
            e.stopPropagation()
        }

        const onDragOver = e => {
            if (!dragActive) return
            const newValue = computeNewValue(e)
            setSize(newValue)
        }

        const onDragEnd = e => {
            if (!dragActive) return
            const newValue = computeNewValue(e)
            setSize(newValue)
            setDragActive(false)
            afterResize && afterResize(newValue)
        }

        const element = splitterHandle.current
        if (element) {
            element.addEventListener('mousedown', onDragStart)
            element.addEventListener('touchstart', onDragStart)
            document.addEventListener('mousemove', onDragOver)
            document.addEventListener('touchmove', onDragOver)
            document.addEventListener('mouseup', onDragEnd)
            document.addEventListener('touchend', onDragEnd)
            return () => {
                element.removeEventListener('mousedown', onDragStart)
                element.removeEventListener('touchstart', onDragStart)
                document.removeEventListener('mousemove', onDragOver)
                document.removeEventListener('touchmove', onDragOver)
                document.removeEventListener('mouseup', onDragEnd)
                document.removeEventListener('touchend', onDragEnd)
            }
        }
    }, [splitterHandle, dragActive, dragOrigin, direction, initial, min, max, afterResize])

    const { className, ...userprops } = other
    let classes = 'av-Splitter'
    if (className) classes += ' ' + className
    return (
        <div ref={ref} className={classes} {...userprops}>
            <div className="av-SplitterHandle" ref={splitterHandle} />
        </div>
    )
})

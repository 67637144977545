//
// LoginPanel
//
// Enter credentials, then log in. We'll remember the user using a JWT, which is sent
// on subsequent requests/sessions so we'll stay logged in automatically.
//
// Forgot your password? That's covered here too.

import React, { useState, useEffect } from 'react'
import { useStore } from '../stores'
import { observer } from 'mobx-react-lite'
import { useDebounce } from '../hooks/useDebounce'
import { useKeyboard } from '../hooks/useKeyboard'

import { VView, Spacer } from '../appview'
import {
    Bar,
    Button,
    PasswordInput,
    Property,
    Select,
    TextInput,
    Text,
    ValidationMessage,
} from '../components'
import { VALIDATION, ValidationResult } from '../utils/validation'
import { validateUsernameIsNotEmail, validatePassword } from '../stores/data/validators'
import { keyboard } from '../utils/keyboard'

const hasInput = value => {
    return !!value && value.trim().length > 0
}

const ACTIVITIES = {
    LOGIN: 0,
    RESET_PASSWORD_REQUEST: 1,
    RESET_PASSWORD: 2,
}

export const LoginPanel = observer(function LoginPanel({
    username: initialUsername,
    onSuccessfulLogin,
    setTitle,
}) {
    const store = useStore()
    const { app } = store

    // login or resetpassword
    const [activity, setActivity] = useState(ACTIVITIES.LOGIN)

    // login fields, username doubles as reset password request field
    const [username, setUsername] = useState(initialUsername ? initialUsername : '')
    const [password, setPassword] = useState('')

    // reset password fields
    const [newPassword, setNewPassword] = useState('')
    const [validationcode, setValidationcode] = useState(null)
    const [verificationcode, setVerificationcode] = useState(null)
    const [usernameChoices, setUsernameChoices] = useState([])
    const [usernameChoice, setUsernameChoice] = useState(null)

    let usernameOptions = new Map()
    usernameChoices.forEach(name => usernameOptions.set(name, name))

    // field validation
    const [usernameOrEmailValidation, setUsernameOrEmailValidation] = useState(false)
    const [isValidNewPassword, setIsValidNewPassword] = useState(false)

    const debouncedUsername = useDebounce(username, 200)
    const debouncedNewPassword = useDebounce(newPassword, 200)

    useEffect(() => {
        let isMounted = true
        const validator =
            activity === ACTIVITIES.LOGIN
                ? validateUsernameIsNotEmail
                : value => new ValidationResult(VALIDATION.NA)
        // this works for returned Promises and for returned regular values
        new Promise(resolve => resolve(validator(debouncedUsername))).then(
            validationresult => {
                if (isMounted) {
                    const validationerror =
                        validationresult.result === VALIDATION.SUCCESS ||
                        validationresult.result === VALIDATION.NA
                            ? null
                            : activity === ACTIVITIES.LOGIN
                            ? new ValidationResult(
                                  VALIDATION.ERROR,
                                  'Use your username, not your email address.'
                              )
                            : null
                    setUsernameOrEmailValidation(validationerror)
                }
            }
        )
        // .catch(error => {})
        return () => (isMounted = false)
    }, [activity, debouncedUsername])

    useEffect(() => {
        let isMounted = true
        // this works for returned Promises and for returned regular values
        new Promise(resolve => resolve(validatePassword(debouncedNewPassword))).then(
            validationresult => {
                if (isMounted) {
                    setIsValidNewPassword(
                        validationresult.result === VALIDATION.SUCCESS
                    )
                }
            }
        )
        // .catch(error => {})
        return () => (isMounted = false)
    }, [debouncedNewPassword])

    // form validation
    const [loginvalidation, setLoginvalidation] = useState(null)
    const [requestresetvalidation, setRequestresetvalidation] = useState(null)
    const [resetvalidation, setResetvalidation] = useState(null)

    const onChangeUsername = e => {
        setUsername(e.target.value)
        setLoginvalidation(null)
        setRequestresetvalidation(null)
    }

    const onChangePassword = e => {
        setPassword(e.target.value)
        setLoginvalidation(null)
    }

    const onChangeNewPassword = e => {
        setNewPassword(e.target.value)
        setResetvalidation(null)
    }

    const onChangeVerificationcode = e => {
        setVerificationcode(e.target.value)
        setResetvalidation(null)
    }

    const onLogin = () => {
        if (!loginEnabled) return
        const validationerror = new ValidationResult(
            VALIDATION.ERROR,
            username.includes('@')
                ? 'Login failed, use your username and not your email address to log in.'
                : 'Login failed, please check your username and password, and the state of your Caps Lock key.'
        )
        store
            .login({ username: username.trim(), password: password.trim() })
            .then(result => {
                if (result) {
                    setPassword('')
                    if (onSuccessfulLogin) onSuccessfulLogin()
                    else store.autoLocation()
                } else {
                    setLoginvalidation(validationerror)
                }
            })
            .catch(error => {
                setLoginvalidation(validationerror)
            })
    }

    const onForgotPassword = () => {
        setPassword('')
        setVerificationcode('')
        setValidationcode(null)
        setUsernameChoice(null)
        setUsernameChoices([])
        setTitle && setTitle(app.text('Unicat Login recovery'))
        setActivity(ACTIVITIES.RESET_PASSWORD_REQUEST)
    }

    const onRememberedPassword = () => {
        setNewPassword('')
        setPassword('')
        setVerificationcode('')
        setValidationcode(null)
        setRequestresetvalidation(null)
        setResetvalidation(null)
        setTitle && setTitle(app.text('Unicat Login'))
        setActivity(ACTIVITIES.LOGIN)
    }

    const onReenterUsername = () => {
        setNewPassword('')
        setVerificationcode('')
        setValidationcode(null)
        setUsernameChoice(null)
        setUsernameChoices([])
        setResetvalidation(null)
        setTitle && setTitle(app.text('Unicat Login recovery'))
        setActivity(ACTIVITIES.RESET_PASSWORD_REQUEST)
    }

    const onResetPasswordRequest = () => {
        if (!resetpasswordrequestEnabled) return
        setTitle && setTitle(app.text('Unicat Password reset'))
        setActivity(ACTIVITIES.RESET_PASSWORD)
        store
            .resetPasswordRequest(username.trim())
            .then(result => {
                setValidationcode(result['validation_code'])
                setUsernameChoices(result['usernames'])
                if (result['usernames'].length === 1) {
                    setUsernameChoice(result['usernames'][0])
                    setUsername(result['usernames'][0])
                } else {
                    setUsernameChoice(null)
                }
            })
            .catch(() => {
                const validationerror = new ValidationResult(
                    VALIDATION.ERROR,
                    'Unknown username or email, please check the spelling.'
                )
                setRequestresetvalidation(validationerror)
                setTitle && setTitle(app.text('Unicat Login recovery'))
                setActivity(ACTIVITIES.RESET_PASSWORD_REQUEST)
            })
    }

    const onChangeUsernameChoice = newChoice => {
        setUsernameChoice(newChoice)
        setUsername(newChoice)
        setResetvalidation(null)
    }

    const onResetPassword = () => {
        if (!resetpasswordEnabled) return
        store
            .resetPassword(
                usernameChoice,
                newPassword.trim(),
                validationcode.trim(),
                verificationcode.trim()
            )
            .then(result => {
                if (result) {
                    setPassword('')
                    if (onSuccessfulLogin) onSuccessfulLogin()
                    else store.autoLocation()
                } else {
                    setValidationcode(null)
                }
            })
            .catch(() => {
                const validationerror = new ValidationResult(
                    VALIDATION.ERROR,
                    'Verification code incorrect or timed out, we could not reset your password.'
                )
                setResetvalidation(validationerror)
            })
    }

    const loginEnabled =
        hasInput(username) && usernameOrEmailValidation === null && hasInput(password)

    const resetpasswordrequestFieldsEnabled =
        activity === ACTIVITIES.RESET_PASSWORD_REQUEST
    const resetpasswordrequestEnabled =
        resetpasswordrequestFieldsEnabled && hasInput(username)

    const resetpasswordFieldsEnabled = activity === ACTIVITIES.RESET_PASSWORD
    const resetpasswordEnabled =
        resetpasswordFieldsEnabled &&
        isValidNewPassword &&
        hasInput(validationcode) &&
        hasInput(verificationcode) &&
        hasInput(usernameChoice)

    const [inInputField, setInInputField] = useState(false)

    const onFocus = e => {
        setInInputField(true)
    }

    const onBlur = e => {
        setInInputField(false)
    }

    useKeyboard(event => {
        if (inInputField && keyboard.test(event, keyboard.ENTER)) {
            if (activity === ACTIVITIES.LOGIN && loginEnabled) {
                onLogin()
            } else if (
                activity === ACTIVITIES.RESET_PASSWORD_REQUEST &&
                resetpasswordrequestEnabled
            ) {
                onResetPasswordRequest()
            } else if (activity === ACTIVITIES.RESET_PASSWORD && resetpasswordEnabled) {
                onResetPassword()
            }
        }
    })

    const Activity =
        activity === ACTIVITIES.LOGIN ? (
            <>
                <Property label={app.text('Username')}>
                    <TextInput
                        onChange={onChangeUsername}
                        value={username}
                        placeholder={app.text('Your username is case-sensitive')}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />
                    {usernameOrEmailValidation ? (
                        <ValidationMessage validation={usernameOrEmailValidation} />
                    ) : undefined}
                </Property>
                <Property label={app.text('Password')}>
                    <PasswordInput
                        onChange={onChangePassword}
                        value={password}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />
                </Property>
                <Spacer size={20} />
                <Property label={''}>
                    <Bar raised>
                        <Button action disabled={!loginEnabled} onClick={onLogin}>
                            {app.text('Log in as {username}', {
                                username: username.trim().length ? username : '...',
                            })}
                        </Button>
                    </Bar>
                    {loginvalidation ? (
                        <ValidationMessage validation={loginvalidation} />
                    ) : undefined}
                </Property>
                <Spacer size={20} />
                <Property label={''}>
                    <Text className="link" onClick={() => onForgotPassword()}>
                        {app.text('I forgot my username and/or password.')}
                    </Text>
                </Property>
            </>
        ) : activity === ACTIVITIES.RESET_PASSWORD_REQUEST ? (
            <>
                <Property label={app.text('Username or email')}>
                    <TextInput
                        enabled={resetpasswordrequestFieldsEnabled}
                        onChange={onChangeUsername}
                        value={username}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />
                </Property>
                <Spacer size={20} />
                <Property label={''}>
                    <Bar raised>
                        <Button
                            action
                            disabled={!resetpasswordrequestEnabled}
                            onClick={onResetPasswordRequest}
                        >
                            {app.text(
                                'Send me a verification code so I can reset my password'
                            )}
                        </Button>
                    </Bar>
                    {requestresetvalidation ? (
                        <ValidationMessage validation={requestresetvalidation} />
                    ) : undefined}
                </Property>
                <Spacer size={20} />
                <Property label={''}>
                    <Text className="link" onClick={() => onRememberedPassword()}>
                        {app.text('I remembered my username and password.')}
                    </Text>
                </Property>
            </>
        ) : (
            <>
                <Property label={app.text('Username')}>
                    {usernameChoices.length === 0 ? (
                        <Text>{app.text('Busy...')}</Text>
                    ) : usernameChoices.length === 1 ? (
                        <Text>{usernameChoices[0]}</Text>
                    ) : (
                        <Select
                            onChange={onChangeUsernameChoice}
                            value={usernameChoice}
                            options={usernameOptions}
                            placeholder={app.text('Multiple usernames, pick one')}
                        />
                    )}
                </Property>
                <Property label={app.text('New password')}>
                    <PasswordInput
                        enabled={resetpasswordFieldsEnabled}
                        validate={validatePassword}
                        onChange={onChangeNewPassword}
                        value={newPassword}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />
                </Property>
                <Property label={app.text('Verification code')}>
                    <TextInput
                        enabled={resetpasswordFieldsEnabled}
                        onChange={onChangeVerificationcode}
                        value={verificationcode}
                        placeholder={app.text("It's in your email, we just sent it")}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />
                </Property>
                <Spacer size={20} />
                <Property label={''}>
                    <Bar raised>
                        <Button
                            action
                            disabled={!resetpasswordEnabled}
                            onClick={onResetPassword}
                        >
                            {app.text('Reset my password')}
                        </Button>
                    </Bar>
                    {resetvalidation ? (
                        <ValidationMessage validation={resetvalidation} />
                    ) : undefined}
                </Property>
                <Spacer size={20} />
                <Property label={''}>
                    <Text className="link" onClick={() => onReenterUsername()}>
                        {app.text('Request a new verification code.')}
                    </Text>
                </Property>
                <Spacer size={5} />
                <Property label={''}>
                    <Text className="link" onClick={() => onRememberedPassword()}>
                        {app.text('I remembered my username and password.')}
                    </Text>
                </Property>
            </>
        )

    return <VView className="form login-form">{Activity}</VView>
})

//
// LinkedRecordMenu for RecordInspector
//

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../stores'

import { Menu, MenuItem } from '../../../components'

export const LinkedRecordMenu = observer(function LinkedRecordMenu({
    record,
    onAction,
}) {
    const { app } = useStore()

    const _onAction = action => {
        onAction && onAction(action)
    }

    if (!record || !record.is_link) return null

    return (
        <Menu>
            <MenuItem onClick={() => _onAction('goto_linked_record')}>
                {app.text('Go to link source')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('unlink_record')}>
                {app.text('Unlink')}
            </MenuItem>
        </Menu>
    )
})

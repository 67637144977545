//
// DefinitionClassFieldMenu
//
// Actions on class-fields in the definition data worksheet
//
// DefinitionClassFieldMenu
// - set_titlefield
// - goto_field

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Menu, MenuDivider, MenuItem } from '../components'

export const DefinitionClassFieldMenu = observer(function DefinitionClassFieldMenu({
    definition,
    fieldGid,
    onAction,
}) {
    const { app, data } = useStore()

    const _onAction = action => {
        onAction && onAction(action)
    }

    const field = data.fields.get(fieldGid)
    const disable_titlefield =
        field && !['textline', 'text', 'textlist', 'barcode'].includes(field.type)

    return (
        <Menu>
            <MenuItem
                disabled={disable_titlefield}
                onClick={() => _onAction('set_titlefield')}
            >
                {app.text('Set as titlefield')}
            </MenuItem>
            <MenuDivider />
            <MenuItem onClick={() => _onAction('goto_field')}>
                {app.text('Go to field')}
            </MenuItem>
        </Menu>
    )
})

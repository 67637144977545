//
// FileInspector
//
// An inspector for a file; we can show info and transforms
//
// Available info:
//
//     info = {
//         "format": ext,
//         "pages": 0,
//         "width": None,
//         "height": None,
//         "resolution": (72, 72),
//         "transparent": False,
//         "animated": False,
//         "colors": None,
//         "colorspace": None,
//         "metadata": {},
//     }

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'
import { useStore } from '../../../stores'
import { useFileUpload } from '../../../hooks/useFileUpload'

import { VView, Spacer, Divider } from '../../../appview'
import { Header, Property, Text, TextInput } from '../../../components'

import { validAssetName, humanReadableBytes } from '../../../utils/text'

export const FileInspector = observer(function FileInspector({ worksheet }) {
    const { app, data, imageserver, DEBUG } = useStore()

    const FileUpload = useFileUpload()

    const asset = worksheet.asset

    const renderkey = asset.gid + '.' + worksheet.language

    const _assetnameparts = asset.publicname.split('.')
    const assetext = _assetnameparts.length > 1 ? '.' + _assetnameparts.pop() : ''
    const assetname = _assetnameparts.join('.')

    const onChangeAssetName = event => {
        asset.setProperty('name', event.target.value + assetext)
    }
    const onBlurAssetName = event => {
        let newname = event.target.value.trim() + assetext
        asset.setProperty('name', newname)
        asset.commitIfModified()
    }

    const onChangeAssetTitle = event => {
        asset.setProperty('title', event.target.value, worksheet.language)
    }
    const onBlurAssetTitle = event => {
        asset.setProperty('title', event.target.value.trim(), worksheet.language)
        asset.commitIfModified()
    }

    const onChangeAssetDescription = event => {
        asset.setProperty('description', event.target.value, worksheet.language)
    }
    const onBlurAssetDescription = event => {
        asset.setProperty('description', event.target.value.trim(), worksheet.language)
        asset.commitIfModified()
    }

    const transform = asset.mainTransform
    const hotspot = transform.hotspot || [0.5, 0.5]
    const crop = transform.crop || [1.0, 1.0]

    const [droppedfile, setDroppedFile] = useState(null)

    const onFileSelection = file => {
        setDroppedFile(file)
        if (file.type) {
            return data.actions.assets.uploadUpdate(file, asset.gid).then(assetgid => {
                // tree.expandItem(dropitem)
                // view.damworksheet.refetch()
                setDroppedFile(null)
                return assetgid
            })
        } else {
            window.setTimeout(() => setDroppedFile(null), 500)
        }
    }

    const [{ isOver }, drop] = useDrop({
        accept: NativeTypes.FILE,
        collect: monitor => {
            const isOver = !!monitor.isOver()
            return {
                isOver: isOver,
            }
        },
        drop: (item, monitor) => {
            onFileSelection(item.files[0])
        },
    })

    let dropclassname = 'dam-asset-upload'
    if (isOver) dropclassname = 'dam-asset-upload drag-over-inside'

    const DropReport = droppedfile ? (
        <div>
            {droppedfile.name} - {droppedfile.type}
            {droppedfile.type
                ? app.text(', uploading {size}...', {
                      size: humanReadableBytes(droppedfile.size),
                  })
                : app.text('folder upload not supported')}
        </div>
    ) : (
        <span className="cc-Placeholder">
            {app.text('Drop an image or document here to update this asset.')}
        </span>
    )

    const can_transform = imageserver.canTransform(asset)

    const DebugInfo = DEBUG ? (
        <div className="DEBUG">
            <Spacer size={5} />
            <Divider />
            <Header>{app.text('Reference info (debug only)')}</Header>
            <Property label={app.text('gid')}>
                <Text>{asset.gid}</Text>
            </Property>
        </div>
    ) : undefined

    return (
        <VView grow className="panel properties">
            <Header>{app.text('Main properties')}</Header>
            <Property label={app.text('path')}>
                <Text>{asset.path}</Text>
            </Property>
            <Property label={app.text('name')}>
                <TextInput
                    autoConvert={validAssetName}
                    onChange={onChangeAssetName}
                    onBlur={onBlurAssetName}
                    value={assetname}
                    renderkey={renderkey}
                />
                <Text>{assetext}</Text>
            </Property>
            <Property label={app.text('format')}>
                <Text>{asset.info.get('format')}</Text>
            </Property>
            <Property label={app.text('filesize')}>
                <Text>
                    {humanReadableBytes(asset.info.get('filesize')) ||
                        app.text('[undetermined]')}
                </Text>
            </Property>
            <Property label={app.text('title')}>
                <TextInput
                    onChange={onChangeAssetTitle}
                    onBlur={onBlurAssetTitle}
                    value={asset.title.get(worksheet.language)}
                    language={worksheet.language}
                    renderkey={renderkey}
                />
            </Property>
            <Property label={app.text('description')}>
                <TextInput
                    onChange={onChangeAssetDescription}
                    onBlur={onBlurAssetDescription}
                    value={asset.description.get(worksheet.language)}
                    language={worksheet.language}
                    renderkey={renderkey}
                />
            </Property>
            {can_transform ? (
                <>
                    <Property label={app.text('width')}>
                        <Text>
                            {asset.info.get('width')} {app.text('px')}
                        </Text>
                    </Property>
                    <Property label={app.text('height')}>
                        <Text>
                            {asset.info.get('height')} {app.text('px')}
                        </Text>
                    </Property>
                    <Divider />
                    <Header>{app.text('Transform')}</Header>
                    <Property label={app.text('hotspot')}>
                        <Text>
                            {hotspot[0].toFixed(3)}, {hotspot[1].toFixed(3)}
                        </Text>
                    </Property>
                    <Property label={app.text('crop')}>
                        <Text>
                            {crop[0].toFixed(3)}, {crop[1].toFixed(3)} (
                            {Math.round(asset.info.get('width') * crop[0])} x{' '}
                            {Math.round(asset.info.get('height') * crop[1])}{' '}
                            {app.text('px')})
                        </Text>
                    </Property>
                </>
            ) : undefined}
            <Divider />
            <FileUpload.Input onFileChange={onFileSelection} />
            <Header>{app.text('Update asset')}</Header>
            <div
                className={dropclassname}
                ref={drop}
                onClick={e => FileUpload.openFileDialog()}
            >
                {DropReport}
            </div>
            {DebugInfo}
        </VView>
    )
})

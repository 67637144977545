//
// FilterPanel
//
// Show and manage search filters

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useMousePopover } from '../hooks/useMousePopover'
import { usePopoverContext } from '../components'

import { VView } from '../appview'

import {
    BooleanAndFilterMenu,
    BooleanOrFilterMenu,
    BooleanNotFilterMenu,
    FilterMenu,
    AddAssetFilterMenu,
    AddRecordFilterMenu,
    AddSchemaFilterMenu,
} from './FilterMenu'

import { Filter } from './Filter'

export const FilterPanel = observer(function FilterPanel({ filterstore, ...other }) {
    const popoverContext = usePopoverContext()

    const [selectedFilter, setSelectedFilter] = useState(null)

    const BooleanAndFilterMenuPopover = useMousePopover(BooleanAndFilterMenu)
    const BooleanOrFilterMenuPopover = useMousePopover(BooleanOrFilterMenu)
    const BooleanNotFilterMenuPopover = useMousePopover(BooleanNotFilterMenu)
    const FilterMenuPopover = useMousePopover(FilterMenu)
    const AddAssetFilterMenuPopover = useMousePopover(AddAssetFilterMenu)
    const AddRecordFilterMenuPopover = useMousePopover(AddRecordFilterMenu)
    const AddSchemaFilterMenuPopover = useMousePopover(AddSchemaFilterMenu)

    const rootFilter = filterstore._filters.get(filterstore._root)
    const selection = selectedFilter ? filterstore._filters.get(selectedFilter) : null

    const defaults = {
        asset: {
            and: ['', []],
            or: ['', []],
            not: ['', null],
            type: ['is', 'image'],
            name: ['q', ''],
            ext: ['is', ''],
            path: ['is', ''],
            title: ['q', ''],
            description: ['q', ''],
            size: ['>', 0],
            updated_on: ['<', [7, 'days']],
            is_deleted: ['is_true', null],
            validation: ['any', ''],
            width: ['>', 0],
            height: ['>', 0],
            aspect_ratio: ['=', 1],
            is_transparent: ['is_true', null],
            is_animated: ['is_true', null],
            colorspace: ['is', ''],
        },
        record: {
            and: ['', []],
            or: ['', []],
            not: ['', null],
            channels: ['has', ''],
            definition: ['is', ''],
            classes: ['has', ''],
            fields: ['has', ''],
            value: ['is', ['', '']],
            childdefinitions: ['mixed_children', null],
            updated_on: ['<', [7, 'days']],
            is_link: ['is_true', null],
            is_deleted: ['is_true', null],
            validation: ['any', ''],
        },
        schema: {
            and: ['', []],
            or: ['', []],
            not: ['', null],
            type: ['is', 'definition'],
            name: ['q', ''],
            label: ['q', ''],
            validation: ['any', ''],
            is_committed: ['is_true', null],
            not_committed: ['is_true', null],
            classes: ['has', ''],
            fields: ['has', ''],
            childdefinitions: ['has', ''],
            unit: ['is', ''],
            is_localized: ['is_true', null],
            is_required: ['is_true', null],
            field_type: ['is', 'text'],
        },
    }

    const onAction = (action, p1, p2, p3) => {
        popoverContext.store.show([...popoverContext.stack])
        let newSelectedFilter
        switch (action) {
            case 'add_filter': // p1 is prop
                let op = defaults[filterstore.doctype][p1][0]
                let arg = defaults[filterstore.doctype][p1][1]
                newSelectedFilter = filterstore.createFilter(
                    p1,
                    op,
                    arg,
                    selectedFilter,
                    null
                )
                setSelectedFilter(newSelectedFilter)
                break
            case 'remove_filter':
                filterstore.deleteFilter(selectedFilter)
                setSelectedFilter(null)
                break
            case 'wrap_and':
                newSelectedFilter = filterstore.wrapFilter(selectedFilter, 'and')
                setSelectedFilter(newSelectedFilter)
                break
            case 'wrap_or':
                newSelectedFilter = filterstore.wrapFilter(selectedFilter, 'or')
                setSelectedFilter(newSelectedFilter)
                break
            case 'wrap_not':
                newSelectedFilter = filterstore.wrapFilter(selectedFilter, 'not')
                setSelectedFilter(newSelectedFilter)
                break
            case 'and_unwrap':
                newSelectedFilter = filterstore.unwrapFilter(selectedFilter)
                setSelectedFilter(newSelectedFilter)
                break
            case 'and_to_or':
                filterstore.updateFilter(selectedFilter, 'or', '', selection.arg)
                break
            case 'and_to_not':
                filterstore.updateFilter(
                    selectedFilter,
                    'not',
                    '',
                    selection.arg && selection.arg.length ? selection.arg[0] : null
                )
                break
            case 'or_to_and':
                filterstore.updateFilter(selectedFilter, 'and', '', selection.arg)
                break
            case 'or_unwrap':
                newSelectedFilter = filterstore.unwrapFilter(selectedFilter)
                setSelectedFilter(newSelectedFilter)
                break
            case 'or_to_not':
                filterstore.updateFilter(
                    selectedFilter,
                    'not',
                    '',
                    selection.arg && selection.arg.length ? selection.arg[0] : null
                )
                break
            case 'not_to_and':
                filterstore.updateFilter(
                    selectedFilter,
                    'and',
                    '',
                    selection.arg ? [selection.arg] : []
                )
                break
            case 'not_to_or':
                filterstore.updateFilter(
                    selectedFilter,
                    'or',
                    '',
                    selection.arg ? [selection.arg] : []
                )
                break
            case 'not_unwrap':
                newSelectedFilter = filterstore.unwrapFilter(selectedFilter)
                setSelectedFilter(newSelectedFilter)
                break
            case 'update_op': // p1 is key, p2 is new op
                filterstore.updateFilterOp(p1, p2)
                break
            case 'update_arg': // p1 is key, p2 is new arg
                filterstore.updateFilterArg(p1, p2)
                break
            default:
                console.log('Action not handled', selectedFilter, action, p1, p2, p3)
                break
        }
    }

    const clickfilterhandler = (e, key, action) => {
        popoverContext.store.show([...popoverContext.stack])
        switch (action) {
            case 'add-asset':
                AddAssetFilterMenuPopover.onShow(e)
                break
            case 'add-record':
                AddRecordFilterMenuPopover.onShow(e)
                break
            case 'add-schema':
                AddSchemaFilterMenuPopover.onShow(e)
                break
            default:
                if (key === selectedFilter) {
                    setSelectedFilter(null)
                    e.preventDefault()
                    e.stopPropagation()
                    e.nativeEvent.stopImmediatePropagation()
                    return
                }
        }
        setSelectedFilter(key)
        e.preventDefault()
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
    }

    const contextfilterhandler = (e, key, action) => {
        setSelectedFilter(key)
        popoverContext.store.show([...popoverContext.stack])
        switch (action) {
            case 'and':
                BooleanAndFilterMenuPopover.onShow(e)
                break
            case 'or':
                BooleanOrFilterMenuPopover.onShow(e)
                break
            case 'not':
                BooleanNotFilterMenuPopover.onShow(e)
                break
            case 'add-asset':
                AddAssetFilterMenuPopover.onShow(e)
                break
            case 'add-record':
                AddRecordFilterMenuPopover.onShow(e)
                break
            case 'add-schema':
                AddSchemaFilterMenuPopover.onShow(e)
                break
            default:
                FilterMenuPopover.onShow(e)
                e.preventDefault()
                e.stopPropagation()
                e.nativeEvent.stopImmediatePropagation()
                break
        }
        e.preventDefault()
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
    }

    const FilterItems = (
        <Filter
            key={filterstore.refreshkey}
            filterstore={filterstore}
            filter={rootFilter}
            selectedfilter={selectedFilter}
            clickfilterhandler={clickfilterhandler}
            contextfilterhandler={contextfilterhandler}
            onAction={onAction}
        />
    )

    return (
        <VView
            className="panel filter-panel"
            {...other}
            style={{ padding: '2px 0' }}
            onClick={e => {
                popoverContext.store.show([...popoverContext.stack])
                e.preventDefault()
                e.stopPropagation()
                e.nativeEvent.stopImmediatePropagation()
            }}
            onContextMenu={e => {
                popoverContext.store.show([...popoverContext.stack])
                e.preventDefault()
                e.stopPropagation()
                e.nativeEvent.stopImmediatePropagation()
            }}
        >
            <BooleanAndFilterMenuPopover.Panel
                onAction={onAction}
                filterargcount={selection && selection.arg ? selection.arg.length : 0}
            />
            <BooleanOrFilterMenuPopover.Panel
                onAction={onAction}
                filterargcount={selection && selection.arg ? selection.arg.length : 0}
            />
            <BooleanNotFilterMenuPopover.Panel onAction={onAction} />
            <FilterMenuPopover.Panel onAction={onAction} />
            <AddAssetFilterMenuPopover.Panel onAction={onAction} />
            <AddRecordFilterMenuPopover.Panel onAction={onAction} />
            <AddSchemaFilterMenuPopover.Panel onAction={onAction} />
            <div className="cc-Filter">{FilterItems}</div>
        </VView>
    )
})

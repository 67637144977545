//
// DamTreeStore
//
// The DamTreeStore handles interaction with the Dam Navigation Tree (TreeStore)
//
// The exposed store is the actual TreeStore (.tree), but that is a generic element
// and the DamTreeStore handles storing view choices like expandedPaths and such, and
// fetching data based on that.
//
// In a store, we use it like this:
//
//     this._damtree = new DamTreeStore("/dam/tree", this._rootstore)
//      ...
//     get damtree() {
//         if (!this.user && !this.project) return null
//         this._damtree.loadOnce()
//         return this._damtree.tree // NOTE the .tree!
//     }
//      ...
//     _damtree is observable, damtree is computed
//
// Have a look at the TreeNavigator component to see how it is used in rendering.
//
// SIDE-EFFECT: when a user selects a treeitem, we update the damworksheet with that
//              same asset

import { makeObservable, observable, action, reaction, runInAction } from 'mobx'

import { TreeStore, TreeStoreItem } from '../components/TreeStore'

export class DamTreeStore {
    __init = false
    _key = null
    _rootstore = null
    _environment = null
    tree = null
    topindex = null
    windowsize = null

    constructor(key, rootstore) {
        makeObservable(this, {
            __init: observable,
            _key: observable,
            _rootstore: observable,
            _environment: observable,
            tree: observable,
            topindex: observable,
            windowsize: observable,
            fetch: action.bound,
            refetch: action.bound,
            _fetch: action.bound,
            select: action.bound,
            selectAsset: action.bound,
            setAsset: action.bound,
            create: action.bound,
            syncdataitem_callback: action.bound,
        })

        this._key = key
        this._rootstore = rootstore
        this.tree = new TreeStore(this)
        this.topindex = 0
        this.windowsize = 10
        this._syncdebounce = null
        this._syncdelay = 100
    }

    loadOnce = () => {
        if (this.__init) return
        const parentIds = this._rootstore.view.loadPerProject(
            this._key + '/expandedIds',
            []
        )
        const selectedId = this._rootstore.view.loadPerProject(
            this._key + '/selectedId'
        )
        this.topindex = this._rootstore.view.loadPerProject(this._key + '/topindex', 0)
        this.windowsize = this._rootstore.view.loadPerProject(
            this._key + '/windowsize',
            10
        )
        this.tree._expandedIds.replace(parentIds)
        this._fetch(parentIds, this.topindex, this.windowsize).then(() => {
            runInAction(() => {
                this.tree._selectedId = selectedId
                const selectedItem = this.tree.selectedItem
                if (selectedItem) {
                    this.tree.multiselection.rangeStart(selectedItem.item.gid)
                }
            })
            reaction(
                () => ({
                    selectedGid: this.tree.selectedItem
                        ? this.tree.selectedItem.item.gid
                        : null,
                    selectionsize: this.tree.multiselection.size,
                }),
                ({ selectedGid, selectionsize }) => {
                    if (selectedGid && !selectionsize) {
                        this.tree.multiselection.rangeStart(selectedGid)
                    }
                }
            )
        })
        this._rootstore.api.register_syncdataitem_callback(this.syncdataitem_callback)
        this.__init = true
    }

    fetch(parentIds, topindex, windowsize) {
        if (topindex !== undefined) this.topindex = topindex
        if (windowsize !== undefined) this.windowsize = windowsize
        if (parentIds !== undefined) {
            this._rootstore.view.savePerProject(this._key + '/expandedIds', parentIds)
        }
        this._rootstore.view.savePerProject(this._key + '/topindex', this.topindex)
        this._rootstore.view.savePerProject(this._key + '/windowsize', this.windowsize)
        return this._fetch(parentIds, this.topindex, this.windowsize)
    }

    refetch(topindex, windowsize) {
        if (topindex !== undefined) this.topindex = topindex
        if (windowsize !== undefined) this.windowsize = windowsize
        return this._fetch(this.tree._expandedIds, this.topindex, this.windowsize)
    }

    _fetch(parentIds, topindex, windowsize) {
        return this._rootstore
            ._fetch('/assets/tree', {
                expanded_paths: parentIds,
                'page.top': topindex,
                'page.size': windowsize,
            })
            .then(result => {
                runInAction(() => {
                    // and use it to populate treestore items
                    // records if a list of gids, in order
                    const treeitems = result['tree']
                        .map(assetgid => {
                            const asset = this._rootstore.data.assets.get(assetgid)
                            if (!asset) return null
                            return new TreeStoreItem(
                                asset.pathname,
                                asset.path,
                                asset.pathname === '/'
                                    ? 0
                                    : asset.pathname.split('/').length - 1,
                                asset.childcount,
                                asset
                            )
                        })
                        .filter(treeitem => treeitem !== null)
                    // update tree
                    this.tree._expandedIds.replace(parentIds)
                    this.topindex = topindex
                    this.windowsize = windowsize
                    this.tree.setTreeItems(treeitems, result['tree.size'])
                })
                return result
            })
        // .catch(error => {})
    }

    select(treeitem) {
        this.selectAsset(treeitem.item)
    }

    deselect(treeitem) {
        this.selectAsset(null)
    }

    selectAsset(asset) {
        this._rootstore.view.savePerProject(
            this._key + '/selectedId',
            asset ? asset.pathname : null
        )
        this._rootstore.view.damworksheet.setAsset(asset)
    }

    pathnamesToPathname(pathname) {
        if (pathname === '/') {
            return ['/']
        }
        let pathnames = []
        let pathparts = pathname.split('/')
        while (pathparts.length > 1) {
            pathnames.push(pathparts.join('/'))
            pathparts.pop()
        }
        pathnames.push('/')
        pathnames.reverse()
        return pathnames
    }

    setAsset(asset) {
        // this is external; when we select an item, we need to make sure it appears in
        // the expanded tree, and that it will be the selected treeitem
        const parentpath = this.pathnamesToPathname(asset.path)
        const newExpandedIds = Array.from(
            new Set([...this.tree._expandedIds, ...parentpath])
        )
        this.tree._setExpandedIds(newExpandedIds)
        this.tree.setSelectedId(asset.pathname)
        this.tree.multiselection.deselectAll()
        this.tree.multiselection.rangeStart(asset.gid)
    }

    create = parenttreeitem => {
        this._rootstore
            ._fetch('/assets/create', {
                parent: parenttreeitem.item.gid,
            })
            .then(result => {
                // store data in store.data.pimrecords
                // add the newly created record as a treeitem
                // and update the parent treeitem
                const treeitems = ['asset', 'parent']
                    .map(resultname => {
                        const assetgid = result[resultname]
                        const asset = this._rootstore.data.assets.get(assetgid)
                        if (!asset) return null
                        const treeitem = new TreeStoreItem(
                            asset.pathname,
                            asset.path,
                            asset.pathname === '/'
                                ? 0
                                : asset.pathname.split('/').length - 1,
                            asset.childcount,
                            asset
                        )
                        return treeitem
                    })
                    .filter(treeitem => treeitem !== null)
                // update tree
                this.tree.expandItem(treeitems[1])
                this.tree.selectItem(treeitems[0])
                return result
            })
        // .catch(error => {})
    }

    schedule_refetch = () => {
        if (this._syncdebounce) {
            clearTimeout(this._syncdebounce)
            this._syncdebounce = null
        }
        this._syncdebounce = setTimeout(() => {
            this.refetch()
            this._syncdebounce = null
        }, this._syncdelay)
    }

    syncdataitem_callback = (syncdataitem, data_before, data_after) => {
        // console.log('DamTreeStore sync', syncdataitem, data_before, data_after)
        // we're only interested in data_type "assets"
        // then, we're only interested in updates (of childcount)
        // from those records, only for parents that are in our store
        // from those parents, only when childcount has changed
        if (syncdataitem['data_type'] !== 'assets') return
        if (syncdataitem['action'] === 'DELETE') {
            if (data_before && this.tree.findItemById(data_before.pathname)) {
                this.schedule_refetch()
            }
            return
        }
        if (syncdataitem['action'] !== 'UPDATE') return
        const pathname = data_before ? data_before.pathname : data_after.pathname
        if (
            !(
                this.tree.findItemById(pathname) ||
                this.tree.hasItemWithParentId(pathname)
            )
        ) {
            return
        }
        if (data_before.childcount !== data_after.childcount) {
            this.schedule_refetch()
        }
    }
}

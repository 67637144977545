//
// BackupsMenu
//
// Actions on one or more backups in the ProjectBackupsWorksheet

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Menu, MenuItem, MenuDivider } from '../components'

const ConfirmRestoreSubmenu = observer(function ConfirmRestoreSubmenu({ onAction }) {
    const { app } = useStore()

    const _onAction = action => {
        onAction && onAction(action)
    }

    return (
        <Menu>
            <MenuItem className="cc-danger" onClick={() => _onAction('restore_backup')}>
                {app.text('Yes, restore this backup')}
            </MenuItem>
        </Menu>
    )
})

const ConfirmDeletionSubmenu = observer(function ConfirmDeletionSubmenu({ onAction }) {
    const { app } = useStore()

    const _onAction = action => {
        onAction && onAction(action)
    }

    return (
        <Menu>
            <MenuItem className="cc-notice" onClick={() => _onAction('delete_backups')}>
                {app.text('Yes, delete the selection')}
            </MenuItem>
        </Menu>
    )
})

export const BackupsMenu = observer(function BackupsMenu({
    selectedbackupversions,
    onAction,
}) {
    const { app } = useStore()

    const disableRestore = selectedbackupversions.length !== 1

    const _onAction = action => {
        onAction && onAction(action)
    }

    return (
        <Menu>
            <MenuItem
                className="cc-danger"
                disabled={disableRestore}
                submenu={<ConfirmRestoreSubmenu onAction={_onAction} />}
            >
                {app.text('Restore')}
            </MenuItem>
            <MenuDivider />
            <MenuItem
                className="cc-notice"
                submenu={<ConfirmDeletionSubmenu onAction={_onAction} />}
            >
                {app.text('Delete')}
            </MenuItem>
        </Menu>
    )
})

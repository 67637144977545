//
// RecordInspectorFieldlists
//
// Defining a worksheet layout.

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../stores'
import { usePersistentState } from '../../../hooks/usePersistentState'

import { useMousePopover } from '../../../hooks/useMousePopover'
import {
    DragDropFieldlistFieldTreeItem,
    DragDropFieldlistTreeItem,
} from '../../../dragdrop/DragDropFieldlistTreeItem'

import { FieldlistMenu, FieldlistFieldMenu } from '../../../menus'

import { Text, Icon, TreeItem, Item } from '../../../components'

export const RecordInspectorFieldlists = observer(function RecordInspectorFieldlists({
    worksheet,
    viewkey,
}) {
    const store = useStore()
    const { app, data } = store
    const record = worksheet.record

    const FieldlistContextMenu = useMousePopover(FieldlistMenu)
    const FieldlistFieldContextMenu = useMousePopover(FieldlistFieldMenu)

    const [expandedKeys, setExpandedKeys] = usePersistentState(
        viewkey + '/fieldlistsExpandedKeys',
        []
    )

    const [selectedField, setSelectedField] = useState({
        fieldlistkey: null,
        fieldgid: null,
    })

    if (!store.project) return null
    if (!worksheet.record) return null

    const orderedfieldlists = store.project.orderedfieldlists
    const definition = worksheet.definitions.get(worksheet.record.definition)
    if (!definition) return null
    const fieldlists = definition.fieldlists

    const onDropFieldlistField = (dragitem, dropitem, isoverzone) => {
        let before_fieldgid = dropitem.fieldgid // can be null, isoverzone is inside then
        if (isoverzone === 'bottom') {
            before_fieldgid = null
            let next = false
            for (const fieldgid of fieldlists.get(dropitem.fieldlistkey)) {
                if (fieldgid === dragitem.fieldgid) {
                    continue
                }
                if (next) {
                    before_fieldgid = fieldgid
                    break
                }
                if (fieldgid === dropitem.fieldgid) next = true
            }
        }
        if (!('fieldlistkey' in dragitem)) {
            // dragged from definition
            worksheet.record.addDefinitionFieldlistField(
                dropitem.fieldlistkey,
                dragitem.fieldgid,
                before_fieldgid
            )
            if (!expandedKeys.includes(dropitem.fieldlistkey)) {
                setExpandedKeys([dropitem.fieldlistkey, ...expandedKeys])
            }
        } else {
            // dragged inside fieldlist(s)
            if (dragitem.fieldlistkey === dropitem.fieldlistkey) {
                worksheet.record.moveDefinitionFieldlistField(
                    dropitem.fieldlistkey,
                    dragitem.fieldgid,
                    before_fieldgid
                )
            } else {
                worksheet.record
                    .addDefinitionFieldlistField(
                        dropitem.fieldlistkey,
                        dragitem.fieldgid,
                        before_fieldgid
                    )
                    .then(() => {
                        if (!expandedKeys.includes(dropitem.fieldlistkey)) {
                            setExpandedKeys([dropitem.fieldlistkey, ...expandedKeys])
                        }
                        worksheet.record.removeDefinitionFieldlistField(
                            dragitem.fieldlistkey,
                            dragitem.fieldgid
                        )
                    })
            }
        }
    }

    const FieldLists = []
    orderedfieldlists.forEach(key => {
        const fieldlistname = store.project.fieldlists.get(key)
        const fieldlistfields =
            fieldlists && fieldlists.has(key) ? fieldlists.get(key) : []
        const state = fieldlistfields.length
            ? expandedKeys.includes(key)
                ? 'expanded'
                : 'collapsed'
            : 'empty'
        const treeitem = (
            <TreeItem
                key={key}
                state={state}
                selected={false}
                indent={0}
                onExpand={() => {
                    setExpandedKeys([key, ...expandedKeys])
                }}
                onCollapse={() => {
                    setExpandedKeys(expandedKeys.filter(value => value !== key))
                }}
                onContextMenu={e => {
                    if (!record.is_link) {
                        setSelectedField({
                            fieldlistkey: key,
                            fieldgid: null,
                        })
                        FieldlistContextMenu.onShow(e)
                    }
                }}
            >
                <span className="cc-inline">
                    <Text>{fieldlistname}</Text>
                </span>
            </TreeItem>
        )
        if (!record.is_link) {
            FieldLists.push(
                <DragDropFieldlistTreeItem
                    key={key}
                    fieldlistkey={key}
                    onDrop={onDropFieldlistField}
                >
                    {treeitem}
                </DragDropFieldlistTreeItem>
            )
        } else {
            FieldLists.push(treeitem)
        }
        if (expandedKeys.includes(key)) {
            if (!fieldlistfields.length && state === 'expanded') {
                const treeitem = (
                    <TreeItem
                        key={key + '.no-fields'}
                        state={'empty'}
                        selected={false}
                        indent={1}
                    >
                        {app.text('no fields')}
                    </TreeItem>
                )
                if (!record.is_link) {
                    FieldLists.push(
                        <DragDropFieldlistTreeItem
                            key={key + '.no-fields'}
                            fieldlistkey={key}
                            onDrop={onDropFieldlistField}
                        >
                            {treeitem}
                        </DragDropFieldlistTreeItem>
                    )
                } else {
                    FieldLists.push(treeitem)
                }
            }

            fieldlistfields.forEach(dottedfieldgid => {
                const dottedfieldgids = dottedfieldgid.split(/\./)

                const subfielditems = dottedfieldgids.map((subfieldgid, index) => {
                    const subfield = data.fields.get(subfieldgid)
                    const title = worksheet.layoutstore.getItemTitle(
                        subfield,
                        record.language
                    )
                    return index ? (
                        <Item key={index} indent={2 * (index - 1)}>
                            <Icon name="field" size="text" /> {title}
                        </Item>
                    ) : (
                        <span key={index}>{title}</span>
                    )
                })

                // const fieldgid = dottedfieldgids[0]
                // const field = worksheet.fields.get(fieldgid)
                // let fieldname = field.label.get(language)
                // if (!fieldname || !fieldname.length) fieldname = field.name
                const treeitem = (
                    <TreeItem
                        key={key + '.' + dottedfieldgid}
                        state={'empty'}
                        emptyicon={'field'}
                        selected={false}
                        indent={1}
                        onClick={() => {
                            setSelectedField({
                                fieldlistkey: key,
                                fieldgid: dottedfieldgid,
                            })
                        }}
                        onContextMenu={e => {
                            if (!record.is_link) {
                                setSelectedField({
                                    fieldlistkey: key,
                                    fieldgid: dottedfieldgid,
                                })
                                FieldlistFieldContextMenu.onShow(e)
                            }
                        }}
                    >
                        {subfielditems}
                    </TreeItem>
                )
                if (!record.is_link) {
                    FieldLists.push(
                        <DragDropFieldlistFieldTreeItem
                            key={key + '.' + dottedfieldgid}
                            fieldlistkey={key}
                            fieldgid={dottedfieldgid}
                            onDrop={onDropFieldlistField}
                        >
                            {treeitem}
                        </DragDropFieldlistFieldTreeItem>
                    )
                } else {
                    FieldLists.push(treeitem)
                }
            })
        }
    })

    const onAddFieldlistField = (_, fieldlistkey, fieldgid) => {
        worksheet.record.addDefinitionFieldlistField(fieldlistkey, fieldgid)
        if (!expandedKeys.includes(fieldlistkey)) {
            setExpandedKeys([fieldlistkey, ...expandedKeys])
        }
    }

    const onRemoveFieldlistField = (_, fieldlistkey, fieldgid) => {
        worksheet.record.removeDefinitionFieldlistField(fieldlistkey, fieldgid)
    }

    const onFieldlistAction = (e, action, fieldlistkey, fieldgid) => {
        const actions = {
            add_fieldlist_field: onAddFieldlistField,
            remove_fieldlist_field: onRemoveFieldlistField,
        }
        if (!(action in actions)) {
            console.log(`onFieldlistAction: unhandled action '${action}'`)
            return
        }
        FieldlistContextMenu.hide()
        FieldlistFieldContextMenu.hide()
        actions[action](e, fieldlistkey, fieldgid)
    }

    return (
        <>
            <FieldlistContextMenu.Panel
                project={store.project}
                worksheet={worksheet}
                fieldlistkey={selectedField.fieldlistkey}
                onAction={onFieldlistAction}
            />
            <FieldlistFieldContextMenu.Panel
                project={store.project}
                worksheet={worksheet}
                fieldlistkey={selectedField.fieldlistkey}
                fieldgid={selectedField.fieldgid}
                onAction={onFieldlistAction}
            />
            {FieldLists}
        </>
    )
})

//
// AboutPopover
//
// About Unicat. Name, version number, and such.

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { VView, HView, Spacer, Divider } from '../appview'
import { Header, Text, Icon } from '../components'

export const AboutPanel = observer(function AboutPanel({ version, link, message }) {
    const store = useStore()
    const app = store.app

    const onClickAvatar = () => {
        if (link) {
            store.setLocation(store.LOCATIONS.WELCOME)
        }
    }

    return (
        <HView className="panel about-panel">
            <VView>
                <div className="avatar" onClick={onClickAvatar}>
                    <Icon name="unicat" size={80} title={app.text('Unicat')} />
                </div>
            </VView>
            <Spacer size={20} />
            <VView grow>
                <Spacer size={20} />
                <Header>{app.text('Unicat')}</Header>
                <Divider />
                <Header>{app.text('version {version}', { version: version })}</Header>
                <Spacer size={10} />
                <Text className="cc-note">
                    {message
                        ? message
                        : app.text('© 2020-{thisyear}, Guidance Rotterdam BV', {
                              thisyear: new Date().getFullYear(),
                          })}
                </Text>
            </VView>
        </HView>
    )
})

//
// SchemaPinboardStore
//
// The SchemaPinboardStore handles interaction with the pinboard area for pinning
// definitions, classes, and fields.
// Should work with treeview and search results.
//
// SIDE-EFFECT: when a user selects an item, we update the worksheet with that
//              same item

import { makeObservable, observable, action } from 'mobx'

export class SchemaPinboardStore {
    __init = false
    _key = null
    _rootstore = null
    _pinnedGids = null
    pinnedItems = null

    constructor(key, rootstore) {
        makeObservable(this, {
            __init: observable,
            _key: observable,
            _rootstore: observable,
            _pinnedGids: observable,
            pinnedItems: observable,
            loadOnce: action.bound,
            fetch: action.bound,
            refetch: action.bound,
            _fetch: action.bound,
            add: action.bound,
            remove: action.bound,
            clear: action.bound,
            selectItem: action.bound,
        })

        this.MAX_ITEMS = 5

        this._key = key
        this._rootstore = rootstore
        this._pinnedGids = []
        this.pinnedItems = []
    }

    loadOnce = () => {
        if (this.__init) return
        const pinnedGids = this._rootstore.view.loadPerProject(
            this._key + '/pinnedGids',
            []
        )
        this.fetch(pinnedGids)
        this._rootstore.api.register_syncdataitem_callback(this.syncdataitem_callback)
        this.__init = true
    }

    fetch(pinnedGids) {
        this._rootstore.view.savePerProject(this._key + '/pinnedGids', pinnedGids)
        return this._fetch(pinnedGids)
    }

    refetch() {
        return this._fetch(this._pinnedGids)
    }

    _fetch(pinnedGids) {
        if (!pinnedGids.length) {
            this.pinnedItems.replace([])
            return
        }
        const pinnedItems = pinnedGids
            .map(itemgid => {
                let pinneditem = null
                if (this._rootstore.data.definitions.has(itemgid)) {
                    pinneditem = this._rootstore.data.definitions.get(itemgid)
                    if (pinneditem) {
                        this._rootstore.data.definitions.forEach(item => {
                            if (item.is_base && item.original === pinneditem.gid) {
                                pinneditem = item
                            }
                        })
                    }
                } else if (this._rootstore.data.classes.has(itemgid)) {
                    pinneditem = this._rootstore.data.classes.get(itemgid)
                    if (pinneditem) {
                        this._rootstore.data.classes.forEach(item => {
                            if (item.is_base && item.original === pinneditem.gid) {
                                pinneditem = item
                            }
                        })
                    }
                } else if (this._rootstore.data.fields.has(itemgid)) {
                    pinneditem = this._rootstore.data.fields.get(itemgid)
                    if (pinneditem) {
                        this._rootstore.data.fields.forEach(item => {
                            if (item.is_base && item.original === pinneditem.gid) {
                                pinneditem = item
                            }
                        })
                    }
                }
                return pinneditem
            })
            .filter(item => item !== null)
        const foundGids = pinnedItems.map(item => item.gid)
        if (foundGids.length !== this._pinnedGids.length) {
            this._rootstore.view.savePerProject(this._key + '/pinnedGids', foundGids)
            this._pinnedGids.replace(foundGids)
        }
        this.pinnedItems.replace(pinnedItems)
    }

    exists(gid) {
        return this._pinnedGids.includes(gid)
    }

    canAdd() {
        return this._pinnedGids.length < this.MAX_ITEMS
    }

    add(gid) {
        if (!gid) return
        if (this.exists(gid)) return // no duplicates
        if (!this.canAdd()) return
        this._pinnedGids.push(gid)
        this.fetch(this._pinnedGids)
    }

    remove(gid) {
        this._pinnedGids.remove(gid)
        this.fetch(this._pinnedGids)
    }

    clear() {
        this._pinnedGids.clear()
        this.fetch(this._pinnedGids)
    }

    selectItem(item) {
        this._rootstore.view.schemaworksheet.setItem(item)
    }

    syncdataitem_callback = (syncdataitem, data_before, data_after) => {
        // console.log('SchemaPinboardStore sync', syncdataitem, data_before, data_after)
        if (!['definitions', 'classes', 'fields'].includes(syncdataitem['data_type'])) {
            return
        }
        if (syncdataitem['data_type'] === 'definitions') {
            const data = data_after ? data_after : data_before
            if (!data) return
            // don't update for extended classes
            if (data.original && !data.is_base) {
                return
            }
        }
        if (syncdataitem['action'] === 'INSERT') {
            if (this.exists(data_after.original)) {
                this.remove(data_after.original)
                this.add(data_after.gid)
            }
        } else if (syncdataitem['action'] === 'DELETE') {
            if (data_before && this.exists(data_before.gid)) {
                this.remove(data_before.gid)
                if (data_before.original) {
                    this.add(data_before.original)
                }
            }
        }
    }
}

//
// LoadingScreen
//
// Show this when we're loading the application from the server

import React from 'react'
import { observer } from 'mobx-react-lite'

import { AppScreen } from './AppScreen'
import { LoadingWorkspace } from '../workspaces'

export const LoadingScreen = observer(function LoadingScreen() {
    return (
        <AppScreen>
            <LoadingWorkspace />
        </AppScreen>
    )
})

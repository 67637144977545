//
// ImportExportWorkspace
//
// Manage import and export configurations.

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { VView } from '../appview'
import { WorkspaceView } from './WorkspaceView'
import { Text } from '../components'

import { ImportExportWorksheet } from './importexport'

export const ImportExportWorkspace = observer(function ImportExportWorkspace({
    user,
    project,
    viewkey,
    grow,
    ...props
}) {
    const { app } = useStore()

    const NavigatorView = (
        <VView grow>
            <Text>{app.text('')}</Text>
        </VView>
    )

    const WorksheetView = <ImportExportWorksheet project={project} />

    const InspectorView = (
        <VView grow>
            <Text>{app.text('')}</Text>
        </VView>
    )

    return (
        <WorkspaceView
            grow={grow}
            className="workspace-importexport"
            viewkey={viewkey}
            navigator={NavigatorView}
            worksheet={WorksheetView}
            inspector={InspectorView}
        />
    )
})

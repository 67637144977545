//
// FolderInspector
//
// An inspector for a folder; we can show subfoldercount, file count, timestamps?

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../stores'

import { VView, Spacer, Divider } from '../../../appview'
import { Header, Property, Text, TextInput } from '../../../components'

import { validAssetName } from '../../../utils/text'

export const FolderInspector = observer(function FolderInspector({ worksheet }) {
    const { app, DEBUG } = useStore()

    const asset = worksheet.asset

    const renderkey = asset.gid + '.' + worksheet.language

    const onChangeAssetName = event => {
        asset.setProperty('name', event.target.value)
    }
    const onBlurAssetName = event => {
        asset.setProperty('name', event.target.value.trim())
        asset.commitIfModified()
    }

    const Name =
        asset.pathname === '/' ? (
            <Text>{app.text('Assets')}</Text> // can't edit root name
        ) : (
            <TextInput
                autoConvert={validAssetName}
                onChange={onChangeAssetName}
                onBlur={onBlurAssetName}
                value={asset.publicname}
                renderkey={renderkey}
            />
        )

    const DebugInfo = DEBUG ? (
        <div className="DEBUG">
            <Spacer size={5} />
            <Divider />
            <Header>{app.text('Reference info (debug only)')}</Header>
            <Property label={app.text('gid')}>
                <Text>{asset.gid}</Text>
            </Property>
        </div>
    ) : undefined

    return (
        <VView grow className="panel properties">
            <Header>{app.text('Main properties')}</Header>
            <Property label={app.text('name')}>{Name}</Property>
            <Property label={app.text('pathname')}>
                <Text>{asset.pathname}</Text>
            </Property>
            <Property label={app.text('subfolders')}>
                <Text>{asset.info.get('foldercount')}</Text>
            </Property>
            <Property label={app.text('files')}>
                <Text>{asset.info.get('filecount')}</Text>
            </Property>
            {DebugInfo}
        </VView>
    )
})

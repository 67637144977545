//
// ProjectMember
//
// Show member info: Avatar, name, roles (only if given a member, not a user)

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { HView } from '../appview'
import { Text } from '../components'
import { Avatar } from '../panels'

export const ProjectMember = observer(
    React.forwardRef(function ProjectMember({ user, member, ...other }, ref) {
        const { data } = useStore()

        if (!user && !member) return null

        const User = user ? user : data.users.get(member.user_gid)
        if (!User) return null

        const Roles =
            user || ['invited', 'declined'].includes(member.status)
                ? undefined
                : member.roles.map(role => (
                      <span
                          key={role}
                          className={'role cc-' + (role === 'owner' ? 'ok' : 'info')}
                      >
                          {role}
                      </span>
                  ))
        const Status =
            user ||
            !['invited', 'inactive', 'declined'].includes(member.status) ? undefined : (
                <span
                    className={
                        'role cc-' + (member.status === 'invited' ? 'notice' : 'danger')
                    }
                >
                    {member.status}
                </span>
            )

        return (
            <HView className="cc-ProjectMember" ref={ref} {...other}>
                <Avatar user={User} />
                <Text>
                    {User.displayname}
                    {Roles}
                    {Status}
                </Text>
            </HView>
        )
    })
)

//
// NumberFieldOptions
//
// Field options for type 'number'

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'

import { TextInput } from '../../../../components'
import { LabeledField } from '../../../components'

import { VALIDATION } from '../../../../utils/validation'
import { duckField, validateField } from '../../../../stores/data/validators'
import { InitialFieldOption } from './InitialFieldOption'

export const NumberFieldOptions = observer(function NumberFieldOptions({
    field,
    selectModified,
    worksheet,
    onAction,
    setSelected,
    renderkeyprefix,
}) {
    const { app } = useStore()

    if (!field) return null
    const min = field.options.get('min')
    const max = field.options.get('max')

    const onChangeFieldOption = option => {
        return event => {
            field.setOption(option, event.target.value)
        }
    }
    const onBlurFieldOption = (option, validator) => {
        return event => {
            if (validator(event.target.value, field).result === VALIDATION.ERROR) {
                // TODO: CC-204 resetOption
            } else {
                field.setOption(option, event.target.value.trim())
                field.commitIfModified().then(result => selectModified(result))
            }
        }
    }

    const duckMinField = duckField('number', { max: max }) // min < max
    const minValidator = fieldvalue => {
        return validateField(fieldvalue, duckMinField, worksheet.language)
    }
    const onChangeFieldMin = onChangeFieldOption('min')
    const onBlurFieldMin = onBlurFieldOption('min', minValidator)

    const duckMaxField = duckField('number', { min: min }) // max > min
    const maxValidator = fieldvalue => {
        return validateField(fieldvalue, duckMaxField, worksheet.language)
    }
    const onChangeFieldMax = onChangeFieldOption('max')
    const onBlurFieldMax = onBlurFieldOption('max', maxValidator)

    const duckInitialField = duckField('number', { min: min, max: max }) // min <= initial <= max

    return (
        <>
            <LabeledField label={app.text('min value')}>
                <TextInput
                    onChange={onChangeFieldMin}
                    onBlur={onBlurFieldMin}
                    value={min}
                    renderkey={renderkeyprefix + '.min'}
                    validate={minValidator}
                />
            </LabeledField>
            <LabeledField label={app.text('max value')}>
                <TextInput
                    onChange={onChangeFieldMax}
                    onBlur={onBlurFieldMax}
                    value={max}
                    renderkey={renderkeyprefix + '.max'}
                    validate={maxValidator}
                />
            </LabeledField>
            <InitialFieldOption
                field={field}
                initialField={duckInitialField}
                selectModified={selectModified}
                worksheet={worksheet}
                onAction={onAction}
                setSelected={setSelected}
                renderkeyprefix={renderkeyprefix}
            />
        </>
    )
})

//
// TextListMenu
//
// Actions on a textlist-input.

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { VView } from '../appview'
import { Menu, MenuItem } from '../components'

const ConfirmSubmenu = observer(function ConfirmSubmenu({ onAction }) {
    const { app } = useStore()

    const _onAction = (e, action) => {
        onAction && onAction(action)
    }

    return (
        <Menu>
            <MenuItem className="cc-danger" onClick={e => _onAction(e, 'remove_all')}>
                {app.text('Yes, clear this list')}
            </MenuItem>
        </Menu>
    )
})

export const TextListMenu = observer(
    React.forwardRef(function TextListMenu({ onAction, ...other }, ref) {
        const { app } = useStore()

        const _onAction = action => {
            onAction && onAction(action)
        }

        return (
            <VView
                ref={ref}
                className="textlist-menu"
                onClick={e => e.nativeEvent.stopImmediatePropagation()}
                {...other}
            >
                <Menu>
                    <MenuItem onClick={() => _onAction('add_item')}>
                        {app.text('Add item before this one')}
                    </MenuItem>
                    <MenuItem
                        className="cc-danger"
                        onClick={() => _onAction('remove_item')}
                    >
                        {app.text('Remove from list')}
                    </MenuItem>
                    <MenuItem
                        className="cc-danger"
                        submenu={<ConfirmSubmenu onAction={_onAction} />}
                    >
                        {app.text('Remove all')}
                    </MenuItem>
                </Menu>
            </VView>
        )
    })
)

//
// Header
//
// A simple header.

import React from 'react'

export const Header = props => {
    const { className, ...other } = props
    let classes = 'cc-Header'
    if (className) classes += ' ' + className
    return <div className={classes} {...other} />
}

//
// AssetItem
//
// A simple asset item, with icon, name and path

import React from 'react'
import { observer } from 'mobx-react-lite'
import { Transform } from '../stores/imageservers/utils'

import { HView, VView, View } from '../appview'
import { AssetThumb, IconTile } from '.'
import { maxChars } from '../utils/text'

export const AssetItem = observer(function AssetItem({
    asset,
    className,
    iconplaceholder,
    thumbsize,
    ...other
}) {
    let classes = 'cc-AssetItem'
    if (className) classes += ' ' + className
    thumbsize = thumbsize || 56

    if (!asset) {
        return (
            <IconTile
                className={classes + ' cc-dimmed'}
                width={thumbsize}
                icon={iconplaceholder ? iconplaceholder : 'plus'}
                anchor="left"
                {...other}
            />
        )
    }

    const assetTransform = new Transform('fit', thumbsize, thumbsize)

    return (
        <HView
            className={classes}
            {...other}
            gap={10}
            style={{
                flexBasis: thumbsize + 'px',
                minHeight: thumbsize,
                maxHeight: thumbsize,
            }}
        >
            <View
                className="cc-Image-background cc-noline"
                style={{
                    flexBasis: thumbsize + 'px',
                    minWidth: thumbsize,
                    maxWidth: thumbsize,
                }}
            >
                <AssetThumb
                    asset={asset}
                    iconplaceholder={iconplaceholder}
                    transform={assetTransform}
                />
            </View>
            <VView grow>
                <div title={asset.name}>{maxChars(asset.name, 24)}</div>
                <div title={asset.path} className="cc-dimmed">
                    {maxChars(asset.path, 24)}
                </div>
            </VView>
        </HView>
    )
})

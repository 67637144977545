//
// FilterItem
//
// A FilterItem is used in constructing search queries.

import React from 'react'

export const FilterItem = React.forwardRef(
    ({ className, indent, selected, ...other }, ref) => {
        let classes = 'cc-FilterItem'
        if (className) classes += ' ' + className
        if (indent) classes += ' cc-indent' + indent
        if (selected) classes += ' cc-selected'
        return <div ref={ref} className={classes} {...other} />
    }
)

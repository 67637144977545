//
// RecordFieldOptions
//
// Field options for type 'record'

import React from 'react'
import { observer } from 'mobx-react-lite'

export const RecordFieldOptions = observer(function RecordFieldOptions({
    field,
    selectModified,
    worksheet,
    onAction,
    setSelected,
    renderkeyprefix,
}) {
    if (!field) return null

    return <></>
})

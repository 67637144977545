//
// DamFolderSearchStore
//
// The DamFolderSearchStore handles interaction with the Dam Folder Search

import { makeObservable, observable, action, runInAction } from 'mobx'

export class DamFolderSearchStore {
    __init = false
    _key = null
    _rootstore = null
    q = null
    topindex = null
    windowsize = null
    results = null
    totalsize = null

    constructor(key, rootstore) {
        makeObservable(this, {
            __init: observable,
            _key: observable,
            _rootstore: observable,
            q: observable,
            topindex: observable,
            windowsize: observable,
            results: observable,
            totalsize: observable,
            fetch: action.bound,
            refetch: action.bound,
            _fetch: action.bound,
            syncdataitem_callback: action.bound,
        })

        this._key = key
        this._rootstore = rootstore
        this.q = ''
        this.topindex = 0
        this.windowsize = 10
        // filled after fetch
        this.results = []
        this.totalsize = 0

        this._syncdebounce = null
        this._syncdelay = 100
    }

    loadOnce = () => {
        if (this.__init) return
        this.q = this._rootstore.view.loadPerProject(this._key + '/q', '')
        this.topindex = this._rootstore.view.loadPerProject(this._key + '/topindex', 0)
        this.windowsize = this._rootstore.view.loadPerProject(
            this._key + '/windowsize',
            10
        )
        this._fetch(this.q, this.topindex, this.windowsize)

        this._rootstore.api.register_syncdataitem_callback(this.syncdataitem_callback)
        this.__init = true
    }

    fetch(q, topindex, windowsize) {
        let fetchneeded = false
        if (q !== undefined && this.q !== q) {
            fetchneeded = true
        }
        if (topindex !== undefined && this.topindex !== topindex) {
            fetchneeded = true
        }
        if (windowsize !== undefined && this.windowsize !== windowsize) {
            fetchneeded = true
        }
        if (!fetchneeded) {
            return Promise.resolve(null)
        }
        if (q !== undefined) this.q = q
        if (topindex !== undefined) this.topindex = topindex
        if (windowsize !== undefined) this.windowsize = windowsize
        this._rootstore.view.savePerProject(this._key + '/q', this.q)
        this._rootstore.view.savePerProject(this._key + '/topindex', this.topindex)
        this._rootstore.view.savePerProject(this._key + '/windowsize', this.windowsize)
        return this._fetch(this.q, this.topindex, this.windowsize)
    }

    refetch() {
        return this._fetch(this.q, this.topindex, this.windowsize)
    }

    _fetch(q, topindex, windowsize) {
        return this._rootstore
            ._fetch('/assets/search/folders', {
                q: q,
                language: this._rootstore.view.environment.get('language'),
                'page.top': topindex,
                'page.size': windowsize,
            })
            .then(result => {
                runInAction(() => {
                    this.results = result['assets']
                    this.totalsize = result['assets.size']
                })
                return result
            })
        // .catch(error => {})
    }

    schedule_refetch = () => {
        if (this._syncdebounce) {
            clearTimeout(this._syncdebounce)
            this._syncdebounce = null
        }
        this._syncdebounce = setTimeout(() => {
            this.refetch()
            this._syncdebounce = null
        }, this._syncdelay)
    }

    syncdataitem_callback = (syncdataitem, data_before, data_after) => {
        // console.log('DamQueryStore sync', syncdataitem, data_before, data_after)
        // we're only interested in data_type "assets"
        // then, we're only interested in updates (because our results could change)
        // from those records, only for assets that are in our store
        if (syncdataitem['data_type'] !== 'assets') return
        if (!['UPDATE', 'DELETE'].includes(syncdataitem['action'])) return
        if (!this.results.includes(syncdataitem['data_key'])) return
        this.schedule_refetch()
    }
}

export const getContentRect = element => {
    if (!element) return null
    const clientRect = element.getBoundingClientRect()
    const style = getComputedStyle(element)
    const paddingTop = parseFloat(style.paddingTop)
    const paddingRight = parseFloat(style.paddingRight)
    const paddingBottom = parseFloat(style.paddingBottom)
    const paddingLeft = parseFloat(style.paddingLeft)
    return {
        left: clientRect.left + paddingLeft,
        top: clientRect.top + paddingTop,
        right: clientRect.right - paddingRight,
        bottom: clientRect.bottom - paddingBottom,
        x: clientRect.x + paddingLeft,
        y: clientRect.y + paddingTop,
        width: clientRect.width - paddingLeft - paddingRight,
        height: clientRect.height - paddingTop - paddingBottom,
    }
}

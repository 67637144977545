//
// FilterStore
//
// A FilterStore is a Tree made of FilterStoreItems.
// The Filter tree doesn't expand or collapse.
// There can be many filter trees for different parts of the ui.

import { makeObservable, observable, computed, action, isObservableArray } from 'mobx'
import { gid } from '../../utils/gid'

export class FilterStoreItem {
    key = null
    prop = null
    op = null
    arg = null

    constructor(key, prop, op, arg) {
        makeObservable(this, {
            key: observable,
            prop: observable,
            op: observable,
            arg: observable,
        })

        this.key = key
        this.prop = prop
        this.op = op
        this.arg = arg
    }
}

// THIS:
//
// ["and", "", [
//     ["type", "is", "image"],
//     ["or", "", [
//         ["width", "<=", 600],
//         ["height", "<=", 600],
//     ]],
// ]]
//
// BECOMES:
//
//  0   ["and", "", [a, b]]
//  a   ["type", "is", "image"]
//  b   ["or", "", [c, d]]
//  c   ["width", "<=", 600]
//  d   ["height", "<=", 600]
// root: 0 ==> [0, a, b, c, d]
//
// That way we have each item available under a unique key so we can modify them easily.
// This is basically how layouts is structured too, except that the root/mapping is also
// the datastructure stored in the db, here we have the actual tree representation.
//
// Use _list_from_tree and _tree_from_list to get either representation

export class FilterStore {
    doctype = null
    _root = null
    _filters = null
    refreshkey = null

    constructor(doctype, filter) {
        makeObservable(this, {
            doctype: observable,
            _root: observable,
            _filters: observable,
            setFilter: action.bound,
            filter: computed,

            refreshkey: observable,

            createFilter: action.bound,
            updateFilter: action.bound,
            updateFilterOp: action.bound,
            updateFilterArg: action.bound,
            moveFilter: action.bound,
            deleteFilter: action.bound,
            wrapFilter: action.bound,
            unwrapFilter: action.bound,
        })

        this.doctype = doctype
        this._root = null
        this._filters = new Map()
        this.setFilter(doctype, filter)
        this.refreshkey = gid()
    }

    setFilter(doctype, filter) {
        this.doctype = doctype
        const [root, filters] = this._list_from_tree(filter)
        this._root = root
        this._filters.replace(filters)
        this.refreshkey = gid()
    }

    _list_from_tree(filter) {
        const key = gid()
        let filters = new Map()

        const prop = filter[0]
        const op = filter[1]
        let arg = filter.length > 2 ? filter[2] : null
        if (prop === 'and' || prop === 'or') {
            // add all args to _filters
            arg = arg.map(arg_filter => {
                if (arg_filter !== null) {
                    const [arg_key, arg_filters] = this._list_from_tree(arg_filter)
                    filters = new Map([...filters, ...arg_filters])
                    return arg_key
                }
                return null
            })
        } else if (prop === 'not') {
            // add single arg to _filters
            if (arg !== null) {
                const [arg_key, arg_filters] = this._list_from_tree(arg)
                arg = arg_key
                filters = new Map([...filters, ...arg_filters])
            }
        }

        filters.set(key, new FilterStoreItem(key, prop, op, arg))
        return [key, filters]
    }

    _tree_from_list(key, filters) {
        if (key === null) return null
        const filteritem = filters.get(key)
        let arg = filteritem.arg
        if (filteritem.prop === 'and' || filteritem.prop === 'or') {
            arg = arg.map(arg_key => this._tree_from_list(arg_key, filters))
        } else if (filteritem.prop === 'not') {
            arg = this._tree_from_list(filteritem.arg, filters)
        }
        return [filteritem.prop, filteritem.op, arg]
    }

    get filter() {
        if (!this._root || !this._filters.has(this._root)) {
            return null
        }
        return this._tree_from_list(this._root, this._filters)
    }

    _find_parent(childkey) {
        if (childkey === this._root) {
            return null
        }
        for (let parentfilteritem of this._filters.values()) {
            if (parentfilteritem.prop === 'and' || parentfilteritem.prop === 'or') {
                if (parentfilteritem.arg.includes(childkey)) {
                    return parentfilteritem
                }
            } else if (parentfilteritem.prop === 'not') {
                if (parentfilteritem.arg === childkey) {
                    return parentfilteritem
                }
            }
        }
        return null
    }

    pathCheck(key, ancestorkey) {
        if (key === ancestorkey) {
            return true
        }
        if (key === this._root) {
            return false
        }
        if (!ancestorkey || !this._filters.has(ancestorkey)) {
            return false
        }
        const ancestoritem = this._filters.get(ancestorkey)

        if (ancestoritem.prop === 'and' || ancestoritem.prop === 'or') {
            if (ancestoritem.arg.includes(key)) {
                return true
            } else {
                return ancestoritem.arg.some(ancestoritemarg =>
                    this.pathCheck(key, ancestoritemarg)
                )
            }
        } else if (ancestoritem.prop === 'not') {
            if (ancestoritem.arg === key) {
                return true
            } else {
                return this.pathCheck(key, ancestoritem.arg)
            }
        }
        return false
    }

    createFilter(prop, op, arg, new_parentkey, before_key) {
        // no parent? return null
        const parent = this._filters.get(new_parentkey)
        if (!parent) {
            return null
        }
        // parent full? return null
        if (parent.prop === 'not' && parent.arg) {
            return null
        }
        // create a new filteritem
        const key = gid()
        this._filters.set(key, new FilterStoreItem(key, prop, op, arg))
        // add it to the parent
        if (parent.prop === 'and' || parent.prop === 'or') {
            let new_arg = []
            parent.arg.forEach(arg => {
                if (arg === before_key) {
                    new_arg.push(key)
                }
                new_arg.push(arg)
            })
            if (!new_arg.includes(key)) {
                new_arg.push(key)
            }
            parent.arg.replace(new_arg)
        } else if (parent.prop === 'not') {
            parent.arg = key
        }
        this.refreshkey = gid()
        return key
    }

    updateFilter(key, prop, op, arg) {
        // not found? return null
        if (!this._filters.has(key)) {
            return null
        }
        const filter = this._filters.get(key)
        filter.prop = prop
        filter.op = op
        filter.arg = arg
        this.refreshkey = gid()
        // NOTE: if we go from and/or/not to some other filter (we shouldn't), we should
        // remove the children
    }

    updateFilterOp(key, op) {
        // not found? return null
        if (!this._filters.has(key)) {
            return null
        }
        const filter = this._filters.get(key)
        filter.op = op
        this.refreshkey = gid()
    }

    updateFilterArg(key, arg) {
        // not found? return null
        if (!this._filters.has(key)) {
            return null
        }
        const filter = this._filters.get(key)
        if (isObservableArray(filter.arg)) {
            filter.arg.replace(arg)
        } else {
            filter.arg = arg
        }
        this.refreshkey = gid()
    }

    moveFilter(key, new_parentkey, before_key) {
        // not found? return null
        if (!this._filters.has(key)) {
            return null
        } // no parent or parent not found? return null
        if (!this._filters.has(new_parentkey)) {
            return null
        }
        const new_parent = this._filters.get(new_parentkey)
        // new parent not of type and/or/not? return null
        if (!['and', 'or', 'not'].includes(new_parent.prop)) {
            return null
        }
        // new parent full? return null
        if (new_parent.prop === 'not' && new_parent.arg) {
            return null
        }
        const parent = this._find_parent(key)
        // remove key from and/or/not arg from existing parent
        if (parent) {
            if (parent.prop === 'and' || parent.prop === 'or') {
                parent.arg.remove(key)
            } else if (parent.prop === 'not') {
                parent.arg = null
            }
        }
        // add key to and/or/not arg at new parent
        if (new_parent.prop === 'and' || new_parent.prop === 'or') {
            let new_arg = []
            new_parent.arg.forEach(arg => {
                if (arg === before_key) {
                    new_arg.push(key)
                }
                new_arg.push(arg)
            })
            if (!new_arg.includes(key)) {
                new_arg.push(key)
            }
            new_parent.arg.replace(new_arg)
        } else if (new_parent.prop === 'not') {
            new_parent.arg = key
        }
        this.refreshkey = gid()
    }

    deleteFilter(key) {
        // not found? return null
        if (!this._filters.has(key)) {
            return null
        }
        // delete filter
        this._filters.delete(key)
        // remove it from parent
        const parent = this._find_parent(key)
        if (parent) {
            if (parent.prop === 'and' || parent.prop === 'or') {
                parent.arg.remove(key)
            } else if (parent.prop === 'not') {
                parent.arg = null
            }
        }
        // if we deleted the root, replace it with an empty 'and' filter
        if (!this._root || !this._filters.has(this._root)) {
            const new_root_key = gid()
            this._filters.set(
                new_root_key,
                new FilterStoreItem(new_root_key, 'and', '', [])
            )
            this._root = new_root_key
        }
        // recursively delete subtree from list -- by converting to & from again
        this.setFilter(this.doctype, this.filter)
        this.refreshkey = gid()
    }

    wrapFilter(key, prop) {
        // not found? return null
        if (!this._filters.has(key)) {
            return null
        }
        // wrapper is not and/or/not? return null
        if (!['and', 'or', 'not'].includes(prop)) {
            return null
        }
        const parent = this._find_parent(key)
        // create a new and/or/not filter with the key as the arg
        const new_key = gid()
        const arg = prop === 'not' ? key : [key]
        this._filters.set(new_key, new FilterStoreItem(new_key, prop, '', arg))
        // replace the filter's parent arg with the new filter
        if (!parent) {
            // it is the root, so replace the root
            this._root = new_key
        } else if (parent.prop === 'and' || parent.prop === 'or') {
            const index = parent.arg.indexOf(key)
            parent.arg[index] = new_key
        } else if (parent.prop === 'not') {
            parent.arg = new_key
        }
        this.refreshkey = gid()
        return new_key
    }

    unwrapFilter(key) {
        // not found? return null
        if (!this._filters.has(key)) {
            return null
        }
        // filter not of type and/or/not? return null
        const filter = this._filters.get(key)
        if (!['and', 'or', 'not'].includes(filter.prop)) {
            return null
        }
        // if filter has more than one arg, return null
        if (['and', 'or'].includes(filter.prop) && filter.arg.length > 1) {
            return null
        }
        const parent = this._find_parent(key)
        // if filter has zero args, delete filter, remove from parent
        if (
            ((filter.prop === 'and' || filter.prop === 'or') &&
                (!filter.arg || !filter.arg.length)) ||
            (filter.prop === 'not' && !filter.arg)
        ) {
            this._filters.delete(key)
            if (!parent) {
                // it is the root, we get an empty root -> set to 'and','',[]
                const new_root_key = gid()
                this._filters.set(
                    new_root_key,
                    new FilterStoreItem(new_root_key, 'and', '', [])
                )
                this._root = new_root_key
            } else if (parent.prop === 'and' || parent.prop === 'or') {
                parent.arg.remove(key)
            } else if (parent.prop === 'not') {
                parent.arg = null
            }
        } else {
            // if filter has single arg, replace parent entry with single arg, then delete filter
            const childkey =
                filter.prop === 'and' || filter.prop === 'or'
                    ? filter.arg[0]
                    : filter.arg
            this._filters.delete(key)
            if (!parent) {
                // it is the root, so replace the root
                this._root = childkey
            } else if (parent.prop === 'and' || parent.prop === 'or') {
                const index = parent.arg.indexOf(key)
                parent.arg[index] = childkey
            } else if (parent.prop === 'not') {
                parent.arg = childkey
            }
        }
        this.refreshkey = gid()
        return parent ? parent.key : this._root
    }

    hasFilters() {
        if (this._root === null) return false
        const rootfilter = this._filters.get(this._root)
        if (['and', 'or'].includes(rootfilter.prop) && !rootfilter.arg.length) {
            return false
        }
        return true
    }
}

//
// AccountScreen
//
// Account management: user/login info and project management screen.

import React from 'react'
import { useStore } from '../stores'
import { observer } from 'mobx-react-lite'

import { AppScreen } from './AppScreen'
import {
    LoadingWorkspace,
    BusyWorkspace,
    LoginWorkspace,
    AccountWorkspace,
} from '../workspaces'

export const AccountScreen = observer(function AccountScreen(props) {
    const store = useStore()

    let Workspace = <LoadingWorkspace />
    if (store.is_busy) {
        Workspace = <BusyWorkspace />
    } else if (!store.view.user) {
        // deprecated: we should never get here
        Workspace = <LoginWorkspace />
    } else if (store.view.user) {
        Workspace = (
            <AccountWorkspace
                grow
                user={store.user}
                project={store.project}
                viewkey={store.userkey + '/account'}
            />
        )
    }

    return (
        <AppScreen user={store.user} project={store.project}>
            {Workspace}
        </AppScreen>
    )
})

//
// SearchUploadAssetMenu
//
// SearchPanel or Upload?

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'
import { useFileUpload } from '../hooks/useFileUpload'

import { VView, View } from '../appview'
import { Menu, MenuItem } from '../components'
import { AssetSearchPanel } from '../panels'
import { UploadedAssetMenu } from '../menus'

export const SearchUploadAssetMenu = observer(
    React.forwardRef(function SearchUploadAssetMenu(
        { add, multiple, onAction, ...other },
        ref
    ) {
        const { app, view } = useStore()

        const _onAction = (action, p1) => {
            onAction && onAction(action, p1)
        }

        const onSelectAsset = assetgid => {
            _onAction('select_asset', assetgid)
        }

        const FileUpload = useFileUpload()

        const [droppedfiles, setDroppedFiles] = useState(null)

        const onFileSelection = file_or_files => {
            setDroppedFiles(file_or_files)
            if (multiple) {
                _onAction('upload_multiple', file_or_files)
            } else if (!file_or_files.type) {
                window.setTimeout(() => setDroppedFiles(null), 500)
            } else if (add) {
                _onAction('upload_new', file_or_files)
            }
        }

        const searchpanelstyle = { display: 'flex', width: 320, minHeight: 500 }

        const hasUploadedAssetMenu = !!droppedfiles && !add

        return (
            <VView
                ref={ref}
                className="uploaded-asset-menu"
                onClick={e => e.nativeEvent.stopImmediatePropagation()}
                {...other}
            >
                <FileUpload.Input onFileChange={onFileSelection} multiple={multiple} />
                <Menu>
                    <MenuItem
                        submenu={
                            <View className="inspector" style={searchpanelstyle}>
                                <AssetSearchPanel
                                    querystore={view.assetquery}
                                    preview={true}
                                    onSelect={onSelectAsset}
                                />
                            </View>
                        }
                    >
                        {app.text('Search for asset')}
                    </MenuItem>
                    <MenuItem
                        className="cc-danger"
                        onClick={e => FileUpload.openFileDialog()}
                        active={hasUploadedAssetMenu}
                        submenu={
                            hasUploadedAssetMenu ? (
                                <UploadedAssetMenu
                                    file={droppedfiles}
                                    onAction={onAction}
                                />
                            ) : undefined
                        }
                    >
                        {app.text('Upload…')}
                    </MenuItem>
                </Menu>
            </VView>
        )
    })
)

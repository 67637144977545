//
// RecordItem
//
// A simple record item, with name and definition

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'
import { Transform } from '../stores/imageservers/utils'

import { HView, VView, View } from '../appview'
import { Icon } from '../components'
import { RecordThumb, IconTile } from '.'
import { maxChars } from '../utils/text'

export const RecordItem = observer(function RecordItem({
    record,
    className,
    iconplaceholder,
    thumbsize,
    ...other
}) {
    const { app, data } = useStore()

    let classes = 'cc-RecordItem'
    if (className) classes += ' ' + className
    thumbsize = thumbsize || 56

    if (!record) {
        return (
            <IconTile
                className={classes + ' cc-dimmed'}
                width={thumbsize}
                icon={iconplaceholder ? iconplaceholder : 'plus'}
                anchor="left"
                {...other}
            />
        )
    }

    const title =
        record.localized_title && record.localized_title.length
            ? record.localized_title
            : app.text('[empty]')

    const definition = data.definitions.get(record.definition)

    const assetTransform = new Transform('fit', thumbsize, thumbsize)

    const LinkIcon = record.is_link ? <Icon name="link" size={'text'} /> : undefined

    return (
        <HView
            className={classes}
            {...other}
            gap={10}
            style={{
                flexBasis: thumbsize + 'px',
                minHeight: thumbsize,
                maxHeight: thumbsize,
            }}
        >
            <View
                className="cc-Image-background cc-noline"
                style={{
                    flexBasis: thumbsize + 'px',
                    minWidth: thumbsize,
                    maxWidth: thumbsize,
                }}
            >
                <RecordThumb
                    record={record}
                    iconplaceholder={iconplaceholder}
                    transform={assetTransform}
                />
            </View>
            <VView grow>
                <div title={title}>
                    {LinkIcon}
                    {maxChars(title, 24)}
                </div>
                <div title={definition && definition.name} className="cc-dimmed">
                    {maxChars(definition ? definition.name : '', 24)}
                </div>
            </VView>
        </HView>
    )
})

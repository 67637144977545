//
// WorkspaceView
//
// A workspace has a navigator/worksheet/inspector setup, optionally a statusbar.
//
// Navigator and Inspector widths are persisted per workspace.
//
// We have a min/max width for Navigator and Inspector, and a min for the Worksheet.
// We do a fancy calculation to make sure everything fits inside the window, for
// example if the navigator is made wider using the splitter, the worksheet shrinks
// until it reaches the minimum, after that the inspector is shrunk too.
// Make sure that the three minimum widths put together (plus the width of the two
// splitters) always fit inside the window (e.g. by setting a minWidth on the html body).

import React from 'react'
import { observer } from 'mobx-react-lite'
import { usePersistentState } from '../hooks/usePersistentState'
import { useOnResize } from '../hooks/useOnResize'

import { VView, HView, Divider, Splitter } from '../appview'

export const WorkspaceView = observer(function WorkspaceView({
    className,
    viewkey,
    navigator,
    worksheet,
    inspector,
    statusbar,
    minNavigatorWidth,
    minInspectorWidth,
    maxNavigatorWidth,
    maxInspectorWidth,
    minWorksheetWidth,
    splitterWidth,
    grow,
    ...props
}) {
    let classes = 'workspace'
    if (className) {
        classes += ' ' + className
    }

    const windowsize = useOnResize()
    const windowWidth = windowsize[0]
    minNavigatorWidth = minNavigatorWidth || 250
    minInspectorWidth = minInspectorWidth || 250
    maxNavigatorWidth = maxNavigatorWidth || 400
    maxInspectorWidth = maxInspectorWidth || 500
    minWorksheetWidth = minWorksheetWidth || 600 // set in css
    splitterWidth = splitterWidth || 1 // set in css

    const [navigatorWidth, setNavigatorWidth] = usePersistentState(
        viewkey + '/navigatorWidth',
        minNavigatorWidth
    )
    const navigatorRef = React.useRef(null)
    const [splittingNavigatorWidth, setSplittingNavigatorWidth] =
        React.useState(navigatorWidth)

    const [inspectorWidth, setInspectorWidth] = usePersistentState(
        viewkey + '/inspectorWidth',
        minInspectorWidth
    )
    const inspectorRef = React.useRef(null)
    const [splittingInspectorWidth, setSplittingInspectorWidth] =
        React.useState(inspectorWidth)

    const worksheetRef = React.useRef(null)

    const splittingWorksheetWidth = Math.max(
        minWorksheetWidth,
        windowWidth -
            (splittingNavigatorWidth + splittingInspectorWidth + 2 * splitterWidth)
    )

    const calculateWidths = React.useCallback(
        (newNavigatorWidth, newInspectorWidth) => {
            let nav = Math.max(
                minNavigatorWidth,
                Math.min(
                    maxNavigatorWidth,
                    newNavigatorWidth ? newNavigatorWidth : splittingNavigatorWidth
                )
            )
            let ins = Math.max(
                minInspectorWidth,
                Math.min(
                    maxInspectorWidth,
                    newInspectorWidth ? newInspectorWidth : splittingInspectorWidth
                )
            )
            const worksheetWidth = Math.max(
                minWorksheetWidth,
                windowWidth - (nav + ins + 2 * splitterWidth)
            )
            // since worksheetWidth has a minimum, we may have to adapt nav & ins to fit
            if (worksheetWidth + nav + ins + 2 * splitterWidth > windowWidth) {
                // inspector width changed? shrink nav but respect min/max
                if (newInspectorWidth) {
                    nav = Math.max(
                        minNavigatorWidth,
                        Math.min(
                            maxNavigatorWidth,
                            windowWidth - (worksheetWidth + ins + 2 * splitterWidth)
                        )
                    )
                    if (worksheetWidth + nav + ins + 2 * splitterWidth > windowWidth) {
                        ins = Math.max(
                            minInspectorWidth,
                            Math.min(
                                maxInspectorWidth,
                                windowWidth - (nav + worksheetWidth + 2 * splitterWidth)
                            )
                        )
                    }
                } else if (newNavigatorWidth) {
                    ins = Math.max(
                        minInspectorWidth,
                        Math.min(
                            maxInspectorWidth,
                            windowWidth - (nav + worksheetWidth + 2 * splitterWidth)
                        )
                    )
                    if (worksheetWidth + nav + ins + 2 * splitterWidth > windowWidth) {
                        nav = Math.max(
                            minNavigatorWidth,
                            Math.min(
                                maxNavigatorWidth,
                                windowWidth - (worksheetWidth + ins + 2 * splitterWidth)
                            )
                        )
                    }
                }
            }
            setSplittingNavigatorWidth(nav)
            setSplittingInspectorWidth(ins)
            return [nav, worksheetWidth, ins]
        },
        [
            windowWidth,
            minNavigatorWidth,
            maxNavigatorWidth,
            minInspectorWidth,
            maxInspectorWidth,
            minWorksheetWidth,
            splitterWidth,
            splittingNavigatorWidth,
            setSplittingNavigatorWidth,
            splittingInspectorWidth,
            setSplittingInspectorWidth,
        ]
    )

    const onSplitNavigator = newValue => {
        calculateWidths(newValue, undefined)
    }

    const onSplitInspector = newValue => {
        calculateWidths(undefined, newValue)
    }

    const onSplitNavigatorEnd = newValue => {
        const [nav, , ins] = calculateWidths(newValue, undefined)
        setNavigatorWidth(nav)
        setInspectorWidth(ins)
    }

    const onSplitInspectorEnd = newValue => {
        const [nav, , ins] = calculateWidths(undefined, newValue)
        setNavigatorWidth(nav)
        setInspectorWidth(ins)
    }

    React.useEffect(() => {
        setSplittingNavigatorWidth(navigatorWidth)
        setSplittingInspectorWidth(inspectorWidth)
    }, [navigatorWidth, inspectorWidth])

    React.useEffect(() => {
        if (navigatorRef) {
            navigatorRef.current.style.flexBasis = splittingNavigatorWidth + 'px'
            navigatorRef.current.style.maxWidth = splittingNavigatorWidth + 'px'
            navigatorRef.current.style.minWidth = splittingNavigatorWidth + 'px'
        }
        if (worksheetRef) {
            worksheetRef.current.style.flexBasis = splittingWorksheetWidth + 'px'
            worksheetRef.current.style.maxWidth = splittingWorksheetWidth + 'px'
            worksheetRef.current.style.minWidth = splittingWorksheetWidth + 'px'
        }
        if (inspectorRef) {
            inspectorRef.current.style.flexBasis = splittingInspectorWidth + 'px'
            inspectorRef.current.style.maxWidth = splittingInspectorWidth + 'px'
            inspectorRef.current.style.minWidth = splittingInspectorWidth + 'px'
        }
        // if (sizeClasses) {
        //     let sizeclass = ''
        //     const sizes = Object.keys(sizeClasses)
        //     for (const sizeclassSize of sizes) {
        //         if (size >= sizeclassSize) {
        //             sizeclass = sizeClasses[sizeclassSize]
        //         }
        //     }
        //     target.current.setAttribute('data-sizeclass', sizeclass)
        // }
    }, [splittingNavigatorWidth, splittingInspectorWidth, splittingWorksheetWidth])

    return (
        <VView grow={grow} className={classes}>
            <HView grow={grow}>
                <VView className="navigator" ref={navigatorRef}>
                    {navigator}
                </VView>

                <Splitter
                    direction="end"
                    initial={navigatorWidth}
                    onDragSplitter={newValue => onSplitNavigator(newValue)}
                    onDragSplitterEnd={newValue => onSplitNavigatorEnd(newValue)}
                />

                <VView ref={worksheetRef}>{worksheet}</VView>

                <Splitter
                    direction="start"
                    initial={inspectorWidth}
                    onDragSplitter={newValue => onSplitInspector(newValue)}
                    onDragSplitterEnd={newValue => onSplitInspectorEnd(newValue)}
                />

                <VView className="inspector" ref={inspectorRef}>
                    {inspector}
                </VView>
            </HView>
            {statusbar ? (
                <>
                    <Divider />
                    <HView className="statusbar">{statusbar}</HView>
                </>
            ) : undefined}
        </VView>
    )
})

//
// Spacer
//
// The Spacer grows to fill the available space unless you set its `size`. So if you
// want an element on the left and one on the right, put a Spacer betweeen them.
// Multiple Spacers in the same view container will grow at the same rate to
// collectively fill the available space. You can modify this with the `grow`
// property, which defaults to 1. This has no effect if `size` is specified.

import React from 'react'

export const Spacer = React.forwardRef(function Spacer(props, ref) {
    const { className, size, grow, style, ...other } = props
    let classes = 'av-Spacer'
    if (className) classes += ' ' + className
    let styles = {}
    if (size) styles = { flex: '0 0 ' + size + 'px' }
    else if (grow) styles = { flexGrow: grow }
    if (style) styles = Object.assign({}, styles, style)
    return <div ref={ref} className={classes} style={styles} {...other} />
})

export const FixedVSpace = React.forwardRef(function FixedVSpace(props, ref) {
    const { className, size, style, ...other } = props
    let classes = 'av-FixedVSpace'
    if (className) classes += ' ' + className
    let styles = {}
    if (size) styles = { height: size + 'px' }
    if (style) styles = Object.assign({}, styles, style)
    return <div ref={ref} className={classes} style={styles} {...other} />
})

export const FixedHSpace = React.forwardRef(function FixedHSpace(props, ref) {
    const { className, size, style, ...other } = props
    let classes = 'av-FixedHSpace'
    if (className) classes += ' ' + className
    let styles = {}
    if (size) styles = { width: size + 'px' }
    if (style) styles = Object.assign({}, styles, style)
    return <div ref={ref} className={classes} style={styles} {...other} />
})

//
// FieldpickerField
//
// A fieldpicker-input.

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'
import { useMousePopover } from '../../../../hooks/useMousePopover'

import { Icon, Item } from '../../../../components'
import { IconTile } from '../../../../panels'
import { FieldpickerFieldMenu } from '../../../../menus'

import { VALIDATION } from '../../../../utils/validation'
import { validateField } from '../../../../stores/data/validators'
import { ValidationMessage } from '../../../../components'

export const FieldpickerField = observer(function FieldpickerField(props) {
    const {
        className,
        record,
        field,
        enabled,
        force_readonly,
        fromClass,
        component,
        renderkey,
        worksheet,
        ...other
    } = props

    const { data, view } = useStore()

    const FieldpickerFieldContextMenu = useMousePopover(FieldpickerFieldMenu)

    let classes = 'cc-Field cc-FieldpickerField'
    if (!enabled) classes += ' cc-disabled'
    if (className) classes += ' ' + className

    const fieldvalue =
        record.localized_fields && record.localized_fields.get(field.name)
    const linkedfieldgids = fieldvalue ? fieldvalue.split(/\./) : []
    const linkedfield =
        fieldvalue && data.fields.has(linkedfieldgids[0])
            ? data.fields.get(linkedfieldgids[0])
            : undefined
    const fieldsoption = field ? field.options.get('fields') : undefined
    let fromFields = []
    if (fieldsoption === 'record') {
        fromFields = worksheet.getAllFields()
    } else if (fieldsoption === 'child') {
        // first try from actual children (they can be extended), then
        // from fields from childdefinitions
        fromFields = worksheet.getAllFirstChildFields()
        if (!fromFields.length) fromFields = worksheet.getAllChildFields()
    } else {
        fromFields = Array.from(data.fields.values())
    }
    if (fromFields.length) {
        fromFields = fromFields.reduce((map, field) => {
            map.set(field.gid, field)
            return map
        }, new Map())
    }

    const validation = validateField(fieldvalue, field, record.language)
    if (validation.result === VALIDATION.ERROR) {
        classes += ' validation-error'
    } else if (validation.result === VALIDATION.REPORT) {
        classes += ' validation-report'
    }

    const onAddField = (linkedfield, otherfieldgid) => {
        record.setField(field, otherfieldgid)
        record.commitIfModified()
    }

    const onReplaceField = (linkedfield, otherfieldgid) => {
        record.setField(field, otherfieldgid)
        record.commitIfModified()
    }

    const onRemoveField = linkedfield => {
        record.setField(field, '')
        record.commitIfModified()
    }

    const onRevealInSchema = linkedfield => {
        view.schemaworksheet.setItem(linkedfield)
        view.schematree.datamanager.setItem(linkedfield)
        view.setWorkspace('schema')
    }

    const onFieldpickerFieldAction = (action, linkedfield, otherfieldgid) => {
        const actions = {
            add_field: onAddField,
            replace_field: onReplaceField,
            remove_field: onRemoveField,
            reveal_in_schema: onRevealInSchema,
        }
        if (!(action in actions)) {
            console.log(`onFieldpickerFieldAction: unhandled action '${action}'`)
            return
        }
        FieldpickerFieldContextMenu.hide()
        actions[action](linkedfield, otherfieldgid)
    }

    const onShowFieldpickerFieldMenu = e => {
        FieldpickerFieldContextMenu.onShow(e)
    }

    const icontileanchor = 'left'
    const iconplaceholder = enabled ? 'plus' : 'field'

    const subfieldtitles = linkedfieldgids.map((linkedfieldgid, index) => {
        const subfield = data.fields.get(linkedfieldgid)
        const title = subfield
            ? view.schemaworksheet.layoutstore.getItemTitle(subfield, record.language)
            : undefined
        return (
            <Item key={index} indent={2 * index}>
                <Icon name="field" size="text" /> {title}
            </Item>
        )
    })

    return (
        <div className={classes} {...other}>
            <FieldpickerFieldContextMenu.Panel
                fromFields={fromFields}
                linkedfield={linkedfield}
                onAction={onFieldpickerFieldAction}
            />
            {!linkedfield ? (
                <IconTile
                    width={20}
                    className="cc-dimmed"
                    icon={iconplaceholder}
                    anchor={enabled ? icontileanchor : undefined}
                    onClick={enabled ? onShowFieldpickerFieldMenu : undefined}
                />
            ) : (
                <Item
                    onClick={enabled ? onShowFieldpickerFieldMenu : undefined}
                    onContextMenu={enabled ? onShowFieldpickerFieldMenu : undefined}
                >
                    {subfieldtitles}
                </Item>
            )}
            <ValidationMessage validation={validation} />
        </div>
    )
})

//
// RecordThumb
//
// Show icon or image preview for a record

import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'
import { Transform } from '../stores/imageservers/utils'

import { AssetThumb, IconTile } from '.'

export const RecordThumb = observer(function RecordThumb({
    record,
    transform,
    className,
    iconplaceholder,
    ...other
}) {
    const { data, imageserver } = useStore() // should I inject this instead?

    let classes = 'cc-RecordThumb'
    if (className) classes += ' ' + className

    const assetTransform = transform || new Transform('fit', 200, 200)

    const [asset, setAsset] = useState(null)
    useEffect(() => {
        if (!record) return
        let isMounted = true
        async function asyncEffect() {
            const field = record.image_field
            if (field) {
                const fieldvalue =
                    record.localized_fields && record.localized_fields.get(field.name)
                if (fieldvalue) {
                    if (!data.assets.has(fieldvalue)) {
                        await data.fetchAssetByGid(fieldvalue)
                    }
                    const fetchedAsset = data.assets.get(fieldvalue)
                    if (
                        isMounted &&
                        fetchedAsset &&
                        (!asset || fetchedAsset.gid !== asset.gid)
                    ) {
                        setAsset(fetchedAsset)
                    }
                }
            }
        }
        asyncEffect()
        return () => (isMounted = false)
    }, [record, data, asset])

    if (!record) {
        return (
            <IconTile
                className={classes + ' cc-dimmed'}
                width={assetTransform.width}
                icon={iconplaceholder ? iconplaceholder : 'plus'}
                {...other}
            />
        )
    }

    return asset && imageserver.canTransform(asset) ? (
        <AssetThumb
            asset={asset}
            iconplaceholder={iconplaceholder}
            transform={assetTransform}
            {...other}
        />
    ) : (
        <IconTile
            className={classes}
            width={assetTransform.width}
            icon="product"
            {...other}
        />
    )
})

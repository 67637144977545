//
// DragDropFilterItem
//
// Drag and Drop wrapper for Filter Item

import React, { useState, useRef, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useDrag, useDrop } from 'react-dnd'

import { DragTypes } from './DragTypes'
import { useDebounce } from '../hooks/useDebounce'

export const DragFilterItem = observer(function DragFilterItem({
    filteritem,
    filterstore,
    children,
}) {
    const ref = useRef(null)

    const [isMousedown, setIsMousedown] = useState(false)

    const [{ isDragging }, drag] = useDrag({
        type: DragTypes.SEARCH_FILTER,
        item: {
            type: DragTypes.SEARCH_FILTER,
            id: filteritem.key,
        },
        canDrag: monitor => {
            if (filteritem.key === filterstore._root) {
                setIsMousedown(false)
            }
            return filteritem.key !== filterstore._root
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
        end: (item, monitor) => {
            if (ref.current) {
                setIsMousedown(false)
            }
        },
    })

    const onMouseDown = e => {
        if (e.button === 0) {
            setIsMousedown(true)
        }
        e.stopPropagation()
    }
    const onMouseUp = e => {
        setIsMousedown(false)
        e.stopPropagation()
    }

    drag(ref)

    let dragclassname = 'cc-DragFilterItem'
    if (isMousedown) dragclassname += ' maybe-start-dragging'

    useEffect(() => {
        // set style later - cloned drag preview has original style
        if (ref && ref.current) {
            ref.current.style.opacity = isDragging ? 0.1 : 1
        }
    }, [isDragging])

    return (
        <div
            ref={ref}
            className={dragclassname}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
        >
            {children}
        </div>
    )
})

export const DropFilterItem = observer(function DropFilterItem({
    filteritem,
    filterstore,
    before_key,
    onDrop,
    children,
}) {
    const ref = useRef(null)

    const [isoverzone, setIsoverzone] = useState('inside')

    const debouncedIsoverzone = useDebounce(isoverzone, 50)

    const canDrop = dragitem => {
        if (
            !ref.current ||
            !dragitem ||
            !filteritem ||
            filterstore.pathCheck(filteritem.key, dragitem.id)
        ) {
            return false
        }
        return true
    }

    const [{ isOver }, drop] = useDrop({
        accept: DragTypes.SEARCH_FILTER,
        canDrop: dragitem => canDrop(dragitem),

        drop(dragitem, monitor) {
            const didDrop = monitor.didDrop()
            if (didDrop) {
                return // stop nested
            }
            onDrop(dragitem, filteritem, before_key)
            return {
                receiver: 'DragDropFilterItem',
                dragitem: dragitem,
                filteritem: filteritem,
                before_key: before_key,
            }
        },

        collect: monitor => {
            let isOver = !!monitor.isOver({ shallow: true })
            const dragitem = monitor.getItem()
            if (!canDrop(dragitem)) {
                isOver = false
            }
            return {
                isOver: isOver,
            }
        },

        hover(dragitem, monitor) {
            if (!canDrop(dragitem)) {
                return
            }
            setIsoverzone('inside')
        },
    })

    drop(ref)

    let dragclassname = 'cc-DropFilterItem'
    if (isOver && debouncedIsoverzone === 'top') dragclassname += ' drag-over-top'
    else if (isOver && debouncedIsoverzone === 'inside')
        dragclassname += ' drag-over-inside'
    else if (isOver && debouncedIsoverzone === 'bottom')
        dragclassname += ' drag-over-bottom'

    return (
        <div ref={ref} className={dragclassname}>
            {children}
        </div>
    )
})

//
// DecimalFieldOptions
//
// Field options for type 'decimal'

import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'

import { TextInput } from '../../../../components'
import { LabeledField } from '../../../components'

import { autoCompleter } from '../../../../stores/data/autocompleters'

import { VALIDATION } from '../../../../utils/validation'
import { duckField, validateField } from '../../../../stores/data/validators'
import { InitialFieldOption } from './InitialFieldOption'

export const DecimalFieldOptions = observer(function DecimalFieldOptions({
    field,
    selectModified,
    worksheet,
    onAction,
    setSelected,
    renderkeyprefix,
}) {
    const { app } = useStore()

    const fieldAutoCompleter = autoCompleter(field, worksheet.language)
    const autoComplete = useMemo(() => {
        return fieldAutoCompleter
            ? async q => {
                  return fieldAutoCompleter(q)
              }
            : null
    }, [fieldAutoCompleter])

    if (!field) return null
    const decimals = field.options.get('decimals')
    const min = field.options.get('min')
    const max = field.options.get('max')

    const onChangeFieldOption = option => {
        return event => {
            field.setOption(option, event.target.value)
        }
    }
    const onBlurFieldOption = (option, validator) => {
        return event => {
            if (validator(event.target.value, field).result === VALIDATION.ERROR) {
                // TODO: CC-204 resetOption
            } else {
                field.setOption(option, event.target.value.trim())
                field.commitIfModified().then(result => selectModified(result))
            }
        }
    }

    const duckDecimalsField = duckField('number', { min: 0, max: 10 })
    const decimalsValidator = fieldvalue => {
        return validateField(fieldvalue, duckDecimalsField, worksheet.language)
    }
    const onChangeFieldDecimals = onChangeFieldOption('decimals')
    const onBlurFieldDecimals = onBlurFieldOption('decimals', decimalsValidator)

    const duckMinField = duckField('decimal', { max: max, decimals: decimals }) // min < max
    const minValidator = fieldvalue => {
        return validateField(fieldvalue, duckMinField, worksheet.language)
    }
    const onChangeFieldMin = onChangeFieldOption('min')
    const onBlurFieldMin = onBlurFieldOption('min', minValidator)

    const duckMaxField = duckField('decimal', { min: min, decimals: decimals }) // max > min
    const maxValidator = fieldvalue => {
        return validateField(fieldvalue, duckMaxField, worksheet.language)
    }
    const onChangeFieldMax = onChangeFieldOption('max')
    const onBlurFieldMax = onBlurFieldOption('max', maxValidator)

    const duckInitialField = duckField('decimal', {
        min: min,
        max: max,
        decimals: decimals,
    }) // min <= initial <= max

    return (
        <>
            <LabeledField label={app.text('decimals')}>
                <TextInput
                    onChange={onChangeFieldDecimals}
                    onBlur={onBlurFieldDecimals}
                    value={decimals}
                    renderkey={renderkeyprefix + '.decimals'}
                    validate={decimalsValidator}
                />
            </LabeledField>
            <LabeledField label={app.text('min value')}>
                <TextInput
                    onChange={onChangeFieldMin}
                    onBlur={onBlurFieldMin}
                    value={min}
                    renderkey={renderkeyprefix + '.min'}
                    validate={minValidator}
                    autoComplete={autoComplete}
                />
            </LabeledField>
            <LabeledField label={app.text('max value')}>
                <TextInput
                    onChange={onChangeFieldMax}
                    onBlur={onBlurFieldMax}
                    value={max}
                    renderkey={renderkeyprefix + '.max'}
                    validate={maxValidator}
                    autoComplete={autoComplete}
                />
            </LabeledField>
            <InitialFieldOption
                field={field}
                initialField={duckInitialField}
                selectModified={selectModified}
                worksheet={worksheet}
                onAction={onAction}
                setSelected={setSelected}
                renderkeyprefix={renderkeyprefix}
            />
        </>
    )
})

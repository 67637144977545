//
// DropTreeRecordMenu
//
// Actions on Drop in the TreeNavigator

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Menu, MenuDivider, MenuItem } from '../components'

export const DropTreeRecordMenu = observer(function DropTreeRecordMenu({ onAction }) {
    const { app } = useStore()

    const _onAction = action => {
        onAction && onAction(action)
    }

    return (
        <Menu>
            <MenuItem onClick={() => _onAction('move')}>{app.text('Move')}</MenuItem>
            <MenuItem onClick={() => _onAction('copy')}>{app.text('Copy')}</MenuItem>
            <MenuItem onClick={() => _onAction('shallow_copy')}>
                {app.text('Copy without subtree')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('link')}>
                {app.text('Create link')}
            </MenuItem>
            <MenuDivider />
            <MenuItem onClick={() => _onAction('cancel')}>
                {app.text('Cancel')}
            </MenuItem>
        </Menu>
    )
})

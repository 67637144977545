//
// ProjectBackupsWorksheet
//
// Project backups worksheet

import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../stores'
import { keyboard } from '../../utils/keyboard'
import { DateTime } from 'luxon'

import { BackupsMenu } from '../../menus'
import { useMousePopover } from '../../hooks/useMousePopover'
import { VView, HView, Spacer } from '../../appview'
import { Header, Text, Icon, TextInput } from '../../components'

const BackupRow = observer(function BackupRow({
    project,
    backup,
    is_selected,
    onSelectionClick,
    onContextMenu,
}) {
    const { data } = useStore()

    let className = 'ws-table-row'
    if (is_selected) {
        className += ' cc-selected'
    }

    const version = backup.get('version')
    const [name, setName] = useState(backup.get('name'))
    const created_by = backup.get('created_by')
    const timestamp = backup.get('timestamp')

    const onChangeBackupName = event => {
        setName(event.target.value)
    }
    const onBlurBackupName = event => {
        setName(event.target.value.trim())
        data.actions.backups.updateBackup(version, event.target.value.trim())
    }

    return (
        <tr
            className={className}
            onClick={onSelectionClick}
            onContextMenu={onContextMenu}
        >
            <td className="ws-table-cell cc-backup-version">{version}</td>
            <td className="ws-table-cell">
                <TextInput
                    onChange={onChangeBackupName}
                    onBlur={onBlurBackupName}
                    value={name}
                    renderkey={'.name'}
                />
            </td>
            <td className="ws-table-cell">{created_by}</td>
            <td className="ws-table-cell">
                {DateTime.fromJSDate(new Date(timestamp * 1000)).toLocaleString(
                    DateTime.DATETIME_MED
                )}
            </td>
        </tr>
    )
})

export const ProjectBackupsWorksheet = observer(function ProjectBackupsWorksheet({
    project,
}) {
    const { app, data } = useStore()

    // ContextMenus
    const BackupsContextMenu = useMousePopover(BackupsMenu)

    let initialbackupselection = {}
    project.backup_versions.forEach((value, _) => {
        if (value.get('version') !== 0) {
            initialbackupselection[value.get('version').toString()] = false
        }
    })
    const [backupselection, setBackupselection] = useState(initialbackupselection)
    const [lastselectedbackup, setLastselectedbackup] = useState(null)
    const backupversions = Object.keys(backupselection).filter(
        key => backupselection[key]
    )

    useEffect(() => {
        // check if there's any new items that aren't in the selection yet
        // this means a backup is created or deleted
        let validselectedbackups = {}
        project.backup_versions.forEach((value, _) => {
            if (value.get('version') !== 0) {
                const key = value.get('version').toString()
                validselectedbackups[key] = !!backupselection[key]
            }
        })
        if (
            Object.keys(validselectedbackups).length !==
            Object.keys(backupselection).length
        ) {
            setBackupselection(validselectedbackups)
        }
    }, [project.backup_versions, backupselection])

    const BackupRows = project.backup_versions
        .filter(backup => backup.get('version') !== 0)
        .map(backup => {
            const key = backup.get('version').toString()

            const is_selected = backupselection[key]

            const onSelectionClick = e => {
                // supports multiple selections
                // single click sets selection to single item (single click when it is the only item: deselect)
                // command-click (or ctrl-click) toggles selection for that item item
                // shift-click selects range from last item to new item
                // command-click toggles item in selection
                if (keyboard.testCommand(e)) {
                    let newselectedbackups = { ...backupselection }
                    newselectedbackups[key] = !backupselection[key]
                    setBackupselection(newselectedbackups)
                } else if (keyboard.testShift(e) && lastselectedbackup !== null) {
                    let newselectedbackups = { ...backupselection }
                    let startenabling = false
                    project.backup_versions.forEach(testbackup => {
                        const testkey = testbackup.get('version').toString()
                        if (
                            !startenabling &&
                            (testkey === key || testkey === lastselectedbackup)
                        ) {
                            startenabling = true
                        } else if (
                            startenabling &&
                            (testkey === key || testkey === lastselectedbackup)
                        ) {
                            newselectedbackups[testkey] = true
                            startenabling = false
                        }
                        if (startenabling) newselectedbackups[testkey] = true
                    })
                    setBackupselection(newselectedbackups)
                } else {
                    if (backupversions.length === 1 && backupversions.includes(key)) {
                        setBackupselection({})
                    } else {
                        let newselectedbackups = { ...initialbackupselection }
                        newselectedbackups[key] = true
                        setBackupselection(newselectedbackups)
                    }
                }
                setLastselectedbackup(key)
            }

            return (
                <BackupRow
                    key={key}
                    project={project}
                    backup={backup}
                    is_selected={is_selected}
                    onSelectionClick={onSelectionClick}
                    onContextMenu={e => {
                        if (!backupversions.includes(key)) {
                            let newselectedbackups = { ...initialbackupselection }
                            newselectedbackups[key] = true
                            setBackupselection(newselectedbackups)
                        }
                        BackupsContextMenu.onShow(e)
                    }}
                />
            )
        })

    const NoBackups =
        project.backup_versions.filter(backup => backup.get('version') !== 0).length ===
        0 ? (
            <>
                <Spacer size={20} />
                <HView>
                    <Icon name="backup" size={2} />
                    <Text>
                        {app.text(
                            'No backups yet. Click the backup icon in the header to create one.'
                        )}
                    </Text>
                </HView>
            </>
        ) : undefined

    const onRestoreBackup = () => {
        if (project && backupversions.length === 1) {
            data.actions.backups.restoreBackup(backupversions[0])
        }
    }

    const onDeleteBackups = () => {
        if (project && backupversions.length) {
            data.actions.backups.deleteBackups(backupversions)
        }
    }

    const onBackupAction = action => {
        const actions = {
            restore_backup: onRestoreBackup,
            delete_backups: onDeleteBackups,
        }
        if (!(action in actions)) {
            console.log(`onBackupAction: unhandled action '${action}'`)
            return
        }
        BackupsContextMenu.hide()
        actions[action]()
    }

    return (
        <VView className="worksheet worksheet-settings worksheet-backups">
            <VView grow>
                <BackupsContextMenu.Panel
                    selectedbackupversions={backupversions}
                    onAction={onBackupAction}
                />
                <Header>{app.text('Backups')}</Header>
                <div className="cc-Backups ws-table">
                    <table className="ws-table">
                        <thead>
                            <tr className="ws-table-header">
                                <td className="ws-table-cell cc-backup-version">
                                    {app.text('Version')}
                                </td>
                                <td className="ws-table-cell">{app.text('Name')}</td>
                                <td className="ws-table-cell">
                                    {app.text('Created by')}
                                </td>
                                <td className="ws-table-cell">
                                    {app.text('Server timestamp')}
                                </td>
                            </tr>
                        </thead>
                        <tbody>{BackupRows}</tbody>
                    </table>
                </div>
                {NoBackups}
            </VView>
            <Spacer size={20} />
            <VView grow></VView>
        </VView>
    )
})

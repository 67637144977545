//
// ClassField
//
// A class input - according to its own layout

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'

import { VALIDATION } from '../../../../utils/validation'
import { validateField } from '../../../../stores/data/validators'
import { ValidationMessage } from '../../../../components'

import { Text } from '../../../../components'
import { LayoutBuilder } from '..'
import { PropertyTable } from '.'
import { ClassFieldRecord } from '../../../../stores/data/ClassFieldRecord'

export const ClassField = observer(function ClassField(props) {
    const {
        className,
        record,
        field,
        enabled,
        force_readonly,
        fromClass,
        component,
        renderkey,
        worksheet,
        propertytable,
        ...other
    } = props

    const { data, app } = useStore()

    let classes = 'cc-Field cc-ClassField'
    if (!enabled) classes += ' cc-disabled'
    if (className) classes += ' ' + className

    const fieldvalue =
        (record.localized_fields && record.localized_fields.get(field.name)) ||
        new Map()
    const classfieldrecord = new ClassFieldRecord(record, field, fieldvalue)

    const validation = validateField(fieldvalue, field, record.language)
    if (validation.result === VALIDATION.ERROR) {
        classes += ' validation-error'
    } else if (validation.result === VALIDATION.REPORT) {
        classes += ' validation-report'
    }

    const class_gid = field ? field.options.get('class') : undefined
    const class_ = class_gid ? data.classes.get(class_gid) : undefined
    const layout = class_ ? data.layouts.get(class_.layout) : undefined

    const ClassFieldLayout =
        class_ && propertytable ? (
            <PropertyTable
                className="cc-block"
                record={classfieldrecord}
                fields={class_.fields}
                fromClass={class_.gid}
                worksheet={worksheet}
            />
        ) : class_ && layout ? (
            <LayoutBuilder
                component={layout.components.get(layout.root)}
                components={layout.components}
                layout={layout}
                record={classfieldrecord}
                worksheet={worksheet}
                active_class={class_}
                force_readonly={force_readonly || !enabled}
            />
        ) : (
            <Text>{app.text('No class or layout assigned.')}</Text>
        )

    return (
        <div className={classes} {...other}>
            {ClassFieldLayout}
            <ValidationMessage validation={validation} />
        </div>
    )
})

//
// useTooltipPopover
//
// The Tooltip Popover is placed below an element, centered, with an arrow pointing up.
// Useful for modals that are linked to an element.
//
// Add a Popover to your views. Returns an object with a Panel to use in your rendering,
// an anchorRef to attach it to a component (the popover arrow will point to it), and
// show/hide functions.
//
// Default placement is below, centered. If there's no space to show the full popover,
// it is tried below, to the right, then to the left, until it fits. If it still doesn't
// fit, it is placed below anyway.
// If it can't then be placed centered horizontally or vertically (depending on initial
// placement), it will be moved to fit just inside the window.
//
// Usage:
//
//     const UserPopover = useTooltipPopover(UserPanel)
//     return (
//         <VView style={{padding: 20}}>
//             <UserPopover.Panel user={store.user} logoutAction={() => { UserPopover.hide() }}/>
//             <Bar raised>
//                  <div ref={UserPopover.anchorRef}>
//                     <Button icon onClick={() => UserPopover.show()}><Icon name="user" size={2}/></Button>
//                 </div>
//             </Bar>
//         </VView>
//     )

import React, { useRef, useEffect, useLayoutEffect, useCallback } from 'react'
import { observer } from 'mobx-react-lite'

import {
    Popover,
    PopoverArrow,
    usePopoverContext,
    PopoverProvider,
} from '../components'
import { gid } from '../utils/gid'

export const useTooltipPopover = (PopoverPanel, options = {}) => {
    const keyRef = useRef(gid())
    const popoverContext = usePopoverContext()

    const mounted = useRef(false)
    useEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
    }, [])

    const anchorRef = useRef()
    const popoverRef = useRef()
    const arrowRef = useRef()
    const [visibility, setVisibility] = React.useState(false)
    const {
        dismissAction = undefined,
        hideArrow = false,
        preferredPosition = 'below',
    } = options

    const positionPopover = () => {
        if (!anchorRef.current) return
        if (!popoverRef.current) return

        let originBounds = anchorRef.current.getBoundingClientRect()
        let popoverBounds = popoverRef.current.getBoundingClientRect()
        const windowBounds = {
            x: 0,
            y: 0,
            width: document.documentElement.clientWidth,
            height: document.documentElement.clientHeight,
        }

        originBounds.center = {
            x: originBounds.x + originBounds.width / 2,
            y: originBounds.y + originBounds.height / 2,
        }
        popoverBounds.half = {
            width: popoverBounds.width / 2,
            height: popoverBounds.height / 2,
        }

        const gap = 5
        const popoverSpace = {
            left: originBounds.left - 2 * gap,
            right: windowBounds.width - originBounds.right - 2 * gap,
            top: originBounds.top - 2 * gap,
            bottom: windowBounds.height - originBounds.bottom - 2 * gap,
        }

        let top = null
        let left = null
        let arrowRotate = 0
        let arrowTop = null
        let arrowLeft = null

        const tryBelow = () => {
            if (popoverBounds.height <= popoverSpace.bottom) {
                top = originBounds.bottom + gap
                arrowTop = -8
                arrowRotate = 0
                return true
            }
            return false
        }

        const tryAbove = () => {
            if (popoverBounds.height <= popoverSpace.top) {
                top = originBounds.top - popoverBounds.height - gap
                arrowTop = popoverBounds.height - 4
                arrowRotate = 180
                return true
            }
            return false
        }

        const tryRight = () => {
            if (popoverBounds.width <= popoverSpace.right) {
                left = originBounds.right + gap
                arrowLeft = -8
                arrowRotate = -90
                return true
            }
            return false
        }

        const tryLeft = () => {
            if (popoverBounds.width <= popoverSpace.left) {
                left = originBounds.left - popoverBounds.width - gap
                arrowLeft = popoverBounds.width - 4
                arrowRotate = 90
                return true
            }
            return false
        }

        const tryPreferredPosition = () => {
            switch (preferredPosition) {
                case 'below':
                    return tryBelow()
                case 'above':
                    return tryAbove()
                case 'right':
                    return tryRight()
                case 'left':
                    return tryLeft()
                default:
                    return false
            }
        }

        // try to place in the preferred position, otherwise,
        // try to place below, if it doesn't fit, try above, then right, then left
        // failed? place below anyway
        if (
            !(
                tryPreferredPosition() ||
                tryBelow() ||
                tryAbove() ||
                tryRight() ||
                tryLeft()
            )
        ) {
            top = originBounds.bottom + gap
            arrowTop = -8
            arrowRotate = 0
        }

        // try centered
        if (top === null) {
            // vertically
            top = originBounds.center.y - popoverBounds.half.height
            if (top < gap) top = gap
            if (top > windowBounds.height - popoverBounds.height - gap)
                top = windowBounds.height - popoverBounds.height - gap
            arrowTop = originBounds.center.y - top - 5
        } else {
            // horizontally
            left = originBounds.center.x - popoverBounds.half.width
            if (left < gap) left = gap
            if (left > windowBounds.width - popoverBounds.width - gap)
                left = windowBounds.width - popoverBounds.width - gap
            arrowLeft = originBounds.center.x - left - 5
        }

        // apply
        popoverRef.current.style.top = top + 'px'
        popoverRef.current.style.left = left + 'px'

        // base arrow sits at 0,0 and points up, we can override top/left, and rotate
        if (arrowRef.current) {
            arrowRef.current.style.top = arrowTop + 'px'
            arrowRef.current.style.left = arrowLeft + 'px'
            arrowRef.current.style.transform = 'rotate(' + arrowRotate + 'deg)'
            arrowRef.current.style.display = hideArrow ? 'none' : 'block'
        }

        if (
            top < 0 ||
            top + popoverBounds.height > windowBounds.height ||
            left < 0 ||
            left + popoverBounds.width > windowBounds.width
        ) {
            popoverRef.current.style.display = 'none'
            if (arrowRef.current) {
                arrowRef.current.style.display = 'none'
            }
        } else {
            popoverRef.current.style.display = 'block'
            if (arrowRef.current) {
                arrowRef.current.style.display = hideArrow ? 'none' : 'block'
            }
        }
    }

    useLayoutEffect(() => {
        positionPopover()
    })

    const panelRef = useRef()
    const resizeobserver = useRef(null)
    useLayoutEffect(() => {
        if (panelRef.current) {
            resizeobserver.current = new ResizeObserver(entries => {
                // window.requestAnimationFrame tries to prevent
                // 'ResizeObserver loop completed with undelivered notifications.'
                window.requestAnimationFrame(() => {
                    positionPopover() // make sure we don't re-render!
                })
            })
            resizeobserver.current.observe(panelRef.current)
            const element = panelRef.current
            return () => {
                resizeobserver.current.unobserve(element)
            }
        }
    })

    useLayoutEffect(() => {
        if (panelRef.current) {
            function handleScroll(e) {
                window.requestAnimationFrame(() => {
                    positionPopover() // make sure we don't re-render!
                })
            }

            window.addEventListener('scroll', handleScroll, { passive: false })
            window.addEventListener('wheel', handleScroll, { passive: false })

            return () => {
                window.removeEventListener('scroll', handleScroll, { passive: false })
                window.removeEventListener('wheel', handleScroll, { passive: false })
            }
        }
    })

    const setVisibilityIfMounted = vis => {
        if (mounted.current) setVisibility(vis)
    }

    const show = () => {
        popoverContext.store.show(
            [...popoverContext.stack],
            keyRef.current,
            setVisibilityIfMounted,
            dismissAction
        )
    }
    const hide = useCallback(() => {
        setVisibility(false)
    }, [])

    const isOpen = () => visibility

    const { onClickOutside = hide } = options

    useEffect(() => {
        const handleClickOutside = event => {
            if (
                event.target.className.split &&
                event.target.className.split(' ').includes('cc-FileUpload')
            ) {
                return
            }
            if (popoverRef.current && !popoverRef.current.contains(event.target)) {
                onClickOutside(event)
            }
        }

        document.addEventListener('click', handleClickOutside)
        document.addEventListener('contextmenu', handleClickOutside)
        document.addEventListener('dragstart', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
            document.removeEventListener('contextmenu', handleClickOutside)
            document.removeEventListener('dragstart', handleClickOutside)
        }
    }, [popoverRef, onClickOutside])

    const Panel = observer(function Panel(props) {
        if (!visibility) return null
        const { hidePopover, ...other } = props
        if (hidePopover) return null
        const Panel = (
            <div ref={panelRef}>
                <PopoverPanel {...other} />
            </div>
        )
        if (!Panel) return null

        return (
            <PopoverProvider
                value={{
                    store: popoverContext.store,
                    stack: [...popoverContext.stack, keyRef.current],
                }}
            >
                <Popover className="cc-Tooltip-Popover" ref={popoverRef}>
                    <PopoverArrow ref={arrowRef} />
                    {Panel}
                </Popover>
            </PopoverProvider>
        )
    })

    return {
        Panel,
        anchorRef,
        show,
        onShow: e => {
            show()
            e.preventDefault()
            e.stopPropagation()
        },
        hide,
        isOpen,
    }
}

//
// AppStore
//
// The AppStore is where we keep lists of globals, like available languages, regions,
// timezones, as well as all the user interface copy, like button texts, tooltips,
// common labels, and such, that can be localized. The list of available localizations
// for the user interface is also found here, currently we only support english.
//
// You'll notice that we don't keep anything here yet. The philosofy is that we start
// with base english ui text, but always use the app.text() method on it so we can
// add localization later. The english ui text is used as the key to retrieve the actual
// text. If a key is not found, the key is returned as-is. This way we don't have to
// start with a complete english key-value text list from the start, allowing for
// maximum flexibility as well as automatic fallback texts for when we start using
// translations. Downside is that if you change the english texts later, the keys for
// the localizations must be updated as well. We'll deal with that when the time comes.
//
// The text.get method allows for text placeholders that will be replaced by their
// values from a replacement object. A placeholder is a name surrounded by braces, so
// you can use it like this:
//
//     const { app } = useStore()
//     app.text('Last saved {counter} {seconds} ago',
//         {'counter': counter,
//         ('seconds': counter == 1? app.text('second'): app.text('seconds'))
//     })
//
// The localized version will use the same placeholder names, e.g. in Italian
//     Ultimo salvataggio {counter} {seconds} fa
//
// We will get into a situation where we need different translations for the same
// english word, and since the word is the key, we have to find a workaround. There's
// no interface for this yet, but the usual solution is to provide a context, a bit of
// text to explain where and how the word/translation is used. This context is also
// useful for translators. We can simply store this context with the key, provided we
// don't alter the context later since then we would have to update all the translated
// keys as well. A scheme could be "Load # Retrieve from computer storage", where we
// can't use a # in the context, but we can in the key, as only the text after the last
// # is the context: "A hashtag looks like #unicat #" ends in a # to indicate there
// is no context provided.
// TODO: CC-208 implement this context concept.

import { makeObservable, observable, action } from 'mobx'

class AppStore {
    _texts = new Map()
    applanguages = new Map([
        ['en', 'English'], // note: never localized
    ])
    // timezones = ["Europe/Amsterdam"]
    imageservers = new Map([
        ['ccdam', 'Unicat'],
        // ["stentor", "Stentor"],
    ])

    constructor() {
        makeObservable(this, {
            _texts: observable,
            applanguages: observable,
            // timezones: observable,
            // text: computed,
            setTexts: action.bound,
        })
    }

    text = (key, replacements = {}) => {
        var text = key
        if (this._texts.has(key)) {
            text = this._texts.get(key)
        }
        if (replacements) {
            for (const [name, value] of Object.entries(replacements)) {
                text = text.replaceAll('{' + name + '}', value)
            }
        }
        return text
    }

    pluralized_text = (keys_01many, count, replacements = {}) => {
        // Usage:
        //
        // const items_count = Items.length
        // app.pluralized_text(
        //     {
        //         0: 'No items',
        //         1: '{count} item',
        //         many: '{count} items',
        //     },
        //     items_count
        // )
        const key =
            count === 0
                ? keys_01many[0]
                : count === 1
                ? keys_01many[1]
                : keys_01many['many']
        replacements['count'] = count
        return this.text(key, replacements)
    }

    setTexts = texts => {
        this._texts.replace(texts)
    }
}

export default AppStore

//
// SchemaActions
//
// Actions on schema items

import { makeObservable, action } from 'mobx'

export class SchemaActions {
    constructor(rootstore) {
        makeObservable(this, {
            // schema items selection
            bulkCommit: action.bound,
            bulkRevert: action.bound,
            bulkSaveAsNew: action.bound,
            bulkCopy: action.bound,
            bulkDelete: action.bound,
        })

        this._rootstore = rootstore
    }

    bulkCommit = selection => {
        return this._rootstore._fetch('/schema/bulk/commit', {
            selection: selection,
        })
    }

    bulkRevert = selection => {
        return this._rootstore._fetch('/schema/bulk/revert', {
            selection: selection,
        })
    }

    bulkSaveAsNew = selection => {
        return this._rootstore._fetch('/schema/bulk/save_as_new', {
            selection: selection,
        })
    }

    bulkCopy = selection => {
        return this._rootstore._fetch('/schema/bulk/copy', {
            selection: selection,
        })
    }

    bulkDelete = selection => {
        return this._rootstore._fetch('/schema/bulk/delete', {
            selection: selection,
        })
    }
}

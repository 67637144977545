//
// MemberProjectMenu
//
// Actions on projects in the project manager that you do not own.
// Only feed it not-deleted projects.

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Menu, MenuDivider, MenuItem } from '../components'

export const MemberProjectMenu = observer(function MemberProjectMenu({
    projectgid,
    onAction,
}) {
    const { app } = useStore()

    const _onAction = action => {
        onAction && onAction(projectgid, action)
    }

    return (
        <Menu>
            <MenuItem onClick={() => _onAction('open_project')}>
                {app.text('Go to project')}
            </MenuItem>
            <MenuDivider />
            <MenuItem onClick={() => _onAction('leave_project')}>
                {app.text('Cancel membership')}
            </MenuItem>
        </Menu>
    )
})

//
// AutoextendLayoutBuilder
//
// For extended definitions: list all extra classes and extra fields

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'

import { HView, VView } from '../../../../appview'
import { Text } from '../../../../components'
import { ClassLayoutBuilder, FieldLayoutBuilder } from '.'
import { LAYOUTCOMPONENTTYPECONFIG } from '../../../../stores/data/LAYOUTCOMPONENTTYPECONFIG'

export const AutoextendLayoutBuilder = observer(function AutoextendLayoutBuilder({
    className,
    component,
    components,
    layout,
    record,
    worksheet,
    active_class,
    visited_gids,
}) {
    const { app, data } = useStore()

    const definition = worksheet.item
    if (!definition) return null
    const base_definition = definition.original
        ? data.definitions.get(definition.original)
        : null
    if (!base_definition) return null
    const extra_classes = definition.classes.filter(
        class_gid => !base_definition.classes.includes(class_gid)
    )
    const extra_fields = definition.fields.filter(
        field_gid => !base_definition.fields.includes(field_gid)
    )
    const language = worksheet.environment.get('language')
    const config = LAYOUTCOMPONENTTYPECONFIG['autogenerate']
    const editable =
        'editable' in component ? component.editable : config.editable.default

    let Items = []
    extra_classes.forEach((class_gid, index) => {
        const class_ = data.classes.get(class_gid)
        if (class_) {
            const label = class_.label.get(language) || class_.name
            Items.push(
                <Text
                    key={class_.gid + '.header.' + index}
                    className="ws-section-header"
                >
                    {label} <span className="ws-tag">{app.text('class')}</span>
                </Text>
            )

            const classlayoutbuildercomponent = {
                gid: '-not-selectable-' + index,
                class: class_.gid,
                placeholder: '',
                editable: editable,
                width: 250, // image
                columns: 3, // imagelist
            }

            Items.push(
                <ClassLayoutBuilder
                    key={class_.gid + '.' + index}
                    component={classlayoutbuildercomponent}
                    components={{}}
                    layout={layout}
                    record={record}
                    worksheet={worksheet}
                    active_class={class_}
                    visited_gids={visited_gids}
                />
            )
        }
    })

    if (definition.fields.length) {
        Items.push(
            <Text key={definition.gid + '.fields'} className="ws-section-header">
                {app.text('Fields')}
            </Text>
        )
    }
    let LeftItems = []
    let RightItems = []
    extra_fields.forEach((field_gid, index) => {
        const field = data.fields.get(field_gid)
        if (field) {
            const fieldtypeconfig =
                LAYOUTCOMPONENTTYPECONFIG['field']['typeconfig'][field.type]
            const fieldtypestyle = fieldtypeconfig['style']
                ? fieldtypeconfig['style']['default']
                : undefined
            const fieldtypelayout = fieldtypeconfig['layout']

            const fieldlayoutbuildercomponent = {
                gid: '-not-selectable-' + index,
                field: field.gid,
                placeholder: '',
                editable: editable,
                style: fieldtypestyle,
                width: 250, // image
                columns: 3, // imagelist
            }

            const fieldlayout = (
                <FieldLayoutBuilder
                    key={definition.gid + '.' + field.gid + '.' + index}
                    component={fieldlayoutbuildercomponent}
                    components={{}}
                    layout={layout}
                    record={record}
                    worksheet={worksheet}
                    active_class={active_class}
                    visited_gids={visited_gids}
                />
            )
            if (fieldtypelayout === 'right') {
                RightItems.push(fieldlayout)
            } else {
                LeftItems.push(fieldlayout)
            }
        }
    })
    if (LeftItems.length || RightItems.length) {
        Items.push(
            <HView key={'extra_fields'} gap={4}>
                <VView gap={4} grow>
                    {LeftItems}
                </VView>
                <VView gap={4} style={{ flexBasis: 250, maxWidth: 250, minWidth: 250 }}>
                    {RightItems}
                </VView>
            </HView>
        )
    }

    let attributes = { className: 'layout-component' }
    if (className) attributes['className'] += ' ' + className
    if (component.gid === worksheet.layoutstore.selectedComponentGid) {
        attributes['className'] += ' cc-selected'
    }
    // attributes["onClick"] = e => onClickElement(e, component, layouttreestore)
    return <VView {...attributes}>{Items}</VView>
})

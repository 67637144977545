//
// AssetFieldMenu
//
// Actions on an asset-input (file or image (-list)).

import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'
import { Transform } from '../stores/imageservers/utils'

import { View, VView } from '../appview'
import { Menu, MenuDivider, MenuItem } from '../components'
import { AssetCard } from '../panels'
import { SearchUploadAssetMenu } from '../menus'

const ConfirmSubmenu = observer(function ConfirmSubmenu({ onAction }) {
    const { app } = useStore()

    const _onAction = (e, action) => {
        onAction && onAction(action)
    }

    return (
        <Menu>
            <MenuItem className="cc-danger" onClick={e => _onAction(e, 'remove_all')}>
                {app.text('Yes, clear this list')}
            </MenuItem>
        </Menu>
    )
})

export const AssetFieldMenu = observer(
    React.forwardRef(function AssetFieldMenu(
        { asset, enabled, list, onAction, ...other },
        ref
    ) {
        const { app } = useStore()

        const _onAction = (action, p1) => {
            onAction && onAction(action, asset, p1)
        }

        const onSearchUploadAssetAddAction = (action, p1) => {
            if (action === 'select_asset') {
                action = 'add_asset'
            }
            _onAction(action, p1)
        }

        const onSearchUploadAssetReplaceAction = (action, p1) => {
            if (action === 'select_asset') {
                action = 'replace_asset'
            }
            _onAction(action, p1)
        }

        const AssetSearchAddSubmenu = (
            <SearchUploadAssetMenu
                add={true}
                multiple={!!list}
                onAction={onSearchUploadAssetAddAction}
            />
        )

        const AssetSearchReplaceSubmenu = (
            <SearchUploadAssetMenu
                add={false}
                multiple={false}
                onAction={onSearchUploadAssetReplaceAction}
            />
        )

        const MemoizedAssetCard = useMemo(() => {
            const transform = new Transform('fit', 190, 190)
            return <AssetCard asset={asset} transform={transform} />
        }, [asset])

        if (!asset) {
            return (
                <View
                    ref={ref}
                    className="inspector"
                    onClick={e => e.nativeEvent.stopImmediatePropagation()}
                    {...other}
                >
                    <SearchUploadAssetMenu
                        add={true}
                        multiple={!!list}
                        onAction={onSearchUploadAssetAddAction}
                    />
                </View>
            )
        }

        return (
            <VView
                ref={ref}
                className="asset-field-menu"
                onClick={e => e.nativeEvent.stopImmediatePropagation()}
                {...other}
            >
                <div className="inspector">{MemoizedAssetCard}</div>
                <Menu>
                    {enabled && list ? (
                        <MenuItem submenu={AssetSearchAddSubmenu}>
                            {app.text('Add before')}
                        </MenuItem>
                    ) : undefined}
                    {enabled ? (
                        <MenuItem submenu={AssetSearchReplaceSubmenu}>
                            {app.text('Replace')}
                        </MenuItem>
                    ) : undefined}
                    {enabled ? (
                        <MenuItem
                            className="cc-danger"
                            onClick={() => _onAction('remove_asset')}
                        >
                            {app.text(list ? 'Remove from list' : 'Remove')}
                        </MenuItem>
                    ) : undefined}
                    {enabled && list ? (
                        <MenuItem
                            className="cc-danger"
                            submenu={<ConfirmSubmenu onAction={_onAction} />}
                        >
                            {app.text('Remove all')}
                        </MenuItem>
                    ) : undefined}
                    {enabled ? <MenuDivider /> : undefined}
                    <MenuItem onClick={() => _onAction('reveal_in_dam')}>
                        {app.text('Reveal in Assets workspace')}
                    </MenuItem>
                </Menu>
            </VView>
        )
    })
)

//
// IconTile
//
// When there's no asset, or if you want to add an asset to a list

import React from 'react'
import { observer } from 'mobx-react-lite'

import { LayerView, Layer } from '../appview'
import { Icon } from '../components'
import { iconsize } from '../utils/icon'

export const IconTile = observer(function IconTile({
    width,
    icon,
    anchor,
    style,
    ...other
}) {
    const tilestyle = { ...style }
    tilestyle.minWidth = tilestyle.minWidth || width
    tilestyle.minHeight = tilestyle.minHeight || width
    return (
        <LayerView style={tilestyle} {...other}>
            <Layer anchor={anchor ? anchor : 'center'}>
                <Icon name={icon ? icon : 'plus'} size={iconsize(width)} />
            </Layer>
        </LayerView>
    )
})

//
// DragDropItem
//
// Drag and Drop wrapper for any component.
//
// This is used in lists where you want to re-order items, and it differentiates a
// 'top' and 'bottom' zone on the drop target.
// You can't drop on yourself.
// You should disableDrag when you're e.g. editing the contents.

import React, { useState, useRef, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useDrag, useDrop } from 'react-dnd'

import { useDebounce } from '../hooks/useDebounce'

export const DragDropItem = observer(function DragDropItem({
    type,
    id,
    disableDrag,
    onDrop,
    children,
    handle,
}) {
    const ref = useRef(null)

    const [isoverzone, setIsoverzone] = useState('top')
    const [isMousedown, setIsMousedown] = useState(false)

    const debouncedIsoverzone = useDebounce(isoverzone, 50)

    const [{ isDragging }, drag, preview] = useDrag({
        type: type,
        item: {
            type: type,
            id: id,
        },
        canDrag: monitor => {
            if (disableDrag) {
                setIsMousedown(false)
            }
            return !disableDrag
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
        end: (item, monitor) => {
            if (ref.current) {
                setIsMousedown(false)
            }
        },
    })

    const onMouseDown = e => {
        if (e.button === 0) {
            setIsMousedown(true)
        }
    }
    const onMouseUp = e => {
        setIsMousedown(false)
    }

    const canDrop = dragitem => {
        if (!ref.current || !dragitem || dragitem.id === id) {
            return false
        }
        return true
    }

    const [{ isOver }, drop] = useDrop({
        accept: type,
        canDrop: dragitem => canDrop(dragitem),

        drop(dragitem, monitor) {
            onDrop(dragitem.id, id, isoverzone)
            return {
                receiver: 'DragDropItem',
                dragkey: dragitem.id,
                dropkey: id,
                dropzone: isoverzone,
            }
        },

        collect: monitor => {
            let isOver = !!monitor.isOver()
            const dragitem = monitor.getItem()
            if (!canDrop(dragitem)) {
                isOver = false
            }
            return {
                isOver: isOver,
            }
        },

        hover(dragitem, monitor) {
            if (!canDrop(dragitem)) {
                return
            }
            // determine top or bottom
            const itemBoundingRect = ref.current.getBoundingClientRect()
            const itemTopY = Math.floor(
                (itemBoundingRect.bottom - itemBoundingRect.top) / 2
            )
            const mousePosition = monitor.getClientOffset()
            const itemMouseY = mousePosition.y - itemBoundingRect.top
            if (itemMouseY < itemTopY) {
                setIsoverzone('top')
            } else {
                setIsoverzone('bottom')
            }
        },
    })

    let Handle
    if (!handle) preview(drag(drop(ref)))
    else {
        preview(drop(ref))
        Handle = <div ref={drag}>{handle}</div>
    }

    let dragclassname = 'dropzone'
    if (isOver && debouncedIsoverzone === 'top') dragclassname += ' drag-over-top'
    else if (isOver && debouncedIsoverzone === 'bottom')
        dragclassname += ' drag-over-bottom'
    if (isMousedown) dragclassname += ' maybe-start-dragging'

    useEffect(() => {
        // set style later - cloned drag preview has original style
        if (ref && ref.current) {
            ref.current.style.opacity = isDragging ? 0.1 : 1
        }
    }, [isDragging])

    return (
        <div
            ref={ref}
            className={dragclassname}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
        >
            {children}
            {Handle}
        </div>
    )
})

//
// LoginScreen
//
// Enter credentials, then log in. We'll remember the user using a JWT, which is sent
// on subsequent requests/sessions so we'll stay logged in automatically.
//
// Forgot your password? That's covered here too.
//
// If you get here and are already logged in? Log out or continue to Unicat.

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { SiteScreen } from './SiteScreen'
import { VView, Spacer } from '../appview'
import { Bar, Button, Property, Text } from '../components'
import { UnicatSitePanel, LoginPanel } from '../panels'

export const LoginScreen = observer(function LoginScreen() {
    const store = useStore()
    const { app } = store

    const onGotoSignup = () => {
        store.setLocation(store.LOCATIONS.SIGNUP)
    }

    // title can change to forgot password/reset password
    const [title, setTitle] = useState(app.text('Unicat Login'))

    const message = store.isLoggedIn
        ? app.text("Hi {username}, you're already logged in.", {
              username: store.user.displayname,
          })
        : undefined

    const Activity = store.isLoggedIn ? (
        <>
            <Property label={''}>
                <Text>{message}</Text>
            </Property>
            <Spacer size={10} />
            <Property label={''}>
                <Bar raised>
                    <Button action onClick={() => store.autoLocation()}>
                        {app.text('Continue to Unicat')}
                    </Button>
                </Bar>
            </Property>
            <Spacer size={20} />
            <Property label={''}>
                <Bar raised>
                    <Button action onClick={() => store.logout()}>
                        {app.text('Log out')}
                    </Button>
                </Bar>
            </Property>
            <Spacer size={20} />
        </>
    ) : (
        <>
            <Spacer size={20} />
            <LoginPanel setTitle={setTitle} />
        </>
    )

    return (
        <SiteScreen>
            <VView className="form login-form">
                <UnicatSitePanel has_link={true} title={title} />
                {Activity}
                <Spacer size={20} />
                {!store.isLoggedIn && store.can('account.signup') ? (
                    <Property label={''}>
                        <Text className="link" onClick={() => onGotoSignup()}>
                            {app.text("I don't have an account yet.")}
                        </Text>
                    </Property>
                ) : undefined}
            </VView>
        </SiteScreen>
    )
})

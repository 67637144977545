//
// DefinitionClassMenu
//
// Actions on classes in the definition data worksheet
//
// DefinitionClassMenu
// - remove_class
// - goto_class

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Menu, MenuDivider, MenuItem } from '../components'

export const DefinitionClassMenu = observer(function DefinitionClassMenu({
    definition,
    onAction,
}) {
    const { app } = useStore()

    const _onAction = action => {
        onAction && onAction(action)
    }

    return (
        <Menu>
            <MenuItem className="cc-notice" onClick={() => _onAction('remove_class')}>
                {app.text('Remove from definition')}
            </MenuItem>
            <MenuDivider />
            <MenuItem onClick={() => _onAction('goto_class')}>
                {app.text('Go to class')}
            </MenuItem>
        </Menu>
    )
})

//
// RecordSearchPanel
//
// A search box with filters, and results.
// Results can be selected and/or dragged. Inputs are backed by the PimQueryStore,
// so they are preserved over reloads or worksheet and workspace switches. This means
// if you use the search for records from within the PIM section, it starts where you
// left off searching the last time.

import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'
import { useDebounce } from '../hooks/useDebounce'
import { useTooltipPopover } from '../hooks/useTooltipPopover'
import { useMousePopover } from '../hooks/useMousePopover'
import { usePopoverContext } from '../components'

import { DragRecordSearchItem } from '../dragdrop/DragRecordSearchItem'
import { Transform } from '../stores/imageservers/utils'
import {
    DeletedTreeRecordMenu,
    TreeRecordMenu,
    DeletedLinkedTreeRecordMenu,
    LinkedTreeRecordMenu,
    RecordsContextmenu,
} from '../menus'
import { keyboard } from '../utils/keyboard'

import {
    HView,
    VView,
    View,
    LazyVScrollList,
    LazyScrollItem,
    Spacer,
    LayerView,
    Layer,
    Divider,
} from '../appview'
import { SearchInput, Item, Icon, Menu, MenuItem, Text, Header } from '../components'
import { RecordItem, RecordCard } from '../panels'

import { QueryMenu, ModifiedQueryMenu, QuickRecordQueries } from '../filters/FilterMenu'
import { FilterPanel } from '../filters/FilterPanel'
import { humanReadableNumber } from '../utils/text'

const SearchResultItem = observer(function SearchResultItem({
    record,
    className,
    selected,
    onClick,
    ...other
}) {
    let classes = 'status-' + record.status
    if (className) classes += ' ' + className
    return (
        <Item
            selected={selected}
            indent={0}
            className={classes}
            onClick={onClick}
            {...other}
        >
            <RecordItem record={record} />
        </Item>
    )
})

const SelectedRecordPanel = observer(function SelectedRecordPanel({
    recordgid,
    onSelect,
    onCancel,
}) {
    const { app, data } = useStore()
    const record = data.records.get(recordgid)

    const _onAction = action => {
        if (action === 'select') onSelect && onSelect(recordgid)
        if (action === 'cancel') onCancel && onCancel(recordgid)
    }

    if (!record) return null

    const transform = new Transform('fit', 190, 190)

    return (
        <VView
            className="selectedrecord-panel"
            onClick={e => e.nativeEvent.stopImmediatePropagation()}
        >
            <div className="inspector">
                <RecordCard record={record} transform={transform} />
            </div>
            <Menu>
                <MenuItem onClick={() => _onAction('select')}>
                    {app.text('Select')}
                </MenuItem>
                <MenuItem onClick={() => _onAction('cancel')}>
                    {app.text('Cancel')}
                </MenuItem>
            </Menu>
        </VView>
    )
})

export const RecordSearchPanel = observer(function RecordSearchPanel({
    querystore,
    selectedRecordGid,
    onSelect,
    preview,
    multiselect,
    recordactions,
    viewkey,
    showMatches,
    ...other
}) {
    const { app, data, view } = useStore()
    const popoverContext = usePopoverContext()

    const [selectedRecord, setSelectedRecord] = useState(null)
    const [isRefreshing, setIsRefreshing] = useState(false)
    const TreeRecordContextMenu = useMousePopover(TreeRecordMenu)
    const LinkedTreeRecordContextMenu = useMousePopover(LinkedTreeRecordMenu)
    const DeletedTreeRecordContextMenu = useMousePopover(DeletedTreeRecordMenu)
    const DeletedLinkedTreeRecordContextMenu = useMousePopover(
        DeletedLinkedTreeRecordMenu
    )
    const RecordsContextmenuPopover = useMousePopover(RecordsContextmenu)

    const onCreateRecord = () => {
        if (!recordactions || !selectedRecord) return
        view.pimtree.create({ item: selectedRecord })
    }

    const onGotoLinkedRecord = () => {
        if (!recordactions || !selectedRecord) return
        return data.fetchRecordsByGid([selectedRecord.canonical]).then(result => {
            if (result.length) {
                view.pimtree.selectItem({ id: result[0].id, item: result[0] })
            }
        })
    }

    const onUnlinkRecord = () => {
        if (!recordactions || !selectedRecord) return
        selectedRecord.unlink()
    }

    const onDelete = () => {
        if (!recordactions || !selectedRecord) return
        selectedRecord.delete()
    }

    const onUndelete = () => {
        if (!recordactions || !selectedRecord) return
        selectedRecord.undelete()
    }

    const onPermanentDelete = () => {
        if (!recordactions || !selectedRecord) return
        const parentrecord = view.pimworksheet.path[view.pimworksheet.path.length - 2]
        selectedRecord.permanentDelete().then(successdata => {
            view.pimworksheet.setRecord(parentrecord)
            view.pimtree.refetch()
        })
    }

    const onExportChildrenToExcel = () => {
        data.actions.export_children_to_excel(selectedRecord.gid)
    }

    const onDuplicateRecordsSelection = () => {
        if (!recordactions || !querystore.multiselection.size) return
        data.actions.records.bulkDuplicate(querystore.actionSelection)
    }

    const onDeleteRecordsSelection = () => {
        if (!recordactions || !querystore.multiselection.size) return
        data.actions.records.bulkDelete(querystore.actionSelection)
    }

    const onUndeleteRecordsSelection = () => {
        if (!recordactions || !querystore.multiselection.size) return
        data.actions.records.bulkUndelete(querystore.actionSelection)
    }

    const onPermanentDeleteRecordsSelection = () => {
        if (!recordactions || !querystore.multiselection.size) return
        data.actions.records.bulkPermanentDelete(querystore.actionSelection)
    }

    const onRecordAction = action => {
        popoverContext.store.show([...popoverContext.stack])
        const actions = {
            create_nested: onCreateRecord,
            goto_linked_record: onGotoLinkedRecord,
            unlink_record: onUnlinkRecord,
            delete: onDelete,
            undelete: onUndelete,
            permanent_delete: onPermanentDelete,
            export_children_to_excel: onExportChildrenToExcel,
            duplicate_records_selection: onDuplicateRecordsSelection,
            delete_records_selection: onDeleteRecordsSelection,
            undelete_records_selection: onUndeleteRecordsSelection,
            permanent_delete_records_selection: onPermanentDeleteRecordsSelection,
        }
        if (!(action in actions) || !recordactions || !selectedRecord) {
            console.log(
                `onRecordAction: unhandled action '${action}' on '${selectedRecord.title}'`
            )
            return
        }
        actions[action]()
    }

    const QueryMenuPopover = useTooltipPopover(QueryMenu)
    const ModifiedQueryMenuPopover = useTooltipPopover(ModifiedQueryMenu)

    const SelectedRecordPopover = useTooltipPopover(SelectedRecordPanel, {
        preferredPosition: 'right',
        onClickOutside: e => {
            setSelectedGid(null)
        },
    })

    const itemheight = 60

    const onUpdate = (newtopindex, newwindowsize) => {
        if (
            newtopindex !== querystore.topindex ||
            newwindowsize !== querystore.windowsize
        ) {
            querystore.fetch(newtopindex, newwindowsize)
        }
    }

    const qref = React.useRef(querystore.q)
    const [searchtext, setSearchtext] = useState(querystore.q)
    const debouncedSearchtext = useDebounce(searchtext, 300)

    const [selectedGid, setSelectedGid] = useState(selectedRecordGid)

    const onSearchinputChange = event => {
        setSearchtext(event.target.value)
    }
    const onSearchinputBlur = event => {
        setSearchtext(event.target.value.trim())
    }

    const onSearchRefresh = event => {
        querystore.refetch()
    }

    useEffect(() => {
        if (querystore.q === qref.current) {
            qref.current = debouncedSearchtext
            querystore.q = debouncedSearchtext
        } else {
            qref.current = querystore.q
            setSearchtext(querystore.q)
        }
    }, [querystore, querystore.q, debouncedSearchtext])

    useEffect(() => {
        querystore.fetch(0, querystore.windowsize)
    }, [
        querystore,
        querystore.q,
        querystore.filterstore.refreshkey,
        querystore.windowsize,
    ])

    const showPreview = () => {
        if (preview) {
            SelectedRecordPopover.show()
        }
    }

    const hidePreview = () => {
        if (preview) {
            SelectedRecordPopover.hide()
        }
    }

    const onConfirmSelect = recordgid => {
        onSelect && onSelect(recordgid)
        setSelectedGid(null)
        hidePreview()
    }

    const onCancel = recordgid => {
        setSelectedGid(null)
        hidePreview()
    }

    const onSearchResultItemClicked = (event, recordgid) => {
        if (multiselect) {
            onSearchResultItemClickedMultiSelect(event, recordgid)
        } else {
            onSearchResultItemClickedSingleSelect(event, recordgid)
        }
    }

    const onSearchResultItemClickedSingleSelect = (event, recordgid) => {
        event.stopPropagation()
        event.nativeEvent.stopImmediatePropagation()
        popoverContext.store.show([...popoverContext.stack])
        if (selectedGid === recordgid) {
            setSelectedGid(null)
            hidePreview()
        } else {
            setSelectedGid(recordgid)
            showPreview()
        }
        if (!preview) {
            onSelect && onSelect(selectedGid === recordgid ? null : recordgid)
        }
    }

    const onSearchResultItemClickedMultiSelect = (event, recordgid) => {
        event.stopPropagation()
        event.nativeEvent.stopImmediatePropagation()
        popoverContext.store.show([...popoverContext.stack])
        if (keyboard.testCommand(event)) {
            if (querystore.multiselection.isSelected(recordgid)) {
                querystore.multiselection.deselect(recordgid)
            } else {
                querystore.multiselection.addRangeStart(recordgid)
            }
        } else if (keyboard.testShift(event)) {
            if (!querystore.multiselection.size && selectedGid) {
                querystore.multiselection.rangeStart(selectedGid)
            } else if (!querystore.multiselection.size && selectedRecordGid) {
                querystore.multiselection.rangeStart(selectedRecordGid)
            }
            querystore.multiselection.rangeEnd(recordgid)
        } else {
            querystore.multiselection.rangeStart(recordgid)
            setSelectedGid(recordgid)
            onSelect && onSelect(recordgid)
        }
    }

    const onSearchResultItemDoubleClicked = (event, recordgid) => {
        SelectedRecordPopover.hide()
        event.stopPropagation()
        event.nativeEvent.stopImmediatePropagation()
        popoverContext.store.show([...popoverContext.stack])
        onConfirmSelect(recordgid)
    }

    const onQueryMenuAction = (action, p1, p2, p3) => {
        switch (action) {
            case 'new':
                querystore.newQuery()
                break
            case 'reopen':
                querystore.reopenQuery()
                break
            case 'open':
                querystore.openQuery(p1) // gid
                break
            case 'cancel_open':
                break
            case 'save':
                querystore.saveQuery()
                break
            case 'save_as_new':
                querystore.saveNewQuery()
                break
            case 'delete':
                querystore.deleteQuery()
                break
            case 'quickquery':
                querystore.gid = null
                querystore.name = p1
                querystore.q = p2
                querystore.filter = p3
                break
            default:
                console.log(`onQueryMenuAction: unhandled action '${action}'`)
                break
        }
        QueryMenuPopover.hide()
        ModifiedQueryMenuPopover.hide()
    }

    const refreshResults = () => {
        if (isRefreshing) return
        setIsRefreshing(true)
        querystore.refetch().then(() => {
            window.setTimeout(() => {
                setIsRefreshing(false)
            }, 200)
        })
    }

    const SearchResultItems = querystore.totalsize ? (
        querystore.results
            .filter((recordgid, index) => {
                return !!data.records.get(recordgid)
            })
            .map((recordgid, index) => {
                const record = data.records.get(recordgid)
                let selected = selectedGid
                    ? recordgid === selectedGid
                    : recordgid === selectedRecordGid
                let classes = ''
                if (querystore.multiselection.isSelected(recordgid) && !selected) {
                    classes += ' cc-multiselected'
                }
                if (
                    multiselect &&
                    querystore.multiselection.size > 0 &&
                    selected &&
                    !querystore.multiselection.isSelected(recordgid)
                ) {
                    selected = false
                }

                const contextmenu = !recordactions
                    ? null
                    : multiselect &&
                      querystore.multiselection.size > 1 &&
                      querystore.multiselection.isSelected(recordgid)
                    ? RecordsContextmenuPopover
                    : record.status !== 'deleted'
                    ? record.is_link
                        ? LinkedTreeRecordContextMenu
                        : TreeRecordContextMenu
                    : record.is_link
                    ? DeletedLinkedTreeRecordContextMenu
                    : DeletedTreeRecordContextMenu

                return (
                    <LazyScrollItem key={recordgid} index={index + querystore.topindex}>
                        <div
                            ref={
                                recordgid === selectedGid
                                    ? SelectedRecordPopover.anchorRef
                                    : undefined
                            }
                            onContextMenu={e => {
                                if (contextmenu) {
                                    setSelectedRecord(record)
                                    if (
                                        !(
                                            multiselect &&
                                            querystore.multiselection.size > 1 &&
                                            querystore.multiselection.isSelected(
                                                recordgid
                                            )
                                        )
                                    ) {
                                        querystore.multiselection.deselectAll()
                                        setSelectedGid(recordgid)
                                    }
                                    contextmenu.onShow(e)
                                }
                            }}
                        >
                            <DragRecordSearchItem
                                record={record}
                                querystore={querystore}
                            >
                                <SearchResultItem
                                    className={classes}
                                    record={record}
                                    selected={selected}
                                    onClick={e =>
                                        onSearchResultItemClicked(e, recordgid)
                                    }
                                    onDoubleClick={e =>
                                        onSearchResultItemDoubleClicked(e, recordgid)
                                    }
                                />
                            </DragRecordSearchItem>
                        </div>
                    </LazyScrollItem>
                )
            })
    ) : (querystore.q && querystore.q.trim().length) ||
      querystore.filterstore.hasFilters() ? (
        multiselect ? (
            <Text className="cc-Placeholder">
                {app.text('Change the search terms or the filter.')}
            </Text>
        ) : (
            <LayerView>
                <Text>
                    {app.text('No matches')}
                    {querystore.filterstore.hasFilters() && !querystore.showFilter ? (
                        <>
                            {' '}
                            <span
                                className="ws-tagbutton"
                                onClick={() => querystore.clearQueryFilters()}
                            >
                                {app.text('clear filters')}
                            </span>
                        </>
                    ) : undefined}
                </Text>
                <Layer anchor="end" style={{ lineHeight: 0, marginRight: 5 }}>
                    <Icon
                        onClick={refreshResults}
                        name="refresh"
                        title={app.text('Refresh')}
                        spin={isRefreshing ? 'quickly' : false}
                        size={1}
                    />
                </Layer>
            </LayerView>
        )
    ) : (
        <Text className="cc-Placeholder">
            {app.text('Type in the search bar or add a filter.')}
        </Text>
    )

    const activeFilterClassName = querystore.filterstore.hasFilters() ? 'cc-active' : ''

    const query = querystore.gid ? data.queries.get(querystore.gid) : null

    const Folder = (
        <>
            <QueryMenuPopover.Panel
                querystore={querystore}
                quickqueries={QuickRecordQueries}
                onAction={onQueryMenuAction}
            />
            <View
                style={{
                    flexBasis: '20px',
                    minWidth: 20,
                    maxWidth: 20,
                    position: 'relative',
                    top: '2px',
                }}
            >
                <div
                    style={{ backgroundColor: 'transparent' }}
                    ref={QueryMenuPopover.anchorRef}
                    onClick={e => {
                        if (querystore.filterstore.hasFilters()) {
                            querystore.setShowFilter(true)
                        }
                        ModifiedQueryMenuPopover.hide(e)
                        QueryMenuPopover.onShow(e)
                    }}
                    onContextMenu={e => {
                        if (querystore.filterstore.hasFilters()) {
                            querystore.setShowFilter(true)
                        }
                        ModifiedQueryMenuPopover.hide(e)
                        QueryMenuPopover.onShow(e)
                    }}
                >
                    <Icon name="folder" size={2} />
                </div>
            </View>
        </>
    )

    showMatches = true

    return (
        <VView grow className="panel record-search-panel" {...other}>
            {query ? (
                <HView style={{ marginLeft: 5, marginRight: 5 }}>
                    <View>
                        <Header>{query.name}</Header>
                    </View>
                    {querystore.is_modified ? (
                        <>
                            <ModifiedQueryMenuPopover.Panel
                                querystore={querystore}
                                onAction={onQueryMenuAction}
                            />
                            <Text>
                                <span
                                    className="ws-tag cc-info"
                                    ref={ModifiedQueryMenuPopover.anchorRef}
                                    onClick={e => {
                                        if (querystore.filterstore.hasFilters()) {
                                            querystore.setShowFilter(true)
                                        }
                                        QueryMenuPopover.hide(e)
                                        ModifiedQueryMenuPopover.onShow(e)
                                    }}
                                    onContextMenu={e => {
                                        if (querystore.filterstore.hasFilters()) {
                                            querystore.setShowFilter(true)
                                        }
                                        QueryMenuPopover.hide(e)
                                        ModifiedQueryMenuPopover.onShow(e)
                                    }}
                                >
                                    {app.text('modified')}
                                </span>
                            </Text>
                        </>
                    ) : undefined}
                    <Spacer grow />
                    {Folder}
                    <Spacer size={5} />
                </HView>
            ) : undefined}
            <HView style={{ marginLeft: 5, marginRight: 5 }}>
                <View grow>
                    <LayerView>
                        <SearchInput
                            value={searchtext}
                            placeholder={app.text('Search')}
                            onChange={onSearchinputChange}
                            onBlur={onSearchinputBlur}
                            onIconClick={onSearchRefresh}
                            icontitle={app.text('Click to refresh')}
                        />
                        <Layer anchor="end">
                            <View
                                style={{
                                    flexBasis: '20px',
                                    minWidth: 20,
                                    maxWidth: 20,
                                    position: 'relative',
                                    top: '1px',
                                    left: '-6px',
                                }}
                            >
                                <div
                                    className={activeFilterClassName}
                                    style={{ backgroundColor: 'transparent' }}
                                    onClick={e => {
                                        querystore.setShowFilter(!querystore.showFilter)
                                    }}
                                >
                                    <Icon name="filter" size={2} />
                                </div>
                            </View>
                        </Layer>
                    </LayerView>
                </View>
                {query ? undefined : (
                    <>
                        <Spacer size={5} />
                        {Folder}
                    </>
                )}
            </HView>
            {querystore.showFilter ? (
                <>
                    <FilterPanel filterstore={querystore.filterstore} />
                    <Divider style={{ margin: '0 5px' }} />
                </>
            ) : undefined}
            {showMatches ? (
                <>
                    <HView vcenter>
                        <Text>
                            {app.pluralized_text(
                                {
                                    0: 'No matches',
                                    1: '{formatted_count} match',
                                    many: '{formatted_count} matches',
                                },
                                querystore.totalsize,
                                {
                                    formatted_count: humanReadableNumber(
                                        querystore.totalsize
                                    ),
                                }
                            )}
                        </Text>
                        <div style={{ lineHeight: 0 }}>
                            <Icon
                                onClick={refreshResults}
                                name="refresh"
                                title={app.text('Refresh')}
                                spin={isRefreshing ? 'quickly' : false}
                                size={1}
                            />
                        </div>
                        <Spacer grow />
                        {querystore.filterstore.hasFilters() ||
                        querystore.showFilter ? (
                            <Text>
                                <span
                                    className="ws-tagbutton"
                                    onClick={() => querystore.clearQueryFilters()}
                                >
                                    {app.text('reset filters')}
                                </span>
                            </Text>
                        ) : undefined}
                    </HView>
                </>
            ) : undefined}
            {showMatches ? <Divider style={{ margin: '0 5px' }} /> : undefined}

            <SelectedRecordPopover.Panel
                recordgid={
                    querystore.results.includes(selectedGid) ? selectedGid : null
                }
                onSelect={onConfirmSelect}
                onCancel={onCancel}
            />
            <RecordsContextmenuPopover.Panel
                record={selectedRecord}
                recordsselection={querystore.multiselection}
                onAction={onRecordAction}
            />
            <TreeRecordContextMenu.Panel
                record={selectedRecord}
                onAction={onRecordAction}
            />
            <LinkedTreeRecordContextMenu.Panel
                record={selectedRecord}
                onAction={onRecordAction}
            />
            <DeletedTreeRecordContextMenu.Panel
                record={selectedRecord}
                onAction={onRecordAction}
            />
            <DeletedLinkedTreeRecordContextMenu.Panel
                record={selectedRecord}
                onAction={onRecordAction}
            />
            <LazyVScrollList
                className="search-results"
                firstindex={querystore.topindex}
                itemheight={itemheight}
                totalitems={querystore.totalsize}
                onUpdate={onUpdate}
            >
                {SearchResultItems}
            </LazyVScrollList>
        </VView>
    )
})

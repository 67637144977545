//
// DataStore
//
// The DataStore is the core data from the server, mapping (almost?) directly to the
// records in the database. They are stored in a map, and the globally unique gid is
// the key.
// This is 'plain' data, and links are represented by their 'gid', not by a reference
// to the actual object. Note that with the Unicat catalog_tree table, we also have
// references internally by gid, parent, and we'll have references to assets by path
// and file name.
//
// TODO: CC-209 implement some sort of caching, where unused records are evicted, to
// prevent using a lot of memory that's not needed

import { makeObservable, observable, action } from 'mobx'

import ActionStore from './actions'

import { UserData } from './UserData'
import { ProjectData } from './ProjectData'
import { ProjectMemberData, projectMemberKey } from './ProjectMemberData'
import { LanguageData } from './LanguageData'
import { RecordData } from './RecordData'
import { DefinitionData } from './DefinitionData'
import { ClassData } from './ClassData'
import { FieldData } from './FieldData'
import { LayoutData } from './LayoutData'
import { AssetData } from './AssetData'
import { QueryData } from './QueryData'

class DataStore {
    users = new Map()
    projects = new Map()
    project_members = new Map()
    languages = new Map()
    records = new Map()
    definitions = new Map()
    classes = new Map()
    fields = new Map()
    layouts = new Map()
    assets = new Map()
    queries = new Map()

    constructor(rootstore) {
        makeObservable(this, {
            users: observable,
            projects: observable,
            project_members: observable,
            records: observable,
            definitions: observable,
            classes: observable,
            fields: observable,
            layouts: observable,
            assets: observable,
            queries: observable,
            addUser: action.bound,
            addUsers: action.bound,
            addProject: action.bound,
            addProjects: action.bound,
            createProject: action.bound,
            addProjectMember: action.bound,
            addProjectMembers: action.bound,
            removeProjectMember: action.bound,
            addRecord: action.bound,
            addRecords: action.bound,
            addDefinition: action.bound,
            addDefinitions: action.bound,
            addClass: action.bound,
            addClasses: action.bound,
            addField: action.bound,
            addFields: action.bound,
            addLayout: action.bound,
            addLayouts: action.bound,
            addQuery: action.bound,
            addQueries: action.bound,
            addAsset: action.bound,
            addAssets: action.bound,
            fetchAssetByGid: action.bound,
            fetchAssetsByGid: action.bound,
            fetchAssetByPathname: action.bound,
            fetchRecordByGid: action.bound,
            fetchRecordsByGid: action.bound,
            fetchFieldPreviewPathname: action.bound,

            createDefinition: action.bound,
            createClass: action.bound,
            createField: action.bound,
            createQuery: action.bound,
        })

        this._rootstore = rootstore
        this.actions = new ActionStore(this._rootstore)
    }

    addUser = data => {
        let user = this.users.get(data['gid'])
        if (user) user.update(data)
        else this.users.set(data['gid'], new UserData(data, this._rootstore))
    }

    addUsers = dataset => {
        Object.values(dataset).forEach(data => this.addUser(data))
    }

    addProject = data => {
        let project = this.projects.get(data['gid'])
        if (project) project.update(data)
        else {
            this.projects.set(data['gid'], new ProjectData(data, this._rootstore))
        }
    }

    addProjects = dataset => {
        Object.values(dataset).forEach(data => this.addProject(data))
    }

    addProjectMember = data => {
        const key = projectMemberKey(data)
        let project_member = this.project_members.get(key)
        if (project_member) project_member.update(data)
        else {
            this.project_members.set(key, new ProjectMemberData(data, this._rootstore))
        }
    }

    addProjectMembers = dataset => {
        Object.values(dataset).forEach(data => this.addProjectMember(data))
    }

    removeProjectMember = data => {
        const key = projectMemberKey(data)
        this.project_members.delete(key)
    }

    projectsForUser = user_gid => {
        const user_projects = Array.from(this.project_members.values()).filter(
            project_member =>
                project_member.user_gid === user_gid &&
                this.projects.has(project_member.project_gid)
        )
        return user_projects
    }

    membersForProject = project_gid => {
        const project_members = Array.from(this.project_members.values()).filter(
            project_member =>
                project_member.project_gid === project_gid &&
                this.projects.has(project_member.project_gid)
        )
        return project_members
    }

    addLanguage = data => {
        let language = this.languages.get(data['code'])
        if (language) language.update(data)
        else {
            this.languages.set(data['code'], new LanguageData(data, this._rootstore))
        }
    }

    addLanguages = dataset => {
        Object.entries(dataset).forEach(([key, value]) =>
            this.addLanguage({ code: key, name: value })
        )
    }

    addRecord = data => {
        let record = this.records.get(data['gid'])
        if (record) record.update(data)
        else {
            this.records.set(data['gid'], new RecordData(data, this._rootstore))
        }
    }

    addRecords = dataset => {
        Object.values(dataset).forEach(data => this.addRecord(data))
    }

    getDefinitionByName = name => {
        let matchingDefinition = null
        this.definitions.forEach(definition => {
            if (definition.name === name) {
                matchingDefinition = definition
            }
        })
        return matchingDefinition
    }

    addDefinition = data => {
        let definition = this.definitions.get(data['gid'])
        if (definition) definition.update(data)
        else
            this.definitions.set(data['gid'], new DefinitionData(data, this._rootstore))
    }

    addDefinitions = dataset => {
        Object.values(dataset).forEach(data => this.addDefinition(data))
    }

    getClassByName = name => {
        let matchingClass = null
        this.classes.forEach(class_ => {
            if (class_.name === name) {
                matchingClass = class_
            }
        })
        return matchingClass
    }

    addClass = data => {
        let class_ = this.classes.get(data['gid'])
        if (class_) class_.update(data)
        else this.classes.set(data['gid'], new ClassData(data, this._rootstore))
    }

    addClasses = dataset => {
        Object.values(dataset).forEach(data => this.addClass(data))
    }

    getFieldByName = name => {
        let matchingField = null
        this.fields.forEach(field => {
            if (field.name === name) {
                matchingField = field
            }
        })
        return matchingField
    }

    addField = data => {
        let field = this.fields.get(data['gid'])
        if (field) field.update(data)
        else this.fields.set(data['gid'], new FieldData(data, this._rootstore))
    }

    addFields = dataset => {
        Object.values(dataset).forEach(data => this.addField(data))
    }

    addLayout = data => {
        let layout = this.layouts.get(data['gid'])
        if (layout) layout.update(data)
        else {
            this.layouts.set(data['gid'], new LayoutData(data, this._rootstore))
        }
    }

    addLayouts = dataset => {
        Object.values(dataset).forEach(data => this.addLayout(data))
    }

    addQuery = data => {
        let query = this.queries.get(data['gid'])
        if (query) query.update(data)
        else this.queries.set(data['gid'], new QueryData(data, this._rootstore))
    }

    addQueries = dataset => {
        Object.values(dataset).forEach(data => this.addQuery(data))
    }

    getQueryByName = name => {
        let matchingQuery = null
        this.queries.forEach(query => {
            if (query.name === name) {
                matchingQuery = query
            }
        })
        return matchingQuery
    }

    addAsset = data => {
        let asset = this.assets.get(data['gid'])
        if (asset) {
            asset.update(data)
        } else {
            this.assets.set(data['gid'], new AssetData(data, this._rootstore))
        }
    }

    addAssets = dataset => {
        Object.values(dataset).forEach(data => this.addAsset(data))
    }

    getAssetByPathname = pathname => {
        let matchingAsset = null
        this.assets.forEach(asset => {
            if (asset.pathname === pathname) {
                matchingAsset = asset
            }
        })
        return matchingAsset
    }

    createProject = name => {
        return this._rootstore
            ._globalfetch('/projects/create', { name })
            .then(result => {
                return result['project']
            })
            .catch(error => {})
    }

    commit = () => {
        return
        // // first, select first record in path that won't be deleted
        // let newlySelectedRecord
        // let path = [...this._rootstore.view.pimworksheet.path]
        // path.reverse()
        // for (newlySelectedRecord of path) {
        //     if (newlySelectedRecord.status !== 'deleted') break
        // }
        // this._rootstore.view.pimworksheet.setRecord(newlySelectedRecord)
        // // ok, now commit
        // this._rootstore
        //     ._fetch('/pim/commit', {})
        //     .then(successdata => {
        //         this._rootstore.view.pimworksheet.refetch()
        //         this._rootstore.view.pimtree.refetch()
        //     })
        //     .catch(error => {})
    }

    async fetchAssetByPathname(pathname, additional_fetch_options) {
        if (!pathname) {
            return Promise.resolve(null)
        }
        let matchingAsset = null
        this.assets.forEach(asset => {
            if (asset.pathname === pathname) {
                matchingAsset = asset
            }
        })
        if (matchingAsset) {
            return Promise.resolve(matchingAsset)
        }

        const asset = await this._rootstore
            ._fetch(
                '/assets/get',
                {
                    pathname: pathname,
                },
                additional_fetch_options
            )
            .then(result => {
                return this._rootstore.data.assets.get(result['asset'])
            })
            .catch(error => {
                return null
            })
        return Promise.resolve(asset)
    }

    async fetchAssetByGid(gid, additional_fetch_options) {
        if (!gid) {
            return Promise.resolve(null)
        }
        const matchingAsset = this.assets.get(gid)
        if (matchingAsset) {
            return Promise.resolve(matchingAsset)
        }
        const asset = await this._rootstore
            ._fetch(
                '/assets/get',
                {
                    asset: gid,
                },
                additional_fetch_options
            )
            .then(result => {
                return this._rootstore.data.assets.get(result['asset'])
            })
            .catch(error => {
                return null
            })
        return Promise.resolve(asset)
    }

    async fetchAssetsByGid(gids, additional_fetch_options) {
        return await this._rootstore
            ._fetch(
                '/assets/get',
                {
                    assets: gids,
                },
                additional_fetch_options
            )
            .then(result => {
                return result['assets'].map(gid => this._rootstore.data.assets.get(gid))
            })
            .catch(error => {
                return null
            })
    }

    async fetchRecordByGid(gid, additional_fetch_options) {
        return this._rootstore
            ._fetch(
                '/records/get',
                {
                    record: gid,
                    language: this._rootstore.view.environment.get('language'),
                },
                additional_fetch_options
            )
            .then(result => {
                return this._rootstore.data.records.get(result['record'])
            })
            .catch(error => {
                return []
            })
    }

    async fetchRecordsByGid(gids, additional_fetch_options) {
        return this._rootstore
            ._fetch(
                '/records/get',
                {
                    records: gids,
                    language: this._rootstore.view.environment.get('language'),
                },
                additional_fetch_options
            )
            .then(result => {
                return result['records'].map(gid =>
                    this._rootstore.data.records.get(gid)
                )
            })
            .catch(error => {
                return []
            })
    }

    async fetchFieldPreviewPathname(
        recordgid,
        fieldgid,
        fieldvalue,
        additional_fetch_options
    ) {
        return this._rootstore
            ._fetch(
                '/records/fields/preview',
                {
                    record: recordgid,
                    field: fieldgid,
                    value: fieldvalue,
                    language: this._rootstore.view.environment.get('language'),
                },
                additional_fetch_options
            )
            .then(result => {
                return result['preview_pathname']
            })
            .catch(error => {
                return null
            })
    }

    createDefinition = () => {
        return this._rootstore._fetch('/definitions/create', {})
    }
    createClass = () => {
        return this._rootstore._fetch('/classes/create', {})
    }
    createField = () => {
        return this._rootstore._fetch('/fields/create', {})
    }
    createQuery = (type, name, q, filter) => {
        return this._rootstore._fetch('/queries/create', {
            type: type,
            name: name,
            q: q || '',
            filter: filter || ['and', '', []],
        })
    }
}

export default DataStore

//
// ProjectAPIKeyMenu
//
// Actions on project api keys

import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { VView, HScrollView } from '../appview'
import { Header, Menu, MenuDivider, MenuItem, Text, TextInput } from '../components'

const ShowAPIKeySubmenu = observer(function ShowAPIKeySubmenu({ apiuser }) {
    const store = useStore()
    const { app } = store

    const [apikey, setAPIKey] = useState(app.text('Fetching…'))
    const [fetched, setFetched] = useState(false)
    const [copied, setCopied] = useState(false)

    useEffect(() => {
        let isMounted = true
        async function asyncEffect() {
            store.project.getAPIKey(apiuser.gid).then(result => {
                if (isMounted) {
                    setAPIKey(result['apikey'])
                    setFetched(true)
                }
            })
        }
        asyncEffect()
        return () => (isMounted = false)
    }, [store.project, apiuser.gid])

    const onCopyToClipboard = () => {
        if ('clipboard' in navigator) {
            navigator.clipboard.writeText(apikey).then(() => {
                setCopied(true)
                window.setTimeout(() => setCopied(false), 2500)
            })
        }
    }

    const copyDisabled = !fetched || !('clipboard' in navigator)

    return (
        <VView className="actions-panel update-apikey-panel" style={{ width: 260 }}>
            <div className="inspector">
                <Header>{app.text('API key')}</Header>
                <HScrollView>
                    <Text className="user-select" style={{ backgroundColor: 'white' }}>
                        {apikey}
                    </Text>
                </HScrollView>
                <Text className="cc-danger">
                    {app.text(
                        'API keys allow full access, be careful when handing them out. Do NOT send them via email or other unsecured channels.'
                    )}
                </Text>
            </div>
            <Menu style={{ maxWidth: 260 }}>
                <MenuItem disabled={copyDisabled} onClick={onCopyToClipboard}>
                    {app.text(copied ? 'Copied' : 'Copy to clipboard')}
                </MenuItem>
            </Menu>
        </VView>
    )
})

const ShowProjectKeySubmenu = observer(function ShowProjectKeySubmenu() {
    const { app, project } = useStore()

    const [copied, setCopied] = useState(false)

    const onCopyToClipboard = () => {
        if ('clipboard' in navigator) {
            navigator.clipboard.writeText(project.gid).then(() => {
                setCopied(true)
                window.setTimeout(() => setCopied(false), 2500)
            })
        }
    }

    const copyDisabled = !('clipboard' in navigator)

    return (
        <VView className="actions-panel update-apikey-panel" style={{ width: 260 }}>
            <div className="inspector">
                <Header>{app.text('Project key')}</Header>
                <HScrollView>
                    <Text className="user-select" style={{ backgroundColor: 'white' }}>
                        {project.gid}
                    </Text>
                </HScrollView>
            </div>
            <Menu style={{ maxWidth: 260 }}>
                <MenuItem disabled={copyDisabled} onClick={onCopyToClipboard}>
                    {app.text(copied ? 'Copied' : 'Copy to clipboard')}
                </MenuItem>
            </Menu>
        </VView>
    )
})

export const ProjectAPIKeyMenu = observer(function ProjectAPIKeyMenu({
    member,
    onAction,
}) {
    const { app, view, data } = useStore()

    const user = data.users.get(member.user_gid)
    const is_owner = view.user && view.project && view.user.gid === view.project.owner

    const [name, setName] = useState(user ? user.name : '')
    const isValidAndChangedName = name.trim().length > 0 && name !== user.name

    const _onAction = (action, changedname) => {
        onAction && onAction(member.user_gid, action, changedname)
    }

    const disableUpdate = !isValidAndChangedName
    const disableDeactivate = member.status === 'inactive'
    const disableReactivate = member.status !== 'inactive'

    if (!user) return null

    if (is_owner) {
        return (
            <VView className="actions-panel update-apikey-panel">
                <div className="inspector">
                    <Header>{app.text('API key name')}</Header>
                    <VView
                        style={{
                            marginLeft: 5,
                            marginRight: 5,
                            marginBottom: 5,
                        }}
                    >
                        <TextInput
                            onChange={e => setName(e.target.value)}
                            value={name}
                        />
                    </VView>
                </div>
                <Menu>
                    <MenuItem
                        disabled={disableUpdate}
                        onClick={() => _onAction('update', name)}
                    >
                        {app.text('Update name')}
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem submenu={<ShowProjectKeySubmenu />}>
                        {app.text('Show project key')}
                    </MenuItem>
                    <MenuItem submenu={<ShowAPIKeySubmenu apiuser={user} />}>
                        {app.text('Show API key')}
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem
                        disabled={disableDeactivate}
                        onClick={() => _onAction('deactivate')}
                    >
                        {app.text('Deactivate')}
                    </MenuItem>
                    <MenuItem
                        disabled={disableReactivate}
                        onClick={() => _onAction('reactivate')}
                    >
                        {app.text('Reactivate')}
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem className="cc-danger" onClick={() => _onAction('delete')}>
                        {app.text('Delete')}
                    </MenuItem>
                </Menu>
            </VView>
        )
    } else {
        return (
            <VView className="actions-panel update-apikey-panel">
                <div className="inspector">
                    <Header>{name}</Header>
                </div>
                <Menu>
                    <MenuItem submenu={<ShowProjectKeySubmenu />}>
                        {app.text('Show project key')}
                    </MenuItem>
                    <MenuItem submenu={<ShowAPIKeySubmenu apiuser={user} />}>
                        {app.text('Show API key')}
                    </MenuItem>
                </Menu>
            </VView>
        )
    }
})

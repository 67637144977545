//
// TreeStore
//
// Backing for the TreeItem components. The Tree is basically a list of TreeItems,
// with different indent settings to make it look like a tree.
//
// Accidentally (or not), we also fetch a tree from the database that is a flat list,
// with childcount, parentid, and id info so we can build a tree from there. The list
// from the server is pre-sorted, and changes in sort order are performed on the server.
//
// The actual data, fetching, selected, and expanded/collapsed state is managed by
// a datamanager. This manager needs the following interface:
//
//     TreeDatamanagerInterface {
//         fetch(parentIds, treestore) => called on expand/collapse action, returns promise
//         select(treeitem) => called on select action
//          ... and more, todo: document this...
//     }

import { makeObservable, observable, computed, action } from 'mobx'
import { MultiSelection } from './MultiSelection'

export class TreeStoreItem {
    id = null
    parentid = null
    depth = null
    childcount = null
    item = null
    data = null

    constructor(id, parentid, depth, childcount, item, data) {
        makeObservable(this, {
            id: observable,
            parentid: observable,
            depth: observable,
            childcount: observable,
            item: observable,
            data: observable,
        })

        this.id = id
        this.parentid = parentid
        this.depth = depth
        this.childcount = childcount
        this.item = item
        this.data = data
    }
}

export class TreeStore {
    _treeitems = []
    _expandedIds = []
    _selectedId = null
    _totalsize = null
    _paths = new Map()
    multiselection = null

    constructor(datamanager) {
        this.multiselection = new MultiSelection(this)
        makeObservable(this, {
            _treeitems: observable,
            _expandedIds: observable,
            _selectedId: observable,
            _totalsize: observable,
            _paths: observable,
            multiselection: observable,

            treeitems: computed,
            selectedItem: computed,

            setTreeItems: action.bound,
            setSelectedId: action.bound,
            deselectItem: action.bound,
            selectItem: action.bound,
            expandItem: action.bound,
            collapseItem: action.bound,
            _setExpandedIds: action.bound,
            createItem: action.bound,
            refetch: action.bound,
            fetch: action.bound,

            full_size: computed,
            keys: computed,
        })

        // manager interface: loadOnce, select, fetch
        this.datamanager = datamanager
    }

    get treeitems() {
        return this._treeitems.map(treeitem => {
            const state =
                treeitem.childcount === 0
                    ? 'empty'
                    : this._expandedIds.includes(treeitem.id)
                    ? 'expanded'
                    : 'collapsed'
            treeitem.isSelected = treeitem.id === this._selectedId
            treeitem.state = state
            return treeitem
        })
    }

    get selectedItem() {
        let result = null
        this._treeitems.forEach(treeitem => {
            if (treeitem.id === this._selectedId) {
                result = treeitem
            }
        })
        return result
    }

    get actionSelection() {
        const selection = this.multiselection.selection
        if (selection.deselected) {
            throw new Error('Select all is not supported for TreeNavigator')
        }
        return [...selection.selected]
    }

    // if you drag to the bottom, you want to insert before the next item
    // if there's no next item, it will be added as last child (before_id = null,
    // same as a drop inside the parentid)
    getNextSibling = item => {
        let next = false
        for (const treeitem of this._treeitems) {
            if (next) {
                if (treeitem.parentid === item.parentid) {
                    return treeitem
                }
                if (treeitem.depth < item.depth) {
                    return null
                }
            }
            if (treeitem.id === item.id) next = true
        }
        return null
    }

    findItemById = id => {
        for (const treeitem of this._treeitems) {
            if (treeitem.id === id) {
                return treeitem
            }
        }
        return null
    }

    hasItemWithParentId = parentid => {
        for (const treeitem of this._treeitems) {
            if (treeitem.parentid === parentid) {
                return true
            }
        }
        return false
    }

    setTreeItems = (treeitems, totalsize) => {
        this._totalsize = totalsize
        this._paths.clear()
        treeitems.forEach(treeitem => {
            this._paths.set(treeitem.id, treeitem.parentid)
        })
        this._treeitems.replace(treeitems)
    }

    inPath = (testitem, pathitem) => {
        let parentid = pathitem.parentid
        while (parentid) {
            if (parentid === testitem.id) return true
            parentid = this._paths.get(parentid)
        }
        return false
    }

    setSelectedId = selectedId => {
        this._selectedId = selectedId
    }

    deselectItem = () => {
        this.datamanager.deselect()
        this.setSelectedId(null)
    }

    selectItem = treeitem => {
        this.datamanager.select(treeitem)
        this.setSelectedId(treeitem ? treeitem.id : null)
    }

    expandItem = treeitem => {
        const newExpandedIds = [...this._expandedIds, treeitem.id]
        return this._setExpandedIds(newExpandedIds)
    }

    collapseItem = treeitem => {
        const newExpandedIds = this._expandedIds.filter(id => id !== treeitem.id)
        return this._setExpandedIds(newExpandedIds)
    }

    _setExpandedIds = newExpandedIds => {
        return this.datamanager.fetch(newExpandedIds).then(result => {
            this._expandedIds.replace(newExpandedIds)
            return result
        })
    }

    createItem = parenttreeitem => {
        return this.datamanager.create(parenttreeitem)
    }

    isExpanded = id => {
        return this._expandedIds.includes(id)
    }

    refetch = (topindex, windowsize) => {
        return this.datamanager.refetch(topindex, windowsize)
    }

    fetch = (topindex, windowsize) => {
        return this.datamanager.fetch(this._expandedIfdds, topindex, windowsize)
    }

    // ResultsStore interface for multiselection

    get full_size() {
        return this._totalsize
    }

    get keys() {
        // this assumes that every treeitem item has a gid, which is always true except
        // for the schema groupings definitions/classes/fields
        return this._treeitems
            .filter(treeitem => {
                return !!treeitem.item.gid
            })
            .map(treeitem => {
                return treeitem.item.gid
            })
    }

    has = key => {
        return this.keys.includes(key)
    }

    range = (anchorKey, chainKey) => {
        // inclusive both ends, chainKey can be before anchorKey, and either can be NULL
        if (anchorKey === null || !this.keys.includes(anchorKey)) return []
        if (chainKey === null || !this.keys.includes(chainKey)) return [anchorKey]
        if (anchorKey === chainKey) return [anchorKey]
        let range = []
        let in_range = false
        for (const key of this.keys) {
            if (!in_range && (key === anchorKey || key === chainKey)) {
                in_range = true
            } else if (in_range && (key === anchorKey || key === chainKey)) {
                range.push(key)
                break
            }
            if (in_range) {
                range.push(key)
            }
        }
        return range
    }

    keyAfter = key => {
        const i = this.keys.indexOf(key)
        if (i === -1) return null
        if (i === this.keys.length - 1) return null
        return this.keys[i + 1]
    }

    keyBefore = key => {
        const i = this.keys.indexOf(key)
        if (i === -1) return null
        if (i === 0) return null
        return this.keys[i - 1]
    }
}

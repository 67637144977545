//
// ChangeProjectIconPanel
//
// drop an image to update the project icon, or delete the project icon

import React, { useState, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'
import { useStore } from '../stores'
import { useFileUpload } from '../hooks/useFileUpload'

import { VView } from '../appview'
import { Header, Menu, MenuItem, ValidationMessage } from '../components'
import { VALIDATION, ValidationResult } from '../utils/validation'

export const ChangeProjectIconPanel = observer(function ChangeProjectIconPanel({
    project,
    onDone,
}) {
    const { app, data } = useStore()

    const FileUpload = useFileUpload()

    const ref = useRef(null)

    const [dropvalidation, setDropvalidation] = useState(null)

    const onFileSelection = file => {
        if (
            file.size &&
            ['image/jpeg', 'image/gif', 'image/png', 'image/svg+xml'].includes(
                file.type
            )
        ) {
            setDropvalidation(new ValidationResult(VALIDATION.WARNING, 'Uploading...'))
            return data.actions
                .upload_project_icon(project['gid'], file)
                .then(projectgid => {
                    setDropvalidation(
                        new ValidationResult(VALIDATION.SUCCESS, 'Icon updated')
                    )
                    onDone && onDone()
                    return projectgid
                })
                .catch(() => {
                    setDropvalidation(
                        new ValidationResult(VALIDATION.ERROR, 'Upload failed')
                    )
                    window.setTimeout(
                        () => setDropvalidation(new ValidationResult(VALIDATION.NA)),
                        500
                    )
                })
        } else {
            setDropvalidation(
                new ValidationResult(VALIDATION.ERROR, 'Unsupported type')
            )
            window.setTimeout(
                () => setDropvalidation(new ValidationResult(VALIDATION.NA)),
                500
            )
        }
    }

    const onClearProjectIcon = () => {
        data.actions
            .delete_project_icon(project['gid'])
            .then(() => {
                onDone && onDone()
            })
            .catch(error => {})
    }

    const canDrop = dragitem => {
        if (!ref.current || !dragitem) {
            return false
        }
        if (dragitem.hasOwnProperty('files')) {
            return true
        }
        return false
    }

    const [{ isOver }, drop] = useDrop({
        accept: NativeTypes.FILE,
        canDrop: dragitem => canDrop(dragitem),
        collect: monitor => {
            const isOver = !!monitor.isOver()
            return {
                isOver: isOver,
            }
        },
        drop: (item, monitor) => {
            onFileSelection(item.files[0])
        },
    })

    drop(ref)

    let dropclassname = 'projecticon-upload'
    if (isOver) dropclassname += ' drag-over-inside'

    return (
        <VView className="actions-panel change-password-panel">
            <div className="inspector">
                <FileUpload.Input onFileChange={onFileSelection} />
                <Header>{app.text('New icon')}</Header>
                <div
                    className={dropclassname}
                    ref={ref}
                    onClick={e => FileUpload.openFileDialog()}
                >
                    <span className="cc-Placeholder">
                        {app.text('Drop an image here to update the icon.')}
                    </span>
                    {dropvalidation ? (
                        <ValidationMessage validation={dropvalidation} />
                    ) : undefined}
                </div>
            </div>
            <Menu>
                <MenuItem disabled={!project.icon} onClick={onClearProjectIcon}>
                    {app.text('Clear current icon')}
                </MenuItem>
            </Menu>
        </VView>
    )
})

//
// BooleanFieldOptions
//
// Field options for type 'boolean'

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'

import { Toggle } from '../../../../components'
import { LabeledField } from '../../../components'

export const BooleanFieldOptions = observer(function BooleanFieldOptions({
    field,
    selectModified,
    worksheet,
    onAction,
    setSelected,
    renderkeyprefix,
}) {
    const { app } = useStore()

    if (!field) return null

    const onToggleField = value => {
        field.setProperty('initial', value, worksheet.language)
        field.commitIfModified().then(result => selectModified(result))
    }

    return (
        <>
            <LabeledField label={app.text('initial value')}>
                <Toggle
                    onChange={onToggleField}
                    value={field.initial.get(worksheet.language)}
                />
            </LabeledField>
        </>
    )
})

//
// ProjectFieldlistsMenu
//
// Actions on project fieldlists in the project settings

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { VView } from '../appview'
import { Header, TextInput, Menu, MenuDivider, MenuItem } from '../components'

const RenameFieldlistMenu = observer(function RenameFieldlistMenu({
    name: current_name,
    onConfirm,
}) {
    const { app } = useStore()

    const [name, setName] = useState(current_name)
    const isValidName = name.trim().length > 0

    const _onConfirm = () => {
        onConfirm && onConfirm(name.trim())
    }
    return (
        <VView className="actions-panel">
            <div className="inspector">
                <Header>{app.text('Rename fieldlist')}</Header>
                <VView
                    style={{
                        marginLeft: 5,
                        marginRight: 5,
                        marginBottom: 5,
                    }}
                >
                    <TextInput onChange={e => setName(e.target.value)} value={name} />
                </VView>
            </div>
            <Menu>
                <MenuItem disabled={!isValidName} onClick={_onConfirm}>
                    {app.text('Rename fieldlist')}
                </MenuItem>
            </Menu>
        </VView>
    )
})

export const ProjectFieldlistsMenu = observer(function ProjectFieldlistsMenu({
    project,
    fieldlistkey,
    onAction,
}) {
    const { app } = useStore()

    const _onAction = (e, action, value) => {
        onAction && onAction(e, fieldlistkey, action, value)
    }

    if (!project) return null
    const fieldlist = project.fieldlists.get(fieldlistkey)
    if (!fieldlist) return null

    const onConfirmRename = value => {
        _onAction(null, 'rename_fieldlist', value.trim())
    }

    // can't delete last remaining fieldlist
    const disableDelete = !fieldlistkey || project.orderedfieldlists.length <= 1

    return (
        <Menu>
            <MenuItem
                submenu={
                    <RenameFieldlistMenu name={fieldlist} onConfirm={onConfirmRename} />
                }
            >
                {app.text('Rename')}
            </MenuItem>
            <MenuDivider />
            <MenuItem
                className="cc-danger"
                disabled={disableDelete}
                onClick={e => _onAction(e, 'delete_fieldlist')}
            >
                {app.text('Delete fieldlist')}
            </MenuItem>
        </Menu>
    )
})

//
// ExcelExportImportPanel
//
// Excel actions on regular records in the TreeNavigator

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Menu, MenuDivider, MenuItem } from '../components'

export const ExcelExportImportMenu = observer(function ExcelExportImportMenu({
    record,
    onAction,
}) {
    const { data, app, view } = useStore()

    const _onAction = action => {
        onAction && onAction(action)
    }

    if (!record) return null

    const definition = data.definitions.get(record.definition)

    if (!definition) return null

    // can't export if there are no children and no child definition(s)
    const disableExport = record.childcount === 0 && !definition.childdefinitions.length
    const disableImport = view.excelimport.visible

    return (
        <Menu>
            <MenuItem
                disabled={disableExport}
                onClick={() => _onAction('export_children_to_excel')}
            >
                {app.text('Export children to Excel')}
            </MenuItem>
            <MenuDivider />
            <MenuItem
                disabled={disableImport}
                onClick={e => {
                    view.excelimport.setVisible(true)
                    _onAction('no-action')
                }}
            >
                {app.text('Show Excel import panel')}
            </MenuItem>
        </Menu>
    )
})

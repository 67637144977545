//
// BusyWorkspace
//
// Show this when we're busy and can't do anything else

import React from 'react'
import { useStore } from '../stores'
import { observer } from 'mobx-react-lite'

import { AboutPanel } from '../panels'
import { VView, HView, Spacer } from '../appview'

export const BusyWorkspace = observer(function BusyWorkspace() {
    const store = useStore()
    const app = store.app

    return (
        <VView grow>
            <VView className="worksheet">
                <HView>
                    <Spacer />
                    <VView className="form">
                        <Spacer size={20} />
                        <AboutPanel
                            version={store.version}
                            message={app.text('Busy... please wait')}
                        />
                    </VView>
                    <Spacer />
                </HView>
            </VView>
        </VView>
    )
})

//
// ListFieldOptions
//
// Field options 'min_items' and 'max_items'

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'

import { TextInput } from '../../../../components'
import { LabeledField } from '../../../components'

import { VALIDATION } from '../../../../utils/validation'
import { duckField, validateField } from '../../../../stores/data/validators'

export const ListFieldOptions = observer(function ListFieldOptions({
    field,
    selectModified,
    worksheet,
    onAction,
    setSelected,
    renderkeyprefix,
}) {
    const { app } = useStore()

    if (!field) return null
    const min_items = field.options.get('min_items')
    const max_items = field.options.get('max_items')

    const onChangeFieldOption = option => {
        return event => {
            field.setOption(option, event.target.value)
        }
    }
    const onBlurFieldOption = (option, validator) => {
        return event => {
            if (validator(event.target.value, field).result === VALIDATION.ERROR) {
                // TODO: CC-204 resetOption
            } else {
                field.setOption(option, event.target.value.trim())
                field.commitIfModified().then(result => selectModified(result))
            }
        }
    }

    const duckMinField = duckField('number', { min: 0, max: max_items }) // min < max
    const minValidator = fieldvalue => {
        return validateField(fieldvalue, duckMinField, worksheet.language)
    }
    const onChangeFieldMin = onChangeFieldOption('min_items')
    const onBlurFieldMin = onBlurFieldOption('min_items', minValidator)

    const duckMaxField = duckField('number', { min: min_items }) // max > min
    const maxValidator = fieldvalue => {
        return validateField(fieldvalue, duckMaxField, worksheet.language)
    }
    const onChangeFieldMax = onChangeFieldOption('max_items')
    const onBlurFieldMax = onBlurFieldOption('max_items', maxValidator)

    return (
        <>
            <LabeledField label={app.text('min items')}>
                <TextInput
                    onChange={onChangeFieldMin}
                    onBlur={onBlurFieldMin}
                    value={min_items}
                    renderkey={renderkeyprefix + '.min_items'}
                    validate={minValidator}
                />
            </LabeledField>
            <LabeledField label={app.text('max items')}>
                <TextInput
                    onChange={onChangeFieldMax}
                    onBlur={onBlurFieldMax}
                    value={max_items}
                    renderkey={renderkeyprefix + '.max_items'}
                    validate={maxValidator}
                />
            </LabeledField>
        </>
    )
})

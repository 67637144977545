//
// RecordInspectorDEBUG
//
// Record DEBUG info

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../stores'

import { Header, Property, Text } from '../../../components'

export const RecordInspectorDEBUG = observer(function RecordInspectorDEBUG({
    worksheet,
    definition,
    layout,
}) {
    const { app, data } = useStore()

    const original_definition = definition.is_extended
        ? data.definitions.get(definition.original)
        : null

    const classes = definition.classes ? definition.classes.slice() : []
    const classdebuginfo = classes.map(classgid => {
        const class_ = worksheet.classes.get(classgid)
        if (!class_) return null
        return (
            <Text key={class_.gid}>
                {class_.name}
                <br />
                {class_.gid}
            </Text>
        )
    })

    return (
        <div className="DEBUG">
            <Header>{app.text('DEBUG info')}</Header>
            <Property label={app.text('parent')}>
                <Text>{worksheet.record.parent}</Text>
            </Property>
            <Property label={app.text('gid')}>
                <Text>{worksheet.record.gid}</Text>
            </Property>
            <Property label={app.text('canonical')}>
                <Text>{worksheet.record.canonical}</Text>
            </Property>
            <Property label={app.text('backlinks')}>
                <Text>{worksheet.record.backlinks}</Text>
            </Property>
            <Property label={app.text('childcount')}>
                <Text>{worksheet.record.childcount}</Text>
            </Property>
            <Property label={app.text('definition')}>
                <Text>
                    {definition.name} {definition.is_extended ? '(extended)' : ''}
                    <br />
                    {definition.gid}
                </Text>
            </Property>
            {original_definition ? (
                <Property label={app.text('original definition')}>
                    <Text>
                        {original_definition.name}
                        <br />
                        {original_definition.gid}
                    </Text>
                </Property>
            ) : undefined}
            <Property label={app.text('classes')}>{classdebuginfo}</Property>
            <Property label={app.text('layout')}>
                <Text>
                    {layout ? layout.name : '[none]'}
                    <br />
                    {layout ? layout.gid : ''}
                </Text>
            </Property>
        </div>
    )
})

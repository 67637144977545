//
// useMousePopover
//
// The Mouse Popover is placed to the right and below a (mouse) position.
// Useful for context menus.
//
// Add a PositionedPopover to your views. Returns an object with a Panel to use in your
// rendering and show/hide functions. It is anchored to the mouse event that triggered
// it (onShow), or to a specific position (showAt)
//
// Usage:
//
//     const UserContextMenu = useMousePopover(UserPanel)
//     return (
//         <VView style={{padding: 20}}>
//             <UserContextMenu.Panel user={store.user} logoutAction={() => { UserContextMenu.hide() }}/>
//             <Bar raised>
//                  <div>
//                     <Button icon onContextMenu={UserContextMenu.onShow}>
//                         <Icon name="user" size={2}/>
//                     </Button>
//                 </div>
//             </Bar>
//         </VView>
//     )

import React, { useRef, useEffect, useLayoutEffect, useCallback } from 'react'
import { observer } from 'mobx-react-lite'

import { Popover, usePopoverContext, PopoverProvider } from '../components'
import { gid } from '../utils/gid'

export const useMousePopover = (PopoverPanel, options = {}) => {
    const keyRef = useRef(gid())
    const popoverContext = usePopoverContext()

    const mounted = useRef(false)
    useEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
    }, [])

    const popoverRef = useRef()
    const [anchorPosition, setAnchorPosition] = React.useState({ x: 0, y: 0 })
    const [visibility, setVisibility] = React.useState(false)
    const { dismissAction = undefined } = options

    const positionPopover = () => {
        if (!popoverRef.current) return

        let popoverBounds = popoverRef.current.getBoundingClientRect()
        const windowBounds = {
            x: 0,
            y: 0,
            width: document.documentElement.clientWidth,
            height: document.documentElement.clientHeight,
        }

        const gap = 5
        const popoverSpace = {
            left: anchorPosition.x - 2 * gap,
            right: windowBounds.width - anchorPosition.x - 2 * gap,
            top: anchorPosition.y - 2 * gap,
            bottom: windowBounds.height - anchorPosition.y - 2 * gap,
        }

        let top = null
        let left = null

        // try to place to the right
        if (popoverBounds.width <= popoverSpace.right) {
            left = anchorPosition.x + gap
        }
        // else, try to place below
        else if (popoverBounds.height <= popoverSpace.bottom) {
            top = anchorPosition.y + gap
        }
        // no space below? try above
        else if (popoverBounds.height <= popoverSpace.top) {
            top = anchorPosition.y - popoverBounds.height - gap
        }
        // failed? place to the right anyway
        else {
            left = anchorPosition.x + gap
        }

        // placed to the right, try to place as much below as possible
        if (top === null) {
            // vertically
            top = anchorPosition.y + gap
            if (top < gap) top = gap
            if (top > windowBounds.height - popoverBounds.height - gap) {
                top = windowBounds.height - popoverBounds.height - gap
            }
        }
        // placed above or below, try to place as much to the right as possible
        else {
            // horizontally
            left = anchorPosition.x + gap
            if (left < gap) left = gap
            if (left > windowBounds.width - popoverBounds.width - gap) {
                left = windowBounds.width - popoverBounds.width - gap
            }
        }

        // apply
        popoverRef.current.style.top = top + 'px'
        popoverRef.current.style.left = left + 'px'
    }

    useLayoutEffect(() => {
        positionPopover()
    })

    const panelRef = useRef()
    const resizeobserver = useRef(null)
    useLayoutEffect(() => {
        if (panelRef.current) {
            resizeobserver.current = new ResizeObserver(entries => {
                // window.requestAnimationFrame tries to prevent
                // 'ResizeObserver loop completed with undelivered notifications.'
                window.requestAnimationFrame(() => {
                    positionPopover() // make sure we don't re-render!
                })
            })
            resizeobserver.current.observe(panelRef.current)
            const element = panelRef.current
            return () => {
                resizeobserver.current.unobserve(element)
            }
        }
    })

    const setVisibilityIfMounted = vis => {
        if (mounted.current) setVisibility(vis)
    }

    const showAt = (x, y) => {
        setAnchorPosition({ x: x, y: y })
        popoverContext.store.show(
            [...popoverContext.stack],
            keyRef.current,
            setVisibilityIfMounted,
            dismissAction
        )
    }
    const hide = useCallback(() => {
        setVisibility(false)
    }, [])

    const isOpen = () => visibility

    const { onClickOutside = hide } = options

    useEffect(() => {
        const handleClickOutside = event => {
            if (
                event.target.className.split &&
                event.target.className.split(' ').includes('cc-FileUpload')
            ) {
                return
            }
            if (popoverRef.current && !popoverRef.current.contains(event.target)) {
                onClickOutside(event)
            }
        }

        document.addEventListener('click', handleClickOutside)
        document.addEventListener('contextmenu', handleClickOutside)
        document.addEventListener('dragstart', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
            document.removeEventListener('contextmenu', handleClickOutside)
            document.removeEventListener('dragstart', handleClickOutside)
        }
    }, [popoverRef, onClickOutside])

    const Panel = observer(function Panel(props) {
        if (!visibility) return null
        const { hidePopover, ...other } = props
        if (hidePopover) return null
        const Panel = (
            <div ref={panelRef}>
                <PopoverPanel {...other} />
            </div>
        )
        if (!Panel) return null

        return (
            <PopoverProvider
                value={{
                    store: popoverContext.store,
                    stack: [...popoverContext.stack, keyRef.current],
                }}
            >
                <Popover className="cc-Mouse-Popover" ref={popoverRef}>
                    {Panel}
                </Popover>
            </PopoverProvider>
        )
    })

    return {
        Panel,
        showAt,
        onShow: e => {
            showAt(e.clientX, e.clientY)
            e.preventDefault()
            e.stopPropagation()
        },
        hide,
        isOpen,
    }
}

//
// DragAssetSearchItem
//
// Drag wrapper for Asset SearchItem

import React, { useState, useRef, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'
import { useDrag, DragPreviewImage } from 'react-dnd'

import { DragTypes } from './DragTypes'
import { selectionsrc } from '../utils/icon'

export const DragAssetSearchItem = observer(function DragAssetSearchItem({
    asset,
    querystore,
    dropData,
    onDrop,
    children,
    ...other
}) {
    const { app } = useStore()
    const ref = useRef(null)

    const multiselect = querystore.multiselection.size > 1
    const dragtype = multiselect ? DragTypes.ASSET_SELECTION : DragTypes.DAM_ASSET

    const [isMousedown, setIsMousedown] = useState(false)

    const [{ isDragging }, drag, preview] = useDrag({
        type: dragtype,
        item: {
            type: dragtype,
            id: asset.gid,
            gid: asset.gid,
            selection: querystore.actionSelection,
            selectionsize: querystore.multiselection.size,
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
        end: (item, monitor) => {
            if (ref.current) {
                setIsMousedown(false)
            }
        },
    })

    const onMouseDown = e => {
        if (e.button === 0) {
            setIsMousedown(true)
        }
    }
    const onMouseUp = e => {
        setIsMousedown(false)
    }

    drag(ref)

    let dragclassname = 'cc-grow'
    if (isMousedown && !multiselect) dragclassname += ' maybe-start-dragging'

    useEffect(() => {
        // set style later - cloned drag preview has original style
        if (ref && ref.current && !multiselect) {
            ref.current.style.opacity = isDragging ? 0.1 : 1
        }
    }, [isDragging, multiselect])

    const Preview = multiselect ? (
        <DragPreviewImage
            connect={preview}
            src={selectionsrc(querystore.multiselection.size + app.text('assets'))}
        />
    ) : undefined

    return (
        <>
            {Preview}
            <div
                ref={ref}
                className={dragclassname}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                {...other}
            >
                {children}
            </div>
        </>
    )
})

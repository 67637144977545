//
// ChangePasswordPanel
//
// Use in a popover for changing password.

import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'
import { useDebounce } from '../hooks/useDebounce'

import { VView } from '../appview'
import { Header, PasswordInput, Text, Menu, MenuItem } from '../components'
import { VALIDATION } from '../utils/validation'
import { validatePassword } from '../stores/data/validators'

export const ChangePasswordPanel = observer(function ChangePasswordPanel({
    onConfirm,
    onCancel,
}) {
    const { app } = useStore()

    const [password, setPassword] = useState('')
    const [isValidNewPassword, setIsValidNewPassword] = useState(false)
    const debouncedPassword = useDebounce(password, 200)

    useEffect(() => {
        let isMounted = true
        // this works for returned Promises and for returned regular values
        new Promise(resolve => resolve(validatePassword(debouncedPassword))).then(
            validationresult => {
                if (isMounted) {
                    setIsValidNewPassword(
                        validationresult.result === VALIDATION.SUCCESS
                    )
                }
            }
        )
        // .catch(error => {})
        return () => (isMounted = false)
    }, [debouncedPassword])

    const _onConfirm = () => {
        onConfirm && onConfirm(password.trim())
    }
    const _onCancel = () => {
        onCancel && onCancel()
    }

    return (
        <VView className="actions-panel change-password-panel">
            <div className="inspector">
                <Header>{app.text('New password')}</Header>
                <VView
                    style={{
                        marginLeft: 5,
                        marginRight: 5,
                        marginBottom: 5,
                    }}
                >
                    <PasswordInput
                        validate={validatePassword}
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                    />
                    <Text className={isValidNewPassword ? '' : 'cc-dimmed'}>
                        {app.text(
                            'Your password will be changed immediately after pressing the Confirm button below.'
                        )}
                    </Text>
                </VView>
            </div>
            <Menu>
                <MenuItem disabled={!isValidNewPassword} onClick={_onConfirm}>
                    {app.text('Confirm password change')}
                </MenuItem>
                <MenuItem onClick={_onCancel}>{app.text('Cancel')}</MenuItem>
            </Menu>
        </VView>
    )
})

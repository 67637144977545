//
// DragRecord
//
// Drag wrapper for Record or a selection of records

import React, { useRef, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useDrag } from 'react-dnd'
import { DragTypes } from './DragTypes'

export const DragRecord = observer(function DragRecord({
    gid,
    record,
    selection,
    selectionsize,
    selectionisflat,
    onDropped,
    children,
}) {
    const ref = useRef(null)

    const dragtype = record ? DragTypes.PIM_RECORD : DragTypes.RECORD_SELECTION

    const [{ isDragging }, drag] = useDrag({
        type: dragtype,
        item: {
            type: dragtype,
            id: record ? record.gid : null,
            gid,
            selection,
            selectionsize,
            isFlat: selectionisflat,
        },
        canDrag: () => dragtype === DragTypes.PIM_RECORD || selectionisflat,
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
        end: (item, monitor) => {
            if (monitor.didDrop(monitor.getDropResult())) {
                onDropped && onDropped(monitor.getDropResult())
            }
        },
    })

    drag(ref)

    useEffect(() => {
        // set style later - cloned drag preview has original style
        if (ref && ref.current) {
            ref.current.style.opacity = isDragging ? 0.1 : 1
        }
    }, [isDragging])

    return <div ref={ref}>{children}</div>
})

//
// AssetCard
//
// A simple asset card, with preview, name and some properties

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'
import { Transform } from '../stores/imageservers/utils'

import { VView } from '../appview'
import { Property, Text } from '../components'
import { AssetThumb, IconTile } from '.'
import { humanReadableBytes, shortDateTime } from '../utils/text'

export const AssetCard = observer(function AssetCard({
    asset,
    className,
    transform,
    iconplaceholder,
    ...other
}) {
    const { app } = useStore()

    let classes = 'cc-AssetCard'
    if (className) classes += ' ' + className

    const assetTransform = transform || new Transform('fit', 200, 200)

    if (!asset) {
        return (
            <IconTile
                className={classes + ' cc-dimmed'}
                width={assetTransform.width}
                icon={iconplaceholder ? iconplaceholder : 'plus'}
                {...other}
            />
        )
    }

    const width = asset.info.get('width')
    const height = asset.info.get('height')
    const filecount = asset.info.get('filecount')
    const foldercount = asset.info.get('foldercount')

    return (
        <VView className={classes} {...other}>
            <div style={{ padding: 5 }}>
                <AssetThumb
                    asset={asset}
                    iconplaceholder={iconplaceholder}
                    transform={assetTransform}
                />
            </div>
            <Property label={app.text('name')}>
                <Text>{asset.name}</Text>
            </Property>
            <Property label={app.text('path')}>
                <Text>{asset.path}</Text>
            </Property>
            {width && height ? (
                <Property label={app.text('dimensions')}>
                    <Text>
                        {width}x{height}
                    </Text>
                </Property>
            ) : undefined}
            {asset.is_file ? (
                <Property label={app.text('filesize')}>
                    <Text>{humanReadableBytes(asset.info.get('filesize'))}</Text>
                </Property>
            ) : (
                <Property label={app.text('contents')}>
                    <Text>
                        {filecount} {app.text(filecount === 1 ? 'file' : 'files')},{' '}
                        {foldercount}{' '}
                        {app.text(foldercount === 1 ? 'folder' : 'folders')}
                    </Text>
                </Property>
            )}
            <Property label={app.text('last update')}>
                <Text>{shortDateTime(new Date(asset.updated_on * 1000))}</Text>
            </Property>
        </VView>
    )
})

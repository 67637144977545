//
// SchemaWorksheet
//
// Switch to a specific worksheet based on worksheet item type; definition, class,
// or field

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../stores'

import { View } from '../../appview'
import { DefinitionData } from '../../stores/data/DefinitionData'
import { ClassData } from '../../stores/data/ClassData'
import { FieldData } from '../../stores/data/FieldData'

import {
    DefinitionDataWorksheet,
    ClassDataWorksheet,
    FieldDataWorksheet,
} from './worksheets'

export const SchemaWorksheet = observer(function SchemaWorksheet({ worksheet }) {
    const { app } = useStore()

    let Worksheet = (
        <View className="worksheet">
            {app.text('No definition, class, or field selected.')}
        </View>
    )
    if (worksheet.item instanceof DefinitionData) {
        Worksheet = <DefinitionDataWorksheet worksheet={worksheet} />
    } else if (worksheet.item instanceof ClassData) {
        Worksheet = <ClassDataWorksheet worksheet={worksheet} />
    } else if (worksheet.item instanceof FieldData) {
        Worksheet = <FieldDataWorksheet worksheet={worksheet} />
    }

    return Worksheet
})

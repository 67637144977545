//
// CreateAPIKeyPanel
//
// Use in a popover for creating a new api key for a project.

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { VView } from '../appview'
import { Header, TextInput, Text, Menu, MenuItem } from '../components'

export const CreateAPIKeyPanel = observer(function CreateAPIKeyPanel({
    onConfirm,
    onCancel,
}) {
    const { app } = useStore()

    const [name, setName] = useState('')
    const isValidName = name.trim().length > 0

    const _onConfirm = () => {
        onConfirm && onConfirm(name.trim())
    }
    const _onCancel = () => {
        onCancel && onCancel()
    }

    return (
        <VView className="actions-panel create-apikey-panel">
            <div className="inspector">
                <Header>{app.text('New API key')}</Header>
                <VView
                    style={{
                        marginLeft: 5,
                        marginRight: 5,
                        marginBottom: 5,
                    }}
                >
                    <TextInput onChange={e => setName(e.target.value)} value={name} />
                    <Text className={isValidName ? '' : 'cc-dimmed'}>
                        {app.text(
                            'Once created, right-click to view the actual API key.'
                        )}
                    </Text>
                </VView>
            </div>
            <Menu>
                <MenuItem disabled={!isValidName} onClick={_onConfirm}>
                    {app.text('Create API key')}
                </MenuItem>
                <MenuItem onClick={_onCancel}>{app.text('Cancel')}</MenuItem>
            </Menu>
        </VView>
    )
})

//
// ClassLayoutBuilder
//
// Based on view info from a layout

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'

import { LayoutBuilder } from '..'

const RecursionMessage = observer(function RecursionMessage({ component }) {
    const { app } = useStore()
    return (
        <pre className="layout-component lc-recursion">
            {app.text('Circular or duplicate layouts detected: {name}', {
                name: component.name,
            })}
        </pre>
    )
})

export const ClassLayoutBuilder = observer(function ClassLayoutBuilder({
    className,
    component,
    components,
    layout,
    record,
    worksheet,
    active_class,
    visited_gids,
}) {
    const { data } = useStore()

    const class_ = data.classes.get(component['class'])
    if (!class_) return null
    const classlayout = data.layouts.get(class_.layout)
    if (!classlayout) return null

    let attributes = { className: 'layout-component' }
    if (className) attributes['className'] += ' ' + className
    if (component['gid'] === worksheet.layoutstore.selectedComponentGid) {
        attributes['className'] += ' cc-selected'
    }
    // attributes["onClick"] = e => onClickElement(e, component, layouttreestore)

    if (classlayout && visited_gids.includes(classlayout.gid)) {
        return <RecursionMessage component={classlayout} />
    }

    const ClassLayout = classlayout ? (
        <LayoutBuilder
            component={classlayout.components.get(classlayout.root)}
            components={classlayout.components}
            layout={layout}
            record={record}
            worksheet={worksheet}
            active_class={class_}
            visited_gids={[
                ...visited_gids,
                component.gid,
                component.original,
                classlayout.gid,
                classlayout.original,
            ]}
        />
    ) : undefined

    return <div {...attributes}>{ClassLayout}</div>
})

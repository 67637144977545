//
// ExcelImportStore
//
// The ExcelImportStore handles interaction with the excelimport area for importing
// Excel files in the Catalog workspace.

import { makeObservable, observable, computed, action } from 'mobx'

class ImportState {
    get is_ready() {
        return this instanceof ReadyState
    }
    get not_excelfile() {
        return this instanceof NotExcelFileState
    }
    get is_processing() {
        return this instanceof ProcessingState
    }
    get is_result() {
        return this instanceof ResultState
    }
}

class ReadyState extends ImportState {}

class NotExcelFileState extends ImportState {
    constructor(file) {
        super()
        this.file = file
    }
}

class ProcessingState extends ImportState {
    constructor(file) {
        super()
        this.file = file
    }
}

class ResultState extends ImportState {
    constructor(result) {
        super()
        this.result = result['state']
        this.reportname = result['report.excel']
        this.dataurl = result['dataurl']
    }
}

export class ExcelImportStore {
    __init = false
    _key = null
    _rootstore = null
    _environment = null
    state = null
    _visible = false

    constructor(key, rootstore, environment) {
        makeObservable(this, {
            __init: observable,
            _key: observable,
            _rootstore: observable,
            _environment: observable,
            state: observable,
            _visible: observable,
            visible: computed,

            setReadyState: action.bound,
            setProcessingState: action.bound,
            setNotExcelFileState: action.bound,
            setResultState: action.bound,

            setVisible: action.bound,
        })

        this._key = key
        this._rootstore = rootstore
        this._environment = environment
        this.setReadyState()
    }

    loadOnce = () => {
        if (this.__init) return
        this._visible = this._rootstore.view.loadPerProject(
            this._key + '/excelImportVisible',
            []
        )
        this.__init = true
    }

    setReadyState = () => {
        this.state = new ReadyState()
    }

    setProcessingState = file => {
        this.state = new ProcessingState(file)
    }

    setNotExcelFileState = file => {
        this.state = new NotExcelFileState(file)
    }

    setResultState = result => {
        this.state = new ResultState(result)
    }

    get visible() {
        return this._visible
    }

    setVisible = visible => {
        this._rootstore.view.savePerProject(this._key + '/excelImportVisible', visible)
        this._visible = visible
    }
}

//
// useClickOutside(ref, action)
//
// If the user clicks outside the ref element, the action is called

import { useRef, useEffect } from 'react'

export const useClickOutside = (ref, action) => {
    const savedAction = useRef(action)

    function handleClickOutside(event) {
        if (
            event.target.className.split &&
            event.target.className.split(' ').includes('cc-FileUpload')
        ) {
            return
        }
        if (ref.current && !ref.current.contains(event.target)) {
            savedAction.current && savedAction.current()
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside)
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    })
}

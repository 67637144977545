//
// InviteMemberPanel
//
// Use in a popover for inviting someone to become a member of a project.

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { VView } from '../appview'
import { Header, TextInput, Text, Menu, MenuItem } from '../components'

export const InviteMemberPanel = observer(function InviteMemberPanel({
    onDone,
    onCancel,
}) {
    const { app, view } = useStore()

    const [name, setName] = useState('')
    const isValidName = name.trim().length > 0

    const [message, setMessage] = useState(null)

    const _onChange = e => {
        setMessage(null)
        setName(e.target.value)
    }

    const _onConfirm = () => {
        view.project
            .inviteMember(name.trim())
            .then(result => {
                onDone && onDone()
            })
            .catch(error => {
                setMessage(
                    app.text(
                        'Could not invite this user by username or email address, please try again.'
                    )
                )
            })
    }

    const _onCancel = () => {
        onCancel && onCancel()
    }

    return (
        <VView className="actions-panel invite-member-panel">
            <div className="inspector">
                <Header>{app.text('Username or email')}</Header>
                <VView
                    style={{
                        marginLeft: 5,
                        marginRight: 5,
                        marginBottom: 5,
                    }}
                >
                    <TextInput onChange={_onChange} value={name} />
                    <Text className={isValidName ? '' : 'cc-dimmed'}>
                        {app.text(
                            'An invite is sent to the potential member by email.'
                        )}
                    </Text>
                    {message ? <Text className="cc-danger">{message}</Text> : undefined}
                </VView>
            </div>
            <Menu>
                <MenuItem disabled={!isValidName} onClick={_onConfirm}>
                    {app.text('Invite user')}
                </MenuItem>
                <MenuItem onClick={_onCancel}>{app.text('Cancel')}</MenuItem>
            </Menu>
        </VView>
    )
})

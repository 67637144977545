//
// Filter Menus
//
// All filter menus and submenus

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { VView, Spacer } from '../appview'
import {
    Header,
    Property,
    Text,
    TextInput,
    Menu,
    MenuHeader,
    MenuItem,
    MenuDivider,
} from '../components'
import { OpenQueryPanel } from '../panels'

export const QuickRecordQueries = [
    { name: 'Any validation', q: '', filter: ['validation', 'any', ''] },
    {
        name: 'Missing translations',
        q: '',
        filter: ['validation', 'not_translated', ''],
    },
    {
        name: 'Updated last week',
        q: '',
        filter: ['updated_on', '<', [7, 'days']],
    },
    {
        name: 'Price range',
        q: '',
        filter: [
            'and',
            '',
            [
                ['fields', 'has', 'price'],
                [
                    'and',
                    '',
                    [
                        ['value', '>=', ['price', 0.0]],
                        ['value', '<=', ['price', 100.0]],
                    ],
                ],
            ],
        ],
    },
    {
        name: 'Mixed children',
        q: '',
        filter: ['childdefinitions', 'mixed_children'],
    },
    {
        name: 'Foreign children',
        q: '',
        filter: ['childdefinitions', 'foreign_children'],
    },
    {
        name: 'Expected children',
        q: '',
        filter: ['childdefinitions', 'expected_children'],
    },
    {
        name: 'Unexpected children',
        q: '',
        filter: ['childdefinitions', 'unexpected_children'],
    },
]

export const QuickAssetQueries = [
    { name: 'Any validation', q: '', filter: ['validation', 'any', ''] },
    {
        name: 'Missing translations',
        q: '',
        filter: ['validation', 'not_translated', ''],
    },
    {
        name: 'Updated last week',
        q: '',
        filter: ['updated_on', '<', [7, 'days']],
    },
    {
        name: 'Lo-res images',
        q: '',
        filter: [
            'and',
            '',
            [
                ['type', 'is', 'image'],
                [
                    'or',
                    '',
                    [
                        ['width', '<=', 600],
                        ['height', '<=', 600],
                    ],
                ],
            ],
        ],
    },
    {
        name: 'Very tall or wide',
        q: '',
        filter: [
            'and',
            '',
            [
                ['type', 'is', 'image'],
                [
                    'or',
                    '',
                    [
                        ['aspect_ratio', '<=', 0.333], // very tall
                        ['aspect_ratio', '>=', 3], // very wide
                    ],
                ],
            ],
        ],
    },
]

export const QuickSchemaQueries = [
    { name: 'Any validation', q: '', filter: ['validation', 'any', ''] },
    {
        name: 'Missing translations',
        q: '',
        filter: ['validation', 'not_translated', ''],
    },
    {
        name: 'Not committed',
        q: '',
        filter: ['and', '', [['is_committed', 'is_false']]],
    },
]

const OpenSubmenu = observer(function OpenSubmenu({ querystore, onAction }) {
    const onOpenQuery = selectedquerygid => {
        onAction && selectedquerygid && onAction('open', selectedquerygid)
    }

    const onCancel = () => {
        onAction && onAction('cancel_open')
    }

    return (
        <OpenQueryPanel
            querystore={querystore}
            selected={false}
            onOpenQuery={onOpenQuery}
            onCancel={onCancel}
        />
    )
})

const SaveSubmenu = observer(function SaveSubmenu({ querystore, onAction }) {
    const { app, data } = useStore()

    const query = querystore.gid ? data.queries.get(querystore.gid) : null
    const [name, setName] = useState(query ? query.name : '')

    const _onAction = (e, action) => {
        querystore.name = name
        onAction && onAction(action)
    }

    const onChangeQueryName = event => {
        setName(event.target.value)
    }
    const onBlurQueryName = event => {
        setName(event.target.value.trim())
    }

    const original_name =
        query && query.name.trim() !== name.trim() ? query.name.trim() : undefined

    const save_label =
        !name || !name.trim().length
            ? app.text('Save')
            : original_name
            ? app.text("Rename to '{name}'", { name: name.trim() })
            : app.text("Save '{name}'", { name: name.trim() })

    const save_copy_label =
        !original_name || !name.trim().length
            ? app.text('Save a copy', { name: name.trim() })
            : app.text("Save a copy as '{name}'", { name: name.trim() })

    return (
        <VView
            className="querymenu-panel"
            onClick={e => e.nativeEvent.stopImmediatePropagation()}
        >
            <div className="inspector">
                <Header>{app.text('Save query')}</Header>
                <Property label={app.text('name')}>
                    <TextInput
                        onChange={onChangeQueryName}
                        onBlur={onBlurQueryName}
                        value={name}
                        renderkey={'.name'}
                    />
                </Property>
                {original_name ? (
                    <Property
                        label={app.text('original')}
                        onClick={e => (querystore.name = original_name)}
                    >
                        <Text>{original_name}</Text>
                    </Property>
                ) : (
                    ''
                )}
                <Spacer size={5} />
            </div>
            <Menu>
                <MenuItem
                    disabled={!name || !name.trim().length}
                    onClick={e => _onAction(e, 'save')}
                >
                    {save_label}
                </MenuItem>
                <MenuItem
                    disabled={!querystore.gid || !original_name || !name.trim().length}
                    onClick={e => _onAction(e, 'save_as_new')}
                >
                    {save_copy_label}
                </MenuItem>
            </Menu>
        </VView>
    )
})

const ConfirmSubmenu = observer(function ConfirmSubmenu({ querystore, onAction }) {
    const { app, data } = useStore()

    const query = querystore.gid ? data.queries.get(querystore.gid) : null

    const _onAction = (e, action) => {
        onAction && onAction('delete')
    }

    if (!query) {
        return null
    }

    return (
        <Menu>
            <MenuItem className="cc-danger" onClick={e => _onAction(e, 'delete')}>
                {app.text("Yes, delete query '{queryname}'", {
                    queryname: query.name,
                })}
            </MenuItem>
        </Menu>
    )
})

const QuickQueriesSubmenu = observer(function QuickQueriesSubmenu({
    quickqueries,
    onAction,
}) {
    const { app } = useStore()

    if (!quickqueries)
        return (
            <Menu>
                <MenuItem onClick={() => onAction && onAction('xxx')}>
                    {app.text('Xxx')}
                </MenuItem>
            </Menu>
        )

    const QuickQueries = quickqueries.map((quickquery, index) => {
        return (
            <MenuItem
                key={index}
                onClick={() =>
                    onAction &&
                    onAction(
                        'quickquery',
                        quickquery.name,
                        quickquery.q,
                        quickquery.filter
                    )
                }
            >
                {app.text(quickquery.name)}
            </MenuItem>
        )
    })

    return <Menu>{QuickQueries}</Menu>
})

export const QueryMenu = observer(function QueryMenu({
    querystore,
    quickqueries,
    onAction,
}) {
    const { app, data } = useStore()

    // New: just clear the current one
    // Load: autocomplete inputbox with load/cancel menuitems
    // Save: name input and 'save' menuitem(s)
    // -> if name unchanged: 'Overwrite'
    // -> if name changed: 'Overwrite' and 'Save as a copy'

    const query = querystore.gid ? data.queries.get(querystore.gid) : null

    return (
        <Menu>
            {query && querystore.is_modified ? (
                <MenuItem onClick={() => onAction && onAction('reopen')}>
                    {app.text("Re-open '{queryname}'", { queryname: query.name })}
                </MenuItem>
            ) : undefined}

            <MenuItem
                submenu={<OpenSubmenu querystore={querystore} onAction={onAction} />}
            >
                {app.text('Open')}
            </MenuItem>
            <MenuItem
                submenu={<SaveSubmenu querystore={querystore} onAction={onAction} />}
            >
                {app.text('Save')}
            </MenuItem>
            <MenuDivider />
            <MenuItem
                disabled={!querystore.gid}
                submenu={<ConfirmSubmenu querystore={querystore} onAction={onAction} />}
            >
                {app.text('Delete')}
            </MenuItem>
            {quickqueries ? <MenuDivider /> : undefined}
            {quickqueries ? (
                <MenuItem
                    submenu={
                        <QuickQueriesSubmenu
                            quickqueries={quickqueries}
                            onAction={onAction}
                        />
                    }
                >
                    {app.text('Quick…')}
                </MenuItem>
            ) : undefined}
        </Menu>
    )
})

export const ModifiedQueryMenu = observer(function ModifiedQueryMenu({
    querystore,
    onAction,
}) {
    const { app } = useStore()

    if (!querystore.is_modified) return null

    return (
        <Menu>
            <MenuItem onClick={() => onAction && onAction('reopen')}>
                {app.text('Revert')}
            </MenuItem>
            <MenuItem
                submenu={<SaveSubmenu querystore={querystore} onAction={onAction} />}
            >
                {app.text('Save')}
            </MenuItem>
        </Menu>
    )
})

const FilterWrapSubmenu = observer(function FilterWrapSubmenu({ onAction }) {
    const { app } = useStore()

    const _onAction = action => {
        onAction && onAction(action)
    }

    return (
        <Menu>
            <MenuItem onClick={() => _onAction('wrap_and')}>
                {app.text('and (…)')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('wrap_or')}>
                {app.text('or (…)')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('wrap_not')}>
                {app.text('not (…)')}
            </MenuItem>
        </Menu>
    )
})

const FilterAddLogicSubmenu = observer(function FilterAddLogicSubmenu({ onAction }) {
    const { app } = useStore()

    const _onAction = (action, p1) => {
        onAction && onAction(action, p1)
    }

    return (
        <Menu>
            <MenuItem onClick={() => _onAction('add_filter', 'and')}>
                {app.text('and (…)')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'or')}>
                {app.text('or (…)')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'not')}>
                {app.text('not (…)')}
            </MenuItem>
        </Menu>
    )
})

export const BooleanAndFilterMenu = observer(function BooleanAndFilterMenu({
    filterargcount,
    onAction,
}) {
    const { app } = useStore()

    const _onAction = action => {
        onAction && onAction(action)
    }

    return (
        <Menu>
            <MenuItem
                onClick={() => _onAction('and_unwrap')}
                disabled={filterargcount > 1}
            >
                {app.text('and ⇒ unwrap (…)')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('and_to_or')}>
                {app.text('and ⇒ or')}
            </MenuItem>
            <MenuItem
                onClick={() => _onAction('and_to_not')}
                disabled={filterargcount > 1}
            >
                {app.text('and ⇒ not')}
            </MenuItem>
            <MenuDivider />
            <MenuItem submenu={<FilterWrapSubmenu onAction={onAction} />}>
                {app.text('wrap')}
            </MenuItem>
            <MenuDivider />
            <MenuItem onClick={() => _onAction('remove_filter')}>
                {app.text('remove filter block')}
            </MenuItem>
        </Menu>
    )
})

export const BooleanOrFilterMenu = observer(function BooleanOrFilterMenu({
    filterargcount,
    onAction,
}) {
    const { app } = useStore()

    const _onAction = action => {
        onAction && onAction(action)
    }

    return (
        <Menu>
            <MenuItem onClick={() => _onAction('or_to_and')}>
                {app.text('or ⇒ and')}
            </MenuItem>
            <MenuItem
                onClick={() => _onAction('or_unwrap')}
                disabled={filterargcount > 1}
            >
                {app.text('or ⇒ unwrap (…)')}
            </MenuItem>
            <MenuItem
                onClick={() => _onAction('or_to_not')}
                disabled={filterargcount > 1}
            >
                {app.text('or ⇒ not')}
            </MenuItem>
            <MenuDivider />
            <MenuItem submenu={<FilterWrapSubmenu onAction={onAction} />}>
                {app.text('wrap')}
            </MenuItem>
            <MenuDivider />
            <MenuItem onClick={() => _onAction('remove_filter')}>
                {app.text('remove filter block')}
            </MenuItem>
        </Menu>
    )
})

export const BooleanNotFilterMenu = observer(function BooleanNotFilterMenu({
    onAction,
}) {
    const { app } = useStore()

    const _onAction = action => {
        onAction && onAction(action)
    }

    return (
        <Menu>
            <MenuItem onClick={() => _onAction('not_to_and')}>
                {app.text('not ⇒ and')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('not_to_or')}>
                {app.text('not ⇒ or')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('not_unwrap')}>
                {app.text('not ⇒ unwrap (…)')}
            </MenuItem>
            <MenuDivider />
            <MenuItem submenu={<FilterWrapSubmenu onAction={onAction} />}>
                {app.text('wrap')}
            </MenuItem>
            <MenuDivider />
            <MenuItem onClick={() => _onAction('remove_filter')}>
                {app.text('remove filter')}
            </MenuItem>
        </Menu>
    )
})

export const FilterMenu = observer(function FilterMenu({ onAction }) {
    const { app } = useStore()

    const _onAction = action => {
        onAction && onAction(action)
    }

    return (
        <Menu>
            <MenuItem submenu={<FilterWrapSubmenu onAction={onAction} />}>
                {app.text('wrap')}
            </MenuItem>
            <MenuDivider />
            <MenuItem onClick={() => _onAction('remove_filter')}>
                {app.text('remove filter')}
            </MenuItem>
        </Menu>
    )
})

export const AddAssetFilterMenu = observer(function AddAssetFilterMenu({ onAction }) {
    const { app } = useStore()

    const _onAction = (action, p1) => {
        onAction && onAction(action, p1)
    }

    return (
        <Menu>
            <MenuHeader>{app.text('Asset fields')}</MenuHeader>
            <MenuItem onClick={() => _onAction('add_filter', 'type')}>
                {app.text('type')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'name')}>
                {app.text('name')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'ext')}>
                {app.text('ext')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'path')}>
                {app.text('path')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'title')}>
                {app.text('title')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'description')}>
                {app.text('description')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'size')}>
                {app.text('size')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'updated_on')}>
                {app.text('updated_on')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'is_deleted')}>
                {app.text('is_deleted')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'validation')}>
                {app.text('validation')}
            </MenuItem>
            <MenuHeader>{app.text('Image asset fields')}</MenuHeader>
            <MenuItem onClick={() => _onAction('add_filter', 'width')}>
                {app.text('width')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'height')}>
                {app.text('height')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'aspect_ratio')}>
                {app.text('aspect ratio')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'is_transparent')}>
                {app.text('is_transparent')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'is_animated')}>
                {app.text('is_animated')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'colorspace')}>
                {app.text('colorspace')}
            </MenuItem>
            <MenuDivider />
            <MenuItem submenu={<FilterAddLogicSubmenu onAction={onAction} />}>
                {app.text('add logic')}
            </MenuItem>
        </Menu>
    )
})

export const AddRecordFilterMenu = observer(function AddRecordFilterMenu({ onAction }) {
    const { app } = useStore()

    const _onAction = (action, p1) => {
        onAction && onAction(action, p1)
    }

    return (
        <Menu>
            <MenuHeader>{app.text('Record fields')}</MenuHeader>
            <MenuItem onClick={() => _onAction('add_filter', 'channels')}>
                {app.text('channels')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'definition')}>
                {app.text('definition')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'classes')}>
                {app.text('classes')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'fields')}>
                {app.text('fields')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'value')}>
                {app.text('field value')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'childdefinitions')}>
                {app.text('childdefinitions')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'updated_on')}>
                {app.text('updated_on')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'is_link')}>
                {app.text('is_link')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'is_deleted')}>
                {app.text('is_deleted')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'validation')}>
                {app.text('validation')}
            </MenuItem>
            <MenuDivider />
            <MenuItem submenu={<FilterAddLogicSubmenu onAction={onAction} />}>
                {app.text('add logic')}
            </MenuItem>
        </Menu>
    )
})

export const AddSchemaFilterMenu = observer(function AddSchemaFilterMenu({ onAction }) {
    const { app } = useStore()

    const _onAction = (action, p1) => {
        onAction && onAction(action, p1)
    }

    return (
        <Menu>
            <MenuHeader>{app.text('Schema fields')}</MenuHeader>
            <MenuItem onClick={() => _onAction('add_filter', 'type')}>
                {app.text('type')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'name')}>
                {app.text('name')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'label')}>
                {app.text('label')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'validation')}>
                {app.text('validation')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'is_committed')}>
                {app.text('is_committed')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'not_committed')}>
                {app.text('not_committed')}
            </MenuItem>
            <MenuHeader>{app.text('Definition type fields')}</MenuHeader>
            <MenuItem onClick={() => _onAction('add_filter', 'classes')}>
                {app.text('classes')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'fields')}>
                {app.text('fields')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'childdefinitions')}>
                {app.text('childdefinitions')}
            </MenuItem>
            <MenuHeader>{app.text('Class type fields')}</MenuHeader>
            <MenuItem onClick={() => _onAction('add_filter', 'fields')}>
                {app.text('fields')}
            </MenuItem>
            <MenuHeader>{app.text('Field type fields')}</MenuHeader>
            <MenuItem onClick={() => _onAction('add_filter', 'unit')}>
                {app.text('unit')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'is_localized')}>
                {app.text('is_localized')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'is_required')}>
                {app.text('is_required')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('add_filter', 'field_type')}>
                {app.text('field type')}
            </MenuItem>
            <MenuDivider />
            <MenuItem submenu={<FilterAddLogicSubmenu onAction={onAction} />}>
                {app.text('add logic')}
            </MenuItem>
        </Menu>
    )
})

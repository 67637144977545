//
// EnvironmentPanel
//
// Select your project environment options here. Think language, channel, ordering,
// and fieldlist.

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { HView } from '../appview'
import { Property, Select } from '../components'

export const EnvironmentPanel = observer(function EnvironmentPanel({
    project,
    environment,
    updateEnvironment,
    languageOnly,
}) {
    const { data, app } = useStore()

    let orderedlanguages = new Map()
    project.languages.map(language =>
        orderedlanguages.set(language, app.text(data.languages.get(language).name))
    )
    let orderedchannels = new Map()
    project.orderedchannels.forEach(key => {
        orderedchannels.set(key, project.channels.get(key))
    })
    let orderedorderings = new Map()
    project.orderedorderings.forEach(key => {
        orderedorderings.set(key, project.orderings.get(key))
    })
    let orderedfieldlists = new Map()
    project.orderedfieldlists.forEach(key => {
        orderedfieldlists.set(key, project.fieldlists.get(key))
    })

    return (
        <HView className="panel environment-panel">
            <Property label={app.text('language')}>
                <Select
                    value={environment.get('language')}
                    onChange={value => updateEnvironment({ language: value })}
                    options={orderedlanguages}
                />
            </Property>
            {languageOnly ? (
                ''
            ) : (
                <>
                    <Property label={app.text('channel')}>
                        <Select
                            value={environment.get('channel')}
                            onChange={value => updateEnvironment({ channel: value })}
                            options={orderedchannels}
                        />
                    </Property>
                    <Property label={app.text('ordering')}>
                        <Select
                            value={environment.get('ordering')}
                            onChange={value => updateEnvironment({ ordering: value })}
                            options={orderedorderings}
                        />
                    </Property>
                    <Property label={app.text('fieldlist')}>
                        <Select
                            value={environment.get('fieldlist')}
                            onChange={value => updateEnvironment({ fieldlist: value })}
                            options={orderedfieldlists}
                        />
                    </Property>
                </>
            )}
        </HView>
    )
})

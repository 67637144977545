//
// TreeNavigator
//
// A Tree view for PIM record hierarchy

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../stores'
import { useMousePopover } from '../../hooks/useMousePopover'
import { usePopoverContext } from '../../components'

import { LazyVScrollList, LazyScrollItem } from '../../appview'
import { TreeItem } from '../../components'
import { maxChars } from '../../utils/text'

import { DragDropTreeItem } from '../../dragdrop/DragDropTreeItem'
import { DragTypes } from '../../dragdrop/DragTypes'
import {
    DropTreeRecordMenu,
    DeletedTreeRecordMenu,
    TreeRecordMenu,
    DeletedLinkedTreeRecordMenu,
    LinkedTreeRecordMenu,
    RecordsContextmenu,
} from '../../menus'
import { keyboard } from '../../utils/keyboard'

export const TreeNavigator = observer(function TreeNavigator({
    tree,
    firstindex: initialfirstindex,
    windowsize: initialwindowsize,
}) {
    const { app, view, data } = useStore()
    const popoverContext = usePopoverContext()

    const itemheight = 19
    const [firstindex, setFirstindex] = useState(initialfirstindex)
    const [windowsize, setWindowsize] = useState(initialwindowsize)

    const onUpdate = (newfirstindex, newwindowsize) => {
        if (newfirstindex !== firstindex || newwindowsize !== windowsize) {
            tree.refetch(newfirstindex, newwindowsize).then(() => {
                setFirstindex(newfirstindex)
                setWindowsize(newwindowsize)
            })
        }
    }

    const totalsize = tree._totalsize
    const selectedRecord = tree.selectedItem ? tree.selectedItem.item : null

    // ContextMenus
    const DropTreeRecordContextMenu = useMousePopover(DropTreeRecordMenu, {
        onClickOutside: e => {
            dropDone()
            DropTreeRecordContextMenu.hide(e)
        },
    })
    const TreeRecordContextMenu = useMousePopover(TreeRecordMenu)
    const LinkedTreeRecordContextMenu = useMousePopover(LinkedTreeRecordMenu)
    const DeletedTreeRecordContextMenu = useMousePopover(DeletedTreeRecordMenu)
    const DeletedLinkedTreeRecordContextMenu = useMousePopover(
        DeletedLinkedTreeRecordMenu
    )
    const RecordsContextmenuPopover = useMousePopover(RecordsContextmenu)

    // we want the action-popover to be anchored to the mouseposition on drop
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })
    const onDraggingMousePosition = newMousePosition => {
        setMousePosition(newMousePosition)
    }

    const [dropData, setDropdata] = useState({
        dragitem: null,
        dropitem: null,
        dropzone: null,
    })

    const dropDone = () => {
        setDropdata({ dragitem: null, dropitem: null, dropzone: null })
        setMousePosition({ x: 0, y: 0 })
    }

    const dropids = dropData => {
        const { dragitem, dropitem, dropzone } = dropData
        const parentid = dropzone === 'inside' ? dropitem.id : dropitem.parentid
        let before_id = null
        if (dropzone === 'top') {
            before_id = dropitem.id
        } else if (dropzone === 'bottom') {
            // if you drag to the bottom, you want to insert before the next item
            // if there's no next item, it will be added as last child (before_id = null,
            // same as a drop inside the parentid)
            const nextitem = tree.getNextSibling(dropitem)
            if (nextitem) {
                before_id = nextitem.id
            }
        }
        return [dragitem, parentid, before_id]
    }

    const onDropMove = () => {
        const [dragitem, parentid, before_id] = dropids(dropData)
        dropDone()
        if (dragitem.type === DragTypes.PIM_RECORD) {
            data.actions.records
                .move(dragitem.id, parentid, before_id)
                .then(() => {
                    tree.refetch()
                    view.pimworksheet.refetch()
                })
                .catch(error => {})
        } else if (dragitem.type === DragTypes.RECORD_SELECTION) {
            data.actions.records
                .bulkMove(dragitem.selection, parentid, before_id)
                .catch(error => {})
        }
    }

    const onDropCopy = () => {
        const [dragitem, parentid, before_id] = dropids(dropData)
        dropDone()
        if (dragitem.type === DragTypes.PIM_RECORD) {
            data.actions.records
                .copy(dragitem.id, parentid, before_id)
                .then(() => {
                    tree.refetch()
                    view.pimworksheet.refetch()
                })
                .catch(error => {})
        } else if (dragitem.type === DragTypes.RECORD_SELECTION) {
            data.actions.records
                .bulkCopy(dragitem.selection, parentid, before_id)
                .catch(error => {})
        }
    }

    const onDropShallowCopy = () => {
        const [dragitem, parentid, before_id] = dropids(dropData)
        dropDone()
        if (dragitem.type === DragTypes.PIM_RECORD) {
            data.actions.records
                .shallow_copy(dragitem.id, parentid, before_id)
                .then(() => {
                    tree.refetch()
                    view.pimworksheet.refetch()
                })
                .catch(error => {})
        } else if (dragitem.type === DragTypes.RECORD_SELECTION) {
            data.actions.records
                .bulkShallowCopy(dragitem.selection, parentid, before_id)
                .catch(error => {})
        }
    }

    const onDropLink = () => {
        const [dragitem, parentid, before_id] = dropids(dropData)
        dropDone()
        if (dragitem.type === DragTypes.PIM_RECORD) {
            data.actions.records
                .link(dragitem.id, parentid, before_id)
                .then(() => {
                    tree.refetch()
                    view.pimworksheet.refetch()
                })
                .catch(error => {})
        } else if (dragitem.type === DragTypes.RECORD_SELECTION) {
            data.actions.records
                .bulkLink(dragitem.selection, parentid, before_id)
                .catch(error => {})
        }
    }

    const onDropCancel = () => {
        dropDone()
    }

    const onDrop = (dragitem, dropitem, dropzone) => {
        setDropdata({
            dragitem: dragitem,
            dropitem: dropitem,
            dropzone: dropzone,
        })
        DropTreeRecordContextMenu.showAt(mousePosition.x, mousePosition.y)
    }

    const onDropAction = action => {
        popoverContext.store.show([...popoverContext.stack])
        const actions = {
            move: onDropMove,
            copy: onDropCopy,
            shallow_copy: onDropShallowCopy,
            link: onDropLink,
            cancel: onDropCancel,
        }
        if (!(action in actions)) {
            console.log(`onDropAction: unhandled action '${action}'`)
            return
        }
        actions[action]()
    }

    const onCreateNested = () => {
        tree.createItem(tree.selectedItem)
    }

    const onDuplicate = () => {
        const item = tree.selectedItem
        data.actions.records
            .duplicate(item.id)
            .then(result => {
                tree.refetch()
                view.pimworksheet.refetch()
            })
            .catch(error => {})
    }

    const onGotoLinkedRecord = () => {
        return data.fetchRecordsByGid([selectedRecord.canonical]).then(result => {
            if (result.length) {
                tree.selectItem({ id: result[0].id, item: result[0] })
            }
        })
    }

    const onUnlinkRecord = () => {
        selectedRecord.unlink()
    }

    const onDelete = () => {
        selectedRecord.delete().then(() => {
            tree.refetch()
        })
    }

    const onUndelete = () => {
        selectedRecord.undelete().then(() => {
            tree.refetch()
        })
    }

    const onPermanentDelete = () => {
        if (selectedRecord && view.pimpinboard.exists(selectedRecord.gid)) {
            view.pimpinboard.remove(selectedRecord.gid)
        }
        const parentrecord = view.pimworksheet.path[view.pimworksheet.path.length - 2]
        selectedRecord.permanentDelete().then(successdata => {
            view.pimworksheet.setRecord(parentrecord)
            tree.refetch()
        })
    }

    const onExportChildrenToExcel = () => {
        data.actions.export_children_to_excel(selectedRecord.gid)
    }

    const onDuplicateRecordsSelection = () => {
        if (!tree.multiselection.size) return
        data.actions.records.bulkDuplicate(tree.actionSelection)
    }

    const onDeleteRecordsSelection = () => {
        if (!tree.multiselection.size) return
        data.actions.records.bulkDelete(tree.actionSelection)
    }

    const onUndeleteRecordsSelection = () => {
        if (!tree.multiselection.size) return
        data.actions.records.bulkUndelete(tree.actionSelection)
    }

    const onPermanentDeleteRecordsSelection = () => {
        if (!tree.multiselection.size) return
        data.actions.records.bulkPermanentDelete(tree.actionSelection)
    }

    const onTreeRecordAction = action => {
        popoverContext.store.show([...popoverContext.stack])
        const actions = {
            create_nested: onCreateNested,
            duplicate: onDuplicate,
            goto_linked_record: onGotoLinkedRecord,
            unlink_record: onUnlinkRecord,
            delete: onDelete,
            undelete: onUndelete,
            permanent_delete: onPermanentDelete,
            export_children_to_excel: onExportChildrenToExcel,
            duplicate_records_selection: onDuplicateRecordsSelection,
            delete_records_selection: onDeleteRecordsSelection,
            undelete_records_selection: onUndeleteRecordsSelection,
            permanent_delete_records_selection: onPermanentDeleteRecordsSelection,
            'no-action': () => {},
        }
        if (!(action in actions)) {
            console.log(
                `onTreeRecordAction: unhandled action '${action}' on '${tree.selectedItem.item.title}'`
            )
            return
        }
        actions[action]()
    }

    const onClickTreeItem = (event, treeitem) => {
        dropDone()
        popoverContext.store.show([...popoverContext.stack])
        if (keyboard.testCommand(event)) {
            if (tree.multiselection.isSelected(treeitem.item.gid)) {
                tree.multiselection.deselect(treeitem.item.gid)
            } else {
                tree.multiselection.addRangeStart(treeitem.item.gid)
            }
        } else if (keyboard.testShift(event)) {
            tree.multiselection.rangeEnd(treeitem.item.gid)
        } else {
            tree.multiselection.rangeStart(treeitem.item.gid)
            tree.selectItem(treeitem)
            if (!tree.multiselection.isSelected(treeitem.item.gid)) {
                tree.multiselection.rangeStart(treeitem.item.gid)
            }
        }
    }

    const TreeItems = tree.treeitems.map((treeitem, index) => {
        const record = treeitem.item
        const name =
            record.localized_title && record.localized_title.length
                ? record.localized_title
                : app.text('[empty]')
        let classes = 'status-' + record.status
        if (record.channels.includes(view.environment.get('channel')) === false) {
            classes += ' channel-off'
        }
        if (tree.multiselection.isSelected(treeitem.item.gid) && !treeitem.isSelected) {
            classes += ' cc-multiselected'
        }
        let selected = treeitem.isSelected
        if (
            tree.multiselection.size > 0 &&
            selected &&
            !tree.multiselection.isSelected(record.gid)
        ) {
            selected = false
        }
        const contextmenu =
            tree.multiselection.size > 1 &&
            tree.multiselection.isSelected(treeitem.item.gid)
                ? RecordsContextmenuPopover
                : record.status !== 'deleted'
                ? record.is_link
                    ? LinkedTreeRecordContextMenu
                    : TreeRecordContextMenu
                : record.is_link
                ? DeletedLinkedTreeRecordContextMenu
                : DeletedTreeRecordContextMenu
        return (
            <LazyScrollItem key={treeitem.id} index={index + firstindex}>
                <DragDropTreeItem
                    tree={tree}
                    treeitem={treeitem}
                    dropData={dropData}
                    onDraggingMousePosition={onDraggingMousePosition}
                    onDrop={onDrop}
                >
                    <TreeItem
                        state={treeitem.state}
                        emptyicon={record.is_link ? 'link' : 'empty'}
                        selected={selected}
                        indent={treeitem.depth}
                        className={classes}
                        onClick={event => onClickTreeItem(event, treeitem)}
                        onExpand={() => {
                            popoverContext.store.show([...popoverContext.stack])
                            tree.expandItem(treeitem)
                        }}
                        onCollapse={() => {
                            popoverContext.store.show([...popoverContext.stack])
                            tree.collapseItem(treeitem)
                        }}
                        onContextMenu={e => {
                            if (!tree.multiselection.isSelected(treeitem.item.gid)) {
                                tree.multiselection.deselectAll()
                                tree.selectItem(treeitem)
                            }
                            contextmenu.onShow(e)
                        }}
                    >
                        {maxChars(name, 40)}
                    </TreeItem>
                </DragDropTreeItem>
            </LazyScrollItem>
        )
    })

    return (
        <>
            <RecordsContextmenuPopover.Panel
                record={selectedRecord}
                recordsselection={tree.multiselection}
                onAction={onTreeRecordAction}
            />
            <DropTreeRecordContextMenu.Panel onAction={onDropAction} />
            <TreeRecordContextMenu.Panel
                record={selectedRecord}
                onAction={onTreeRecordAction}
            />
            <LinkedTreeRecordContextMenu.Panel
                record={selectedRecord}
                onAction={onTreeRecordAction}
            />
            <DeletedTreeRecordContextMenu.Panel
                record={selectedRecord}
                onAction={onTreeRecordAction}
            />
            <DeletedLinkedTreeRecordContextMenu.Panel
                record={selectedRecord}
                onAction={onTreeRecordAction}
            />
            <LazyVScrollList
                className="cc-Tree"
                firstindex={firstindex}
                itemheight={itemheight}
                totalitems={totalsize}
                onUpdate={onUpdate}
            >
                {TreeItems}
            </LazyVScrollList>
        </>
    )
})

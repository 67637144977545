//
// FieldpickerFieldMenu
//
// Actions on a fieldpicker-input

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { View, VView } from '../appview'
import { Menu, MenuDivider, MenuItem } from '../components'
import { RecordDefinitionAddFieldPanel } from '../panels'

export const FieldpickerFieldMenu = observer(
    React.forwardRef(function FieldpickerFieldMenu(
        { linkedfield, fromFields, onAction, ...other },
        ref
    ) {
        const { app } = useStore()

        const _onAction = (action, otherfieldgid) => {
            if (!otherfieldgid) {
                onAction && onAction(action, linkedfield)
            } else {
                onAction && onAction(action, linkedfield, otherfieldgid)
            }
        }

        const onSelectAdd = fieldgid => {
            _onAction('add_field', fieldgid)
        }

        const onSelectReplace = fieldgid => {
            _onAction('replace_field', fieldgid)
        }

        const FieldpickerSearchReplaceSubmenu = (
            <RecordDefinitionAddFieldPanel
                fromFields={fromFields}
                onAddField={onSelectReplace}
                subselect
            />
        )

        if (!linkedfield) {
            return (
                <View
                    ref={ref}
                    onClick={e => e.nativeEvent.stopImmediatePropagation()}
                    {...other}
                >
                    <RecordDefinitionAddFieldPanel
                        fromFields={fromFields}
                        onAddField={onSelectAdd}
                        subselect
                    />
                </View>
            )
        }

        return (
            <VView
                ref={ref}
                className="fieldpicker-field-menu"
                onClick={e => e.nativeEvent.stopImmediatePropagation()}
                {...other}
            >
                <Menu>
                    <MenuItem submenu={FieldpickerSearchReplaceSubmenu}>
                        {app.text('Replace')}
                    </MenuItem>
                    <MenuItem
                        className="cc-danger"
                        onClick={() => _onAction('remove_field')}
                    >
                        {app.text('Remove')}
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem onClick={() => _onAction('reveal_in_schema')}>
                        {app.text('Reveal in Schema workspace')}
                    </MenuItem>
                </Menu>
            </VView>
        )
    })
)

// SearchInput
//
// TextInput with search icon.

import React from 'react'

import { Icon, TextInput } from '.'

export const SearchInput = props => {
    const {
        value,
        language,
        children,
        onChange,
        onBlur,
        onIconClick,
        icontitle,
        renderkey,
        ...other // placeholder, enabled, etc (see TextInput)
    } = props

    const _onChange = event => onChange && onChange(event)
    const _onBlur = event => onBlur && onBlur(event)
    const _onIconClick = event => onIconClick && onIconClick(event)

    return (
        <div className="cc-SearchInput">
            <TextInput
                value={value}
                language={language}
                multiline={false}
                onChange={_onChange}
                onBlur={_onBlur}
                renderkey={renderkey}
                {...other}
            />
            <Icon
                name="search"
                size={'text'}
                title={icontitle}
                onClick={_onIconClick}
            />
        </div>
    )
}

//
// RecordDefinitionAddFieldPanel
//
// Add a field to a definition (or class), by searching for a field.

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { VView, VScrollView, Spacer } from '../appview'
import {
    Header,
    SearchInput,
    Item,
    Property,
    Text,
    Menu,
    MenuItem,
} from '../components'
import { normalize } from '../utils/text'
import { fieldtitle } from '../stores/data/utils'
import { ClassFieldSubmenu } from '../menus'

export const RecordDefinitionAddFieldPanel = observer(
    function RecordDefinitionAddFieldPanel({ fromFields, onAddField, subselect }) {
        const { app, data, view } = useStore()
        const worksheet = view.pimworksheet
        const language = worksheet.environment.get('language')

        const [searchtext, setSearchtext] = useState('')
        const [selecteditem, setSelecteditem] = useState(null)
        let selection_in_results = false // initially

        let searchresultfields = fromFields
            ? Array.from(fromFields.entries())
            : Array.from(data.fields.entries())
        searchresultfields = searchresultfields.filter(
            ([key, field]) => !field.is_working_copy
        )

        if (searchtext.length) {
            const searchwords = normalize(searchtext).split(' ')
            searchresultfields = searchresultfields.filter(([key, field]) => {
                // do a match: in label and name, label only current language
                // first, normalize (validName)
                // second, split on spaces
                // then, match all words
                let fieldlabel = field.label.get(language)
                if (!fieldlabel || !fieldlabel.length) {
                    fieldlabel = field.name
                }
                fieldlabel = normalize(fieldlabel)
                for (const word of searchwords) {
                    if (!word.length) continue
                    if (!fieldlabel.includes(word) && !field.name.includes(word)) {
                        return false
                    }
                }
                return true
            })
        }

        searchresultfields.sort((a, b) => {
            const afield = a[1]
            const bfield = b[1]
            let alabel = afield.label.get(language)
            if (!alabel || !alabel.length) {
                alabel = afield.name
            }
            alabel = normalize(alabel)
            let blabel = bfield.label.get(language)
            if (!blabel || !blabel.length) {
                blabel = bfield.name
            }
            blabel = normalize(blabel)
            if (alabel === blabel) return 0
            return alabel > blabel ? 1 : -1
        })

        const onSearchinputChange = event => {
            setSearchtext(event.target.value)
        }
        const onSearchinputBlur = event => {
            setSearchtext(event.target.value.trim())
        }

        const _onAddField = () => {
            onAddField && selecteditem && onAddField(selecteditem)
        }

        const onSelectClassField = dottedfield => {
            onAddField && selecteditem && onAddField(dottedfield)
        }

        const searchresults = searchresultfields.length ? (
            searchresultfields.map(([key, field]) => {
                let fieldname = fieldtitle(field, language)
                if (selecteditem === key) selection_in_results = true
                return (
                    <Item
                        key={key}
                        selected={selecteditem === key}
                        onClick={() => setSelecteditem(key)}
                        onDoubleClick={() => {
                            setSelecteditem(key)
                            _onAddField()
                        }}
                    >
                        {fieldname}
                    </Item>
                )
            })
        ) : (
            <div style={{ padding: '2px 5px' }}>{app.text('No matches')}</div>
        )

        const selectedfield = selection_in_results
            ? data.fields.get(selecteditem)
            : null
        let selectedfield_info
        if (selectedfield) {
            let fieldname = selectedfield.label.get(language)
            if (!fieldname || !fieldname.length) {
                fieldname = selectedfield.name
            }
            const unitproperty = selectedfield.unit ? (
                <Property label={app.text('unit')}>
                    <Text className="user-select">{selectedfield.unit}</Text>
                </Property>
            ) : undefined
            selectedfield_info = (
                <>
                    <Property className="nobreak" label={app.text('name')}>
                        <Text className="user-select">{selectedfield.name}</Text>
                    </Property>
                    <Property label={app.text('label')}>
                        <Text className="user-select">{fieldname}</Text>
                    </Property>
                    {unitproperty}
                </>
            )
        }

        const classfieldsubmenu =
            selectedfield &&
            subselect &&
            ['class', 'classlist'].includes(selectedfield.type) ? (
                <ClassFieldSubmenu
                    language={language}
                    prefix={selectedfield.gid}
                    classfield={selectedfield}
                    onSelectClassField={onSelectClassField}
                />
            ) : undefined

        return (
            <VView className="actions-panel recorddefinition-add-field-panel">
                <div className="inspector">
                    <Header>{app.text('Add field')}</Header>
                    <VView
                        style={{
                            marginLeft: 5,
                            marginRight: 5,
                            marginBottom: 2,
                        }}
                    >
                        <SearchInput
                            value={searchtext}
                            placeholder={app.text('Search by name or label')}
                            onChange={onSearchinputChange}
                            onBlur={onSearchinputBlur}
                        />
                        <VScrollView
                            className="search-results"
                            style={{ backgroundColor: 'white' }}
                        >
                            {searchresults}
                        </VScrollView>
                        <Spacer size={5} />
                        {selectedfield_info}
                    </VView>
                </div>
                <Menu>
                    <MenuItem
                        submenu={classfieldsubmenu}
                        disabled={!selection_in_results}
                        onClick={_onAddField}
                    >
                        {selectedfield
                            ? app.text("Add field '{fieldname}'", {
                                  fieldname: fieldtitle(selectedfield, language),
                              })
                            : app.text('Add field')}
                    </MenuItem>
                </Menu>
            </VView>
        )
    }
)

//
// View, HView, VView
//
// A View is only for show, when you need a container that doesn't do anything but hold
// its children.
//
// A HView takes up the available width and lays out its children horizonally, starting
// on the left (or right in RTL setting). Each child gets its intrinsic size, and the
// HView takes on the height of the tallest child.
//
// A VView is like a HView, but takes up the available height, lays its children out
// vertically, and takes on the width of the widest child.
//
// All three Views can take a `grow` property if they're inside another container view
// and must take on the available height/width instead of using the height/width of the
// largest child. The `grow` property can take a value which is used as its weight, in
// case there are other Spacer/`grow`-ing siblings inside the parent container.
//
// You can specify a `gap` between children.

import React from 'react'

export const View = React.forwardRef(function View(props, ref) {
    const { grow, fill, center, vcenter, hcenter, className, gap, style, ...other } =
        props
    let classes = 'av-View'
    let styles = {}
    if (grow) {
        classes += ' av-Spacer'
        if (grow !== true) {
            styles = { flexGrow: grow }
        }
    }
    if (gap) {
        styles['gap'] = gap
    }
    if (fill) classes += ' av-fill'
    if (center) classes += ' av-vcenter av-hcenter'
    else if (vcenter) classes += ' av-vcenter'
    else if (hcenter) classes += ' av-hcenter'
    if (className) classes += ' ' + className
    if (style) styles = Object.assign({}, styles, style)
    return <div ref={ref} className={classes} style={styles} {...other} />
})

export const HView = React.forwardRef(function HView(props, ref) {
    const { grow, wrap, vcenter, className, gap, style, ...other } = props
    let classes = 'av-HView'
    let styles = {}
    if (grow) {
        classes += ' av-Spacer'
        if (grow !== true) {
            styles = { flexGrow: grow }
        }
    }
    if (gap) {
        styles['gap'] = gap
    }
    if (wrap) classes += ' av-wrap'
    if (vcenter) classes += ' av-vcenter'
    if (className) classes += ' ' + className
    if (style) styles = Object.assign({}, styles, style)
    return <div ref={ref} className={classes} style={styles} {...other} />
})

export const VView = React.forwardRef(function VView(props, ref) {
    const { grow, wrap, hcenter, className, gap, style, ...other } = props
    let classes = 'av-VView'
    let styles = {}
    if (grow) {
        classes += ' av-Spacer'
        if (grow !== true) {
            styles = { flexGrow: grow }
        }
    }
    if (gap) {
        styles['gap'] = gap
    }
    if (wrap) classes += ' av-wrap'
    if (hcenter) classes += ' av-hcenter'
    if (className) classes += ' ' + className
    if (style) styles = Object.assign({}, styles, style)
    return <div ref={ref} className={classes} style={styles} {...other} />
})

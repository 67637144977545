//
// DragTypes
//
// All available drag types

export const DragTypes = {
    UNDEFINED: 'undefined',
    LANGUAGE: 'language',
    CHANNEL: 'channel',
    ORDERING: 'ordering',
    FIELDLIST: 'fieldlist',
    PIM_RECORD: 'pim_record',
    PIM_DEFINITION: 'pim_definition',
    PIM_CLASS: 'pim_class',
    PIM_FIELD: 'pim_field',
    DAM_ASSET: 'dam_asset',
    LIST_ITEM: 'list_item',
    CLASSLISTFIELD_ITEM: 'classlistfield_item',
    LAYOUT_COMPONENT: 'layout_component',
    SEARCH_FILTER: 'search_filter',
    TEXTLINE: 'textline',
    ASSET_SELECTION: 'asset_selection',
    RECORD_SELECTION: 'record_selection',
    SCHEMA_SELECTION: 'schema_selection',
}

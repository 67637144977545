//
// DragAsset
//
// Drag wrapper for Asset or a selection of assets

import React, { useRef, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useDrag } from 'react-dnd'
import { DragTypes } from './DragTypes'

export const DragAsset = observer(function DragAsset({
    gid,
    asset,
    selection,
    selectionsize,
    onDropped,
    children,
}) {
    const ref = useRef(null)

    const dragtype = asset ? DragTypes.DAM_ASSET : DragTypes.ASSET_SELECTION

    const [{ isDragging }, drag] = useDrag({
        type: dragtype,
        item: {
            type: dragtype,
            id: asset ? asset.pathname : null,
            gid,
            selection,
            selectionsize,
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
        end: (item, monitor) => {
            if (monitor.didDrop(monitor.getDropResult())) {
                onDropped && onDropped(monitor.getDropResult())
            }
        },
    })

    drag(ref)

    useEffect(() => {
        // set style later - cloned drag preview has original style
        if (ref && ref.current) {
            ref.current.style.opacity = isDragging ? 0.1 : 1
        }
    }, [isDragging])

    return <div ref={ref}>{children}</div>
})

// Validation is used to validate input fields or whole forms.
//
// isValid* function should return true or false
// validate* functions should return a ValidationResult
//
// e.g.
//
// const isValidPassword = text => {
//     // make sure we are working with text
//     text = text.toString().trim()
//     // a valid password should be long, but not too long
//     return text.length >= 10 && text.length <= 100
// }
//
// and
//
// const validatePassword = async value => {
//     // only validate if we have a value
//     if (value === null || value === undefined || value.toString().trim().length === 0) {
//         return new ValidationResult(VALIDATION.NA)
//     }
//     if (!await isValidPassword(value)) {
//         return new ValidationResult(
//             VALIDATION.ERROR,
//             'Password length must be between 10 and 100 characters'
//         )
//     }
//     return new ValidationResult(VALIDATION.SUCCESS)
// }
//
// This file contains the base ValidationResult class and VALIDATION enum, as well as a
// number of isValid functions you can use to build your own validate functions.
//
import { maybe_gid } from './gid'
import {
    calculateEAN8Checksum,
    calculateEAN13Checksum,
    calculateUPCAChecksum,
    isValidCode128CharacterSet,
    isValidCode39CharacterSet,
    isValidInterleaved2Of5CharacterSet,
    isValidPdf417TextCharacterSet,
    isValidDataMatrixCharacterSet,
    isValidQRCharacterSet,
} from './barcode'

export const VALIDATION = {
    SUCCESS: 0,
    REPORT: 1,
    ERROR: 2,
    NA: 3, // not applicable; NULL value, or unknown field type
}

export class ValidationResult {
    constructor(result, message_key = '', message_replacements = {}) {
        this.result = result
        this._message_key = message_key // message should be shown with validationresult.text(app)
        this._message_replacements = message_replacements
    }

    text = app => {
        if (!this._message_key.length) return ''
        return app.text(this._message_key, this._message_replacements)
    }
}

// isValid functions

export const isValidText = text => {
    text = text.toString().trim() // just in case
    // a valid text is basically any input
    return true
}

export const isValidTextline = text => {
    text = text.toString().trim() // just in case
    // a valid textline shouldn't have any newlines, but is allowed one trailing newline
    if (text.match(/\n/)) {
        return false
    }
    return isValidText(text)
}

export const isValidTextlist = textlist => {
    if (!Array.isArray(textlist)) {
        return false
    }
    return textlist.every(line => isValidText(line))
}

export const isValidUsername = text => {
    text = text.toString().trim() // just in case
    // a valid username should be between 2 and 100 characters and not contain an @ sign
    if (text.length < 2 || text.length > 100) {
        return false
    }
    if (text.includes('@') || text.includes('＠') || text.includes('﹫')) {
        return false
    }
    return true
}

export const isValidEmail = text => {
    text = text.toString().trim() // just in case
    // a valid email should satisfy this regex
    // for emails, we look at everything in lowercase
    const email_regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return email_regex.test(text.toLowerCase())
}

export const isValidPassword = text => {
    text = text.toString().trim() // just in case
    // a valid password should be long, but not too long
    return text.length >= 10 && text.length <= 100
}

export const isValidCode = text => {
    text = text.toString().trim() // just in case
    // a valid code is any valid text
    // in the future, we may add validators for specific code formats, e.g.
    // if/when we can specify json/xml/yaml or some custom format
    return isValidText(text)
}

export const isValidBarcode = (text, options) => {
    text = text.toString().trim() // just in case
    // a valid barcode should have a format option that specifies the barcode type
    // we support EAN13, <no others yet>
    const barcodevalidators = {
        'CODE-39': isValidBarcodeCode39,
        'CODE-128': isValidBarcodeCode128,
        DATAMATRIX: isValidBarcodeDataMatrix,
        'EAN-8': isValidBarcodeEAN8,
        'EAN-13': isValidBarcodeEAN13,
        I2OF5: isValidBarcodeInterleaved2Of5,
        'PDF417-TEXT': isValidBarcodePdf417Text,
        QR: isValidBarcodeQR,
        'UPC-A': isValidBarcodeUPCA,
    }
    const format = options.get('format')
    if (!(format in barcodevalidators)) {
        return false
    }
    return barcodevalidators[format](text)
}

const isValidBarcodeEAN8 = text => {
    text = text.toString().trim() // just in case
    // barcode, type EAN8
    // The text is the barcode - including the checksum digit
    // First we check for digit-only and length 8
    if (!text.match(/^\d{8}$/)) {
        return false
    }
    // We should verify the checksum digit for correctness
    // this is the last entry from the barcode
    // the first 7 positions are the actual barcode
    const checksumdigit = text.slice(-1)
    const barcode7 = text.slice(0, -1)
    const checksum = calculateEAN8Checksum(barcode7)
    return checksum === parseInt(checksumdigit, 10)
}

const isValidBarcodeEAN13 = text => {
    text = text.toString().trim() // just in case
    // barcode, type EAN13
    // The text is the barcode - including the checksum digit
    // First we check for digit-only and length 13
    if (!text.match(/^\d{13}$/)) {
        return false
    }
    // We should verify the checksum digit for correctness
    // this is the last entry from the barcode
    // the first 12 positions are the actual barcode
    const checksumdigit = text.slice(-1)
    const barcode12 = text.slice(0, -1)
    const checksum = calculateEAN13Checksum(barcode12)
    return checksum === parseInt(checksumdigit, 10)
}

const isValidBarcodeCode128 = text => {
    text = text.toString().trim() // just in case
    // barcode, type Code 128
    // can encode ASCII strings plus control characters
    return isValidCode128CharacterSet(text)
}

const isValidBarcodeCode39 = text => {
    text = text.toString().trim() // just in case
    // barcode, type Code 39
    // can encode A-Z0-9 and some symbols
    return isValidCode39CharacterSet(text)
}

const isValidBarcodeInterleaved2Of5 = text => {
    text = text.toString().trim() // just in case
    // barcode, type Interleaved 2 of 5
    // can encode 0-9, with even-sized strings (zero-prefix an odd-sized one)
    return isValidInterleaved2Of5CharacterSet(text)
}

const isValidBarcodeUPCA = text => {
    text = text.toString().trim() // just in case
    // barcode, type UPC-A
    // The text is the barcode - including the checksum digit
    // First we check for digit-only and length 12
    if (!text.match(/^\d{12}$/)) {
        return false
    }
    // We should verify the checksum digit for correctness
    // this is the last entry from the barcode
    // the first 11 positions are the actual barcode
    const checksumdigit = text.slice(-1)
    const barcode11 = text.slice(0, -1)
    const checksum = calculateUPCAChecksum(barcode11)
    return checksum === parseInt(checksumdigit, 10)
}

const isValidBarcodePdf417Text = text => {
    text = text.toString().trim() // just in case
    // barcode, type PDF417 TEXT
    // can encode ASCII strings plus control characters
    // can encode up to 130o or 1800 characters, but
    // only reliably up to about 800? so we limit to 800
    if (text.length > 800) {
        return false
    }
    return isValidPdf417TextCharacterSet(text)
}

const isValidBarcodeDataMatrix = text => {
    text = text.toString().trim() // just in case
    // barcode, type Data Matrix
    // The maximum capacity for Data Matrix codes is up to 3116 numeric characters
    // or up to 2335 alphanumeric characters or up to 1555 bytes of binary information.
    // But, only reliably up to about 800? so we limit to 800
    if (text.length > 800) {
        return false
    }
    return isValidDataMatrixCharacterSet(text)
}

const isValidBarcodeQR = text => {
    text = text.toString().trim() // just in case
    // barcode, type QR
    // The ISO/IEC 18004 specifications state that up to 2900 bytes and 4200 ASCII
    // characters may be encoded in single symbol; however, few imagers can dependably
    // decode symbols that large. The amount of data that can be encoded will vary
    // depending upon the type of data, the encoding mode and what the scanner can read.
    // But, only reliably up to about 800? so we limit to 800
    if (text.length > 800) {
        return false
    }
    return isValidQRCharacterSet(text)
}

export const isValidBoolean = text_or_boolean => {
    const text = text_or_boolean.toString().toLowerCase().trim() // just in case
    // a valid boolean is true or false, but in text we only support
    // a few values that can evaluate to true or false
    return [
        // truth-y
        'true',
        '1',
        'yes',
        'y',
        'on',
        'checked',
        'selected',
        // false-y
        'false',
        '0',
        'no',
        'n',
        'off',
        'unchecked',
        'deselected',
    ].includes(text.toString().trim())
}

export const isValidNumber = text_or_number => {
    const text = text_or_number.toString().trim() // just in case
    // a valid number should have be a valid, non-decimal number
    // parseInt(text, 10) is not good enough as it drops illegal characters
    return /^[-+]?(\d+)$/.test(text)
}

export const isValidDecimal = text_or_number => {
    const text = text_or_number.toString().trim() // just in case
    // a valid number should have be a valid, non-decimal number
    // parseFloat(text) is not good enough as it drops illegal characters
    return /^[-+]?(\d+)(\.\d*)?$/.test(text)
}

export const isValidRecord = text => {
    text = text.toString().trim() // just in case
    // a valid record is a gid
    // we don't check if the gid is actually in the record database
    // or if its type is actually an record
    return maybe_gid(text)
}

export const isValidRecordlist = recordlist => {
    if (!Array.isArray(recordlist)) {
        return false
    }
    return recordlist.every(line => isValidRecord(line))
}

export const isValidImage = text => {
    text = text.toString().trim() // just in case
    // a valid image is a gid
    // we don't check if the gid is actually in the asset database
    // or if its type is actually an image
    return maybe_gid(text)
}

export const isValidImagelist = imagelist => {
    if (!Array.isArray(imagelist)) {
        return false
    }
    return imagelist.every(line => isValidImage(line))
}

export const isValidFile = text => {
    text = text.toString().trim() // just in case
    // a valid file is a gid
    // we don't check if the gid is actually in the asset database
    // or if its type is actually a file
    return maybe_gid(text)
}

export const isValidFilelist = filelist => {
    if (!Array.isArray(filelist)) {
        return false
    }
    return filelist.every(line => isValidFile(line))
}

export const isValidFieldpicker = text => {
    text = text.toString().trim() // just in case
    // a valid field is (dotted) gid(s)
    // we don't check if the gid is actually in the fields database
    for (var gid of text.split(/\./)) {
        if (!maybe_gid(gid)) return false
    }
    return true
}

export const isValidClass = data => {
    if (typeof data !== 'object' || Array.isArray(data) || data === null) {
        return false
    }
    // TODO CC-95
    return true
}

export const isValidClasslist = data => {
    if (!Array.isArray(data)) {
        return false
    }
    // TODO CC-95
    return true
}

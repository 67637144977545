//
// FolderItem
//
// A simple folder asset item, with icon, name and path

import React from 'react'
import { observer } from 'mobx-react-lite'

import { HView, VView, View } from '../appview'
import { IconTile } from '.'
import { maxChars } from '../utils/text'

export const FolderItem = observer(function FolderItem({
    folder,
    className,
    iconplaceholder,
    ...other
}) {
    let classes = 'cc-FolderItem'
    if (className) classes += ' ' + className

    if (!folder) {
        return (
            <HView className={classes} {...other}>
                <View style={{ flexBasis: '40px', minWidth: 40, maxWidth: 40 }}>
                    <IconTile
                        className="cc-dimmed"
                        width={32}
                        icon={iconplaceholder ? iconplaceholder : 'plus'}
                    />
                </View>
            </HView>
        )
    }

    return (
        <HView className={classes} {...other}>
            <View style={{ flexBasis: '40px', minWidth: 40, maxWidth: 40 }}>
                <IconTile width={32} icon="folder" />
            </View>
            <VView grow>
                <div title={folder.name}>{maxChars(folder.name, 24)}</div>
                <div title={folder.pathname} className="cc-dimmed">
                    {maxChars(folder.pathname, 24)}
                </div>
            </VView>
        </HView>
    )
})

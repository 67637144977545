//
// App
//
// Main entry for the App, where we switch between loading, login, and project screens.

import React from 'react'
import { useStore } from './stores'
import { observer } from 'mobx-react-lite'

import './appview/appview.css'
import './components/components.scss'
import './site.scss'
import './app.scss'
import './workspaces/pim/worksheets/worksheets.scss'

import { AppView } from './appview'
import {
    WelcomeScreen,
    LoadingScreen,
    LoginScreen,
    SignUpScreen,
    InviteScreen,
    AccountScreen,
    ProjectScreen,
} from './screens'
import { ErrorBoundary, AppErrorScreen } from './errors'

export const App = observer(function App(props) {
    const store = useStore()

    let Screen = <LoadingScreen />
    if (!store.is_loading) {
        if (store.location === store.LOCATIONS.WELCOME) {
            Screen = <WelcomeScreen />
        } else if (store.location === store.LOCATIONS.SIGNUP) {
            Screen = <SignUpScreen />
        } else if (store.location === store.LOCATIONS.LOGIN) {
            Screen = <LoginScreen />
        } else if (store.location === store.LOCATIONS.INVITE) {
            Screen = <InviteScreen />
        } else if (store.location === store.LOCATIONS.ACCOUNT) {
            Screen = <AccountScreen />
        } else if (store.location === store.LOCATIONS.PROJECT) {
            Screen = <ProjectScreen />
        }
    }

    return (
        <AppView
            onContextMenu={e => {
                if (!store.can('debug')) {
                    e.preventDefault()
                    e.stopPropagation()
                    e.nativeEvent.stopImmediatePropagation()
                }
            }}
        >
            <ErrorBoundary
                errorUI={AppErrorScreen}
                reportError={store.reportError}
                onReset={() => window.location.reload()}
            >
                {Screen}
            </ErrorBoundary>
        </AppView>
    )
})

//
// PimQueryStore
//
// The PimQueryStore handles interaction with the Record Queries
//
// We have (or don't have) a reference to a Query (by gid)
// We hold the relevant query data in memory, so we can change it without changing
// the actual query - we can do that with a Save action.
//
// This means we need a name, a q, and a filter(tree)
//
// We use a FilterStore to handle the filters.

import {
    makeObservable,
    observable,
    computed,
    action,
    reaction,
    runInAction,
} from 'mobx'

import { FilterStore } from '../components/FilterStore'
import { MultiSelection } from '../components/MultiSelection'
import { hashData } from '../../utils/utils'

export class PimQueryStore {
    __init = false
    _key = null
    _rootstore = null
    _environment = null
    gid = null
    name = null
    q = null
    filterstore = null
    topindex = null
    windowsize = null
    results = null
    totalsize = null
    showFilter = false
    multiselection = null

    queryChanged = false

    constructor(key, rootstore, environment) {
        this.multiselection = new MultiSelection(this)
        makeObservable(this, {
            __init: observable,
            _key: observable,
            _rootstore: observable,
            _environment: observable,
            gid: observable,
            name: observable,
            q: observable,
            filterstore: observable,
            topindex: observable,
            windowsize: observable,
            results: observable,
            totalsize: observable,
            showFilter: observable,
            multiselection: observable,

            setShowFilter: action.bound,
            fetch: action.bound,
            refetch: action.bound,
            _fetch: action.bound,
            newQuery: action.bound,
            clearQueryFilters: action.bound,
            openQuery: action.bound,
            saveQuery: action.bound,
            saveNewQuery: action.bound,
            deleteQuery: action.bound,
            syncdataitem_callback: action.bound,

            queryChanged: observable,
            full_size: computed,
            keys: computed,
        })

        this._key = key
        this._rootstore = rootstore
        this._environment = environment

        this.gid = null
        this.name = ''
        this.q = ''
        this.filterstore = new FilterStore('record', ['and', '', []])
        this.topindex = 0
        this.windowsize = 10
        // filled after fetch
        this.results = []
        this.totalsize = 0

        this._syncdebounce = null
        this._syncdelay = 100
    }

    set filter(filter) {
        this.filterstore.setFilter(this.filterstore.doctype, filter)
    }

    get language() {
        return this._rootstore.view.environment.get('language')
    }

    get is_modified() {
        if (!this.gid) return false
        const query = this._rootstore.data.queries.get(this.gid)
        if (this.name !== query.name) return true
        if (this.q !== query.q) return true
        if (hashData(this.filterstore.filter) !== hashData(query.filter)) return true
        return false
    }

    get actionSelection() {
        const selection = this.multiselection.selection
        if (selection.hasOwnProperty('selected')) {
            return [...selection.selected]
        } else {
            return [
                {
                    search: {
                        language: this.language,
                        q: this.q,
                        filter: this.filterstore.filter,
                    },
                    exclude: [...selection.deselected],
                },
            ]
        }
    }

    loadOnce = () => {
        if (this.__init) return

        this.gid = this._rootstore.view.loadPerProject(this._key + '/gid', null)
        this.name = this._rootstore.view.loadPerProject(this._key + '/name', '')
        this.q = this._rootstore.view.loadPerProject(this._key + '/q', '')
        const filter = this._rootstore.view.loadPerProject(this._key + '/filter', [
            'and',
            '',
            [],
        ])
        this.filterstore.setFilter('record', filter)
        this.topindex = this._rootstore.view.loadPerProject(this._key + '/topindex', 0)
        this.windowsize = this._rootstore.view.loadPerProject(
            this._key + '/windowsize',
            10
        )
        this.showFilter = this._rootstore.view.loadPerProject(
            this._key + '/showFilter',
            this.showFilter
        )
        this.queryChanged = true

        reaction(
            () => this.gid,
            gid => {
                this._rootstore.view.savePerProject(this._key + '/gid', gid)
                this.queryChanged = true
            }
        )
        reaction(
            () => this.name,
            name => {
                this._rootstore.view.savePerProject(this._key + '/name', name)
                this.queryChanged = true
            }
        )
        reaction(
            () => this.q,
            q => {
                this._rootstore.view.savePerProject(this._key + '/q', q)
                this.queryChanged = true
            }
        )
        reaction(
            () => this.filterstore.refreshkey,
            refreshkey => {
                this._rootstore.view.savePerProject(
                    this._key + '/filter',
                    this.filterstore.filter
                )
                this.queryChanged = true
            }
        )
        reaction(
            () => this._environment.get('language'),
            language => {
                this.queryChanged = true
            }
        )

        this._fetch(this.topindex, this.windowsize)

        this._rootstore.api.register_syncdataitem_callback(this.syncdataitem_callback)
        this.__init = true
    }

    fetch(topindex, windowsize) {
        if (topindex !== this.topindex) {
            this.queryChanged = true
        }
        if (windowsize !== this.windowsize && windowsize < this.totalsize) {
            this.queryChanged = true
        }
        if (topindex !== undefined) this.topindex = topindex
        if (windowsize !== undefined) this.windowsize = windowsize
        this._rootstore.view.savePerProject(this._key + '/topindex', this.topindex)
        this._rootstore.view.savePerProject(this._key + '/windowsize', this.windowsize)
        return this._fetch(this.topindex, this.windowsize)
    }

    refetch() {
        this.queryChanged = true
        return this._fetch(this.topindex, this.windowsize)
    }

    _fetch(topindex, windowsize) {
        if (!this.queryChanged) {
            return Promise.resolve(true)
        }
        this.queryChanged = false
        return this._rootstore
            ._fetch('/records/search', {
                language: this._environment.get('language'),
                q: this.q,
                filter: this.filterstore.filter,
                'page.top': topindex,
                'page.size': windowsize,
            })
            .then(result => {
                runInAction(() => {
                    this.results = result['records']
                    this.totalsize = result['records.size']
                })
                return result
            })
            .catch(error => {})
    }

    setShowFilter = toggle => {
        this.showFilter = toggle
        this._rootstore.view.savePerProject(this._key + '/showFilter', this.showFilter)
    }

    newQuery() {
        this.gid = null
        this.name = ''
        this.q = ''
        this.filter = ['and', '', []]
        this.showFilter = false
    }

    clearQueryFilters() {
        this.gid = null
        this.name = ''
        this.filter = ['and', '', []]
        this.setShowFilter(false)
    }

    reopenQuery() {
        if (!this.gid) return
        const query = this._rootstore.data.queries.get(this.gid)
        if (!query) return
        this.name = query.name
        this.q = query.q
        this.filter = JSON.parse(JSON.stringify(query.filter))
    }

    openQuery(gid) {
        const query = this._rootstore.data.queries.get(gid)
        if (!query) return
        this.gid = query.gid
        this.name = query.name
        this.q = query.q
        this.filter = JSON.parse(JSON.stringify(query.filter))
    }

    saveQuery() {
        if (!this.gid) {
            this.saveNewQuery()
        } else {
            const query = this._rootstore.data.queries.get(this.gid)
            if (!query) return
            query.name = this.name
            query.q = this.q
            query.filter.replace(this.filterstore.filter)
            query.updateQuery().then(result => {
                this.openQuery(result['query'])
                return result
            })
        }
    }

    saveNewQuery() {
        this._rootstore.data
            .createQuery('record', this.name, this.q, this.filterstore.filter)
            .then(result => {
                this.openQuery(result['query'])
                return result
            })
    }

    deleteQuery() {
        const query = this._rootstore.data.queries.get(this.gid)
        if (!query) return
        query
            .deleteQuery()
            .then(result => {
                this.newQuery()
                return result
            })
            .catch(error => {})
    }

    schedule_refetch = () => {
        if (this._syncdebounce) {
            clearTimeout(this._syncdebounce)
            this._syncdebounce = null
        }
        this._syncdebounce = setTimeout(() => {
            this.refetch()
            this._syncdebounce = null
        }, this._syncdelay)
    }

    syncdataitem_callback = (syncdataitem, data_before, data_after) => {
        // console.log('PimQueryStore sync', syncdataitem, data_before, data_after)
        // we're only interested in data_type "records"
        // then, we're only interested in updates (because our results could change)
        // from those records, only for records that are in our store
        if (syncdataitem['data_type'] !== 'records') return
        if (!['UPDATE', 'DELETE'].includes(syncdataitem['action'])) return
        if (!this.results.includes(syncdataitem['data_key'])) return
        this.schedule_refetch()
    }

    // ResultsStore interface for multiselection

    get full_size() {
        return this.totalsize
    }

    get keys() {
        return this.results
    }

    has = key => {
        return this.keys.includes(key)
    }

    range = (anchorKey, chainKey) => {
        // inclusive both ends, chainKey can be before anchorKey, and either can be NULL
        if (anchorKey === null || !this.keys.includes(anchorKey)) return []
        if (chainKey === null || !this.keys.includes(chainKey)) return [anchorKey]
        if (anchorKey === chainKey) return [anchorKey]
        let range = []
        let in_range = false
        for (const key of this.keys) {
            if (!in_range && (key === anchorKey || key === chainKey)) {
                in_range = true
            } else if (in_range && (key === anchorKey || key === chainKey)) {
                range.push(key)
                break
            }
            if (in_range) {
                range.push(key)
            }
        }
        return range
    }

    keyAfter = key => {
        const i = this.keys.indexOf(key)
        if (i === -1) return null
        if (i === this.keys.length - 1) return null
        return this.keys[i + 1]
    }

    keyBefore = key => {
        const i = this.keys.indexOf(key)
        if (i === -1) return null
        if (i === 0) return null
        return this.keys[i - 1]
    }
}

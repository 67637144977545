//
// FieldDataWorksheet
//
// Work on a Class

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../stores'

import { View, VView, HView, Spacer } from '../../../appview'
import { Header, Select, TextInput, Toggle } from '../../../components'
import { LabeledField } from '../../components'
import { FieldOptions } from './FieldOptions'
import { VALIDATION } from '../../../utils/validation'
import { duckField, validateField } from '../../../stores/data/validators'
import { validName } from '../../../utils/text'

export const FieldDataWorksheet = observer(function FieldDataWorksheet({ worksheet }) {
    const { app, data, view } = useStore()

    const field = worksheet.item
    const language = view.environment.get('language')
    const namerenderkey = field.gid + '.name'
    const labelrenderkey = field.gid + '.label.' + language
    const unitrenderkey = field.gid + '.unit'
    const optionsrenderkey = field.gid + '.options'

    const selectModified = result => {
        const field = data.fields.get(result['field'])
        if (field) {
            view.schematree.selectItem({ id: field.gid, item: field })
            view.schemaquery.refetch()
        }
        return result
    }

    const duckRequiredTextField = duckField('text', {}, true)
    const requiredValidator = value => {
        return validateField(value, duckRequiredTextField, worksheet.language)
    }

    const onNameChange = value => {
        field.setProperty('name', value)
    }
    const onNameBlur = value => {
        if (requiredValidator(value).result !== VALIDATION.SUCCESS) {
            // TODO: CC-204 resetName
        } else {
            field.setProperty('name', value.trim())
            field.commitIfModified().then(result => selectModified(result))
        }
    }

    const onLabelChange = value => {
        field.setProperty('label', value, language)
    }
    const onLabelBlur = value => {
        field.setProperty('label', value.trim(), language)
        field.commitIfModified().then(result => selectModified(result))
    }

    const onSelectFieldType = selectedType => {
        field.setProperty('type', selectedType)
        field.commitIfModified().then(result => selectModified(result))
    }

    const onSelectFormat = selectedFormat => {
        field.setOption('format', selectedFormat)
        field.commitIfModified().then(result => selectModified(result))
    }

    const onLocalizedChange = value => {
        field.setProperty('is_localized', value)
        field.commitIfModified().then(result => selectModified(result))
    }

    const onUnitChange = value => {
        field.setProperty('unit', value)
    }
    const onUnitBlur = value => {
        field.setProperty('unit', value.trim())
        field.commitIfModified().then(result => selectModified(result))
    }

    let FormatSelect
    let format = field.options.get('format')
    switch (field.type) {
        case 'code':
            if (!['text', 'json'].includes(format)) {
                format = undefined
            }
            FormatSelect = (
                <LabeledField
                    label={app.text('format')}
                    is_localized={false}
                    language={language}
                >
                    <Select
                        value={format}
                        options={{
                            text: app.text('text'),
                            json: app.text('JSON'),
                        }}
                        onChange={onSelectFormat}
                    />
                </LabeledField>
            )
            break
        case 'barcode':
            if (
                ![
                    'CODE-39',
                    'CODE-128',
                    'DATAMATRIX',
                    'EAN-8',
                    'EAN-13',
                    'I2OF5',
                    'PDF417-TEXT',
                    'QR',
                    'UPC-A',
                ].includes(format)
            ) {
                format = undefined
            }
            FormatSelect = (
                <LabeledField
                    label={app.text('format')}
                    is_localized={false}
                    language={language}
                >
                    <Select
                        value={format}
                        options={{
                            'CODE-39': app.text('Code 39'),
                            'CODE-128': app.text('Code 128'),
                            DATAMATRIX: app.text('Data Matrix'),
                            'EAN-8': app.text('EAN 8'),
                            'EAN-13': app.text('EAN 13'),
                            I2OF5: app.text('Interleaved 2 of 5'),
                            'PDF417-TEXT': app.text('PDF417 text'),
                            QR: app.text('QR code'),
                            'UPC-A': app.text('UPC A'),
                        }}
                        onChange={onSelectFormat}
                    />
                </LabeledField>
            )
            break
        default:
    }

    return (
        <View className="worksheet">
            <HView>
                <VView grow>
                    <Header>{app.text('Field properties')}</Header>
                    <LabeledField
                        label={app.text('name')}
                        is_localized={false}
                        language={language}
                        placeholder={app.text(
                            'Reference name, only lower-case letters, digits, and underscore allowed.'
                        )}
                    >
                        <div className="ws-text">
                            <TextInput
                                multiline={false}
                                enabled={true}
                                validate={requiredValidator}
                                autoConvert={validName}
                                value={field.name}
                                onChange={e => onNameChange(e.target.value)}
                                onBlur={e => onNameBlur(e.target.value)}
                                renderkey={namerenderkey}
                            />
                        </div>
                    </LabeledField>
                    <LabeledField
                        className="lc-subtitle"
                        label={app.text('label')}
                        is_localized={true}
                        language={language}
                        placeholder={app.text('Label, localized.')}
                    >
                        <div className="ws-text">
                            <TextInput
                                multiline={false}
                                enabled={true}
                                value={field.label.get(language)}
                                language={language}
                                onChange={e => onLabelChange(e.target.value)}
                                onBlur={e => onLabelBlur(e.target.value)}
                                renderkey={labelrenderkey}
                            />
                        </div>
                    </LabeledField>
                    <LabeledField
                        label={app.text('type')}
                        is_localized={false}
                        language={language}
                    >
                        <Select
                            value={field.type}
                            options={{
                                text: app.text('Text multiline'),
                                textline: app.text('Text line'),
                                textlist: app.text('Text list'),
                                boolean: app.text('Yes/No'),
                                number: app.text('Number'),
                                decimal: app.text('Decimal number'),
                                image: app.text('Image'),
                                imagelist: app.text('Image list'),
                                file: app.text('File'),
                                filelist: app.text('File list'),
                                record: app.text('Record'),
                                recordlist: app.text('Record list'),
                                code: app.text('Code'),
                                barcode: app.text('Barcode'),
                                fieldpicker: app.text('Field picker'),
                                class: app.text('Class'),
                                classlist: app.text('Class list'),
                            }}
                            onChange={onSelectFieldType}
                        />
                    </LabeledField>
                    {FormatSelect}
                    <LabeledField
                        label={app.text('localized')}
                        is_localized={false}
                        language={language}
                        placeholder={app.text(
                            'Is the value different in different languages?'
                        )}
                    >
                        <div className="ws-text">
                            <Toggle
                                value={field.is_localized}
                                onChange={onLocalizedChange}
                            />
                        </div>
                    </LabeledField>
                    <LabeledField
                        label={app.text('unit')}
                        is_localized={false}
                        language={language}
                        placeholder={app.text('Unit of measure, e.g. kg or °C.')}
                    >
                        <div className="ws-text">
                            <TextInput
                                multiline={false}
                                enabled={true}
                                value={field.unit}
                                onChange={e => onUnitChange(e.target.value)}
                                onBlur={e => onUnitBlur(e.target.value)}
                                renderkey={unitrenderkey}
                            />
                        </div>
                    </LabeledField>
                </VView>
                <Spacer size={50} />
                <VView grow>
                    <Header>{app.text('Options')}</Header>
                    <FieldOptions
                        field={field}
                        worksheet={worksheet}
                        selectModified={selectModified}
                        renderkeyprefix={optionsrenderkey}
                    />
                </VView>
            </HView>
        </View>
    )
})

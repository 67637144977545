//
// StentorImageserver
//
// An Imageserver returns a URL for an asset, transformed as specified

class StentorImageserver {
    constructor(options, rootstore) {
        this._rootstore = rootstore
        this.options = options
        this.transformable_formats = [
            // images
            'jpg',
            'jpeg',
            'gif',
            'svg',
            'png',
        ]
    }

    canTransform(asset) {
        if (!asset) return false
        if (!asset.is_file) return false
        return this.transformable_formats.includes(asset.ext)
    }

    transformAssetUrl(asset, transform = {}) {
        if (!asset) return null
        if (!this.canTransform(asset)) {
            return this.options['baseurl'] + '/media' + asset.pathname
        }
        const source = asset.pathname
        return this.transformUrl(source, transform)
    }

    transformUrl(url, transform = {}) {
        const width = Math.max(10, transform['width'])
        const transform_url =
            this.options['baseurl'] +
            '/image/media' +
            url.replace(
                /.([^.]*)$/, // last occurrence only, so only extension
                '@' + width + 'w.jpg' // replace extension with .jpg
            )
        return transform_url
    }

    async transformedAssetUrl(asset, transform_url) {
        return this.transformedUrl(transform_url)
    }

    async transformedUrl(transform_url) {
        if (!transform_url) {
            return Promise.resolve(null)
        }
        return Promise.resolve(transform_url)
    }
}

export default StentorImageserver

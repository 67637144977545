//
// Bar
//
// The Bar groups any number of components, line-height 0.

import React from 'react'

export const Bar = React.forwardRef((props, ref) => {
    const { className, raised, vertical, ...other } = props
    let classes = 'cc-Bar'
    if (className) classes += ' ' + className
    if (raised) classes += ' cc-raised'
    if (vertical) classes += ' cc-vertical'
    return <div className={classes} ref={ref} {...other} />
})

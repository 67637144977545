//
// LazyVScrollTable, LazyScrollRow
//
// Based on LazyScroll, look there for details
//
// A lazy-loading, vertical-scrolling (html) table is next to impossible if you
// don't want to 'fix' all the cells to specific widths (which will be wrong for later
// rows, perhaps).
//
// We use a bit of a trick here, because the 'Lazy' part means we're only rendering
// the rows that are visible. Add a header, and you have a usable table, but it isn't
// scrolling yet. So we use the LazyScroll method with a scrollcontainer and a nested
// div that has the correct size to get the scrollbars. But in LazyScroll, we can
// position items absolutely -- that doesn't work tables/rows/cells if you want to keep
// table-autosizing behaviour. So we make the table 'sticky', this is including the
// header which is automatically always at the top, and that works very good. However,
// the visual scrolling effect isn't as good - first you see the scrollbar moving but
// not the contents, until those are fetched from the server. A bit jarring, not as
// smooth. But for now, it will do.
//
// Usage:
//
//     import React, { useState } from 'react'
//     import { HView, VView, LazyVScrollTable, LazyScrollRow } from '../../appview'
//
//
//     export const TableWindow = () => {
//
//         const headerheight = 30
//         const rowheight = 20
//         const [totalsize, setTotalsize] = useState(99999)
//         const [firstindex, setFirstindex] = useState(0)
//         const [windowsize, setWindowsize] = useState(10)
//
//         const onUpdate = (newfirstindex, newwindowsize) => {
//             setFirstindex(newfirstindex)
//             setWindowsize(newwindowsize)
//         }
//
//         const renderedwindowsize = Math.min(windowsize, totalsize)
//         const rows = [...Array(renderedwindowsize).keys()].map(index => {
//             return (
//                 <LazyScrollRow key={index + firstindex}>
//                     <td>Item {index + firstindex} ({index})</td>
//                     <td>Cell 2</td>
//                 </LazyScrollRow>
//             )
//         })
//
//         const headerrow = (
//             <LazyScrollRow>
//                 <td>Header 1</td>
//                 <td>Header 2</td>
//             </LazyScrollRow>
//         )
//
//         return (
//             <HView style={{padding: 20}}>
//                 <VView style={{width: 300}}>
//                     <LazyVScrollTable
//                         firstindex={firstindex}
//                         headerheight={headerheight}
//                         headerrow={headerrow}
//                         rowheight={rowheight}
//                         totalitems={totalsize}
//                         onUpdate={onUpdate}
//                     >
//                         {rows}
//                     </LazyVScrollTable>
//                     <div onClick={() => { setFirstindex(0) }}>scroll to Top</div>
//                     <div onClick={() => { setFirstindex(Math.ceil(totalsize/2)) }}>scroll to Middle</div>
//                     <div onClick={() => { setFirstindex(totalsize) }}>scroll to Bottom</div>
//                     <div onClick={() => { setTotalsize(10) }}>set total to 10 items</div>
//                     <div onClick={() => { setTotalsize(windowsize) }}>set total to {windowsize} items</div>
//                     <div onClick={() => { setTotalsize(100) }}>set total to 100 items</div>
//                     <div onClick={() => { setTotalsize(99999) }}>set total to 99999 items</div>
//                     <div>
//                        firstindex: {firstindex}, windowsize: {windowsize}, totalsize: {totalsize}
//                     </div>
//                 </VView>
//             </HView>
//         )
//     }

import React, { useState, useLayoutEffect, useMemo } from 'react'
import { useOnElementResize } from '../hooks/useOnElementResize'

const DEBOUNCE_MAGIC_NUMBER = 40 // milliseconds

export const LazyScrollRow = React.forwardRef(function LazyScrollRow(
    { className, height, minHeight, children, style },
    ref
) {
    let classes = 'av-LazyScrollRow'
    if (className) classes += ' ' + className

    const positioning = {
        height: height,
        minHeight: minHeight,
    }
    return (
        <tr ref={ref} className={classes} style={{ ...(style || {}), ...positioning }}>
            {children}
        </tr>
    )
})

export const LazyVScrollTable = props => {
    const { className, style } = props
    let classes = 'av-LazyVScrollTable'
    let styles = {}
    if (className) classes += ' ' + className
    if (style) styles = Object.assign({}, styles, style)

    const {
        firstindex: propsfirstindex,
        rowheight,
        totalitems,
        onUpdate,
        scrollToFirst,
        headerrow,
        headerheight,
        tableheight,
        children,
        renderkey,
    } = props
    const [initialfirstindex, setInitialFirstindex] = useState(propsfirstindex)
    const [firstindex, setFirstindex] = useState(propsfirstindex)
    const [visibleitemcount, setVisibleitemcount] = useState(10)
    const [scrollingContainerRef, elementsize] = useOnElementResize(250, 500)
    const scrollingContainerRefCurrent = scrollingContainerRef.current
    const [debounceScrolling, setDebounceScrolling] = useState(null)

    const [calculatedrowheight, setCalculatedRowheight] = useState(rowheight)
    const [calculatedheaderheight, setCalculatedHeaderheight] = useState(headerheight)

    const rowcount = React.Children.toArray(children).length
    const rowRefs = useMemo(() => {
        return Array(rowcount).fill(0).map(React.createRef)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowcount, renderkey])

    // initially set correct scroll position
    useLayoutEffect(() => {
        if (scrollingContainerRefCurrent) {
            if (
                Math.abs(
                    scrollingContainerRefCurrent.scrollTop -
                        initialfirstindex * calculatedrowheight
                ) > calculatedrowheight
            ) {
                scrollingContainerRefCurrent.scrollTop =
                    initialfirstindex * calculatedrowheight
            }
        }
    }, [
        initialfirstindex,
        calculatedrowheight,
        totalitems,
        renderkey,
        scrollingContainerRefCurrent,
    ])

    // when the firstindex changed externally (e.g. by setting it to 0) instead of
    // internally, we have to update our internal state and scroll position
    useLayoutEffect(() => {
        if (initialfirstindex !== propsfirstindex) {
            setInitialFirstindex(propsfirstindex)
            if (firstindex !== propsfirstindex) {
                setFirstindex(propsfirstindex)
                if (scrollingContainerRefCurrent) {
                    scrollingContainerRefCurrent.scrollTop =
                        propsfirstindex * calculatedrowheight
                }
                onUpdate && onUpdate(propsfirstindex, visibleitemcount)
            }
        }
    }, [
        totalitems,
        calculatedrowheight,
        firstindex,
        initialfirstindex,
        propsfirstindex,
        visibleitemcount,
        onUpdate,
        scrollingContainerRefCurrent,
    ])

    // when scrollToFirst is true, scroll to top
    useLayoutEffect(() => {
        if (scrollToFirst) {
            if (scrollingContainerRefCurrent) {
                scrollingContainerRefCurrent.scrollTop = 0
            }
        }
    }, [scrollToFirst, scrollingContainerRefCurrent])

    // when the height of the container changes (which is predicated on a change of the
    // elementsize) we need to recalculate how many items would be visible
    useLayoutEffect(() => {
        const newvisibleitemcount = elementsize
            ? 1 +
              Math.ceil(
                  (elementsize.height - calculatedheaderheight) / calculatedrowheight
              )
            : // we add one even though we already round up, so both the top and bottom
              // items can be partially visible
              10
        if (visibleitemcount !== newvisibleitemcount) {
            setVisibleitemcount(newvisibleitemcount)
            onUpdate && onUpdate(firstindex, newvisibleitemcount)
        }
    }, [
        elementsize,
        totalitems,
        calculatedrowheight,
        calculatedheaderheight,
        firstindex,
        visibleitemcount,
        onUpdate,
    ])

    const onScroll = event => {
        const { scrollTop } = event.currentTarget
        // Prevent Safari's elastic scrolling from causing visual shaking when scrolling past bounds.
        const scrollFirstindex = Math.max(
            0,
            Math.min(
                Math.round(scrollTop / calculatedrowheight),
                totalitems - visibleitemcount + 2
            )
        )
        if (firstindex === scrollFirstindex) {
            return
        }
        if (debounceScrolling) {
            window.clearTimeout(debounceScrolling)
            setDebounceScrolling(null)
        }
        setDebounceScrolling(
            window.setTimeout(() => {
                setDebounceScrolling(null)
                setFirstindex(scrollFirstindex)
                onUpdate && onUpdate(scrollFirstindex, visibleitemcount)
            }, DEBOUNCE_MAGIC_NUMBER)
        )
    }

    const headerrefcallback = element => {
        if (element) setCalculatedHeaderheight(element.offsetHeight)
        else setCalculatedHeaderheight(headerheight)
    }

    const Header = headerrow
        ? React.cloneElement(headerrow, {
              height: calculatedheaderheight,
              ref: headerrefcallback,
          })
        : undefined

    const rowrefcallback = element => {
        if (element) {
            setCalculatedRowheight(Math.max(element.offsetHeight, rowheight, 10))
        } else {
            setCalculatedRowheight(rowheight)
        }
    }

    let Rows =
        children && children.map
            ? children.map((child, index) => {
                  if (!child) return null
                  let extraprops = {
                      height: index === 0 ? 'auto' : calculatedrowheight,
                      minHeight: calculatedrowheight,
                      ref: index === 0 ? rowrefcallback : rowRefs[index],
                  }
                  return React.cloneElement(child, extraprops)
              })
            : children

    const calculatedtableheights = Array(visibleitemcount)
        .fill(0)
        .map((_, index) => calculatedheaderheight + index * calculatedrowheight + 1)
        .filter(value => value > tableheight)
    const calculatedtableheight = calculatedtableheights.length
        ? calculatedtableheights[0]
        : tableheight
    styles['maxHeight'] = calculatedtableheight

    return (
        <div
            ref={scrollingContainerRef}
            onScroll={onScroll}
            className={classes}
            style={styles}
        >
            <div
                style={{
                    height:
                        calculatedheaderheight + totalitems * calculatedrowheight + 1,
                }}
            >
                <table style={{ position: 'sticky', top: 0, width: '100%' }}>
                    <thead>{Header}</thead>
                    <tbody>{Rows}</tbody>
                </table>
            </div>
        </div>
    )
}

//
// SchemaItem
//
// A simple schema item (definition, class, or field), with icon, name and label

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { HView, VView, View } from '../appview'
import { IconTile } from '.'
import { maxChars } from '../utils/text'
import { DefinitionData } from '../stores/data/DefinitionData'
import { ClassData } from '../stores/data/ClassData'
import { FieldData } from '../stores/data/FieldData'

export const SchemaItem = observer(function SchemaItem({
    item,
    className,
    iconplaceholder,
    ...other
}) {
    const { view } = useStore()
    const language = view.environment.get('language')

    let classes = 'cc-SchemaItem'
    if (className) classes += ' ' + className
    const thumbsize = 20
    const lineheight = 32

    if (!item) {
        return (
            <IconTile
                className={classes + ' cc-dimmed'}
                width={thumbsize}
                icon={iconplaceholder ? iconplaceholder : 'plus'}
                anchor="left"
                {...other}
            />
        )
    }

    const iconname =
        item instanceof DefinitionData
            ? 'definition'
            : item instanceof ClassData
            ? 'class'
            : item instanceof FieldData
            ? 'field'
            : 'empty'

    return (
        <HView
            className={classes}
            {...other}
            gap={5}
            style={{
                flexBasis: lineheight + 'px',
                minHeight: lineheight,
                maxHeight: lineheight,
            }}
        >
            <View
                className="cc-noline"
                style={{
                    flexBasis: thumbsize + 'px',
                    minWidth: thumbsize,
                    maxWidth: thumbsize,
                }}
            >
                <IconTile width={thumbsize} icon={iconname} anchor="left" {...other} />
            </View>
            <VView grow>
                <div title={item.label.get(language)}>
                    {maxChars(item.label.get(language), 32)}
                </div>
                <div title={item.name} className="cc-dimmed">
                    {maxChars(item.name, 32)}
                </div>
            </VView>
        </HView>
    )
})

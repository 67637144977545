//
// Layout panel
//
// Defining a layout.

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMousePopover } from '../hooks/useMousePopover'
import { DragDropLayoutTreeItem } from '../dragdrop/DragDropLayoutTreeItem'
import { DragTypes } from '../dragdrop/DragTypes'
import { useStore } from '../stores'

import { LayoutTreeMenu } from '../menus'

import { VView } from '../appview'
import { Icon, TreeItem } from '../components'

const LayoutTreeItem = observer(function LayoutTreeItem({
    tree,
    treeitem,
    worksheetstore,
    contextmenu,
    onDrop,
}) {
    const { app, data, view } = useStore()

    const language = view.environment.get('language')

    let item
    if (treeitem.item.type === 'field') {
        item = data.fields.get(treeitem.item.field)
    } else if (treeitem.item.type === 'class') {
        item = data.classes.get(treeitem.item['class'])
    } else if (treeitem.item.type === 'definition') {
        item = data.definitions.get(treeitem.item.definition)
    }

    const title = worksheetstore.layoutstore.getItemTitle(item, language)
    let name = title ? title : treeitem.item.name

    let nameclass = 'cc-TreeItem-name'
    if (!name) {
        nameclass += ' cc-unnamed-layout-component'
        name = treeitem.item.type
    }
    let icon = treeitem.item.type
    if (icon === 'root') {
        icon = 'layout'
        if (treeitem.item.is_working_copy) {
            nameclass += ' status-modified'
        }
        if (treeitem.item.has_modified()) {
            nameclass += ' status-has-modified'
        }
    }
    if (['definition', 'class'].includes(icon)) {
        icon = 'layout'
    }
    if (['autoextend', 'autogenerate'].includes(icon)) {
        icon = 'fieldlist'
    }
    if (treeitem.item.isRecursive) {
        nameclass += ' status-is-recursive'
    }
    let Postfix =
        treeitem.item.type === 'definition'
            ? app.text('(definition)')
            : treeitem.item.type === 'class'
            ? app.text('(class)')
            : null
    Postfix = Postfix ? <span className="cc-dimmed"> {Postfix}</span> : ''

    return (
        <DragDropLayoutTreeItem
            key={treeitem.id}
            tree={tree}
            treeitem={treeitem}
            onDrop={onDrop}
        >
            <TreeItem
                state={treeitem.state}
                emptyicon={'empty'}
                selected={treeitem.isSelected}
                indent={treeitem.depth - 1}
                onClick={() => {
                    tree.selectedItem && tree.selectedItem.id === treeitem.id
                        ? tree.deselectItem()
                        : tree.selectItem(treeitem)
                }}
                onContextMenu={e => {
                    tree.selectItem(treeitem)
                    contextmenu.onShow(e)
                }}
                onExpand={() => {
                    tree.expandItem(treeitem)
                }}
                onCollapse={() => {
                    tree.collapseItem(treeitem)
                }}
                title={item ? item.name : ''}
            >
                <Icon className="itemtype" name={icon} size={'text'} />
                <span className={nameclass}>
                    {name}
                    {Postfix}
                </span>
            </TreeItem>
        </DragDropLayoutTreeItem>
    )
})

const LayoutTree = observer(function LayoutTree({ worksheetstore, onDrop, onAction }) {
    const layoutstore = worksheetstore.layoutstore

    const LayoutTreeContextMenu = useMousePopover(LayoutTreeMenu)

    const tree = layoutstore.tree

    const selectedLayoutComponent = tree.selectedItem ? tree.selectedItem.item : null

    const onLayoutTreeAction = (action, name_or_type, value) => {
        const actions = {
            update_property: () => {
                onAction &&
                    onAction('update_component_property', [
                        selectedLayoutComponent.gid,
                        name_or_type,
                        value,
                    ])
            },
            update_property_in_memory: () => {
                onAction &&
                    onAction('update_component_property_in_memory', [
                        selectedLayoutComponent.gid,
                        name_or_type,
                        value,
                    ])
            },
            create_child: () => {
                onAction &&
                    onAction('create_component', [
                        name_or_type,
                        selectedLayoutComponent.gid,
                        null,
                    ])
                LayoutTreeContextMenu.hide()
            },
            table_create_child: () => {
                onAction &&
                    onAction('create_component', [
                        name_or_type,
                        selectedLayoutComponent.gid,
                        null,
                    ])
                LayoutTreeContextMenu.hide()
            },
            remove: () => {
                onAction && onAction('delete_component', [selectedLayoutComponent.gid])
                LayoutTreeContextMenu.hide()
            },
        }
        if (!(action in actions)) {
            console.log(
                `onLayoutTreeAction: unhandled action '${action}' ('${name_or_type}', '${value}') on '${tree.selectedItem.item.gid}'`
            )
            LayoutTreeContextMenu.hide()
            return
        }
        actions[action]()
    }

    const TreeItems = tree.treeitems
        .filter(treeitem => treeitem.depth > 0)
        .map(treeitem => {
            return (
                <LayoutTreeItem
                    key={treeitem.item.gid}
                    tree={tree}
                    treeitem={treeitem}
                    worksheetstore={worksheetstore}
                    contextmenu={LayoutTreeContextMenu}
                    onDrop={onDrop}
                />
            )
        })

    return (
        <>
            <LayoutTreeContextMenu.Panel
                layoutcomponent={selectedLayoutComponent}
                isRoot={tree.selectedItem ? tree.selectedItem.depth <= 1 : false}
                worksheetstore={worksheetstore}
                onAction={onLayoutTreeAction}
            />
            {TreeItems}
        </>
    )
})

export const LayoutPanel = observer(function LayoutPanel({
    worksheetstore,
    className,
    onAction,
}) {
    const layoutstore = worksheetstore.layoutstore

    const onLayoutTreeDrop = (dragitem, dropitem, dropzone) => {
        if (dropzone === 'disabled') return
        const to_parentgid = dropzone === 'inside' ? dropitem.id : dropitem.parentid
        let before_gid = null
        if (dropzone === 'top') {
            before_gid = dropitem.id
        } else if (dropzone === 'bottom') {
            // if you drag to the bottom, you want to insert before the next item
            // if there's no next item, it will be added as last child (before_id = null,
            // same as a drop inside the parentid)
            const nextitem = layoutstore.tree.getNextSibling(dropitem)
            if (nextitem) {
                before_gid = nextitem.id
            }
        }
        if (dragitem.type === DragTypes.PIM_CLASS) {
            onAction &&
                onAction('create_component', [
                    'class',
                    to_parentgid,
                    before_gid,
                    { class: dragitem.id },
                ])
        }
        if (dragitem.type === DragTypes.PIM_FIELD) {
            onAction &&
                onAction('create_component', [
                    'field',
                    to_parentgid,
                    before_gid,
                    { field: dragitem.id },
                ])
        } else {
            // dragged from inside the tree
            onAction &&
                onAction('move_component', [dragitem.id, to_parentgid, before_gid])
        }
    }

    return (
        <div className={className}>
            <VView>
                <LayoutTree
                    worksheetstore={worksheetstore}
                    onDrop={onLayoutTreeDrop}
                    onAction={onAction}
                />
            </VView>
        </div>
    )
})

//
// ProjectOrderingsMenu
//
// Actions on project orderings in the project settings

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { VView } from '../appview'
import { Header, TextInput, Menu, MenuDivider, MenuItem } from '../components'

const RenameOrderingMenu = observer(function RenameOrderingMenu({
    name: current_name,
    onConfirm,
}) {
    const { app } = useStore()

    const [name, setName] = useState(current_name)
    const isValidName = name.trim().length > 0

    const _onConfirm = () => {
        onConfirm && onConfirm(name.trim())
    }
    return (
        <VView className="actions-panel">
            <div className="inspector">
                <Header>{app.text('Rename ordering')}</Header>
                <VView
                    style={{
                        marginLeft: 5,
                        marginRight: 5,
                        marginBottom: 5,
                    }}
                >
                    <TextInput onChange={e => setName(e.target.value)} value={name} />
                </VView>
            </div>
            <Menu>
                <MenuItem disabled={!isValidName} onClick={_onConfirm}>
                    {app.text('Rename ordering')}
                </MenuItem>
            </Menu>
        </VView>
    )
})

export const ProjectOrderingsMenu = observer(function ProjectOrderingsMenu({
    project,
    orderingkey,
    onAction,
}) {
    const { app } = useStore()

    const _onAction = (e, action, value) => {
        onAction && onAction(e, orderingkey, action, value)
    }

    if (!project) return null
    const ordering = project.orderings.get(orderingkey)
    if (!ordering) return null

    const onConfirmRename = value => {
        _onAction(null, 'rename_ordering', value.trim())
    }

    // can't delete last remaining ordering
    const disableDelete = !orderingkey || project.orderedorderings.length <= 1

    return (
        <Menu>
            <MenuItem
                submenu={
                    <RenameOrderingMenu name={ordering} onConfirm={onConfirmRename} />
                }
            >
                {app.text('Rename')}
            </MenuItem>
            <MenuDivider />
            <MenuItem
                className="cc-danger"
                disabled={disableDelete}
                onClick={e => _onAction(e, 'delete_ordering')}
            >
                {app.text('Delete ordering')}
            </MenuItem>
        </Menu>
    )
})

import { maybe_gid } from '../../utils/gid'

export const getWorksheetImageUrl = async (field, width, data, imageserver) => {
    if (!field || !field.length) {
        return Promise.resolve(null)
    }
    // fieldvalue may look like
    // - <assetgid>:<imagename>;<alt>
    // - <assetgid>;<alt>
    // - <assetgid>
    // - <imagename>;<alt>
    // - <imagename>
    // NOTE: this will change to simply <assetgid> in the future
    let key_alt_parts = field.split(';')
    const key = key_alt_parts.shift()
    let keyparts = key.split(':', 2)
    const assetgid_or_imagename = keyparts.shift()
    const assetgid = maybe_gid(assetgid_or_imagename)
        ? assetgid_or_imagename
        : undefined
    const imagename = !assetgid ? assetgid_or_imagename : keyparts.shift()
    const asset = assetgid
        ? await data.fetchAssetByGid(assetgid)
        : await data.fetchAssetByPathname(imagename)
    let transform = new Transform('width', width, width)
    const transformurl = imageserver.transformAssetUrl(asset, transform)
    const src = await imageserver.transformedAssetUrl(asset, transformurl)
    return src
}

export const getImageUrl = async (imagename, width, data, imageserver) => {
    if (!imagename || !imagename.length) {
        return Promise.resolve(null)
    }
    const asset = await data.fetchAssetByPathname(imagename)
    let transform = new Transform('width', width, width)
    const transformurl = imageserver.transformAssetUrl(asset, transform)
    const src = await imageserver.transformedAssetUrl(asset, transformurl)
    return src
}

export const mergeTransforms = (...transforms) => {
    var merged = new BaseTransform()
    return transforms.reduce((result, transform) => {
        if (transform.resize !== null) result.setResize(transform.resize)
        if (transform.width !== null) result.setWidth(transform.width)
        if (transform.height !== null) result.setHeight(transform.height)
        if (transform.type !== null) result.setType(transform.type)
        if (transform.hotspot !== null)
            result.setHotspot(transform.hotspot[0], transform.hotspot[1])
        if (transform.crop !== null)
            result.setCrop(transform.crop[0], transform.crop[1])
        if (transform.padding !== null)
            result.setPadding(
                transform.padding[0],
                transform.padding[1],
                transform.padding[2],
                transform.padding[3]
            )
        if (transform.quality !== null) result.setQuality(transform.quality)
        if (transform.background !== null) result.setBackground(transform.background)
        return result
    }, merged)
}

export class BaseTransform {
    resize = null
    width = null
    height = null
    type = null
    hotspot = null
    crop = null
    padding = null
    quality = null
    background = null

    setResize(resize) {
        if (['width', 'height', 'fit', 'fill'].includes(resize)) {
            this.resize = resize
        } else {
            this.resize = 'width'
        }
        // to make sure we have valid values
        this.setWidth(this.width)
        this.setHeight(this.height)
        return this
    }

    setWidth(width) {
        if (!width) {
            if (this.resize === 'height') {
                this.width = null
            } else {
                this.width = 1
            }
        } else {
            this.width = Math.round(width)
        }
        return this
    }

    setHeight(height) {
        if (!height) {
            if (this.resize === 'width') {
                this.height = null
            } else {
                this.height = 1
            }
        } else {
            this.height = Math.round(height)
        }
        return this
    }

    setType(type) {
        if (['jpg', 'png', 'gif'].includes(type)) {
            this.type = type
        } else {
            this.type = 'jpg'
        }
        return this
    }

    setHotspot(x, y) {
        if (typeof x !== 'number' || typeof y !== 'number') {
            this.hotspot = null
            return this
        }
        if (x < 0) x = 0
        if (x > 1) x = 1
        if (y < 0) y = 0
        if (y > 1) y = 1
        this.hotspot = [x, y]
        return this
    }

    setCrop(w, h) {
        if (typeof w !== 'number' || typeof h !== 'number') {
            this.crop = null
            return this
        }
        if (w < 0) w = 0
        if (w > 1) w = 1
        if (h < 0) h = 0
        if (h > 1) h = 1
        const floatingpointthreshold = 0.00001
        if (w < floatingpointthreshold || h < floatingpointthreshold) {
            this.crop = null
        } else {
            this.crop = [w, h]
            if (!this.hotspot) {
                this.setHotspot(0.5, 0.5)
            }
        }
        return this
    }

    setPadding(top, right, bottom, left) {
        this.padding = [top, right, bottom, left]
        return this
    }

    setQuality(q) {
        if (typeof q !== 'number') {
            this.quality = null
            return this
        }
        q = Math.round(q)
        if (q < 0) q = 0
        if (q > 100) q = 100
        this.quality = q
        return this
    }

    setBackground(background) {
        this.background = 'white'
        if (['white', 'transparent'].includes(background)) {
            this.background = background
        } else {
            const re = /^([0-9a-fA-F]{6})$/
            if (re.exec(background)) {
                this.background = background
            }
        }
        return this
    }
}

export class Transform extends BaseTransform {
    constructor(resize, width, height) {
        super()
        this.setType('jpg')
        this.setResize(resize)
        this.setWidth(width)
        this.setHeight(height)
    }
}

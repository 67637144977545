//
// PimTreeStore
//
// The PimTreeStore handles interaction with the Pim Navigation Tree (TreeStore)
//
// The exposed store is the actual TreeStore (.tree), but that is a generic element
// and the PimTreeStore handles storing view choices like expandedIds and such, and
// fetching data based on that and the current, possibly changeable, environment.
//
// In a store, we use it like this:
//
//     this._pimtree = new PimTreeStore("/records/tree", this._rootstore, this._environment)
//      ...
//     get pimtree() {
//         if (!this.user && !this.project) return null
//         this._pimtree.loadOnce()
//         return this._pimtree.tree // NOTE the .tree!
//     }
//      ...
//     _pimtree is observable, pimtree is computed
//
// Have a look at the TreeNavigator component to see how it is used in rendering.
//
// SIDE-EFFECT: when a user selects a treeitem, we update the pimworksheet with that
//              same record

import { makeObservable, observable, action, reaction, runInAction } from 'mobx'

import { TreeStore, TreeStoreItem } from '../components/TreeStore'

export class PimTreeStore {
    __init = false
    _key = null
    _rootstore = null
    _environment = null
    tree = null
    topindex = null
    windowsize = null

    constructor(key, rootstore, environment) {
        makeObservable(this, {
            __init: observable,
            _key: observable,
            _rootstore: observable,
            _environment: observable,
            tree: observable,
            topindex: observable,
            windowsize: observable,
            fetch: action.bound,
            refetch: action.bound,
            _fetch: action.bound,
            select: action.bound,
            selectRecord: action.bound,
            setRecord: action.bound,
            create: action.bound,
            syncdataitem_callback: action.bound,
        })

        this._key = key
        this._rootstore = rootstore
        this._environment = environment
        this.tree = new TreeStore(this)
        this.topindex = 0
        this.windowsize = 10
        this._syncdebounce = null
        this._syncdelay = 100
    }

    loadOnce = () => {
        if (this.__init) return
        const parentGids = this._rootstore.view.loadPerProject(
            this._key + '/expandedGids',
            []
        )
        const selectedGid = this._rootstore.view.loadPerProject(
            this._key + '/selectedGid'
        )
        this.topindex = this._rootstore.view.loadPerProject(this._key + '/topindex', 0)
        this.windowsize = this._rootstore.view.loadPerProject(
            this._key + '/windowsize',
            10
        )
        this.tree._expandedIds.replace(parentGids)
        this._fetch(parentGids, this.topindex, this.windowsize).then(() => {
            runInAction(() => {
                this.tree._selectedId = selectedGid
                this.tree.multiselection.rangeStart(selectedGid)
            })
            reaction(
                () => ({
                    selectedGid: this.tree._selectedId,
                    selectionsize: this.tree.multiselection.size,
                }),
                ({ selectedGid, selectionsize }) => {
                    if (selectedGid && !selectionsize) {
                        this.tree.multiselection.rangeStart(selectedGid)
                    }
                }
            )
            reaction(
                () => this._environment.get('language'),
                language => {
                    this._fetch(this.tree._expandedIds, this.topindex, this.windowsize)
                }
            )
            reaction(
                () => this._environment.get('ordering'),
                ordering => {
                    this._fetch(this.tree._expandedIds, this.topindex, this.windowsize)
                }
            )
        })
        this._rootstore.api.register_syncdataitem_callback(this.syncdataitem_callback)
        this.__init = true
    }

    fetch(parentGids, topindex, windowsize) {
        if (topindex !== undefined) this.topindex = topindex
        if (windowsize !== undefined) this.windowsize = windowsize
        if (parentGids !== undefined) {
            this._rootstore.view.savePerProject(this._key + '/expandedGids', parentGids)
        }
        this._rootstore.view.savePerProject(this._key + '/topindex', this.topindex)
        this._rootstore.view.savePerProject(this._key + '/windowsize', this.windowsize)
        return this._fetch(parentGids, this.topindex, this.windowsize)
    }

    refetch(topindex, windowsize) {
        if (topindex !== undefined) this.topindex = topindex
        if (windowsize !== undefined) this.windowsize = windowsize
        return this._fetch(this.tree._expandedIds, this.topindex, this.windowsize)
    }

    _fetch(parentGids, topindex, windowsize) {
        return this._rootstore
            ._fetch('/records/tree', {
                expanded_records: parentGids,
                'page.top': topindex,
                'page.size': windowsize,
                language: this._environment.get('language'),
                ordering: this._environment.get('ordering'),
            })
            .then(result => {
                runInAction(() => {
                    // and use it to populate treestore items
                    // result tree is a list of gids, in order
                    const treeitems = result['tree']
                        .map(recordgid => {
                            const record = this._rootstore.data.records.get(recordgid)
                            if (!record) return null
                            return new TreeStoreItem(
                                record.gid,
                                record.parent,
                                record.treelevel,
                                record.childcount,
                                record
                            )
                        })
                        .filter(treeitem => treeitem !== null)
                    // update tree
                    this.tree._expandedIds.replace(parentGids)
                    this.topindex = topindex
                    this.windowsize = windowsize
                    this.tree.setTreeItems(treeitems, result['tree.size'])
                })
                return result
            })
        // .catch(error => {})
    }

    select(treeitem) {
        this.selectRecord(treeitem.item)
    }

    deselect(treeitem) {
        this.selectRecord(null)
    }

    selectRecord(record) {
        this._rootstore.view.savePerProject(
            this._key + '/selectedGid',
            record ? record.gid : null
        )
        this._rootstore.view.pimworksheet.setRecord(record)
    }

    setRecord(record) {
        // this is external; when we select an item, we need to make sure it appears in
        // the expanded tree, and that it will be the selected treeitem
        const parentpath = record.path.slice(1)
        const newExpandedGids = Array.from(
            new Set([...this.tree._expandedIds, ...parentpath])
        )
        this.tree._setExpandedIds(newExpandedGids)
        this.tree.setSelectedId(record.gid)
        this.tree.multiselection.deselectAll()
        this.tree.multiselection.rangeStart(record.gid)
    }

    create = parenttreeitem => {
        this._rootstore
            ._fetch('/records/create', {
                parent: parenttreeitem.item.gid,
                language: this._environment.get('language'),
                ordering: this._environment.get('ordering'),
            })
            .then(result => {
                // store data in store.data.pimrecords
                // add the newly created record as a treeitem
                // and update the parent treeitem
                const treeitems = ['record', 'parent']
                    .map(resultname => {
                        const recordgid = result[resultname]
                        const record = this._rootstore.data.records.get(recordgid)
                        if (!record) return null
                        const treeitem = new TreeStoreItem(
                            record.gid,
                            record.parent,
                            record.treelevel,
                            record.childcount,
                            record
                        )
                        return treeitem
                    })
                    .filter(treeitem => treeitem !== null)
                // update tree
                this.tree.expandItem(treeitems[1])
                this.tree.selectItem(treeitems[0])
                return result
            })
        // .catch(error => {})
    }

    schedule_refetch = () => {
        if (this._syncdebounce) {
            clearTimeout(this._syncdebounce)
            this._syncdebounce = null
        }
        this._syncdebounce = setTimeout(() => {
            this.refetch()
            this._syncdebounce = null
        }, this._syncdelay)
    }

    syncdataitem_callback = (syncdataitem, data_before, data_after) => {
        // console.log('PimTreeStore sync', syncdataitem, data_before, data_after)
        // we're only interested in data_type "records"
        // then, we're only interested in updates (of childcount)
        // from those records, only for parents that are in our store
        // from those parents, only when childcount has changed
        if (syncdataitem['data_type'] !== 'records') return
        if (syncdataitem['action'] === 'DELETE') {
            if (data_before && this.tree.findItemById(data_before.parent)) {
                this.schedule_refetch()
            }
            return
        }
        if (syncdataitem['action'] !== 'UPDATE') return
        const gid = data_before ? data_before.gid : data_after.gid
        if (!(this.tree.findItemById(gid) || this.tree.hasItemWithParentId(gid))) {
            return
        }
        if (data_before.childcount !== data_after.childcount) {
            this.schedule_refetch()
        }
    }
}

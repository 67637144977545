//
// ChannelsInspector
//
// An inspector for the properties of a PIM record (definition manager) and the channels

import React from 'react'
import { observer } from 'mobx-react-lite'

import { VView } from '../../../appview'
import { RecordChannelsPanel } from '../../../panels'

export const ChannelsInspector = observer(function ChannelsInspector({
    worksheet,
    viewkey,
}) {
    return (
        <VView grow>
            <RecordChannelsPanel record={worksheet.record} />
        </VView>
    )
})

//
// RecordData
//
// A plain RecordData object to hold properties
//
// We use a gid as a globally unique id, so where we have two records with the same id
// and a different language, we will have two gid's.
//
// The concept of a headingrecord is removed. This concept is replaced by the definition,
// class, and field configuration. When exporting to Stentor or CataloggerClassic, these
// records can be added back in from the new definitions (also: fieldselections).
//
// I know Record is a very generic word, but I mean the Unicat catalog_tree data records.
// These are not only Products but also Chapters, Brands, Categories. If anyone comes
// up with a better word that is non-generic or at least less generic, let me know.

import { makeObservable, observable, computed, action } from 'mobx'

import { updateInstanceWithData } from './utils'
import { hashData, objectFrom } from '../../utils/utils'
import { gid } from '../../utils/gid'

export class RecordData {
    gid = null
    canonical = 0
    parent = 0
    backlinks = []
    treelevel = 0
    path = []
    title = new Map()
    status = ''
    channels = []
    orderings = new Map()
    childcount = 0
    definition = null
    fieldselection = []
    fields = new Map()
    version_gid = null

    _modified_fields = new Map()

    constructor(recorddata, rootstore) {
        this.version_gid = gid()

        makeObservable(this, {
            canonical: observable,
            parent: observable,
            backlinks: observable,
            treelevel: observable,
            path: observable,
            title: observable,
            status: observable,
            channels: observable,
            orderings: observable,
            childcount: observable,
            definition: observable,
            fieldselection: observable,
            fields: observable,
            version_gid: observable,

            title_field: computed,
            image_field: computed,
            is_link: computed,
            data_hash: computed,
            localized_title: computed,
            localized_fields: computed,

            language: computed,

            update: action.bound,
            setField: action.bound,
            _setField: action.bound,
            commitIfModified: action.bound,
            setChannels: action.bound,
            copyChannelsFromParent: action.bound,
            copyChannelsDown: action.bound,
            copyChannelsUp: action.bound,
            delete: action.bound,
            undelete: action.bound,
            unlink: action.bound,

            addDefinitionField: action.bound,
            moveDefinitionField: action.bound,
            removeDefinitionField: action.bound,

            addDefinitionClass: action.bound,
            moveDefinitionClass: action.bound,
            removeDefinitionClass: action.bound,

            addDefinitionFieldlistField: action.bound,
            moveDefinitionFieldlistField: action.bound,
            removeDefinitionFieldlistField: action.bound,

            createLayoutComponent: action.bound,
            modifyLayoutComponent: action.bound,
            moveLayoutComponent: action.bound,
            deleteLayoutComponent: action.bound,

            setDefinition: action.bound,
            revertDefinition: action.bound,
            copyDefinitionToSiblings: action.bound,

            bump_version_gid: action.bound,
        })

        this._rootstore = rootstore
        this.update(recorddata)
    }

    bump_version_gid = () => {
        this.version_gid = gid()
    }

    update = recorddata => {
        updateInstanceWithData(
            this,
            recorddata,
            [
                'gid',
                'canonical',
                'parent',
                'backlinks',
                'treelevel',
                'path',
                'title',
                'status',
                'channels',
                'orderings',
                'childcount',
                'definition',
                'fieldselection',
            ],
            ['fields']
        )
        this.bump_version_gid()
    }

    get localized_title() {
        const data = this._rootstore.data
        const definition = data.definitions.get(this.definition)
        if (definition) {
            const field = data.fields.get(definition.titlefield)
            if (
                field &&
                this.localized_fields &&
                this.localized_fields.has(field.name)
            ) {
                return this.localized_fields.get(field.name)
            }
        }
        return this.title.get(this._rootstore.view.environment.get('language'))
    }

    get localized_fields() {
        return this.fields.get(this._rootstore.view.environment.get('language'))
    }

    get title_field() {
        const data = this._rootstore.data
        let definition = data.definitions.get(this.definition)
        if (!definition) return undefined
        return data.fields.get(definition.titlefield)
    }

    get image_field() {
        const data = this._rootstore.data
        const definition = data.definitions.get(this.definition)
        if (!definition) return undefined
        const imagefield = data.getFieldByName('image')
        const all_fields = [
            ...definition.fields,
            ...definition.classes
                .map(classgid => {
                    const class_ = data.classes.get(classgid)
                    return class_.fields
                })
                .reduce((result, fields) => [...result, ...fields], []),
        ]
        if (imagefield && all_fields.includes(imagefield.gid)) {
            return imagefield
        }
        for (const fieldgid of all_fields) {
            let field = data.fields.get(fieldgid)
            if (field && field.type === 'image') {
                return field
            }
        }
        return undefined
    }

    get is_link() {
        return this.gid !== this.canonical
    }

    get data_hash() {
        return hashData(this.fields)
    }

    get language() {
        return this._rootstore.view.environment.get('language')
    }

    setClassField = (key, value, recordfield) => {
        // find the right part of the data in a class/classlisst field
        // from the key (<recordgid>.<fieldname>[index].<fieldname>....etc)
        let keys = key.split('.')
        if (keys.shift() !== this.gid) {
            console.log('RecordData::setClassField RECORD GID ERROR')
        }
        keys.shift() // chop version_gid
        let keyfound = false
        let datapointer = null
        const lastpartindex = keys.length - 1
        keys.forEach((keypart, partindex) => {
            const fieldname_index = keypart.split('[')
            const fieldname = fieldname_index[0]
            const index =
                fieldname_index.length === 1
                    ? null
                    : parseInt(fieldname_index[1].replace(']', ''))
            if (datapointer === null) {
                if (recordfield.name !== fieldname) {
                    console.log('RecordData::setClassField RECORD FIELD ERROR')
                }
                if (partindex === lastpartindex) {
                    this.localized_fields.set(fieldname, value)
                } else {
                    datapointer = this.localized_fields.get(fieldname)
                }
                keyfound = true
            } else {
                if (datapointer.has(fieldname)) {
                    if (partindex === lastpartindex) {
                        datapointer.set(fieldname, value)
                    } else {
                        datapointer = datapointer.get(fieldname)
                    }
                } else {
                    console.log('RecordData::setClassField RECORD FIELD NAME ERROR')
                    keyfound = false
                }
            }
            if (index !== null) {
                if (datapointer.length && index < datapointer.length) {
                    if (partindex === lastpartindex) {
                        datapointer[index] = value
                    } else {
                        datapointer = datapointer[index]
                    }
                } else {
                    console.log('RecordData::setClassField RECORD FIELD INDEX ERROR')
                    keyfound = false
                }
            }
        })
        if (keyfound) {
            this._modified_fields.set(recordfield.gid, recordfield.name)
        }
    }

    setField = (field, value) => {
        if (['class', 'classlist'].includes(field.type)) {
            console.log("RecordData::setField ERROR - don't call on class fields!")
            return
        }
        if (!this._setField(field, value)) return false
        const title_field = this.title_field
        if (title_field && field.gid === title_field.gid) {
            this.title.set(this.language, value.toString())
        }
        return true
    }

    resetField = field => {
        // mark field as not modified
        this._modified_fields.delete(field.gid)
        // just in case something else changed
        this.commitIfModified().then(data => {
            const language = this._rootstore.view.environment.get('language')
            // if committed, we have fresh data too
            if (data === 'committed') return data
            // otherwise, nothing was committed
            // refetch to update current record to valid field values
            return this._rootstore
                ._fetch('/records/get', {
                    record: this.gid,
                    language,
                })
                .then(result => {
                    this._modified_fields = new Map()
                    return result
                })
            // .catch(error => {})
        })
    }

    _setField = (field, value) => {
        const previous_value = this.localized_fields.get(field.name)
        if (previous_value === value) return false
        const language = this._rootstore.view.environment.get('language')
        this.fields.get(language).set(field.name, value)
        this._modified_fields.set(field.gid, field.name)
        return true
    }

    commitIfModified = () => {
        // send gid + modified fields to server backend
        if (this._modified_fields.size) {
            const language = this._rootstore.view.environment.get('language')
            let fields = {}
            fields[language] = {}
            for (const [gid, fieldname] of this._modified_fields.entries()) {
                fields[this.language][gid] = this.fields.get(language).get(fieldname)
            }
            // basically fire & forget updates
            return this._rootstore
                ._fetch('/records/update', {
                    record: this.gid,
                    language,
                    fields: objectFrom(fields),
                })
                .then(result => {
                    this._modified_fields = new Map()
                    return result
                })
            // .catch(error => {})
        } else {
            return Promise.resolve(false)
        }
    }

    setChannels = (channelkeys, value) => {
        const channelvalue = value ? true : false
        channelkeys.forEach(key => {
            if (channelvalue && !this.channels.includes(key)) {
                this.channels.push(key)
            } else if (!channelvalue && this.channels.includes(key)) {
                this.channels.splice(this.channels.indexOf(key), 1)
            }
        })
        // basically fire & forget updates
        const language = this._rootstore.view.environment.get('language')
        return this._rootstore._fetch('/records/channels/set', {
            record: this.gid,
            language,
            channels: channelkeys,
            enabled: channelvalue,
        })
        // .catch(error => {})
    }

    copyChannelsFromParent = channelkeys => {
        // a reload on succes is the best way to update the complete worksheet
        const language = this._rootstore.view.environment.get('language')
        return this._rootstore
            ._fetch('/records/channels/copy_from_parent', {
                record: this.gid,
                language,
                channels: channelkeys,
            })
            .then(result => {
                this._rootstore.view.pimworksheet.refetch()
                return result
            })
        // .catch(error => {})
    }

    copyChannelsDown = channelkeys => {
        // a reload on succes is the best way to update the complete worksheet
        const language = this._rootstore.view.environment.get('language')
        return this._rootstore
            ._fetch('/records/channels/copy_down', {
                record: this.gid,
                language,
                channels: channelkeys,
            })
            .then(result => {
                this._rootstore.view.pimworksheet.refetch()
                return result
            })
        // .catch(error => {})
    }

    copyChannelsUp = channelkeys => {
        // a reload on succes is the best way to update the complete worksheet
        const language = this._rootstore.view.environment.get('language')
        return this._rootstore
            ._fetch('/records/channels/copy_up', {
                record: this.gid,
                language,
                channels: channelkeys,
            })
            .then(result => {
                this._rootstore.view.pimworksheet.refetch()
                return result
            })
        // .catch(error => {})
    }

    delete = () => {
        const language = this._rootstore.view.environment.get('language')
        return this._rootstore
            ._fetch('/records/delete', {
                record: this.gid,
                language,
            })
            .then(result => {
                this._rootstore.view.pimworksheet.refetch()
                return result
            })
        // .catch(error => {})
    }

    undelete = () => {
        const language = this._rootstore.view.environment.get('language')
        return this._rootstore
            ._fetch('/records/undelete', {
                record: this.gid,
                language,
            })
            .then(result => {
                this._rootstore.view.pimworksheet.refetch()
                return result
            })
        // .catch(error => {})
    }

    permanentDelete = () => {
        const language = this._rootstore.view.environment.get('language')
        this._rootstore.view.pimpinboard.remove(this.gid)
        return this._rootstore._fetch('/records/permanent_delete', {
            record: this.gid,
            language,
        }) // no refetch
        // .catch(error => {})
    }

    unlink = () => {
        const language = this._rootstore.view.environment.get('language')
        return this._rootstore
            ._fetch('/records/unlink', {
                record: this.gid,
                language,
            })
            .then(result => {
                // this._rootstore.view.pimworksheet.refetch()
                return result
            })
    }

    addDefinitionField = (field_gid, before_field_gid) => {
        const language = this._rootstore.view.environment.get('language')
        let data = {
            record: this.gid,
            field: field_gid,
            language,
        }
        if (before_field_gid) {
            data['before_field'] = before_field_gid
        }
        return this._rootstore._fetch('/records/definition/fields/add', data)
    }

    moveDefinitionField = (field_gid, before_field_gid) => {
        const language = this._rootstore.view.environment.get('language')
        let data = {
            record: this.gid,
            field: field_gid,
            language,
        }
        if (before_field_gid) {
            data['before_field'] = before_field_gid
        }
        return this._rootstore._fetch('/records/definition/fields/move', data)
    }

    removeDefinitionField = field_gid => {
        const language = this._rootstore.view.environment.get('language')
        return this._rootstore._fetch('/records/definition/fields/remove', {
            record: this.gid,
            field: field_gid,
            language,
        })
    }

    addDefinitionClass = (class_gid, before_class_gid) => {
        const language = this._rootstore.view.environment.get('language')
        let data = {
            record: this.gid,
            class: class_gid,
            language,
        }
        if (before_class_gid) {
            data['before_class'] = before_class_gid
        }
        return this._rootstore._fetch('/records/definition/classes/add', data)
    }

    moveDefinitionClass = (class_gid, before_class_gid) => {
        const language = this._rootstore.view.environment.get('language')
        let data = {
            record: this.gid,
            class: class_gid,
            language,
        }
        if (before_class_gid) {
            data['before_class'] = before_class_gid
        }
        return this._rootstore._fetch('/records/definition/classes/move', data)
    }

    removeDefinitionClass = class_gid => {
        const language = this._rootstore.view.environment.get('language')
        return this._rootstore._fetch('/records/definition/classes/remove', {
            record: this.gid,
            class: class_gid,
            language,
        })
    }

    addDefinitionFieldlistField = (fieldlist_key, field_gid, before_field_gid) => {
        const language = this._rootstore.view.environment.get('language')
        let data = {
            record: this.gid,
            fieldlist: fieldlist_key,
            field: field_gid,
            language,
        }
        if (before_field_gid) {
            data['before_field'] = before_field_gid
        }
        return this._rootstore._fetch('/records/definition/fieldlists/fields/add', data)
    }

    moveDefinitionFieldlistField = (fieldlist_key, field_gid, before_field_gid) => {
        const language = this._rootstore.view.environment.get('language')
        let data = {
            record: this.gid,
            fieldlist: fieldlist_key,
            field: field_gid,
            language,
        }
        if (before_field_gid) {
            data['before_field'] = before_field_gid
        }
        return this._rootstore._fetch(
            '/records/definition/fieldlists/fields/move',
            data
        )
    }

    removeDefinitionFieldlistField = (fieldlist_key, field_gid) => {
        const language = this._rootstore.view.environment.get('language')
        return this._rootstore._fetch('/records/definition/fieldlists/fields/remove', {
            record: this.gid,
            fieldlist: fieldlist_key,
            field: field_gid,
            language,
        })
    }

    createLayoutComponent = (type, parent_gid, before_component_gid, name, options) => {
        const language = this._rootstore.view.environment.get('language')
        let data = { record: this.gid, parent: parent_gid, type: type, language }
        if (name) {
            data['name'] = name
        }
        if (options) {
            data['options'] = options
        }
        if (before_component_gid) {
            data['before_component'] = before_component_gid
        }
        return this._rootstore._fetch(
            '/records/definition/layouts/components/create',
            data
        )
    }

    modifyLayoutComponent = (component_gid, updated_component) => {
        const language = this._rootstore.view.environment.get('language')
        let data = { record: this.gid, component: component_gid, language }
        if (updated_component.type) {
            data['type'] = updated_component.type
        }
        if (updated_component.name) {
            data['name'] = updated_component.name
        }
        let options = {}
        for (let property_name in updated_component) {
            if (
                [
                    'type',
                    'name',
                    'gid',
                    'isContainer',
                    'isComponentContainer',
                    'parent',
                    'parentgid',
                ].includes(property_name)
            ) {
                continue
            }
            options[property_name] = updated_component[property_name]
        }
        if (options) {
            data['options'] = options
        }
        return this._rootstore._fetch(
            '/records/definition/layouts/components/modify',
            data
        )
    }

    moveLayoutComponent = (component_gid, parent_gid, before_component_gid) => {
        const language = this._rootstore.view.environment.get('language')
        let data = {
            record: this.gid,
            component: component_gid,
            parent: parent_gid,
            language,
        }
        if (before_component_gid) {
            data['before_component'] = before_component_gid
        }
        return this._rootstore._fetch(
            '/records/definition/layouts/components/move',
            data
        )
    }

    deleteLayoutComponent = component_gid => {
        const language = this._rootstore.view.environment.get('language')
        let data = { record: this.gid, component: component_gid, language }
        return this._rootstore._fetch(
            '/records/definition/layouts/components/delete',
            data
        )
    }

    setDefinition = definition_gid => {
        const language = this._rootstore.view.environment.get('language')
        return this._rootstore._fetch('/records/definition/set', {
            record: this.gid,
            definition: definition_gid,
            language,
        })
    }

    revertDefinition = () => {
        const language = this._rootstore.view.environment.get('language')
        return this._rootstore._fetch('/records/definition/revert', {
            record: this.gid,
            language,
        })
    }

    revertDefinitionWithoutExtensions = () => {
        const data = this._rootstore.data
        const definition = data.definitions.get(this.definition)
        if (!definition) {
            return Promise.resolve(false)
        }
        const original_definition =
            definition && definition.is_extended
                ? data.definitions.get(definition.original)
                : null
        if (!original_definition) {
            return Promise.resolve(false)
        }
        let class_extensions = []
        definition.classes.forEach(class_gid => {
            const class_ = data.classes.get(class_gid)
            if (!original_definition.classes.includes(class_.gid)) {
                class_extensions.push(class_.gid)
            }
        })
        let field_extensions = []
        definition.fields.forEach(field_gid => {
            const field = data.fields.get(field_gid)
            if (!original_definition.fields.includes(field.gid)) {
                field_extensions.push(field.gid)
            }
        })
        if (!class_extensions.length && !field_extensions.length) {
            // revert if all extensions are removed
            return this.revertDefinition()
        }
        return Promise.resolve(false)
    }

    copyDefinitionToSiblings = () => {
        return this._rootstore._fetch('/records/definition/copy_to_siblings', {
            record: this.gid,
        })
    }
}

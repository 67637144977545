//
// ActionStore
//
// The ActionStore handles all actions (todo... for now it handles some)

import { makeObservable, observable, action } from 'mobx'

import { SchemaActions } from './SchemaActions'
import { RecordActions } from './RecordActions'
import { AssetActions } from './AssetActions'
import { BackupActions } from './BackupActions'

import { download_dataurl } from '../../../utils/utils'

class ActionStore {
    schema = null
    records = null
    assets = null
    backups = null

    constructor(rootstore) {
        this._rootstore = rootstore
        this.schema = new SchemaActions(this._rootstore)
        this.records = new RecordActions(this._rootstore)
        this.assets = new AssetActions(this._rootstore)
        this.backups = new BackupActions(this._rootstore)

        makeObservable(this, {
            schema: observable,
            records: observable,
            assets: observable,
            backups: observable,

            export_children_to_excel: action.bound,
            import_excel_children: action.bound,

            upload_avatar: action.bound,
            delete_avatar: action.bound,

            accept_invite: action.bound,
            decline_invite: action.bound,
            anonymous_decline_invite: action.bound,

            autocomplete_asset_filter: action.bound,

            upload_project_icon: action.bound,
            delete_project_icon: action.bound,
        })
    }

    export_children_to_excel = recordgid =>
        this._rootstore
            ._fetch('/records/excel/children', {
                record: recordgid,
                language: this._rootstore.view.environment.get('language'),
            })
            .then(result => {
                const dataurl =
                    'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
                    result['data/files'][result['children.excel']]
                download_dataurl(dataurl, result['children.excel'])
            })

    import_excel_children = upload_file =>
        this._rootstore.api
            .upload('/records/excel/update', upload_file, {
                language: this._rootstore.view.environment.get('language'),
            })
            .catch(error => {
                if (error.response) {
                    return error.response
                }
                throw error
            })
            .then(result_or_response => {
                const result =
                    result_or_response.success === undefined ? result_or_response : null
                if (result) return result
                const response = result_or_response
                if (response && response['result']['code'] === 1005) {
                    // excel update can return an error that has a report attached
                    // so need to treat it as a regular response
                    let result = response['result']['info']
                    result['state'] = 'error'
                    if ('files' in response['data']) {
                        result['data/files'] = response['data']['files']
                    }
                    return result
                }
                throw new Error('Error.import_excel_children')
            })
            .then(result => {
                if (!result['state']) {
                    result['state'] = result['report.validation'].length
                        ? 'warning'
                        : 'ok'
                }
                const dataurl =
                    'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
                    result['data/files'][result['report.excel']]
                result['dataurl'] = dataurl
                return result
            })

    upload_avatar = file =>
        this._rootstore.api
            .globalupload('/account/upload_avatar', file, {})
            .then(result => {
                return result['user']
            })

    delete_avatar = () =>
        this._rootstore._globalfetch('/account/delete_avatar', {}).then(result => {
            return result['user']
        })

    accept_invite = project_gid => {
        return this._rootstore._globalfetch('/account/accept_invite', {
            project: project_gid,
        })
    }

    decline_invite = project_gid => {
        return this._rootstore._globalfetch('/account/decline_invite', {
            project: project_gid,
        })
    }

    anonymous_decline_invite = (invitee_gid, project_gid) => {
        return this._rootstore._globalfetch('/decline_invite', {
            invitee: invitee_gid,
            project: project_gid,
        })
    }

    autocomplete_asset_filter = (name, q) =>
        this._rootstore._fetch('/assets/search/autocomplete', {
            field: name,
            fieldq: q,
            language: this._rootstore.view.environment.get('language'),
            'completions.size': 25,
        })

    upload_project_icon = (project_gid, file) =>
        this._rootstore.api
            .globalupload('/projects/upload_icon', file, { project: project_gid })
            .then(result => {
                return result['project']
            })

    delete_project_icon = project_gid =>
        this._rootstore
            ._globalfetch('/projects/delete_icon', { project: project_gid })
            .then(result => {
                return result['project']
            })
}

export default ActionStore

//
// ViewStore
//
// The ViewStore is where we keep track of the currently logged in user, the current
// project they are working on, which workspace is front and center, and the choices
// related to that workspace, such as environment and tree hierarchy for the pim
// workspace.
//
// This data is kept in LocalStorage, with a user/projectkey prefix. That way we can
// reconstruct the last state of the ui when a user revisits a project.
//
// Internally to the class we use the savePerUser/Project and loadPerUser/Project
// methods (that internally use plain save/load methods), these are not for public
// consumption.
//
// A note about storing the tree hierarchy for the pim navigator here: it is a view on
// the actual data (which is in the DataStore, and also knows about the complete
// hierarchy of course), and we can have multiple views on the same data, so it makes
// sense to store it here in the ViewStore.
// TODO: CC-209 when you collapse a node, the children can be removed from memory, but for
// speed and efficiency you want to keep them in memory, so we need to balance that out.
// Currently everything is kept, and only on a page reload is memory reclaimed.

import { makeObservable, observable, computed, action, runInAction } from 'mobx'

import { PimTreeStore } from './PimTreeStore'
import { PimQueryStore } from './PimQueryStore'
import { PimPinboardStore } from './PimPinboardStore'
import { PimWorksheetStore } from './PimWorksheetStore'
import { DamTreeStore } from './DamTreeStore'
import { DamQueryStore } from './DamQueryStore'
import { DamPinboardStore } from './DamPinboardStore'
import { DamWorksheetStore } from './DamWorksheetStore'
import { DamFolderSearchStore } from './DamFolderSearchStore'
import { SchemaTreeStore } from './SchemaTreeStore'
import { SchemaQueryStore } from './SchemaQueryStore'
import { SchemaPinboardStore } from './SchemaPinboardStore'
import { SchemaWorksheetStore } from './SchemaWorksheetStore'
import { ExcelImportStore } from './ExcelImportStore'

class ViewStore {
    user = null
    _project = null
    _workspace = null
    _recordgid = null
    _environment = new Map()
    _pimtree = null
    _pimquery = null
    _pimpinboard = null
    _pimworksheet = null
    _recordquery = null
    _assetquery = null
    _damtree = null
    _damquery = null
    _dampinboard = null
    _damworksheet = null
    _damfoldersearch = null
    _schematree = null
    _schemaquery = null
    _schemapinboard = null
    _schemaworksheet = null
    _excelimport = null

    constructor(rootstore) {
        makeObservable(this, {
            resetUser: action.bound,
            resetProject: action.bound,
            userkey: computed,
            projectkey: computed,
            isLoggedIn: computed,
            user: observable,
            _project: observable,
            _workspace: observable,
            _recordgid: observable,
            _environment: observable,
            environment: computed,
            language: computed,
            _pimtree: observable,
            //pimtree: computed,
            _pimquery: observable,
            //pimquery: computed,
            _pimpinboard: observable,
            //pimpinboard: computed,
            _pimworksheet: observable,
            //pimworksheet: computed,
            _recordquery: observable,
            //recordquery: computed,
            _assetquery: observable,
            //assetquery: computed,
            _damtree: observable,
            //damtree: computed,
            _damquery: observable,
            //damquery: computed,
            _dampinboard: observable,
            //dampinboard: computed,
            _damworksheet: observable,
            //damworksheet: computed,
            _damfoldersearch: observable,
            //damfoldersearch: computed,
            _schematree: observable,
            //schematree: computed,
            _schemaquery: observable,
            //schemaquery: computed,
            _schemapinboard: observable,
            //schemapinboard: computed,
            _schemaworksheet: observable,
            //schemaworksheet: computed,
            _excelimport: observable,
            //excelimport: computed,

            initWithUsergid: action.bound,
            setUser: action.bound,
            noProject: action.bound,
            setProject: action.bound,
            setWorkspace: action.bound,
            prepareProjectWorkspace: action.bound,
            setRecordgid: action.bound,
            updateEnvironment: action.bound,

            loadOnce: action.bound,
        })

        this._rootstore = rootstore
        this.workspaces = {
            account: {
                title: rootstore.app.text('Account settings and projects overview'),
                label: rootstore.app.text('Account'),
                icon: 'user',
            },
            settings: {
                title: rootstore.app.text('Project settings, members, and API keys'),
                label: rootstore.app.text('Project'),
                icon: 'settings',
            },
            schema: {
                title: rootstore.app.text(
                    'Construct definitions, classes, fields, and layouts'
                ),
                label: rootstore.app.text('Schema'),
                icon: 'schema',
            },
            pim: {
                title: rootstore.app.text('Product Information Management'),
                label: rootstore.app.text('Catalog'),
                icon: 'product',
            },
            dam: {
                title: rootstore.app.text('Asset Management'),
                label: rootstore.app.text('Assets'),
                icon: 'image',
            },
            // importexport: {
            //     label: rootstore.app.text('Import/Export'),
            //     icon: 'importexport',
            // },
        }

        this.resetUser()
    }

    resetUser = () => {
        this.user = null
        this.resetProject()
    }

    resetProject = () => {
        this._rootstore.api.unregister_syncdataitem_callbacks()
        this._project = null
        this._workspace = null
        this._recordgid = null
        this._environment.replace({
            language: null,
            channel: null,
            ordering: null,
            fieldlist: null,
        })
        this._pimtree = new PimTreeStore(
            '/pim/tree',
            this._rootstore,
            this._environment
        )
        this._pimquery = new PimQueryStore(
            '/pim/query',
            this._rootstore,
            this._environment
        )
        this._pimpinboard = new PimPinboardStore(
            '/pim/pinboard',
            this._rootstore,
            this._environment
        )
        this._pimworksheet = new PimWorksheetStore(
            '/pim/worksheet',
            this._rootstore,
            this._environment
        )
        this._recordquery = new PimQueryStore(
            '/record/query',
            this._rootstore,
            this._environment
        )
        this._assetquery = new DamQueryStore(
            '/asset/query',
            this._rootstore,
            this._environment
        )
        this._damtree = new DamTreeStore('/dam/tree', this._rootstore)
        this._damquery = new DamQueryStore(
            '/dam/query',
            this._rootstore,
            this._environment
        )
        this._dampinboard = new DamPinboardStore('/dam/pinboard', this._rootstore)
        this._damworksheet = new DamWorksheetStore(
            '/dam/worksheet',
            this._rootstore,
            this._environment
        )
        this._damfoldersearch = new DamFolderSearchStore(
            '/dam/foldersearch',
            this._rootstore
        )
        this._schematree = new SchemaTreeStore('/schema/tree', this._rootstore)
        this._schemaquery = new SchemaQueryStore(
            '/schema/query',
            this._rootstore,
            this._environment
        )
        this._schemapinboard = new SchemaPinboardStore(
            '/schema/pinboard',
            this._rootstore
        )
        this._schemaworksheet = new SchemaWorksheetStore(
            '/schema/worksheet',
            this._rootstore,
            this._environment
        )
        this._excelimport = new ExcelImportStore(
            '/catalog/excel/import',
            this._rootstore,
            this._environment
        )
    }

    save = (key, value) => {
        window.localStorage.setItem(key, JSON.stringify(value))
    }

    load = (key, defaultvalue = null) => {
        const localvalue = window.localStorage.getItem(key)
        if (localvalue === null || localvalue === undefined) return defaultvalue
        try {
            return JSON.parse(localvalue)
        } catch (error) {
            return defaultvalue
        }
    }

    savePerUser = (subkey, value) => {
        this.save(this.userkey + subkey, value)
    }

    loadPerUser = (subkey, defaultvalue = null) => {
        return this.load(this.userkey + subkey, defaultvalue)
    }

    savePerProject = (subkey, value) => {
        this.save(this.projectkey + subkey, value)
    }

    loadPerProject = (subkey, defaultvalue = null) => {
        return this.load(this.projectkey + subkey, defaultvalue)
    }

    savePerProjectGid = (projectgid, subkey, value) => {
        this.save(this.projectgidkey(projectgid) + subkey, value)
    }

    loadPerProjectGid = (projectgid, subkey, defaultvalue = null) => {
        return this.load(this.projectgidkey(projectgid) + subkey, defaultvalue)
    }

    get userkey() {
        return this.user ? '/u/' + this.user.gid : ''
    }

    get projectkey() {
        return this.user && this.project ? this.userkey + '/p/' + this.project.gid : ''
    }

    projectgidkey = projectgid => {
        return this.user ? this.userkey + '/p/' + projectgid : ''
    }

    get isLoggedIn() {
        return this.user !== null
    }

    initWithUsergid = (usergid, projectgid) => {
        this.setUser(this._rootstore.data.users.get(usergid))
        if (this._rootstore.location === this._rootstore.LOCATIONS.ACCOUNT) {
            this.setProject(null)
        } else if (projectgid) {
            if (!this.project || this.project.gid !== projectgid) {
                this.setProject(this._rootstore.data.projects.get(projectgid))
            }
        } else {
            this.noProject()
        }
        // check if stored environment properties are still valid for current project
        this.updateProjectEnvironment()
    }

    updateProjectEnvironment = () => {
        if (this.project) {
            const properties = {
                language: this.project.languages, // array
                channel: this.project.channels, // map
                ordering: this.project.orderings, // map
                fieldlist: this.project.fieldlists, // map
            }
            let environment = {}
            for (let [property, options] of Object.entries(properties)) {
                if (!options) continue
                if (options.includes) {
                    if (!options.includes(this.environment.get(property))) {
                        environment[property] = options[0]
                    }
                } else if (!options.has(this.environment.get(property))) {
                    environment[property] = Array.from(options.keys())[0]
                }
            }
            this.updateEnvironment(environment)
            this.loadOnce()
        }
    }

    setUser = user => {
        this.resetUser()
        this.user = user
    }

    loadOnce = () => {
        if (!this.project) return null
        this._pimtree.loadOnce()
        this._pimquery.loadOnce()
        this._pimpinboard.loadOnce()
        this._pimworksheet.loadOnce()
        this._recordquery.loadOnce()
        this._assetquery.loadOnce()
        this._damtree.loadOnce()
        this._damquery.loadOnce()
        this._dampinboard.loadOnce()
        this._damworksheet.loadOnce()
        this._damfoldersearch.loadOnce()
        this._schematree.loadOnce()
        this._schemaquery.loadOnce()
        this._schemapinboard.loadOnce()
        this._schemaworksheet.loadOnce()
        this._excelimport.loadOnce()
    }

    get project() {
        if (this._rootstore.location !== this._rootstore.LOCATIONS.PROJECT) {
            return null
        }
        if (!this.user) return null
        if (!this._project) {
            const projectgid = this.loadPerUser('/p')
            if (projectgid && this._rootstore.data.projects.has(projectgid)) {
                this._project = this._rootstore.data.projects.get(projectgid)
            }
        }
        return this._project
    }

    noProject = () => {
        this.resetProject()
        this._project = null
    }

    setProject = project => {
        this.resetProject()
        this._project = project
        this.savePerUser('/p', this._project && this._project.gid)
    }

    get workspace() {
        if (!this.user && !this.project) return null
        if (!this._workspace) this._workspace = this.loadPerProject('/ws')
        if (!this._workspace) this._workspace = 'settings'
        return this._workspace
    }

    setWorkspace = workspacename => {
        this._workspace = workspacename
        if (
            ![
                'account',
                'settings',
                'schema',
                'pim',
                'dam',
                'importexport',
                'lab',
            ].includes(workspacename)
        ) {
            this._workspace = 'lab'
        }
        this.savePerProject('/ws', this._workspace)
        if (
            ['settings', 'schema', 'pim', 'dam', 'importexport'].includes(
                this._workspace
            )
        ) {
            this.savePerProject('/pws', this._workspace)
        }
    }

    prepareProjectWorkspace = projectgid => {
        const pws = this.loadPerProjectGid(projectgid, '/pws')
        this.savePerProjectGid(projectgid, '/ws', pws ? pws : 'settings')
    }

    get recordgid() {
        if (!this.user && !this.project) return null
        if (!this._recordgid) this._recordgid = this.loadPerProject('/pim/rec')
        return this._recordgid
    }

    setRecordgid = recordgid => {
        this._recordgid = recordgid
        this.savePerProject('/pim/rec', this._recordgid)
    }

    get environment() {
        if (!this.user && !this.project) return null
        // test first value, everything should be set if one is set
        const language = this._environment.get('language')
        if (language === null || language === undefined) {
            const storedenvironment = this.loadPerProject('/pim/env')
            if (storedenvironment) {
                runInAction(() => this._environment.replace(storedenvironment))
            }
        }
        return this._environment
    }

    get language() {
        const language = this.environment.get('language')
        return language ? language : 'en'
    }

    updateEnvironment = environmentdata => {
        let needsSaving = false
        this._environment.forEach((_, key) => {
            if (
                environmentdata.hasOwnProperty(key) &&
                environmentdata[key] !== this._environment.get(key)
            ) {
                this._environment.set(key, environmentdata[key])
                needsSaving = true
            }
        })
        if (needsSaving) {
            this.savePerProject('/pim/env', this._environment)
        }
    }

    get pimtree() {
        if (!this.user && !this.project) return null
        return this._pimtree.tree
    }

    get pimquery() {
        if (!this.user && !this.project) return null
        return this._pimquery
    }

    get pimpinboard() {
        if (!this.user && !this.project) return null
        return this._pimpinboard
    }

    get pimworksheet() {
        if (!this.user && !this.project) return null
        return this._pimworksheet
    }

    get recordquery() {
        if (!this.user && !this.project) return null
        return this._recordquery
    }

    get assetquery() {
        if (!this.user && !this.project) return null
        return this._assetquery
    }

    get damtree() {
        if (!this.user && !this.project) return null
        return this._damtree.tree
    }

    get damquery() {
        if (!this.user && !this.project) return null
        return this._damquery
    }

    get dampinboard() {
        if (!this.user && !this.project) return null
        return this._dampinboard
    }

    get damworksheet() {
        if (!this.user && !this.project) return null
        return this._damworksheet
    }

    get damfoldersearch() {
        if (!this.user && !this.project) return null
        return this._damfoldersearch
    }

    get schematree() {
        if (!this.user && !this.project) return null
        return this._schematree.tree
    }

    get schemaquery() {
        if (!this.user && !this.project) return null
        return this._schemaquery
    }

    get schemapinboard() {
        if (!this.user && !this.project) return null
        return this._schemapinboard
    }

    get schemaworksheet() {
        if (!this.user && !this.project) return null
        return this._schemaworksheet
    }

    get excelimport() {
        if (!this.user && !this.project) return null
        return this._excelimport
    }
}

export default ViewStore

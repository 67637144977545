//
// WelcomeScreen
//
// Show this when we're not anywhere in /p
// We can be logged in or out

import React from 'react'
import { useStore } from '../stores'
import { observer } from 'mobx-react-lite'

import { SiteScreen } from './SiteScreen'

export const WelcomeScreen = observer(function WelcomeScreen(props) {
    const store = useStore()

    // TODO: CC-81
    // for now, just show welcome text and login status
    // I'm also not using language switching here yet
    // I want this in some other code as well, no need to include the
    // marketing site code and the unicat app code in the same bundle
    // perhaps we only want the latter to be available after login...

    const UserLinks = store.isLoggedIn ? (
        <>
            <span onClick={() => store.autoLocation()}>{'Hi, ' + store.user.name}</span>
            {' - '}
            <span onClick={() => store.logout()}>Log out</span>
        </>
    ) : (
        <>
            <span onClick={() => store.setLocation(store.LOCATIONS.LOGIN)}>Log in</span>
            {store.can('account.signup') && (
                <>
                    {' - '}
                    <span onClick={() => store.setLocation(store.LOCATIONS.SIGNUP)}>
                        Sign up
                    </span>
                </>
            )}
        </>
    )

    return (
        <SiteScreen>
            <div className="avatar">
                <img alt="Unicat logo" title="Unicat" src="/unicat-logo.png" />
            </div>

            <h1>Welcome to Unicat</h1>

            <p className="userlink">{UserLinks}</p>

            {store.can('dev.notes') && (
                <>
                    <br />

                    <hr />

                    <br />
                    <br />

                    <header>Next up in development</header>
                    <p>ETIM support</p>

                    <header>29-07-2024</header>
                    <p>v2024.07.003 - Workers-sync-listeners reconnection logic</p>

                    <header>09-07-2024</header>
                    <p>v2024.07.002 - Fix Chrome paste-multiple-values bug</p>

                    <header>03-07-2024</header>
                    <p>v2024.07.001 - Fix scalable .eps images</p>

                    <header>27-06-2024</header>
                    <p>
                        v2024.06.004 - Fix asset update fix
                        <br />
                        v2024.06.003 - Asset update fix and highlightMatchingText fix
                    </p>

                    <header>11-06-2024</header>
                    <p>v2024.06.002 - Nested class(list) fields fix</p>

                    <header>04-06-2024</header>
                    <p>
                        v2024.06.001 - Read-only fields in Catalog worksheet layouts +
                        Exception email fix
                    </p>

                    <header>15-04-2024</header>
                    <p>v2024.04.002 - Search fix</p>

                    <header>10-04-2024</header>
                    <p>v2024.04.001 - UI fixes and optimizations</p>

                    <header>26-02-2024</header>
                    <p>v2024.02.003 - fix backup selection UI</p>

                    <header>20-02-2024</header>
                    <p>v2024.02.002 - restore backup fix for rebuild solr</p>

                    <header>05-02-2024</header>
                    <p>
                        v2024.02.001 - multiple backups (public release), code
                        migrations (public release), ui fixes
                    </p>

                    <header>30-01-2024</header>
                    <p>v2024.01.007 - multiple backups, code migrations</p>

                    <header>09-01-2024</header>
                    <p>
                        v2024.01.003 - more logging, complete round 1 of security checks
                        by Detectify
                    </p>

                    <header>08-01-2024</header>
                    <p>
                        v2024.01.002 - cors setting on google cloud storage buckets
                        <br />
                        v2024.01.001 - svg scaling fix, username availability fix,
                        search fix, asset download fix
                    </p>

                    <header>28-12-2023</header>
                    <p>v2023.12.002 - redesigned login/signup/invite flow</p>

                    <header>20-12-2023</header>
                    <p>v2023.12.001 - styled emails & assorted fixes</p>

                    <header>05-10-2023</header>
                    <p>v2023.10.002 - get_unique_name performance fix</p>

                    <header>04-10-2023</header>
                    <p>v2023.10.001 - Content-security policy, fixes, and cleanup</p>

                    <header>31-08-2023</header>
                    <p>
                        v2023.08.006 - Add Sentry & fix UnicatConnect module packaging
                    </p>

                    <header>30-08-2023</header>
                    <p>v2023.08.005 - UI fix asset hotspot usage</p>

                    <header>29-08-2023</header>
                    <p>v2023.08.004 - Headers and CSP-reporting</p>

                    <header>22-08-2023</header>
                    <p>
                        v2023.08.003 - Public docs & downloads, project.create/delete
                        permission for Guidance user
                    </p>

                    <header>14-08-2023</header>
                    <p>v2023.08.002 - Clean up jobs, unicat-lib support for jobs</p>

                    <header>10-08-2023</header>
                    <p>v2023.08.001 - Workers service</p>

                    <header>31-05-2023</header>
                    <p>v2023.05.004 - fix UI crash</p>

                    <header>30-05-2023</header>
                    <p>v2023.05.003 - Bugfixes and environment variables</p>

                    <header>08-05-2023</header>
                    <p>v2023.05.002 - Assorted UI fixes</p>

                    <header>02-05-2023</header>
                    <p>v2023.05.001 - Assorted fixes</p>

                    <header>16-02-2023</header>
                    <p>v2023.02.002 - Search UI tweaks</p>

                    <header>10-02-2023</header>
                    <p>v2023.02.001 - Records ordering</p>

                    <header>25-01-2023</header>
                    <p>v2023.01.003 - Unicat Connect 1.0 & project icons</p>

                    <header>03-01-2023</header>
                    <p>
                        v2023.01.002 - Better password hashing & internal config
                        <br />
                        v2023.01.001 - Unicat connect & fixes
                    </p>

                    <header>22-11-2022</header>
                    <p>
                        v2022.11.005 - Fix record fields inspector
                        <br />
                        v2022.11.004 - Fix Excel import & download images
                    </p>

                    <header>21-11-2022</header>
                    <p>v2022.11.003 - Testing & fixes</p>

                    <header>15-11-2022</header>
                    <p>v2022.11.002 - Upgrade software dependencies</p>

                    <header>07-11-2022</header>
                    <p>v2022.11.001 - Unicat & Production deployment preparation</p>

                    <header>31-10-2022</header>
                    <p>v2022.10.002 - Beta preparation</p>

                    <header>20-10-2022</header>
                    <p>v2022.10.001 - Cloud storage</p>

                    <header>26-08-2022</header>
                    <p>
                        v2022.08.008 - Assets move nested selection fix & complete
                        languages list
                    </p>

                    <header>25-08-2022</header>
                    <p>
                        v2022.08.007 - multi-select fixes & prevent duplicate asset &
                        folder names
                        <br />
                        v2022.08.006 - Multi-select UI and bulk APIs - part 3a
                        <br />
                        v2022.08.005 - Multi-select UI and bulk APIs - part 3
                    </p>

                    <header>24-08-2022</header>
                    <p>
                        v2022.08.004 - Multi-select UI and bulk APIs - part 2a
                        <br />
                        v2022.08.003 - Multi-select UI and bulk APIs - part 2
                    </p>

                    <header>18-08-2022</header>
                    <p>
                        v2022.08.002 - Multi-select UI and bulk APIs - part 1a
                        <br />
                        v2022.08.001 - Multi-select UI and bulk APIs - part 1
                    </p>

                    <header>20-07-2022</header>
                    <p>v2022.07.004 - Record/recordlist search fix</p>

                    <header>18-07-2022</header>
                    <p>v2022.07.003 - Excel missing language fix</p>

                    <header>07-07-2022</header>
                    <p>v2022.07.002 - Auto-row-height for LazyVList/Tiles/Table</p>

                    <header>04-07-2022</header>
                    <p>v2022.07.001 - Record tree refactor</p>

                    <header>03-06-2022</header>
                    <p>v2022.06.001 - Contextmenu fixes & Excel import panel</p>

                    <header>30-05-2022</header>
                    <p>v2022.05.004 - Excel import fixes</p>

                    <header>20-05-2022</header>
                    <p>v2022.05.003 - Set default project language</p>

                    <header>19-05-2022</header>
                    <p>v2022.05.002 - Excel export/import</p>

                    <header>05-05-2022</header>
                    <p>v2022.05.001 - Class/classlist and other fixes</p>

                    <header>22-04-2022</header>
                    <p>
                        v2022.04.001 - Class/classlist field type & new test project
                        schema
                    </p>

                    <header>25-03-2022</header>
                    <p>
                        v2022.03.006 - Barcode field fix and fieldpicker field type
                        <br />
                        v2022.03.005 - Change/revert definition shows lost fields
                    </p>

                    <header>24-03-2022</header>
                    <p>v2022.03.004 - Extended definitions rethink</p>

                    <header>16-03-2022</header>
                    <p>v2022.03.003 - Move/copy/link rethink</p>

                    <header>10-03-2022</header>
                    <p>v2022.03.002 - .001 fixer-upper</p>

                    <header>08-03-2022</header>
                    <p>
                        v2022.03.001 - UI fixes, Textlist fieldeditor, Textlist values
                        option
                    </p>

                    <header>17-02-2022</header>
                    <p>v2022.02.003 - UI and search/filter fixes</p>

                    <header>03-02-2022</header>
                    <p>
                        v2022.02.002 - UI and sync bugfixes
                        <br />
                        v2022.02.001 - first db-migration step for v2022.02.002
                    </p>

                    <header>20-01-2022</header>
                    <p>v2022.01.002 - Fix PostgreSQL backup/restore</p>

                    <header>19-01-2022</header>
                    <p>
                        v2022.01.001 - Upgrade apps & libraries to newest versions,
                        development environment
                    </p>

                    <header>16-12-2021</header>
                    <p>
                        v2021.12.005 - Drag-n-drop on folder-worksheet, autocomplete
                        path filter for asset search
                    </p>

                    <header>15-12-2021</header>
                    <p>v2021.12.004 - Small(ish) fixes and improvements</p>

                    <header>14-12-2021</header>
                    <p>
                        v2021.12.003 - more UI sync fixes
                        <br />
                        v2021.12.002 - UI sync fixes
                    </p>

                    <header>9-12-2021</header>
                    <p>v2021.12.001 - Multi-user Part 2 - UI sync</p>

                    <header>25-11-2021</header>
                    <p>v2021.11.002 - Fix login UI</p>

                    <header>24-11-2021</header>
                    <p>v2021.11.001 - Multi-user Part 1 - members and api-keys</p>

                    <header>21-10-2021</header>
                    <p>
                        v2021.10.004 - More filters fixes
                        <br />
                        v2021.10.003 - Fix adding 'not' to the filters
                    </p>

                    <header>20-10-2021</header>
                    <p>v2021.10.002 - Search with filters and Save/Open queries</p>

                    <header>04-10-2021</header>
                    <p>
                        v2021.10.001 - Fix too many rerenders, zero-width empty fields,
                        search-results name word-wrap
                    </p>

                    <header>02-09-2021</header>
                    <p>v2021.09.001 - Search UI prototyping</p>

                    <header>25-08-2021</header>
                    <p>
                        v2021.08.009 - Search API for assets, records,
                        definitions/classes/fields
                    </p>

                    <header>18-08-2021</header>
                    <p>v2021.08.008 - Fix initial field value issues</p>

                    <header>16-08-2021</header>
                    <p>
                        v2021.08.007 - More empty layout components fixes & whitespace
                        in folder is allowed
                    </p>

                    <header>13-08-2021</header>
                    <p>
                        v2021.08.006 - DAM accepts more color profiles and converts to
                        sRGB profile
                        <br />
                        v2021.08.005 - Fix layout in pim workspace, asset search on root
                        folder
                    </p>

                    <header>11-08-2021</header>
                    <p>v2021.08.004 - Fix empty layout components</p>

                    <header>10-08-2021</header>
                    <p>v2021.08.003 - Fix definition workspace search results</p>

                    <header>09-08-2021</header>
                    <p>v2021.08.002 - Layout tree/preview show field labels</p>

                    <header>04-08-2021</header>
                    <p>
                        v2021.08.001 - Fix required-name, localized label and titlefield
                        issues
                    </p>

                    <header>28-07-2021</header>
                    <p>v2021.07.003 - Fix class commit issue</p>

                    <header>27-07-2021</header>
                    <p>
                        v2021.07.002 - Fix definition layout navigation and search
                        filter issues
                    </p>

                    <header>22-07-2021</header>
                    <p>v2021.07.001 - New definitions/classes/fields workspace</p>

                    <header>23-03-2021</header>
                    <p>v2021.03.001 - API refactor & documentation</p>

                    <header>23-02-2021</header>
                    <p>v2021.02.008 - Definition context-menu</p>

                    <header>11-02-2021</header>
                    <p>
                        v2021.02.007 - Fix field without options (NULL)
                        <br />
                        v2021.02.006 - Moar fixing!
                    </p>

                    <header>10-02-2021</header>
                    <p>v2021.02.005 - More text input fixing</p>

                    <header>09-02-2021</header>
                    <p>v2021.02.004 - Fix text input</p>

                    <header>02-02-2021</header>
                    <p>
                        v2021.02.003 - Autocomplete for decimal places & barcode
                        checksum
                        <br />
                        v2021.02.002 - Workers for DB change notifications
                    </p>

                    <header>01-02-2021</header>
                    <p>v2021.02.001 - Field options and validation</p>

                    <header>24-11-2020</header>
                    <p>v2020.11.001 - Linked asset(-list) and record(-list) fields</p>

                    <header>21-10-2020</header>
                    <p>v2020.10.002 - Search in PIM and DAM</p>

                    <header>07-10-2020</header>
                    <p>v2020.10.001 - Search back-end</p>

                    <header>06-08-2020</header>
                    <p>v2020.08.001 - PIM fieldlists</p>

                    <header>31-07-2020</header>
                    <p>v2020.07.002 - Quick backup/restore</p>

                    <header>29-07-2020</header>
                    <p>
                        v2020.07.001 - Layout editor and class/field inspector updates
                    </p>

                    <header>11-05-2020</header>
                    <p>v2020.05.001 - Project settings</p>

                    <header>29-04-2020</header>
                    <p>
                        v2020.04.006 - Copy lab-assets when creating a new project
                        <br />
                        v2020.04.005 - Fix 'drop on root' issue
                        <br />
                        v2020.04.004 - Field labels in worksheet and styling tweaks
                        <br />
                        v2020.04.003 - Firefox and small bugfixes
                        <br />
                    </p>

                    <header>24-04-2020</header>
                    <p>v2020.04.002 - Allfields layout supports grouped classes</p>

                    <header>22-04-2020</header>
                    <p>v2020.04.001 - Create new projects</p>

                    <header>25-03-2020</header>
                    <p>v2020.03.001 - A first DAM version and imageserver</p>

                    <header>25-02-2020</header>
                    <p>v2020.02.002 - PIM context-menu update</p>

                    <header>12-02-2020</header>
                    <p>v2020.02.001 - A test version and a Reset Database button</p>

                    <header>07-01-2020</header>
                    <p>v2020.01.001 - A first PIM version</p>

                    <header>18-07-2019</header>
                    <p>Start development</p>
                </>
            )}
        </SiteScreen>
    )
})

//
// ImportExportWorksheet
//
// Import/export worksheet

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../stores'

import { VView } from '../../appview'
import { Text } from '../../components'

export const ImportExportWorksheet = observer(function ImportExportWorksheet({ user }) {
    const { app } = useStore()

    return (
        <VView grow className="worksheet worksheet-importexport">
            <Text>{app.text('')}</Text>
        </VView>
    )
})

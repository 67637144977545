//
// VerticalLayoutBuilder
//
// Based on view info from a layout

import React from 'react'
import { observer } from 'mobx-react-lite'

import { VView } from '../../../../appview'
import { LayoutBuilder } from '..'
import { sizetypeconverter } from '../../../../stores/data/LAYOUTCOMPONENTTYPECONFIG'

export const VerticalLayoutBuilder = observer(function VerticalLayoutBuilder({
    className,
    component,
    components,
    layout,
    record,
    worksheet,
    active_class,
    visited_gids,
    force_readonly,
}) {
    const size = sizetypeconverter(component.size) // old & new value for 'size' prop
    let attributes = { style: {}, className: 'layout-component' }
    if (className) attributes['className'] += ' ' + className
    if (size.grow === 'grow') {
        attributes['grow'] = size.value
    } else if (size.grow === 'fixed') {
        attributes['style']['flexBasis'] = size.value + 'px'
        attributes['style']['maxWidth'] = size.value + 'px'
        attributes['style']['minWidth'] = size.value + 'px'
    }
    if (component.gid === worksheet.layoutstore.selectedComponentGid) {
        attributes['className'] += ' cc-selected'
    }
    // attributes["onClick"] = e => onClickElement(e, component, layouttreestore)
    const Children =
        component.content && component.content.length
            ? component.content.map(subelement_gid => {
                  return (
                      <LayoutBuilder
                          key={subelement_gid}
                          component={components.get(subelement_gid)}
                          components={components}
                          layout={layout}
                          record={record}
                          worksheet={worksheet}
                          active_class={active_class}
                          visited_gids={[
                              ...visited_gids,
                              component.gid,
                              component.original,
                          ]}
                          force_readonly={force_readonly}
                      />
                  )
              })
            : undefined
    return <VView {...attributes}>{Children}</VView>
})

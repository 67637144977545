//
// LazyVScrollList, LazyHScrollList, LazyVScrollTiles, LazyHScrollTiles, LazyScrollItem
//
// REQUIRES useOnElementResize hook(¹)
//
// TODO: CC-202 handle right-to-left setting (dir="rtl") for LazyHScrollList
//
// A ScrollList fits the available space. If its contents don't fit, you can scroll
// around in this view. A LazyHScrollList limits this to horizontal scrolling, while
// a LazyVScrollList only allows vertical scrolling. Same for LazyHScrollTiles and LazyVScrollTiles,
// but these show a specific number of items per column or row, respectively.
//
// Its contents are a list of items, where only the visible items are rendered. You are
// responsible for rendering these, using the index of the first item, and the number of
// visible items. These two properties are provided by the containers through an
// onUpdate event, which triggers on scrolling and on resizing. Each rendered item must
// be inside a LazyScrollItem container.
//
// Basics: one appropriately-sized (based on item width/height, total number of items,
// and items per row or column) view inside a scrollview will give the right scrollbar
// indicator and behavior. The actual items are absolutely positioned; these are only
// the ones that are currently visible.
//
// Usage:
//
//     import React, { useState } from 'react'
//     import { HView, VView, LazyVScrollList, LazyScrollItem } from '../../appview'
//
//
//     export const ListWindow = () => {
//
//         const itemheight = 19
//         const [totalsize, setTotalsize] = useState(99999)
//         const [firstindex, setFirstindex] = useState(0)
//         const [windowsize, setWindowsize] = useState(10)
//
//         const onUpdate = (newfirstindex, newwindowsize) => {
//             setFirstindex(newfirstindex)
//             setWindowsize(newwindowsize)
//         }
//
//         const renderedfirstindex = Math.max(0, Math.min(totalsize - windowsize, firstindex))
//         const renderedwindowsize = Math.min(windowsize, totalsize)
//         const rows = [...Array(renderedwindowsize).keys()].map(index => {
//             return (
//                 <LazyScrollItem key={index + renderedfirstindex}
//                                  index={index + renderedfirstindex}
//                                  >
//                     <div style={{padding: "2px 5px"}}>Item {index + renderedfirstindex} ({index})</div>
//                 </LazyScrollItem>
//             )
//         })
//
//         return (
//             <HView style={{padding: 20}}>
//                 <VView style={{width: 300}}>
//                     <LazyVScrollList
//                         firstindex={firstindex}
//                         itemheight={itemheight}
//                         totalitems={totalsize}
//                         onUpdate={onUpdate}
//                     >
//                         {rows}
//                     </LazyVScrollList>
//                     <div onClick={() => { setFirstindex(0) }}>scroll to Top</div>
//                     <div onClick={() => { setFirstindex(Math.ceil(totalsize/2)) }}>scroll to Middle</div>
//                     <div onClick={() => { setFirstindex(totalsize) }}>scroll to Bottom</div>
//                     <div onClick={() => { setTotalsize(10) }}>set total to 10 items</div>
//                     <div onClick={() => { setTotalsize(windowsize) }}>set total to {windowsize} items</div>
//                     <div onClick={() => { setTotalsize(100) }}>set total to 100 items</div>
//                     <div onClick={() => { setTotalsize(99999) }}>set total to 99999 items</div>
//                     <div>
//                        firstindex: {firstindex}, windowsize: {windowsize}, totalsize: {totalsize}
//                     </div>
//                 </VView>
//             </HView>
//         )
//     }
//
//
// (¹) useOnResize hook contents:
//
//     // useOnElementResize
//     //
//     // When you need the dimensions of the current element, e.g. to determine the number of
//     // items that can fit.
//     //
//     // Usage:
//     //
//     //     const [ref, height] = useOnElementResize(400)
//     //     return (
//     //         <View ref={ref}>
//     //             Height: {height}
//     //         </View>
//     //     )
//
//     import { useRef, useState, useLayoutEffect } from 'react'
//
//     export const useOnElementResize = (initialwidth, initialheight) => {
//         const [width, setWidth] = useState(initialwidth)
//         const [height, setHeight] = useState(initialheight)
//         const ref = useRef(null)
//
//         const resizeobserver = useRef(null)
//
//         useLayoutEffect(() => {
//             if (!ref.current) return
//
//             if (resizeobserver.current && ref.current) {
//                 resizeobserver.current.unobserve(ref.current)
//             }
//
//             resizeobserver.current = new ResizeObserver(entries => {
//                 // window.requestAnimationFrame tries to prevent
//                 // 'ResizeObserver loop completed with undelivered notifications.'
//                 window.requestAnimationFrame(() => {
//                     if (!ref.current) return
//                     for (let entry of entries) {
//                         setWidth(Math.round(entry.contentRect.width))
//                         setHeight(Math.round(entry.contentRect.height))
//                     }
//                 })
//             })
//             const element = ref.current
//             resizeobserver.current.observe(element)
//
//             return () => {
//                 resizeobserver.current.unobserve(element)
//             }
//         }, [])
//
//         return [ref, { width, height }]
//     }

import React, { useState, useRef, useLayoutEffect } from 'react'
import { useOnElementResize } from '../hooks/useOnElementResize'
import { useDebounce } from '../hooks/useDebounce'
import { getContentRect } from '../utils/dom'

const DEBOUNCE_MAGIC_NUMBER = 40 // milliseconds

export const LazyScrollItem = React.forwardRef(function LazyScrollItem(
    {
        className,
        index,
        autoheight,
        minheight,
        height,
        width,
        columns,
        rows,
        gap,
        children,
        style,
    },
    ref
) {
    let classes = 'av-LazyScrollItem'
    if (className) classes += ' ' + className

    const positioning = !width
        ? // LazyVScrollList
          {
              top: (height + gap) * index,
              height: autoheight ? 'auto' : height,
              minHeight: autoheight ? minheight : height,
          }
        : !height
        ? // LazyHScrollList
          { left: (width + gap) * index, width: width }
        : !rows && !columns
        ? // Error really, but handle like LazyVScrollList
          { top: (height + gap) * index, height: height }
        : columns
        ? // LazyVScrollTiles
          {
              left: (width + gap) * (index % columns),
              top: (height + gap) * Math.floor(index / columns),
              width: width,
              height: autoheight ? 'auto' : height,
              minHeight: autoheight ? minheight : height,
          }
        : // LazyHScrollTiles
          {
              left: (width + gap) * Math.floor(index / rows),
              top: (height + gap) * (index % rows),
              width: width,
              height: height,
          }
    return (
        <div ref={ref} className={classes} style={{ ...(style || {}), ...positioning }}>
            {children}
        </div>
    )
})

export const LazyHScrollList = props => {
    const { grow, className, style } = props
    let classes = 'av-LazyHScrollList'
    let styles = {}
    if (grow) {
        if (grow !== true) {
            styles = { flexGrow: grow }
        }
    }
    if (className) classes += ' ' + className
    if (style) styles = Object.assign({}, styles, style)

    const {
        firstindex: propsfirstindex,
        itemwidth,
        gap: propsgap,
        totalitems,
        onUpdate,
        scrollToFirst,
        children,
    } = props
    const [initialfirstindex, setInitialFirstindex] = useState(propsfirstindex)
    const [firstindex, setFirstindex] = useState(propsfirstindex)
    const [visibleitemcount, setVisibleitemcount] = useState(10)
    const [scrollingContainerRef, elementsize] = useOnElementResize(500, 250)
    const scrollingContainerRefCurrent = scrollingContainerRef.current
    const [debounceScrolling, setDebounceScrolling] = useState(null)

    const gap = propsgap || 0
    const columnwidth = itemwidth + gap

    // initially set correct scroll position
    useLayoutEffect(() => {
        if (scrollingContainerRefCurrent) {
            if (
                scrollingContainerRefCurrent.scrollLeft <
                    initialfirstindex * columnwidth ||
                scrollingContainerRefCurrent.scrollLeft >
                    (initialfirstindex + visibleitemcount + 1) * columnwidth
            ) {
                scrollingContainerRefCurrent.scrollLeft =
                    initialfirstindex * columnwidth
            }
        }
    }, [
        initialfirstindex,
        columnwidth,
        totalitems,
        visibleitemcount,
        scrollingContainerRefCurrent,
    ])

    // when the firstindex changed externally (e.g. by setting it to 0) instead of
    // internally, we have to update our internal state and scroll position
    useLayoutEffect(() => {
        if (initialfirstindex !== propsfirstindex) {
            setInitialFirstindex(propsfirstindex)
            if (firstindex !== propsfirstindex) {
                setFirstindex(propsfirstindex)
                if (scrollingContainerRefCurrent) {
                    scrollingContainerRefCurrent.scrollLeft =
                        propsfirstindex * columnwidth
                }
                onUpdate && onUpdate(propsfirstindex, visibleitemcount)
            }
        }
    }, [
        totalitems,
        columnwidth,
        firstindex,
        initialfirstindex,
        propsfirstindex,
        visibleitemcount,
        onUpdate,
        scrollingContainerRefCurrent,
    ])

    // when scrollToFirst is true, scroll to left
    useLayoutEffect(() => {
        if (scrollToFirst) {
            if (scrollingContainerRefCurrent) {
                scrollingContainerRefCurrent.scrollLeft = 0
            }
        }
    }, [scrollToFirst, scrollingContainerRefCurrent])

    // when the width of the container changes (which is predicated on a change of the
    // elementsize) we need to recalculate how many items would be visible
    useLayoutEffect(() => {
        const newvisibleitemcount = elementsize
            ? 1 + Math.ceil(elementsize.width / columnwidth)
            : // we add one even though we already round up, so both the left and right
              // items can be partially visible
              10
        if (visibleitemcount !== newvisibleitemcount) {
            setVisibleitemcount(newvisibleitemcount)
            onUpdate && onUpdate(firstindex, newvisibleitemcount)
        }
    }, [elementsize, totalitems, columnwidth, firstindex, visibleitemcount, onUpdate])

    const onScroll = event => {
        const { scrollLeft } = event.currentTarget
        // Prevent Safari's elastic scrolling from causing visual shaking when scrolling past bounds.
        const scrollFirstindex = Math.max(
            0,
            Math.min(
                Math.floor(scrollLeft / columnwidth),
                totalitems - visibleitemcount + 1
            )
            // the + 1 is to compensate for the one we added to the newvisibleitemcount
        )
        if (firstindex === scrollFirstindex) {
            return
        }
        if (debounceScrolling) {
            window.clearTimeout(debounceScrolling)
            setDebounceScrolling(null)
        }
        setDebounceScrolling(
            window.setTimeout(() => {
                setDebounceScrolling(null)
                setFirstindex(scrollFirstindex)
                onUpdate && onUpdate(scrollFirstindex, visibleitemcount)
            }, DEBOUNCE_MAGIC_NUMBER)
        )
    }

    const Items =
        children && children.map
            ? children.map((child, index) => {
                  if (!child) return null
                  let extraprops = {
                      width: itemwidth,
                      gap: gap,
                  }
                  return React.cloneElement(child, extraprops)
              })
            : children

    return (
        <div
            ref={scrollingContainerRef}
            onScroll={onScroll}
            className={classes}
            style={styles}
        >
            <div style={{ width: totalitems * columnwidth - gap }}>{Items}</div>
        </div>
    )
}

export const LazyVScrollList = props => {
    const { grow, className, style } = props
    let classes = 'av-LazyVScrollList'
    let styles = {}
    if (grow) {
        if (grow !== true) {
            styles = { flexGrow: grow }
        }
    }
    if (className) classes += ' ' + className
    if (style) styles = Object.assign({}, styles, style)

    const {
        firstindex: propsfirstindex,
        itemheight,
        calculateitemheight,
        gap: propsgap,
        totalitems,
        onUpdate,
        scrollToFirst,
        children,
    } = props
    const [initialfirstindex, setInitialFirstindex] = useState(propsfirstindex)
    const [firstindex, setFirstindex] = useState(propsfirstindex)
    const [visibleitemcount, setVisibleitemcount] = useState(10)
    const [scrollingContainerRef, elementsize] = useOnElementResize(250, 500)
    const scrollingContainerRefCurrent = scrollingContainerRef.current
    const [debounceScrolling, setDebounceScrolling] = useState(null)

    const [calculateditemheight, setCalculatedItemheight] = useState(itemheight)

    const gap = propsgap || 0
    const rowheight = calculateditemheight + gap

    // initially set correct scroll position
    useLayoutEffect(() => {
        if (scrollingContainerRefCurrent) {
            if (
                scrollingContainerRefCurrent.scrollTop <
                    initialfirstindex * rowheight ||
                scrollingContainerRefCurrent.scrollTop >
                    (initialfirstindex + visibleitemcount + 1) * rowheight
            ) {
                scrollingContainerRefCurrent.scrollTop = initialfirstindex * rowheight
            }
        }
    }, [
        initialfirstindex,
        rowheight,
        totalitems,
        visibleitemcount,
        scrollingContainerRefCurrent,
    ])

    // when the firstindex changed externally (e.g. by setting it to 0) instead of
    // internally, we have to update our internal state and scroll position
    useLayoutEffect(() => {
        if (initialfirstindex !== propsfirstindex) {
            setInitialFirstindex(propsfirstindex)
            if (firstindex !== propsfirstindex) {
                setFirstindex(propsfirstindex)
                if (scrollingContainerRefCurrent) {
                    scrollingContainerRefCurrent.scrollTop = propsfirstindex * rowheight
                }
                onUpdate && onUpdate(propsfirstindex, visibleitemcount)
            }
        }
    }, [
        totalitems,
        rowheight,
        firstindex,
        initialfirstindex,
        propsfirstindex,
        visibleitemcount,
        onUpdate,
        scrollingContainerRefCurrent,
    ])

    // when scrollToFirst is true, scroll to top
    useLayoutEffect(() => {
        if (scrollToFirst) {
            if (scrollingContainerRefCurrent) {
                scrollingContainerRefCurrent.scrollTop = 0
            }
        }
    }, [scrollToFirst, scrollingContainerRefCurrent])

    // when the height of the container changes (which is predicated on a change of the
    // elementsize) we need to recalculate how many items would be visible
    useLayoutEffect(() => {
        const newvisibleitemcount = elementsize
            ? 1 + Math.ceil(elementsize.height / rowheight)
            : // we add one even though we already round up, so both the top and bottom
              // items can be partially visible
              10
        if (visibleitemcount !== newvisibleitemcount) {
            setVisibleitemcount(newvisibleitemcount)
            onUpdate && onUpdate(firstindex, newvisibleitemcount)
        }
    }, [elementsize, totalitems, rowheight, firstindex, visibleitemcount, onUpdate])

    const onScroll = event => {
        const { scrollTop } = event.currentTarget
        // Prevent Safari's elastic scrolling from causing visual shaking when scrolling past bounds.
        const scrollFirstindex = Math.max(
            0,
            Math.min(
                Math.floor(scrollTop / rowheight),
                totalitems - visibleitemcount + 1
            )
            // the + 1 is to compensate for the one we added to the newvisibleitemcount
        )
        if (firstindex === scrollFirstindex) {
            return
        }
        if (debounceScrolling) {
            window.clearTimeout(debounceScrolling)
            setDebounceScrolling(null)
        }
        setDebounceScrolling(
            window.setTimeout(() => {
                setDebounceScrolling(null)
                setFirstindex(scrollFirstindex)
                onUpdate && onUpdate(scrollFirstindex, visibleitemcount)
            }, DEBOUNCE_MAGIC_NUMBER)
        )
    }

    const itemrefcallback = element => {
        if (element && calculateitemheight) {
            setCalculatedItemheight(Math.max(element.offsetHeight, itemheight, 10))
        } else {
            setCalculatedItemheight(itemheight)
        }
    }

    const Items =
        children && children.map
            ? children.map((child, index) => {
                  if (!child) return null
                  let extraprops = {
                      height: calculateditemheight,
                      minheight: itemheight,
                      gap: gap,
                      ref: index === 0 ? itemrefcallback : undefined,
                      autoheight: index === 0,
                  }
                  return React.cloneElement(child, extraprops)
              })
            : children

    return (
        <div
            ref={scrollingContainerRef}
            onScroll={onScroll}
            className={classes}
            style={styles}
        >
            <div style={{ height: totalitems * rowheight - gap }}>{Items}</div>
        </div>
    )
}

const calculateTileHeight = (contentHeight, rows, gap) =>
    (contentHeight - gap * (rows - 1)) / rows

export const LazyHScrollTiles = props => {
    const { grow, className, style } = props
    let classes = 'av-LazyHScrollTiles'
    let styles = {}
    if (grow) {
        if (grow !== true) {
            styles = { flexGrow: grow }
        }
    }
    if (className) classes += ' ' + className
    if (style) styles = Object.assign({}, styles, style)

    const {
        firstindex: propsfirstindex,
        itemwidth,
        rows,
        gap: propsgap,
        totalitems,
        onUpdate,
        scrollToFirst,
        children,
    } = props
    const [initialfirstindex, setInitialFirstindex] = useState(propsfirstindex)
    const [firstindex, setFirstindex] = useState(propsfirstindex)
    const [visibleitemcount, setVisibleitemcount] = useState(10)
    const [scrollingContainerRef, elementsize] = useOnElementResize(500, 500)
    const scrollingContainerRefCurrent = scrollingContainerRef.current
    const innerScrollingContainerRef = useRef()
    const [itemheight, setItemheight] = useState(itemwidth)
    const [debounceScrolling, setDebounceScrolling] = useState(null)

    const gap = propsgap || 0
    const columnindex = Math.floor(initialfirstindex / rows)
    const columnwidth = itemwidth + gap

    // initially set correct scroll position
    useLayoutEffect(() => {
        if (scrollingContainerRefCurrent) {
            const contentRect = getContentRect(innerScrollingContainerRef.current)
            if (contentRect) {
                setItemheight(calculateTileHeight(contentRect.height, rows, gap))
            }
            if (
                scrollingContainerRefCurrent.scrollLeft < columnindex * columnwidth ||
                scrollingContainerRefCurrent.scrollLeft >
                    (columnindex + visibleitemcount / rows + 1) * columnwidth
            ) {
                scrollingContainerRefCurrent.scrollLeft = columnindex * columnwidth
            }
        }
    }, [
        columnindex,
        columnwidth,
        totalitems,
        visibleitemcount,
        rows,
        gap,
        scrollingContainerRefCurrent,
    ])

    // when the firstindex changed externally (e.g. by setting it to 0) instead of
    // internally, we have to update our internal state and scroll position
    useLayoutEffect(() => {
        if (initialfirstindex !== propsfirstindex) {
            setInitialFirstindex(propsfirstindex)
            if (firstindex !== propsfirstindex) {
                setFirstindex(propsfirstindex)
                if (scrollingContainerRefCurrent) {
                    scrollingContainerRefCurrent.scrollLeft =
                        Math.floor(propsfirstindex / rows) * columnwidth
                }
                onUpdate && onUpdate(propsfirstindex, visibleitemcount)
            }
        }
    }, [
        totalitems,
        columnwidth,
        rows,
        firstindex,
        initialfirstindex,
        propsfirstindex,
        visibleitemcount,
        onUpdate,
        scrollingContainerRefCurrent,
    ])

    // when scrollToFirst is true, scroll to left
    useLayoutEffect(() => {
        if (scrollToFirst) {
            if (scrollingContainerRefCurrent) {
                scrollingContainerRefCurrent.scrollLeft = 0
            }
        }
    }, [scrollToFirst, scrollingContainerRefCurrent])

    // when the width of the container changes (which is predicated on a change of the
    // elementsize) we need to recalculate how many items would be visible
    useLayoutEffect(() => {
        const newvisibleitemcount = elementsize
            ? rows + Math.ceil((rows * elementsize.width) / columnwidth)
            : // we add rows even though we already round up, so both the left
              // and right items can be partially visible
              10
        if (visibleitemcount !== newvisibleitemcount) {
            setVisibleitemcount(newvisibleitemcount)
            if (elementsize) {
                setItemheight(calculateTileHeight(elementsize.height, rows, gap))
            }
            onUpdate && onUpdate(firstindex, newvisibleitemcount)
        }
    }, [
        elementsize,
        totalitems,
        columnwidth,
        rows,
        gap,
        firstindex,
        visibleitemcount,
        onUpdate,
    ])

    const onScroll = event => {
        const { scrollLeft } = event.currentTarget
        // Prevent Safari's elastic scrolling from causing visual shaking when scrolling past bounds.
        let scrollFirstindex = Math.max(
            0,
            Math.min(
                Math.floor((rows * scrollLeft) / columnwidth),
                totalitems - visibleitemcount + rows
            )
            // the + rows is to compensate for the one we added to the newvisibleitemcount
        )
        scrollFirstindex = scrollFirstindex - (scrollFirstindex % rows)
        if (firstindex === scrollFirstindex) {
            return
        }
        if (debounceScrolling) {
            window.clearTimeout(debounceScrolling)
            setDebounceScrolling(null)
        }
        setDebounceScrolling(
            window.setTimeout(() => {
                setDebounceScrolling(null)
                setFirstindex(scrollFirstindex)
                onUpdate && onUpdate(scrollFirstindex, visibleitemcount)
            }, DEBOUNCE_MAGIC_NUMBER)
        )
    }

    const Items =
        children && children.map
            ? children.map((child, index) => {
                  if (!child) return null
                  let extraprops = {
                      height: itemheight,
                      width: itemwidth,
                      rows: rows,
                      gap: gap,
                  }
                  return React.cloneElement(child, extraprops)
              })
            : children

    return (
        <div
            ref={scrollingContainerRef}
            onScroll={onScroll}
            className={classes}
            style={styles}
        >
            <div
                ref={innerScrollingContainerRef}
                style={{ width: Math.ceil(totalitems / rows) * columnwidth - gap }}
            >
                {Items}
            </div>
        </div>
    )
}

const calculateTileWidth = (contentWidth, columns, gap) =>
    (contentWidth - gap * (columns - 1)) / columns

export const LazyVScrollTiles = props => {
    const { grow, className, style } = props
    let classes = 'av-LazyVScrollTiles'
    let styles = {}
    if (grow) {
        if (grow !== true) {
            styles = { flexGrow: grow }
        }
    }
    if (className) classes += ' ' + className
    if (style) styles = Object.assign({}, styles, style)

    const {
        firstindex: propsfirstindex,
        itemheight,
        calculateitemheight,
        columns,
        gap: propsgap,
        totalitems,
        onUpdate,
        scrollToFirst,
        children,
    } = props
    const [initialfirstindex, setInitialFirstindex] = useState(propsfirstindex)
    const [firstindex, setFirstindex] = useState(propsfirstindex)
    const [visibleitemcount, setVisibleitemcount] = useState(10)
    const [scrollingContainerRef, elementsize] = useOnElementResize(500, 500)
    const scrollingContainerRefCurrent = scrollingContainerRef.current
    const innerScrollingContainerRef = useRef()
    const debouncedScrollingContainerWidth = useDebounce(elementsize.width, 350)
    const [itemwidth, setItemwidth] = useState(itemheight)
    const [debounceScrolling, setDebounceScrolling] = useState(null)

    const [calculateditemheight, setCalculatedItemheight] = useState(itemheight)

    const gap = propsgap || 0
    const rowindex = Math.floor(initialfirstindex / columns)
    const rowheight = calculateditemheight + gap

    // initially set correct scroll position
    useLayoutEffect(() => {
        if (scrollingContainerRefCurrent) {
            const contentRect = getContentRect(innerScrollingContainerRef.current)
            if (contentRect) {
                setItemwidth(calculateTileWidth(contentRect.width, columns, gap))
            }
            if (
                scrollingContainerRefCurrent.scrollTop < rowindex * rowheight ||
                scrollingContainerRefCurrent.scrollTop >
                    (rowindex + visibleitemcount / columns + 1) * rowheight
            ) {
                scrollingContainerRefCurrent.scrollTop = rowindex * rowheight
            }
        }
    }, [
        rowindex,
        rowheight,
        totalitems,
        visibleitemcount,
        columns,
        gap,
        scrollingContainerRefCurrent,
    ])

    // when container width changes
    useLayoutEffect(() => {
        if (scrollingContainerRefCurrent) {
            const contentRect = getContentRect(innerScrollingContainerRef.current)
            if (contentRect) {
                setItemwidth(calculateTileWidth(contentRect.width, columns, gap))
            }
        }
    }, [columns, gap, scrollingContainerRefCurrent, debouncedScrollingContainerWidth])

    // when the firstindex changed externally (e.g. by setting it to 0) instead of
    // internally, we have to update our internal state and scroll position
    useLayoutEffect(() => {
        if (initialfirstindex !== propsfirstindex) {
            setInitialFirstindex(propsfirstindex)
            if (firstindex !== propsfirstindex) {
                setFirstindex(propsfirstindex)
                if (scrollingContainerRefCurrent) {
                    scrollingContainerRefCurrent.scrollTop =
                        Math.floor(propsfirstindex / columns) * rowheight
                }
                onUpdate && onUpdate(propsfirstindex, visibleitemcount)
            }
        }
    }, [
        totalitems,
        rowheight,
        columns,
        firstindex,
        initialfirstindex,
        propsfirstindex,
        visibleitemcount,
        onUpdate,
        scrollingContainerRefCurrent,
    ])

    // when scrollToFirst is true, scroll to top
    useLayoutEffect(() => {
        if (scrollToFirst) {
            if (scrollingContainerRefCurrent) {
                scrollingContainerRefCurrent.scrollTop = 0
            }
        }
    }, [scrollToFirst, scrollingContainerRefCurrent])

    // when the height of the container changes (which is predicated on a change of the
    // elementsize) we need to recalculate how many items would be visible
    useLayoutEffect(() => {
        const newvisibleitemcount = elementsize
            ? columns + Math.ceil((columns * elementsize.height) / rowheight)
            : // we add columns even though we already round up, so both the top and
              // bottom items can be partially visible
              10
        if (visibleitemcount !== newvisibleitemcount) {
            setVisibleitemcount(newvisibleitemcount)
            if (elementsize) {
                setItemwidth(calculateTileWidth(elementsize.width, columns, gap))
            }
            onUpdate && onUpdate(firstindex, newvisibleitemcount)
        }
    }, [
        elementsize,
        totalitems,
        rowheight,
        columns,
        gap,
        firstindex,
        visibleitemcount,
        onUpdate,
    ])

    const onScroll = event => {
        const { scrollTop } = event.currentTarget
        // Prevent Safari's elastic scrolling from causing visual shaking when scrolling past bounds.

        // firstindex should always be the leftmost item of the row
        let scrollFirstindex = Math.max(
            0,
            Math.min(
                Math.floor((columns * scrollTop) / rowheight),
                totalitems - visibleitemcount + columns
            )
            // the + columns is to compensate for the one we added to the newvisibleitemcount
        )
        scrollFirstindex = scrollFirstindex - (scrollFirstindex % columns)
        if (firstindex === scrollFirstindex) {
            return
        }
        if (debounceScrolling) {
            window.clearTimeout(debounceScrolling)
            setDebounceScrolling(null)
        }
        setDebounceScrolling(
            window.setTimeout(() => {
                setDebounceScrolling(null)
                setFirstindex(scrollFirstindex)
                onUpdate && onUpdate(scrollFirstindex, visibleitemcount)
            }, DEBOUNCE_MAGIC_NUMBER)
        )
    }

    const itemrefcallback = element => {
        if (element && calculateitemheight) {
            setCalculatedItemheight(Math.max(element.offsetHeight, itemheight, 10))
        } else {
            setCalculatedItemheight(itemheight)
        }
    }

    const Items =
        children && children.map
            ? children.map((child, index) => {
                  if (!child) return null
                  let extraprops = {
                      height: calculateditemheight,
                      minheight: itemheight,
                      width: itemwidth,
                      columns: columns,
                      gap: gap,
                      autoheight: index === 0,
                      ref: index === 0 ? itemrefcallback : undefined,
                  }
                  return React.cloneElement(child, extraprops)
              })
            : children

    return (
        <div
            ref={scrollingContainerRef}
            onScroll={onScroll}
            className={classes}
            style={styles}
        >
            <div
                ref={innerScrollingContainerRef}
                style={{ height: Math.ceil(totalitems / columns) * rowheight - gap }}
            >
                {Items}
            </div>
        </div>
    )
}

//
// LazyScroll lab
//
// Show/test the various LazyScroll views (LazyVScrollList, LazyHScrollList, LazyVScrollTiles,
// LazyHScrollTiles, and the H/V/Tiles-context-aware LazyScrollItem)

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

import {
    HView,
    VView,
    Spacer,
    LazyScrollItem,
    LazyVScrollList,
    LazyHScrollList,
    LazyVScrollTiles,
    LazyHScrollTiles,
} from '../../appview'

export const LazyScroll = observer(function LazyScroll() {
    const [totalsizeV, setTotalsizeV] = useState(99999)
    const [topindexV, setTopindexV] = useState(0)
    const [windowsizeV, setWindowsizeV] = useState(10)

    const onUpdateV = (newtopindex, newwindowsize) => {
        setTopindexV(newtopindex)
        setWindowsizeV(newwindowsize)
    }

    const renderedtopindexV = Math.max(0, Math.min(totalsizeV - windowsizeV, topindexV))
    const renderedwindowsizeV = Math.min(windowsizeV, totalsizeV)
    const rows = [...Array(renderedwindowsizeV).keys()].map(index => {
        return (
            <LazyScrollItem
                key={index + renderedtopindexV}
                index={index + renderedtopindexV}
                style={{
                    padding: '2px 5px',
                    backgroundColor: '#f4f4f5',
                }}
            >
                <div>
                    Item {index + renderedtopindexV} ({index})<br />
                    new line
                </div>
            </LazyScrollItem>
        )
    })

    const [totalsizeH, setTotalsizeH] = useState(99999)
    const [topindexH, setTopindexH] = useState(0)
    const [windowsizeH, setWindowsizeH] = useState(10)

    const onUpdateH = (newtopindex, newwindowsize) => {
        setTopindexH(newtopindex)
        setWindowsizeH(newwindowsize)
    }

    const renderedtopindexH = Math.max(0, Math.min(totalsizeH - windowsizeH, topindexH))
    const renderedwindowsizeH = Math.min(windowsizeH, totalsizeH)
    const columns = [...Array(renderedwindowsizeH).keys()].map(index => {
        return (
            <LazyScrollItem
                key={index + renderedtopindexH}
                index={index + renderedtopindexH}
                style={{
                    padding: '2px 5px',
                    backgroundColor: '#f4f4f5',
                }}
            >
                <div>
                    Item {index + renderedtopindexH} ({index})<br />
                    new line
                </div>
            </LazyScrollItem>
        )
    })

    const [totalsizeT, setTotalsizeT] = useState(99999)
    const [topindexT, setTopindexT] = useState(0)
    const [windowsizeT, setWindowsizeT] = useState(10)

    const onUpdateT = (newtopindex, newwindowsize) => {
        setTopindexT(newtopindex)
        setWindowsizeT(newwindowsize)
    }

    const renderedtopindexT = Math.max(0, Math.min(totalsizeT - windowsizeT, topindexT))
    const renderedwindowsizeT = Math.min(windowsizeT, totalsizeT)
    const tilesTV = [...Array(renderedwindowsizeT).keys()].map(index => {
        return (
            <LazyScrollItem
                key={index + renderedtopindexT}
                index={index + renderedtopindexT}
                style={{
                    padding: '2px 5px',
                    backgroundColor: '#f4f4f5',
                }}
            >
                <div>
                    Item {index + renderedtopindexT} ({index})<br />
                    new line
                </div>
            </LazyScrollItem>
        )
    })

    const [totalsizeTH, setTotalsizeTH] = useState(99999)
    const [topindexTH, setTopindexTH] = useState(0)
    const [windowsizeTH, setWindowsizeTH] = useState(10)

    const onUpdateTH = (newtopindex, newwindowsize) => {
        setTopindexTH(newtopindex)
        setWindowsizeTH(newwindowsize)
    }

    const renderedtopindexTH = Math.max(
        0,
        Math.min(totalsizeTH - windowsizeTH, topindexTH)
    )
    const renderedwindowsizeTH = Math.min(windowsizeTH, totalsizeTH)
    const tilesTH = [...Array(renderedwindowsizeTH).keys()].map(index => {
        return (
            <LazyScrollItem
                key={index + renderedtopindexTH}
                index={index + renderedtopindexTH}
                style={{
                    padding: '2px 5px',
                    backgroundColor: '#f4f4f5',
                }}
            >
                <div>
                    Item {index + renderedtopindexTH} ({index})<br />
                    new line
                </div>
            </LazyScrollItem>
        )
    })

    return (
        <HView style={{ padding: 20 }}>
            <VView style={{ width: 300 }}>
                <LazyVScrollList
                    className="test"
                    style={{ padding: 5, border: '1px solid #999' }}
                    firstindex={topindexV}
                    itemheight={19}
                    calculateitemheight={true}
                    gap={1}
                    totalitems={totalsizeV}
                    onUpdate={onUpdateV}
                >
                    {rows}
                </LazyVScrollList>
                <div
                    style={{ marginTop: 5 }}
                    onClick={() => {
                        setTopindexV(0)
                    }}
                >
                    scroll to Start
                </div>
                <div
                    style={{ marginTop: 5 }}
                    onClick={() => {
                        setTopindexV(Math.ceil(totalsizeV / 2))
                    }}
                >
                    scroll to Middle
                </div>
                <div
                    style={{ marginTop: 5 }}
                    onClick={() => {
                        setTopindexV(totalsizeV)
                    }}
                >
                    scroll to End
                </div>
                <div
                    style={{ marginTop: 5 }}
                    onClick={() => {
                        setTotalsizeV(4)
                    }}
                >
                    set total to 4 items
                </div>
                <div
                    style={{ marginTop: 5 }}
                    onClick={() => {
                        setTotalsizeV(windowsizeV)
                    }}
                >
                    set total to {windowsizeV} items
                </div>
                <div
                    style={{ marginTop: 5 }}
                    onClick={() => {
                        setTotalsizeV(100)
                    }}
                >
                    set total to 100 items
                </div>
                <div
                    style={{ marginTop: 5 }}
                    onClick={() => {
                        setTotalsizeV(99999)
                    }}
                >
                    set total to 99999 items
                </div>
                <div
                    style={{
                        padding: 4,
                        marginTop: 5,
                        border: '1px solid #999',
                    }}
                >
                    topindex: {topindexV}, windowsize: {windowsizeV}, totalsize:{' '}
                    {totalsizeV}
                </div>
            </VView>
            <Spacer size={15} />
            <VView style={{ width: 350 }}>
                <LazyVScrollTiles
                    className="test"
                    style={{ padding: 5, border: '1px solid #999' }}
                    firstindex={topindexT}
                    itemheight={19}
                    calculateitemheight={true}
                    columns={3}
                    gap={1}
                    totalitems={totalsizeT}
                    onUpdate={onUpdateT}
                >
                    {tilesTV}
                </LazyVScrollTiles>
                <div
                    style={{ marginTop: 5 }}
                    onClick={() => {
                        setTopindexT(0)
                    }}
                >
                    scroll to Start
                </div>
                <div
                    style={{ marginTop: 5 }}
                    onClick={() => {
                        setTopindexT(Math.ceil(totalsizeT / 2))
                    }}
                >
                    scroll to Middle
                </div>
                <div
                    style={{ marginTop: 5 }}
                    onClick={() => {
                        setTopindexT(totalsizeT)
                    }}
                >
                    scroll to End
                </div>
                <div
                    style={{ marginTop: 5 }}
                    onClick={() => {
                        setTotalsizeT(4)
                    }}
                >
                    set total to 4 items
                </div>
                <div
                    style={{ marginTop: 5 }}
                    onClick={() => {
                        setTotalsizeT(windowsizeT)
                    }}
                >
                    set total to {windowsizeT} items
                </div>
                <div
                    style={{ marginTop: 5 }}
                    onClick={() => {
                        setTotalsizeT(100)
                    }}
                >
                    set total to 100 items
                </div>
                <div
                    style={{ marginTop: 5 }}
                    onClick={() => {
                        setTotalsizeT(99999)
                    }}
                >
                    set total to 99999 items
                </div>
                <div
                    style={{
                        padding: 4,
                        marginTop: 5,
                        border: '1px solid #999',
                    }}
                >
                    firstindex: {topindexT}, windowsize: {windowsizeT}, totalsize:{' '}
                    {totalsizeT}
                </div>
            </VView>
            <Spacer size={15} />
            <VView style={{ width: 460 }}>
                <LazyHScrollList
                    className="test"
                    style={{ padding: 5, border: '1px solid #999' }}
                    firstindex={topindexH}
                    itemwidth={100}
                    gap={1}
                    totalitems={totalsizeH}
                    onUpdate={onUpdateH}
                >
                    {columns}
                </LazyHScrollList>
                <div
                    style={{ marginTop: 5 }}
                    onClick={() => {
                        setTopindexH(0)
                    }}
                >
                    scroll to Start
                </div>
                <div
                    style={{ marginTop: 5 }}
                    onClick={() => {
                        setTopindexH(Math.ceil(totalsizeH / 2))
                    }}
                >
                    scroll to Middle
                </div>
                <div
                    style={{ marginTop: 5 }}
                    onClick={() => {
                        setTopindexH(totalsizeH)
                    }}
                >
                    scroll to End
                </div>
                <div
                    style={{ marginTop: 5 }}
                    onClick={() => {
                        setTotalsizeH(4)
                    }}
                >
                    set total to 4 items
                </div>
                <div
                    style={{ marginTop: 5 }}
                    onClick={() => {
                        setTotalsizeH(windowsizeH)
                    }}
                >
                    set total to {windowsizeH} items
                </div>
                <div
                    style={{ marginTop: 5 }}
                    onClick={() => {
                        setTotalsizeH(100)
                    }}
                >
                    set total to 100 items
                </div>
                <div
                    style={{ marginTop: 5 }}
                    onClick={() => {
                        setTotalsizeH(99999)
                    }}
                >
                    set total to 99999 items
                </div>
                <div
                    style={{
                        padding: 4,
                        marginTop: 5,
                        border: '1px solid #999',
                    }}
                >
                    leftindex: {topindexH}, windowsize: {windowsizeH}, totalsize:{' '}
                    {totalsizeH}
                </div>
                <Spacer size={15} />
                <LazyHScrollTiles
                    className="test"
                    style={{ padding: 5, border: '1px solid #999' }}
                    firstindex={topindexTH}
                    itemwidth={100}
                    rows={3}
                    gap={1}
                    totalitems={totalsizeTH}
                    onUpdate={onUpdateTH}
                >
                    {tilesTH}
                </LazyHScrollTiles>
                <div
                    style={{ marginTop: 5 }}
                    onClick={() => {
                        setTopindexTH(0)
                    }}
                >
                    scroll to Start
                </div>
                <div
                    style={{ marginTop: 5 }}
                    onClick={() => {
                        setTopindexTH(Math.ceil(totalsizeTH / 2))
                    }}
                >
                    scroll to Middle
                </div>
                <div
                    style={{ marginTop: 5 }}
                    onClick={() => {
                        setTopindexTH(totalsizeTH)
                    }}
                >
                    scroll to End
                </div>
                <div
                    style={{ marginTop: 5 }}
                    onClick={() => {
                        setTotalsizeTH(4)
                    }}
                >
                    set total to 4 items
                </div>
                <div
                    style={{ marginTop: 5 }}
                    onClick={() => {
                        setTotalsizeTH(windowsizeTH)
                    }}
                >
                    set total to {windowsizeTH} items
                </div>
                <div
                    style={{ marginTop: 5 }}
                    onClick={() => {
                        setTotalsizeTH(100)
                    }}
                >
                    set total to 100 items
                </div>
                <div
                    style={{ marginTop: 5 }}
                    onClick={() => {
                        setTotalsizeTH(99999)
                    }}
                >
                    set total to 99999 items
                </div>
                <div
                    style={{
                        padding: 4,
                        marginTop: 5,
                        border: '1px solid #999',
                    }}
                >
                    firstindex: {topindexTH}, windowsize: {windowsizeTH}, totalsize:{' '}
                    {totalsizeTH}
                </div>
            </VView>
        </HView>
    )
})

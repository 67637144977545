//
// Avatar
//
// Show users' image or initials

import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Bar } from '../components'
import { Transform } from '../stores/imageservers/utils'

export const Avatar = observer(
    React.forwardRef(function Avatar({ user, selected, ...other }, ref) {
        const { ccimageserver } = useStore()

        const [avatarSrc, setAvatarSrc] = useState(null)
        useEffect(() => {
            let isMounted = true
            let controller = new AbortController()
            async function asyncEffect() {
                setAvatarSrc(null)
                let transform = new Transform('fill', 160, 160)
                const transformurl = ccimageserver.transformAvatarUrl(
                    user.avatar,
                    transform
                )
                const imageurl = await ccimageserver.transformedAvatarUrl(
                    transformurl,
                    { signal: controller.signal }
                )
                if (isMounted) {
                    setAvatarSrc(imageurl)
                }
            }
            asyncEffect()
            return () => {
                controller.abort('Unmounted')
                isMounted = false
                return isMounted
            }
        }, [user.avatar, ccimageserver])

        let className = 'avatar'
        if (selected) {
            className += ' cc-selected'
        }

        return (
            <Bar raised className={className} {...other} ref={ref}>
                {avatarSrc ? (
                    <img alt={user.initials} title={user.name} src={avatarSrc} />
                ) : (
                    <div title={user.name}>{user.initials}</div>
                )}
            </Bar>
        )
    })
)

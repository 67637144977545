//
// MultiSelection
//
// A selection class that supports multi-selection.
//
// It expects some Store that implements ResultsStore, so it can calculate ranges.
//
// For ranges (start, end, add_start) we use anchor/chain keys, where the anchor is
// the first selected key (start or add_start), and the chain key is the other end.
// It can be before or after the anchor. If you change the chain, you remove the current
// anchor/chain range and then add the new one.
//
// Select-all is supported even on very large result sets, by using an internal `include`
// variable (`include` the keys; if select-all was chosen, `include` is false so we
// exclude the keys from the `all` selection).
//
// To use the selection, e.g. to perform actions on it, use the `selection` property.
// It will return a dict with either a `selected` or `deselected` property, that
// indicates if select-all was chosen, and which keys were included or excluded
// respectively.
//
// ResultsStore interface:
// .full_size
// .has(key)
// .range(anchorKey, chainKey)
// .keyAfter(key)
// .keyBefore(key)

import { makeObservable, observable, computed, action } from 'mobx'
import { keyboard } from '../../utils/keyboard'

export class MultiSelection {
    _include = true
    _keys = new Set() // included if _include == true, excluded if _include == false
    _anchorKey = null
    _chainKey = null

    constructor(store) {
        makeObservable(this, {
            _include: observable,
            _keys: observable,
            _anchorKey: observable,
            _chainKey: observable,
            // commands
            rangeStart: action.bound,
            rangeEnd: action.bound,
            addRangeStart: action.bound,
            select: action.bound,
            deselect: action.bound,
            selectAll: action.bound,
            deselectAll: action.bound,
            // state
            state: computed,
            setState: action.bound,
        })
        this.store = store
    }

    get size() {
        if (this._include) {
            return this._keys.size
        } else {
            return this.store.full_size - this._keys.size
        }
    }

    get selection() {
        if (this._include) {
            return { selected: Array.from(this._keys) }
        } else {
            return { deselected: Array.from(this._keys) }
        }
    }

    isSelected = key => {
        if (this._include) {
            return this._keys.has(key)
        } else {
            return !this._keys.has(key)
        }
    }

    rangeStart = key => {
        // click
        if (this.isSelected(key) && this.size === 1) {
            this.deselect(key)
        } else {
            this.deselectAll()
            this.select(key)
        }
        this._anchorKey = key
        this._chainKey = null
    }

    rangeEnd = key => {
        // shift-click
        if (!this._include) {
            this.deselectAll()
            this.rangeStart(key)
            return
        }
        if (this._anchorKey === null) {
            this.rangeStart(key)
        } else if (!this.store.has(this._anchorKey)) {
            this.addRangeStart(key)
        } else {
            for (const chainkey of this.store.range(this._anchorKey, this._chainKey)) {
                this._keys.delete(chainkey)
            }
            for (const chainkey of this.store.range(this._anchorKey, key)) {
                this._keys.add(chainkey)
            }
            this._chainKey = key
        }
    }

    addRangeStart = key => {
        // cmd-click outside selection
        this.select(key)
        this._anchorKey = key
        this._chainKey = null
    }

    select = key => {
        if (this._include) {
            this._keys.add(key)
            this._anchorKey = key
        } else {
            this._keys.delete(key)
            this._anchorKey = null
        }
        this._chainKey = null
    }

    deselect = key => {
        // cmd-click inside selection
        this._anchorKey = this.store.keyAfter(key)
        if (this._anchorKey === null || !this.isSelected(this._anchorKey)) {
            this._anchorKey = this.store.keyBefore(key)
        }
        if (!this.isSelected(this._anchorKey)) {
            this._anchorKey = null
        }
        this._chainKey = null
        if (this._include) {
            this._keys.delete(key)
        } else {
            this._keys.add(key)
        }
    }

    selectAll = () => {
        this._keys.clear()
        this._include = false
        this._anchorKey = null
        this._chainKey = null
    }

    deselectAll = () => {
        this._keys.clear()
        this._include = true
        this._anchorKey = null
        this._chainKey = null
    }

    get state() {
        return {
            include: this._include,
            keys: this._keys.toJSON(),
            anchorKey: this._anchorKey,
            chainKey: this._chainKey,
        }
    }

    setState = state => {
        if (!state) return
        this._include = state.include
        this._keys.replace(state.keys)
        this._anchorKey = state.anchorKey
        this._chainKey = state.chainKey
    }
}

export const onClickMultiSelect = (event, selection, key) => {
    if (keyboard.testCommand(event)) {
        if (selection.isSelected(key)) {
            selection.deselect(key)
        } else {
            selection.addRangeStart(key)
        }
    } else if (keyboard.testShift(event)) {
        selection.rangeEnd(key)
    } else {
        selection.rangeStart(key)
    }
}

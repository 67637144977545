//
// AccountSettingsWorksheet
//
// Account settings worksheet

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../stores'
import { useTooltipPopover } from '../../hooks/useTooltipPopover'

import { VView, HView, Spacer } from '../../appview'
import { Header, Property, Text, TextInput } from '../../components'
import {
    UploadAvatarPanel,
    ChangeAvatarPanel,
    ChangePasswordPanel,
    ChangeEmailPanel,
} from '../../panels'
import { VALIDATION } from '../../utils/validation'
import {
    duckField,
    validateField,
    validateUsername,
} from '../../stores/data/validators'

const NameField = observer(function NameField({ user }) {
    const duckRequiredTextField = duckField('text', {}, true)
    const requiredValidator = value => {
        return validateField(value, duckRequiredTextField, undefined)
    }

    const onFieldChange = value => {
        user.setName(value)
    }
    const onFieldBlur = value => {
        if (requiredValidator(value.trim()).result !== VALIDATION.SUCCESS) {
            // TODO: CC-204 resetName
        } else {
            user.setName(value.trim())
            user.commitIfModified()
        }
    }

    return (
        <TextInput
            multiline={false}
            enabled={true}
            validate={requiredValidator}
            value={user.name}
            onChange={e => onFieldChange(e.target.value)}
            onBlur={e => onFieldBlur(e.target.value)}
            renderkey={user.gid + '.name'}
        />
    )
})

const UsernameField = observer(function UsernameField({ user }) {
    const [value, setValue] = useState(user.username)

    const validator = newvalue => {
        return validateUsername(newvalue, user.username)
    }

    const onFieldChange = fieldvalue => {
        setValue(fieldvalue)
    }
    const onFieldBlur = fieldvalue => {
        new Promise(resolve => resolve(validator(fieldvalue.trim()))).then(
            validationresult => {
                if (validationresult.result !== VALIDATION.SUCCESS) {
                    // TODO: CC-204 resetUsername
                } else {
                    user.setUsername(fieldvalue.trim())
                    user.commitIfModified()
                }
            }
        )
    }

    return (
        <TextInput
            multiline={false}
            enabled={true}
            validate={validator}
            value={value}
            onChange={e => onFieldChange(e.target.value)}
            onBlur={e => onFieldBlur(e.target.value)}
            renderkey={user.gid + '.username'}
        />
    )
})

export const AccountSettingsWorksheet = observer(function AccountSettingsWorksheet({
    user,
}) {
    const { app } = useStore()

    const ChangeAvatarPopover = useTooltipPopover(ChangeAvatarPanel)
    const onDoneChangeAvatar = () => {
        ChangeAvatarPopover.hide()
    }

    const ChangeEmailPopover = useTooltipPopover(ChangeEmailPanel)
    const onDoneChangeEmail = () => {
        ChangeEmailPopover.hide()
    }

    const ChangePasswordPopover = useTooltipPopover(ChangePasswordPanel)
    const onCancelChangePassword = () => {
        ChangePasswordPopover.hide()
    }
    const onConfirmChangePassword = new_password => {
        ChangePasswordPopover.hide()
        user.commitPassword(new_password)
    }

    return (
        <VView className="worksheet worksheet-account worksheet-account-settings">
            <HView>
                <VView grow>
                    <Header>{app.text('Account settings')}</Header>
                    <ChangeAvatarPopover.Panel
                        user={user}
                        onDone={onDoneChangeAvatar}
                    />
                    <Property className="avatar-property" label={app.text('avatar')}>
                        <UploadAvatarPanel
                            user={user}
                            ref={ChangeAvatarPopover.anchorRef}
                            onClick={e => ChangeAvatarPopover.onShow(e)}
                            onContextMenu={e => ChangeAvatarPopover.onShow(e)}
                        />
                    </Property>
                    <Property label={app.text('name')}>
                        <NameField user={user} />
                    </Property>
                    <ChangeEmailPopover.Panel user={user} onDone={onDoneChangeEmail} />
                    <Property label={app.text('email')}>
                        <Text
                            ref={ChangeEmailPopover.anchorRef}
                            onClick={e => ChangeEmailPopover.onShow(e)}
                            onContextMenu={e => ChangeEmailPopover.onShow(e)}
                        >
                            {user.email}
                        </Text>
                    </Property>
                </VView>
                <Spacer size={50} />
                <VView grow>
                    <Header>{app.text('Login settings')}</Header>
                    <Property label={app.text('username')}>
                        <UsernameField user={user} />
                    </Property>
                    <ChangePasswordPopover.Panel
                        onConfirm={onConfirmChangePassword}
                        onCancel={onCancelChangePassword}
                    />
                    <Property label={app.text('password')}>
                        <Text className="cc-dimmed">
                            {app.text('Not shown for security reasons')}
                        </Text>
                        <Text
                            ref={ChangePasswordPopover.anchorRef}
                            onClick={e => ChangePasswordPopover.onShow(e)}
                            onContextMenu={e => ChangePasswordPopover.onShow(e)}
                        >
                            {app.text('Change password')}
                        </Text>
                    </Property>
                </VView>
            </HView>
        </VView>
    )
})

//
// RecordInspector
//
// An inspector for the properties of a PIM record (definition manager) and the channels

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../stores'

import { VView, Divider, Spacer } from '../../../appview'
import { useMousePopover } from '../../../hooks/useMousePopover'
import { Header, Property, Text, usePopoverContext } from '../../../components'
import { RecordInspectorExtensions } from './RecordInspectorExtensions'
import { RecordInspectorDEBUG } from './RecordInspectorDEBUG'
import { DefinitionMenu } from './DefinitionMenu'
import { ExtendedDefinitionMenu } from './ExtendedDefinitionMenu'
import { LinkedRecordMenu } from './LinkedRecordMenu'
import { SiblingDefinitionsMenu } from './SiblingDefinitionsMenu'
import { ChildDefinitionsMenu } from './ChildDefinitionsMenu'

export const RecordInspector = observer(function RecordInspector({
    worksheet,
    viewkey,
}) {
    const { app, view, data, DEBUG } = useStore()

    const [selectedProperty, setSelectedProperty] = useState(null)

    const DefinitionContextMenu = useMousePopover(DefinitionMenu)
    const ExtendedDefinitionContextMenu = useMousePopover(ExtendedDefinitionMenu)
    const LinkedRecordContextMenu = useMousePopover(LinkedRecordMenu)
    const SiblingDefinitionsContextMenu = useMousePopover(SiblingDefinitionsMenu)
    const ChildDefinitionsContextMenu = useMousePopover(ChildDefinitionsMenu)
    const popoverContext = usePopoverContext()

    const hideContextMenus = () => {
        DefinitionContextMenu.hide()
        ExtendedDefinitionContextMenu.hide()
        LinkedRecordContextMenu.hide()
        SiblingDefinitionsContextMenu.hide()
        ChildDefinitionsContextMenu.hide()
    }

    const onToggleProperty = (e, property) => {
        hideContextMenus()
        if (selectedProperty === property) {
            setSelectedProperty(null)
        } else {
            setSelectedProperty(property)
        }
        e.preventDefault()
        e.stopPropagation()
    }

    const record = worksheet.record
    const definition = worksheet.definitions.get(record.definition)
    const original_definition =
        definition && definition.is_extended
            ? data.definitions.get(definition.original)
            : null
    const firstchild = worksheet.children.length ? worksheet.children[0] : null

    const onChangeSiblingsToCurrent = () => {
        record.copyDefinitionToSiblings().then(result => {
            worksheet.refetch()
        })
    }
    const onChangeSiblingsTo = new_definition_gid => {
        record.setDefinition(new_definition_gid).then(result => {
            const updatedrecord = data.records.get(result['record'])
            if (updatedrecord) {
                updatedrecord.copyDefinitionToSiblings().then(result => {
                    worksheet.refetch()
                })
            }
        })
    }
    const onChangeChildrenToFirst = () => {
        if (!firstchild) return
        firstchild.copyDefinitionToSiblings().then(result => {
            worksheet.refetch()
        })
    }
    const onChangeChildrenTo = new_definition_gid => {
        if (!firstchild) return
        firstchild.setDefinition(new_definition_gid).then(result => {
            const updatedchild = data.records.get(result['record'])
            if (updatedchild) {
                updatedchild.copyDefinitionToSiblings().then(result => {
                    worksheet.refetch()
                })
            }
        })
    }

    const onRevealOriginal = () => {
        if (original_definition) {
            view.schemaworksheet.setItem(original_definition)
            view.schematree.datamanager.setItem(original_definition)
            view.setWorkspace('schema')
        }
    }

    const onRevealDefinition = () => {
        view.schemaworksheet.setItem(definition)
        view.schematree.datamanager.setItem(definition)
        view.setWorkspace('schema')
    }

    const onAddClass = class_gid => {
        record.addDefinitionClass(class_gid)
    }
    const onAddField = field_gid => {
        record.addDefinitionField(field_gid)
    }

    const onRevertDefinition = () => {
        record.revertDefinition()
    }

    const onChangeDefinition = new_definition_gid => {
        record.setDefinition(new_definition_gid)
    }

    const onGotoLinkedRecord = () => {
        return data.fetchRecordsByGid([record.canonical]).then(result => {
            if (result.length) {
                view.pimworksheet.setRecord(result[0])
                view.pimtree.datamanager.setRecord(result[0])
            }
        })
    }

    const onUnlinkRecord = () => {
        record.unlink()
    }

    const onCancel = () => {}

    const onRecordInspectorAction = (action, parameter) => {
        const actions = {
            change_siblings_to_current: onChangeSiblingsToCurrent,
            change_siblings_to: onChangeSiblingsTo,
            change_children_to_first: onChangeChildrenToFirst,
            change_children_to: onChangeChildrenTo,

            reveal_original: onRevealOriginal,
            reveal_definition: onRevealDefinition,
            add_class: onAddClass,
            add_field: onAddField,
            revert_definition: onRevertDefinition,
            change_definition: onChangeDefinition,
            goto_linked_record: onGotoLinkedRecord,
            unlink_record: onUnlinkRecord,
            cancel: onCancel,
        }
        if (!(action in actions)) {
            console.log(`onRecordInspectorAction: unhandled action '${action}'`)
            return
        }
        popoverContext.store.show([...popoverContext.stack])
        actions[action](parameter)
    }

    if (!definition) {
        return null
    }

    const layout = worksheet.layouts.get(definition.layout)
    const DebugInfo = DEBUG ? (
        <>
            <Spacer size={50} />
            <Divider />
            <RecordInspectorDEBUG
                worksheet={worksheet}
                definition={definition}
                layout={layout}
            />
        </>
    ) : undefined

    const contextmenu = definition.is_extended
        ? ExtendedDefinitionContextMenu
        : DefinitionContextMenu

    const definition_validation = worksheet.definition_validation
    const sibling_definitions_validation = worksheet.sibling_definitions_validation
    const child_definitions_validation = worksheet.child_definitions_validation

    return (
        <VView grow className="panel properties">
            <Header>{app.text('Record info')}</Header>
            <DefinitionContextMenu.Panel
                worksheet={worksheet}
                onAction={onRecordInspectorAction}
            />
            <ExtendedDefinitionContextMenu.Panel
                worksheet={worksheet}
                onAction={onRecordInspectorAction}
            />
            <LinkedRecordContextMenu.Panel
                record={record}
                onAction={onRecordInspectorAction}
            />
            <SiblingDefinitionsContextMenu.Panel
                worksheet={worksheet}
                onAction={onRecordInspectorAction}
            />
            <ChildDefinitionsContextMenu.Panel
                worksheet={worksheet}
                onAction={onRecordInspectorAction}
            />
            <Property
                className="cc-packed"
                label={app.text('definition')}
                selected={selectedProperty === 'definition'}
                onClick={e => onToggleProperty(e, 'definition')}
                onContextMenu={e => {
                    setSelectedProperty('definition')
                    contextmenu.onShow(e)
                }}
            >
                <Text>
                    {definition.name}
                    {definition_validation ? (
                        <span className="role cc-notice">
                            {app.text(definition_validation)}
                        </span>
                    ) : (
                        ''
                    )}
                    {definition.is_extended ? (
                        <span className="role">{app.text('extended')}</span>
                    ) : (
                        ''
                    )}
                </Text>
            </Property>
            {record.is_link ? (
                <Property
                    className="cc-packed"
                    label={app.text('record')}
                    selected={selectedProperty === 'linked'}
                    onClick={e => onToggleProperty(e, 'linked')}
                    onContextMenu={e => {
                        setSelectedProperty('linked')
                        LinkedRecordContextMenu.onShow(e)
                    }}
                >
                    <Text>{app.text('linked')}</Text>
                </Property>
            ) : undefined}
            <Property
                className="cc-packed"
                label={app.text('siblings')}
                selected={selectedProperty === 'siblings'}
                onClick={e => onToggleProperty(e, 'siblings')}
                onContextMenu={e => {
                    setSelectedProperty('siblings')
                    SiblingDefinitionsContextMenu.onShow(e)
                }}
            >
                <Text>
                    {sibling_definitions_validation.length
                        ? sibling_definitions_validation.map(validation => (
                              <span key={validation} className="role cc-notice">
                                  {app.text(validation)}
                              </span>
                          ))
                        : app.text('shared')}
                </Text>
            </Property>
            {!record.is_link &&
            (definition.childdefinitions.length || record.childcount > 0) ? (
                <>
                    <Property
                        className="cc-packed"
                        label={app.text('children')}
                        selected={selectedProperty === 'children'}
                        onClick={e => onToggleProperty(e, 'children')}
                        onContextMenu={e => {
                            setSelectedProperty('children')
                            if (firstchild) {
                                ChildDefinitionsContextMenu.onShow(e)
                            }
                        }}
                    >
                        <Text>
                            {child_definitions_validation.length
                                ? child_definitions_validation.map(validation => (
                                      <span key={validation} className="role cc-notice">
                                          {app.text(validation)}
                                      </span>
                                  ))
                                : app.text('shared')}
                        </Text>
                    </Property>
                </>
            ) : undefined}
            {definition.is_extended ? (
                <>
                    <Spacer size={5} />
                    <Divider />
                    <Header>{app.text('Extensions')}</Header>
                    <RecordInspectorExtensions
                        worksheet={worksheet}
                        viewkey={viewkey}
                    />
                </>
            ) : undefined}

            {DebugInfo}
        </VView>
    )
})

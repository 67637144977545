//
// ProjectsPanel
//
// List projects with members, allow creation

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { VView, HView, VScrollView, Spacer } from '../appview'
import { Icon, Text } from '../components'
import { ProjectIcon } from '../panels'

export const ProjectSelectionPanel = observer(function ProjectSelectionPanel({
    project,
    user,
    dismiss,
}) {
    const store = useStore()
    const { app, data } = store

    const onOpenProject = projectgid => {
        store.view.prepareProjectWorkspace(projectgid)
        store.setLocation(store.LOCATIONS.PROJECT, projectgid)
        dismiss && dismiss()
    }

    const onGotoProjectManagement = () => {
        store.view.savePerUser('/account/navigatorItem', 'projects')
        store.view.setWorkspace('account')
        dismiss && dismiss()
    }

    if (!user) return

    const userProjects = data.projectsForUser(user.gid).sort((a, b) => {
        // own projects first, then member projects
        // sort projects by name (unedited)
        const project_a = data.projects.get(a.project_gid)
        const project_b = data.projects.get(b.project_gid)
        const is_owner_a = project_a && user.gid === project_a.owner ? 1 : 0
        const is_owner_b = project_b && user.gid === project_b.owner ? 1 : 0
        return (
            is_owner_b - is_owner_a ||
            project_a.uneditedname.localeCompare(project_b.uneditedname)
        )
    })

    let projectsCount = 0
    const Projects = userProjects.map(project_member => {
        const projectgid = project_member.project_gid
        const user_project = data.projects.get(projectgid)

        if (
            !user_project ||
            user_project.status === 'deleted' ||
            project_member.status === 'invited'
        ) {
            return null
        }
        projectsCount += 1

        let classes = 'project'
        if (project && user_project.gid === project.gid) {
            classes += ' cc-selected'
        }

        const owner = data.users.get(user_project.owner)
        const project_members = data.membersForProject(projectgid)
        let user_member
        project_members.forEach(project_member => {
            if (project_member.user_gid === user.gid) {
                user_member = project_member
            }
        })

        const Roles =
            user_member && user_member.roles
                ? user_member.roles.map(role => {
                      return (
                          <div
                              className={
                                  'role ' + (role === 'owner' ? 'cc-ok' : 'cc-info')
                              }
                              key={role}
                          >
                              {role}
                          </div>
                      )
                  })
                : undefined

        const iconstyle = { width: 40, height: 40 }
        return (
            <HView
                key={user_project.gid}
                className={classes}
                onClick={() => onOpenProject(user_project.gid)}
                gap={10}
            >
                <ProjectIcon project={user_project} style={iconstyle} />
                <VView grow>
                    <HView gap={5}>
                        <div className="project-name">{user_project.name}</div>
                        <Spacer />
                        {Roles}
                    </HView>
                    <div>
                        {app.text('by')}{' '}
                        <span className="owner-name">{owner.displayname}</span>
                    </div>
                </VView>
            </HView>
        )
    })

    const height = Math.min(220, 50 * projectsCount)

    return (
        <VView className="panel project-selection-panel">
            <VScrollView style={{ height, minHeight: height }}>{Projects}</VScrollView>
            <Spacer size={5} />
            <Text onClick={onGotoProjectManagement}>
                <Icon size="text" name="navigate" />{' '}
                {app.text('Go to Project management')}
            </Text>
        </VView>
    )
})

//
// MenuText
//
// A MenuText can be used in a menu and is a wrapper for Text.

import React from 'react'
import { Text } from './Text'

export const MenuText = React.forwardRef(({ children, ...other }, ref) => {
    return (
        <div className="cc-MenuText" ref={ref}>
            <Text {...other}>{children}</Text>
        </div>
    )
})

//
// ProjectMemberData
//
// A plain ProjectMemberData object that connects users and projects, assigning
// roles.

import { makeObservable, observable, computed, action } from 'mobx'

import { updateInstanceWithData } from './utils'

export const projectMemberKey = data => {
    if (data.hasOwnProperty('project_gid')) {
        return data.project_gid + '/' + data.user_gid
    }
    return data['project_gid'] + '/' + data['user_gid']
}

export class ProjectMemberData {
    project_gid = null
    user_gid = null
    status = ''
    roles = []
    options = new Map()

    constructor(projectmemberdata, rootstore) {
        makeObservable(this, {
            status: observable,
            roles: observable,
            options: observable,

            key: computed,

            update: action.bound,
        })

        this._rootstore = rootstore
        this.update(projectmemberdata)
    }

    update = projectmemberdata => {
        updateInstanceWithData(this, projectmemberdata, [
            'project_gid',
            'user_gid',
            'status',
            'roles',
            'options',
        ])
    }

    get key() {
        if (!this.options) return null
        return projectMemberKey(this)
    }
}

//
// ProjectManagementWorksheet
//
// Project management worksheet

import React from 'react'
import { observer } from 'mobx-react-lite'

import { VView } from '../../appview'
import { ProjectsPanel } from '../../panels'

export const ProjectManagementWorksheet = observer(function ProjectManagementWorksheet({
    user,
}) {
    return (
        <VView className="worksheet worksheet-account worksheet-account-projects">
            <ProjectsPanel user={user} />
        </VView>
    )
})

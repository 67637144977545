//
// ProjectBackupsInspector
//
// Project inspector; show owner

import React from 'react'
import { observer } from 'mobx-react-lite'

import { VView } from '../../appview'

export const ProjectBackupsInspector = observer(function ProjectBackupsInspector({
    project,
}) {
    return <VView grow className="panel"></VView>
})

//
// ChangeEmailPanel
//
// Use in a popover for changing email + verification.

import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'
import { useDebounce } from '../hooks/useDebounce'

import { VView, Spacer } from '../appview'
import {
    Header,
    Text,
    TextInput,
    Menu,
    MenuItem,
    Bar,
    Button,
    ValidationMessage,
} from '../components'
import { VALIDATION, ValidationResult } from '../utils/validation'
import { validateEmail } from '../stores/data/validators'

const hasInput = value => {
    return value && value.trim().length > 0
}

export const ChangeEmailPanel = observer(function ChangeEmailPanel({ user, onDone }) {
    const { app } = useStore()

    const [email, setEmail] = useState(user.email)
    const [isValidEmail, setIsValidEmail] = useState(false)
    const debouncedEmail = useDebounce(email, 200)

    const [validationcode, setValidationcode] = useState(null)
    const [verificationcode, setVerificationcode] = useState(null)

    useEffect(() => {
        let isMounted = true
        // this works for returned Promises and for returned regular values
        new Promise(resolve => resolve(validateEmail(debouncedEmail))).then(
            validationresult => {
                if (isMounted) {
                    setIsValidEmail(validationresult.result === VALIDATION.SUCCESS)
                }
            }
        )
        // .catch(error => {})
        return () => (isMounted = false)
    }, [debouncedEmail])

    // form validation
    const [requestemailvalidation, setRequestemailvalidation] = useState(null)
    const [emailvalidation, setEmailvalidation] = useState(null)

    const ACTIVITIES = {
        UPDATE_EMAIL_REQUEST: 0,
        UPDATE_EMAIL: 1,
    }
    const [activity, setActivity] = useState(ACTIVITIES.UPDATE_EMAIL_REQUEST)

    const onReenterEmail = () => {
        setValidationcode('')
        setVerificationcode('')
        setActivity(ACTIVITIES.UPDATE_EMAIL_REQUEST)
    }

    const onUpdateEmailRequest = () => {
        setActivity(ACTIVITIES.UPDATE_EMAIL)
        user.updateEmailRequest(email.trim())
            .then(result => {
                setValidationcode(result['validation_code'])
            })
            .catch(() => {
                const validationerror = new ValidationResult(
                    VALIDATION.ERROR,
                    'Email update request failed, please try again. [TODO: CC-205 add reason]'
                )
                setRequestemailvalidation(validationerror)
                setActivity(ACTIVITIES.UPDATE_EMAIL_REQUEST)
            })
    }

    const onUpdateEmail = () => {
        user.updateEmail(email.trim(), validationcode.trim(), verificationcode.trim())
            .then(result => {
                if (result) {
                    onDone && onDone()
                } else {
                    setValidationcode(null)
                }
            })
            .catch(() => {
                const validationerror = new ValidationResult(
                    VALIDATION.ERROR,
                    'Email update failed, please try again. [TODO: CC-205 add reason]'
                )
                setEmailvalidation(validationerror)
            })
    }

    const _onCancel = () => {
        onDone && onDone()
    }

    const updaterequestFieldsEnabled = activity === ACTIVITIES.UPDATE_EMAIL_REQUEST
    const updaterequestEnabled = updaterequestFieldsEnabled && isValidEmail

    const updateFieldsEnabled = activity === ACTIVITIES.UPDATE_EMAIL
    const updateEnabled =
        updateFieldsEnabled &&
        isValidEmail &&
        hasInput(validationcode) &&
        hasInput(verificationcode)

    return (
        <VView className="actions-panel change-email-panel">
            <div className="inspector">
                <Header>{app.text('New email address')}</Header>
                <VView
                    style={{
                        marginLeft: 5,
                        marginRight: 5,
                        marginBottom: 5,
                    }}
                >
                    <TextInput
                        enabled={updaterequestFieldsEnabled}
                        validate={validateEmail}
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        placeholder={app.text(
                            "Where you'll get your verification code"
                        )}
                    />
                    <Spacer size={5} />
                    <Bar raised>
                        <Button
                            action
                            disabled={!updaterequestEnabled}
                            onClick={onUpdateEmailRequest}
                        >
                            {app.text('Send me a verification code at {email}', {
                                email: email.trim().length ? email : '...',
                            })}
                        </Button>
                    </Bar>
                    {requestemailvalidation ? (
                        <ValidationMessage validation={requestemailvalidation} />
                    ) : undefined}
                    {updateFieldsEnabled ? (
                        <Text onClick={() => onReenterEmail()}>
                            {app.text('I want to alter the new email address.')}
                        </Text>
                    ) : undefined}
                    <Spacer size={5} />
                    <Text>{app.text('Verification code')}</Text>
                    <TextInput
                        enabled={updateFieldsEnabled}
                        onChange={e => setVerificationcode(e.target.value)}
                        value={verificationcode}
                        placeholder={app.text("You'll get this by email, once")}
                    />
                    <Text className={updateEnabled ? '' : 'cc-dimmed'}>
                        {app.text(
                            'Your email will be changed immediately after pressing the Confirm button below.'
                        )}
                    </Text>
                </VView>
            </div>
            <Menu>
                <MenuItem disabled={!updateEnabled} onClick={onUpdateEmail}>
                    {app.text('Confirm email change')}
                </MenuItem>
                {emailvalidation ? (
                    <ValidationMessage validation={emailvalidation} />
                ) : undefined}
                <MenuItem onClick={_onCancel}>{app.text('Cancel')}</MenuItem>
            </Menu>
        </VView>
    )
})

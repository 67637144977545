//
// RecordFieldMenu
//
// Actions on a record-input (-list).

import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'
import { Transform } from '../stores/imageservers/utils'

import { View, VView } from '../appview'
import { Menu, MenuDivider, MenuItem } from '../components'
import { RecordCard, RecordSearchPanel } from '../panels'

const ConfirmSubmenu = observer(function ConfirmSubmenu({ onAction }) {
    const { app } = useStore()

    const _onAction = (e, action) => {
        onAction && onAction(action)
    }

    return (
        <Menu>
            <MenuItem className="cc-danger" onClick={e => _onAction(e, 'remove_all')}>
                {app.text('Yes, clear this list')}
            </MenuItem>
        </Menu>
    )
})

export const RecordFieldMenu = observer(
    React.forwardRef(function RecordFieldMenu(
        { record, enabled, list, onAction, ...other },
        ref
    ) {
        const { app, view, data } = useStore()

        const _onAction = (action, otherrecordgid) => {
            if (!otherrecordgid) {
                onAction && onAction(action, record)
            } else {
                const otherrecord = data.records.get(otherrecordgid)
                onAction &&
                    otherrecord &&
                    onAction(action, record, otherrecord.gid.toString())
            }
        }

        const onSelectAdd = recordgid => {
            _onAction('add_record', recordgid)
        }

        const onSelectReplace = recordgid => {
            _onAction('replace_record', recordgid)
        }

        const searchpanelstyle = { display: 'flex', width: 320, minHeight: 500 }

        const RecordSearchAddSubmenu = (
            <View className="inspector" style={searchpanelstyle}>
                <RecordSearchPanel
                    querystore={view.recordquery}
                    preview={true}
                    onSelect={onSelectAdd}
                />
            </View>
        )

        const RecordSearchReplaceSubmenu = (
            <View className="inspector" style={searchpanelstyle}>
                <RecordSearchPanel
                    querystore={view.recordquery}
                    preview={true}
                    onSelect={onSelectReplace}
                />
            </View>
        )

        const MemoizedRecordCard = useMemo(() => {
            const transform = new Transform('fit', 190, 190)
            return <RecordCard record={record} transform={transform} />
        }, [record])

        if (!record) {
            return (
                <View
                    ref={ref}
                    className="inspector"
                    style={searchpanelstyle}
                    onClick={e => e.nativeEvent.stopImmediatePropagation()}
                    {...other}
                >
                    <RecordSearchPanel
                        querystore={view.recordquery}
                        preview={true}
                        onSelect={onSelectAdd}
                    />
                </View>
            )
        }

        return (
            <VView
                ref={ref}
                className="record-field-menu"
                onClick={e => e.nativeEvent.stopImmediatePropagation()}
                {...other}
            >
                <div className="inspector">{MemoizedRecordCard}</div>
                <Menu>
                    {enabled && list ? (
                        <MenuItem submenu={RecordSearchAddSubmenu}>
                            {app.text('Add before')}
                        </MenuItem>
                    ) : undefined}
                    {enabled ? (
                        <MenuItem submenu={RecordSearchReplaceSubmenu}>
                            {app.text('Replace')}
                        </MenuItem>
                    ) : undefined}
                    {enabled ? (
                        <MenuItem
                            className="cc-danger"
                            onClick={() => _onAction('remove_record')}
                        >
                            {app.text(list ? 'Remove from list' : 'Remove')}
                        </MenuItem>
                    ) : undefined}
                    {enabled && list ? (
                        <MenuItem
                            className="cc-danger"
                            submenu={<ConfirmSubmenu onAction={_onAction} />}
                        >
                            {app.text('Remove all')}
                        </MenuItem>
                    ) : undefined}
                    {enabled ? <MenuDivider /> : undefined}
                    <MenuItem onClick={() => _onAction('reveal_in_pim')}>
                        {app.text('Reveal in Catalog workspace')}
                    </MenuItem>
                </Menu>
            </VView>
        )
    })
)

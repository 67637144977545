//
// FolderFieldOption
//
// Field option 'folder'

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'

import { FolderInput } from '../../../../components'
import { LabeledField } from '../../../components'

export const FolderFieldOption = observer(function FolderFieldOption({
    field,
    selectModified,
    worksheet,
    onAction,
    setSelected,
    renderkeyprefix,
}) {
    const { app } = useStore()

    if (!field) return null
    const folder_gid = field.options.get('folder')

    const onSelectFolder = new_folder_gid => {
        field.setOption('folder', new_folder_gid)
        field.commitIfModified().then(result => selectModified(result))
    }

    return (
        <LabeledField label={app.text('upload to')}>
            <FolderInput onSelect={onSelectFolder} value={folder_gid} />
        </LabeledField>
    )
})

//
// SiteScreen
//
// Show this when we're not anywhere in /p
// We can be logged in or out

import React from 'react'
import { observer } from 'mobx-react-lite'

import { VScrollView } from '../appview'

export const SiteScreen = observer(function SiteScreen({ children }) {
    return (
        <VScrollView className="site">
            <div>{children}</div>
        </VScrollView>
    )
})

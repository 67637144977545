//
// AppView
//
// The AppView takes the whole viewport, and is only used as the root element in your
// layout. You can skip it if you don't want your ui to fill the available browser
// window.

import React from 'react'

export const AppView = React.forwardRef(function AppView(props, ref) {
    var { className, ...other } = props
    var classes = 'av-AppView'
    if (className) classes += ' ' + className
    return <div ref={ref} className={classes} {...other} />
})

//
// OpenQueryPanel
//
// Select a query to open.

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { VView, VScrollView } from '../appview'
import { Header, SearchInput, Item, Menu, MenuItem } from '../components'
import { normalize } from '../utils/text'

export const OpenQueryPanel = observer(function OpenQueryPanel({
    querystore,
    selected,
    onOpenQuery,
    onCancel,
}) {
    const { app, data } = useStore()

    const [searchtext, setSearchtext] = useState('')
    const [selecteditem, setSelecteditem] = useState(selected)
    let selection_in_results = false // initially

    let searchresultqueries = Array.from(data.queries.entries())
    searchresultqueries = searchresultqueries.filter(
        ([key, query]) => query.type === querystore.filterstore.doctype
    )

    if (searchtext.length) {
        const searchwords = normalize(searchtext).split(' ')
        searchresultqueries = searchresultqueries.filter(([key, query]) => {
            // do a match on name
            // first, normalize (validName)
            // second, split on spaces
            // then, match all words
            const queryname = normalize(query.name)
            for (const word of searchwords) {
                if (!word.length) continue
                if (!queryname.includes(word)) {
                    return false
                }
            }
            return true
        })
    }

    searchresultqueries.sort((a, b) => {
        const aquery = a[1]
        const bquery = b[1]
        const aname = normalize(aquery.name)
        const bname = normalize(bquery.name)
        if (aname === bname) return 0
        return aname > bname ? 1 : -1
    })

    const onSearchinputChange = event => {
        setSearchtext(event.target.value)
    }
    const onSearchinputBlur = event => {
        setSearchtext(event.target.value.trim())
    }

    const _onOpenQuery = () => {
        onOpenQuery && selecteditem && onOpenQuery(selecteditem)
    }

    const _onCancel = () => {
        onCancel && onCancel()
    }

    const searchresults = searchresultqueries.length ? (
        searchresultqueries.map(([key, query]) => {
            if (selecteditem === key) selection_in_results = true
            return (
                <Item
                    key={key}
                    selected={selecteditem === key}
                    onClick={() => setSelecteditem(key)}
                    onDoubleClick={() => {
                        setSelecteditem(key)
                        _onOpenQuery()
                    }}
                >
                    {query.name}
                </Item>
            )
        })
    ) : (
        <div style={{ padding: '2px 5px' }}>{app.text('No matches')}</div>
    )

    const selectedquery = selection_in_results ? data.queries.get(selecteditem) : null

    return (
        <VView className="actions-panel open-query-panel">
            <div className="inspector">
                <Header>{app.text('Open query')}</Header>
                <VView
                    style={{
                        marginLeft: 5,
                        marginRight: 5,
                        marginBottom: 2,
                    }}
                >
                    <SearchInput
                        value={searchtext}
                        placeholder={app.text('Search by name')}
                        onChange={onSearchinputChange}
                        onBlur={onSearchinputBlur}
                    />
                    <VScrollView
                        className="search-results"
                        style={{ backgroundColor: 'white' }}
                    >
                        {searchresults}
                    </VScrollView>
                </VView>
            </div>
            <Menu>
                <MenuItem disabled={!selection_in_results} onClick={_onOpenQuery}>
                    {selectedquery
                        ? app.text("Open query '{queryname}'", {
                              queryname: selectedquery.name,
                          })
                        : app.text('Open query')}
                </MenuItem>
                {onCancel ? (
                    <MenuItem onClick={_onCancel}>{app.text('Cancel')}</MenuItem>
                ) : undefined}
            </Menu>
        </VView>
    )
})

//
// ExcelExportImportPanel
//
// Excel actions on regular records in the TreeNavigator

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'
import { useStore } from '../stores'
import { download_dataurl } from '../utils/utils'

import { VView } from '../appview'
import { Header, Bar, Button, Icon } from '../components'
import { useFileUpload } from '../hooks/useFileUpload'

export const ExcelImportPanel = observer(function ExcelImportPanel({ excelimport }) {
    const { data, app } = useStore()

    const FileUpload = useFileUpload()

    const onFileSelection = file => {
        const filename = file.name
        const filetype = file.type
        const filesize = file.size
        if (
            file.type &&
            file.type ===
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
            excelimport.setProcessingState(file)
            data.actions
                .import_excel_children(file)
                .then(result => {
                    if (result) {
                        excelimport.setResultState(result)
                    }
                })
                .catch(error => {
                    const duckfile = {
                        name: filename,
                        type: filetype,
                        size: filesize,
                    }
                    excelimport.setNotExcelFileState(duckfile)
                })
        } else {
            excelimport.setNotExcelFileState(file)
        }
    }

    const [{ isOver }, drop] = useDrop({
        accept: NativeTypes.FILE,
        collect: monitor => {
            const isOver = !!monitor.isOver()
            return {
                isOver: isOver,
            }
        },
        drop: (item, monitor) => {
            onFileSelection(item.files[0])
        },
    })

    // drag/drop over import dropzone

    let dropclassname = 'excel-upload'
    if (isOver) dropclassname += ' drag-over-inside'

    let ImportPanel
    if (excelimport.state.is_ready) {
        ImportPanel = (
            <>
                <div
                    className={dropclassname}
                    ref={drop}
                    onClick={e => FileUpload.openFileDialog()}
                >
                    <span className="cc-Placeholder">
                        {app.text(
                            'Drop an exported Excel file here to update the catalog.'
                        )}
                    </span>
                </div>
                <div className="cc-note">
                    {app.text('Or click the box to open a file selection dialog.')}
                </div>
            </>
        )
    } else if (excelimport.state.not_excelfile) {
        dropclassname += ' excel-upload-not-excelfile'
        ImportPanel = (
            <>
                <div className={dropclassname}>
                    <Header>{app.text('Upload error')}</Header>
                    <div style={{ marginBottom: 10 }}>
                        {excelimport.state.file.type
                            ? app.text('File')
                            : app.text('Folder')}{' '}
                        '{excelimport.state.file.name}'
                    </div>
                    <div style={{ marginBottom: 10 }}>
                        {excelimport.state.file.type
                            ? app.text('Not a valid Excel file')
                            : app.text('Folder upload not supported')}
                    </div>
                    <Bar raised>
                        <Button action onClick={() => excelimport.setReadyState()}>
                            {app.text('Reset')}
                        </Button>
                    </Bar>
                </div>
            </>
        )
    } else if (excelimport.state.is_processing) {
        dropclassname += ' excel-upload-processing'
        ImportPanel = (
            <>
                <div className={dropclassname}>
                    <Header>{app.text('Processing')}</Header>
                    <div style={{ marginBottom: 10 }}>
                        {app.text('File ')}'{excelimport.state.file.name}'
                    </div>
                    <div style={{ marginBottom: 10 }}>
                        {app.text('Please wait a moment…')}
                    </div>
                    <div>
                        <Icon name="settings" size={2} spin />
                    </div>
                </div>
            </>
        )
    } else if (excelimport.state.is_result) {
        dropclassname +=
            ' excel-upload-result excel-upload-result-' + excelimport.state.result

        let header
        let message
        let note
        switch (excelimport.state.result) {
            case 'error':
                header = app.text('Not imported!')
                message = app.text(
                    'The uploaded file has structural errors. These are indicated in the report.'
                )
                note = app.text('You can modify the report, save it, then re-upload.')
                break
            case 'warning':
                header = app.text('Import complete')
                message = app.text(
                    'Field validation showed some errors (not imported) or warnings (imported). Both types are indicated in the report.'
                )
                note = app.text('You can modify the report, save it, then re-upload.')
                break
            case 'ok':
                header = app.text('Import complete')
                message = app.text(
                    'File imported successfully. Changed records and values are indicated in the report.'
                )
                break
            default:
                header = app.text('Unknown state')
                message = app.text('Returned state is ') + excelimport.state.result
                break
        }

        ImportPanel = (
            <>
                <div className={dropclassname}>
                    <Header>{header}</Header>
                    <div style={{ marginBottom: 10 }}>{message}</div>
                    <Bar style={{ marginBottom: 10 }} raised>
                        <Button
                            action
                            onClick={() =>
                                download_dataurl(
                                    excelimport.state.dataurl,
                                    excelimport.state.reportname
                                )
                            }
                        >
                            {app.text('Download report')}
                        </Button>
                    </Bar>
                    <Bar raised>
                        <Button action onClick={() => excelimport.setReadyState()}>
                            {app.text('Reset')}
                        </Button>
                    </Bar>
                </div>
                {note ? <div className="cc-note">{note}</div> : undefined}
            </>
        )
    }

    return (
        <VView className="excel-import-panel">
            <FileUpload.Input
                onFileChange={onFileSelection}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
            <Header>{app.text('Import Excel file')}</Header>
            <VView className="inspector">{ImportPanel}</VView>
        </VView>
    )
})

//
// FieldOptions
//
// Field options based on field type

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../stores'

import { Toggle } from '../../../components'

import {
    TextFieldOptions,
    TextlineFieldOptions,
    TextlistFieldOptions,
    CodeFieldOptions,
    BarcodeFieldOptions,
    BooleanFieldOptions,
    NumberFieldOptions,
    DecimalFieldOptions,
    RecordFieldOptions,
    RecordlistFieldOptions,
    ImageFieldOptions,
    ImagelistFieldOptions,
    FileFieldOptions,
    FilelistFieldOptions,
    FieldpickerFieldOptions,
    ClassFieldOptions,
    ClasslistFieldOptions,
} from './typefieldoptions'
import { LabeledField } from '../../components'

export const FieldOptions = observer(function FieldOptions({
    field,
    worksheet,
    selectModified,
    onAction,
    setSelected,
    renderkeyprefix,
}) {
    const { app } = useStore()

    if (!field) return null

    const onToggleFieldIsRequired = value => {
        field.setProperty('is_required', value)
        field.commitIfModified().then(result => selectModified(result))
    }

    const FieldOptionsByType = {
        text: TextFieldOptions,
        textline: TextlineFieldOptions,
        textlist: TextlistFieldOptions,
        code: CodeFieldOptions,
        barcode: BarcodeFieldOptions,
        boolean: BooleanFieldOptions,
        number: NumberFieldOptions,
        decimal: DecimalFieldOptions,
        record: RecordFieldOptions,
        recordlist: RecordlistFieldOptions,
        image: ImageFieldOptions,
        imagelist: ImagelistFieldOptions,
        file: FileFieldOptions,
        filelist: FilelistFieldOptions,
        fieldpicker: FieldpickerFieldOptions,
        class: ClassFieldOptions,
        classlist: ClasslistFieldOptions,
    }

    const TypeFieldOptions = FieldOptionsByType[field.type]

    return (
        <div className="field-options">
            <LabeledField label={app.text('required')}>
                <Toggle onChange={onToggleFieldIsRequired} value={field.is_required} />
            </LabeledField>
            <TypeFieldOptions
                field={field}
                worksheet={worksheet}
                selectModified={selectModified}
                onAction={onAction}
                setSelected={setSelected}
                renderkeyprefix={renderkeyprefix}
            />
        </div>
    )
})

//
// ProjectMembersInspector
//
// Project inspector; show owner

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../stores'

import { VView } from '../../appview'
import { Header } from '../../components'
import { ProjectMember } from '../../panels'

export const ProjectMembersInspector = observer(function ProjectMembersInspector({
    project,
}) {
    const { app, data } = useStore()

    const owner = data.users.get(project.owner)
    if (!owner) return null

    return (
        <VView grow className="panel members">
            <Header>{app.text('Owner')}</Header>
            <ProjectMember user={owner} />
        </VView>
    )
})

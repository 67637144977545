//
// SignUpScreen
//
// Sign up for an account

import React from 'react'
import { useStore } from '../stores'
import { observer } from 'mobx-react-lite'

import { SiteScreen } from './SiteScreen'
import { VView, Spacer } from '../appview'
import { Bar, Button, Property, Text } from '../components'
import { UnicatSitePanel } from '../panels'

export const SignUpScreen = observer(function SignUpScreen() {
    const store = useStore()
    const app = store.app

    const onGotoLogin = () => {
        store.setLocation(store.LOCATIONS.LOGIN)
    }

    const message = store.isLoggedIn
        ? app.text("Hi {username}, you're already logged in.", {
              username: store.user.displayname,
          })
        : undefined

    const Activity = store.isLoggedIn ? (
        <>
            <Property label={''}>
                <Text>{message}</Text>
            </Property>
            <Spacer size={10} />
            <Property label={''}>
                <Bar raised>
                    <Button action onClick={() => store.autoLocation()}>
                        {app.text('Continue to Unicat')}
                    </Button>
                </Bar>
            </Property>
            <Spacer size={20} />
            <Property label={''}>
                <Bar raised>
                    <Button action onClick={() => store.logout()}>
                        {app.text('Log out')}
                    </Button>
                </Bar>
            </Property>
            <Spacer size={20} />
        </>
    ) : (
        <>
            <Spacer size={20} />
            <Property label={''}>
                <Text>
                    {app.text(
                        'Signup is not enabled yet. Please contact us for more information, or get an invite from an existing project.'
                    )}
                </Text>
            </Property>
        </>
    )

    return (
        <SiteScreen>
            <VView className="form signup-form">
                <UnicatSitePanel has_link={true} title={app.text('Unicat Signup')} />
                {Activity}
                <Spacer size={10} />
                {!store.isLoggedIn ? (
                    <Property label={''}>
                        <hr />
                        <br />
                        <Text className="link" onClick={() => onGotoLogin()}>
                            {app.text('I already have an account.')}
                        </Text>
                    </Property>
                ) : undefined}
            </VView>
        </SiteScreen>
    )
})

//
// FieldlistLayoutBuilder
//
// Based on view info from a layout

import React from 'react'
import { observer } from 'mobx-react-lite'

import { VView, Divider } from '../../../../appview'
import { Text } from '../../../../components'
import { FieldLayoutBuilder } from '.'
import { LAYOUTCOMPONENTTYPECONFIG } from '../../../../stores/data/LAYOUTCOMPONENTTYPECONFIG'

export const FieldlistLayoutBuilder = observer(function FieldlistLayoutBuilder({
    className,
    component,
    components,
    layout,
    record,
    worksheet,
    active_class,
    visited_gids,
    force_readonly,
}) {
    const config = LAYOUTCOMPONENTTYPECONFIG['fieldlist']

    const language = worksheet.environment.get('language')

    const definition = worksheet.definitions.get(record.definition)
    if (!definition) return null

    const fieldnames = component.fieldlist ? [component.fieldlist] : ['{fieldlist}']
    const fieldswithclass = worksheet.getNamedFieldsWithClass(definition, fieldnames)
    const editable = force_readonly
        ? false
        : 'editable' in component
        ? component.editable
        : config.editable.default

    let Fields = []
    let currentclass = null
    fieldswithclass.forEach((fieldwithclass, index) => {
        const field = fieldwithclass[0]
        if (!field) return null
        const class_ = fieldwithclass[1]
        const renderkey =
            record.gid + '.' + (class_ ? class_.gid + '.' : '') + field.gid

        if (fieldnames.includes('{all}')) {
            if (class_ !== currentclass) {
                if (class_) {
                    const label = class_.label.get(language) || class_.name
                    let sectionclasses = 'ws-section-header'
                    if (
                        class_.gid === worksheet.selected.classgid &&
                        !worksheet.selected.property.fieldgid
                    ) {
                        sectionclasses += ' ws-selected'
                    }
                    Fields.push(
                        <Text
                            key={class_.gid + '.' + index}
                            className={sectionclasses}
                            onClick={() =>
                                worksheet.setSelected(
                                    worksheet.record,
                                    class_.gid,
                                    null,
                                    class_.gid,
                                    null
                                )
                            }
                        >
                            {label}
                        </Text>
                    )
                } else {
                    Fields.push(
                        <Divider key={'key-' + index} className="ws-margin-bottom" />
                    )
                }
            }
        }

        const fieldtypeconfig =
            LAYOUTCOMPONENTTYPECONFIG['field']['typeconfig'][field.type]
        const fieldtypestyle = fieldtypeconfig['style']
            ? fieldtypeconfig['style']['default']
            : undefined

        const fieldlayoutbuildercomponent = {
            gid: '-not-selectable-' + index,
            field: field.name,
            placeholder: '',
            editable: editable,
            style: fieldtypestyle,
            width: 250, // image
            columns: 3, // imagelist
        }

        Fields.push(
            <FieldLayoutBuilder
                key={renderkey}
                component={fieldlayoutbuildercomponent}
                components={{}}
                layout={layout}
                record={record}
                worksheet={worksheet}
                active_class={active_class}
                visited_gids={visited_gids}
                force_readonly={force_readonly}
            />
        )

        currentclass = class_
    })

    let attributes = { className: 'layout-component' }
    if (className) attributes['className'] += ' ' + className
    if (component.gid === worksheet.layoutstore.selectedComponentGid) {
        attributes['className'] += ' cc-selected'
    }
    // attributes["onClick"] = e => onClickElement(e, component, layouttreestore)
    return <VView {...attributes}>{Fields}</VView>
})

//
// FolderWorksheet
//
// Folders display their subfolders and documents, so you can navigate further

import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'
import { useStore } from '../../../stores'
import { useMousePopover } from '../../../hooks/useMousePopover'
import { useElementWidth } from '../../../hooks/useElementWidth'

import {
    VView,
    HView,
    View,
    LazyVScrollTiles,
    LazyScrollItem,
    Spacer,
    LayerView,
    Layer,
} from '../../../appview'
import { Icon, Header, Text, TextInput } from '../../../components'
import { Transform } from '../../../stores/imageservers/utils'
import { DeletedTreeAssetMenu, TreeAssetMenu } from '../../../menus'
import { AssetThumb } from '../../../panels'
import { DragDropAsset } from '../../../dragdrop/DragDropAsset'
import { DragTypes } from '../../../dragdrop/DragTypes'
import { validAssetName } from '../../../utils/text'

const MAGIC_INITIAL_TILE_WIDTH = 142
const MAGIC_TILE_HEIGHT = 156

const AssetPreview = observer(function AssetPreview({ asset, transform }) {
    const { app, imageserver } = useStore()

    if (!asset.is_file) {
        return (
            <View
                center
                style={{
                    width: transform.width,
                    height: transform.height,
                }}
            >
                <LayerView>
                    <Icon size={80} name={asset.iconname} />
                    <Layer anchor="bottom-end" className="badge dam-folder-info">
                        {asset.childcount ? asset.childcount : app.text('empty')}
                    </Layer>
                </LayerView>
            </View>
        )
    }

    if (!imageserver.canTransform(asset)) {
        return (
            <View
                center
                style={{
                    width: transform.width,
                    height: transform.height,
                }}
            >
                <Icon size={80} name={asset.iconname} />
            </View>
        )
    }

    return <AssetThumb asset={asset} transform={transform} />
})

const AssetItem = observer(function AssetItem({ asset }) {
    const [ref, width] = useElementWidth(MAGIC_INITIAL_TILE_WIDTH)

    let transform = new Transform('fit', width, MAGIC_TILE_HEIGHT - 10 - 2 - 5 - 15)

    return (
        <VView ref={ref}>
            <AssetPreview asset={asset} transform={transform} />
            <Spacer size={5} />
            <div
                style={{
                    height: 15,
                    wordWrap: 'break-word',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}
            >
                {asset.publicname}
            </div>
        </VView>
    )
})

export const FolderWorksheet = observer(function FolderWorksheet({ worksheet }) {
    const { app, view, data } = useStore()

    const onChangeAssetName = event => {
        worksheet.asset.setProperty('name', event.target.value + assetext)
    }
    const onBlurAssetName = event => {
        let newname = event.target.value.trim() + assetext
        worksheet.asset.setProperty('name', newname)
        worksheet.asset.commitIfModified()
    }
    const renderkey = worksheet.asset.gid + '.' + worksheet.language
    const _assetnameparts = worksheet.asset.publicname.split('.')
    const assetext = _assetnameparts.length > 1 ? '.' + _assetnameparts.pop() : ''
    const assetname = _assetnameparts.join('.')

    const columns = 5
    const itemheight = MAGIC_TILE_HEIGHT
    const [topindex, setTopindex] = useState(worksheet.topindex)
    const [windowsize, setWindowsize] = useState(worksheet.windowsize)

    const onUpdate = (newtopindex, newwindowsize) => {
        if (newtopindex !== topindex || newwindowsize !== windowsize) {
            worksheet
                .fetch(worksheet.asset.gid, newtopindex, newwindowsize)
                .then(() => {
                    setTopindex(newtopindex)
                    setWindowsize(newwindowsize)
                })
        }
    }

    const totalsize = worksheet.totalsize

    const [selectedAsset, setSelectedAsset] = useState(null)
    const TreeAssetContextMenu = useMousePopover(TreeAssetMenu)
    const DeletedTreeAssetContextMenu = useMousePopover(DeletedTreeAssetMenu)

    const [{ isOver }, drop] = useDrop({
        accept: [DragTypes.DAM_ASSET, NativeTypes.FILE],
        collect: monitor => {
            const isOver = !!monitor.isOver()
            return {
                isOver: isOver,
            }
        },
        drop: (item, monitor) => {
            if (monitor.didDrop()) return
            if (item.hasOwnProperty('files')) {
                item.files.forEach(file => {
                    if (!file.type) {
                        console.log(file.name + ' - folder upload not supported')
                        return false
                    }
                    data.actions.assets
                        .upload(file, worksheet.asset.gid)
                        .catch(error => {})
                })
            } else {
                data.actions.assets
                    .move(item.gid, worksheet.asset.gid)
                    .catch(error => {})
            }
        },
    })

    let dropclassname = 'dam-worksheet-upload'
    if (isOver) dropclassname = 'dam-worksheet-upload drag-over-inside'

    const onDrop = (dragitem, dropitem, dropzone) => {
        if (dragitem.hasOwnProperty('files')) {
            dragitem.files.forEach(file => {
                if (!file.type) {
                    console.log(file.name + ' - folder upload not supported')
                    return false
                }
                data.actions.assets.upload(file, dropitem.gid).catch(error => {})
            })
        } else {
            data.actions.assets.move(dragitem.gid, dropitem.gid).catch(error => {})
        }
    }

    const onCreateAsset = () => {
        if (!selectedAsset) return
        view.damtree.create({ item: selectedAsset })
    }

    const onUploaded = newgid => {
        if (!selectedAsset) return
        view.damworksheet.refetch()
    }

    const onDownload = () => {
        if (!selectedAsset) return
        selectedAsset.download()
    }

    const onDelete = () => {
        if (!selectedAsset) return
        selectedAsset.delete()
    }

    const onUndelete = () => {
        if (!selectedAsset) return
        selectedAsset.undelete()
    }

    const onPermanentDelete = () => {
        if (!selectedAsset) return
        selectedAsset.permanentDelete()
    }

    const onAssetAction = (action, p1) => {
        const actions = {
            create_asset: onCreateAsset,
            uploaded: onUploaded,
            download: onDownload,
            delete: onDelete,
            undelete: onUndelete,
            permanent_delete: onPermanentDelete,
        }
        if (!(action in actions) || !selectedAsset) {
            console.log(
                `onAssetAction: unhandled action '${action}' on '${selectedAsset.title}'`
            )
            return
        }
        TreeAssetContextMenu.hide()
        DeletedTreeAssetContextMenu.hide()
        actions[action](p1)
    }

    useEffect(() => {
        worksheet.scrollToTop = false
    }, [worksheet, worksheet.scrollToTop])

    let Assets = <div>{app.text('No folder selected.')}</div>
    if (worksheet.children) {
        if (!worksheet.children.length) {
            Assets = (
                <Text>
                    {app.text(
                        'This folder is empty. Drag files here or to the folder in the treeview.'
                    )}
                </Text>
            )
        } else {
            const Tiles = worksheet.children.map((asset, index) => {
                const contextmenu =
                    asset.status !== 'deleted'
                        ? TreeAssetContextMenu
                        : DeletedTreeAssetContextMenu

                const tileclasses = 'ws-tile status-' + asset.status
                return (
                    <LazyScrollItem key={asset.gid} index={index + topindex}>
                        <DragDropAsset asset={asset} onDrop={onDrop}>
                            <div
                                className={tileclasses}
                                title={asset.publicname}
                                onClick={() => worksheet.setAsset(asset)}
                                onContextMenu={e => {
                                    setSelectedAsset(asset)
                                    contextmenu.onShow(e)
                                }}
                            >
                                <AssetItem asset={asset} />
                            </div>
                        </DragDropAsset>
                    </LazyScrollItem>
                )
            })
            Assets = (
                <LazyVScrollTiles
                    className="ws-tiles"
                    firstindex={topindex}
                    itemheight={itemheight}
                    columns={columns}
                    gap={15}
                    totalitems={totalsize}
                    onUpdate={onUpdate}
                    scrollToFirst={worksheet.scrollToTop}
                >
                    {Tiles}
                </LazyVScrollTiles>
            )
        }
    }

    return (
        <VView className="worksheet">
            <Header>{app.text('Asset properties')}</Header>
            <HView>
                <View grow className="cc-Field ws-field">
                    <div className="ws-label">
                        <span className="label">{app.text('name')}</span>
                    </div>
                    <div className="ws-inline">
                        <TextInput
                            autoConvert={validAssetName}
                            onChange={onChangeAssetName}
                            onBlur={onBlurAssetName}
                            value={assetname}
                            renderkey={renderkey}
                            style={{ minWidth: 200 }}
                        />
                        <Text>{assetext}</Text>
                    </div>
                </View>
            </HView>

            <VView grow className={dropclassname} ref={drop}>
                <TreeAssetContextMenu.Panel
                    asset={selectedAsset}
                    onAction={onAssetAction}
                />
                <DeletedTreeAssetContextMenu.Panel
                    asset={selectedAsset}
                    onAction={onAssetAction}
                />
                {Assets}
            </VView>
        </VView>
    )
})

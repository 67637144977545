//
// MultiselectionTypes
//
// for a record/asset tree multiselection, we can have a FLAT or NESTED selection type
// FLAT means that for all selected records, NO ancestors are in the selection
// NESTED means that for at least one selected record there is an ancestor selected too
//
// determineRecordsMultiselectionType(treerecordsselection, data)
//
// returns a MultiselectionType based on the records tree selection
//
// determineAssetsMultiselectionType(treeassetsselection, data)
//
// returns a MultiselectionType based on the assets tree selection

import { union } from './set'

export const MultiselectionTypes = {
    FLAT: 'flat', // no ancestors of selection items are selected items
    NESTED: 'nested', // selection also includes ancestors of selected items
}

export const determineRecordsMultiselectionType = (treerecordsselection, data) => {
    // treerecordsselection is always a list of gids
    // determine pool of ancestors
    // check selection against ancestors - there should be no overlap
    const selection = treerecordsselection.selection
    if (selection.deselected) {
        return MultiselectionTypes.NESTED
        // throw new Error('Select all is not supported')
    }
    const gids = [...selection.selected]
    let ancestors = new Set()
    gids.forEach(gid => {
        const record = data.records.get(gid)
        if (!record) return
        let path = new Set(record.path)
        path.delete(gid)
        ancestors = union(ancestors, path)
    })
    const isFlat = gids.reduce((value, gid) => value && !ancestors.has(gid), true)
    return isFlat ? MultiselectionTypes.FLAT : MultiselectionTypes.NESTED
}

export const multiselectionHasDeletedRecords = (treerecordsselection, data) => {
    // treerecordsselection is always a list of gids
    // determine pool of ancestors
    // check selection against ancestors - there should be no overlap
    const selection = treerecordsselection.selection
    if (selection.deselected) {
        return false
        // throw new Error('Select all is not supported')
    }
    const gids = [...selection.selected]
    for (const gid of gids) {
        const record = data.records.get(gid)
        if (!record) continue
        if (record.status === 'deleted') {
            return true
        }
    }
    return false
}

export const determineAssetsMultiselectionType = (treeassetsselection, data) => {
    // treeassetsselection is always a list of gids
    // parent/child relationship is determined by path and pathname
    // so the 'path' of an asset is e.g. /barcodes/contacts/file.png
    // then the ancestors list is / and /barcodes and /barcodes/contacts
    // determine pool of ancestors
    // check selection against ancestors - there should be no overlap
    const selection = treeassetsselection.selection
    if (selection.deselected) {
        return MultiselectionTypes.NESTED
        // throw new Error('Select all is not supported')
    }
    const gids = [...selection.selected]
    let ancestors = new Set()
    gids.forEach(gid => {
        const asset = data.assets.get(gid)
        if (!asset) return
        let assetancestors = asset.pathname.split('/')
        let path = new Set()
        while (assetancestors.length) {
            assetancestors.pop() // remove deepest
            const assetancestorpath = assetancestors.join('/')
            path.add(assetancestorpath.length ? assetancestorpath : '/')
        }
        ancestors = union(ancestors, path)
    })
    const isFlat = gids.reduce((value, gid) => {
        const asset = data.assets.get(gid)
        if (!asset) return false
        return value && !ancestors.has(asset.pathname)
    }, true)
    return isFlat ? MultiselectionTypes.FLAT : MultiselectionTypes.NESTED
}

export const multiselectionHasDeletedAssets = (treeassetsselection, data) => {
    // treerecordsselection is always a list of gids
    // determine pool of ancestors
    // check selection against ancestors - there should be no overlap
    const selection = treeassetsselection.selection
    if (selection.deselected) {
        return false
        // throw new Error('Select all is not supported')
    }
    const gids = [...selection.selected]
    for (const gid of gids) {
        const asset = data.assets.get(gid)
        if (!asset) continue
        if (asset.status === 'deleted') {
            return true
        }
    }
    return false
}

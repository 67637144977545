//
// LanguagesMenu
//
// Actions on project languages in the project settings

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Menu, MenuItem } from '../components'

export const LanguagesMenu = observer(function LanguagesMenu({
    project,
    language,
    onAction,
}) {
    const { app } = useStore()

    const _onAction = (e, action) => {
        onAction && onAction(e, language, action)
    }

    if (!project) return null

    // if it's already the default language
    const disableDefault = !language || language === project.languages[0]

    // can't delete last remaining language
    const disableDelete = !language || project.languages.length <= 1

    return (
        <Menu>
            <MenuItem
                disabled={disableDefault}
                onClick={e => _onAction(e, 'default_language')}
            >
                {app.text('Set as default')}
            </MenuItem>
            <MenuItem
                className="cc-danger"
                disabled={disableDelete}
                onClick={e => _onAction(e, 'remove_language')}
            >
                {app.text('Remove language')}
            </MenuItem>
        </Menu>
    )
})

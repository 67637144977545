//
// AppScreen
//
// The core app screen, with a header and workspace.
//
// The workspace is added by the specific screen from where we are in the app.
//
// The header has an aboutbox, a link to the account screen (if logged in), and a
// workspace switcher for the active project (if any).
//
// For development purposes, the header also has a link to the Lab workspace.

import React, { useEffect } from 'react'
import { useStore } from '../stores'
import { observer } from 'mobx-react-lite'

import { VView, HView, Divider, Spacer } from '../appview'
import { useTooltipPopover } from '../hooks/useTooltipPopover'
import { AboutPanel } from '../panels'
import { Bar, Button, Icon, Header, Text } from '../components'

export const AppErrorScreen = observer(function AppErrorScreen({ error }) {
    // we don't use onReset here, this is a top-level Error UI and will always
    // trigger a window.location.reload()
    const store = useStore()
    const app = store.app

    const AboutPopover = useTooltipPopover(AboutPanel)

    let pageTitle = app.text('Unicat error')

    const AboutButton = (
        <>
            <AboutPopover.Panel version={store.version} />
            <div ref={AboutPopover.anchorRef}>
                <Bar raised className={AboutPopover.isOpen() ? 'cc-selected' : ''}>
                    <Button
                        icon
                        title={app.text('About Unicat')}
                        onClick={AboutPopover.onShow}
                    >
                        <Icon name="unicat" size={2} />
                    </Button>
                </Bar>
            </div>
        </>
    )

    useEffect(() => {
        document.title = pageTitle
    }, [pageTitle])

    const onResetRefreshScreen = () => {
        window.location.reload()
    }

    const onResetRefreshProject = () => {
        store.clearProjectState()
        window.location.reload()
    }

    const onResetRefreshProjectLogout = () => {
        store.clearProjectState()
        store.logout()
        window.location.reload()
    }

    const onResetRefreshAllProjects = () => {
        store.clearAllProjectsState()
        window.location.reload()
    }

    const onResetRefreshAllProjectsLogout = () => {
        store.clearAllProjectsState()
        store.logout()
        window.location.reload()
    }

    const onResetRefreshEverything = () => {
        store.clearEverything()
        window.location.reload()
    }

    return (
        <VView>
            <HView className="header">{AboutButton}</HView>

            <Divider />

            <VView grow>
                <VView className="worksheet">
                    <HView>
                        <Spacer />
                        <VView className="form">
                            <Spacer size={20} />
                            <HView className="panel">
                                <VView>
                                    <div>
                                        <Icon
                                            name="oops"
                                            size={80}
                                            title={app.text('Banana of Oops!')}
                                        />
                                    </div>
                                </VView>
                                <Spacer size={20} />
                                <VView grow>
                                    <Header
                                        className="cc-danger"
                                        style={{ fontSize: 14 }}
                                    >
                                        {app.text("Oops! We've slipped up!")}
                                    </Header>
                                    <Divider />
                                    <Spacer size={10} />
                                    <Header>
                                        {app.text(
                                            "The error is logged and will be analyzed later. For now, let's get you back up and running, if possible."
                                        )}
                                    </Header>
                                    <Spacer size={10} />
                                    <Text>
                                        {app.text(
                                            "There are a number of actions you can take, some of which will clear data stored in your browser. These actions don't and can't affect the project data that is stored on our servers."
                                        )}
                                    </Text>
                                    <Spacer size={10} />
                                    <Text>
                                        {app.text(
                                            'Try these in order, until the error is resolved.'
                                        )}
                                    </Text>
                                    <Spacer size={10} />
                                    <Text>
                                        {app.text('Action 1. Refresh the screen.')}
                                    </Text>
                                    <button onClick={() => onResetRefreshScreen()}>
                                        {app.text('Refresh the screen')}
                                    </button>
                                    <Spacer size={10} />
                                    <Text>
                                        {app.text(
                                            'Action 2. Refresh the project, optionally log out.'
                                        )}
                                    </Text>
                                    <button onClick={() => onResetRefreshProject()}>
                                        {app.text('Refresh the project')}
                                    </button>
                                    <Spacer size={10} />
                                    <button
                                        onClick={() => onResetRefreshProjectLogout()}
                                    >
                                        {app.text('Refresh the project and log out')}
                                    </button>
                                    <Spacer size={10} />
                                    <Text>
                                        {app.text(
                                            'Action 3. Refresh all projects, optionally log out.'
                                        )}
                                    </Text>
                                    <button onClick={() => onResetRefreshAllProjects()}>
                                        {app.text('Refresh all projects')}
                                    </button>
                                    <Spacer size={10} />
                                    <button
                                        onClick={() =>
                                            onResetRefreshAllProjectsLogout()
                                        }
                                    >
                                        {app.text('Refresh all projects and log out')}
                                    </button>
                                    <Spacer size={10} />
                                    <Text>
                                        {app.text(
                                            'Action 4. Refresh everything for all users, log out.'
                                        )}
                                    </Text>
                                    <button onClick={() => onResetRefreshEverything()}>
                                        {app.text('Refresh everything')}
                                    </button>
                                    <Spacer size={10} />
                                    <Text>
                                        {app.text(
                                            "If all these actions don't help, please contact us."
                                        )}
                                    </Text>
                                </VView>
                            </HView>
                        </VView>
                        <Spacer />
                    </HView>
                </VView>
            </VView>
        </VView>
    )
})

//
// ProjectSettingsWorksheet
//
// Project settings worksheet

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { DragTypes } from '../../dragdrop/DragTypes'
import { DragDropItem } from '../../dragdrop/DragDropItem'
import { useStore } from '../../stores'
import { useMousePopover } from '../../hooks/useMousePopover'
import { useTooltipPopover } from '../../hooks/useTooltipPopover'

import { VView, HView, Spacer } from '../../appview'
import {
    Header,
    Property,
    Text,
    TextInput,
    Item,
    Icon,
    Menu,
    MenuItem,
    usePopoverContext,
} from '../../components'
import {
    UploadProjectIconPanel,
    ChangeProjectIconPanel,
    ProjectAddLanguagePanel,
} from '../../panels'
import {
    LanguagesMenu,
    ProjectChannelsMenu,
    ProjectOrderingsMenu,
    ProjectFieldlistsMenu,
} from '../../menus'

const Language = observer(function Language({
    language,
    is_default,
    is_selected,
    onDrop,
    onClick,
    onContextMenu,
}) {
    const { data, app } = useStore()
    const languagelabel = data.languages.get(language).name
    const classname = is_selected ? 'cc-selected' : undefined
    const role = is_default ? (
        <span className="role">{app.text('default')}</span>
    ) : undefined
    return (
        <DragDropItem type={DragTypes.LANGUAGE} id={language} onDrop={onDrop}>
            <Text className={classname} onClick={onClick} onContextMenu={onContextMenu}>
                {app.text(languagelabel)}
                {role}
            </Text>
        </DragDropItem>
    )
})

const CreateChannelPanel = observer(function CreateChannelPanel({ onAction }) {
    const { app } = useStore()

    const [name, setName] = useState('')
    const isValidName = name.trim().length > 0

    const _onConfirm = () => {
        onAction && onAction(null, null, 'create_channel', name.trim())
    }
    return (
        <VView className="actions-panel">
            <div className="inspector">
                <Header>{app.text('Create channel')}</Header>
                <VView
                    style={{
                        marginLeft: 5,
                        marginRight: 5,
                        marginBottom: 5,
                    }}
                >
                    <TextInput onChange={e => setName(e.target.value)} value={name} />
                </VView>
            </div>
            <Menu>
                <MenuItem disabled={!isValidName} onClick={_onConfirm}>
                    {app.text('Create channel')}
                </MenuItem>
            </Menu>
        </VView>
    )
})

const Channel = observer(function Channel({
    channelkey,
    channelname,
    is_selected,
    onDrop,
    onClick,
    onContextMenu,
}) {
    const classname = is_selected ? 'cc-selected' : undefined

    return (
        <DragDropItem type={DragTypes.CHANNEL} id={channelkey} onDrop={onDrop}>
            <div className={classname} onClick={onClick} onContextMenu={onContextMenu}>
                <Text>{channelname}</Text>
            </div>
        </DragDropItem>
    )
})

const CreateOrderingPanel = observer(function CreateOrderingPanel({ onAction }) {
    const { app } = useStore()

    const [name, setName] = useState('')
    const isValidName = name.trim().length > 0

    const _onConfirm = () => {
        onAction && onAction(null, null, 'create_ordering', name.trim())
    }
    return (
        <VView className="actions-panel">
            <div className="inspector">
                <Header>{app.text('Create ordering')}</Header>
                <VView
                    style={{
                        marginLeft: 5,
                        marginRight: 5,
                        marginBottom: 5,
                    }}
                >
                    <TextInput onChange={e => setName(e.target.value)} value={name} />
                </VView>
            </div>
            <Menu>
                <MenuItem disabled={!isValidName} onClick={_onConfirm}>
                    {app.text('Create ordering')}
                </MenuItem>
            </Menu>
        </VView>
    )
})

const Ordering = observer(function Ordering({
    orderingkey,
    orderingname,
    is_selected,
    onDrop,
    onClick,
    onContextMenu,
}) {
    const classname = is_selected ? 'cc-selected' : undefined

    return (
        <DragDropItem type={DragTypes.ORDERING} id={orderingkey} onDrop={onDrop}>
            <div className={classname} onClick={onClick} onContextMenu={onContextMenu}>
                <Text>{orderingname}</Text>
            </div>
        </DragDropItem>
    )
})

const CreateFieldlistPanel = observer(function CreateFieldlistPanel({ onAction }) {
    const { app } = useStore()

    const [name, setName] = useState('')
    const isValidName = name.trim().length > 0

    const _onConfirm = () => {
        onAction && onAction(null, null, 'create_fieldlist', name.trim())
    }
    return (
        <VView className="actions-panel">
            <div className="inspector">
                <Header>{app.text('Create fieldlist')}</Header>
                <VView
                    style={{
                        marginLeft: 5,
                        marginRight: 5,
                        marginBottom: 5,
                    }}
                >
                    <TextInput onChange={e => setName(e.target.value)} value={name} />
                </VView>
            </div>
            <Menu>
                <MenuItem disabled={!isValidName} onClick={_onConfirm}>
                    {app.text('Create fieldlist')}
                </MenuItem>
            </Menu>
        </VView>
    )
})

const Fieldlist = observer(function Fieldlist({
    fieldlistkey,
    fieldlistname,
    is_selected,
    onDrop,
    onClick,
    onContextMenu,
}) {
    const classname = is_selected ? 'cc-selected' : undefined

    return (
        <DragDropItem type={DragTypes.FIELDLIST} id={fieldlistkey} onDrop={onDrop}>
            <div className={classname} onClick={onClick} onContextMenu={onContextMenu}>
                <Text>{fieldlistname}</Text>
            </div>
        </DragDropItem>
    )
})

export const ProjectSettingsWorksheet = observer(function ProjectSettingsWorksheet({
    project,
}) {
    const popoverContext = usePopoverContext()

    const { app } = useStore()

    // ContextMenus
    const LanguagesContextMenu = useMousePopover(LanguagesMenu)
    const ProjectAddLanguageContextMenu = useMousePopover(ProjectAddLanguagePanel)
    const ProjectCreateChannelPopover = useMousePopover(CreateChannelPanel)
    const ProjectChannelsContextMenu = useMousePopover(ProjectChannelsMenu)
    const ProjectCreateOrderingPopover = useMousePopover(CreateOrderingPanel)
    const ProjectOrderingsContextMenu = useMousePopover(ProjectOrderingsMenu)
    const ProjectCreateFieldlistPopover = useMousePopover(CreateFieldlistPanel)
    const ProjectFieldlistsContextMenu = useMousePopover(ProjectFieldlistsMenu)

    const [selectedItem, setSelectedItem] = useState([null, null])
    const checkSelected = (type, value) =>
        selectedItem[0] === type && selectedItem[1] === value

    const onNameChange = value => {
        project.renameProject(value)
    }
    const onNameBlur = value => {
        project.renameProject(value.trim())
        project.commitRenameProject()
    }

    const onDropLanguage = (draglanguage, droplanguage, dropzone) => {
        let before_language = null
        if (dropzone === 'top') {
            before_language = droplanguage
        } else if (dropzone === 'bottom') {
            let next = false
            for (const language of project.languages) {
                if (language === draglanguage) {
                    continue
                }
                if (next) {
                    before_language = language
                    break
                }
                if (language === droplanguage) next = true
            }
        }
        project.moveLanguage(draglanguage, before_language)
    }

    const onDropChannel = (dragchannelkey, dropchannelkey, dropzone) => {
        let before_channelkey = null
        if (dropzone === 'top') {
            before_channelkey = dropchannelkey
        } else if (dropzone === 'bottom') {
            let next = false
            for (const channelkey of project.orderedchannels) {
                if (channelkey === dragchannelkey) {
                    continue
                }
                if (next) {
                    before_channelkey = channelkey
                    break
                }
                if (channelkey === dropchannelkey) next = true
            }
        }
        project.moveChannel(dragchannelkey, before_channelkey)
    }

    const onDropOrdering = (dragorderingkey, droporderingkey, dropzone) => {
        let before_orderingkey = null
        if (dropzone === 'top') {
            before_orderingkey = droporderingkey
        } else if (dropzone === 'bottom') {
            let next = false
            for (const orderingkey of project.orderedorderings) {
                if (orderingkey === dragorderingkey) {
                    continue
                }
                if (next) {
                    before_orderingkey = orderingkey
                    break
                }
                if (orderingkey === droporderingkey) next = true
            }
        }
        project.moveOrdering(dragorderingkey, before_orderingkey)
    }

    const onDropFieldlist = (dragfieldlistkey, dropfieldlistkey, dropzone) => {
        let before_fieldlistkey = null
        if (dropzone === 'top') {
            before_fieldlistkey = dropfieldlistkey
        } else if (dropzone === 'bottom') {
            let next = false
            for (const fieldlistkey of project.orderedfieldlists) {
                if (fieldlistkey === dragfieldlistkey) {
                    continue
                }
                if (next) {
                    before_fieldlistkey = fieldlistkey
                    break
                }
                if (fieldlistkey === dropfieldlistkey) next = true
            }
        }
        project.moveFieldlist(dragfieldlistkey, before_fieldlistkey)
    }

    const onAddLanguage = language => {
        project.addLanguage(language)
        popoverContext.store.show([...popoverContext.stack])
    }

    const onAddLanguagePanel = (e, _) => {
        setSelectedItem([null, null])
        ProjectAddLanguageContextMenu.onShow(e)
    }

    const onRemoveLanguage = (e, language) => {
        project.removeLanguage(language)
    }

    const onSetAsDefaultLanguage = (e, language) => {
        const before_language = project.languages[0]
        project.moveLanguage(language, before_language)
    }

    const onLanguagesAction = (e, language, action) => {
        popoverContext.store.show([...popoverContext.stack])
        const actions = {
            add_language: onAddLanguagePanel,
            remove_language: onRemoveLanguage,
            default_language: onSetAsDefaultLanguage,
        }
        if (!(action in actions)) {
            console.log(
                `onLanguagesAction: unhandled action '${action}' on '${language}'`
            )
            return
        }
        actions[action](e, language)
    }

    const Languages = project.languages.map((language, index) => {
        return (
            <Language
                key={language}
                language={language}
                is_default={!index}
                is_selected={checkSelected('language', language)}
                onDrop={onDropLanguage}
                onClick={e => {
                    setSelectedItem(
                        checkSelected('language', language)
                            ? [null, null]
                            : ['language', language]
                    )
                }}
                onContextMenu={e => {
                    setSelectedItem(['language', language])
                    LanguagesContextMenu.onShow(e)
                }}
            />
        )
    })

    const onRenameChannel = (_, channelkey, value) => {
        project.renameChannel(channelkey, value.trim())
        project.commitRenameChannel(channelkey)
    }

    const onCreateChannel = (_, channelkey, value) => {
        project.createChannel(app.text(value.trim())).then(created_channelkey => {
            setSelectedItem(['channel', created_channelkey])
        })
    }

    const onDeleteChannel = (_, channelkey) => {
        project.deleteChannels([channelkey])
    }

    const onChannelsAction = (e, channelkey, action, value) => {
        popoverContext.store.show([...popoverContext.stack])
        const actions = {
            rename_channel: onRenameChannel,
            create_channel: onCreateChannel,
            delete_channel: onDeleteChannel,
        }
        if (!(action in actions)) {
            console.log(
                `onChannelsAction: unhandled action '${action}' on '${channelkey}'`
            )
            return
        }
        actions[action](e, channelkey, value)
    }

    const Channels = project.orderedchannels.map(channelkey => {
        if (channelkey === '1') {
            return null
        }
        return (
            <Channel
                key={channelkey}
                channelkey={channelkey}
                channelname={project.channels.get(channelkey)}
                is_selected={checkSelected('channel', channelkey)}
                onDrop={onDropChannel}
                onClick={e => {
                    if (checkSelected('channel', channelkey)) {
                        setSelectedItem(['channel', null])
                    } else {
                        setSelectedItem(['channel', channelkey])
                    }
                }}
                onContextMenu={e => {
                    setSelectedItem(['channel', channelkey])
                    ProjectChannelsContextMenu.onShow(e)
                }}
            />
        )
    })

    const onRenameOrdering = (_, orderingkey, value) => {
        project.renameOrdering(orderingkey, value.trim())
        project.commitRenameOrdering(orderingkey)
    }

    const onCreateOrdering = (_, orderingkey, value) => {
        project.createOrdering(app.text(value.trim())).then(created_orderingkey => {
            setSelectedItem(['ordering', created_orderingkey])
        })
    }

    const onDeleteOrdering = (_, orderingkey) => {
        project.deleteOrdering(orderingkey)
    }

    const onOrderingsAction = (e, orderingkey, action, value) => {
        popoverContext.store.show([...popoverContext.stack])
        const actions = {
            rename_ordering: onRenameOrdering,
            create_ordering: onCreateOrdering,
            delete_ordering: onDeleteOrdering,
        }
        if (!(action in actions)) {
            console.log(
                `onOrderingsAction: unhandled action '${action}' on '${orderingkey}'`
            )
            return
        }
        actions[action](e, orderingkey, value)
    }

    const Orderings = project.orderedorderings.map(orderingkey => {
        return (
            <Ordering
                key={orderingkey}
                orderingkey={orderingkey}
                orderingname={project.orderings.get(orderingkey)}
                is_selected={checkSelected('ordering', orderingkey)}
                onDrop={onDropOrdering}
                onClick={e => {
                    if (checkSelected('ordering', orderingkey)) {
                        setSelectedItem(['ordering', null])
                    } else {
                        setSelectedItem(['ordering', orderingkey])
                    }
                }}
                onContextMenu={e => {
                    setSelectedItem(['ordering', orderingkey])
                    ProjectOrderingsContextMenu.onShow(e)
                }}
            />
        )
    })

    const onRenameFieldlist = (_, fieldlistkey, value) => {
        project.renameFieldlist(fieldlistkey, value.trim())
        project.commitRenameFieldlist(fieldlistkey)
    }

    const onCreateFieldlist = (_, fieldlistkey, value) => {
        project.createFieldlist(app.text(value.trim())).then(created_fieldlistkey => {
            setSelectedItem(['fieldlist', created_fieldlistkey])
        })
    }

    const onDeleteFieldlist = (_, fieldlistkey) => {
        project.deleteFieldlist(fieldlistkey)
    }

    const onFieldlistsAction = (e, fieldlistkey, action, value) => {
        popoverContext.store.show([...popoverContext.stack])
        const actions = {
            rename_fieldlist: onRenameFieldlist,
            create_fieldlist: onCreateFieldlist,
            delete_fieldlist: onDeleteFieldlist,
        }
        if (!(action in actions)) {
            console.log(
                `onFieldlistsAction: unhandled action '${action}' on '${fieldlistkey}'`
            )
            return
        }
        actions[action](e, fieldlistkey, value)
    }

    const Fieldlists = project.orderedfieldlists.map(fieldlistkey => {
        return (
            <Fieldlist
                key={fieldlistkey}
                fieldlistkey={fieldlistkey}
                fieldlistname={project.fieldlists.get(fieldlistkey)}
                is_selected={checkSelected('fieldlist', fieldlistkey)}
                onDrop={onDropFieldlist}
                onClick={e => {
                    if (checkSelected('fieldlist', fieldlistkey)) {
                        setSelectedItem(['fieldlist', null])
                    } else {
                        setSelectedItem(['fieldlist', fieldlistkey])
                    }
                }}
                onContextMenu={e => {
                    setSelectedItem(['fieldlist', fieldlistkey])
                    ProjectFieldlistsContextMenu.onShow(e)
                }}
            />
        )
    })

    const iconstyle = {
        flexBasis: 80,
        maxWidth: 80,
        minWidth: 80,
    }
    const ChangeProjectIconPopover = useTooltipPopover(ChangeProjectIconPanel)
    const onDoneChangeProjectIcon = () => {
        ChangeProjectIconPopover.hide()
    }

    return (
        <VView className="worksheet worksheet-settings worksheet-project-settings">
            <Header>{app.text('Project settings')}</Header>
            <HView>
                <VView grow>
                    <Property label={app.text('name')}>
                        <TextInput
                            className="ws-title"
                            multiline={false}
                            enabled={true}
                            value={project.name}
                            placeholder={app.text('Project name')}
                            onChange={e => onNameChange(e.target.value)}
                            onBlur={e => onNameBlur(e.target.value)}
                            renderkey={project.gid}
                        />
                    </Property>
                </VView>
                <Spacer size={20} />
                <VView style={iconstyle}>
                    <ChangeProjectIconPopover.Panel
                        project={project}
                        onDone={onDoneChangeProjectIcon}
                    />
                    <Property className="projecticon-property" label={app.text('icon')}>
                        <UploadProjectIconPanel
                            project={project}
                            ref={ChangeProjectIconPopover.anchorRef}
                            onClick={e => ChangeProjectIconPopover.onShow(e)}
                            onContextMenu={e => ChangeProjectIconPopover.onShow(e)}
                        />
                    </Property>
                </VView>
                <Spacer size={5} />
            </HView>
            <HView>
                <VView grow>
                    <LanguagesContextMenu.Panel
                        project={project}
                        language={
                            selectedItem[0] === 'language' ? selectedItem[1] : null
                        }
                        onAction={onLanguagesAction}
                    />
                    <ProjectAddLanguageContextMenu.Panel
                        project={project}
                        onAddLanguage={onAddLanguage}
                    />
                    <Property label={app.text('languages')}>
                        {Languages}
                        <Item onClick={onAddLanguagePanel}>
                            <Icon className="itemtype" name="plus" size="text" />
                            <span className="cc-dimmed">
                                {app.text('Add a language')}
                            </span>
                        </Item>
                    </Property>
                    <ProjectChannelsContextMenu.Panel
                        project={project}
                        channelkey={
                            selectedItem[0] === 'channel' ? selectedItem[1] : null
                        }
                        onAction={onChannelsAction}
                    />
                    <ProjectCreateChannelPopover.Panel
                        project={project}
                        onAction={onChannelsAction}
                    />
                    <Property label={app.text('channels')}>
                        {Channels}
                        <Item
                            onClick={e => {
                                setSelectedItem(['channel', null])
                                ProjectCreateChannelPopover.onShow(e)
                            }}
                        >
                            <Icon className="itemtype" name="plus" size="text" />
                            <span className="cc-dimmed">
                                {app.text('Add a channel')}
                            </span>
                        </Item>
                    </Property>
                </VView>
                <Spacer size={20} />
                <VView grow>
                    <ProjectOrderingsContextMenu.Panel
                        project={project}
                        orderingkey={
                            selectedItem[0] === 'ordering' ? selectedItem[1] : null
                        }
                        onAction={onOrderingsAction}
                    />
                    <ProjectCreateOrderingPopover.Panel
                        project={project}
                        onAction={onOrderingsAction}
                    />
                    <Property label={app.text('orderings')}>
                        {Orderings}
                        <Item
                            onClick={e => {
                                setSelectedItem(['ordering', null])
                                ProjectCreateOrderingPopover.onShow(e)
                            }}
                        >
                            <Icon className="itemtype" name="plus" size="text" />
                            <span className="cc-dimmed">
                                {app.text('Add an ordering')}
                            </span>
                        </Item>
                    </Property>
                    <ProjectFieldlistsContextMenu.Panel
                        project={project}
                        fieldlistkey={
                            selectedItem[0] === 'fieldlist' ? selectedItem[1] : null
                        }
                        onAction={onFieldlistsAction}
                    />
                    <ProjectCreateFieldlistPopover.Panel
                        project={project}
                        onAction={onFieldlistsAction}
                    />
                    <Property label={app.text('fieldlists')}>
                        {Fieldlists}
                        <Item
                            onClick={e => {
                                setSelectedItem(['fieldlist', null])
                                ProjectCreateFieldlistPopover.onShow(e)
                            }}
                        >
                            <Icon className="itemtype" name="plus" size="text" />
                            <span className="cc-dimmed">
                                {app.text('Add a fieldlist')}
                            </span>
                        </Item>
                    </Property>
                </VView>
            </HView>
        </VView>
    )
})

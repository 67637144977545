//
// useFileUpload
//
// use the FileUpload component
//
// Usage:
//
//     const FileUpload = useFileUpload()
//
//     const onFileChange = file => {
//         console.log(file)
//     }
//
//     return (
//         <VView style={{padding: 20}}>
//             <FileUpload.Input onFileChange={onFileChange}/>
//             <Bar raised>
//                 <Button icon onClick={() => fileUpload.openFileDialog()}>
//                     <Icon name="folder" size={2}/>
//                 </Button>
//             </Bar>
//         </VView>
//     )

import React, { useRef } from 'react'
import { observer } from 'mobx-react-lite'

import { FileUpload } from '../components'

export const useFileUpload = onFileChange => {
    const ref = useRef(null)

    const openFileDialog = () => {
        ref.current && ref.current.click()
    }

    const Input = observer(function Input(props) {
        return <FileUpload ref={ref} onFileChange={onFileChange} {...props} />
    })

    return {
        Input,
        openFileDialog,
    }
}

//
// LayoutStore
//
// The LayoutStore handles interaction with the Layout Tree
//
// The exposed store is the actual TreeStore (.tree), but that is a generic element
// and the PimLayoutStore handles storing view choices like expandedIds and such, and
// fetching data based on that and the current, possibly changeable, environment.

import { makeObservable, observable, computed, action } from 'mobx'
import { gid } from '../../utils/gid'

import { TreeStore, TreeStoreItem } from '../components/TreeStore'
import { LAYOUTCOMPONENTTYPECONFIG } from '../data/LAYOUTCOMPONENTTYPECONFIG'

export class LayoutStore {
    __init = false
    layout = null
    tree = null

    constructor(layout, rootstore) {
        makeObservable(this, {
            __init: observable,
            layout: observable,
            tree: observable,
            setLayout: action.bound,

            selectedComponentGid: computed,
            selectComponent: action.bound,
            select: action.bound,
            fetch: action.bound,
            refetch: action.bound,
            _fetch: action.bound,
            updateComponentInMemory: action.bound,
        })

        this._rootstore = rootstore
        this.layout = layout
        this.tree = new TreeStore(this)
    }

    setLayout(layout) {
        if (!this.layout || !layout || layout.gid !== this.layout.gid) {
            if (
                this.layout &&
                layout &&
                layout.gid !== this.layout.gid &&
                layout.gid_map.size
            ) {
                const mappedIds = this.tree._expandedIds.map(id =>
                    layout.gid_map.has(id) ? layout.gid_map.get(id) : id
                )
                const selectedGid = this.selectedComponentGid
                const mappedSelectedGid = layout.gid_map.has(selectedGid)
                    ? layout.gid_map.get(selectedGid)
                    : selectedGid
                this.layout = layout
                this._fetch([layout.gid, ...mappedIds]).then(result => {
                    this.selectComponent(mappedSelectedGid)
                })
            } else {
                this.layout = layout
                this.reset()
            }
        }
    }

    reset() {
        // start with an open tree, except for sublayouts
        if (this.layout) {
            this._fetch([this.layout.gid, ...this.layout.components.keys()]) // ... makes sure there's always an array, even an empty one
        } else {
            this._fetch([])
        }
    }

    get selectedComponentGid() {
        return this.tree.selectedItem ? this.tree.selectedItem.item.gid : null
    }

    selectComponent(gid) {
        this.tree.setSelectedId(gid)
    }

    deselect() {}

    select(treeitem) {}

    loadOnce = () => {
        if (this.__init) return
        if (this.layout) {
            this._fetch([this.layout.gid, ...this.layout.components.keys()])
        }
        this.__init = true
    }

    fetch(parentIds) {
        return this._fetch(parentIds)
    }

    refetch() {
        return this._fetch(this.tree._expandedIds)
    }

    cleanup_layout(layout) {
        let used_components = new Map()
        for (const [, component] of this._treewalker(
            layout,
            undefined,
            undefined,
            undefined,
            false
        )) {
            if (component.gid === layout.gid) continue
            used_components.set(component.gid, component)
        }
        layout.components.replace(used_components)
        return layout
    }

    *_treewalker(
        layout,
        component,
        parentgid,
        level,
        include_sublayouts,
        visited_gids
    ) {
        if (visited_gids === undefined) visited_gids = []
        if (include_sublayouts === undefined) include_sublayouts = true
        if (!layout) {
            return
        }
        if (!level) {
            level = 0
        }
        if (!component) {
            if (visited_gids.includes(layout.gid)) {
                yield [
                    level,
                    {
                        gid: gid(),
                        parentgid: parentgid,
                        type: 'layout',
                        name: layout.name,
                        parent: null,
                        isContainer: false,
                        isComponentContainer: false,
                        isRecursive: true,
                        recursiveComponent: layout,
                        has_modified: () => false,
                    },
                ]
                return // avoid rest of recursion
            }
            visited_gids.push(layout.gid)
            visited_gids.push(layout.original)
            layout.type = 'root'
            layout.parentgid = parentgid
            layout.parent = null
            layout.isContainer = false
            layout.isComponentContainer = true
            yield [level, layout]
            level += 1
            component = layout.components.get(layout.root)
            if (component) {
                component.gid = layout.root
                component.parentgid = layout.gid
                component.parent = layout
                component.isContainer = [
                    'vertical',
                    'horizontal',
                    'table',
                    'tiles',
                    'rows',
                ].includes(component.type)
                component.isComponentContainer = ['root', 'layout'].includes(
                    component.type
                )
            }
        }
        if (!component) return
        if (visited_gids.includes(component.gid)) {
            yield [
                level,
                {
                    gid: gid(),
                    parentgid: component.parentgid,
                    type: 'root',
                    name: component.name,
                    parent: null,
                    isContainer: false,
                    isComponentContainer: false,
                    isRecursive: true,
                    recursiveComponent: component,
                    has_modified: () => false,
                },
            ]
            return // avoid rest of recursion
        }
        visited_gids.push(component.gid)
        visited_gids.push(component.original)
        yield [level, component]
        if ('content' in component && component.content && component.content.length) {
            for (let i = 0; i < component.content.length; ++i) {
                const childgid = component.content[i]
                const child = layout.components.get(childgid)
                child.gid = childgid
                child.parentgid = component.gid
                child.parent = component
                child.isContainer = [
                    'vertical',
                    'horizontal',
                    'table',
                    'tiles',
                    'rows',
                ].includes(child.type)
                child.isComponentContainer = ['root', 'layout'].includes(child.type)
                yield* this._treewalker(
                    layout,
                    child,
                    component.gid,
                    level + 1,
                    include_sublayouts,
                    visited_gids
                )
            }
        }
        if (include_sublayouts && 'layout' in component && component.layout) {
            yield* this._treewalker(
                this._rootstore.data.layouts.get(component.layout),
                null,
                component.gid,
                level,
                include_sublayouts,
                visited_gids
            )
        }
    }

    _treeitems(expandedIds) {
        let expandmax = 999
        let treeitems = []

        let level, component
        for ([level, component] of this._treewalker(this.layout)) {
            if (level > expandmax) continue
            if (!expandedIds.includes(component.gid)) {
                expandmax = level
            } else {
                expandmax = level + 1
            }
            let childcount =
                component.type === 'root' || component.type === 'layout'
                    ? 1
                    : component.content
                    ? component.content.length
                    : 0
            if (component.type === 'root' && !component.root) {
                childcount = 0
            }
            if (component.type === 'layout' && !component.layout) {
                childcount = 0
            }
            treeitems.push(
                new TreeStoreItem(
                    component.gid,
                    component.parentgid,
                    level,
                    childcount,
                    component
                )
            )
        }
        return treeitems
    }

    _fetch(expandedIds) {
        if (!this.layout) {
            const faketreeitems = [
                new TreeStoreItem('definition_layout', null, 0, 0, {
                    gid: 'definition_layout',
                    type: 'layout',
                    layout: null,
                }),
            ]
            this.tree._expandedIds.replace([])
            this.tree.setTreeItems(faketreeitems, 0) // don't care about totalsize
            return Promise.resolve(faketreeitems)
        }
        const treeitems = this._treeitems(expandedIds)
        this.tree._expandedIds.replace(expandedIds)
        this.tree.setTreeItems(treeitems, 0) // don't care about totalsize
        return Promise.resolve(treeitems)
    }

    getLayoutGids() {
        if (!this.layout) return []
        let layoutgids = []
        for (let [, component] of this._treewalker(this.layout)) {
            if (component.type === 'root') {
                layoutgids.push(component.gid)
                if (component.original) {
                    layoutgids.push(component.original)
                }
            }
        }
        return layoutgids
    }

    getComponent(componentgid) {
        if (!componentgid) return null
        if (!this.layout) return null
        for (let [, component] of this._treewalker(this.layout)) {
            if (component.gid === componentgid) {
                return component
            }
        }
        return null
    }

    getComponentParent(componentgid) {
        if (!componentgid) return null
        if (!this.layout) return null
        for (let [, component] of this._treewalker(this.layout)) {
            if (component.gid === componentgid) {
                return this.getComponent(component.parentgid)
            }
        }
        return null
    }

    getComponentLayout(componentgid) {
        if (!componentgid) return null
        if (!this.layout) return null
        let layoutstack = []
        let layoutdepth = 0
        for (let [level, component] of this._treewalker(this.layout)) {
            if (component.type === 'root') {
                layoutstack.push([level, component])
                layoutdepth = level
                if (component.gid === componentgid) {
                    return layoutstack[layoutstack.length - 1][1]
                }
                continue
            }
            while (level <= layoutdepth && layoutstack.length) {
                layoutstack.pop()
                layoutdepth = layoutstack.length
                    ? layoutstack[layoutstack.length - 1][0]
                    : 0
            }
            if (component.gid === componentgid) {
                return layoutstack[layoutstack.length - 1][1]
            }
        }
        return null
    }

    getComponentSubtreeGids(componentgid) {
        // get the complete subtree, but exclude sublayouts
        if (!componentgid) return null
        if (!this.layout) return null
        let subtreegids = []
        let subtree_level = 0
        let sublayout_level = 0
        for (let [level, component] of this._treewalker(this.layout)) {
            if (component.gid === componentgid) {
                subtree_level = level
                subtreegids.push(component.gid)
                continue
            }
            if (subtree_level) {
                if (level <= subtree_level) {
                    return subtreegids
                }
                if (component.type === 'root') {
                    sublayout_level = level + 1
                }
                if (level <= sublayout_level) {
                    sublayout_level = 0
                }
                if (!sublayout_level) {
                    subtreegids.push(component.gid)
                }
            }
        }
        return subtreegids
    }

    newComponent(type, options) {
        if (!options) options = {}
        switch (type) {
            // layout
            case 'vertical':
                options['content'] = []
                break
            case 'horizontal':
                options['content'] = []
                break
            case 'spacer':
                break
            // data
            case 'field':
                if (!('field' in options)) {
                    options['field'] = '<not set>'
                }
                break
            case 'fieldlist':
                // there's only one
                break
            case 'definition':
                // always the original definition
                break
            case 'class':
                if (!('class' in options)) {
                    options['class'] = '<not set>'
                }
                break
            case 'autoextend':
                break
            case 'autogenerate':
                break
            // children
            case 'table':
                options['content'] = []
                break
            case 'rows':
                options['content'] = []
                break
            case 'tiles':
                options['columns'] = 4
                options['content'] = []
                break
            // static
            case 'image':
                break
            case 'text':
                break
            case 'divider':
                break
            default:
                return null
        }
        return { type: type, options: options }
    }

    updateComponentInMemory(component_gid, property_name, property_value) {
        const component = this.getComponent(component_gid)
        if (!component) return null
        const component_config = LAYOUTCOMPONENTTYPECONFIG[component.type]
        let property_config = null
        if (property_name in component_config) {
            property_config = component_config[property_name]
        } else if ('typeconfig' in component_config) {
            const field = this._rootstore.data.fields.get(component.field)
            if (field.type in component_config.typeconfig) {
                const field_config = component_config.typeconfig[field.type]
                if (property_name in field_config) {
                    property_config = field_config[property_name]
                }
            }
        }
        if (!property_config) return null
        if (property_config.type === 'localtext') {
            if (!component[property_name]) {
                component[property_name] = {}
            }
            for (let language in property_value) {
                component[property_name][language] = property_value[language]
            }
        } else {
            component[property_name] = property_value
        }
        return component
    }

    getItemTitle(item, language) {
        if (!item) return ''
        let title = item.label ? item.label.get(language) : item.name
        if (!title.length) title = item.name
        if (!title.length) title = this._rootstore.app.text('[empty]')
        if (item.unit && item.unit.length) {
            title += ' [' + item.unit + ']'
        }
        return title
    }
}

//
// ClassInput
//
// An input for a class.

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'
import { useTooltipPopover } from '../hooks/useTooltipPopover'

import { SchemaItem, SelectClassPanel } from '../panels'

export const ClassInput = observer(function ClassInput({
    className,
    onSelect,
    value,
    ...other
}) {
    const { data } = useStore()

    const class_ = data.classes.get(value)

    let classes = 'cc-ClassInput'
    if (className) classes += ' ' + className

    const ClassSearchPopover = useTooltipPopover(SelectClassPanel, {
        preferredPosition: 'left',
    })

    const onSelectClass = class_gid => {
        ClassSearchPopover.hide()
        return onSelect && onSelect(class_gid)
    }

    return (
        <div className={classes} {...other}>
            <ClassSearchPopover.Panel selected={value} onSelectClass={onSelectClass} />
            <div ref={ClassSearchPopover.anchorRef}>
                <SchemaItem
                    item={class_}
                    iconplaceholder="class"
                    onClick={ClassSearchPopover.onShow}
                    onContextMenu={ClassSearchPopover.onShow}
                />
            </div>
        </div>
    )
})

//
// TreeRecordMenu
//
// Actions on records in the TreeNavigator
// Only feed it not-deleted records that are not links.

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Menu, MenuItem } from '../components'

export const TreeRecordWithLinksSubmenu = observer(function TreeRecordWithLinksSubmenu({
    className,
    record,
    onConfirm,
}) {
    const { app } = useStore()

    if (!record) return null

    return (
        <Menu>
            <MenuItem className={className} onClick={() => onConfirm && onConfirm()}>
                {app.pluralized_text(
                    {
                        0: 'No linked records',
                        1: 'Includes {count} linked record',
                        many: 'Includes {count} linked records',
                    },
                    record.backlinks.length,
                    {
                        count: record.backlinks.length,
                    }
                )}
            </MenuItem>
        </Menu>
    )
})

//
// RecordCard
//
// A simple record card, preview, name and some properties

import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'
import { Transform } from '../stores/imageservers/utils'

import { VView } from '../appview'
import { Property, Text } from '../components'
import { RecordThumb, IconTile } from '.'

export const RecordCard = observer(function RecordCard({
    record,
    className,
    transform,
    iconplaceholder,
    ...other
}) {
    const { app, data } = useStore()

    let classes = 'cc-RecordCard'
    if (className) classes += ' ' + className

    const assetTransform = transform || new Transform('fit', 200, 200)

    const [path, setPath] = useState(null)
    useEffect(() => {
        if (!record) return
        let isMounted = true
        let controller = new AbortController()
        async function asyncEffect() {
            const missingGids = record.path.filter(gid => !data.records.has(gid))
            if (missingGids.length) {
                await data.fetchRecordsByGid(missingGids, { signal: controller.signal })
            }
            const pathRecords = record.path
                .map(gid => data.records.get(gid))
                .filter(item => item !== null)
            if (isMounted) {
                setPath(pathRecords)
            }
        }
        asyncEffect()
        return () => {
            controller.abort('Unmounted')
            isMounted = false
            return isMounted
        }
    }, [record, data])

    if (!record) {
        return (
            <IconTile
                className={classes + ' cc-dimmed'}
                width={assetTransform.width}
                icon={iconplaceholder ? iconplaceholder : 'plus'}
                {...other}
            />
        )
    }

    const title =
        record.localized_title && record.localized_title.length
            ? record.localized_title
            : app.text('[empty]')
    const definition = data.definitions.get(record.definition)

    let Pathlist
    if (path && path.length > 2) {
        const pathRecordsInOrder = record.path
            .slice(1, -1)
            .map(gid => path.find(pathRecord => gid === pathRecord.gid))
        pathRecordsInOrder.reverse()
        const PathItems = pathRecordsInOrder.map(pathRecord => {
            return (
                <div key={pathRecord.gid}>
                    {pathRecord.localized_title && pathRecord.localized_title.length
                        ? pathRecord.localized_title
                        : app.text('[empty]')}
                </div>
            )
        })
        Pathlist = (
            <Property label={app.text('path')}>
                <Text>{PathItems}</Text>
            </Property>
        )
    }

    return (
        <VView className={classes} {...other}>
            <RecordThumb
                record={record}
                iconplaceholder={iconplaceholder}
                transform={assetTransform}
            />
            <Property label={app.text('title')}>
                <Text>{title}</Text>
            </Property>
            <Property label={app.text('definition')}>
                <Text>{definition && definition.name}</Text>
            </Property>
            {Pathlist}
        </VView>
    )
})

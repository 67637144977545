import React from 'react'

import Store from './Store'

const StoreContext = React.createContext({})
export const createStore = () => {
    const store = new Store()
    window.setInterval(() => store.tick(), 1000)
    window.store = store // for debug purposes
    return store
}
export const useStore = () => React.useContext(StoreContext)
export const StoreProvider = StoreContext.Provider

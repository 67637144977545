//
// Toggle
//
// A yes/no toggle

import React from 'react'

export const Toggle = props => {
    const { className, onChange, value, ...other } = props
    let classes = 'cc-Toggle'
    if (className) classes += ' ' + className
    return (
        <label
            className={classes}
            {...other}
            tabIndex="0"
            onKeyDown={() => {}}
            onClick={event => {
                event.stopPropagation()
                event.nativeEvent.stopImmediatePropagation()
            }}
        >
            <input
                type="checkbox"
                tabIndex="-1"
                checked={value ? 'checked' : ''}
                onChange={() => onChange && onChange(!value)}
            />
            <span className="cc-Toggle-Knob"></span>
        </label>
    )
}

//
// RecordsContextmenu
//
// Actions on records in the TreeNavigator or QueryNavigator
//
// The contextmenu is always triggered on a record that is in the selection.

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Menu, MenuDivider, MenuItem, MenuText } from '../components'

import {
    MultiselectionTypes,
    determineRecordsMultiselectionType,
    multiselectionHasDeletedRecords,
} from '../utils/multiselection'

const DeletedRecordsSubmenu = observer(function DeletedRecordsSubmenu({
    record,
    onAction,
}) {
    const { app } = useStore()

    const _onAction = action => {
        onAction && onAction(action)
    }

    return (
        <Menu>
            <MenuItem onClick={() => _onAction('undelete_records_selection')}>
                {app.text('Undelete')}
            </MenuItem>
            <MenuDivider />
            <MenuItem
                className="cc-danger"
                onClick={() => _onAction('permanent_delete_records_selection')}
            >
                {app.text('Permanent delete')}
            </MenuItem>
        </Menu>
    )
})

export const RecordsContextmenu = observer(function RecordsContextmenu({
    record,
    recordsselection,
    onAction,
}) {
    const { app, data } = useStore()

    const selectiontype = determineRecordsMultiselectionType(recordsselection, data)
    const isNested = selectiontype === MultiselectionTypes.NESTED

    const _onAction = action => {
        onAction && onAction(action)
    }

    const empty = recordsselection.size === 0
    const singleselect = recordsselection.size === 1

    // can't create folder on multiselect
    // in fact, a different contextmenu is used when a single item is selected
    const disableCreateNewChild = true

    // can duplicate multiple items (skips root && deleted ones)
    // can't duplicate root on single selection
    const disableDelete = empty || (singleselect && record.is_root) || isNested

    // can delete multiple items (skips root && deleted ones)
    // can't delete root on single selection
    const disableDuplicate = empty || (singleselect && record.is_root) || isNested

    // can undelete/permanent delete multiple items (skips root && not-deleted ones)
    // can't work on non-deleted record on single selection
    const disableDeletedSubmenu =
        empty ||
        (singleselect && record.status !== 'deleted') ||
        isNested ||
        !multiselectionHasDeletedRecords(recordsselection, data)

    return (
        <>
            <Menu>
                <MenuItem disabled={disableCreateNewChild}>
                    {app.text('New child')}
                </MenuItem>
                <MenuItem
                    disabled={disableDuplicate}
                    onClick={() => _onAction('duplicate_records_selection')}
                >
                    {app.text('Duplicate records')}
                </MenuItem>
                <MenuDivider />
                <MenuItem
                    disabled={disableDelete}
                    onClick={() => _onAction('delete_records_selection')}
                >
                    {app.text('Delete')}
                </MenuItem>
                <MenuDivider />
                <MenuItem
                    disabled={disableDeletedSubmenu}
                    submenu={
                        <DeletedRecordsSubmenu
                            record={record}
                            recordsselection={recordsselection}
                            onAction={onAction}
                        />
                    }
                >
                    {app.text('Deleted records')}
                </MenuItem>
                {isNested ? (
                    <>
                        <MenuDivider />
                        <MenuText>
                            {app.text('No actions available for nested selections.')}
                        </MenuText>
                    </>
                ) : undefined}
            </Menu>
        </>
    )
})

//
// keys
//
// key constants, os-specific

export const keyboard = {
    BACKSPACE: 8,
    TAB: 9,
    ENTER: 13,
    // RETURN: 13, // no, just one way to name things
    SHIFT: 16,
    CONTROL: 17,
    ALT: 18,
    ESC: 27,
    SPACE: 32,
    PAGEUP: 33,
    PAGEDOWN: 34,
    END: 35,
    HOME: 36,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
    DEL: 40, // huh? and DOWN?
    COMMAND_LEFT: 91,
    WINDOWS_LEFT: 91,
    WINDOWS_RIGHT: 92,
    COMMAND_RIGHT: 93,
    SELECT: 93, // what's this?
    F1: 112,
    F2: 113,
    F3: 114,
    F4: 115,
    F5: 116,
    F6: 117,
    F7: 118,
    F8: 119,
    F9: 120,
    F10: 121,
    F11: 122,
    F12: 123,
    _: 189,
    test: (event, testKeycode) => {
        const keycode = event.keyCode || event.which
        return keycode === testKeycode
    },
    testCommand: event => {
        // on windows: ctrl-someykey/click, on macos: cmd-someykey/click
        return event.ctrlKey || event.metaKey
    },
    testShift: event => {
        return event.shiftKey
    },
    testAlt: event => {
        return event.altKey
    },
}

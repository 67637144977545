//
// useDebounce()
//
// Used if a user types input and you only want to fire off a network request
// once the typing stops for a little while.
//
//    const [searchtext, setSearchtext] = useState("")
//    const debouncedSearchtext = useDebounce(searchtext, 300);

import { useState, useEffect } from 'react'

export const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value)

    useEffect(() => {
        const handler = setTimeout(() => setDebouncedValue(value), delay)
        return () => clearTimeout(handler)
    }, [value, delay])

    return debouncedValue
}

//
// JWT
//
// Handle the JSON Web Token

import { makeObservable, observable, computed, action } from 'mobx'

function parseJWT(token) {
    try {
        var base64Url = token.split('.')[1]
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        var jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                })
                .join('')
        )

        return JSON.parse(jsonPayload)
    } catch {
        return null
    }
}

class JWT {
    _storageKey
    _token = null
    _data = null

    constructor(storageKey) {
        makeObservable(this, {
            _token: observable,
            token: computed,
            hasToken: computed,
            setToken: action.bound,
            invalidateToken: action.bound,
            _data: observable,
            data: computed,
        })

        this._storageKey = storageKey
        const storedjwt = window.localStorage.getItem(this._storageKey)
        try {
            this._token = storedjwt !== null ? JSON.parse(storedjwt) : null
        } catch (error) {
            this._token = null
        }
    }

    get token() {
        return this._token
    }

    get hasToken() {
        return this._token !== null
    }

    setToken = newToken => {
        this._token = newToken
        window.localStorage.setItem(this._storageKey, JSON.stringify(newToken))
        this._data = newToken ? parseJWT(newToken) : null
    }

    invalidateToken = () => {
        this.setToken(null)
    }

    get data() {
        return this._data
    }
}

export default JWT

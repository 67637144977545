//
// InviteeProjectMenu
//
// Actions on projects in the project manager that you are invited to.
// Only feed it not-deleted projects.

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Menu, MenuItem } from '../components'

export const InviteeProjectMenu = observer(function InviteeProjectMenu({
    projectgid,
    onAction,
}) {
    const { app } = useStore()

    const _onAction = action => {
        onAction && onAction(projectgid, action)
    }

    return (
        <Menu>
            <MenuItem onClick={() => _onAction('accept_invite')}>
                {app.text('Accept invitation')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('decline_invite')}>
                {app.text('Decline invitation')}
            </MenuItem>
        </Menu>
    )
})

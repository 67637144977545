//
// ProjectMenu
//
// Actions on projects in the project manager
// Only feed it not-deleted projects.

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Menu, MenuDivider, MenuItem } from '../components'

export const ProjectMenu = observer(function ProjectMenu({ projectgid, onAction }) {
    const store = useStore()
    const { app, _testdatabase_gid } = store

    const _onAction = action => {
        onAction && onAction(projectgid, action)
    }

    // can't delete test project
    const disableDelete =
        !store.can('project.delete') || projectgid === _testdatabase_gid

    return (
        <Menu>
            <MenuItem onClick={() => _onAction('open_project')}>
                {app.text('Go to project')}
            </MenuItem>
            <MenuDivider />
            <MenuItem disabled={disableDelete} onClick={() => _onAction('delete')}>
                {app.text('Delete')}
            </MenuItem>
        </Menu>
    )
})

//
// FolderInput
//
// An asset-input for a folder.

import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'
import { useTooltipPopover } from '../hooks/useTooltipPopover'

import { VView } from '../appview'
import { Menu, MenuItem } from '../components'
import { FolderItem, FolderSearchPanel } from '../panels'

const FolderSearchPanelMenu = observer(function FolderSearchPanelMenu({
    folder,
    onSelect,
    onCancel,
}) {
    const { app } = useStore()

    const searchpanelstyle = { display: 'flex', width: 240, minHeight: 500 }
    const menustyle = { width: 240, maxWidth: 240 }

    return (
        <VView className="folder-search-panel-menu" style={searchpanelstyle}>
            <FolderSearchPanel
                className="inspector"
                folder={folder}
                onSelect={onSelect}
            />
            <Menu style={menustyle}>
                <MenuItem onClick={() => onCancel && onCancel()}>
                    {app.text('Cancel')}
                </MenuItem>
            </Menu>
        </VView>
    )
})

export const FolderInput = observer(function FolderInput({
    className,
    onSelect,
    value,
    ...other
}) {
    const { data } = useStore()

    let classes = 'cc-FolderInput'
    if (className) classes += ' ' + className

    const FolderSearchPopover = useTooltipPopover(FolderSearchPanelMenu, {
        preferredPosition: 'left',
    })

    const [folder, setFolder] = useState(null)
    useEffect(() => {
        let isMounted = true
        let controller = new AbortController()
        async function asyncEffect() {
            setFolder(null)
            if (value) {
                if (!data.assets.has(value)) {
                    await data.fetchAssetByGid(value, {
                        signal: controller.signal,
                    })
                }
                const asyncfolder = data.assets.get(value)
                if (isMounted) {
                    setFolder(asyncfolder)
                }
            }
        }
        asyncEffect()
        return () => {
            controller.abort('Unmounted')
            isMounted = false
            return isMounted
        }
    }, [value, data])

    const onSelectFolder = folder_gid => {
        FolderSearchPopover.hide()
        return onSelect && onSelect(folder_gid)
    }

    const onCancelFolder = () => {
        FolderSearchPopover.hide()
    }

    const onShowFolderSearchPopover = e => {
        FolderSearchPopover.onShow(e)
    }

    return (
        <div className={classes} {...other}>
            <FolderSearchPopover.Panel
                folder={folder}
                onSelect={onSelectFolder}
                onCancel={onCancelFolder}
            />
            <div ref={FolderSearchPopover.anchorRef}>
                <FolderItem
                    folder={folder}
                    iconplaceholder="folder"
                    onClick={onShowFolderSearchPopover}
                    onContextMenu={onShowFolderSearchPopover}
                />
            </div>
        </div>
    )
})

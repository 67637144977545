//
// DamWorkspace
//
// Manage Digital Assets, we use a navigator/worksheet/inspector setup.

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useDrop } from 'react-dnd'
import { useStore } from '../stores'
import { usePersistentState } from '../hooks/usePersistentState'

import { DragTypes } from '../dragdrop/DragTypes'

import { VView, HView, Spacer, Divider, VScrollView } from '../appview'
import { WorkspaceView } from './WorkspaceView'
import { Button, Icon } from '../components'
import { EnvironmentPanel, AssetPinboardPanel, AssetSearchPanel } from '../panels'
import { TreeNavigator, AssetWorksheet, AssetInspector } from './dam'
import { maxChars } from '../utils/text'

export const DamWorkspace = observer(function DamWorkspace({
    user,
    project,
    viewkey,
    grow,
    ...props
}) {
    const { app, data, view } = useStore()

    const sep = (
        <span key="_" className="breadcrumbs-separator">
            {app.text('›')}
        </span>
    )

    const tree = view.damtree
    const worksheet = view.damworksheet
    const damquery = view.damquery

    const [pinboardVisible, setPinboardVisible] = usePersistentState(
        viewkey + '/pinboardVisible',
        true
    )

    const [navigatorTab, setNavigatorTab] = usePersistentState(
        viewkey + '/navigatorTab',
        'tree'
    )

    let NavigationToolbar
    NavigationToolbar = (
        <>
            <Button
                icon
                active={navigatorTab === 'tree' ? true : false}
                onClick={() => setNavigatorTab('tree')}
                title={app.text('Tree')}
            >
                <Icon name="tree" size={1} />
            </Button>
            <Button
                icon
                active={navigatorTab === 'search' ? true : false}
                onClick={() => setNavigatorTab('search')}
                title={app.text('Search')}
            >
                <Icon name="search" size={1} />
            </Button>
        </>
    )

    const onSelect = assetgid => {
        const selectedAsset = data.assets.get(assetgid)
        if (selectedAsset) {
            worksheet.setAsset(selectedAsset)
        }
    }

    let Navigator
    switch (navigatorTab) {
        case 'tree':
            Navigator = (
                <TreeNavigator
                    tree={tree}
                    firstindex={view._damtree.topindex}
                    windowsize={view._damtree.windowsize}
                />
            )
            break
        case 'search':
            Navigator = (
                <AssetSearchPanel
                    querystore={damquery}
                    preview={false}
                    showMatches={true}
                    assetactions={true}
                    multiselect={true}
                    onSelect={onSelect}
                    selectedAssetGid={worksheet.asset ? worksheet.asset.gid : null}
                    style={{ marginTop: 5 }}
                />
            )
            break
        default:
            Navigator = <VView grow />
            break
    }

    let WorksheetToolbar
    if (worksheet.path.length) {
        WorksheetToolbar = worksheet.path
            .map(pathasset => {
                const name = pathasset.publicname
                    ? pathasset.publicname
                    : app.text('[empty]')
                return (
                    <span
                        key={pathasset.gid}
                        onClick={() => worksheet.setAsset(pathasset)}
                    >
                        {maxChars(name, 30)}
                    </span>
                )
            })
            .reduce((prev, curr) => [prev, sep, curr])
    }

    let InspectorToolbar
    if (worksheet.asset) {
        const name = worksheet.asset.publicname
            ? worksheet.asset.publicname
            : app.text('[empty]')
        InspectorToolbar = <span>{maxChars(name, 30)}</span>
    }

    const [
        { isOver: isOverPinboardToggle, canDrop: canDropPinboardToggle },
        dropPinboardToggle,
    ] = useDrop({
        accept: [DragTypes.DAM_ASSET, DragTypes.ASSET_SELECTION],
        canDrop: () => view.dampinboard.canAdd(),
        collect: monitor => {
            return {
                isOver: !!monitor.isOver(),
                canDrop: !!monitor.canDrop(),
            }
        },

        drop: item => {
            if (item.type === DragTypes.DAM_ASSET) {
                view.dampinboard.addAsset(item.gid)
            } else if (item.type === DragTypes.ASSET_SELECTION) {
                if (!view.dampinboard.exists(item.gid)) {
                    view.dampinboard.addSelection(
                        item.selection,
                        item.selectionsize,
                        item.isFlat
                    )
                }
            }
            setPinboardVisible(true)
            return { receiver: 'PinboardToggle', item: item }
        },
    })

    const Pinboard = pinboardVisible ? (
        <>
            <Divider />
            <AssetPinboardPanel
                pinboard={view.dampinboard}
                selectedAsset={worksheet.asset}
            />
        </>
    ) : undefined

    let classesPinboardToggle = 'pinboardtoggle-drop'
    if (isOverPinboardToggle) {
        classesPinboardToggle += ' drag-over'
        if (!canDropPinboardToggle) classesPinboardToggle += ' drop-rejected'
    }
    if (!pinboardVisible && !view.dampinboard.canAdd()) {
        classesPinboardToggle += ' pinboard-full'
    }

    const PinboardToggle = (
        <div className={classesPinboardToggle} ref={dropPinboardToggle}>
            <Button
                icon
                active={pinboardVisible}
                title={app.text(
                    pinboardVisible ? 'Hide pinboard panel' : 'Show pinboard panel'
                )}
                onClick={() => setPinboardVisible(!pinboardVisible)}
            >
                <Icon name="image" size={1} />
                <Icon name="pin" size={1} />
            </Button>
        </div>
    )

    const NavigatorView = (
        <>
            <HView className="toolbar">{NavigationToolbar}</HView>
            <Divider />
            {Navigator}
            {Pinboard}
        </>
    )
    const WorksheetView = (
        <>
            <div className="toolbar">{WorksheetToolbar}</div>
            <Divider />
            <AssetWorksheet worksheet={worksheet} />
        </>
    )
    const InspectorView = (
        <>
            <div className="toolbar">{InspectorToolbar}</div>
            <Divider />
            <VScrollView>
                <AssetInspector worksheet={worksheet} />
            </VScrollView>
        </>
    )
    const StatusbarView = (
        <>
            {PinboardToggle}
            <Spacer size={15} />
            <EnvironmentPanel
                project={project}
                languageOnly
                environment={view.environment}
                updateEnvironment={view.updateEnvironment}
            />
        </>
    )

    return (
        <WorkspaceView
            grow={grow}
            className="workspace-dam"
            viewkey={viewkey}
            navigator={NavigatorView}
            worksheet={WorksheetView}
            inspector={InspectorView}
            statusbar={StatusbarView}
        />
    )
})

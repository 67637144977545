//
// MenuHeader
//
// A MenuHeader can be used in a menu and is a wrapper for Header.

import React from 'react'
import { Header } from './Header'

export const MenuHeader = React.forwardRef(({ children, ...other }, ref) => {
    return (
        <div className="cc-MenuHeader" ref={ref}>
            <Header {...other}>{children}</Header>
        </div>
    )
})

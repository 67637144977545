//
// DividerLayoutBuilder
//
// Based on view info from a layout

import React from 'react'
import { observer } from 'mobx-react-lite'

import { Divider } from '../../../../appview'

export const DividerLayoutBuilder = observer(function DividerLayoutBuilder({
    className,
    component,
    components,
    layout,
    record,
    worksheet,
    active_class,
    visited_gids,
}) {
    let attributes = { className: 'layout-component ws-margin-bottom' }
    if (className) attributes['className'] += ' ' + className
    if (component.style) attributes['className'] += ' lc-' + component.style
    if (component.gid === worksheet.layoutstore.selectedComponentGid) {
        attributes['className'] += ' cc-selected'
    }
    // attributes["onClick"] = e => onClickElement(e, component, layouttreestore)
    return <Divider {...attributes} />
})

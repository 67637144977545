//
// FolderSearchPanel
//
// A search box, and results.
// Results can be selected.

import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'
import { useDebounce } from '../hooks/useDebounce'

import { HView, VView, View, LazyVScrollList, LazyScrollItem } from '../appview'
import { SearchInput, Item, Text } from '../components'
import { FolderItem } from '../panels'

const SearchResultItem = observer(function SearchResultItem({
    folder,
    selected,
    onClick,
    ...other
}) {
    return (
        <Item
            selected={selected}
            indent={0}
            className={''}
            onClick={onClick}
            {...other}
        >
            <FolderItem folder={folder} />
        </Item>
    )
})

export const FolderSearchPanel = observer(function FolderSearchPanel({
    className,
    onSelect,
    folder,
    viewkey,
    ...other
}) {
    const { app, data, view } = useStore()
    const damfoldersearch = view.damfoldersearch

    let classes = 'panel folder-search-panel'
    if (className) classes += ' ' + className

    const itemheight = 36

    const onUpdate = (newtopindex, newwindowsize) => {
        if (
            newtopindex !== damfoldersearch.topindex ||
            newwindowsize !== damfoldersearch.windowsize
        ) {
            damfoldersearch.fetch(searchtext, newtopindex, newwindowsize)
        }
    }

    const [searchtext, setSearchtext] = useState(damfoldersearch.q)
    const debouncedSearchtext = useDebounce(searchtext, 300)

    const [selectedGid, setSelectedGid] = useState(null)

    const onSearchinputChange = event => {
        setSearchtext(event.target.value)
    }
    const onSearchinputBlur = event => {
        setSearchtext(event.target.value.trim())
    }

    useEffect(() => {
        damfoldersearch.fetch(debouncedSearchtext, 0, damfoldersearch.windowsize)
    }, [damfoldersearch, debouncedSearchtext, damfoldersearch.windowsize])

    const onSearchResultItemClicked = (event, foldergid) => {
        event.stopPropagation()
        event.nativeEvent.stopImmediatePropagation()
        if (selectedGid === foldergid) {
            setSelectedGid(null)
        } else {
            setSelectedGid(foldergid)
        }
        onSelect && onSelect(selectedGid === foldergid ? null : foldergid)
    }

    const onSearchResultItemDoubleClicked = (event, foldergid) => {
        event.stopPropagation()
        event.nativeEvent.stopImmediatePropagation()
        onConfirmSelect(foldergid)
    }

    const SearchResultItems = damfoldersearch.totalsize ? (
        damfoldersearch.results.map((foldergid, index) => {
            const folder = data.assets.get(foldergid)
            return (
                <LazyScrollItem
                    key={foldergid}
                    index={index + damfoldersearch.topindex}
                >
                    <SearchResultItem
                        folder={folder}
                        selected={foldergid === selectedGid}
                        onClick={e => onSearchResultItemClicked(e, foldergid)}
                        onDoubleClick={e =>
                            onSearchResultItemDoubleClicked(e, foldergid)
                        }
                    />
                </LazyScrollItem>
            )
        })
    ) : (
        <Text>{app.text('No matches')}</Text>
    )

    const onConfirmSelect = foldergid => {
        onSelect && onSelect(foldergid)
        setSelectedGid(null)
    }

    return (
        <VView grow className={classes} {...other}>
            <HView
                onClick={() => setSelectedGid(null)}
                style={{ marginLeft: 5, marginRight: 5 }}
            >
                <View grow>
                    <SearchInput
                        value={searchtext}
                        placeholder={app.text('Search')}
                        onChange={onSearchinputChange}
                        onBlur={onSearchinputBlur}
                    />
                </View>
            </HView>
            <LazyVScrollList
                className="search-results"
                firstindex={damfoldersearch.topindex}
                itemheight={itemheight}
                totalitems={damfoldersearch.totalsize}
                onUpdate={onUpdate}
            >
                {SearchResultItems}
            </LazyVScrollList>
        </VView>
    )
})

//
// UnicatSitePanel
//
// Header for Log in, Sign up, Invites, etc.

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { HView, Spacer } from '../appview'
import { Header, Icon } from '../components'

export const UnicatSitePanel = observer(function UnicatSitePanel({ has_link, title }) {
    const store = useStore()
    const app = store.app

    const onClickAvatar = () => {
        if (has_link) {
            store.setLocation(store.LOCATIONS.WELCOME)
        }
    }

    return (
        <HView className="panel unicat-site-panel" gap={20}>
            <HView style={{ width: 120 }}>
                <Spacer />
                <div className="avatar" onClick={onClickAvatar}>
                    <Icon name="unicat" size={80} title={app.text('Unicat')} />
                </div>
            </HView>
            <HView grow vcenter>
                <Header>{title ? title : app.text('Unicat')}</Header>
            </HView>
        </HView>
    )
})

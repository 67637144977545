//
// SettingsWorkspace
//
// Manage project settings, such as members and channels etc.

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'
import { usePersistentState } from '../hooks/usePersistentState'

import { VView, VScrollView } from '../appview'
import { WorkspaceView } from './WorkspaceView'
import { Item, Icon } from '../components'

import {
    ProjectSettingsWorksheet,
    ProjectMembersWorksheet,
    ProjectAPIKeysWorksheet,
    ProjectBackupsWorksheet,
    ProjectSettingsInspector,
    ProjectMembersInspector,
    ProjectAPIKeysInspector,
    ProjectBackupsInspector,
} from './settings'

export const SettingsWorkspace = observer(function SettingsWorkspace({
    user,
    project,
    viewkey,
    grow,
    ...props
}) {
    const { app, data } = useStore()

    const members = data.membersForProject(project.gid)
    const members_count = members.filter(member => !member.roles.includes('api')).length
    const apikeys_count = members.filter(member => member.roles.includes('api')).length
    const backups_count = project.backup_versions.filter(
        backup => backup.get('version') !== 0
    ).length

    // the ui
    const [navigatorItem, setNavigatorItem] = usePersistentState(
        viewkey + '/navigatorItem',
        'project'
    )

    const Navigator = (
        <VView grow>
            <Item
                indent={0}
                selected={navigatorItem === 'project'}
                onClick={e => {
                    setNavigatorItem('project')
                }}
            >
                <Icon name="settings" size={4} />
                {app.text('Project settings')}
            </Item>
            <Item
                indent={0}
                selected={navigatorItem === 'members'}
                onClick={e => {
                    setNavigatorItem('members')
                }}
            >
                <Icon name="badge" size={4} />
                <VView grow>
                    <div>{app.text('Project members')}</div>
                    <div className="cc-dimmed">
                        {app.pluralized_text(
                            {
                                0: 'No members',
                                1: '{count} member',
                                many: '{count} members',
                            },
                            members_count
                        )}
                    </div>
                </VView>
            </Item>
            <Item
                indent={0}
                selected={navigatorItem === 'apikeys'}
                onClick={e => {
                    setNavigatorItem('apikeys')
                }}
            >
                <Icon name="key" size={4} />
                <VView grow>
                    <div>{app.text('API keys')}</div>
                    <div className="cc-dimmed">
                        {app.pluralized_text(
                            {
                                0: 'No keys',
                                1: '{count} key',
                                many: '{count} keys',
                            },
                            apikeys_count
                        )}
                    </div>
                </VView>
            </Item>
            <Item
                indent={0}
                selected={navigatorItem === 'backups'}
                onClick={e => {
                    setNavigatorItem('backups')
                }}
            >
                <Icon name="backup" size={4} />
                <VView grow>
                    <div>{app.text('Backups')}</div>
                    <div className="cc-dimmed">
                        {app.pluralized_text(
                            {
                                0: 'No backups',
                                1: '{count} backup',
                                many: '{count} backups',
                            },
                            backups_count
                        )}
                    </div>
                </VView>
            </Item>
        </VView>
    )
    const worksheet =
        navigatorItem === 'project' ? (
            <ProjectSettingsWorksheet project={project} />
        ) : navigatorItem === 'members' ? (
            <ProjectMembersWorksheet project={project} />
        ) : navigatorItem === 'apikeys' ? (
            <ProjectAPIKeysWorksheet project={project} />
        ) : navigatorItem === 'backups' ? (
            <ProjectBackupsWorksheet project={project} />
        ) : undefined
    const Inspector = (
        <VView grow>
            {navigatorItem === 'project' ? (
                <ProjectSettingsInspector project={project} />
            ) : navigatorItem === 'members' ? (
                <ProjectMembersInspector project={project} />
            ) : navigatorItem === 'apikeys' ? (
                <ProjectAPIKeysInspector project={project} />
            ) : navigatorItem === 'backups' ? (
                <ProjectBackupsInspector project={project} />
            ) : undefined}
        </VView>
    )

    const NavigatorView = Navigator
    const WorksheetView = <VScrollView>{worksheet}</VScrollView>
    const InspectorView = Inspector

    return (
        <WorkspaceView
            grow={grow}
            className="workspace-settings"
            viewkey={viewkey}
            minWorksheetWidth={640}
            navigator={NavigatorView}
            worksheet={WorksheetView}
            inspector={InspectorView}
        />
    )
})

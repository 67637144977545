//
// DefinitionChildDefinitionMenu
//
// Actions on child definitions in the definition data worksheet
//
// DefinitionChildDefinitionMenu
// - remove_childdefinition
// - goto_definition

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Menu, MenuDivider, MenuItem } from '../components'

export const DefinitionChildDefinitionMenu = observer(
    function DefinitionChildDefinitionMenu({ definition, onAction }) {
        const { app } = useStore()

        const _onAction = action => {
            onAction && onAction(action)
        }

        return (
            <Menu>
                <MenuItem
                    className="cc-notice"
                    onClick={() => _onAction('remove_childdefinition')}
                >
                    {app.text('Remove from definition')}
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={() => _onAction('goto_class')}>
                    {app.text('Go to definition')}
                </MenuItem>
            </Menu>
        )
    }
)

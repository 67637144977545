//
// useSubmenuPopover
//
// The Submenu Popover is placed to the right of an element, top aligned.
// Useful for submenus.
//
// Add a Popover to your views. Returns an object with a Panel to use in your rendering,
// an anchorRef to attach it to a component, and show/hide functions.
//
// Default placement is to the right, top aligned. If there's no space to the right, it
// will be shown to the left, top aligned. If it still doesn't fit, it is placed to the
// right anyway.
// If top alignment puts the bottom of the popover below the viewport, it will be moved
// up to fit just inside the window.
//
// Usage:
//
//     const UserPopover = useTooltipPopover(UserPanel)
//     return (
//         <VView style={{padding: 20}}>
//             <UserPopover.Panel user={store.user} logoutAction={() => { UserPopover.hide() }}/>
//             <Bar raised>
//                  <div ref={UserPopover.anchorRef}>
//                     <Button icon onClick={() => UserPopover.show()}><Icon name="user" size={2}/></Button>
//                 </div>
//             </Bar>
//         </VView>
//     )

import React, { useRef, useEffect, useLayoutEffect, useCallback } from 'react'
import { observer } from 'mobx-react-lite'

import {
    Popover,
    PopoverArrow,
    usePopoverContext,
    PopoverProvider,
} from '../components'
import { gid } from '../utils/gid'

export const useSubmenuPopover = (PopoverPanel, options = {}) => {
    const keyRef = useRef(gid())
    const popoverContext = usePopoverContext()

    const mounted = useRef(false)
    useEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
    }, [])

    const anchorRef = useRef()
    const popoverRef = useRef()
    const arrowRef = useRef()
    const [visibility, setVisibility] = React.useState(false)
    const { dismissAction = undefined, hideArrow = true } = options

    const positionPopover = () => {
        if (!anchorRef.current) return
        if (!popoverRef.current) return

        let originBounds = anchorRef.current.getBoundingClientRect()
        let popoverBounds = popoverRef.current.getBoundingClientRect()
        const windowBounds = {
            x: 0,
            y: 0,
            width: document.documentElement.clientWidth,
            height: document.documentElement.clientHeight,
        }

        const gap = 5
        const overlap = 0
        const popoverSpace = {
            left: originBounds.left + overlap - gap,
            right: windowBounds.width - originBounds.right + overlap - gap,
            top: originBounds.top - gap,
            bottom: windowBounds.height - originBounds.bottom - gap,
        }

        let top = null
        let left = null
        let arrowRotate = 0
        let arrowTop = null
        let arrowLeft = null

        // try to place to the right
        if (popoverBounds.width <= popoverSpace.right) {
            left = originBounds.right - overlap
            arrowLeft = -8
            arrowRotate = -90
        }
        // try left
        else if (popoverBounds.width <= popoverSpace.left) {
            left = originBounds.left - popoverBounds.width + overlap
            arrowLeft = popoverBounds.width - 4
            arrowRotate = 90
        }
        // failed? place right anyway
        else {
            left = originBounds.right - overlap
            arrowLeft = -8
            arrowRotate = -90
        }

        // try top aligned
        if (top === null) {
            // vertically
            top = originBounds.top - gap
            if (top < gap) top = gap
            if (top > windowBounds.height - popoverBounds.height - gap)
                top = windowBounds.height - popoverBounds.height - gap
            arrowTop = 12
        }

        // apply
        popoverRef.current.style.top = top + 'px'
        popoverRef.current.style.left = left + 'px'

        // base arrow sits at 0,0 and points up, we can override top/left, and rotate
        if (arrowRef.current) {
            arrowRef.current.style.top = arrowTop + 'px'
            arrowRef.current.style.left = arrowLeft + 'px'
            arrowRef.current.style.transform = 'rotate(' + arrowRotate + 'deg)'
            arrowRef.current.style.display = hideArrow ? 'none' : 'block'
        }

        if (anchorRef.current) {
            const submenuBounds = popoverRef.current.getBoundingClientRect()
            anchorRef.current.dataset['submenuDirection'] =
                arrowRotate === 90 ? 'left' : 'right'
            anchorRef.current.dataset['submenuTop'] = submenuBounds.top
            anchorRef.current.dataset['submenuBottom'] = submenuBounds.bottom
            anchorRef.current.dataset['submenuX'] =
                arrowRotate === 90 ? submenuBounds.right : submenuBounds.left
        }

        if (
            top < 0 ||
            top + popoverBounds.height > windowBounds.height ||
            left < 0 ||
            left + popoverBounds.width > windowBounds.width
        ) {
            popoverRef.current.style.display = 'none'
            if (arrowRef.current) {
                arrowRef.current.style.display = 'none'
            }
        } else {
            popoverRef.current.style.display = 'block'
            if (arrowRef.current) {
                arrowRef.current.style.display = hideArrow ? 'none' : 'block'
            }
        }
    }

    useLayoutEffect(() => {
        positionPopover()
    })

    const panelRef = useRef()
    const resizeobserver = useRef(null)
    useLayoutEffect(() => {
        if (panelRef.current) {
            resizeobserver.current = new ResizeObserver(entries => {
                // window.requestAnimationFrame tries to prevent
                // 'ResizeObserver loop completed with undelivered notifications.'
                window.requestAnimationFrame(() => {
                    positionPopover() // make sure we don't re-render!
                })
            })
            resizeobserver.current.observe(panelRef.current)
            const element = panelRef.current
            return () => {
                resizeobserver.current.unobserve(element)
            }
        }
    })

    useLayoutEffect(() => {
        if (panelRef.current) {
            function handleScroll(e) {
                window.requestAnimationFrame(() => {
                    positionPopover() // make sure we don't re-render!
                })
            }

            window.addEventListener('scroll', handleScroll, { passive: false })
            window.addEventListener('wheel', handleScroll, { passive: false })

            return () => {
                window.removeEventListener('scroll', handleScroll, { passive: false })
                window.removeEventListener('wheel', handleScroll, { passive: false })
            }
        }
    })

    const setVisibilityIfMounted = vis => {
        if (mounted.current) setVisibility(vis)
    }

    const show = () => {
        popoverContext.store.show(
            [...popoverContext.stack],
            keyRef.current,
            setVisibilityIfMounted,
            dismissAction
        )
    }
    const hide = useCallback(() => {
        setVisibility(false)
    }, [])

    const isOpen = () => visibility

    const { onClickOutside = hide } = options

    useEffect(() => {
        const handleClickOutside = event => {
            if (
                event.target.className.split &&
                event.target.className.split(' ').includes('cc-FileUpload')
            ) {
                return
            }
            if (popoverRef.current && !popoverRef.current.contains(event.target)) {
                onClickOutside(event)
            }
        }

        document.addEventListener('click', handleClickOutside)
        document.addEventListener('contextmenu', handleClickOutside)
        document.addEventListener('dragstart', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
            document.removeEventListener('contextmenu', handleClickOutside)
            document.removeEventListener('dragstart', handleClickOutside)
        }
    }, [popoverRef, onClickOutside])

    if (!PopoverPanel) return null

    const Panel = observer(function Panel(props) {
        if (!visibility) return null
        const { hidePopover, ...other } = props
        if (hidePopover) return null
        const Panel = (
            <div ref={panelRef}>
                <PopoverPanel {...other} />
            </div>
        )
        if (!Panel) return null

        return (
            <PopoverProvider
                value={{
                    store: popoverContext.store,
                    stack: [...popoverContext.stack, keyRef.current],
                }}
            >
                <Popover className="cc-Submenu-Popover" ref={popoverRef}>
                    <PopoverArrow ref={arrowRef} />
                    {Panel}
                </Popover>
            </PopoverProvider>
        )
    })

    return {
        Panel,
        anchorRef,
        show,
        onShow: e => {
            show()
            e.preventDefault()
            e.stopPropagation()
        },
        hide,
        isOpen,
    }
}

//
// FieldLayoutBuilder
//
// Based on view info from a layout

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'

import { Text } from '../../../../components'
import { FieldLabel, LabeledField } from '../fields'
import { TypedField } from '.'
import { LAYOUTCOMPONENTTYPECONFIG } from '../../../../stores/data/LAYOUTCOMPONENTTYPECONFIG'

export const FieldLayoutBuilder = observer(function FieldLayoutBuilder({
    className,
    component,
    components,
    layout,
    record,
    worksheet,
    active_class,
    visited_gids,
    force_readonly,
}) {
    const store = useStore()

    const config = LAYOUTCOMPONENTTYPECONFIG['field']

    const field_gid = component.field
    const placeholder = component.placeholder

    let field = worksheet.findField(field_gid)
    if (!field) {
        return null
    }
    const fromClass = worksheet.findClassForField(null, field)

    const editable = force_readonly
        ? false
        : 'editable' in component
        ? component.editable
        : config.editable.default
    const localizedPlaceholder = placeholder && placeholder[store.appLanguage]

    const Placeholder = localizedPlaceholder ? (
        <div className="ws-placeholder">{localizedPlaceholder}</div>
    ) : undefined

    let attributes = { className: 'layout-component' }
    if (className) attributes['className'] += ' ' + className
    if (component.style) attributes['className'] += ' lc-' + component.style
    if (component.gid === worksheet.layoutstore.selectedComponentGid) {
        attributes['className'] += ' cc-selected'
    }
    // attributes["onClick"] = e => onClickElement(e, component, layouttreestore)

    let Field = (
        <TypedField
            field={field}
            fromClass={fromClass}
            component={component}
            editable={editable}
            force_readonly={force_readonly}
            record={record}
            active_class={active_class}
            worksheet={worksheet}
        />
    )
    if (!Field) {
        attributes['className'] += ' ws-placeholder-visible'
        Field = (
            <div>
                <br />
            </div>
        )
    }

    const fieldvalue =
        record.localized_fields && record.localized_fields.get(field.name)
    if (fieldvalue === undefined) {
        return (
            <LabeledField
                fieldgid={field.gid}
                label={<FieldLabel field={field} worksheet={worksheet} />}
                fromClass={fromClass}
                worksheet={worksheet}
                {...attributes}
            >
                <Text>
                    {store.app.text("Undefined field '{fieldname}'", {
                        fieldname: field.name,
                    })}
                </Text>
            </LabeledField>
        )
    }

    if (
        field.type !== 'boolean' &&
        (fieldvalue === null || !fieldvalue.toString().length)
    ) {
        attributes['className'] += ' ws-placeholder-visible'
        if (
            [
                'textlist',
                'record',
                'recordlist',
                'file',
                'filelist',
                'image',
                'imagelist',
                'class',
                'classlist',
            ].includes(field.type)
        ) {
            attributes['className'] += ' ws-placeholder-with-field'
        }
    }
    return (
        <LabeledField
            fieldgid={field.gid}
            editable={editable}
            hide_editable_icon={force_readonly}
            label={<FieldLabel field={field} worksheet={worksheet} />}
            fromClass={fromClass}
            worksheet={worksheet}
            {...attributes}
        >
            {Placeholder}
            {Field}
        </LabeledField>
    )
})

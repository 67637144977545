//
// RecordDefinitionAddChildDefinitionPanel
//
// Add a child definition to a definition, by searching for a definition.

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { VView, VScrollView, Spacer } from '../appview'
import {
    Header,
    SearchInput,
    Item,
    Property,
    Text,
    Menu,
    MenuItem,
} from '../components'
import { normalize } from '../utils/text'

export const RecordDefinitionAddChildDefinitionPanel = observer(
    function RecordDefinitionAddChildDefinitionPanel({ onAddChildDefinition }) {
        const { app, data, view } = useStore()
        const worksheet = view.pimworksheet
        const language = worksheet.environment.get('language')

        const [searchtext, setSearchtext] = useState('')
        const [selecteditem, setSelecteditem] = useState(null)
        let selection_in_results = false // initially

        let searchresultdefinitions = Array.from(data.definitions.entries())
        searchresultdefinitions = searchresultdefinitions.filter(
            ([key, definition]) =>
                !definition.is_working_copy && !definition.is_extended
        )

        if (searchtext.length) {
            const searchwords = normalize(searchtext).split(' ')
            searchresultdefinitions = searchresultdefinitions.filter(
                ([key, definition]) => {
                    // do a match: in label and name, label only current language
                    // first, normalize (validName)
                    // second, split on spaces
                    // then, match all words
                    let definitionlabel = definition.label.get(language)
                    if (!definitionlabel || !definitionlabel.length) {
                        definitionlabel = definition.name
                    }
                    definitionlabel = normalize(definitionlabel)
                    for (const word of searchwords) {
                        if (!word.length) continue
                        if (
                            !definitionlabel.includes(word) &&
                            !definition.name.includes(word)
                        ) {
                            return false
                        }
                    }
                    return true
                }
            )
        }

        searchresultdefinitions.sort((a, b) => {
            const adefinition = a[1]
            const bdefinition = b[1]
            let alabel = adefinition.label.get(language)
            if (!alabel || !alabel.length) {
                alabel = adefinition.name
            }
            alabel = normalize(alabel)
            let blabel = bdefinition.label.get(language)
            if (!blabel || !blabel.length) {
                blabel = bdefinition.name
            }
            blabel = normalize(blabel)
            if (alabel === blabel) return 0
            return alabel > blabel ? 1 : -1
        })

        const onSearchinputChange = event => {
            setSearchtext(event.target.value)
        }
        const onSearchinputBlur = event => {
            setSearchtext(event.target.value.trim())
        }

        const _onAddChildDefinition = () => {
            onAddChildDefinition && selecteditem && onAddChildDefinition(selecteditem)
        }

        const searchresults = searchresultdefinitions.length ? (
            searchresultdefinitions.map(([key, definition]) => {
                let definitionname = definition.label.get(language)
                if (!definitionname || !definitionname.length) {
                    definitionname = definition.name
                }
                if (selecteditem === key) selection_in_results = true
                return (
                    <Item
                        key={key}
                        selected={selecteditem === key}
                        onClick={() => setSelecteditem(key)}
                        onDoubleClick={() => {
                            setSelecteditem(key)
                            _onAddChildDefinition()
                        }}
                    >
                        {definitionname}
                    </Item>
                )
            })
        ) : (
            <div style={{ padding: '2px 5px' }}>{app.text('No matches')}</div>
        )

        const selecteddefinition = selection_in_results
            ? data.definitions.get(selecteditem)
            : null
        let selecteddefinition_info
        if (selecteddefinition) {
            let definitionname = selecteddefinition.label.get(language)
            if (!definitionname || !definitionname.length) {
                definitionname = selecteddefinition.name
            }
            selecteddefinition_info = (
                <>
                    <Property className="nobreak" label={app.text('name')}>
                        <Text className="user-select">{selecteddefinition.name}</Text>
                    </Property>
                    <Property label={app.text('label')}>
                        <Text className="user-select">{definitionname}</Text>
                    </Property>
                </>
            )
        }

        return (
            <VView className="actions-panel recorddefinition-add-childdefinition-panel">
                <div className="inspector">
                    <Header>{app.text('Add child definition')}</Header>
                    <VView
                        style={{
                            marginLeft: 5,
                            marginRight: 5,
                            marginBottom: 2,
                        }}
                    >
                        <SearchInput
                            value={searchtext}
                            placeholder={app.text('Search by name or label')}
                            onChange={onSearchinputChange}
                            onBlur={onSearchinputBlur}
                        />
                        <VScrollView
                            className="search-results"
                            style={{ backgroundColor: 'white' }}
                        >
                            {searchresults}
                        </VScrollView>
                        <Spacer size={5} />
                        {selecteddefinition_info}
                    </VView>
                </div>
                <Menu>
                    <MenuItem
                        disabled={!selection_in_results}
                        onClick={_onAddChildDefinition}
                    >
                        {selecteddefinition
                            ? app.text("Add child definition '{definitionname}'", {
                                  definitionname: selecteddefinition.name,
                              })
                            : app.text('Add child definition')}
                    </MenuItem>
                </Menu>
            </VView>
        )
    }
)

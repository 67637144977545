//
// ExtendedDefinitionMenu for RecordInspector
//

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../stores'

import { VView, VScrollView, FixedVSpace } from '../../../appview'
import {
    Header,
    Text,
    Icon,
    Item,
    Menu,
    MenuItem,
    MenuDivider,
} from '../../../components'
import {
    RecordDefinitionAddClassPanel,
    RecordDefinitionAddFieldPanel,
    RecordDefinitionChangePanel,
} from '../../../panels'
import { changeDefinitionLostDataFields } from '../../../stores/data/utils'

export const ExtendedDefinitionMenu = observer(function ExtendedDefinitionMenu({
    worksheet,
    onAction,
}) {
    const { app, data } = useStore()
    const language = worksheet.environment.get('language')

    const _onAction = (action, parameter) => {
        onAction && onAction(action, parameter)
    }

    const definition = data.definitions.get(worksheet.selected.record.definition)
    if (!definition) return null
    const original_definition = definition
        ? data.definitions.get(definition.original)
        : null

    const lost_fields =
        original_definition && definition
            ? changeDefinitionLostDataFields(original_definition, definition, data)
            : []

    const ConfirmRevertSubmenu = observer(function ConfirmRevertSubmenu({
        lost_fields,
        onConfirm,
        onCancel,
    }) {
        const { app, view } = useStore()

        const _onConfirm = () => {
            onConfirm && onConfirm()
        }

        const _onCancel = () => {
            onCancel && onCancel()
        }

        return (
            <VView className="actions-panel recorddefinition-revert-panel">
                <div className="inspector">
                    <Header className="cc-danger">{app.text('Warning')}</Header>
                    <Text>
                        {app.text(
                            `Reverting the definition can result in data loss
                             when fields are no longer available in the original
                             definition. This data cannot be recovered by
                             re-adding the fields.`
                        )}
                    </Text>
                    <Text>
                        {app.text(
                            `For all records that use this definition,
                             these fields will be lost:`
                        )}
                    </Text>
                    <VScrollView className="lost-fields">
                        {lost_fields.map(field => {
                            const title = view.schemaworksheet.layoutstore.getItemTitle(
                                field,
                                language
                            )
                            return (
                                <Item key={field.gid}>
                                    <Icon name="field" size="text" /> {title}
                                </Item>
                            )
                        })}
                    </VScrollView>
                    <FixedVSpace size={5} />
                </div>
                <Menu>
                    <MenuItem onClick={_onCancel}>{app.text('Cancel')}</MenuItem>
                    <MenuItem className="cc-danger" onClick={_onConfirm}>
                        {app.text('Yes, change the definition')}
                    </MenuItem>
                </Menu>
            </VView>
        )
    })

    return (
        <Menu>
            <MenuItem
                onClick={() => {
                    _onAction('reveal_original')
                }}
            >
                {app.text('Reveal original in Schema workspace')}
            </MenuItem>
            <MenuDivider />
            <MenuItem
                disabled={worksheet.record.is_link}
                className={lost_fields.length ? 'cc-notice' : ''}
                submenu={
                    lost_fields.length ? (
                        <ConfirmRevertSubmenu
                            lost_fields={lost_fields}
                            onConfirm={() => _onAction('revert_definition')}
                            onCancel={() => _onAction('cancel')}
                        />
                    ) : undefined
                }
            >
                {app.text('Revert to original')}
            </MenuItem>
            <MenuItem
                disabled={worksheet.record.is_link}
                className="cc-notice"
                submenu={
                    <RecordDefinitionChangePanel
                        worksheet={worksheet}
                        onChangeDefinition={new_definition_gid => {
                            _onAction('change_definition', new_definition_gid)
                        }}
                        onCancel={() => _onAction('cancel')}
                    />
                }
            >
                {app.text('Change')}
            </MenuItem>
            <MenuDivider />
            <MenuItem
                disabled={worksheet.record.is_link}
                submenu={
                    <RecordDefinitionAddClassPanel
                        onAddClass={class_gid => {
                            _onAction('add_class', class_gid)
                        }}
                    />
                }
            >
                {app.text('Add a class')}
            </MenuItem>
            <MenuItem
                disabled={worksheet.record.is_link}
                submenu={
                    <RecordDefinitionAddFieldPanel
                        onAddField={field_gid => {
                            _onAction('add_field', field_gid)
                        }}
                    />
                }
            >
                {app.text('Add a field')}
            </MenuItem>
        </Menu>
    )
})

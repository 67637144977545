//
// ImagelistFieldOptions
//
// Field options for type 'imagelist'

import React from 'react'
import { observer } from 'mobx-react-lite'

import { FolderFieldOption } from './FolderFieldOption'
import { ListFieldOptions } from './ListFieldOptions'

export const ImagelistFieldOptions = observer(function ImagelistFieldOptions({
    field,
    selectModified,
    worksheet,
    onAction,
    setSelected,
    renderkeyprefix,
}) {
    if (!field) return null

    return (
        <>
            <FolderFieldOption
                field={field}
                selectModified={selectModified}
                worksheet={worksheet}
                onAction={onAction}
                setSelected={setSelected}
                renderkeyprefix={renderkeyprefix}
            />
            <ListFieldOptions
                field={field}
                selectModified={selectModified}
                worksheet={worksheet}
                onAction={onAction}
                setSelected={setSelected}
                renderkeyprefix={renderkeyprefix}
            />
        </>
    )
})

//
// FieldData
//
// A plain FieldData object to hold properties

import { makeObservable, observable, computed, action } from 'mobx'

import { updateInstanceWithData } from './utils'

export class FieldData {
    gid = null
    original = null
    name = ''
    type = ''
    options = new Map()
    is_localized = true
    is_required = false
    label = new Map()
    initial = new Map()
    _initial = new Map()
    unit = ''
    is_new = false
    is_working_copy = false

    _modifications = new Set()

    constructor(fielddata, rootstore) {
        makeObservable(this, {
            original: observable,
            name: observable,
            type: observable,
            options: observable,
            is_localized: observable,
            is_required: observable,
            label: observable,
            initial: observable,
            unit: observable,
            renamed_from: computed,
            is_working_copy: observable,
            is_new: observable,
            is_committed: computed,
            is_multiline: computed,
            update: action.bound,
            setProperty: action.bound,
            setOption: action.bound,
            setValuesOption: action.bound,
            commitIfModified: action.bound,
            commit: action.bound,
            revert: action.bound,
            saveAsNew: action.bound,
            delete: action.bound,
        })

        this._rootstore = rootstore
        this.update(fielddata)
    }

    newDataValue = () => {
        // if (this.initial.size) {
        //     missing language.....
        //     return objectFrom(this.initial)
        // } else
        if (['imagelist', 'filelist', 'recordlist', 'classlist'].includes(this.type)) {
            return []
        } else if (['class'].includes(this.type)) {
            const class_gid = this.options.get('class')
            if (!class_gid) return null
            const _class = this._rootstore.data.classes.get(class_gid)
            if (!_class) return null
            let newitem = new Map()
            for (const field_gid of _class.fields) {
                const field = this._rootstore.data.fields.get(field_gid)
                if (field) {
                    newitem.set(field.name, field.newDataValue())
                } else {
                    console.log('ClassField field ERROR', field_gid)
                }
            }
            return newitem
        } else if (
            ['text', 'textline', 'textlist', 'code', 'barcode'].includes(this.type)
        ) {
            return ''
        }
        return null
    }

    update = (fielddata, overwrite_resets) => {
        // overwrite_resets for initial only
        updateInstanceWithData(this, fielddata, [
            'gid',
            'original',
            'name',
            'type',
            'options',
            'is_localized',
            'is_required',
            'label',
            'initial',
            'unit',
            'is_new',
            'is_working_copy',
        ])
        if (
            ['textline', 'textlist'].includes(this.type) &&
            !this.options.has('values')
        ) {
            this.options.set('values', [])
        }
        if (!overwrite_resets) {
            this._initial = new Map(this.initial) // store db value
        }
    }

    get renamed_from() {
        if (!this.is_working_copy) return null
        if (this.is_new) return null
        const original = this._rootstore.data.fields.get(this.original)
        if (!original || original.name === this.name) return null
        return original.name
    }

    get is_committed() {
        return !this.is_working_copy
    }

    get is_multiline() {
        return ['text', 'code', 'recordlist', 'imagelist', 'filelist'].includes(
            this.type
        )
    }

    setProperty = (propertyname, value, optional_language) => {
        const previous_value = optional_language
            ? this[propertyname].get(optional_language)
            : this[propertyname]
        if (previous_value === value) return false

        let fielddata = {}
        if (
            ['name', 'type', 'is_localized', 'is_required', 'unit'].includes(
                propertyname
            )
        ) {
            fielddata[propertyname] = value
        } else if (['options'].includes(propertyname)) {
            fielddata[propertyname] = Object.fromEntries(this[propertyname])
            fielddata[propertyname] = { ...fielddata[propertyname], ...value }
        } else if (['label'].includes(propertyname)) {
            // label is -always- localized
            fielddata[propertyname] = Object.fromEntries(this[propertyname])
            fielddata[propertyname][optional_language] = value
        } else if (['initial'].includes(propertyname)) {
            // initial localization depends on is_localized setting
            fielddata[propertyname] = Object.fromEntries(this[propertyname])
            if (this['is_localized']) {
                fielddata[propertyname][optional_language] = value
            } else {
                for (var language of this._rootstore.project.languages) {
                    fielddata[propertyname][language] = value
                }
            }
        }
        this.update(fielddata, true)
        this._modifications.add(propertyname)
        return true
    }

    setOption = (optionname, value, optional_language) => {
        const options = this['options']
        let option = options.get(optionname)

        const previous_value = optional_language
            ? option
                ? option[optional_language]
                : undefined
            : option
        if (previous_value === value) return false

        if (optional_language) {
            if (!option) option = {}
            if (this['is_localized']) {
                option[optional_language] = value
            } else {
                for (var language of this._rootstore.project.languages) {
                    option[language] = value
                }
            }
        } else {
            option = value
        }
        let new_options = { ...Object.fromEntries(options) }
        new_options[optionname] = option

        this.update({ options: new_options }, true)
        this._modifications.add('options')
        return true
    }

    setValuesOption = values => {
        const options = this['options']
        let new_options = { ...Object.fromEntries(options) }
        new_options['values'] = values
        this.update({ options: new_options }, true)
        this._modifications.add('options')
        return true
    }

    resetInitial = language => {
        this.initial.set(language, this._initial.get(language))
    }

    shouldSubmit = propertyname => {
        // only call this if the action on returning true is an update of the backend
        const is_modified = this._modifications.has(propertyname)
        if (is_modified) {
            this._modifications.delete(propertyname)
        }
        return is_modified
    }

    commitIfModified = () => {
        // send gid + modified fields to server backend
        if (!this._modifications.size) {
            return Promise.resolve(true)
        }

        let updateparams = { field: this.gid }
        for (const fieldname of this._modifications.keys()) {
            updateparams[fieldname] = this[fieldname]
        }
        // basically fire & forget updates
        return this._rootstore._fetch('/fields/modify', updateparams).then(result => {
            this._modifications = new Set()
            return result
        })
    }

    commit = () => {
        const is_new = this.is_new
        return this._rootstore
            ._fetch('/fields/commit', { field: this.gid })
            .then(result => {
                if (!is_new) {
                    // delete the 'modified' version
                    this._rootstore.data.fields.delete(this.gid)
                }
                return result
            })
    }
    revert = () => {
        return this._rootstore
            ._fetch('/fields/revert', { field: this.gid })
            .then(result => {
                // delete the 'modified' version
                this._rootstore.data.fields.delete(this.gid)
                return result
            })
    }
    saveAsNew = () => {
        return this._rootstore._fetch('/fields/save_as_new', {
            field: this.gid,
        })
    }
    copy = () => {
        return this._rootstore._fetch('/fields/copy', {
            field: this.gid,
        })
    }
    delete = () => {
        return this._rootstore
            ._fetch('/fields/delete', { field: this.gid })
            .then(result => {
                this._rootstore.data.fields.delete(this.gid)
                return result
            })
            .catch(error => {
                // in use - report to user?
                return false
            })
    }
}

//
// PasswordInput
//
// auto-validating password input

import React, { useState, useEffect } from 'react'
import { useDebounce } from '../hooks/useDebounce'

import { Icon } from '.'
import { VALIDATION } from '../utils/validation'
import { ValidationMessage } from './ValidationMessage'

export const PasswordInput = ({
    value,
    enabled,
    placeholder,
    validate,
    onFocus,
    onChange,
    onBlur,
    onKeyDown,
    ...other
}) => {
    const Placeholder = (
        <div className="cc-Placeholder">{placeholder ? placeholder : '⚬⚬⚬⚬⚬⚬⚬⚬⚬⚬'}</div>
    )
    const strvalue = value ? value.toString() : ''

    if (enabled === undefined) enabled = true

    const debouncedValue = useDebounce(strvalue, 200)

    const [showPassword, setShowPassword] = useState(false)
    const [validation, setValidation] = useState(null)

    useEffect(() => {
        if (!validate) return
        let isMounted = true
        // this works for returned Promises and for returned regular values
        new Promise(resolve => resolve(validate(debouncedValue))).then(
            validationresult => {
                if (isMounted) {
                    setValidation(validationresult)
                }
            }
        )
        // .catch(error => {})
        return () => (isMounted = false)
    }, [validate, debouncedValue])

    let classes = 'cc-Input cc-PasswordInput'
    if (!enabled) {
        classes += ' cc-disabled'
    }
    if (!value || !strvalue.toString().trim().length) {
        classes += ' cc-placeholder-visible'
    }

    if (validation) {
        if (validation.result === VALIDATION.ERROR) {
            classes += ' validation-error'
        } else if (validation.result === VALIDATION.REPORT) {
            classes += ' validation-report'
        }
    }

    const _onFocus = e => {
        onFocus && onFocus(e)
    }

    const _onChange = e => {
        onChange && onChange(e)
    }

    const _onBlur = e => {
        onBlur && onBlur(e)
    }

    const _onKeyDown = e => {
        onKeyDown && onKeyDown(e)
    }

    return (
        <div className={classes}>
            <input
                value={strvalue}
                className={'user-select' + (!enabled ? ' cc-disabled' : '')}
                type={showPassword ? 'text' : 'password'}
                name="password"
                disabled={!enabled}
                onFocus={_onFocus}
                onChange={_onChange}
                onBlur={_onBlur}
                onKeyDown={_onKeyDown}
            />
            <Icon
                onClick={e => setShowPassword(!showPassword)}
                name={showPassword ? 'hide' : 'show'}
                size={'text'}
            />
            {Placeholder}
            <ValidationMessage validation={validation} />
        </div>
    )
}

//
// Menu
//
// A Menu is meant to be used in a Popover, usually a useMousePopover to anchor it to
// the mouse position. It supports any children, but MenuItem children with submenus are
// especially supported with menu-aim.
//
// Example code:
//
//     const MenuPanel = observer(function MenuPanel(props) {
//         const log = console.log
//         const Submenu = (
//             <Menu>
//                 <MenuItem onClick={() => log("Cut")}>Cut</MenuItem>
//                 <MenuItem onClick={() => log("Copy")}>Copy</MenuItem>
//                 <MenuItem onClick={() => log("Paste")}>Paste</MenuItem>
//             </Menu>
//         )
//
//         return (
//             <Menu>
//                 <MenuItem submenu={Submenu}>Actions</MenuItem>
//                 <MenuDivider />
//                 <MenuItem onClick={() => log("Create new")}>Create new</MenuItem>
//                 <MenuDivider />
//                 <MenuItem disabled onClick={() => log("Delete")}>
//                     Delete
//                 </MenuItem>
//             </Menu>
//         )
//     })
//
//     const ContextMenu = useMousePopover(MenuPanel)
//
//     return (
//         <>
//             <ContextMenu.Panel />
//             <Text onContextMenu={ContextMenu.onShow}>
//                 Context menu (right-click)
//             </Text>
//         </>
//     )

import React, { useState, useRef, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { useRefEventListener } from '../hooks/useEventListener'
import { useClickOutside } from '../hooks/useClickOutside'
import { MenuItem } from '.'

export const Menu = observer(function Menu(props) {
    const { className, activeMenuitem, setActiveMenuitem, children, ...other } = props
    let classes = 'cc-Menu'
    if (className) classes += ' ' + className

    const menuitems = React.Children.toArray(children)
    const menuitemRefs = useMemo(() => menuitems.map(React.createRef), [menuitems])

    const [_activeMenuitem, _setActiveMenuitem] = useState(activeMenuitem)
    const timeoutId = useRef(null)
    const mouseLocs = useRef([])
    const [lastDelayMouseLoc, setDelayLastMouseLoc] = useState(null)
    const TOLERANCE = 75
    const MOUSE_LOCS_TRACKED = 3
    const DELAY = 300

    const __activeMenuitem = setActiveMenuitem ? activeMenuitem : _activeMenuitem
    const __setActiveMenuitem = index => {
        if (setActiveMenuitem) {
            setActiveMenuitem(index)
        } else {
            _setActiveMenuitem(index)
        }
    }

    const clearTimeoutId = () => {
        if (timeoutId.current) {
            clearTimeout(timeoutId.current)
            timeoutId.current = null
        }
    }

    useEffect(() => {
        return () => {
            clearTimeoutId()
        }
    }, [])

    const onLeaveMenu = e => {
        clearTimeoutId()
    }

    const onMouseMove = e => {
        mouseLocs.current.push({
            x: e.pageX,
            y: e.pageY,
        })
        if (mouseLocs.current.length > MOUSE_LOCS_TRACKED) {
            mouseLocs.current.shift()
        }
    }

    const onEnterMenuitem = index => {
        clearTimeoutId()
        possiblyActivate(index)
    }

    const activate = index => {
        __setActiveMenuitem(index)
    }

    const possiblyActivate = index => {
        const delay = getActivationDelay()
        if (delay) {
            timeoutId.current = setTimeout(() => {
                possiblyActivate(index)
            }, delay)
        } else {
            activate(index)
        }
    }

    const getActivationDelay = () => {
        if (__activeMenuitem === null) return 0
        const menuitemref = menuitemRefs[__activeMenuitem]
        if (!menuitemref || !menuitemref.current) return 0
        const menuitem = menuitemref.current.firstChild
        if (
            !menuitem ||
            !menuitem.dataset ||
            !('submenuDirection' in menuitem.dataset)
        ) {
            // no submenu active, so activate immediately
            return 0
        }
        const submenuDirection = menuitem.dataset['submenuDirection']
        const submenuTop = parseInt(menuitem.dataset['submenuTop'], 10)
        const submenuBottom = parseInt(menuitem.dataset['submenuBottom'], 10)
        const submenuX = parseInt(menuitem.dataset['submenuX'], 10)
        const currentMouseLoc = mouseLocs.current[mouseLocs.current.length - 1]
        let previousMouseLoc = mouseLocs.current[0]
        if (!currentMouseLoc) {
            return 0
        }
        if (!previousMouseLoc) {
            previousMouseLoc = currentMouseLoc
        }
        if (
            lastDelayMouseLoc &&
            lastDelayMouseLoc.x === currentMouseLoc.x &&
            lastDelayMouseLoc.y === currentMouseLoc.y
        ) {
            // not moved since last check
            return 0
        }

        const slope = (a, b) => (b.y - a.y) / (b.x - a.x)
        const decreasingCorner =
            submenuDirection === 'right'
                ? { x: submenuX, y: submenuTop - TOLERANCE }
                : { x: submenuX, y: submenuBottom + TOLERANCE }
        const increasingCorner =
            submenuDirection === 'right'
                ? { x: submenuX, y: submenuBottom + TOLERANCE }
                : { x: submenuX, y: submenuTop - TOLERANCE }

        const decreasingSlope = slope(currentMouseLoc, decreasingCorner)
        const increasingSlope = slope(currentMouseLoc, increasingCorner)
        const previousDecreasingSlope = slope(previousMouseLoc, decreasingCorner)
        const previousIncreasingSlope = slope(previousMouseLoc, increasingCorner)

        if (
            decreasingSlope < previousDecreasingSlope &&
            increasingSlope > previousIncreasingSlope
        ) {
            setDelayLastMouseLoc(currentMouseLoc)
            return DELAY
        }

        setDelayLastMouseLoc(null)
        return 0
    }

    const menuRef = useRef(null)
    useRefEventListener(menuRef, 'mouseleave', onLeaveMenu) // onMouseLeave isn't reliable in react
    useClickOutside(menuRef, () => {
        __setActiveMenuitem(null)
    })

    const MenuItems = menuitems.map((child, index) => {
        const id = 'menuitem-' + index
        let extraprops = {
            key: id,
            ref: menuitemRefs[index],
            onMouseEnter: () => onEnterMenuitem(index),
        }
        if (child.type === MenuItem) {
            extraprops['dismissAction'] = () => __setActiveMenuitem(null)
        }
        if (!child.props.disabled && __activeMenuitem === index) {
            extraprops['active'] = 'true'
        }
        return React.cloneElement(child, extraprops)
    })

    return (
        <div className={classes} {...other} ref={menuRef} onMouseMove={onMouseMove}>
            {MenuItems}
        </div>
    )
})

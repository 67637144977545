//
// Image
//
// An Image. Please provide an alt text.

import React from 'react'
import { LayerView, Layer } from '../appview'
import { Icon } from '.'

export const Image = React.forwardRef(function Image(props, ref) {
    const { className, value, alt, ...other } = props
    let classes = 'cc-Image'
    if (className) classes += ' ' + className
    let imgalt = ''
    if (alt) imgalt = alt
    if (!value) {
        return (
            <LayerView ref={ref} title={imgalt} className={classes}>
                <Icon name="image" {...other} />
                <Layer anchor="center">
                    <Icon name="settings" size={2} spin />
                </Layer>
            </LayerView>
        )
    }
    return <img ref={ref} src={value} alt={imgalt} className={classes} {...other} />
})

//
// ProjectMembersWorksheet
//
// Project members worksheet

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../stores'
import { useMousePopover } from '../../hooks/useMousePopover'
import { useTooltipPopover } from '../../hooks/useTooltipPopover'

import { VView, HView, Spacer } from '../../appview'
import { Header, Property, Item, Icon } from '../../components'
import { ProjectMemberMenu } from '../../menus'
import { ProjectMember, InviteMemberPanel } from '../../panels'

export const ProjectMembersWorksheet = observer(function ProjectMembersWorksheet({
    project,
}) {
    const { app, data, view } = useStore()

    const is_owner = view.user && view.project && view.user.gid === view.project.owner

    const [selectedMember, setSelectedMember] = useState(null)

    const onResendInvite = user_gid => {
        view.project.resendInvite(user_gid)
    }

    const onDeactivateMember = user_gid => {
        view.project.deactivateMember(user_gid)
    }

    const onReactivateMember = user_gid => {
        view.project.reactivateMember(user_gid)
    }

    const onRemoveMember = user_gid => {
        view.project.removeMember(user_gid)
    }

    // ContextMenus and Popovers
    const ProjectMemberContextMenu = useMousePopover(ProjectMemberMenu)
    const InviteMemberPopover = useTooltipPopover(InviteMemberPanel)

    const onShowProjectMemberContextMenu = (e, member) => {
        if (!is_owner) return
        if (member.roles.includes('owner')) return
        setSelectedMember(member)
        ProjectMemberContextMenu.onShow(e)
    }

    const onShowInviteMemberPopover = e => {
        if (!is_owner) return
        InviteMemberPopover.onShow(e)
    }

    const onDoneInviteMember = () => {
        InviteMemberPopover.hide()
    }

    const onCancelInviteMember = () => {
        InviteMemberPopover.hide()
    }

    const onProjectMemberAction = (user_gid, action) => {
        const actions = {
            resend_invite: onResendInvite,
            deactivate: onDeactivateMember,
            reactivate: onReactivateMember,
            remove: onRemoveMember,
        }
        if (!(action in actions)) {
            console.log(
                `onProjectMemberAction: unhandled action '${action}' on '${user_gid}'`
            )
            return
        }
        ProjectMemberContextMenu.hide()
        actions[action](user_gid)
    }

    const members = data.membersForProject(project.gid)
    members.sort((a, b) => {
        const a_owner = a.roles.includes('owner')
        const b_owner = b.roles.includes('owner')
        const a_api = a.roles.includes('api')
        const b_api = b.roles.includes('api')
        if (a_owner !== b_owner) return a_owner ? -1 : 1
        if (a_api !== b_api) return a_api ? 1 : -1
        const a_user = data.users.get(a.user_gid)
        const b_user = data.users.get(b.user_gid)
        if (!a_user) return -1
        if (!b_user) return 1
        return a_user.displayname.localeCompare(b_user.displayname)
    })

    const Members = members
        .filter(member => {
            if (member.roles.includes('api')) {
                return false
            }
            if (
                !is_owner &&
                view.user &&
                view.user.gid !== member.user_gid &&
                ['inactive', 'invited'].includes(member.status)
            ) {
                return false
            }
            return true
        })
        .map(member => (
            <ProjectMember
                key={member.user_gid}
                member={member}
                onClick={e => onShowProjectMemberContextMenu(e, member)}
                onContextMenu={e => onShowProjectMemberContextMenu(e, member)}
            />
        ))

    return (
        <VView className="worksheet worksheet-settings worksheet-project-members">
            <HView>
                <VView grow>
                    <Header>{app.text('Project members')}</Header>
                    <ProjectMemberContextMenu.Panel
                        member={selectedMember}
                        onAction={onProjectMemberAction}
                    />
                    <InviteMemberPopover.Panel
                        onDone={onDoneInviteMember}
                        onCancel={onCancelInviteMember}
                    />
                    <Property label={app.text('members')}>
                        {Members}
                        {is_owner ? (
                            <Item
                                onClick={onShowInviteMemberPopover}
                                style={{ marginTop: 5 }}
                                ref={InviteMemberPopover.anchorRef}
                            >
                                <Icon className="itemtype" name="plus" size="text" />
                                <span className="cc-dimmed">
                                    {app.text('Invite a new member')}
                                </span>
                            </Item>
                        ) : undefined}
                    </Property>
                </VView>
                <Spacer size={20} />
                <VView grow></VView>
            </HView>
        </VView>
    )
})

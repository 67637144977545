import React from 'react'
import { createRoot } from 'react-dom/client'
import * as serviceWorker from './serviceWorker'
import './index.scss'
import { addKeyedEventsToLocalStorage } from './app/hooks/usePersistentState'
import { StoreProvider, createStore } from './app/stores'
import { PopoverProvider, PopoverStore } from './app/components'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { App } from './app/App'
import { configure } from 'mobx'
configure({ enforceActions: 'never' })

addKeyedEventsToLocalStorage()

const root = createRoot(document.getElementById('root'))

root.render(
    <StoreProvider value={createStore()}>
        <DndProvider backend={HTML5Backend}>
            <PopoverProvider value={{ store: new PopoverStore(), stack: [] }}>
                <App />
            </PopoverProvider>
        </DndProvider>
    </StoreProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

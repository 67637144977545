//
// gid()
//
// get a new unicat id, which is a uuid-v4
//
// maybe_gid(possible_gid)
//
// test if a string matches the gid (uuid-v4) format

import { v4 as uuidv4, version, validate } from 'uuid'

export const gid = uuidv4

export const maybe_gid = possible_gid => {
    return validate(possible_gid) && version(possible_gid) === 4
}

export const maybe_gid_regex =
    '[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}'

//
// DamPinboardStore
//
// The DamPinboardStore handles interaction with the pinboard area for pinning assets
// and asset-selections (multi-select).
// Should work with treeview and search results.
//
// SIDE-EFFECT: when a user selects an asset, we update the damworksheet with that
//              same asset
// TODO: remove side-effects
//
// A pinboard item can either be an asset or a selection:
//
// item {
//     gid: <gid>,
//     type: 'asset',
// }
//
// item {
//     gid: <gid>,
//     type: 'selection',
//     selection: [<assetgid>, <assetgid>, {'search':{…}}],
//     selectionsize: 17,
// }

import { makeObservable, observable, action, computed, runInAction } from 'mobx'
import { gid } from '../../utils/gid'

export class DamPinboardStore {
    __init = false
    _viewkey = null
    _rootstore = null

    _pinned = new Map() // keeps insertion order

    constructor(key, rootstore) {
        makeObservable(this, {
            __init: observable,
            _viewkey: observable,
            _rootstore: observable,
            _pinned: observable,

            loadOnce: action.bound,
            fetch: action.bound,
            refetch: action.bound,
            _fetch: action.bound,

            pinnedItems: computed,

            addAsset: action.bound,
            addSelection: action.bound,
            remove: action.bound,
            clear: action.bound,
            selectAsset: action.bound,
            syncdataitem_callback: action.bound,
        })

        this.MAX_ITEMS = 5

        this._viewkey = key
        this._rootstore = rootstore
    }

    get pinnedItems() {
        return Array.from(this._pinned.values())
    }

    loadOnce = () => {
        if (this.__init) return
        const pinned = this._rootstore.view.loadPerProject(
            this._viewkey + '/pinned',
            []
        )
        this.fetch(new Map(pinned))
        this._rootstore.api.register_syncdataitem_callback(this.syncdataitem_callback)
        this.__init = true
    }

    fetch(pinned) {
        this._rootstore.view.savePerProject(
            this._viewkey + '/pinned',
            Array.from(pinned.entries())
        )
        return this._fetch(pinned)
    }

    refetch() {
        return this._fetch(this._pinned)
    }

    _fetch(pinned) {
        if (!pinned.size) {
            this._pinned.clear()
            return
        }
        const assetgids = Array.from(pinned.values())
            .filter(item => item.type === 'asset')
            .map(item => item.gid)
        return this._rootstore
            ._fetch('/assets/get', {
                assets: assetgids,
            })
            .then(result => {
                runInAction(() => {
                    this._pinned.replace(pinned)
                })
                return result
            })
            .catch(error => {})
    }

    exists(itemgid) {
        return this._pinned.has(itemgid)
    }

    canAdd() {
        return this._pinned.size < this.MAX_ITEMS
    }

    addAsset(assetgid) {
        if (!assetgid) return
        if (this.exists(assetgid)) return // no duplicates
        if (!this.canAdd()) return
        this._pinned.set(assetgid, { gid: assetgid, type: 'asset' })
        this.fetch(this._pinned)
    }

    addSelection(selection, size) {
        if (!this.canAdd()) return
        const itemgid = gid()
        this._pinned.set(itemgid, { gid: itemgid, type: 'selection', selection, size })
        this.fetch(this._pinned)
    }

    remove(itemgid) {
        this._pinned.delete(itemgid)
        this.fetch(this._pinned)
    }

    clear() {
        this._pinned.clear()
        this.fetch(this._pinned)
    }

    selectAsset(asset) {
        this._rootstore.view.damworksheet.setAsset(asset)
    }

    syncdataitem_callback = (syncdataitem, data_before, data_after) => {
        // console.log('DamPinboardStore sync', syncdataitem, data_before, data_after)
        // we're only interested in data_type "assets"
        // then, we're only interested in deletions (of pinned ids)
        // from those records, only for assets that are in our store
        if (syncdataitem['data_type'] !== 'assets') return
        if (syncdataitem['action'] !== 'DELETE') return
        if (!data_before || !this.exists(data_before.gid)) return
        this.remove(data_before.gid)
    }
}

//
// AccountWorkspace
//
// Manage account settings and manage projects

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'
import { usePersistentState } from '../hooks/usePersistentState'
import { useTooltipPopover } from '../hooks/useTooltipPopover'

import { HView, VView, VScrollView, Spacer } from '../appview'
import { WorkspaceView } from './WorkspaceView'
import { Item, Bar, Button, Icon } from '../components'
import { Avatar, DeleteAccountPanel } from '../panels'

import { AccountSettingsWorksheet, ProjectManagementWorksheet } from './account'

export const AccountWorkspace = observer(function AccountWorkspace({
    viewkey,
    grow,
    ...props
}) {
    const store = useStore()
    const { app, view } = store

    const DeleteAccountPopover = useTooltipPopover(DeleteAccountPanel, {
        preferredPosition: 'right',
    })
    const onCancelDeleteAccount = () => {
        DeleteAccountPopover.hide()
    }
    const onConfirmDeleteAccount = confirm => {
        DeleteAccountPopover.hide()
        // actually delete the account!
        store.deleteAccount(confirm)
    }

    // the ui
    const [navigatorItem, setNavigatorItem] = usePersistentState(
        viewkey + '/navigatorItem',
        'account'
    )

    const Navigator = (
        <VView grow>
            <Item
                indent={0}
                selected={navigatorItem === 'account'}
                onClick={e => {
                    setNavigatorItem('account')
                }}
            >
                <Avatar user={view.user} />
                {view.user.displayname}
            </Item>
            <Item
                indent={0}
                selected={navigatorItem === 'projects'}
                onClick={e => {
                    setNavigatorItem('projects')
                }}
            >
                <Icon name="product" size={4} />
                {app.text('Project management')}
            </Item>
            <Spacer size={20} />
            <HView grow>
                <Spacer size={40} />
                <VView grow>
                    <Bar raised>
                        <Button
                            action
                            onClick={() => {
                                store.logout()
                            }}
                        >
                            {app.text('Log out')}
                        </Button>
                    </Bar>
                    <Spacer grow />
                    <DeleteAccountPopover.Panel
                        onConfirm={onConfirmDeleteAccount}
                        onCancel={onCancelDeleteAccount}
                    />
                    <Bar raised>
                        <Button
                            action
                            ref={DeleteAccountPopover.anchorRef}
                            onClick={e => DeleteAccountPopover.onShow(e)}
                        >
                            {app.text('Delete account')}
                        </Button>
                    </Bar>
                </VView>
                <Spacer size={40} />
            </HView>
            <Spacer size={20} />
        </VView>
    )

    const worksheet =
        navigatorItem === 'account' ? (
            <AccountSettingsWorksheet user={view.user} />
        ) : (
            <ProjectManagementWorksheet user={view.user} />
        )

    const Inspector =
        navigatorItem === 'account' ? <VView grow></VView> : <VView grow></VView>

    const NavigatorView = Navigator
    const WorksheetView = <VScrollView>{worksheet}</VScrollView>
    const InspectorView = Inspector

    return (
        <WorkspaceView
            grow={grow}
            className="workspace-account"
            viewkey={viewkey}
            minWorksheetWidth={640}
            navigator={NavigatorView}
            worksheet={WorksheetView}
            inspector={InspectorView}
        />
    )
})

//
// SelectClassPanel
//
// Select a class to use in a filter, by searching for a class.

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { VView, VScrollView, Spacer } from '../appview'
import {
    Header,
    SearchInput,
    Item,
    Property,
    Text,
    Menu,
    MenuItem,
} from '../components'
import { normalize } from '../utils/text'

export const SelectClassPanel = observer(function SelectClassPanel({
    selected,
    onSelectClass,
}) {
    const { app, data, view } = useStore()
    const worksheet = view.pimworksheet
    const language = worksheet.environment.get('language')

    const [searchtext, setSearchtext] = useState('')
    const [selecteditem, setSelecteditem] = useState(selected)
    let selection_in_results = false // initially

    let searchresultclasses = Array.from(data.classes.entries())
    searchresultclasses = searchresultclasses.filter(
        ([key, class_]) => !class_.is_working_copy
    )

    if (searchtext.length) {
        const searchwords = normalize(searchtext).split(' ')
        searchresultclasses = searchresultclasses.filter(([key, class_]) => {
            // do a match: in label and name, label only current language
            // first, normalize (validName)
            // second, split on spaces
            // then, match all words
            let classlabel = class_.label.get(language)
            if (!classlabel || !classlabel.length) {
                classlabel = class_.name
            }
            classlabel = normalize(classlabel)
            for (const word of searchwords) {
                if (!word.length) continue
                if (!classlabel.includes(word) && !class_.name.includes(word)) {
                    return false
                }
            }
            return true
        })
    }

    searchresultclasses.sort((a, b) => {
        const aclass = a[1]
        const bclass = b[1]
        let alabel = aclass.label.get(language)
        if (!alabel || !alabel.length) {
            alabel = aclass.name
        }
        alabel = normalize(alabel)
        let blabel = bclass.label.get(language)
        if (!blabel || !blabel.length) {
            blabel = bclass.name
        }
        blabel = normalize(blabel)
        if (alabel === blabel) return 0
        return alabel > blabel ? 1 : -1
    })

    const onSearchinputChange = event => {
        setSearchtext(event.target.value)
    }
    const onSearchinputBlur = event => {
        setSearchtext(event.target.value.trim())
    }

    const _onSelectClass = () => {
        onSelectClass && selecteditem && onSelectClass(selecteditem)
    }

    const searchresults = searchresultclasses.length ? (
        searchresultclasses.map(([key, class_]) => {
            let classname = class_.label.get(language)
            if (!classname || !classname.length) {
                classname = class_.name
            }
            if (selecteditem === key) selection_in_results = true
            return (
                <Item
                    key={key}
                    selected={selecteditem === key}
                    onClick={() => setSelecteditem(key)}
                    onDoubleClick={() => {
                        setSelecteditem(key)
                        _onSelectClass()
                    }}
                    title={classname}
                >
                    {classname}
                </Item>
            )
        })
    ) : (
        <div style={{ padding: '2px 5px' }}>{app.text('No matches')}</div>
    )

    const selectedclass = selection_in_results ? data.classes.get(selecteditem) : null
    let selectedclass_info
    if (selectedclass) {
        let classname = selectedclass.label.get(language)
        if (!classname || !classname.length) {
            classname = selectedclass.name
        }
        selectedclass_info = (
            <>
                <Property className="nobreak" label={app.text('name')}>
                    <Text className="user-select" title={selectedclass.name}>
                        {selectedclass.name}
                    </Text>
                </Property>
                <Property label={app.text('label')}>
                    <Text className="user-select" title={classname}>
                        {classname}
                    </Text>
                </Property>
            </>
        )
    }
    return (
        <VView className="actions-panel select-class-panel">
            <div className="inspector">
                <Header>{app.text('Select class')}</Header>
                <VView
                    style={{
                        marginLeft: 5,
                        marginRight: 5,
                        marginBottom: 2,
                    }}
                >
                    <SearchInput
                        value={searchtext}
                        placeholder={app.text('Search by name or label')}
                        onChange={onSearchinputChange}
                        onBlur={onSearchinputBlur}
                    />
                    <VScrollView
                        className="search-results"
                        style={{ backgroundColor: 'white' }}
                    >
                        {searchresults}
                    </VScrollView>
                    <Spacer size={5} />
                    {selectedclass_info}
                </VView>
            </div>
            <Menu>
                <MenuItem disabled={!selection_in_results} onClick={_onSelectClass}>
                    {selectedclass
                        ? app.text("Select class '{classname}'", {
                              classname: selectedclass.name,
                          })
                        : app.text('Select class')}
                </MenuItem>
            </Menu>
        </VView>
    )
})

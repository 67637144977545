//
// BooleanField
//
// A yes/no toggle.

import React from 'react'
import { observer } from 'mobx-react-lite'

import { Toggle } from '../../../../components'

export const BooleanField = observer(function BooleanField(props) {
    const {
        className,
        record,
        field,
        fromClass,
        component,
        enabled,
        force_readonly,
        renderkey,
        worksheet,
        ...other
    } = props

    let classes = 'cc-Field cc-BooleanField ws-text'
    if (!enabled) classes += ' cc-disabled'
    if (className) classes += ' ' + className

    const fieldvalue =
        record.localized_fields && record.localized_fields.get(field.name)

    const onToggle = newValue => {
        if (enabled) {
            record.setField(field, newValue)
            record.commitIfModified()
        }
    }

    return (
        <div className={classes} {...other}>
            <Toggle value={fieldvalue} onChange={onToggle} />
        </div>
    )
})

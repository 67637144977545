//
// TextListInput
//
// A list of text-inputs.

import React, { useState, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'
import { useMousePopover } from '../hooks/useMousePopover'
import { useClickOutside } from '../hooks/useClickOutside'
import { DragDropListRow } from '../dragdrop/DragDropListRow'

import { TextListMenu } from '../menus'

import { VALIDATION } from '../utils/validation'
import { ValidationMessage } from './ValidationMessage'
import {
    addIndexedListValue,
    replaceIndexedListValue,
    moveIndexedListValue,
    removeIndexedListValue,
} from '../utils/list'
import { keyboard } from '../utils/keyboard'

import { Icon, TextInput } from '../components'

export const TextListInput = observer(function TextListInput(props) {
    const {
        className,
        enabled,
        header,
        listvalue,
        language,
        renderkey,
        autoComplete,
        validateLine,
        validation,
        // implement either these
        onChange,
        onBlur,
        // or these, for more complicate list handling
        onAddItemBefore,
        onRemoveItem,
        onRemoveAllItems,
        onMoveItem,
        onUpdateItemAddAfter,
        onChangeItem,
        onBlurItem,
        // but not BOTH!
        // NOTE: for onChangeItem, you might get a multiline text (when pasting)
        // so you should handle that yourself. See SelectFieldOptions.jsx for example
        ...other
    } = props

    const { app } = useStore()
    const containerRef = useRef()

    const TextListContextMenu = useMousePopover(TextListMenu)
    const [focusItem, setFocusItem] = useState([null, 'end'])

    let classes = 'cc-Field cc-TextListInput ws-table'
    if (className) classes += ' ' + className

    if (validation) {
        if (validation.result === VALIDATION.ERROR) {
            classes += ' validation-error'
        } else if (validation.result === VALIDATION.REPORT) {
            classes += ' validation-report'
        }
    }

    const [selectedItemIndex, setSelectedItemIndex] = useState(null) // an index
    useClickOutside(containerRef, () => setSelectedItemIndex(null))

    const _onAddItemBefore = before_index => {
        onAddItemBefore && onAddItemBefore(before_index)
        const newValue = addIndexedListValue(listvalue, '', before_index)
        onBlur && onBlur(newValue)
        if (before_index === null) {
            setFocusItem([newValue.length - 1, 'start'])
        } else {
            setFocusItem([before_index, 'start'])
        }
    }

    const _onRemoveItem = index => {
        onRemoveItem && onRemoveItem(index)
        const newValue = removeIndexedListValue(listvalue, index)
        onBlur && onBlur(newValue)
        setFocusItem([index - 1, 'end'])
    }

    const _onRemoveAllItems = () => {
        onRemoveAllItems && onRemoveAllItems()
        const newValue = []
        onBlur && onBlur(newValue)
        setFocusItem([null, 'end'])
    }

    const onTextListAction = action => {
        const actions = {
            add_item: _onAddItemBefore,
            remove_item: _onRemoveItem,
            remove_all: _onRemoveAllItems,
        }
        if (!(action in actions)) {
            console.log(`onTextListAction: unhandled action '${action}'`)
            return
        }
        TextListContextMenu.hide()
        actions[action](selectedItemIndex)
    }

    const onDrop = (dragitem, item_index, isoverzone) => {
        const other_index = dragitem.id
        let before_index = null
        if (['top', 'left', 'inside'].includes(isoverzone)) {
            before_index = item_index
        } else {
            // before_index is record -after- item_index
            before_index = item_index + 1
        }
        onMoveItem && onMoveItem(other_index, before_index)
        const newValue = moveIndexedListValue(listvalue, other_index, before_index)
        onBlur && onBlur(newValue)
        setFocusItem([null, 'end'])
    }

    const _onUpdateItemAddAfter = (index, newItemValue) => {
        onUpdateItemAddAfter && onUpdateItemAddAfter(index, newItemValue)
        const newValue = addIndexedListValue(
            replaceIndexedListValue(listvalue, newItemValue, index),
            '',
            index + 1
        )
        onBlur && onBlur(newValue)
        setFocusItem([index + 1, 'start'])
    }

    const _onChangeItem = (index, newItemValue) => {
        // if you supplied onChangeItem, you must handle multiline paste yourself
        onChangeItem && onChangeItem(index, newItemValue)
        if (onChange) {
            const lines = newItemValue.trim('\n').split('\n')
            const thisline = lines.shift()
            let newValue = replaceIndexedListValue(listvalue, thisline, index)
            for (const line of lines) {
                index += 1
                newValue = addIndexedListValue(newValue, line, index)
            }
            if (lines.length > 1) {
                setFocusItem([index, 'end'])
            }
            onChange(newValue)
        }
    }

    const _onBlurItem = (index, newItemValue) => {
        onBlurItem && onBlurItem(index, newItemValue)
        const newValue = replaceIndexedListValue(listvalue, newItemValue, index)
        onBlur && onBlur(newValue)
    }

    const onShowTextListMenu = (e, index) => {
        if (index === null) {
            _onAddItemBefore(null)
            return
        }
        setSelectedItemIndex(index)
        TextListContextMenu.onShow(e)
    }

    const TableHeader = header ? (
        <tr>
            <td key={'action'} className="ws-table-cell ws-table-cell-_action_"></td>
            <td key={'translation'} className="ws-table-cell ws-table-cell-translation">
                {header}
            </td>
            <td key={'handle'} className="ws-table-cell ws-table-cell-_action_"></td>
        </tr>
    ) : undefined

    let TableRows
    if (listvalue.length) {
        TableRows = listvalue.map((line, index) => {
            let cellclasses = 'ws-table-cell ws-table-cell-text'
            const _onChange = event => {
                _onChangeItem(index, event.target.value)
            }
            const _onBlur = event => {
                _onBlurItem(index, event.target.value.trim())
            }
            const _onKeyDown = event => {
                if (keyboard.test(event, keyboard.ENTER)) {
                    _onUpdateItemAddAfter(index, event.target.value.trim())
                } else if (
                    (!event.target.value.length || event.target.value === '\n') &&
                    keyboard.test(event, keyboard.BACKSPACE)
                ) {
                    _onRemoveItem(index)
                }
            }
            const inputrenderkey = renderkey + index.toString()

            let Input = (
                <TextInput
                    multiline={false}
                    enabled={enabled}
                    setFocus={index === focusItem[0] ? focusItem[1] : false}
                    value={line}
                    language={language}
                    onChange={_onChange}
                    onBlur={_onBlur}
                    onKeyDown={_onKeyDown}
                    renderkey={inputrenderkey}
                    autoComplete={autoComplete}
                    validate={validateLine}
                    {...other}
                />
            )

            return (
                <DragDropListRow
                    key={'textlist.' + listvalue.length + '.' + index.toString()}
                    className={classes}
                    list_key={renderkey}
                    item_index={index}
                    direction="vertical"
                    disabled={!enabled}
                    onDrop={onDrop}
                >
                    <td
                        key={'action'}
                        className="ws-table-cell ws-table-cell-_action_"
                        onClick={e => onShowTextListMenu(e, index)}
                        onContextMenu={e => onShowTextListMenu(e, index)}
                    >
                        <Icon size="text" name="ellipsis" />
                    </td>
                    <td className={cellclasses}>{Input}</td>
                    <td key={'handle'} className="ws-table-cell ws-table-cell-_action_">
                        <Icon size={1} name="reorder" />
                    </td>
                </DragDropListRow>
            )
        })
    }

    if (enabled) {
        let classes = 'ws-table-cell cc-dimmed'
        const AddTableRow = (
            <tr key={'textlist.add'}>
                <td className={classes + ' ws-table-cell-_action_'}>
                    <Icon size={1} name="plus" onClick={e => _onAddItemBefore(null)} />
                </td>
                <td
                    colSpan={2}
                    className={classes + ' ws-table-cell-_add-text_'}
                    style={{ textAlign: 'left' }}
                >
                    <div onClick={e => _onAddItemBefore(null)}>
                        {app.text('Add new item')}
                    </div>
                </td>
            </tr>
        )

        if (!TableRows) {
            TableRows = AddTableRow
        } else {
            TableRows.push(AddTableRow)
        }
    }

    return (
        <div>
            <TextListContextMenu.Panel onAction={onTextListAction} />
            <div className={classes} {...other}>
                <table className="ws-table">
                    <thead>{TableHeader}</thead>
                    <tbody ref={containerRef}>{TableRows}</tbody>
                </table>
            </div>
            <ValidationMessage validation={validation} />
        </div>
    )
})

//
// LanguageData
//
// A plain LanguageData object to hold properties

import { makeObservable, observable, action } from 'mobx'

import { updateInstanceWithData } from './utils'

export class LanguageData {
    code = null
    name = ''

    constructor(languagedata) {
        makeObservable(this, {
            code: observable,
            name: observable,

            update: action.bound,
        })

        this.update(languagedata)
    }

    update = languagedata => {
        updateInstanceWithData(this, languagedata, ['code', 'name'])
    }
}

//
// Assets lab
//
// Show all available test assets with some transformations.

import React, { useState, useEffect } from 'react'

import { usePersistentState } from '../../hooks/usePersistentState'
import { useStore } from '../../stores'

import { VView, HView, Spacer } from '../../appview'
import { Item, Property, Select } from '../../components'

export const Assets = () => {
    const store = useStore()

    const files = [
        'landscape.jpg',
        'portrait.jpg',
        'square.jpg',
        'vector.svg',
        'transparent.png',
        'non-animated.gif',
        'postscript.eps',
        'document.pdf',
    ]

    const resize_options = {
        width: { resize: 'width', size: '400' },
        'width (fit)': { resize: 'width', size: '400,400' },
        height: { resize: 'height', size: '400' },
        'height (fit)': { resize: 'height', size: '400,400' },
        fill: { resize: 'fill', size: '400,400' },
        fit: { resize: 'fit', size: '400,400' },
        'fill (wide)': { resize: 'fill', size: '400,240' },
        'fit (wide)': { resize: 'fit', size: '400,240' },
        'fill (tall)': { resize: 'fill', size: '240,400' },
        'fit (tall)': { resize: 'fit', size: '240,400' },
    }

    const type_options = {
        jpg: { type: 'jpg' },
        png: { type: 'png' },
        gif: { type: 'gif' },
    }

    const background_options = {
        transparent: { bg: 'transparent' },
        white: { bg: 'white' },
        black: { bg: '000000' },
        lightblue: { bg: 'dbeaf6' },
    }

    const hotspot_options = {
        'top-left': { hs: '0,0' },
        top: { hs: '0.5,0' },
        'top-right': { hs: '1,0' },
        left: { hs: '0,0.5' },
        center: { hs: '0.5,0.5' },
        right: { hs: '1,0.5' },
        'bottom-left': { hs: '0,1' },
        bottom: { hs: '0.5,1' },
        'bottom-right': { hs: '1,1' },
    }

    const dpr_options = {
        '@1x': { dpr: '1' },
        '@2x': { dpr: '2' },
        '@3x': { dpr: '3' },
    }

    const quality_options = {
        low: { q: '40' },
        medium: { q: '65' },
        high: { q: '82' },
        max: { q: '100' },
    }

    const padding_options = {
        none: { pad: '0,0,0,0' },
        small: { pad: '10,10,10,10' },
        large: { pad: '50,50,50,50' },
        rightleft: { pad: '0,20,0,20' },
        '20,20,auto,20': { pad: '20,20,auto,20' },
        anchor_left: { pad: 'auto,auto,auto,0' },
        anchor_topleft: { pad: '0,auto,auto,0' },
    }

    const crop_options = {
        none: { crop: null },
        half: { crop: '0.6,0.6' },
        wide: { crop: '1,0.6' },
        tall: { crop: '0.6,1' },
    }

    /* ----- */

    const [file, setFile] = usePersistentState('Lab.Assets.File', 'landscape.jpg')

    const [resizeOption, setResizeOption] = usePersistentState(
        'Lab.Assets.Option.resize',
        'fit'
    )

    const [typeOption, setTypeOption] = usePersistentState(
        'Lab.Assets.Option.type',
        'jpg'
    )

    const [backgroundOption, setBackgroundOption] = usePersistentState(
        'Lab.Assets.Option.background',
        'transparent'
    )

    const [hotspotOption, setHotspotOption] = usePersistentState(
        'Lab.Assets.Option.hotspot',
        'center'
    )

    const [dprOption, setDprOption] = usePersistentState('Lab.Assets.Option.dpr', '@1x')

    const [qualityOption, setQualityOption] = usePersistentState(
        'Lab.Assets.Option.quality',
        'high'
    )

    const [paddingOption, setPaddingOption] = usePersistentState(
        'Lab.Assets.Option.padding',
        'small'
    )

    const [cropOption, setCropOption] = usePersistentState(
        'Lab.Assets.Option.crop',
        'none'
    )

    let preview_url =
        '/_labasset_-' + file + '/fit=400,400/type=jpg/dpr=2/key=labassetpreview@2x'
    // preview_url += "/force=true"

    const resize = resize_options[resizeOption]
    let transform_url =
        '/_labasset_-' + file + '/' + resize['resize'] + '=' + resize['size']
    if (type_options[typeOption]['type']) {
        transform_url += '/type=' + type_options[typeOption]['type']
    }
    if (background_options[backgroundOption]['bg']) {
        transform_url += '/bg=' + background_options[backgroundOption]['bg']
    }
    if (hotspot_options[hotspotOption]['hs']) {
        transform_url += '/hs=' + hotspot_options[hotspotOption]['hs']
    }
    if (dpr_options[dprOption]['dpr']) {
        transform_url += '/dpr=' + dpr_options[dprOption]['dpr']
    }
    if (quality_options[qualityOption]['q']) {
        transform_url += '/q=' + quality_options[qualityOption]['q']
    }
    if (padding_options[paddingOption]['pad']) {
        transform_url += '/pad=' + padding_options[paddingOption]['pad']
    }
    if (crop_options[cropOption]['crop']) {
        transform_url += '/crop=' + crop_options[cropOption]['crop']
    }
    transform_url += '/name=labasset'
    transform_url += '/key=transform'
    transform_url += '/force=true'

    const [previewUrl, setPreviewUrl] = useState(null)
    const [previewUrlRenderkey, setPreviewUrlRenderkey] = useState(Date.now())

    const [transformResult, setTransformResult] = useState([null, null])

    const [transformUrl, setTransformUrl] = useState(null)
    const [transformUrlRenderkey, setTransformUrlRenderkey] = useState(Date.now())
    const [transformDimensions, setTransformDimensions] = useState([400, 400])

    useEffect(() => {
        setPreviewUrl(null)
        store.dam.transform(preview_url).then(data => {
            setPreviewUrl(data['public_url'])
            setPreviewUrlRenderkey(Date.now())
        })
    }, [preview_url, store.dam])

    useEffect(() => {
        setTransformUrl(null)
        store.dam.transform(transform_url).then(data => {
            setTransformResult([transform_url, data])
        })
    }, [transform_url, store.dam])

    useEffect(() => {
        const url = transformResult[0]
        const data = transformResult[1]
        if (url && url.startsWith(`/_labasset_-${file}/`) && data) {
            setTransformUrl(data['public_url'])
            setTransformUrlRenderkey(Date.now())
            setTransformDimensions(data['dimensions'])
        }
    }, [file, transformResult])

    /* ----- */

    const Files = files.map(thisitem => {
        return (
            <Item
                key={thisitem}
                selected={file === thisitem}
                onClick={() => setFile(thisitem)}
            >
                {thisitem}
            </Item>
        )
    })

    // make selectable options for Select component
    const select_options = {
        resize_options,
        hotspot_options,
        crop_options,
        padding_options,
        background_options,
        quality_options,
        type_options,
        dpr_options,
    }
    const so = Object.keys(select_options).reduce(function (obj, key) {
        const rso = Object.keys(select_options[key]).reduce(function (subobj, subkey) {
            subobj[subkey] = subkey
            return subobj
        }, {})
        obj[key] = rso
        return obj
    }, {})

    const Transforms = (
        <>
            <Property label={'resize'}>
                <Select
                    value={resizeOption}
                    onChange={value => setResizeOption(value)}
                    options={so['resize_options']}
                />
            </Property>
            <Property label={'hotspot'}>
                <Select
                    value={hotspotOption}
                    onChange={value => setHotspotOption(value)}
                    options={so['hotspot_options']}
                />
            </Property>
            <Property label={'crop'}>
                <Select
                    value={cropOption}
                    onChange={value => setCropOption(value)}
                    options={so['crop_options']}
                />
            </Property>
            <Property label={'padding'}>
                <Select
                    value={paddingOption}
                    onChange={value => setPaddingOption(value)}
                    options={so['padding_options']}
                />
            </Property>
            <Property label={'background'}>
                <Select
                    value={backgroundOption}
                    onChange={value => setBackgroundOption(value)}
                    options={so['background_options']}
                />
            </Property>
            <Property label={'quality'}>
                <Select
                    value={qualityOption}
                    onChange={value => setQualityOption(value)}
                    options={so['quality_options']}
                />
            </Property>
            <Property label={'type'}>
                <Select
                    value={typeOption}
                    onChange={value => setTypeOption(value)}
                    options={so['type_options']}
                />
            </Property>
            <Property label={'dpr'}>
                <Select
                    value={dprOption}
                    onChange={value => setDprOption(value)}
                    options={so['dpr_options']}
                />
            </Property>
        </>
    )

    const transformWidth = transformDimensions[0]

    return (
        <VView grow>
            <HView style={{ padding: 20 }}>
                <VView className="inspector">
                    <div
                        style={{
                            padding: '5px 0',
                            width: 200,
                        }}
                    >
                        {Files}
                    </div>
                </VView>
                <Spacer size={20} />
                <div
                    style={{
                        width: 400,
                        minHeight: 400,
                        verticalAlign: 'top',
                        lineHeight: 0.1,
                    }}
                >
                    {previewUrl ? (
                        <img
                            className="background-squares"
                            src={'/media' + previewUrl + '?v=' + previewUrlRenderkey}
                            alt="preview"
                            width="400"
                        />
                    ) : (
                        <div style={{ padding: '5px 0', lineHeight: '1' }}>
                            working...
                        </div>
                    )}
                </div>
                <Spacer size={20} />
                <VView className="inspector">
                    <div
                        style={{
                            padding: '5px 0',
                            width: 200,
                        }}
                    >
                        {Transforms}
                    </div>
                </VView>
                <Spacer size={20} />
                <div
                    style={{
                        width: transformWidth,
                        minHeight: 400,
                        verticalAlign: 'top',
                        lineHeight: 0.1,
                    }}
                >
                    {transformUrl ? (
                        <img
                            className="background-squares"
                            src={
                                '/media' + transformUrl + '?v=' + transformUrlRenderkey
                            }
                            alt="transform"
                            width={transformWidth}
                        />
                    ) : (
                        <div style={{ padding: '5px 0', lineHeight: '1' }}>
                            working...
                        </div>
                    )}
                </div>
            </HView>
            <div style={{ padding: '0 20px 20px' }}>
                preview: {preview_url}
                <br />
                transform: {transform_url}
            </div>
        </VView>
    )
}

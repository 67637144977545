import * as ReactDOMServer from 'react-dom/server'
import { icons } from '../components/Icon'
import { htmlSafe } from '../utils/text'

export const iconsize = width => {
    const defaultsize = 3
    const intwidth = parseInt(width, 10)
    return width === 'auto'
        ? defaultsize
        : intwidth >= 88
        ? 80
        : intwidth >= 44
        ? 5
        : intwidth >= 32
        ? 4
        : intwidth >= 28
        ? 3
        : intwidth >= 24
        ? 2
        : intwidth >= 18
        ? 1
        : 1
}

export const svgsrc = svg => 'data:image/svg+xml;base64,' + btoa(svg)

export const iconsrc = (iconname, size) => {
    let classes = 'cc-Icon'
    if (size) classes += ' cc-icon-size' + size

    let pixels
    switch (size) {
        case 1:
            pixels = 18
            break
        case 2:
            pixels = 24
            break
        case 3:
            pixels = 28
            break
        case 4:
            pixels = 32
            break
        case 5:
            pixels = 44
            break
        case 80:
            pixels = 80
            break
        case 'text':
            pixels = 12
            break
        case 'fill':
            pixels = '100%'
            break
        default:
            pixels = 28
    }

    return svgsrc(
        '<svg preserveAspectRatio="xMidYMid meet" width="' +
            pixels +
            '" height="' +
            pixels +
            '" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg">' +
            `<style>
.cc-Icon path {
    fill: transparent;
    fill-rule: evenodd;
    stroke: #595963;
    stroke-width: 1;
    stroke-linecap: round;
    stroke-linejoin: round;
}
.cc-Icon path.cc-thin {
    stroke-width: 0.5;
}
.cc-Icon path.cc-hair {
    stroke-width: 0.5;
}
.cc-Icon path.cc-fill {
    fill: #97979d;
}
.cc-Icon.cc-icon-sizetext path {
    stroke-width: 1;
}
.cc-Icon.cc-icon-sizetext path.cc-thin {
    stroke-width: 0.5;
}
.cc-Icon.cc-icon-sizetext path.cc-hair {
    stroke-width: 0.3;
}
.cc-Icon.cc-icon-size1 path {
    stroke-width: 1;
}
.cc-Icon.cc-icon-size1 path.cc-thin {
    stroke-width: 0.5;
}
.cc-Icon.cc-icon-size1 path.cc-hair {
    stroke-width: 0.5;
}
.cc-Icon.cc-icon-size80 path {
    stroke-width: 3;
}
.cc-Icon.cc-icon-size80 path.cc-thin {
    stroke-width: 1;
}
.cc-Icon.cc-icon-size80 path.cc-hair {
    stroke-width: 0.5;
}
.cc-dimmed .cc-Icon path {
    stroke: #c0c0c4;
}
.cc-dimmed .cc-Icon path.cc-fill {
    fill: #dfdfe1;
}

@media (max-resolution: 120dpi) {
    .cc-Icon .cc-hi {
        display: none;
    }
}
@media (min-resolution: 121dpi) {
    .cc-Icon .cc-lo {
        display: none;
    }
}
.cc-Icon.cc-icon-sizetext .cc-min-1,
.cc-Icon.cc-icon-sizetext .cc-min-2,
.cc-Icon.cc-icon-size1 .cc-min-2,
.cc-Icon.cc-icon-sizetext .cc-min-3,
.cc-Icon.cc-icon-size1 .cc-min-3,
.cc-Icon.cc-icon-size2 .cc-min-3,
.cc-Icon.cc-icon-sizetext .cc-min-4,
.cc-Icon.cc-icon-size1 .cc-min-4,
.cc-Icon.cc-icon-size2 .cc-min-4,
.cc-Icon.cc-icon-size3 .cc-min-4 {
    display: none;
}</style><g class="` +
            classes +
            `">` +
            ReactDOMServer.renderToStaticMarkup(icons[iconname]) +
            '</g></svg>'
    )
}

export const selectionsrc = text => {
    return svgsrc(
        '<svg preserveAspectRatio="xMidYMid meet" width="120" height="24" viewBox="0 0 400 80" xmlns="http://www.w3.org/2000/svg">' +
            `<style>
                .cc-Icon text {
                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    font-size: 40px;
                }
                .cc-Icon path {
                    fill: transparent;
                    fill-rule: evenodd;
                    stroke: #595963;
                    stroke-width: 1;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                }
                .cc-Icon path.cc-thin {
                    stroke-width: 0.5;
                }
                @media (max-resolution: 120dpi) {
                    .cc-Icon .cc-hi {
                        display: none;
                    }
                }
                @media (min-resolution: 121dpi) {
                    .cc-Icon .cc-lo {
                        display: none;
                    }
                }
                .cc-Icon.cc-icon-size2 .cc-min-3,
                .cc-Icon.cc-icon-size2 .cc-min-4 {
                    display: none;
                }
            </style>
            <g class="cc-Icon cc-icon-size2">` +
            ReactDOMServer.renderToStaticMarkup(icons['selection']) +
            '<text x="90" y="54">' +
            htmlSafe(text) +
            '</text>' +
            '</g></svg>'
    )
}

// helpers for newline-separated textfields that represent lists
// these are value-based, so duplicates aren't really allowed/possible
// unless the index-based version is also provided (todo...)

export const listFromText = (text, options) => {
    return text && text.length
        ? text
              .trim()
              .split('\n')
              .map(line => line.trim())
              .filter(line => (options && options.empty_lines ? true : line.length > 0))
        : []
}

export const textFromList = list => {
    return list.map(line => line.toString()).join('\n')
}

export const addListValue = (list, value, before_value) => {
    let updatedlist = []
    for (const line of list) {
        if (line === before_value) {
            updatedlist.push(value)
        }
        updatedlist.push(line)
    }
    if (before_value === undefined || list.length + 1 !== updatedlist.length) {
        updatedlist.push(value)
    }
    return updatedlist
}

export const replaceListValue = (list, value, value_to_replace) => {
    let updatedlist = []
    for (const line of list) {
        if (line === value_to_replace) {
            updatedlist.push(value)
        } else {
            updatedlist.push(line)
        }
    }
    return updatedlist
}

export const moveListValue = (list, value, before_value) => {
    let updatedlist = []
    for (const line of list) {
        if (line === value) continue
        if (line === before_value) {
            updatedlist.push(value)
        }
        updatedlist.push(line)
    }
    if (before_value === undefined || list.length !== updatedlist.length) {
        updatedlist.push(value)
    }
    return updatedlist
}

export const removeListValue = (list, value) => {
    let updatedlist = []
    for (const line of list) {
        if (line !== value) {
            updatedlist.push(line)
        }
    }
    return updatedlist
}

export const deduplicateList = list => {
    return list.filter((value, index, self) => {
        return self.indexOf(value) === index
    })
}

export const addIndexedListValue = (list, value, before_index) => {
    let updatedlist = []
    if (list) {
        list.forEach((line, index) => {
            if (index === before_index) {
                updatedlist.push(value)
            }
            updatedlist.push(line)
        })
    }
    if (before_index === undefined || !list || list.length + 1 !== updatedlist.length) {
        updatedlist.push(value)
    }
    return updatedlist
}

export const replaceIndexedListValue = (list, value, at_index) => {
    let updatedlist = []
    if (list) {
        list.forEach((line, index) => {
            if (index === at_index) {
                updatedlist.push(value)
            } else {
                updatedlist.push(line)
            }
        })
    }
    return updatedlist
}

export const moveIndexedListValue = (list, move_index, before_index) => {
    let updatedlist = []
    if (!list) return updatedlist
    const value = list[move_index]
    list.forEach((line, index) => {
        if (index === move_index) return
        if (index === before_index) {
            updatedlist.push(value)
        }
        updatedlist.push(line)
    })
    if (before_index === undefined || list.length !== updatedlist.length) {
        updatedlist.push(value)
    }
    return updatedlist
}

export const removeIndexedListValue = (list, remove_index) => {
    let updatedlist = []
    if (!list) return list
    list.forEach((line, index) => {
        if (index !== remove_index) {
            updatedlist.push(line)
        }
    })
    return updatedlist
}

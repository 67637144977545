//
// UserData
//
// A plain UserData object to hold properties

import { makeObservable, observable, computed, action } from 'mobx'

import { updateInstanceWithData } from './utils'
import { initialsFromName, initialsFromEmail } from '../../utils/text'

export class UserData {
    gid = null
    email = ''
    name = ''
    avatar = ''
    status = ''
    options = new Map()

    _modifications = new Set()

    constructor(userdata, rootstore) {
        makeObservable(this, {
            email: observable,
            name: observable,
            avatar: observable,
            status: observable,
            options: observable,

            initials: computed,
            displayname: computed,

            update: action.bound,
            setName: action.bound,
            setUsername: action.bound,
            commitPassword: action.bound,
            updateEmailRequest: action.bound,
            updateEmail: action.bound,
            commitIfModified: action.bound,
        })

        this._rootstore = rootstore
        this.update(userdata)
    }

    update = userdata => {
        updateInstanceWithData(this, userdata, [
            'gid',
            'username',
            'email',
            'name',
            'avatar',
            'status',
            'options',
        ])
    }

    get initials() {
        return this.name
            ? initialsFromName(this.name)
            : this.email
            ? initialsFromEmail(this.email)
            : '?'
    }

    get displayname() {
        return this.name ? this.name : this.email ? this.email.split('@')[0] : '?'
    }

    setName = value => {
        const previous_value = this.name
        if (previous_value === value) return false
        this.name = value
        this._modifications.add('name')
        return true
    }

    setUsername = value => {
        const previous_value = this.username
        if (previous_value === value) return false
        this.username = value
        this._modifications.add('username')
        return true
    }

    commitPassword = new_password => {
        // basically fire & forget updates
        return this._rootstore._globalfetch('/account/update', {
            password: new_password,
        })
    }

    updateEmailRequest = email => {
        return this._rootstore._globalfetch('/account/update_email_request', {
            email,
        })
    }

    updateEmail = (email, validation_code, verification_code) => {
        return this._rootstore._globalfetch('/account/update_email', {
            email,
            validation_code,
            verification_code,
        })
    }

    commitIfModified = () => {
        // send gid + modified fields to server backend
        if (!this._modifications.size) {
            return Promise.resolve({ user: this.gid })
        }

        let updateparams = {}
        for (const fieldname of this._modifications.keys()) {
            updateparams[fieldname] = this[fieldname]
        }
        // basically fire & forget updates
        return this._rootstore
            ._globalfetch('/account/update', updateparams)
            .then(result => {
                this._modifications = new Set()
                return result
            })
    }
}

//
// FileUpload
//
// Off-screen file upload field.
//
// Usage: see ../hooks/useFileUpload

import React from 'react'

export const FileUpload = React.forwardRef(
    ({ className, onFileChange, multiple, ...props }, ref) => {
        const onChange = event => {
            const file_or_files = multiple ? event.target.files : event.target.files[0]
            onFileChange && onFileChange(file_or_files)
        }

        let classes = 'cc-FileUpload'
        if (className) classes += ' ' + className

        return (
            <input
                ref={ref}
                className={classes}
                {...props}
                type="file"
                onChange={onChange}
                multiple={!!multiple}
            />
        )
    }
)

//
// SchemaWorkspace
//
// Manage PIM Definitions, Classes, Fields, and Layouts, we use a navigator/
// worksheet/inspector setup.

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useDrop } from 'react-dnd'
import { useStore } from '../stores'
import { usePersistentState } from '../hooks/usePersistentState'

import { DragTypes } from '../dragdrop/DragTypes'

import { VView, HView, Spacer, Divider, VScrollView } from '../appview'
import { WorkspaceView } from './WorkspaceView'
import { Button, Icon } from '../components'
import { EnvironmentPanel, SchemaPinboardPanel, SchemaSearchPanel } from '../panels'
import { TreeNavigator, SchemaWorksheet, SchemaInspector } from './schema'
import { maxChars } from '../utils/text'

export const SchemaWorkspace = observer(function SchemaWorkspace({
    user,
    project,
    viewkey,
    grow,
    ...props
}) {
    const { app, view } = useStore()

    const sep = (
        <span key="_" className="breadcrumbs-separator">
            {app.text('›')}
        </span>
    )

    const tree = view.schematree
    const worksheet = view.schemaworksheet
    const language = view.environment.get('language')
    const schemaquery = view.schemaquery

    const [pinboardVisible, setPinboardVisible] = usePersistentState(
        viewkey + '/pinboardVisible',
        true
    )

    const [navigatorTab, setNavigatorTab] = usePersistentState(
        viewkey + '/navigatorTab',
        'tree'
    )

    let NavigationToolbar
    NavigationToolbar = (
        <>
            <Button
                icon
                active={navigatorTab === 'tree' ? true : false}
                onClick={() => setNavigatorTab('tree')}
                title={app.text('Tree')}
            >
                <Icon name="tree" size={1} />
            </Button>
            <Button
                icon
                active={navigatorTab === 'search' ? true : false}
                onClick={() => setNavigatorTab('search')}
                title={app.text('Search')}
            >
                <Icon name="search" size={1} />
            </Button>
        </>
    )

    const onSelect = itemgid => {
        const selectedItem = worksheet.getItem(itemgid)
        if (selectedItem) {
            worksheet.setItem(selectedItem)
        }
    }

    let Navigator
    switch (navigatorTab) {
        case 'tree':
            Navigator = <TreeNavigator tree={tree} worksheet={worksheet} />
            break
        case 'search':
            Navigator = (
                <SchemaSearchPanel
                    querystore={schemaquery}
                    preview={false}
                    showMatches={true}
                    multiselect={true}
                    onSelect={onSelect}
                    selectedSchemaGid={worksheet.item ? worksheet.item.gid : null}
                    style={{ marginTop: 5 }}
                />
            )
            break
        default:
            Navigator = <VView grow />
            break
    }

    let WorksheetToolbar
    if (worksheet.item) {
        let title = worksheet.layoutstore.getItemTitle(worksheet.item, language)
        title += ' - ' + worksheet.item.name
        WorksheetToolbar = (
            <>
                <span>{worksheet.datatypeTexts[worksheet.datatype]}</span>
                {sep}
                <span>{maxChars(title, 128)}</span>
            </>
        )
    }

    let InspectorToolbar = <span>Actions</span>
    if (worksheet.item) {
        const title = worksheet.layoutstore.getItemTitle(worksheet.item, language)
        InspectorToolbar = <span>{maxChars(title, 30)}</span>
    }

    const [
        { isOver: isOverPinboardToggle, canDrop: canDropPinboardToggle },
        dropPinboardToggle,
    ] = useDrop({
        accept: [DragTypes.PIM_DEFINITION, DragTypes.PIM_CLASS, DragTypes.PIM_FIELD],
        canDrop: () => view.schemapinboard.canAdd(),
        collect: monitor => {
            return {
                isOver: !!monitor.isOver(),
                canDrop: !!monitor.canDrop(),
            }
        },
        drop: ({ id }) => {
            view.schemapinboard.add(id)
            setPinboardVisible(true)
            return { receiver: 'PinboardToggle' }
        },
    })

    const Pinboard = pinboardVisible ? (
        <>
            <Divider />
            <SchemaPinboardPanel
                pinboard={view.schemapinboard}
                selectedItem={worksheet.item}
            />
        </>
    ) : undefined

    let classesPinboardToggle = 'pinboardtoggle-drop'
    if (isOverPinboardToggle) {
        classesPinboardToggle += ' drag-over'
        if (!canDropPinboardToggle) classesPinboardToggle += ' drop-rejected'
    }
    if (!pinboardVisible && !view.schemapinboard.canAdd()) {
        classesPinboardToggle += ' pinboard-full'
    }

    const PinboardToggle = (
        <div className={classesPinboardToggle} ref={dropPinboardToggle}>
            <Button
                icon
                active={pinboardVisible}
                title={app.text(
                    pinboardVisible
                        ? 'Hide schema pinboard panel'
                        : 'Show schema pinboard panel'
                )}
                onClick={() => setPinboardVisible(!pinboardVisible)}
            >
                <Icon name="schema" size={1} />
                <Icon name="pin" size={1} />
            </Button>
        </div>
    )

    const NavigatorView = (
        <>
            <HView className="toolbar">{NavigationToolbar}</HView>
            <Divider />
            {Navigator}
            {Pinboard}
        </>
    )
    const WorksheetView = (
        <>
            <HView className="toolbar">{WorksheetToolbar}</HView>
            <Divider />
            <VScrollView>
                <SchemaWorksheet worksheet={worksheet} />
            </VScrollView>
        </>
    )
    const InspectorView = (
        <>
            <div className="toolbar">{InspectorToolbar}</div>
            <Divider />
            <VScrollView>
                <SchemaInspector tree={tree} worksheet={worksheet} />
            </VScrollView>
        </>
    )
    const StatusbarView = (
        <>
            {PinboardToggle}
            <Spacer size={15} />
            <EnvironmentPanel
                project={project}
                languageOnly
                environment={view.environment}
                updateEnvironment={view.updateEnvironment}
            />
        </>
    )

    return (
        <WorkspaceView
            grow={grow}
            className="workspace-schema"
            viewkey={viewkey}
            navigator={NavigatorView}
            worksheet={WorksheetView}
            inspector={InspectorView}
            statusbar={StatusbarView}
        />
    )
})

//
// RecordWorksheet
//
// Switch to a specific worksheet based on worksheet record def

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../stores'

import { View } from '../../appview'

import { LayoutBuilder } from './worksheets'

export const RecordWorksheet = observer(function RecordWorksheet({ worksheet }) {
    const { app } = useStore()

    let RecordWorksheetLayout = app.text('No record selected.')
    if (worksheet.record) {
        const definition = worksheet.definitions.get(worksheet.record.definition)
        if (!definition) {
            RecordWorksheetLayout = app.text('Definition not found.')
        } else {
            const layout = worksheet.layouts.get(definition.layout)
            if (layout) {
                RecordWorksheetLayout = (
                    <LayoutBuilder
                        component={layout.components.get(layout.root)}
                        components={layout.components}
                        layout={layout}
                        record={worksheet.record}
                        worksheet={worksheet}
                    />
                )
            } else {
                RecordWorksheetLayout = app.text('No layout assigned.')
            }
        }
    }

    return <View className="worksheet">{RecordWorksheetLayout}</View>
})

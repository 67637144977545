//
// TableLayoutBuilder
//
// Based on view info from a layout

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'

import { Icon, Text } from '../../../../components'
import { TypedField } from '.'
import { LAYOUTCOMPONENTTYPECONFIG } from '../../../../stores/data/LAYOUTCOMPONENTTYPECONFIG'

const MAGIC_LIMIT_BEFORE_IMPLEMENTING_SCROLLING = 21

export const TableLayoutBuilder = observer(function TableLayoutBuilder({
    className,
    component,
    components,
    layout,
    record,
    worksheet,
    active_class,
    visited_gids,
}) {
    const store = useStore()
    const { app } = useStore()

    const config = LAYOUTCOMPONENTTYPECONFIG['table']
    const language = worksheet.environment.get('language')

    const childrecords = [{ gid: 1 }, { gid: 2 }, { gid: 3 }] //worksheet.children

    const name = component.name ? component.name : component.type
    const placeholder = component.placeholder
    const localizedPlaceholder = placeholder && placeholder[store.appLanguage]
    const editable =
        'editable' in component ? component.editable : config.editable.default
    const readonlyIcon = editable ? undefined : (
        <Icon className="cc-inline" name="lock" size={'text'} />
    )
    const navigation =
        'navigation' in component ? component.navigation : config.navigation.default
    const navigationIcon = navigation ? (
        <Icon className="cc-inline" name="collapsed" size={'text'} />
    ) : undefined

    let Table
    if (childrecords && childrecords.length) {
        const contentcomponents =
            component.content && component.content.length
                ? component.content.map(subelement_gid =>
                      components.get(subelement_gid)
                  )
                : []
        const fieldlikecomponents = contentcomponents.filter(
            cc => cc && ['field', 'fieldlist'].includes(cc.type)
        )
        let fieldnames = []
        let field_duckcomponents = {}
        fieldlikecomponents.map((fieldlikecomponent, index) => {
            if (fieldlikecomponent.type === 'field') {
                fieldnames.push(fieldlikecomponent.field)
                field_duckcomponents[fieldlikecomponent.field] = {
                    // for previews, don't propagate not-editable to prevent many lock icons
                    editable: !editable
                        ? true
                        : 'editable' in fieldlikecomponent
                        ? fieldlikecomponent.editable
                        : LAYOUTCOMPONENTTYPECONFIG['field'].editable.default,
                    style: fieldlikecomponent.style,
                    columns: fieldlikecomponent.columns,
                }
            } else if (fieldlikecomponent.type === 'fieldlist') {
                const fieldlist = fieldlikecomponent.fieldlist || '{fieldlist}'
                const fieldlistfields = worksheet.findFields(
                    // childdefinition,
                    [fieldlist]
                )
                fieldnames.push(
                    ...fieldlistfields.map(field => {
                        field_duckcomponents[field.gid] = {
                            // for previews, don't propagate not-editable to prevent many lock icons
                            editable: !editable
                                ? true
                                : 'editable' in fieldlikecomponent
                                ? fieldlikecomponent.editable
                                : LAYOUTCOMPONENTTYPECONFIG['fieldlist'].editable
                                      .default,
                            style: fieldlikecomponent.style,
                            columns: fieldlikecomponent.columns,
                        }
                        return field.name
                    })
                )
            }
            return true
        })
        const fields = worksheet.findFields(fieldnames)

        const firstHeadercell = navigation ? (
            <td className="ws-table-cell ws-table-cell-navigator"></td>
        ) : undefined
        const Headercells = fields.map(field => {
            let classes = 'ws-table-cell ws-table-cell-' + field.name
            // if (field.gid === worksheet.selected.property.fieldgid) {
            //     classes = classes + " ws-selected"
            // }
            const label = (field.label && field.label.get(language)) || field.name

            return (
                <td key={'field' + field.gid} className={classes}>
                    <Text>{label}</Text>
                </td>
            )
        })
        const Header = (
            <tr className="ws-table-header">
                {firstHeadercell}
                {Headercells}
            </tr>
        )
        const Rows = childrecords.map((childrecord, index) => {
            if (index === MAGIC_LIMIT_BEFORE_IMPLEMENTING_SCROLLING - 1) return null
            const firstCell = navigation ? (
                <td className="ws-table-cell ws-table-cell-navigator">
                    <Icon name={'collapsed'} size={'text'} />
                </td>
            ) : undefined
            const Cells = fields.map(field => {
                const value = 'x' //childrecord.fields.get("" + field.name)
                let classes =
                    'ws-table-cell ws-table-cell-' + field.name + ' layout-component'
                if (field.gid in field_duckcomponents) {
                    classes += ' lc-' + field_duckcomponents[field.gid]['style']
                }
                const editable =
                    field.gid in field_duckcomponents
                        ? field_duckcomponents[field.gid]['editable']
                        : false
                const Field =
                    value === undefined ? (
                        <Text>
                            {store.app.text("Undefined field '{fieldname}'", {
                                fieldname: field.name,
                            })}
                        </Text>
                    ) : (
                        <TypedField
                            field={field}
                            fromClass={null}
                            component={field_duckcomponents[field.gid]}
                            editable={editable}
                            record={childrecord}
                            worksheet={worksheet}
                            active_class={active_class}
                        />
                    )
                return (
                    <td key={'field' + field.gid} className={classes}>
                        {Field}
                    </td>
                )
            })
            let rowclasses = 'ws-table-row'
            // if (childrecord.gid === worksheet.selected.record.gid) {
            //     rowclasses = rowclasses + " ws-selected"
            // }
            return (
                <tr className={rowclasses} key={childrecord.gid}>
                    {firstCell}
                    {Cells}
                </tr>
            )
        })
        Table = (
            <table className="ws-table">
                <tbody>
                    {Header}
                    {Rows}
                </tbody>
            </table>
        )
    }

    const More =
        childrecords.length === MAGIC_LIMIT_BEFORE_IMPLEMENTING_SCROLLING ? (
            <Text>
                {app.text(
                    'There are more children than can be shown efficiently (for now). Please access them through the treeview.'
                )}
            </Text>
        ) : undefined

    const Placeholder = localizedPlaceholder ? (
        <div className="ws-placeholder">{localizedPlaceholder}</div>
    ) : undefined

    let attributes = {
        className: 'layout-component ws-record-navigator ws-table ws-layoutpreview',
    }
    if (className) attributes['className'] += ' ' + className
    if (component.gid === worksheet.layoutstore.selectedComponentGid) {
        attributes['className'] += ' cc-selected'
    }
    // attributes["onClick"] = e => onClickElement(e, component, layouttreestore)

    if (!Table) {
        attributes['className'] += ' ws-placeholder-visible'
        Table = (
            <div>
                <br />
            </div>
        )
    }

    return (
        <div {...attributes}>
            {name}
            {readonlyIcon}
            {navigationIcon}
            <br />
            {Table}
            {More}
            {Placeholder}
        </div>
    )
})

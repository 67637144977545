//
// UnicatDam
//
// Connect to the DAM backend for image transformation

import { makeObservable, action } from 'mobx'

const MAX_CACHE = 1000

class UnicatDam {
    _baseurl
    _projectgid
    _jwt

    constructor(baseurl, projectgid, jwt) {
        makeObservable(this, {
            globaltransform: action.bound,
            transform: action.bound,
        })

        this._baseurl = baseurl
        this._projectgid = projectgid
        this._jwt = jwt
        this._transformcache = new Map()
    }

    async _transform(url_with_options, optional_projectgid, additional_fetch_options) {
        const url =
            this._baseurl +
            (optional_projectgid ? '/p/' + optional_projectgid : '/cc') +
            '/transform' +
            (url_with_options.startsWith('/') ? '' : '/') +
            url_with_options
        if (this._transformcache.has(url)) {
            return Promise.resolve(this._transformcache.get(url))
        }
        let options = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }
        if (this._jwt.hasToken) {
            options.headers['Authorization'] = 'Bearer ' + this._jwt.token
        }
        if (additional_fetch_options) {
            options = { ...options, ...additional_fetch_options }
        }
        // console.log('dam.transform', url, {
        //     method: options.method,
        //     headers: options.headers,
        // })
        return window
            .fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.status + ' ' + response.statusText)
                }
                return response.json()
            })
            .then(response => {
                if (!response['success']) {
                    throw new Error(
                        response['result']['code'] + ' ' + response['result']['message']
                    )
                }
                // console.log('dam.transform', url, response['result'])
                if (this._transformcache.size > MAX_CACHE) {
                    this._transformcache.clear() // TODO: CC-207 use an LFU solution?
                }
                this._transformcache.set(url, response['result'])
                return response['result']
            })
            .catch(error => {
                if (error === 'Unmounted' || error.name === 'AbortError') {
                    // do nothing, this is working as intended
                } else {
                    throw new Error(error)
                }
            })
    }

    async globaltransform(url_with_options, additional_fetch_options) {
        return this._transform(url_with_options, undefined, additional_fetch_options)
    }

    async transform(url_with_options, additional_fetch_options) {
        if (!this._projectgid) {
            return Promise.resolve(false)
        }
        return this._transform(
            url_with_options,
            this._projectgid,
            additional_fetch_options
        )
    }

    async _publish(asset_url, optional_projectgid, additional_fetch_options) {
        const url =
            this._baseurl +
            (optional_projectgid ? '/p/' + optional_projectgid : '/cc') +
            '/publish' +
            (asset_url.startsWith('/') ? '' : '/') +
            asset_url
        let options = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }
        if (this._jwt.hasToken) {
            options.headers['Authorization'] = 'Bearer ' + this._jwt.token
        }
        if (additional_fetch_options) {
            options = { ...options, ...additional_fetch_options }
        }
        return window
            .fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.status + ' ' + response.statusText)
                }
                return response.json()
            })
            .then(response => {
                if (!response['success']) {
                    throw new Error(
                        response['result']['code'] + ' ' + response['result']['message']
                    )
                }
                return response['result']
            })
            .catch(error => {
                if (error === 'Unmounted' || error.name === 'AbortError') {
                    // do nothing, this is working as intended
                } else {
                    throw new Error(error)
                }
            })
    }

    async globalpublish(asset_url, additional_fetch_options) {
        return this._publish(asset_url, undefined, additional_fetch_options)
    }

    async publish(asset_url, additional_fetch_options) {
        if (!this._projectgid) {
            return Promise.resolve(false)
        }
        return this._publish(asset_url, this._projectgid, additional_fetch_options)
    }
}

export default UnicatDam

//
// AssetInspector
//
// Switch to a specific inspector based on worksheet asset

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../stores'

import { View } from '../../appview'
import { FolderInspector, FileInspector } from './inspectors'

export const AssetInspector = observer(function AssetInspector({ worksheet }) {
    const { app } = useStore()

    let Inspector = app.text('')
    if (worksheet.asset) {
        if (worksheet.asset.is_file) {
            Inspector = <FileInspector worksheet={worksheet} />
        } else {
            Inspector = <FolderInspector worksheet={worksheet} />
        }
    }

    return <View className="panel properties">{Inspector}</View>
})

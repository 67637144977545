//
// DeletedLinkedTreeRecordMenu
//
// Actions on deleted linked records in the TreeNavigator
// Only feed it deleted, linked records.

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Menu, MenuDivider, MenuItem } from '../components'

export const DeletedLinkedTreeRecordMenu = observer(
    function DeletedLinkedTreeRecordMenu({ record, onAction }) {
        const { app, data } = useStore()

        if (!record) return null

        // can't undelete when parent is still deleted
        const parentrecord = data.records.get(record.parent)
        const disableUndelete = parentrecord && parentrecord.status === 'deleted'

        const _onAction = action => {
            onAction && onAction(action)
        }

        return (
            <Menu>
                <MenuItem onClick={() => _onAction('goto_linked_record')}>
                    {app.text('Go to link source')}
                </MenuItem>
                <MenuItem onClick={() => _onAction('unlink_record')}>
                    {app.text('Unlink')}
                </MenuItem>
                <MenuDivider />
                <MenuItem
                    disabled={disableUndelete}
                    onClick={() => _onAction('undelete')}
                >
                    {app.text('Undelete')}
                </MenuItem>
                <MenuDivider />
                <MenuItem
                    className="cc-danger"
                    onClick={() => _onAction('permanent_delete')}
                >
                    {app.text('Permanent delete')}
                </MenuItem>
            </Menu>
        )
    }
)

//
// TilesClassListField
//
// An implementation for ClassListField.

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'
import { ClasslistFieldRecords } from '../../../../stores/data/ClasslistFieldRecords'
import { useElementWidth } from '../../../../hooks/useElementWidth'
import { useMousePopover } from '../../../../hooks/useMousePopover'
import { useClickOutside } from '../../../../hooks/useClickOutside'
import { DragDropClasslistFieldItem } from '../../../../dragdrop/DragDropClasslistFieldItem'

import { TilesView, LayerView, Layer } from '../../../../appview'
import { IconTile } from '../../../../panels'
import { ClassListFieldMenu } from '../../../../menus'
import { LAYOUTCOMPONENTTYPECONFIG } from '../../../../stores/data/LAYOUTCOMPONENTTYPECONFIG'

import { VALIDATION } from '../../../../utils/validation'
import { validateField } from '../../../../stores/data/validators'
import { ValidationMessage } from '../../../../components'

import { Text, Icon } from '../../../../components'
import { LayoutBuilder } from '..'

const MAGIC_WIDTH = { table: 2000, tiles: 200 }
const MAGIC_GAP = 15

export const TilesClassListField = observer(function TilesClassListField(props) {
    const {
        className,
        record,
        field,
        enabled,
        force_readonly,
        fromClass,
        component,
        renderkey,
        worksheet,
        propertytable,
        ...other
    } = props

    const { data, app } = useStore()
    const [containerRef, containerWidth] = useElementWidth(250)

    const ClassListFieldContextMenu = useMousePopover(ClassListFieldMenu)

    let classes = 'cc-Field cc-ClassListField ws-tiles'
    if (!enabled) classes += ' cc-disabled'
    if (className) classes += ' ' + className

    const fieldvalue =
        (record.localized_fields && record.localized_fields.get(field.name)) || []
    const classlistfieldvalue = new ClasslistFieldRecords(record, field, fieldvalue)

    const validation = validateField(fieldvalue, field, record.language)
    if (validation.result === VALIDATION.ERROR) {
        classes += ' validation-error'
    } else if (validation.result === VALIDATION.REPORT) {
        classes += ' validation-report'
    }

    const class_gid = field ? field.options.get('class') : undefined
    const class_ = class_gid ? data.classes.get(class_gid) : undefined
    const layout = class_ ? data.layouts.get(class_.layout) : undefined
    const config = LAYOUTCOMPONENTTYPECONFIG['field']['typeconfig'][field.type]

    const maxtilesperrow = Math.max(
        1,
        Math.floor((containerWidth + MAGIC_GAP) / (MAGIC_WIDTH['tiles'] + MAGIC_GAP))
    )
    const tilesperrow = Math.min(
        maxtilesperrow,
        component
            ? parseInt(component.columns, 10) || config.columns.default
            : config.columns.default
    )

    const tileWidth = Math.max(
        20,
        Math.floor((containerWidth - MAGIC_GAP * (tilesperrow - 1)) / tilesperrow)
    )

    const [selectedItemIndex, setSelectedItemIndex] = useState(null)
    useClickOutside(containerRef, () => setSelectedItemIndex(null))

    const onAddItemBefore = before_index => {
        classlistfieldvalue.newItemBefore(before_index)
        classlistfieldvalue.commitIfModified()
    }

    const onRemoveItem = index => {
        classlistfieldvalue.removeItem(index)
        classlistfieldvalue.commitIfModified()
    }

    const onRemoveAllItems = () => {
        classlistfieldvalue.removeAllItems()
        classlistfieldvalue.commitIfModified()
    }

    const onClassListFieldAction = action => {
        const actions = {
            add_item: onAddItemBefore,
            remove_item: onRemoveItem,
            remove_all: onRemoveAllItems,
        }
        if (!(action in actions)) {
            console.log(`onClassListFieldAction: unhandled action '${action}'`)
            return
        }
        ClassListFieldContextMenu.hide()
        actions[action](selectedItemIndex)
    }

    const onDrop = (dragitem, item_index, isoverzone) => {
        const other_index = dragitem.id
        let before_index = null
        if (['top', 'left', 'inside'].includes(isoverzone)) {
            before_index = item_index
        } else {
            // before_index is record -after- item_index
            before_index = item_index + 1
        }

        classlistfieldvalue.moveItem(other_index, before_index)
        classlistfieldvalue.commitIfModified()
    }

    const onShowClassListFieldMenu = (e, index) => {
        if (index === null) {
            onAddItemBefore(null)
            return
        }
        setSelectedItemIndex(index)
        ClassListFieldContextMenu.onShow(e)
    }

    let Tiles
    const dragdropdirection = tilesperrow === 1 ? 'vertical' : 'horizontal'
    if (classlistfieldvalue.records.length) {
        Tiles = classlistfieldvalue.records.map((classlistfieldrecord, index) => {
            if (!classlistfieldrecord) return null

            const selected = selectedItemIndex === index
            const classes = selected ? ' cc-selected' : ''
            const ClassView =
                class_ && layout ? (
                    <LayoutBuilder
                        component={layout.components.get(layout.root)}
                        components={layout.components}
                        layout={layout}
                        record={classlistfieldrecord}
                        worksheet={worksheet}
                        active_class={class_}
                        force_readonly={force_readonly || !enabled}
                    />
                ) : (
                    <Text>{app.text('No class or layout assigned.')}</Text>
                )
            return (
                <DragDropClasslistFieldItem
                    className={classes}
                    key={index}
                    field={field}
                    item_index={index}
                    direction={dragdropdirection}
                    disabled={!enabled}
                    onDrop={onDrop}
                >
                    <LayerView
                        className="ws-tile"
                        style={{ width: tileWidth }}
                        onContextMenu={e => onShowClassListFieldMenu(e, index)}
                    >
                        {ClassView}
                        {enabled ? (
                            <Layer
                                className="classlist-actions"
                                anchor="top-end"
                                onClick={e => onShowClassListFieldMenu(e, index)}
                            >
                                <Icon size="text" name="ellipsis" />
                            </Layer>
                        ) : undefined}
                    </LayerView>
                </DragDropClasslistFieldItem>
            )
        })
    }

    if (enabled) {
        const AddTile = (
            <div
                key="add"
                className="ws-tile"
                style={{ width: tileWidth }}
                onClick={e => onShowClassListFieldMenu(e, null)}
            >
                <IconTile
                    width={32}
                    className="cc-dimmed"
                    anchor="center"
                    icon="plus"
                />
            </div>
        )

        if (!Tiles) {
            Tiles = AddTile
        } else {
            Tiles.push(AddTile)
        }
    }

    return (
        <div>
            <TilesView
                ref={containerRef}
                columns={tilesperrow}
                className={classes}
                {...other}
            >
                <ClassListFieldContextMenu.Panel onAction={onClassListFieldAction} />
                {Tiles}
            </TilesView>
            <ValidationMessage validation={validation} />
        </div>
    )
})

//
// RowsLayoutBuilder
//
// Based on view info from a layout

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'

import { HView, LazyVScrollList, LazyScrollItem } from '../../../../appview'
import { LabeledField } from '../fields/LabeledField'
import { LayoutBuilder } from '..'
import { LAYOUTCOMPONENTTYPECONFIG } from '../../../../stores/data/LAYOUTCOMPONENTTYPECONFIG'
import { useLazyScrollingRecordStore } from '../../../../hooks/useLazyScrollingRecordStore'

export const RowsLayoutBuilder = observer(function RowsLayoutBuilder({
    className,
    component,
    components,
    layout,
    record,
    worksheet,
    active_class,
    visited_gids,
    force_readonly,
}) {
    const store = useStore()

    const { children, totalsize, firstindex, onUpdate } = useLazyScrollingRecordStore(
        worksheet.record.gid,
        worksheet.children,
        worksheet.environment,
        0,
        10
    )

    const config = LAYOUTCOMPONENTTYPECONFIG['rows']

    const name = component.name ? component.name : component.type
    const placeholder = component.placeholder
    const localizedPlaceholder = placeholder && placeholder[store.appLanguage]
    const editable = force_readonly
        ? false
        : 'editable' in component
        ? component.editable
        : config.editable.default
    const navigation =
        'navigation' in component ? component.navigation : config.navigation.default
    const height = component.height ? parseInt(component.height) : config.height.default
    const itemheight = component.itemheight
        ? parseInt(component.itemheight)
        : config.itemheight.default

    let Rows
    if (children && children.length) {
        Rows = children.map((child, index) => {
            const tileclasses = 'status-' + child.status
            const ChildLayout =
                component.content && component.content.length
                    ? component.content.map(childcomponent_gid => {
                          const childcomponent = components.get(childcomponent_gid)
                          if (!childcomponent) return null
                          const childconfig =
                              LAYOUTCOMPONENTTYPECONFIG[childcomponent.type]
                          const childeditable =
                              editable &&
                              ('editable' in childcomponent
                                  ? childcomponent.editable
                                  : childconfig.editable.default)
                          childcomponent.editable = childeditable
                          return (
                              <LayoutBuilder
                                  key={childcomponent_gid}
                                  component={childcomponent}
                                  components={components}
                                  layout={layout}
                                  record={child}
                                  worksheet={worksheet}
                                  active_class={active_class}
                                  visited_gids={[
                                      ...visited_gids,
                                      component.gid,
                                      component.original,
                                  ]}
                                  force_readonly={force_readonly || !childeditable}
                              />
                          )
                      })
                    : undefined
            return (
                <LazyScrollItem
                    className="ws-tile"
                    key={child.gid}
                    index={index + firstindex}
                >
                    <HView
                        vcenter
                        className={tileclasses}
                        key={child.gid}
                        onClick={() => navigation && worksheet.setRecord(child)}
                    >
                        {ChildLayout}
                    </HView>
                </LazyScrollItem>
            )
        })
        Rows = (
            <LazyVScrollList
                className="ws-record-navigator ws-rows"
                firstindex={firstindex}
                itemheight={itemheight}
                calculateitemheight={true}
                gap={5}
                totalitems={totalsize}
                onUpdate={onUpdate}
                style={{ maxHeight: height }}
            >
                {Rows}
            </LazyVScrollList>
        )
    }

    const Placeholder = localizedPlaceholder ? (
        <div className="ws-placeholder">{localizedPlaceholder}</div>
    ) : undefined

    let attributes = {
        className: 'layout-component ws-record-navigator ws-rows',
    }
    if (className) attributes['className'] += ' ' + className
    if (component.gid === worksheet.layoutstore.selectedComponentGid) {
        attributes['className'] += ' cc-selected'
    }
    // attributes["onClick"] = e => onClickElement(e, component, layouttreestore)

    if (!Rows) {
        attributes['className'] += ' ws-placeholder-visible'
        Rows = (
            <div>
                <br />
            </div>
        )
    }

    return (
        <LabeledField
            fieldgid={name}
            editable={editable}
            hide_editable_icon={force_readonly}
            label={name}
            worksheet={worksheet}
            {...attributes}
        >
            {Rows}
            {Placeholder}
        </LabeledField>
    )
})

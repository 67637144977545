//
// FieldLayoutBuilder
//
// Based on view info from a layout

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'

import { TypedField } from '.'
import { LAYOUTCOMPONENTTYPECONFIG } from '../../../../stores/data/LAYOUTCOMPONENTTYPECONFIG'

export const FieldLayoutBuilder = observer(function FieldLayoutBuilder({
    className,
    component,
    components,
    layout,
    record,
    worksheet,
    active_class,
    visited_gids,
}) {
    const store = useStore()

    const config = LAYOUTCOMPONENTTYPECONFIG['field']

    const field_gid = component.field
    const placeholder = component.placeholder

    let field = worksheet.findField(field_gid)
    if (!field) {
        return null
    }
    const fromClass = null //worksheet.findClassForField(null, field)

    const editable =
        'editable' in component ? component.editable : config.editable.default
    const localizedPlaceholder = placeholder && placeholder[store.appLanguage]

    const Placeholder = localizedPlaceholder ? (
        <div className="ws-placeholder">{localizedPlaceholder}</div>
    ) : undefined

    let attributes = { className: 'layout-component' }
    if (className) attributes['className'] += ' ' + className
    if (component.style) attributes['className'] += ' lc-' + component.style
    if (component.gid === worksheet.layoutstore.selectedComponentGid) {
        attributes['className'] += ' cc-selected'
    }
    // attributes["onClick"] = e => onClickElement(e, component, layouttreestore)

    let Field = (
        <TypedField
            field={field}
            fromClass={fromClass}
            component={component}
            editable={editable}
            record={record}
            worksheet={worksheet}
            active_class={active_class}
        />
    )
    if (!Field) {
        attributes['className'] += ' ws-placeholder-visible'
        Field = (
            <div>
                <br />
            </div>
        )
    }

    return (
        <div {...attributes}>
            {Placeholder}
            {Field}
        </div>
    )
})

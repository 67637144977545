//
// DEPRECATED - this logic is now found in LoginScreen
//
// LoginWorkspace
//
// Enter credentials, then log in. We'll remember the user using a JWT, which is sent
// on subsequent requests/sessions so we'll stay logged in automatically.
//
// Forgot your password? That's covered here too.
//
// If you get here and are already logged in? Log out or continue to Unicat.
//
// DEPRECATED - this logic is now found in LoginScreen

import React from 'react'
import { useStore } from '../stores'
import { observer } from 'mobx-react-lite'

import { VView, HView, Spacer } from '../appview'
import { Bar, Button, Icon, Property, Text } from '../components'
import { AboutPanel, LoginPanel } from '../panels'

export const LoginWorkspace = observer(function LoginWorkspace() {
    const store = useStore()
    const { app } = store

    const onGotoSignup = () => {
        store.setLocation(store.LOCATIONS.SIGNUP)
    }

    const message = store.isLoggedIn
        ? app.text("Hi {username}, you're already logged in.", {
              username: store.user.displayname,
          })
        : app.text('Log in to Unicat.')

    const Activity = store.isLoggedIn ? (
        <>
            <Property label={''}>
                <Bar raised>
                    <Button action onClick={() => store.autoLocation()}>
                        {app.text('Continue to Unicat')}
                    </Button>
                </Bar>
            </Property>
            <Property label={''}>
                <Bar raised>
                    <Button action onClick={() => store.logout()}>
                        {app.text('Log out')}
                    </Button>
                </Bar>
            </Property>
            <Spacer size={20} />
        </>
    ) : (
        <LoginPanel />
    )

    return (
        <VView grow>
            <VView className="worksheet login-worksheet">
                <HView>
                    <Spacer />
                    <VView className="form login-form">
                        <Spacer size={20} />
                        <AboutPanel
                            version={store.version}
                            link={true}
                            message={message}
                        />
                        <Spacer size={20} />
                        {Activity}
                        <Spacer size={10} />
                        {!store.isLoggedIn && store.can('account.signup') ? (
                            <Property label={''}>
                                <Text onClick={() => onGotoSignup()}>
                                    <Icon name="navigate" size={'text'} />{' '}
                                    {app.text("I don't have an account yet.")}
                                </Text>
                            </Property>
                        ) : undefined}
                    </VView>
                    <Spacer />
                </HView>
            </VView>
        </VView>
    )
})

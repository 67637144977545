//
// ProjectAddLanguagePanel
//
// Add a language to a project, with search

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { VView, VScrollView, Spacer } from '../appview'
import { Header, SearchInput, Item, Menu, MenuItem } from '../components'
import { normalize } from '../utils/text'

export const ProjectAddLanguagePanel = observer(function ProjectAddLanguagePanel({
    project,
    onAddLanguage,
}) {
    const { data, app } = useStore()

    const languages = data.languages

    const [searchtext, setSearchtext] = useState('')
    const [selecteditem, setSelecteditem] = useState(null)
    let selection_in_results = false // initially

    let searchresultlanguages = Array.from(languages.values())
    if (searchtext.length) {
        const searchwords = normalize(searchtext).split(' ')
        searchresultlanguages = searchresultlanguages.filter(language => {
            // do a match: in key and language
            // first, normalize (validName)
            // second, split on spaces
            // then, match all words
            const languagelabel = normalize(app.text(language.name))
            for (const word of searchwords) {
                if (!word.length) continue
                if (
                    !languagelabel.includes(word) &&
                    !normalize(language.code).includes(word)
                ) {
                    return false
                }
            }
            return true
        })
    }

    searchresultlanguages.sort((a, b) => {
        const alabel = normalize(app.text(a.name))
        const blabel = normalize(app.text(b.name))
        if (alabel === blabel) return 0
        return alabel > blabel ? 1 : -1
    })

    const onSearchinputChange = event => {
        setSearchtext(event.target.value)
    }
    const onSearchinputBlur = event => {
        setSearchtext(event.target.value.trim())
    }

    const _onAddLanguage = () => {
        onAddLanguage && selecteditem && onAddLanguage(selecteditem)
    }

    const searchresults = searchresultlanguages.length ? (
        searchresultlanguages.map(language => {
            if (selecteditem === language.code) selection_in_results = true
            if (project.languages.includes(language.code)) {
                return (
                    <Item
                        key={language.code}
                        className="cc-active"
                        selected={selecteditem === language.code}
                    >
                        {app.text(language.name)}
                    </Item>
                )
            }
            return (
                <Item
                    key={language.code}
                    selected={selecteditem === language.code}
                    onClick={() => setSelecteditem(language.code)}
                    onDoubleClick={() => onAddLanguage && onAddLanguage(language.code)}
                >
                    {app.text(language.name)}
                </Item>
            )
        })
    ) : (
        <div style={{ padding: '2px 5px' }}>{app.text('No matches')}</div>
    )

    return (
        <VView className="actions-panel project-add-language-panel">
            <div className="inspector">
                <Header>{app.text('Add language')}</Header>
                <VView
                    style={{
                        marginLeft: 5,
                        marginRight: 5,
                        marginBottom: 2,
                    }}
                >
                    <SearchInput
                        value={searchtext}
                        placeholder={app.text('Search by name or code')}
                        onChange={onSearchinputChange}
                        onBlur={onSearchinputBlur}
                    />
                    <VScrollView
                        className="search-results"
                        style={{ backgroundColor: 'white' }}
                    >
                        {searchresults}
                    </VScrollView>
                    <Spacer size={5} />
                </VView>
            </div>
            <Menu>
                <MenuItem disabled={!selection_in_results} onClick={_onAddLanguage}>
                    {selecteditem
                        ? app.text("Add language '{languagename}'", {
                              languagename: selecteditem,
                          })
                        : app.text('Add language')}
                </MenuItem>
            </Menu>
        </VView>
    )
})

//
// LabeledField
//
// Work on a Definition

import React from 'react'

export const LabeledField = props => {
    var {
        className,
        label,
        is_localized,
        language,
        placeholder,
        show_placeholder,
        children,
        ...other
    } = props

    const languagelabel = is_localized ? (
        <span className="language">{language}</span>
    ) : undefined

    const Placeholder = placeholder ? (
        <div className="ws-placeholder">{placeholder}</div>
    ) : undefined

    var classes = 'ws-field layout-component'
    if (className) classes += ' ' + className
    if (!children || show_placeholder) classes += ' ws-placeholder-visible'

    return (
        <div className={classes} {...other}>
            <div className="ws-label">
                <span className="label">{label}</span>
                {languagelabel}
            </div>
            {Placeholder}
            {children}
        </div>
    )
}

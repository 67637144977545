//
// RecordActions
//
// Actions on records

import { makeObservable, action } from 'mobx'

export class RecordActions {
    constructor(rootstore) {
        makeObservable(this, {
            // single record
            move: action.bound,
            copy: action.bound,
            shallow_copy: action.bound,
            duplicate: action.bound,
            link: action.bound,
            unlink: action.bound,
            delete: action.bound,
            undelete: action.bound,
            permanent_delete: action.bound,
            // records selection
            bulkDuplicate: action.bound,
            bulkDelete: action.bound,
            bulkUndelete: action.bound,
            bulkPermanentDelete: action.bound,
        })

        this._rootstore = rootstore
    }

    get language() {
        return this._rootstore.view.environment.get('language')
    }

    get ordering() {
        return this._rootstore.view.environment.get('ordering')
    }

    move = (recordgid, parentgid, before_gid) =>
        this._rootstore._fetch('/records/move', {
            record: recordgid,
            parent: parentgid,
            before_record: before_gid,
            language: this.language,
            ordering: this.ordering,
        })

    copy = (recordgid, parentgid, before_gid) =>
        this._rootstore._fetch('/records/copy', {
            record: recordgid,
            parent: parentgid,
            before_record: before_gid,
            language: this.language,
            ordering: this.ordering,
        })

    shallow_copy = (recordgid, parentgid, before_gid) =>
        this._rootstore._fetch('/records/shallow_copy', {
            record: recordgid,
            parent: parentgid,
            before_record: before_gid,
            language: this.language,
            ordering: this.ordering,
        })

    duplicate = recordgid =>
        this._rootstore._fetch('/records/duplicate', {
            record: recordgid,
            language: this.language,
            ordering: this.ordering,
        })

    link = (recordgid, parentgid, before_gid) =>
        this._rootstore._fetch('/records/link', {
            record: recordgid,
            parent: parentgid,
            before_record: before_gid,
            language: this.language,
            ordering: this.ordering,
        })

    unlink = recordgid =>
        this._rootstore._fetch('/records/unlink', {
            record: recordgid,
            language: this.language,
        })

    delete = recordgid =>
        this._rootstore._fetch('/records/delete', {
            record: recordgid,
            language: this.language,
        })

    undelete = recordgid =>
        this._rootstore._fetch('/records/undelete', {
            record: recordgid,
            language: this.language,
        })

    permanent_delete = recordgid =>
        this._rootstore._fetch('/records/permanent_delete', {
            record: recordgid,
            language: this.language,
        })

    bulkMove = (selection, parentgid, before_gid) =>
        this._rootstore._fetch('/records/bulk/move', {
            selection: selection,
            parent: parentgid,
            before_record: before_gid,
            ordering: this.ordering,
        })

    bulkCopy = (selection, parentgid, before_gid) =>
        this._rootstore._fetch('/records/bulk/copy', {
            selection: selection,
            parent: parentgid,
            before_record: before_gid,
            ordering: this.ordering,
        })

    bulkShallowCopy = (selection, parentgid, before_gid) =>
        this._rootstore._fetch('/records/bulk/shallow_copy', {
            selection: selection,
            parent: parentgid,
            before_record: before_gid,
            ordering: this.ordering,
        })

    bulkDuplicate = selection =>
        this._rootstore._fetch('/records/bulk/duplicate', {
            selection: selection,
            ordering: this.ordering,
        })

    bulkLink = (selection, parentgid, before_gid) =>
        this._rootstore._fetch('/records/bulk/link', {
            selection: selection,
            parent: parentgid,
            before_record: before_gid,
            ordering: this.ordering,
        })

    bulkDelete = selection =>
        this._rootstore._fetch('/records/bulk/delete', {
            selection: selection,
        })

    bulkUndelete = selection =>
        this._rootstore._fetch('/records/bulk/undelete', {
            selection: selection,
        })

    bulkPermanentDelete = selection =>
        this._rootstore._fetch('/records/bulk/permanent_delete', {
            selection: selection,
        })
}

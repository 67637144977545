//
// ValidationMessage
//
// A validation message.

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { VALIDATION } from '../utils/validation'

export const ValidationMessage = observer(function ValidationMessage(props) {
    const { validation, className, ...other } = props

    const { app } = useStore()

    let classes = 'cc-ValidationMessage'
    if (className) classes += ' ' + className

    if (
        !validation ||
        [VALIDATION.SUCCESS, VALIDATION.NA].includes(validation.result)
    ) {
        return null
    }

    if (validation.result === VALIDATION.ERROR) {
        classes += ' validation-error'
    } else if (validation.result === VALIDATION.REPORT) {
        classes += ' validation-report'
    }

    return (
        <div className={classes} {...other}>
            {validation
                .text(app)
                .split('\n')
                .map((line, index) => (
                    <div key={index}>{line}</div>
                ))}
        </div>
    )
})

//
// FieldLabel
//
// A text-input in code style.

import React from 'react'
import { observer } from 'mobx-react-lite'

export const FieldLabel = observer(function FieldLabel({ field, worksheet, inline }) {
    if (!field || !worksheet) return null

    const language = worksheet.environment.get('language')

    const label = field.label.get(language) || field.name

    return (
        <>
            {label}
            {field.unit ? (
                inline ? (
                    <span> [{field.unit}]</span>
                ) : (
                    <div>[{field.unit}]</div>
                )
            ) : (
                ''
            )}
        </>
    )
})

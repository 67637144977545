//
// SelectFieldOptions
//
// Field options 'values' (to choose from) and 'restrict_to_values' (boolean)

import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'

import { TextListInput, Toggle } from '../../../../components'
import { VALIDATION } from '../../../../utils/validation'
import { validateField, duckField } from '../../../../stores/data/validators'
import { Text, ValidationMessage } from '../../../../components'
import { listFromValues } from '../../../../stores/data/utils'
import { LabeledField } from '../../../components'
import {
    addIndexedListValue,
    replaceIndexedListValue,
    moveIndexedListValue,
    removeIndexedListValue,
} from '../../../../utils/list'

export const SelectFieldOptions = observer(function SelectFieldOptions({
    field,
    selectModified,
    worksheet,
    onAction,
    setSelected,
    renderkeyprefix,
}) {
    const { app, view } = useStore()

    const values = field ? field.options.get('values') : undefined
    const restrict_to_values = field
        ? field.options.get('restrict_to_values')
        : undefined

    const [listvalue, setListvalue] = useState([])
    useEffect(() => {
        setListvalue(listFromValues(values, worksheet.language))
    }, [values, worksheet.language])

    if (!field) return null

    // onChange, onBlur:
    // we have a more complex list structure, so we implement the individual
    // change handlers: onAddItemBefore, onRemoveItem, onRemoveAllItems, onMoveItem,
    // onUpdateItemAddAfter, onChangeItem, and onBlurItem

    const updateValuesOptions = newValues => {
        field.setValuesOption(newValues)
        field.commitIfModified().then(result => selectModified(result))
    }

    const onAddItemBefore = before_index => {
        const values = field.options.get('values')
        let entry = {}
        view.project.languages.forEach(language => {
            entry[language] = ''
        })
        const newValues = addIndexedListValue(values, entry, before_index)
        updateValuesOptions(newValues)
    }

    const onRemoveItem = index => {
        const values = field.options.get('values')
        const newValues = removeIndexedListValue(values, index)
        updateValuesOptions(newValues)
    }

    const onRemoveAllItems = () => {
        const newValues = []
        updateValuesOptions(newValues)
    }

    const onMoveItem = (index, before_index) => {
        const values = field.options.get('values')
        const newValues = moveIndexedListValue(values, index, before_index)
        updateValuesOptions(newValues)
    }

    const onUpdateItemAddAfter = (index, newItemValue) => {
        const values = field.options.get('values')
        let update = {}
        view.project.languages.forEach(language => {
            update[language] = values[index][language]
        })
        update[worksheet.language] = newItemValue
        let entry = {}
        view.project.languages.forEach(language => {
            entry[language] = ''
        })
        const newValues = addIndexedListValue(
            replaceIndexedListValue(values, update, index),
            entry,
            index + 1
        )
        updateValuesOptions(newValues)
    }

    const onChangeItem = (index, newItemValue) => {
        const values = field.options.get('values')
        const lines = newItemValue.trim('\n').split('\n')
        const thisline = lines.shift()
        let update = {}
        view.project.languages.forEach(language => {
            update[language] = values[index][language]
        })
        update[worksheet.language] = thisline
        let newValues = replaceIndexedListValue(values, update, index)
        for (const line of lines) {
            let entry = {}
            view.project.languages.forEach(language => {
                entry[language] = ''
            })
            entry[worksheet.language] = line
            index += 1
            newValues = addIndexedListValue(newValues, entry, index)
        }
        field.setValuesOption(newValues)
    }

    const onBlurItem = (index, newItemValue) => {
        field.commitIfModified().then(result => selectModified(result))
    }

    const onToggleRestrict = newValue => {
        field.setOption('restrict_to_values', newValue)
        field.commitIfModified().then(result => selectModified(result))
    }

    let classes = 'cc-TextListField'
    const duckListField = duckField('textlist', {
        allow_duplicates: false,
    })
    const validation = validateField(listvalue, duckListField)
    if (validation.result === VALIDATION.ERROR) {
        classes += ' validation-error'
    } else if (validation.result === VALIDATION.REPORT) {
        classes += ' validation-report'
    }

    const Header = <Text>{app.text('translation')}</Text>

    return (
        <>
            <LabeledField
                className={classes}
                is_localized={true}
                language={worksheet.language}
                label={app.text('values')}
            >
                <TextListInput
                    enabled={true}
                    header={Header}
                    listvalue={listvalue}
                    renderkey={renderkeyprefix + '.values'}
                    onAddItemBefore={onAddItemBefore}
                    onRemoveItem={onRemoveItem}
                    onRemoveAllItems={onRemoveAllItems}
                    onMoveItem={onMoveItem}
                    onUpdateItemAddAfter={onUpdateItemAddAfter}
                    onChangeItem={onChangeItem}
                    onBlurItem={onBlurItem}
                />
                <ValidationMessage validation={validation} />
            </LabeledField>
            <LabeledField label={app.text('restrict to values')}>
                <Toggle onChange={onToggleRestrict} value={restrict_to_values} />
            </LabeledField>
        </>
    )
})

//
// DragDropTreeItem
//
// Drag and Drop wrapper for TreeItem
//
// If you set dropData, you can 'postpone' the onDrop, e.g. when you want to present an
// action sheet (like move/copy/link) after dragging ends, but you still want the visual
// feedback from where you dropped it
//
// Use onDraggingMousePosition to receive the mouse x,y coordinates when dragging over
// an item. If you want to pup up the action sheet near the last mouse position, you
// can use this.

import React, { useState, useRef, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'
import { useDrag, useDrop, DragPreviewImage } from 'react-dnd'

import { DragTypes } from './DragTypes'
import { useDebounce } from '../hooks/useDebounce'
import { selectionsrc } from '../utils/icon'
import { gid } from '../utils/gid'
import {
    MultiselectionTypes,
    determineRecordsMultiselectionType,
} from '../utils/multiselection'

export const DragDropTreeItem = observer(function DragDropTreeItem({
    tree,
    treeitem,
    dropData,
    onDraggingMousePosition,
    onDrop,
    children,
    ...other
}) {
    const { app, data } = useStore()
    const ref = useRef(null)

    const multiselect = tree.multiselection.size > 1
    const dragtype = multiselect ? DragTypes.RECORD_SELECTION : DragTypes.PIM_RECORD
    const draggid = multiselect ? gid() : treeitem.item.gid

    const selectiontype = determineRecordsMultiselectionType(tree.multiselection, data)
    const isFlat = selectiontype === MultiselectionTypes.FLAT

    const [isoverzone, setIsoverzone] = useState('top')
    const [isMousedown, setIsMousedown] = useState(false)

    const debouncedIsoverzone = useDebounce(isoverzone, 50)

    const [{ isDragging }, drag, preview] = useDrag({
        type: dragtype,
        item: {
            type: dragtype,
            id: treeitem.id,
            gid: draggid,
            selection: tree.actionSelection,
            selectionsize: tree.multiselection.size,
            isFlat,
        },
        canDrag: () => isFlat,
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
        end: (item, monitor) => {
            if (ref.current) {
                setIsMousedown(false)
            }
        },
    })

    const onMouseDown = e => {
        if (e.button === 0) {
            setIsMousedown(true)
        }
    }
    const onMouseUp = e => {
        setIsMousedown(false)
    }

    const canDropDragitem = dragitem => {
        if (!ref.current || !dragitem) {
            return false
        }
        if (dragitem.type === DragTypes.PIM_RECORD) {
            if (
                dragitem.parentid === 0 ||
                dragitem.id === treeitem.id ||
                treeitem.item.path.includes(dragitem.id) // is Descendant
            ) {
                return false
            }
        } else if (dragitem.type === DragTypes.RECORD_SELECTION) {
            if (
                treeitem.item.status === 'deleted' ||
                tree.multiselection.isSelected(treeitem.item.gid)
            ) {
                return false
            }
        }
        return true
    }

    const [{ isOver, shouldDimForDragitem }, drop] = useDrop({
        accept: [DragTypes.PIM_RECORD, DragTypes.RECORD_SELECTION],
        canDrop: dragitem => canDropDragitem(dragitem),

        drop(dragitem, monitor) {
            onDrop(dragitem, treeitem, isoverzone)
            return {
                receiver: 'DragDropTreeItem',
                dragitem: dragitem,
                treeitem: treeitem,
                dropzone: isoverzone,
            }
        },

        collect: monitor => {
            const item = monitor.getItem()
            return {
                isOver: !!monitor.isOver() && !!monitor.canDrop(),
                shouldDimForDragitem:
                    !item ||
                    (item.type !== DragTypes.PIM_RECORD &&
                        item.type !== DragTypes.RECORD_SELECTION) ||
                    !!monitor.canDrop(),
            }
        },

        hover(dragitem, monitor) {
            if (!monitor.canDrop()) {
                return
            }
            if (treeitem.parentid === 0) {
                setIsoverzone('inside')
                return
            }
            // determine top or bottom
            const itemBoundingRect = ref.current.getBoundingClientRect()
            const zoneSize = Math.floor(itemBoundingRect.height / 3)
            const topZone = zoneSize
            const bottomZone = zoneSize
            const itemTopY = topZone
            const itemBottomY =
                itemBoundingRect.bottom - itemBoundingRect.top - bottomZone
            const mousePosition = monitor.getClientOffset()
            onDraggingMousePosition && onDraggingMousePosition(mousePosition)
            const itemMouseY = mousePosition.y - itemBoundingRect.top
            if (itemMouseY < itemTopY) {
                setIsoverzone('top')
            } else if (itemMouseY > itemBottomY && !tree.isExpanded(treeitem.id)) {
                setIsoverzone('bottom')
            } else {
                setIsoverzone('inside')
            }
        },
    })

    drag(drop(ref))

    const droppedOnMe =
        dropData && dropData.dropitem && dropData.dropitem.id === treeitem.id

    let dragclassname = ''
    if (isOver && debouncedIsoverzone === 'top') dragclassname = 'drag-over-top'
    else if (isOver && debouncedIsoverzone === 'inside')
        dragclassname = 'drag-over-inside'
    else if (isOver && debouncedIsoverzone === 'bottom')
        dragclassname = 'drag-over-bottom'
    if (droppedOnMe) {
        if (dropData.dropzone === 'top') dragclassname = 'drag-over-top'
        else if (dropData.dropzone === 'inside') dragclassname = 'drag-over-inside'
        else if (dropData.dropzone === 'bottom') dragclassname = 'drag-over-bottom'
    }
    if (isMousedown && !multiselect) dragclassname += ' maybe-start-dragging'

    useEffect(() => {
        // set style later - cloned drag preview has original style
        if (ref && ref.current && !multiselect) {
            ref.current.style.opacity =
                isDragging ||
                (dropData &&
                    dropData.dragitem &&
                    dropData.dragitem.id === treeitem.id) ||
                !shouldDimForDragitem
                    ? 0.1
                    : 1
        }
    }, [isDragging, dropData, treeitem.id, shouldDimForDragitem, multiselect])

    const Preview = multiselect ? (
        <DragPreviewImage
            connect={preview}
            src={selectionsrc(tree.multiselection.size + app.text('records'))}
        />
    ) : undefined

    return (
        <>
            {Preview}
            <div
                ref={ref}
                className={dragclassname}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                {...other}
            >
                {children}
            </div>
        </>
    )
})

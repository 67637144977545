//
// ProjectsPanel
//
// List projects with members, allow creation

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'
import { useMousePopover } from '../hooks/useMousePopover'
import { useTooltipPopover } from '../hooks/useTooltipPopover'

import {
    VView,
    HView,
    TilesView,
    InlineBlockLayerView,
    Layer,
    Spacer,
} from '../appview'
import { Header, Icon, Text } from '../components'
import { Avatar, ProjectIcon, CreateProjectPanel } from '../panels'

import {
    DeletedProjectMenu,
    ProjectMenu,
    MemberProjectMenu,
    InviteeProjectMenu,
} from '../menus'

export const ProjectsPanel = observer(function ProjectsPanel({
    user,
    logoutAction,
    readonly,
}) {
    const store = useStore()
    const { app, data } = store

    const columns = 2

    // Create project popover
    const CreateProjectPopover = useTooltipPopover(CreateProjectPanel, {
        preferredPosition: 'right',
    })

    // ContextMenus
    const ProjectContextMenu = useMousePopover(ProjectMenu)
    const MemberProjectContextMenu = useMousePopover(MemberProjectMenu)
    const InviteeProjectContextMenu = useMousePopover(InviteeProjectMenu)
    const DeletedProjectContextMenu = useMousePopover(DeletedProjectMenu)

    const [selectedProjectGid, setSelectedProjectGid] = useState(null)

    const onConfirmCreateProject = name => {
        CreateProjectPopover.hide()
        data.createProject(name).then(created_project_gid => {
            store.setLocation(store.LOCATIONS.PROJECT, created_project_gid)
        })
    }

    const onCancelCreateProject = name => {
        CreateProjectPopover.hide()
    }

    const onOpenProject = projectgid => {
        store.view.prepareProjectWorkspace(projectgid)
        store.setLocation(store.LOCATIONS.PROJECT, projectgid)
    }

    const onDelete = projectgid => {
        const project = data.projects.get(projectgid)
        if (project) {
            project.deleteProject()
        }
    }

    const onUndelete = projectgid => {
        const project = data.projects.get(projectgid)
        if (project) {
            project.undeleteProject()
        }
    }

    const onPermanentDelete = projectgid => {
        const project = data.projects.get(projectgid)
        if (project) {
            project.permanentDeleteProject()
        }
    }

    const onAcceptInvite = projectgid => {
        data.actions
            .accept_invite(projectgid)
            .then(result => window.location.reload())
            .catch(error => {})
    }

    const onDeclineInvite = projectgid => {
        data.actions
            .decline_invite(projectgid)
            .then(result => window.location.reload())
            .catch(error => {})
    }

    const onLeaveProject = projectgid => {
        const project = data.projects.get(projectgid)
        if (project) {
            project.cancelMembership()
        }
    }

    const onProjectAction = (projectgid, action) => {
        const actions = {
            open_project: onOpenProject,
            delete: onDelete,
            undelete: onUndelete,
            permanent_delete: onPermanentDelete,
            accept_invite: onAcceptInvite,
            decline_invite: onDeclineInvite,
            leave_project: onLeaveProject,
        }
        if (!(action in actions)) {
            console.log(
                `onProjectAction: unhandled action '${action}' on '${projectgid}'`
            )
            return
        }
        ProjectContextMenu.hide()
        DeletedProjectContextMenu.hide()
        actions[action](projectgid)
    }

    if (!user) return
    const userProjects = data.projectsForUser(user.gid).sort((a, b) => {
        // own projects first, then member projects
        // sort projects by name (unedited)
        const project_a = data.projects.get(a.project_gid)
        const project_b = data.projects.get(b.project_gid)
        const is_owner_a = project_a && user.gid === project_a.owner ? 1 : 0
        const is_owner_b = project_b && user.gid === project_b.owner ? 1 : 0
        return (
            is_owner_b - is_owner_a ||
            project_a.uneditedname.localeCompare(project_b.uneditedname)
        )
    })

    const Projects = userProjects.map(project_member => {
        const projectgid = project_member.project_gid
        const project = data.projects.get(projectgid)

        if (!project) return null

        const contextmenu =
            project.status === 'deleted'
                ? DeletedProjectContextMenu
                : project_member.roles.includes('owner')
                ? ProjectContextMenu
                : project_member.status === 'invited'
                ? InviteeProjectContextMenu
                : MemberProjectContextMenu
        let classes =
            'ws-tile project status-' +
            project.status +
            ' memberstatus-' +
            project_member.status
        if (store.project && projectgid === store.project.gid) {
            classes += ' cc-selected'
        }
        const project_members = data.membersForProject(projectgid)

        let user_member
        const owner = data.users.get(project.owner)
        const Members = project_members
            .filter(project_member => {
                if (project_member.roles.includes('api')) {
                    return false
                }
                if (
                    user.gid !== project.owner &&
                    user.gid !== project_member.user_gid &&
                    ['inactive', 'invited'].includes(project_member.status)
                ) {
                    return false
                }
                return true
            })
            .map(project_member => {
                const membergid = project_member.user_gid
                const member = data.users.get(membergid)
                if (!member) return null
                if (project_member.user_gid === user.gid) {
                    user_member = project_member
                }

                let title = ''
                let layerclass = 'badge ' // trailing space
                if (['inactive', 'declined'].includes(project_member.status)) {
                    title = app.text('Inactive')
                    layerclass += 'cc-danger'
                } else if (project_member.status === 'invited') {
                    title = app.text('Invited')
                    layerclass += 'cc-notice'
                } else if (project_member.roles.includes('owner')) {
                    title = app.text('Owner')
                    layerclass += 'cc-ok'
                } else {
                    title = app.text('Member')
                    layerclass += 'cc-info'
                }

                return (
                    <InlineBlockLayerView className="member" key={membergid}>
                        <Avatar user={member} />
                        <Layer
                            className={layerclass}
                            anchor="top-end"
                            edge
                            title={title}
                        />
                    </InlineBlockLayerView>
                )
            })

        const Roles =
            project_member.status === 'invited' ? (
                <span className={'role cc-notice'}>{project_member.status}</span>
            ) : user_member && user_member.roles ? (
                user_member.roles.map(role => {
                    return (
                        <span
                            className={
                                'role ' + (role === 'owner' ? 'cc-ok' : 'cc-info')
                            }
                            key={role}
                        >
                            {role}
                        </span>
                    )
                })
            ) : undefined

        return (
            <div
                className={classes}
                title={project.name}
                key={project.gid}
                onClick={e => {
                    if (
                        project.status === 'deleted' ||
                        project_member.status === 'invited'
                    ) {
                        setSelectedProjectGid(project.gid)
                        contextmenu.onShow(e)
                    } else {
                        onOpenProject(project.gid)
                    }
                }}
                onContextMenu={e => {
                    setSelectedProjectGid(project.gid)
                    contextmenu.onShow(e)
                }}
            >
                <VView>
                    <HView vcenter>
                        <ProjectIcon
                            project={project}
                            style={{
                                width: 40,
                                height: 40,
                                margin: 5,
                            }}
                        />
                        <Text>
                            <span className="project-name">
                                {project.status === 'deleted' ? (
                                    project.name
                                ) : (
                                    <span onClick={() => onOpenProject(project.gid)}>
                                        {project.name}
                                    </span>
                                )}
                            </span>
                            <br />
                            {app.text('by')}{' '}
                            <span className="owner-name">{owner.displayname}</span>
                        </Text>
                    </HView>
                    <Spacer size={3} />
                    <HView className="roles">{Roles}</HView>
                    <Spacer size={15} />
                    <HView className="members" gap={4} style={{ padding: 5 }}>
                        {Members}
                    </HView>
                </VView>
            </div>
        )
    })
    if (store.can('project.create')) {
        Projects.push(
            <div
                className={'ws-tile status-create'}
                title={app.text('Create a new project')}
                key={'create-project'}
                ref={CreateProjectPopover.anchorRef}
                onClick={CreateProjectPopover.onShow}
            >
                <VView>
                    <Text>{app.text('Create a new project')}</Text>
                    <HView vcenter>
                        <div className="cc-dimmed">
                            <Icon name="plus" size={80} />
                        </div>
                    </HView>
                </VView>
            </div>
        )
    }

    return (
        <VView>
            <CreateProjectPopover.Panel
                onConfirm={onConfirmCreateProject}
                onCancel={onCancelCreateProject}
            />
            <ProjectContextMenu.Panel
                projectgid={selectedProjectGid}
                onAction={onProjectAction}
            />
            <MemberProjectContextMenu.Panel
                projectgid={selectedProjectGid}
                onAction={onProjectAction}
            />
            <InviteeProjectContextMenu.Panel
                projectgid={selectedProjectGid}
                onAction={onProjectAction}
            />
            <DeletedProjectContextMenu.Panel
                projectgid={selectedProjectGid}
                onAction={onProjectAction}
            />
            <Header>{app.text('Projects')}</Header>
            <TilesView className="ws-project-navigator ws-tiles" columns={columns}>
                {Projects}
            </TilesView>
        </VView>
    )
})

//
// LinkedTreeRecordMenu
//
// Actions on records in the TreeNavigator
// Only feed it not-deleted, linked records.

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Menu, MenuDivider, MenuItem } from '../components'

export const LinkedTreeRecordMenu = observer(function LinkedTreeRecordMenu({
    record,
    onAction,
}) {
    const { app } = useStore()

    const _onAction = action => {
        onAction && onAction(action)
    }

    if (!record) return null

    // can't delete root
    const disableDelete = record.parent === null

    return (
        <Menu>
            <MenuItem disabled={true}>{app.text('New child')}</MenuItem>
            <MenuDivider />
            <MenuItem onClick={() => _onAction('goto_linked_record')}>
                {app.text('Go to link source')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('unlink_record')}>
                {app.text('Unlink')}
            </MenuItem>
            <MenuDivider />
            <MenuItem disabled={disableDelete} onClick={() => _onAction('delete')}>
                {app.text('Delete')}
            </MenuItem>
        </Menu>
    )
})

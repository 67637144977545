//
// FileTransformWorksheet
//
// Files show a preview and info/tools

import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../stores'

import { HView, Spacer, VView, View, VScrollView } from '../../../appview'
import { Image, Header, Text, Property, Select, TextInput } from '../../../components'
import { Transform, mergeTransforms } from '../../../stores/imageservers/utils'
import { validAssetName } from '../../../utils/text'

export const FileTransformWorksheet = observer(function FileTransformWorksheet({
    worksheet,
}) {
    const { app, imageserver } = useStore()

    const onChangeAssetName = event => {
        worksheet.asset.setProperty('name', event.target.value + assetext)
    }
    const onBlurAssetName = event => {
        let newname = event.target.value.trim() + assetext
        worksheet.asset.setProperty('name', newname)
        worksheet.asset.commitIfModified()
    }
    const renderkey = worksheet.asset.gid + '.' + worksheet.language
    const _assetnameparts = worksheet.asset.publicname.split('.')
    const assetext = _assetnameparts.length > 1 ? '.' + _assetnameparts.pop() : ''
    const assetname = _assetnameparts.join('.')

    const [hotspot, setHotspot] = useState([0.5, 0.5])
    const [crop, setCrop] = useState([1.0, 1.0])

    const dpr = Math.ceil(window.devicePixelRatio)
    const isScalable =
        worksheet.asset.info.get('format') === 'svg' ||
        worksheet.asset.info.get('format') === 'eps'
    const assetW = worksheet.asset.info.get('width')
    const assetH = worksheet.asset.info.get('height')
    const assetRatio = assetW / assetH
    const transformcrop = worksheet.asset.mainTransform.crop || [1, 1]
    const croppedAssetW = transformcrop[0] * assetW
    const croppedAssetH = transformcrop[1] * assetH
    const croppedAssetRatio = croppedAssetW / croppedAssetH

    useEffect(() => {
        const transform = worksheet.asset.mainTransform
        setHotspot(transform.hotspot ? transform.hotspot : [0.5, 0.5])
        setCrop(transform.crop ? transform.crop : [1.0, 1.0])
    }, [worksheet.asset, imageserver])

    const dampreviewcontainerRef = useRef()
    const dampreviewRef = useRef()
    const damtransformRef = useRef()

    const [previewLoaded, updatePreviewLoaded] = useState(0) // height updates after load
    const handlePreviewLoad = () => {
        updatePreviewLoaded(previewLoaded + 1)
    }
    const [previewSrc, setPreviewSrc] = useState(null)
    useEffect(() => {
        let isMounted = true
        let controller = new AbortController()
        async function asyncEffect() {
            setPreviewSrc(null)

            let transformWidth = 425
            let transformHeight = 425
            if (!isScalable && assetW < 425 * dpr && assetH < 425 * dpr) {
                transformWidth = assetW / dpr
                transformHeight = assetH / dpr
            }

            let transform =
                assetRatio >= 1
                    ? new Transform('width', transformWidth)
                    : new Transform('height', null, transformHeight)

            const transformurl = imageserver.transformAssetUrl(
                worksheet.asset,
                transform
            )
            const imageurl = await imageserver.transformedAssetUrl(
                worksheet.asset,
                transformurl,
                { signal: controller.signal }
            )
            if (isMounted) {
                setPreviewSrc(imageurl)
            }
        }
        asyncEffect()
        return () => {
            controller.abort('Unmounted')
            isMounted = false
            return isMounted
        }
    }, [
        worksheet.asset,
        worksheet.asset.updated_on,
        isScalable,
        assetW,
        assetH,
        assetRatio,
        dpr,
        imageserver,
    ])

    const [transformFitFill, setTransformFitFill] = useState('fill')
    const [transformDimensions, setTransformDimensions] = useState('square')
    const [transformLoaded, updateTransformLoaded] = useState(0) // height updates after load
    const handleTransformLoad = () => {
        updateTransformLoaded(transformLoaded + 1)
    }
    const [transformSrc, setTransformSrc] = useState(null)
    const resize = transformDimensions === 'auto' ? 'width' : transformFitFill
    let width = 425
    let height = 425
    switch (transformDimensions) {
        case 'banner':
            height = (6 / 20) * width
            break
        case 'landscape':
            height = (9 / 16) * width
            break
        case 'portrait':
            width = (9 / 16) * height
            break
        case 'pillar':
            width = (6 / 20) * height
            break
        case 'auto':
            if (croppedAssetRatio >= 1) {
                height = width / croppedAssetRatio
            } else {
                width = width * croppedAssetRatio
            }
            break
        default:
            break
    }
    if (!isScalable && croppedAssetW < 425 * dpr && croppedAssetH < 425 * dpr) {
        width = width / dpr
        height = height / dpr
    }

    const transformBaseTransform = new Transform(resize, width, height)
    const mergedtransforms = mergeTransforms(
        worksheet.asset.mainTransform,
        transformBaseTransform
    )
    const transformurl = imageserver.transformAssetUrl(
        worksheet.asset,
        mergedtransforms
    )

    useEffect(() => {
        let isMounted = true
        let controller = new AbortController()
        async function asyncEffect() {
            setTransformSrc(null)
            const imageurl = await imageserver.transformedAssetUrl(
                worksheet.asset,
                transformurl,
                { signal: controller.signal }
            )
            if (isMounted) {
                setTransformSrc(imageurl)
            }
        }
        asyncEffect()
        return () => {
            controller.abort('Unmounted')
            isMounted = false
            return isMounted
        }
    }, [
        transformurl,
        worksheet.asset,
        worksheet.asset.name,
        worksheet.asset.updated_on,
        imageserver,
    ])

    useLayoutEffect(() => {
        if (damtransformRef.current) {
            const damtransformcontainer = damtransformRef.current.parentElement
            const damtransformcontainerWidth = damtransformcontainer.clientWidth
            const damtransformcontainerHeight = damtransformcontainer.clientHeight
            const transformRatio = width / height
            if (transformRatio >= 1) {
                const damtransformWidth = damtransformcontainerWidth
                const damtransformHeight = damtransformWidth / transformRatio
                damtransformRef.current.style.width = damtransformWidth + 'px'
                damtransformRef.current.style.height = damtransformHeight + 'px'
            } else {
                const damtransformHeight = damtransformcontainerHeight
                const damtransformWidth = damtransformHeight * transformRatio
                damtransformRef.current.style.width = damtransformWidth + 'px'
                damtransformRef.current.style.height = damtransformHeight + 'px'
            }
        }
    }, [worksheet.asset, transformLoaded, width, height, imageserver])

    useLayoutEffect(() => {
        if (dampreviewRef.current) {
            const dampreviewcontainer = dampreviewRef.current.parentElement
            const dampreviewcontainerWidth = dampreviewcontainer.clientWidth
            const dampreviewcontainerHeight = dampreviewcontainer.clientHeight
            const isScalable =
                worksheet.asset.info.get('format') === 'svg' ||
                worksheet.asset.info.get('format') === 'eps'
            if (assetRatio >= 1) {
                const dampreviewWidth =
                    !isScalable && assetW < 425 ? assetW : dampreviewcontainerWidth
                const dampreviewHeight = dampreviewWidth / assetRatio
                dampreviewRef.current.style.width = dampreviewWidth + 'px'
                dampreviewRef.current.style.height = dampreviewHeight + 'px'
            } else {
                const dampreviewHeight =
                    !isScalable && assetH < 425 ? assetH : dampreviewcontainerHeight
                const dampreviewWidth = dampreviewHeight * assetRatio
                dampreviewRef.current.style.width = dampreviewWidth + 'px'
                dampreviewRef.current.style.height = dampreviewHeight + 'px'
            }
        }
    }, [worksheet.asset, previewLoaded, assetW, assetH, assetRatio, imageserver])

    const [mouseActive, setMouseActive] = useState(false)
    const [shiftActive, setShiftActive] = useState(false)
    const [cropstart, setCropstart] = useState([0.0, 0.0])

    const xy = e => {
        const x =
            Math.max(
                0,
                Math.min(
                    dampreviewRef.current.clientWidth,
                    e.clientX -
                        dampreviewcontainerRef.current.offsetLeft -
                        dampreviewRef.current.offsetLeft
                )
            ) / dampreviewRef.current.clientWidth
        const y =
            Math.max(
                0,
                Math.min(
                    dampreviewRef.current.clientHeight,
                    e.clientY -
                        dampreviewcontainerRef.current.offsetTop -
                        dampreviewRef.current.offsetTop
                )
            ) / dampreviewRef.current.clientHeight
        return [x, y]
    }

    const updateMainTransform = (hotspot, crop) => {
        if (!hotspot && !crop) return
        var transform = worksheet.asset.mainTransform
        if (hotspot) {
            transform.setHotspot(hotspot[0], hotspot[1])
        }
        if (crop) {
            transform.setCrop(crop[0], crop[1])
        }
        worksheet.asset.updateMainTransform(transform)
        worksheet.asset.commitIfModified()
    }

    const resetTransform = () => {
        const hs = [0.5, 0.5]
        const c = [1.0, 1.0]
        setHotspot(hs)
        setCrop(c)
        updateMainTransform(hs, c)
    }

    const adjustTransformRatio = (w, h) => {
        const adjustaspect = w / h
        let croparea = [croppedAssetW, croppedAssetH]
        if (adjustaspect === croppedAssetRatio) return

        let widthfactor = adjustaspect / croppedAssetRatio

        if (adjustaspect > croppedAssetRatio) {
            croparea[0] = assetW * crop[0] * widthfactor
        } else {
            croparea[1] = (assetH * crop[1]) / widthfactor
        }
        let c = [croparea[0] / assetW, croparea[1] / assetH]

        if (c[0] > 1) {
            c[1] = c[1] / c[0]
            c[0] = 1
        } else if (c[1] > 1) {
            c[0] = c[0] / c[1]
            c[1] = 1
        }

        console.log({
            adjustaspect,
            croppedAssetRatio,
            cw: c[0],
            ch: c[1],
            aspect: c[0] / c[1],
        })

        // const hs = [0.5, 0.5]
        // const c = [1.0, 1.0]
        // setHotspot(hs)
        setCrop(c)
        updateMainTransform(null, c)
    }

    const onMouseDown = e => {
        if (!previewLoaded) return
        setMouseActive(true)
        setShiftActive(e.shiftKey)
        const pos = xy(e)
        if (!e.shiftKey) setHotspot(pos)
        else {
            setCropstart(pos)
            setCrop([0, 0])
            setHotspot(pos)
        }
    }
    const onMouseMove = e => {
        if (!mouseActive) return
        const pos = xy(e)
        if (!shiftActive) setHotspot(pos)
        else {
            const dx = pos[0] - cropstart[0]
            const dy = pos[1] - cropstart[1]
            const w = Math.abs(dx)
            const h = Math.abs(dy)
            setCrop([w, h])
            setHotspot([cropstart[0] + dx / 2, cropstart[1] + dy / 2])
        }
    }
    const onMouseUp = e => {
        if (!mouseActive) return
        const pos = xy(e)
        if (!shiftActive) {
            setHotspot(pos)
            updateMainTransform(pos)
        } else {
            const dx = pos[0] - cropstart[0]
            const dy = pos[1] - cropstart[1]
            const w = Math.abs(dx)
            const h = Math.abs(dy)
            setCrop([w, h])
            setHotspot([cropstart[0] + dx / 2, cropstart[1] + dy / 2])
            updateMainTransform([cropstart[0] + dx / 2, cropstart[1] + dy / 2], [w, h])
        }
        setMouseActive(false)
    }

    let ImagePreview
    if (previewSrc && worksheet.asset) {
        const hotspotX = hotspot[0]
        const hotspotY = hotspot[1]
        const cropW = crop[0]
        const cropH = crop[1]
        const cropX = Math.max(0, Math.min(1 - cropW, hotspotX - cropW / 2))
        const cropY = Math.max(0, Math.min(1 - cropH, hotspotY - cropH / 2))
        // dam-preview should match aspect-ration AND fit in a square based on width
        ImagePreview = (
            <div className="dam-preview-container" ref={dampreviewcontainerRef}>
                <div className="dam-preview cc-checkered" ref={dampreviewRef}>
                    <Image
                        className="ws-image"
                        alt={worksheet.asset.pathname}
                        title={worksheet.asset.name}
                        value={previewSrc}
                        onLoad={handlePreviewLoad}
                    />
                    <div className="image-area">
                        <div
                            className="transform-hotspot"
                            style={{
                                left: 'calc(' + hotspotX * 100 + '% - 4px)',
                                top: 'calc(' + hotspotY * 100 + '% - 4px)',
                            }}
                        />
                        <div
                            className="transform-crop"
                            style={{
                                left: cropX * 100 + '%',
                                top: cropY * 100 + '%',
                                width: cropW * 100 + '%',
                                height: cropH * 100 + '%',
                            }}
                        />
                        <div
                            className="image-area-overshoot"
                            style={{
                                position: 'absolute',
                                top: '-15px',
                                left: '-15px',
                                height: 'calc(100% + 30px)',
                                width: 'calc(100% + 30px)',
                            }}
                            onMouseDown={onMouseDown}
                            onMouseMove={onMouseMove}
                            onMouseUp={onMouseUp}
                            onMouseLeave={onMouseUp}
                        />
                    </div>
                </div>
            </div>
        )
    }

    let ImageTransform
    if (transformSrc && worksheet.asset) {
        ImageTransform = (
            <div className="dam-transform-container">
                <div className="dam-transform cc-checkered" ref={damtransformRef}>
                    <Image
                        alt={worksheet.asset.pathname}
                        title={worksheet.asset.name}
                        value={transformSrc}
                        onLoad={handleTransformLoad}
                    />
                </div>
            </div>
        )
    }

    const fitfill_options = {
        fit: 'fit',
        fill: 'fill',
    }

    const dimensions_options = {
        square: 'square',
        banner: 'banner',
        landscape: 'landscape',
        portrait: 'portrait',
        pillar: 'pillar',
        auto: 'auto',
    }

    let Worksheet = app.text('No file selected.')
    if (worksheet.asset) {
        Worksheet = (
            <>
                <VView grow>
                    <Header>{app.text('Original, fitted')}</Header>
                    {ImagePreview}
                    <Spacer size={7} />
                    <HView>
                        <Text>
                            {app.text(
                                'Click or drag for hotspot, Shift-drag for crop area.'
                            )}{' '}
                            <span
                                className="ws-tagbutton"
                                onClick={() => resetTransform()}
                            >
                                {app.text('reset')}
                            </span>
                        </Text>
                    </HView>
                    <Text>
                        <HView gap={5}>
                            <span>{app.text('Adjust crop aspect ratio')}</span>
                            <span
                                className={
                                    'ws-tagbutton' +
                                    (Math.abs(croppedAssetRatio - 16 / 9) < 0.0001
                                        ? ' cc-selected'
                                        : '')
                                }
                                onClick={() => adjustTransformRatio(16, 9)}
                            >
                                {app.text('16:9')}
                            </span>
                            <span
                                className={
                                    'ws-tagbutton' +
                                    (Math.abs(croppedAssetRatio - 3 / 2) < 0.0001
                                        ? ' cc-selected'
                                        : '')
                                }
                                onClick={() => adjustTransformRatio(3, 2)}
                            >
                                {app.text('3:2')}
                            </span>
                            <span
                                className={
                                    'ws-tagbutton' +
                                    (Math.abs(croppedAssetRatio - 4 / 3) < 0.0001
                                        ? ' cc-selected'
                                        : '')
                                }
                                onClick={() => adjustTransformRatio(4, 3)}
                            >
                                {app.text('4:3')}
                            </span>
                            <span
                                className={
                                    'ws-tagbutton' +
                                    (Math.abs(croppedAssetRatio - 1) < 0.0001
                                        ? ' cc-selected'
                                        : '')
                                }
                                onClick={() => adjustTransformRatio(1, 1)}
                            >
                                {app.text('square')}
                            </span>
                            <span
                                className={
                                    'ws-tagbutton' +
                                    (Math.abs(croppedAssetRatio - 3 / 4) < 0.0001
                                        ? ' cc-selected'
                                        : '')
                                }
                                onClick={() => adjustTransformRatio(3, 4)}
                            >
                                {app.text('3:4')}
                            </span>
                            <span
                                className={
                                    'ws-tagbutton' +
                                    (Math.abs(croppedAssetRatio - 2 / 3) < 0.0001
                                        ? ' cc-selected'
                                        : '')
                                }
                                onClick={() => adjustTransformRatio(2, 3)}
                            >
                                {app.text('2:3')}
                            </span>
                        </HView>
                    </Text>
                </VView>
                <Spacer size={20} />
                <VView grow>
                    <Header>{app.text('Transform testing')}</Header>
                    {ImageTransform}
                    <Spacer size={10} />
                    <HView
                        className="transform-testing-options"
                        style={{ paddingLeft: 5 }}
                    >
                        <Property label={app.text('Dimensions')}>
                            <Select
                                value={transformDimensions}
                                onChange={value => setTransformDimensions(value)}
                                options={dimensions_options}
                            />
                        </Property>
                        <Property label={app.text('Resize')}>
                            <Select
                                value={transformFitFill}
                                onChange={value => setTransformFitFill(value)}
                                options={fitfill_options}
                            />
                        </Property>
                    </HView>
                </VView>
            </>
        )
    }

    return (
        <VScrollView>
            <VView className="worksheet">
                <Header>{app.text('Asset properties')}</Header>
                <HView>
                    <View grow className="cc-Field ws-field">
                        <div className="ws-label">
                            <span className="label">{app.text('name')}</span>
                        </div>
                        <div className="ws-inline">
                            <TextInput
                                autoConvert={validAssetName}
                                onChange={onChangeAssetName}
                                onBlur={onBlurAssetName}
                                value={assetname}
                                renderkey={renderkey}
                                style={{ minWidth: 200 }}
                            />
                            <Text>{assetext}</Text>
                        </div>
                    </View>
                </HView>
                <HView>{Worksheet}</HView>
            </VView>
        </VScrollView>
    )
})

//
// Property
//
// A Property has a label and some (editable?) value(s) that is (are) passed as children.

import React from 'react'

export const Property = props => {
    const { className, label, selected, children, ...other } = props
    let classes = 'cc-Property'
    if (selected) classes += ' cc-selected'
    if (className) classes += ' ' + className
    return (
        <div className={classes} {...other}>
            <div className="cc-Property-Label">{label}</div>
            <div className="cc-Property-Value">{children}</div>
        </div>
    )
}

//
// CCDamImageserver
//
// An Imageserver returns a URL for an asset, transformed as specified.
// The URL can be relative to the root, or it can be a full scheme://domain:port/...

import { hashData } from '../../utils/utils'

class CCDamImageserver {
    constructor(options, rootstore) {
        this._rootstore = rootstore
        this.options = options
        this.transformable_formats = [
            // images
            'jpg',
            'jpeg',
            'gif',
            'svg',
            'png',
            'bmp',
            'eps',
            'ico',
            'tif',
            'tiff',
            // TODO: CC-96 add webp, jpeg-xl
            // documents
            'pdf',
            // 'txt',
        ]
        this.force = false
    }

    canTransform(asset) {
        if (!asset) return false
        if (!asset.is_file) return false
        return this.transformable_formats.includes(asset.ext)
    }

    assetUrl(asset) {
        if (!asset) return null
        return asset.gid + '~' + asset.version + '.' + asset.ext
    }

    transformAssetUrl(asset, transform = {}) {
        if (!asset) return null
        if (!this.canTransform(asset)) {
            return this.assetUrl(asset)
        }
        const source = this.assetUrl(asset)
        return this.transformUrl(source, transform, asset.updated_on)
    }

    transformAvatarUrl(avatar, transform = {}) {
        if (!avatar) return null
        const source = 'avatar/' + avatar
        return this.transformUrl(source, transform, '')
    }

    transformProjectIconUrl(icon, transform = {}) {
        if (!icon) return null
        const source = icon
        return this.transformUrl(source, transform, '')
    }

    transformUrl(url, transform = {}, timestamp) {
        const default_options = {
            timestamp: timestamp, // for hash
            resize: 'width',
            width: 200,
            height: 200,
            type: 'jpg',
            dpr: Math.ceil(window.devicePixelRatio),
            background: 'transparent',
        }
        const options = { ...default_options, ...transform }
        const key = hashData(options)

        //     /name=seo-optimized-name
        //     /key=2x
        //     /type=jpg
        //     /force=true
        //     /optimize=true

        //     /width=400
        //     /width=400,300
        //     /height=300
        //     /height=400,300
        //     /fit=400,300
        //     /fill=400,300

        //     /hs=0.5,0.5
        //     /crop=0.6,0.6
        //     /padding=0,auto,0,auto
        //     /bg=abcdef
        //     /q=82
        //     /dpr=2

        let transform_url =
            url +
            '/' +
            options['resize'] +
            '=' +
            options['width'] +
            ',' +
            options['height']
        if (options['resize'] === 'width' && options['height'] === null) {
            transform_url = url + '/' + options['resize'] + '=' + options['width']
        } else if (options['resize'] === 'height' && options['width'] === null) {
            transform_url = url + '/' + options['resize'] + '=' + options['height']
        }

        if (options['hotspot']) {
            transform_url +=
                '/hs=' +
                options['hotspot'][0].toFixed(3) +
                ',' +
                options['hotspot'][1].toFixed(3)
        }
        if (options['crop']) {
            transform_url +=
                '/crop=' +
                options['crop'][0].toFixed(3) +
                ',' +
                options['crop'][1].toFixed(3)
        }
        if (options['background']) {
            transform_url += '/bg=' + options['background']
        }
        transform_url += '/type=jpg/dpr=' + options['dpr'] + '/key=' + key
        if (this.force) {
            transform_url += '/force=true'
        }

        return transform_url
    }

    async transformedAssetUrl(asset, transform_url, additional_fetch_options) {
        if (!transform_url) {
            return Promise.resolve(null)
        }
        if (transform_url === this.assetUrl(asset)) {
            return Promise.resolve(null)
        }
        return this.transformedUrl(transform_url, additional_fetch_options)
    }

    async transformedUrl(transform_url, additional_fetch_options) {
        if (!transform_url) {
            return Promise.resolve(null)
        }

        return this._rootstore.dam
            .transform(transform_url, additional_fetch_options)
            .then(result => {
                let transformed_url = result['public_url']
                if (this.force) {
                    transformed_url += '?v=' + Date.now()
                }
                return transformed_url
            })
            .catch(error => {})
    }

    async transformedAvatarUrl(transform_url, additional_fetch_options) {
        if (!transform_url) {
            return Promise.resolve(null)
        }

        return this._rootstore.dam
            .globaltransform(transform_url, additional_fetch_options)
            .then(result => {
                let transformed_url = result['public_url']
                if (this.force) {
                    transformed_url += '?v=' + Date.now()
                }
                return transformed_url
            })
            .catch(error => {})
    }

    async transformedProjectIconUrl(transform_url, additional_fetch_options) {
        if (!transform_url) {
            return Promise.resolve(null)
        }

        return this._rootstore.dam
            .globaltransform(transform_url, additional_fetch_options)
            .then(result => {
                let transformed_url = result['public_url']
                if (this.force) {
                    transformed_url += '?v=' + Date.now()
                }
                return transformed_url
            })
            .catch(error => {})
    }

    async downloadAssetUrl(asset) {
        if (!asset) return null
        const asset_url = this.assetUrl(asset)
        return this._rootstore.dam
            .publish(asset_url)
            .then(result => {
                let published_url = result['public_url']
                if (this.force) {
                    published_url += '?v=' + Date.now()
                }
                return published_url
            })
            .catch(error => {})
    }
}

export default CCDamImageserver

//
// TreeNavigator
//
// A Tree view for Definition/Classes/Fields hierarchy

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../stores'
import { useMousePopover } from '../../hooks/useMousePopover'

import { VScrollView } from '../../appview'
import { TreeItem, Icon } from '../../components'
import { isString, maxChars } from '../../utils/text'

import { DragSchemaTreeItem } from '../../dragdrop/DragSchemaTreeItem'
import {
    TreeSchemaDefinitionHeaderMenu,
    TreeSchemaDefinitionMenu,
    TreeSchemaClassHeaderMenu,
    TreeSchemaClassMenu,
    TreeSchemaFieldHeaderMenu,
    TreeSchemaFieldMenu,
    SchemaContextmenu,
} from '../../menus'
import { keyboard } from '../../utils/keyboard'
import { DefinitionData } from '../../stores/data/DefinitionData'
import { ClassData } from '../../stores/data/ClassData'
import { FieldData } from '../../stores/data/FieldData'

export const TreeNavigator = observer(function TreeNavigator({ tree, worksheet }) {
    const { app, data, view } = useStore()
    const language = view.environment.get('language')

    const itemheight = 19

    const selectedSchemaItem = tree.selectedItem ? tree.selectedItem.item : null

    const TreeSchemaDefinitionHeaderContextMenu = useMousePopover(
        TreeSchemaDefinitionHeaderMenu
    )
    const TreeSchemaDefinitionContextMenu = useMousePopover(TreeSchemaDefinitionMenu)
    const TreeSchemaClassHeaderContextMenu = useMousePopover(TreeSchemaClassHeaderMenu)
    const TreeSchemaClassContextMenu = useMousePopover(TreeSchemaClassMenu)
    const TreeSchemaFieldHeaderContextMenu = useMousePopover(TreeSchemaFieldHeaderMenu)
    const TreeSchemaFieldContextMenu = useMousePopover(TreeSchemaFieldMenu)
    const SchemaContextmenuPopover = useMousePopover(SchemaContextmenu)

    const onCreateDefinition = () => {
        data.createDefinition().then(result => {
            tree.expandItem({ id: 'definition' }).then(() => {
                const newitem = tree.findItemById(result['definition'])
                tree.selectItem(newitem)
                view.schemaquery.refetch()
            })
        })
    }
    const onCommitDefinition = () => {
        selectedSchemaItem.commit().then(result => {
            tree.expandItem({ id: 'definition' }).then(() => {
                const newitem = tree.findItemById(result['definition'])
                tree.selectItem(newitem)
                view.schemaquery.refetch()
            })
        })
    }
    const onRevertDefinition = () => {
        selectedSchemaItem.revert().then(result => {
            tree.expandItem({ id: 'definition' }).then(() => {
                const newitem = tree.findItemById(result['definition'])
                tree.selectItem(newitem)
                view.schemaquery.refetch()
            })
        })
    }
    const onSaveAsNewDefinition = () => {
        selectedSchemaItem.saveAsNew().then(result => {
            tree.expandItem({ id: 'definition' }).then(() => {
                const newitem = tree.findItemById(result['definition'])
                tree.selectItem(newitem)
                view.schemaquery.refetch()
            })
        })
    }
    const onCopyDefinition = () => {
        selectedSchemaItem.copy().then(result => {
            tree.expandItem({ id: 'definition' }).then(() => {
                const newitem = tree.findItemById(result['definition'])
                tree.selectItem(newitem)
                view.schemaquery.refetch()
            })
        })
    }
    const onDeleteDefinition = () => {
        selectedSchemaItem.delete().then(result => {
            if (!result) return
            tree.expandItem({ id: 'definition' }).then(() => {
                const parentitem = tree.findItemById('definition')
                tree.selectItem(parentitem)
                view.schemaquery.refetch()
            })
        })
    }

    const onCreateClass = () => {
        data.createClass().then(result => {
            tree.expandItem({ id: 'class' }).then(() => {
                const newitem = tree.findItemById(result['class'])
                tree.selectItem(newitem)
                view.schemaquery.refetch()
            })
        })
    }
    const onCommitClass = () => {
        selectedSchemaItem.commit().then(result => {
            tree.expandItem({ id: 'class' }).then(() => {
                const newitem = tree.findItemById(result['class'])
                tree.selectItem(newitem)
                view.schemaquery.refetch()
            })
        })
    }
    const onRevertClass = () => {
        selectedSchemaItem.revert().then(result => {
            tree.expandItem({ id: 'class' }).then(() => {
                const newitem = tree.findItemById(result['class'])
                tree.selectItem(newitem)
                view.schemaquery.refetch()
            })
        })
    }
    const onSaveAsNewClass = () => {
        selectedSchemaItem.saveAsNew().then(result => {
            tree.expandItem({ id: 'class' }).then(() => {
                const newitem = tree.findItemById(result['class'])
                tree.selectItem(newitem)
                view.schemaquery.refetch()
            })
        })
    }
    const onCopyClass = () => {
        selectedSchemaItem.copy().then(result => {
            tree.expandItem({ id: 'class' }).then(() => {
                const newitem = tree.findItemById(result['class'])
                tree.selectItem(newitem)
                view.schemaquery.refetch()
            })
        })
    }
    const onDeleteClass = () => {
        selectedSchemaItem.delete().then(result => {
            if (!result) return
            tree.expandItem({ id: 'class' }).then(() => {
                const parentitem = tree.findItemById('class')
                tree.selectItem(parentitem)
                view.schemaquery.refetch()
            })
        })
    }

    const onCreateField = () => {
        data.createField().then(result => {
            tree.expandItem({ id: 'field' }).then(() => {
                const newitem = tree.findItemById(result['field'])
                tree.selectItem(newitem)
                view.schemaquery.refetch()
            })
        })
    }
    const onCommitField = () => {
        selectedSchemaItem.commit().then(result => {
            tree.expandItem({ id: 'field' }).then(() => {
                const newitem = tree.findItemById(result['field'])
                tree.selectItem(newitem)
                view.schemaquery.refetch()
            })
        })
    }
    const onRevertField = () => {
        selectedSchemaItem.revert().then(result => {
            tree.expandItem({ id: 'field' }).then(() => {
                const newitem = tree.findItemById(result['field'])
                tree.selectItem(newitem)
                view.schemaquery.refetch()
            })
        })
    }
    const onSaveAsNewField = () => {
        selectedSchemaItem.saveAsNew().then(result => {
            tree.expandItem({ id: 'field' }).then(() => {
                const newitem = tree.findItemById(result['field'])
                tree.selectItem(newitem)
                view.schemaquery.refetch()
            })
        })
    }
    const onCopyField = () => {
        selectedSchemaItem.copy().then(result => {
            tree.expandItem({ id: 'field' }).then(() => {
                const newitem = tree.findItemById(result['field'])
                tree.selectItem(newitem)
                view.schemaquery.refetch()
            })
        })
    }
    const onDeleteField = () => {
        selectedSchemaItem.delete().then(result => {
            if (!result) return
            tree.expandItem({ id: 'field' }).then(() => {
                const parentitem = tree.findItemById('field')
                tree.selectItem(parentitem)
                view.schemaquery.refetch()
            })
        })
    }

    const onCommitSchemaSelection = () => {
        if (!tree.multiselection.size) return
        data.actions.schema.bulkCommit(tree.actionSelection)
    }

    const onRevertSchemaSelection = () => {
        if (!tree.multiselection.size) return
        data.actions.schema.bulkRevert(tree.actionSelection)
    }

    const onSaveAsNewSchemaSelection = () => {
        if (!tree.multiselection.size) return
        data.actions.schema.bulkSaveAsNew(tree.actionSelection)
    }

    const onCopySchemaSelection = () => {
        if (!tree.multiselection.size) return
        data.actions.schema.bulkCopy(tree.actionSelection)
    }

    const onDeleteSchemaSelection = () => {
        if (!tree.multiselection.size) return
        data.actions.schema.bulkDelete(tree.actionSelection)
    }

    const onTreeSchemaAction = action => {
        const actions = {
            create_definition: onCreateDefinition,
            commit_definition: onCommitDefinition,
            revert_definition: onRevertDefinition,
            save_as_new_definition: onSaveAsNewDefinition,
            copy_definition: onCopyDefinition,
            delete_definition: onDeleteDefinition,
            create_class: onCreateClass,
            commit_class: onCommitClass,
            revert_class: onRevertClass,
            save_as_new_class: onSaveAsNewClass,
            copy_class: onCopyClass,
            delete_class: onDeleteClass,
            create_field: onCreateField,
            commit_field: onCommitField,
            revert_field: onRevertField,
            save_as_new_field: onSaveAsNewField,
            copy_field: onCopyField,
            delete_field: onDeleteField,
            commit_schema_selection: onCommitSchemaSelection,
            revert_schema_selection: onRevertSchemaSelection,
            save_as_new_schema_selection: onSaveAsNewSchemaSelection,
            copy_schema_selection: onCopySchemaSelection,
            delete_schema_selection: onDeleteSchemaSelection,
        }
        if (!(action in actions)) {
            console.log(
                `onTreeSchemaAction: unhandled action '${action}' on '${
                    tree.selectedItem ? tree.selectedItem.item.name : '?'
                }'`
            )
            return
        }
        TreeSchemaDefinitionHeaderContextMenu.hide()
        TreeSchemaDefinitionContextMenu.hide()
        TreeSchemaClassHeaderContextMenu.hide()
        TreeSchemaClassContextMenu.hide()
        TreeSchemaFieldHeaderContextMenu.hide()
        TreeSchemaFieldContextMenu.hide()
        SchemaContextmenuPopover.hide()
        if (actions[action]) {
            actions[action]()
        }
    }

    const onClickTreeItem = (event, treeitem) => {
        if (keyboard.testCommand(event)) {
            if (tree.multiselection.isSelected(treeitem.item.gid)) {
                tree.multiselection.deselect(treeitem.item.gid)
            } else {
                tree.multiselection.addRangeStart(treeitem.item.gid)
            }
        } else if (keyboard.testShift(event)) {
            tree.multiselection.rangeEnd(treeitem.item.gid)
        } else {
            tree.multiselection.rangeStart(treeitem.item.gid)
            tree.selectItem(treeitem)
            if (!tree.multiselection.isSelected(treeitem.item.gid)) {
                tree.multiselection.rangeStart(treeitem.item.gid)
            }
        }
    }

    const TreeItems = tree.treeitems.map((treeitem, index) => {
        let label = isString(treeitem.item)
            ? app.text(treeitem.item)
            : worksheet.layoutstore.getItemTitle(treeitem.item, language)

        let classes = ''
        if (treeitem.item.is_new) {
            classes = 'status-created'
        } else if (treeitem.item.is_working_copy) {
            classes = 'status-modified'
        }
        if (
            !isString(treeitem.item) &&
            tree.multiselection.isSelected(treeitem.item.gid) &&
            !treeitem.isSelected
        ) {
            classes += ' cc-multiselected'
        }
        let selected = treeitem.isSelected
        if (
            isString(treeitem.item) ||
            (tree.multiselection.size > 0 &&
                selected &&
                !tree.multiselection.isSelected(treeitem.item.gid))
        ) {
            selected = false
        }
        let contextmenu =
            tree.multiselection.size > 1 &&
            tree.multiselection.isSelected(treeitem.item.gid)
                ? SchemaContextmenuPopover
                : undefined
        let icon = ''
        let draggable = true
        const itemtype =
            treeitem.item instanceof DefinitionData
                ? 'DefinitionData'
                : treeitem.item instanceof ClassData
                ? 'ClassData'
                : treeitem.item instanceof FieldData
                ? 'FieldData'
                : 'String'
        switch (itemtype) {
            case 'DefinitionData':
                if (!contextmenu) {
                    contextmenu = TreeSchemaDefinitionContextMenu
                }
                icon = 'definition'
                if (!treeitem.item.is_committed) draggable = false
                break
            case 'ClassData':
                if (!contextmenu) {
                    contextmenu = TreeSchemaClassContextMenu
                }
                icon = 'class'
                if (!treeitem.item.is_committed) draggable = false
                break
            case 'FieldData':
                if (!contextmenu) {
                    contextmenu = TreeSchemaFieldContextMenu
                }
                icon = 'field'
                if (!treeitem.item.is_committed) draggable = false
                break
            case 'String':
                draggable = false
                if (contextmenu) {
                    contextmenu = undefined
                } else {
                    if (treeitem.item === 'definitions') {
                        contextmenu = TreeSchemaDefinitionHeaderContextMenu
                    } else if (treeitem.item === 'classes') {
                        contextmenu = TreeSchemaClassHeaderContextMenu
                    } else if (treeitem.item === 'fields') {
                        contextmenu = TreeSchemaFieldHeaderContextMenu
                    }
                }
                label = app.text(
                    treeitem.item[0].toUpperCase() + treeitem.item.slice(1)
                )
                break
            default:
                break
        }

        const AnyTreeItem = (
            <TreeItem
                key={treeitem.id + '.' + index}
                title={label}
                state={treeitem.state}
                emptyicon={'empty'}
                selected={selected}
                indent={treeitem.depth}
                className={classes}
                onClick={event => onClickTreeItem(event, treeitem)}
                onExpand={() => {
                    tree.expandItem(treeitem)
                }}
                onCollapse={() => {
                    tree.collapseItem(treeitem)
                }}
                onContextMenu={e => {
                    if (!selected) {
                        tree.multiselection.deselectAll()
                        tree.selectItem(treeitem)
                    }
                    if (contextmenu) {
                        contextmenu.onShow(e)
                    }
                }}
            >
                <span>
                    {treeitem.parentid === 'root' ? (
                        ''
                    ) : (
                        <Icon className="itemtype" name={icon} size={'text'} />
                    )}
                    {treeitem.data && treeitem.data.is_link ? (
                        <Icon className="itemtype" name="link" size={'text'} />
                    ) : (
                        ''
                    )}
                    {maxChars(label, 40)}
                </span>
                {treeitem.parentid !== 'root' ? (
                    <div title={treeitem.item.name} className="cc-dimmed">
                        <Icon className="itemtype" name="empty" size={'text'} />
                        {maxChars(treeitem.item.name, 32)}
                    </div>
                ) : undefined}
            </TreeItem>
        )
        if (draggable) {
            return (
                <DragSchemaTreeItem
                    key={treeitem.id + '.' + index}
                    index={index}
                    height={itemheight}
                    tree={tree}
                    treeitem={treeitem}
                >
                    {AnyTreeItem}
                </DragSchemaTreeItem>
            )
        } else {
            return AnyTreeItem
        }
    })

    return (
        <>
            <SchemaContextmenuPopover.Panel
                schemaitem={tree.selectedItem}
                schemaitemsselection={tree.multiselection}
                onAction={onTreeSchemaAction}
            />
            <TreeSchemaDefinitionHeaderContextMenu.Panel
                onAction={onTreeSchemaAction}
            />
            <TreeSchemaDefinitionContextMenu.Panel
                definition={selectedSchemaItem}
                treeitem={tree.selectedItem}
                onAction={onTreeSchemaAction}
            />
            <TreeSchemaClassHeaderContextMenu.Panel onAction={onTreeSchemaAction} />
            <TreeSchemaClassContextMenu.Panel
                class_={selectedSchemaItem}
                onAction={onTreeSchemaAction}
            />
            <TreeSchemaFieldHeaderContextMenu.Panel onAction={onTreeSchemaAction} />
            <TreeSchemaFieldContextMenu.Panel
                field={selectedSchemaItem}
                onAction={onTreeSchemaAction}
            />
            <VScrollView className="cc-Tree" itemheight={itemheight}>
                {TreeItems}
            </VScrollView>
        </>
    )
})

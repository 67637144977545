//
// FieldPreview
//
// Show preview for a field, e.g. a barcode

import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Image } from '../components'

export const FieldPreview = observer(function FieldPreview({
    record,
    field,
    transform,
    className,
    ...other
}) {
    const { data, imageserver } = useStore()

    let classes = 'cc-FieldPreview'
    if (className) classes += ' ' + className

    const recordgid = record ? record.gid : null
    const fieldgid = field ? field.gid : null
    const fieldvalue =
        recordgid && fieldgid ? record.localized_fields.get(field.name) : null

    const [previewLoaded, updatePreviewLoaded] = useState(0) // height updates after load
    const handlePreviewLoad = () => {
        updatePreviewLoaded(previewLoaded + 1)
    }

    const [previewSrc, setPreviewSrc] = useState(null)
    useEffect(() => {
        if (!recordgid || !fieldgid || !transform || !fieldvalue || !fieldvalue.length)
            return
        let isMounted = true
        let controller = new AbortController()
        async function asyncEffect() {
            setPreviewSrc(null)
            const preview_pathname = await data.fetchFieldPreviewPathname(
                recordgid,
                fieldgid,
                fieldvalue
            )
            if (!preview_pathname) return
            const transformurl = imageserver.transformUrl(preview_pathname, transform)
            const imageurl = await imageserver.transformedUrl(transformurl, {
                signal: controller.signal,
            })
            if (isMounted) {
                setPreviewSrc(imageurl)
            }
        }
        asyncEffect()
        return () => {
            controller.abort('Unmounted')
            isMounted = false
            return isMounted
        }
    }, [recordgid, fieldgid, fieldvalue, transform, data, imageserver])

    const width = !transform || transform.width === null ? 'auto' : transform.width
    const height = !transform || transform.height === null ? 'auto' : transform.height

    if (!record || !field || !transform || !fieldvalue || !fieldvalue.length) {
        return null
    }

    return (
        <Image
            className={classes}
            alt={fieldvalue}
            title={fieldvalue}
            value={previewSrc}
            onLoad={handlePreviewLoad}
            style={{ width, height }}
            {...other}
        />
    )
})

//
// SchemaSearchPanel
//
// A search box and type (definition, class, field) filter, and results.
// Results can be selected and/or dragged. Inputs are backed by the
// SchemaQueryStore, so they are preserved over reloads or worksheet and
// workspace switches. This means if you use the search for schemas from within
// the PIM section, it starts where you left off searching the last time.

import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'
import { useDebounce } from '../hooks/useDebounce'
import { useTooltipPopover } from '../hooks/useTooltipPopover'
import { useMousePopover } from '../hooks/useMousePopover'
import { usePopoverContext } from '../components'

import { DragSchemaSearchItem } from '../dragdrop/DragSchemaSearchItem'
import {
    TreeSchemaDefinitionMenu,
    TreeSchemaClassMenu,
    TreeSchemaFieldMenu,
    SchemaContextmenu,
} from '../menus'
import { keyboard } from '../utils/keyboard'

import {
    HView,
    VView,
    View,
    LazyVScrollList,
    LazyScrollItem,
    Spacer,
    LayerView,
    Layer,
    Divider,
} from '../appview'
import { SearchInput, Item, Icon, Text, Header } from '../components'
import { SchemaItem } from '../panels'

import { QueryMenu, ModifiedQueryMenu, QuickSchemaQueries } from '../filters/FilterMenu'
import { FilterPanel } from '../filters/FilterPanel'
import { humanReadableNumber } from '../utils/text'
import { DefinitionData } from '../stores/data/DefinitionData'
import { ClassData } from '../stores/data/ClassData'
import { FieldData } from '../stores/data/FieldData'

const SearchResultItem = observer(function SearchResultItem({
    item,
    className,
    selected,
    onClick,
    ...other
}) {
    let classes = 'status-' + item.status
    if (className) classes += ' ' + className
    return (
        <Item
            selected={selected}
            indent={0}
            className={classes}
            onClick={onClick}
            {...other}
        >
            <SchemaItem item={item} />
        </Item>
    )
})

export const SchemaSearchPanel = observer(function SchemaSearchPanel({
    querystore,
    selectedSchemaGid,
    onSelect,
    preview,
    multiselect,
    viewkey,
    showMatches,
    ...other
}) {
    const { app, data, view } = useStore()
    const popoverContext = usePopoverContext()

    const [isRefreshing, setIsRefreshing] = useState(false)

    const QueryMenuPopover = useTooltipPopover(QueryMenu)
    const ModifiedQueryMenuPopover = useTooltipPopover(ModifiedQueryMenu)

    const itemheight = 36

    const onUpdate = (newtopindex, newwindowsize) => {
        if (
            newtopindex !== querystore.topindex ||
            newwindowsize !== querystore.windowsize
        ) {
            querystore.fetch(newtopindex, newwindowsize)
        }
    }

    const qref = React.useRef(querystore.q)
    const [searchtext, setSearchtext] = useState(querystore.q)
    const debouncedSearchtext = useDebounce(searchtext, 300)

    const selectedSchemaItem = selectedSchemaGid
        ? view.schemaworksheet.getItem(selectedSchemaGid)
        : null

    const onSearchinputChange = event => {
        setSearchtext(event.target.value)
    }
    const onSearchinputBlur = event => {
        setSearchtext(event.target.value.trim())
    }

    const onSearchRefresh = event => {
        querystore.refetch()
    }

    useEffect(() => {
        if (querystore.q === qref.current) {
            qref.current = debouncedSearchtext
            querystore.q = debouncedSearchtext
        } else {
            qref.current = querystore.q
            setSearchtext(querystore.q)
        }
    }, [querystore, querystore.q, debouncedSearchtext])

    useEffect(() => {
        querystore.fetch(0, querystore.windowsize)
    }, [
        querystore,
        querystore.q,
        querystore.filterstore.refreshkey,
        querystore.windowsize,
    ])

    const SchemaSearchResultDefinitionContextMenu = useMousePopover(
        TreeSchemaDefinitionMenu
    )
    const SchemaSearchResultClassContextMenu = useMousePopover(TreeSchemaClassMenu)
    const SchemaSearchResultFieldContextMenu = useMousePopover(TreeSchemaFieldMenu)
    const SchemaContextmenuPopover = useMousePopover(SchemaContextmenu)

    const onSearchResultItemClicked = (event, itemgid) => {
        if (multiselect) {
            onSearchResultItemClickedMultiSelect(event, itemgid)
        } else {
            onSearchResultItemClickedSingleSelect(event, itemgid)
        }
    }

    const onSearchResultItemClickedSingleSelect = (event, itemgid) => {
        event.stopPropagation()
        event.nativeEvent.stopImmediatePropagation()
        popoverContext.store.show([...popoverContext.stack])
        onSelect && onSelect(selectedSchemaGid === itemgid ? null : itemgid)
    }

    const onSearchResultItemClickedMultiSelect = (event, itemgid) => {
        event.stopPropagation()
        event.nativeEvent.stopImmediatePropagation()
        popoverContext.store.show([...popoverContext.stack])
        if (keyboard.testCommand(event)) {
            if (selectedSchemaGid === itemgid) {
                // selectedSchemaGid(null)
                onSelect && onSelect(null)
            }
            if (querystore.multiselection.isSelected(itemgid)) {
                querystore.multiselection.deselect(itemgid)
            } else {
                querystore.multiselection.addRangeStart(itemgid)
            }
        } else if (keyboard.testShift(event)) {
            if (!querystore.multiselection.size && selectedSchemaGid) {
                querystore.multiselection.rangeStart(selectedSchemaGid)
            } else if (!querystore.multiselection.size && selectedSchemaGid) {
                querystore.multiselection.rangeStart(selectedSchemaGid)
            }
            querystore.multiselection.rangeEnd(itemgid)
        } else {
            querystore.multiselection.rangeStart(itemgid)
            // selectedSchemaGid(itemgid)
            onSelect && onSelect(itemgid)
        }
    }

    const onSearchResultItemSelected = (event, itemgid) => {
        event.stopPropagation()
        event.nativeEvent.stopImmediatePropagation()
        popoverContext.store.show([...popoverContext.stack])
        onSelect && onSelect(itemgid)
    }

    const onCommitDefinition = () => {
        selectedSchemaItem.commit().then(result => {
            const modified_item = data.definitions.get(result['definition'])
            if (modified_item) {
                view.schematree.selectItem({
                    id: modified_item.gid,
                    item: modified_item,
                })
            }
            view.schemaquery.refetch()
        })
    }
    const onRevertDefinition = () => {
        selectedSchemaItem.revert().then(result => {
            const modified_item = data.definitions.get(result['definition'])
            if (modified_item) {
                view.schematree.selectItem({
                    id: modified_item.gid,
                    item: modified_item,
                })
            }
            view.schemaquery.refetch()
        })
    }
    const onSaveAsNewDefinition = () => {
        selectedSchemaItem.saveAsNew().then(result => {
            const modified_item = data.definitions.get(result['definition'])
            if (modified_item) {
                view.schematree.selectItem({
                    id: modified_item.gid,
                    item: modified_item,
                })
            }
            view.schemaquery.refetch()
        })
    }
    const onCopyDefinition = () => {
        selectedSchemaItem.copy().then(result => {
            const modified_item = data.definitions.get(result['definition'])
            if (modified_item) {
                view.schematree.selectItem({
                    id: modified_item.gid,
                    item: modified_item,
                })
            }
            view.schemaquery.refetch()
        })
    }
    const onDeleteDefinition = () => {
        view.schematree.deselectItem()
        selectedSchemaItem.delete().then(result => {
            view.schemaquery.refetch()
        })
    }

    const onCommitClass = () => {
        selectedSchemaItem.commit().then(result => {
            const modified_item = data.classes.get(result['class'])
            if (modified_item) {
                view.schematree.selectItem({
                    id: modified_item.gid,
                    item: modified_item,
                })
            }
            view.schemaquery.refetch()
        })
    }
    const onRevertClass = () => {
        selectedSchemaItem.revert().then(result => {
            const modified_item = data.classes.get(result['class'])
            if (modified_item) {
                view.schematree.selectItem({
                    id: modified_item.gid,
                    item: modified_item,
                })
            }
            view.schemaquery.refetch()
        })
    }
    const onSaveAsNewClass = () => {
        selectedSchemaItem.saveAsNew().then(result => {
            const modified_item = data.classes.get(result['class'])
            if (modified_item) {
                view.schematree.selectItem({
                    id: modified_item.gid,
                    item: modified_item,
                })
            }
            view.schemaquery.refetch()
        })
    }
    const onCopyClass = () => {
        selectedSchemaItem.copy().then(result => {
            const modified_item = data.classes.get(result['class'])
            if (modified_item) {
                view.schematree.selectItem({
                    id: modified_item.gid,
                    item: modified_item,
                })
            }
            view.schemaquery.refetch()
        })
    }
    const onDeleteClass = () => {
        view.schematree.deselectItem()
        selectedSchemaItem.delete().then(result => {
            view.schemaquery.refetch()
        })
    }

    const onCommitField = () => {
        selectedSchemaItem.commit().then(result => {
            const modified_item = data.fields.get(result['field'])
            if (modified_item) {
                view.schematree.selectItem({
                    id: modified_item.gid,
                    item: modified_item,
                })
            }
            view.schemaquery.refetch()
        })
    }
    const onRevertField = () => {
        selectedSchemaItem.revert().then(result => {
            const modified_item = data.fields.get(result['field'])
            if (modified_item) {
                view.schematree.selectItem({
                    id: modified_item.gid,
                    item: modified_item,
                })
            }
            view.schemaquery.refetch()
        })
    }
    const onSaveAsNewField = () => {
        selectedSchemaItem.saveAsNew().then(result => {
            const modified_item = data.fields.get(result['field'])
            if (modified_item) {
                view.schematree.selectItem({
                    id: modified_item.gid,
                    item: modified_item,
                })
            }
            view.schemaquery.refetch()
        })
    }
    const onCopyField = () => {
        selectedSchemaItem.copy().then(result => {
            const modified_item = data.fields.get(result['field'])
            if (modified_item) {
                view.schematree.selectItem({
                    id: modified_item.gid,
                    item: modified_item,
                })
            }
            view.schemaquery.refetch()
        })
    }
    const onDeleteField = () => {
        view.schematree.deselectItem()
        selectedSchemaItem.delete().then(result => {
            view.schemaquery.refetch()
        })
    }

    const onCommitSchemaSelection = () => {
        if (!querystore.multiselection.size) return
        data.actions.schema.bulkCommit(querystore.actionSelection)
    }

    const onRevertSchemaSelection = () => {
        if (!querystore.multiselection.size) return
        data.actions.schema.bulkRevert(querystore.actionSelection)
    }

    const onSaveAsNewSchemaSelection = () => {
        if (!querystore.multiselection.size) return
        data.actions.schema.bulkSaveAsNew(querystore.actionSelection)
    }

    const onCopySchemaSelection = () => {
        if (!querystore.multiselection.size) return
        data.actions.schema.bulkCopy(querystore.actionSelection)
    }

    const onDeleteSchemaSelection = () => {
        if (!querystore.multiselection.size) return
        data.actions.schema.bulkDelete(querystore.actionSelection)
    }

    const onSearchResultAction = action => {
        popoverContext.store.show([...popoverContext.stack])
        const actions = {
            commit_definition: onCommitDefinition,
            revert_definition: onRevertDefinition,
            save_as_new_definition: onSaveAsNewDefinition,
            copy_definition: onCopyDefinition,
            delete_definition: onDeleteDefinition,
            commit_class: onCommitClass,
            revert_class: onRevertClass,
            save_as_new_class: onSaveAsNewClass,
            copy_class: onCopyClass,
            delete_class: onDeleteClass,
            commit_field: onCommitField,
            revert_field: onRevertField,
            save_as_new_field: onSaveAsNewField,
            copy_field: onCopyField,
            delete_field: onDeleteField,
            commit_schema_selection: onCommitSchemaSelection,
            revert_schema_selection: onRevertSchemaSelection,
            save_as_new_schema_selection: onSaveAsNewSchemaSelection,
            copy_schema_selection: onCopySchemaSelection,
            delete_schema_selection: onDeleteSchemaSelection,
        }
        if (!(action in actions)) {
            console.log(
                `onSearchResultAction: unhandled action '${action}' on '${selectedSchemaItem.name}'`
            )
            return
        }
        if (actions[action]) {
            actions[action]()
        }
    }

    const onQueryMenuAction = (action, p1, p2, p3) => {
        switch (action) {
            case 'new':
                querystore.newQuery()
                break
            case 'reopen':
                querystore.reopenQuery()
                break
            case 'open':
                querystore.openQuery(p1) // gid
                break
            case 'cancel_open':
                break
            case 'save':
                querystore.saveQuery()
                break
            case 'save_as_new':
                querystore.saveNewQuery()
                break
            case 'delete':
                querystore.deleteQuery()
                break
            case 'quickquery':
                querystore.gid = null
                querystore.name = p1
                querystore.q = p2
                querystore.filter = p3
                break
            default:
                console.log(`onQueryMenuAction: unhandled action '${action}'`)
                break
        }
        QueryMenuPopover.hide()
        ModifiedQueryMenuPopover.hide()
    }

    const refreshResults = () => {
        if (isRefreshing) return
        setIsRefreshing(true)
        querystore.refetch().then(() => {
            window.setTimeout(() => {
                setIsRefreshing(false)
            }, 200)
        })
    }

    const SearchResultItems = querystore.totalsize ? (
        querystore.results.map(({ type, item }, index) => {
            const schemaitem =
                type === 'definition'
                    ? data.definitions.get(item)
                    : type === 'class'
                    ? data.classes.get(item)
                    : data.fields.get(item)
            if (!schemaitem) return null
            let selected = schemaitem.gid === selectedSchemaGid
            let classes = ''
            if (schemaitem.is_new) {
                classes = 'status-created'
            } else if (schemaitem.is_working_copy) {
                classes = 'status-modified'
            }

            if (querystore.multiselection.isSelected(item) && !selected) {
                classes += ' cc-multiselected'
            }
            if (
                multiselect &&
                querystore.multiselection.size > 0 &&
                selected &&
                !querystore.multiselection.isSelected(item)
            ) {
                selected = false
            }
            let contextmenu =
                querystore.multiselection.size > 1 &&
                querystore.multiselection.isSelected(schemaitem.gid)
                    ? SchemaContextmenuPopover
                    : undefined
            let draggable = true
            const itemtype =
                schemaitem instanceof DefinitionData
                    ? 'DefinitionData'
                    : schemaitem instanceof ClassData
                    ? 'ClassData'
                    : schemaitem instanceof FieldData
                    ? 'FieldData'
                    : 'String'
            switch (itemtype) {
                case 'DefinitionData':
                    if (!contextmenu) {
                        contextmenu = SchemaSearchResultDefinitionContextMenu
                    }
                    if (!schemaitem.is_committed) draggable = false
                    break
                case 'ClassData':
                    if (!contextmenu) {
                        contextmenu = SchemaSearchResultClassContextMenu
                    }
                    if (!schemaitem.is_committed) draggable = false
                    break
                case 'FieldData':
                    if (!contextmenu) {
                        contextmenu = SchemaSearchResultFieldContextMenu
                    }
                    if (!schemaitem.is_committed) draggable = false
                    break
                default:
                    break
            }

            const AnySearchResultItem = (
                <SearchResultItem
                    item={schemaitem}
                    selected={selected}
                    className={classes}
                    onClick={e => onSearchResultItemClicked(e, schemaitem.gid)}
                    onContextMenu={e => {
                        if (contextmenu) {
                            onSearchResultItemSelected(e, schemaitem.gid)
                            if (
                                !(
                                    multiselect &&
                                    querystore.multiselection.size > 1 &&
                                    querystore.multiselection.isSelected(schemaitem.gid)
                                )
                            ) {
                                querystore.multiselection.deselectAll()
                            }
                            contextmenu.onShow(e)
                        }
                    }}
                />
            )

            if (draggable) {
                return (
                    <LazyScrollItem
                        key={schemaitem.gid}
                        index={index + querystore.topindex}
                    >
                        <DragSchemaSearchItem
                            schemaitem={schemaitem}
                            querystore={querystore}
                        >
                            {AnySearchResultItem}
                        </DragSchemaSearchItem>
                    </LazyScrollItem>
                )
            } else {
                return (
                    <LazyScrollItem
                        key={schemaitem.gid}
                        index={index + querystore.topindex}
                    >
                        {AnySearchResultItem}
                    </LazyScrollItem>
                )
            }
        })
    ) : (querystore.q && querystore.q.trim().length) ||
      querystore.filterstore.hasFilters() ? (
        multiselect ? (
            <Text className="cc-Placeholder">
                {app.text('Change the search terms or the filter.')}
            </Text>
        ) : (
            <LayerView>
                <Text>
                    {app.text('No matches')}
                    {querystore.filterstore.hasFilters() && !querystore.showFilter ? (
                        <>
                            {' '}
                            <span
                                className="ws-tagbutton"
                                onClick={() => querystore.clearQueryFilters()}
                            >
                                {app.text('clear filters')}
                            </span>
                        </>
                    ) : undefined}
                </Text>
                <Layer anchor="end" style={{ lineHeight: 0, marginRight: 5 }}>
                    <Icon
                        onClick={refreshResults}
                        name="refresh"
                        title={app.text('Refresh')}
                        spin={isRefreshing ? 'quickly' : false}
                        size={1}
                    />
                </Layer>
            </LayerView>
        )
    ) : (
        <Text className="cc-Placeholder">
            {app.text('Type in the search bar or add a filter.')}
        </Text>
    )

    const activeFilterClassName = querystore.filterstore.hasFilters() ? 'cc-active' : ''

    const query = querystore.gid ? data.queries.get(querystore.gid) : null

    const Folder = (
        <>
            <QueryMenuPopover.Panel
                querystore={querystore}
                quickqueries={QuickSchemaQueries}
                onAction={onQueryMenuAction}
            />
            <View
                style={{
                    flexBasis: '20px',
                    minWidth: 20,
                    maxWidth: 20,
                    position: 'relative',
                    top: '2px',
                }}
            >
                <div
                    style={{ backgroundColor: 'transparent' }}
                    ref={QueryMenuPopover.anchorRef}
                    onClick={e => {
                        if (querystore.filterstore.hasFilters()) {
                            querystore.setShowFilter(true)
                        }
                        ModifiedQueryMenuPopover.hide(e)
                        QueryMenuPopover.onShow(e)
                    }}
                    onContextMenu={e => {
                        if (querystore.filterstore.hasFilters()) {
                            querystore.setShowFilter(true)
                        }
                        ModifiedQueryMenuPopover.hide(e)
                        QueryMenuPopover.onShow(e)
                    }}
                >
                    <Icon name="folder" size={2} />
                </div>
            </View>
        </>
    )

    showMatches = true

    return (
        <VView grow className="panel schema-search-panel" {...other}>
            {query ? (
                <HView style={{ marginLeft: 5, marginRight: 5 }}>
                    <View>
                        <Header>{query.name}</Header>
                    </View>
                    {querystore.is_modified ? (
                        <>
                            <ModifiedQueryMenuPopover.Panel
                                querystore={querystore}
                                onAction={onQueryMenuAction}
                            />
                            <Text>
                                <span
                                    className="ws-tag cc-info"
                                    ref={ModifiedQueryMenuPopover.anchorRef}
                                    onClick={e => {
                                        if (querystore.filterstore.hasFilters()) {
                                            querystore.setShowFilter(true)
                                        }
                                        QueryMenuPopover.hide(e)
                                        ModifiedQueryMenuPopover.onShow(e)
                                    }}
                                    onContextMenu={e => {
                                        if (querystore.filterstore.hasFilters()) {
                                            querystore.setShowFilter(true)
                                        }
                                        QueryMenuPopover.hide(e)
                                        ModifiedQueryMenuPopover.onShow(e)
                                    }}
                                >
                                    {app.text('modified')}
                                </span>
                            </Text>
                        </>
                    ) : undefined}
                    <Spacer grow />
                    {Folder}
                    <Spacer size={5} />
                </HView>
            ) : undefined}
            <HView style={{ marginLeft: 5, marginRight: 5 }}>
                <View grow>
                    <LayerView>
                        <SearchInput
                            value={searchtext}
                            placeholder={app.text('Search')}
                            onChange={onSearchinputChange}
                            onBlur={onSearchinputBlur}
                            onIconClick={onSearchRefresh}
                            icontitle={app.text('Click to refresh')}
                        />
                        <Layer anchor="end">
                            <View
                                style={{
                                    flexBasis: '20px',
                                    minWidth: 20,
                                    maxWidth: 20,
                                    position: 'relative',
                                    top: '1px',
                                    left: '-6px',
                                }}
                            >
                                <div
                                    className={activeFilterClassName}
                                    style={{ backgroundColor: 'transparent' }}
                                    onClick={e => {
                                        querystore.setShowFilter(!querystore.showFilter)
                                    }}
                                >
                                    <Icon name="filter" size={2} />
                                </div>
                            </View>
                        </Layer>
                    </LayerView>
                </View>
                {query ? undefined : (
                    <>
                        <Spacer size={5} />
                        {Folder}
                    </>
                )}
            </HView>
            {querystore.showFilter ? (
                <>
                    <FilterPanel filterstore={querystore.filterstore} />
                    <Divider style={{ margin: '0 5px' }} />
                </>
            ) : undefined}
            {showMatches ? (
                <>
                    <HView vcenter>
                        <Text>
                            {app.pluralized_text(
                                {
                                    0: 'No matches',
                                    1: '{formatted_count} match',
                                    many: '{formatted_count} matches',
                                },
                                querystore.totalsize,
                                {
                                    formatted_count: humanReadableNumber(
                                        querystore.totalsize
                                    ),
                                }
                            )}
                        </Text>
                        <div style={{ lineHeight: 0 }}>
                            <Icon
                                onClick={refreshResults}
                                name="refresh"
                                title={app.text('Refresh')}
                                spin={isRefreshing ? 'quickly' : false}
                                size={1}
                            />
                        </div>
                        <Spacer grow />
                        {querystore.filterstore.hasFilters() ||
                        querystore.showFilter ? (
                            <Text>
                                <span
                                    className="ws-tagbutton"
                                    onClick={() => querystore.clearQueryFilters()}
                                >
                                    {app.text('reset filters')}
                                </span>
                            </Text>
                        ) : undefined}
                    </HView>
                </>
            ) : undefined}
            {showMatches ? <Divider style={{ margin: '0 5px' }} /> : undefined}

            <SchemaContextmenuPopover.Panel
                schemaitem={selectedSchemaItem}
                schemaitemsselection={querystore.multiselection}
                onAction={onSearchResultAction}
            />
            <SchemaSearchResultDefinitionContextMenu.Panel
                definition={selectedSchemaItem}
                onAction={onSearchResultAction}
            />
            <SchemaSearchResultClassContextMenu.Panel
                class_={selectedSchemaItem}
                onAction={onSearchResultAction}
            />
            <SchemaSearchResultFieldContextMenu.Panel
                field={selectedSchemaItem}
                onAction={onSearchResultAction}
            />
            <LazyVScrollList
                className="search-results"
                firstindex={querystore.topindex}
                itemheight={itemheight}
                totalitems={querystore.totalsize}
                onUpdate={onUpdate}
            >
                {SearchResultItems}
            </LazyVScrollList>
        </VView>
    )
})

//
// Search lab
//
// Show plain text input, both single and multiline

import React, { useState, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'
import { useSelectPopover } from '../hooks/useSelectPopover'
import { autoCompleter } from '../stores/data/autocompleters'

import { HView, VView } from '../appview'
import { Text, Icon, FilterItem, TextInput, Select } from '../components'
import { DragFilterItem, DropFilterItem } from '../dragdrop/DragDropFilterItem'
import { SelectClassPanel, SelectDefinitionPanel, SelectFieldPanel } from '../panels'

import { sortSchemaItems } from '../stores/view/SchemaTreeStore'
import { fieldtitle } from '../stores/data/utils'

const FieldSelectorFilterPart = observer(function FieldSelectorFilterPart({
    className,
    fields,
    subselect,
    exclude,
    onSelectField,
    onClearField,
}) {
    const { app, view } = useStore()
    const language = view.environment.get('language')
    const fieldgid = fields && fields.length ? fields[0].gid : undefined

    const SelectFieldPopover = useSelectPopover(SelectFieldPanel)

    const _onSelectField = value => {
        onSelectField && onSelectField(value)
        SelectFieldPopover.hide()
    }

    const _onClearField = onClearField
        ? () => {
              onClearField && onClearField()
              SelectFieldPopover.hide()
          }
        : undefined

    let classes = 'filter-part filter-fieldselector'
    if (className) classes += ' ' + className

    const name = fields ? (
        fields.map(field => fieldtitle(field, language)).join(' - ')
    ) : onClearField ? (
        <span className="cc-dimmed">{app.text('<any field>')}</span>
    ) : (
        app.text('Select…')
    )

    return (
        <span
            className={classes}
            onClick={e => {
                SelectFieldPopover.onShow(e)
            }}
        >
            <SelectFieldPopover.Panel
                selected={fieldgid}
                subselect={subselect}
                exclude={exclude}
                onSelectField={_onSelectField}
                onClearField={_onClearField}
            />
            <div className="cc-Select" ref={SelectFieldPopover.anchorRef}>
                <div className="cc-Select-Value">{name}</div>
                <Icon name="expanded" size={'text'} className="cc-Select-Icon" />
            </div>
        </span>
    )
})

const ClassSelectorFilterPart = observer(function ClassSelectorFilterPart({
    className,
    class_,
    onSelectClass,
}) {
    const { app, view } = useStore()
    const language = view.environment.get('language')
    const classgid = class_ ? class_.gid : undefined

    const SelectClassPopover = useSelectPopover(SelectClassPanel)

    const _onSelectClass = value => {
        onSelectClass && onSelectClass(value)
        SelectClassPopover.hide()
    }

    let classes = 'filter-part filter-classselector'
    if (className) classes += ' ' + className

    return (
        <span
            className={classes}
            onClick={e => {
                SelectClassPopover.onShow(e)
            }}
        >
            <SelectClassPopover.Panel
                selected={classgid}
                onSelectClass={_onSelectClass}
            />
            <div className="cc-Select" ref={SelectClassPopover.anchorRef}>
                <div className="cc-Select-Value">
                    {class_
                        ? view.schemaworksheet.layoutstore.getItemTitle(
                              class_,
                              language
                          )
                        : app.text('Select…')}
                </div>
                <Icon name="expanded" size={'text'} className="cc-Select-Icon" />
            </div>
        </span>
    )
})

const DefinitionSelectorFilterPart = observer(function DefinitionSelectorFilterPart({
    className,
    definition,
    onSelectDefinition,
}) {
    const { app, view } = useStore()
    const language = view.environment.get('language')
    const definitiongid = definition ? definition.gid : undefined

    const SelectDefinitionPopover = useSelectPopover(SelectDefinitionPanel)

    const _onSelectDefinition = value => {
        onSelectDefinition && onSelectDefinition(value)
        SelectDefinitionPopover.hide()
    }

    let classes = 'filter-part filter-classselector'
    if (className) classes += ' ' + className

    return (
        <span
            className={classes}
            onClick={e => {
                SelectDefinitionPopover.onShow(e)
            }}
        >
            <SelectDefinitionPopover.Panel
                selected={definitiongid}
                onSelectDefinition={_onSelectDefinition}
            />
            <div className="cc-Select" ref={SelectDefinitionPopover.anchorRef}>
                <div className="cc-Select-Value">
                    {definition
                        ? view.schemaworksheet.layoutstore.getItemTitle(
                              definition,
                              language
                          )
                        : app.text('Select…')}
                </div>
                <Icon name="expanded" size={'text'} className="cc-Select-Icon" />
            </div>
        </span>
    )
})

const SelectFieldFilter = observer(function SelectFieldFilter({
    filterstore,
    filter,
    onClick,
    onContextMenu,
    onAction,
}) {
    const { data } = useStore()

    const onFieldChange = value => {
        onAction && onAction('update_arg', filter.key, value)
    }

    let fields
    if (filter.arg) {
        const dottedfieldkeys = filter.arg.split('.')
        fields = dottedfieldkeys
            .map(key => {
                let field = data.fields.get(key)
                if (!field) {
                    field = data.getFieldByName(key)
                }
                return field
            })
            .filter(field => field !== null)
    }

    return (
        <HView wrap>
            <span
                className="filter-part filter-property"
                onClick={onClick}
                onContextMenu={onContextMenu}
            >
                <Text>{filter.prop}</Text>
            </span>
            <span className="filter-part filter-operator">
                <Text>{filter.op}</Text>
            </span>
            <FieldSelectorFilterPart
                className="filter-operand"
                fields={fields}
                onSelectField={onFieldChange}
            />
        </HView>
    )
})

const SelectClassFilter = observer(function SelectClassFilter({
    filterstore,
    filter,
    onClick,
    onContextMenu,
    onAction,
}) {
    const { data } = useStore()

    const onClassChange = value => {
        onAction && onAction('update_arg', filter.key, value)
    }

    let class_
    if (filter.arg) {
        class_ = data.classes.get(filter.arg)
        if (!class_) {
            class_ = data.getClassByName(filter.arg)
        }
    }

    return (
        <HView wrap>
            <span
                className="filter-part filter-property"
                onClick={onClick}
                onContextMenu={onContextMenu}
            >
                <Text>{filter.prop}</Text>
            </span>
            <span className="filter-part filter-operator">
                <Text>{filter.op}</Text>
            </span>
            <ClassSelectorFilterPart
                className="filter-operand"
                class_={class_}
                onSelectClass={onClassChange}
            />
        </HView>
    )
})

const SelectDefinitionFilter = observer(function SelectDefinitionFilter({
    filterstore,
    filter,
    ops,
    onClick,
    onContextMenu,
    onAction,
}) {
    const { app, data } = useStore()

    const onOpChange = value => {
        onAction && onAction('update_op', filter.key, value)
    }

    const onDefinitionChange = value => {
        onAction && onAction('update_arg', filter.key, value)
    }

    let definition
    if (filter.arg) {
        definition = data.definitions.get(filter.arg)
        if (!definition) {
            definition = data.getDefinitionByName(filter.arg)
        }
    }

    if (!ops)
        ops = {
            has: app.text('has'),
        }
    const FilterOperator =
        Object.keys(ops).length === 1 ? (
            <Text>{filter.op}</Text>
        ) : (
            <Select value={filter.op} options={ops} onChange={onOpChange} />
        )

    return (
        <HView wrap>
            <span
                className="filter-part filter-property"
                onClick={onClick}
                onContextMenu={onContextMenu}
            >
                <Text>{filter.prop}</Text>
            </span>
            <span className="filter-part filter-operator">{FilterOperator}</span>
            <DefinitionSelectorFilterPart
                className="filter-operand"
                definition={definition}
                onSelectDefinition={onDefinitionChange}
            />
        </HView>
    )
})

const AddFilter = observer(function AddFilter({
    filterstore,
    filter,
    onClick,
    onContextMenu,
    onAction,
}) {
    return (
        <Icon
            name="plusbutton"
            size={1}
            onClick={onClick}
            onContextMenu={onContextMenu}
        />
    )
})

const AndFilter = observer(function AndFilter({
    filterstore,
    filter,
    className,
    clickfilterhandler,
    contextfilterhandler,
    onAction,
}) {
    const { app } = useStore()
    const onClick = e => clickfilterhandler && clickfilterhandler(e, filter.key, 'and')
    const onContext = e =>
        contextfilterhandler && contextfilterhandler(e, filter.key, 'and')
    let classes = 'filter-part filter-logic'
    if (className) classes += ' ' + className
    return (
        <span className={classes} onClick={onClick} onContextMenu={onContext}>
            <Text>{app.text('and')}</Text>
        </span>
    )
})

const OrFilter = observer(function OrFilter({
    filterstore,
    filter,
    className,
    clickfilterhandler,
    contextfilterhandler,
    onAction,
}) {
    const { app } = useStore()
    const onClick = e => clickfilterhandler && clickfilterhandler(e, filter.key, 'or')
    const onContext = e =>
        contextfilterhandler && contextfilterhandler(e, filter.key, 'or')
    let classes = 'filter-part filter-logic'
    if (className) classes += ' ' + className
    return (
        <span className={classes} onClick={onClick} onContextMenu={onContext}>
            <Text>{app.text('or')}</Text>
        </span>
    )
})

const NotFilter = observer(function NotFilter({
    filterstore,
    filter,
    className,
    clickfilterhandler,
    contextfilterhandler,
    onAction,
}) {
    const { app } = useStore()
    const onClick = e => clickfilterhandler && clickfilterhandler(e, filter.key, 'not')
    const onContext = e =>
        contextfilterhandler && contextfilterhandler(e, filter.key, 'not')
    let classes = 'filter-part filter-logic'
    if (className) classes += ' ' + className
    return (
        <span className={classes} onClick={onClick} onContextMenu={onContext}>
            <Text>{app.text('not')}</Text>
        </span>
    )
})

const TextFilter = observer(function TextFilter({
    filterstore,
    filter,
    fieldselector,
    textoptions,
    listoptions,
    autoComplete,
    onClick,
    onContextMenu,
    onAction,
}) {
    const { app } = useStore()
    const [value, setValue] = useState(filter.arg)

    const onOpChange = value => {
        onAction && onAction('update_op', filter.key, value)
    }

    const onArgChange = event => {
        setValue(event.target.value)
    }
    const onArgBlur = event => {
        const value = event.target.value.trim()
        setValue(value)
        onAction && onAction('update_arg', filter.key, value)
    }

    let options = {}
    if (textoptions) {
        options = {
            is: app.text('is'),
            q: app.text('contains'),
        }
    }
    if (listoptions) {
        if (textoptions) {
            options = {
                has: app.text('has'),
                has_not: app.text("doesn't have"),
            }
        }
        options = {
            ...options,
            '<=': app.text('max items'),
            '>=': app.text('min items'),
        }
    }

    return (
        <HView wrap>
            <span
                className="filter-part filter-property"
                onClick={onClick}
                onContextMenu={onContextMenu}
            >
                <Text>{filter.prop}</Text>
            </span>
            {fieldselector}
            <span className="filter-part filter-operator">
                <Select
                    value={options.hasOwnProperty(filter.op) ? filter.op : undefined}
                    options={options}
                    onChange={onOpChange}
                />
            </span>
            <span className="filter-part filter-operand">
                <TextInput
                    value={value}
                    onChange={onArgChange}
                    onBlur={onArgBlur}
                    autoComplete={autoComplete}
                />
            </span>
        </HView>
    )
})

const SelectFilter = observer(function SelectFilter({
    filterstore,
    filter,
    fieldselector,
    options,
    ops,
    onClick,
    onContextMenu,
    onAction,
}) {
    const { app } = useStore()

    const onOpChange = value => {
        onAction && onAction('update_op', filter.key, value)
    }

    const onArgChange = value => {
        onAction && onAction('update_arg', filter.key, value)
    }

    if (!ops)
        ops = {
            is: app.text('is'),
        }
    const FilterOperator =
        Object.keys(ops).length === 1 ? (
            <Text>{filter.op}</Text>
        ) : (
            <Select value={filter.op} options={ops} onChange={onOpChange} />
        )

    return (
        <HView wrap>
            <span
                className="filter-part filter-property"
                onClick={onClick}
                onContextMenu={onContextMenu}
            >
                <Text>{filter.prop}</Text>
            </span>
            {fieldselector}
            <span className="filter-part filter-operator">{FilterOperator}</span>
            <span className="filter-part filter-operand">
                <Select value={filter.arg} options={options} onChange={onArgChange} />
            </span>
        </HView>
    )
})

const PathFilter = observer(function PathFilter({
    filterstore,
    filter,
    onClick,
    onContextMenu,
    onAction,
}) {
    const { app, data } = useStore()
    const [value, setValue] = useState(filter.arg)

    const onOpChange = value => {
        onAction && onAction('update_op', filter.key, value)
    }

    const onArgChange = event => {
        setValue(event.target.value)
    }
    const onArgBlur = event => {
        const value = event.target.value.trim()
        setValue(value)
        onAction && onAction('update_arg', filter.key, value)
    }

    const autoCompleteFilter = useMemo(
        () => async q => {
            return data.actions
                .autocomplete_asset_filter('path', q)
                .then(successdata => {
                    return successdata['completions']
                })
        },
        [data]
    )

    return (
        <HView wrap>
            <span
                className="filter-part filter-property"
                onClick={onClick}
                onContextMenu={onContextMenu}
            >
                <Text>{filter.prop}</Text>
            </span>
            <span className="filter-part filter-operator">
                <Select
                    value={filter.op}
                    options={{
                        is: app.text('is'),
                        starts_with: app.text('starts_with'),
                    }}
                    onChange={onOpChange}
                />
            </span>
            <span className="filter-part filter-operand">
                <TextInput
                    value={value}
                    onChange={onArgChange}
                    onBlur={onArgBlur}
                    autoComplete={autoCompleteFilter}
                />
            </span>
        </HView>
    )
})

const BooleanFilter = observer(function BooleanFilter({
    filterstore,
    filter,
    fieldselector,
    onClick,
    onContextMenu,
    onAction,
}) {
    const { app } = useStore()

    const onOpChange = value => {
        onAction && onAction('update_op', filter.key, value)
    }

    return (
        <HView wrap>
            <span
                className="filter-part filter-property"
                onClick={onClick}
                onContextMenu={onContextMenu}
            >
                <Text>{filter.prop}</Text>
            </span>
            {fieldselector}
            <span className="filter-part filter-operator">
                <Select
                    value={filter.op}
                    options={{
                        is_true: app.text('is_true'),
                        is_false: app.text('is_false'),
                    }}
                    onChange={onOpChange}
                />
            </span>
        </HView>
    )
})

const NumberFilter = observer(function NumberFilter({
    filterstore,
    filter,
    fieldselector,
    autoComplete,
    onClick,
    onContextMenu,
    onAction,
}) {
    const { app } = useStore()
    const [value, setValue] = useState(filter.arg ? filter.arg.toString() : '')

    const onOpChange = value => {
        onAction && onAction('update_op', filter.key, value)
    }

    const onArgChange = event => {
        setValue(event.target.value)
    }
    const onArgBlur = event => {
        const value = event.target.value.trim()
        setValue(value)
        onAction && onAction('update_arg', filter.key, parseFloat(value))
    }

    return (
        <HView wrap>
            <span
                className="filter-part filter-property"
                onClick={onClick}
                onContextMenu={onContextMenu}
            >
                <Text>{filter.prop}</Text>
            </span>
            {fieldselector}
            <span className="filter-part filter-operator">
                <Select
                    value={filter.op}
                    options={{
                        '=': app.text('='),
                        '!=': app.text('!='),
                        '<': app.text('<'),
                        '<=': app.text('<='),
                        '>': app.text('>'),
                        '>=': app.text('>='),
                    }}
                    onChange={onOpChange}
                />
            </span>
            <span className="filter-part filter-operand">
                <TextInput
                    value={value}
                    onChange={onArgChange}
                    onBlur={onArgBlur}
                    autoComplete={autoComplete}
                />
            </span>
        </HView>
    )
})

const ValueFilter = observer(function ValueFilter({
    filterstore,
    filter,
    options,
    onClick,
    onContextMenu,
    onAction,
}) {
    const { app, data, view } = useStore()

    let fields
    let autocompleteField
    if (filter.arg && filter.arg[0]) {
        const dottedfieldkeys = filter.arg[0].split('.')
        fields = dottedfieldkeys
            .map(key => {
                let field = data.fields.get(key)
                if (!field) {
                    field = data.getFieldByName(key)
                }
                return field
            })
            .filter(field => field !== null)
        autocompleteField = fields[fields.length - 1]
    }

    const onFieldChange = value => {
        if (!onAction) return
        let deepestvalue = value.split('.').pop()
        let field = data.fields.get(deepestvalue)
        if (!field) {
            field = data.getFieldByName(deepestvalue)
        }

        onAction('update_arg', filter.key, [value, filter.arg ? filter.arg[1] : ''])
        // change field? change op to default for that type
        switch (field.type) {
            case 'number':
            case 'decimal':
                onAction('update_op', filter.key, '=')
                break
            case 'boolean':
                onAction('update_op', filter.key, 'is_true')
                break
            case 'text':
            case 'code':
            case 'barcode':
                onAction('update_op', filter.key, 'q')
                break
            case 'textline':
                onAction('update_op', filter.key, 'is')
                break
            case 'textlist':
            case 'imagelist':
            case 'filelist':
            case 'recordlist':
            case 'classlist':
                onAction('update_op', filter.key, '>=')
                break
            default:
                break
        }
    }

    const onFieldFilterAction = (action, key, value) => {
        if (action === 'update_arg') {
            onAction &&
                onAction('update_arg', filter.key, [
                    filter.arg ? filter.arg[0] : null,
                    value,
                ])
        } else {
            onAction && onAction(action, filter.key, value)
        }
    }

    const exclude_fieldtypes = [
        'record',
        //'recordlist',
        'image',
        //'imagelist',
        'file',
        //'filelist',
        'fieldpicker',
    ]

    const FieldSelector = (
        <FieldSelectorFilterPart
            fields={fields}
            subselect
            exclude={exclude_fieldtypes}
            onSelectField={onFieldChange}
        />
    )

    const fieldfilter = {
        key: filter.key,
        prop: app.text('field'),
        op: filter.op,
        arg: filter.arg ? filter.arg[1] : '',
    }

    const language = view.environment.get('language')
    const fieldAutoCompleter = autoCompleter(autocompleteField, language)
    const autoComplete = useMemo(() => {
        return fieldAutoCompleter
            ? async q => {
                  return fieldAutoCompleter(q)
              }
            : null
    }, [fieldAutoCompleter])

    if (!autocompleteField) {
        return (
            <HView wrap>
                <span
                    className="filter-part filter-property"
                    onClick={onClick}
                    onContextMenu={onContextMenu}
                >
                    <Text>{fieldfilter.prop}</Text>
                </span>
                {FieldSelector}
            </HView>
        )
    }

    switch (autocompleteField.type) {
        case 'number':
        case 'decimal':
            return (
                // no further fallthrough
                <NumberFilter
                    filterstore={filterstore}
                    filter={fieldfilter}
                    fieldselector={FieldSelector}
                    onClick={onClick}
                    onContextMenu={onContextMenu}
                    onAction={onFieldFilterAction}
                    autoComplete={autoComplete}
                />
            )
        case 'boolean':
            return (
                // no further fallthrough
                <BooleanFilter
                    filterstore={filterstore}
                    filter={fieldfilter}
                    fieldselector={FieldSelector}
                    onClick={onClick}
                    onContextMenu={onContextMenu}
                    onAction={onFieldFilterAction}
                />
            )
        case 'text':
        case 'textline':
        case 'textlist':
        case 'code':
        case 'barcode':
        default:
            return (
                <TextFilter
                    filterstore={filterstore}
                    filter={fieldfilter}
                    textoptions={
                        ['imagelist', 'filelist', 'recordlist', 'classlist'].includes(
                            autocompleteField.type
                        )
                            ? undefined
                            : true
                    }
                    listoptions={
                        [
                            'textlist',
                            'imagelist',
                            'filelist',
                            'recordlist',
                            'classlist',
                        ].includes(autocompleteField.type)
                            ? true
                            : undefined
                    }
                    fieldselector={FieldSelector}
                    onClick={onClick}
                    onContextMenu={onContextMenu}
                    onAction={onFieldFilterAction}
                    autoComplete={autoComplete}
                />
            )
    }
})

const DateTimeFilter = observer(function DateTimeFilter({
    filterstore,
    filter,
    fieldselector,
    options,
    onClick,
    onContextMenu,
    onAction,
}) {
    const { app } = useStore()
    const [value, setValue] = useState(filter.arg ? filter.arg[0].toString() : '7')

    const onOpChange = value => {
        onAction && onAction('update_op', filter.key, value)
    }

    const onArg0Change = event => {
        setValue(event.target.value)
    }
    const onArg0Blur = event => {
        let value = parseFloat(event.target.value.trim())
        value = isNaN(value) ? '' : value.toString()
        setValue(value)
        onAction &&
            onAction('update_arg', filter.key, [
                value,
                filter.arg ? filter.arg[1] : 'days',
            ])
    }

    const onArg1Change = value => {
        onAction &&
            onAction('update_arg', filter.key, [
                filter.arg ? filter.arg[0] : '7',
                value,
            ])
    }

    return (
        <HView wrap>
            <span
                className="filter-part filter-property"
                onClick={onClick}
                onContextMenu={onContextMenu}
            >
                <Text>{filter.prop}</Text>
            </span>
            {fieldselector}
            <span className="filter-part filter-operator">
                <Select
                    value={filter.op}
                    options={{
                        '<': app.text('less than'),
                        '>': app.text('more than'),
                    }}
                    onChange={onOpChange}
                />
            </span>
            <HView className="filter-part filter-operand">
                <TextInput value={value} onChange={onArg0Change} onBlur={onArg0Blur} />
                <Select
                    value={filter.arg ? filter.arg[1] : 'days'}
                    options={{
                        days: app.text('days ago'),
                    }}
                    onChange={onArg1Change}
                />
            </HView>
        </HView>
    )
})

const ValidationFilter = observer(function ValidationFilter({
    filterstore,
    filter,
    ops,
    options,
    onClick,
    onContextMenu,
    onAction,
}) {
    const { data } = useStore()

    const onOpChange = value => {
        onAction && onAction('update_op', filter.key, value)
    }

    const onArgChange = value => {
        onAction && onAction('update_arg', filter.key, value)
    }

    const onArgClear = () => {
        onAction && onAction('update_arg', filter.key, '')
    }

    let fields
    if (filter.arg) {
        const dottedfieldkeys = filter.arg.split('.')
        fields = dottedfieldkeys
            .map(key => {
                let field = data.fields.get(key)
                if (!field) {
                    field = data.getFieldByName(key)
                }
                return field
            })
            .filter(field => field !== null)
    }

    const OperandSelector =
        filterstore.doctype === 'record' ? (
            <FieldSelectorFilterPart
                fields={fields}
                subselect
                onSelectField={onArgChange}
                onClearField={onArgClear}
            />
        ) : (
            <span className="filter-part filter-operand">
                <Select value={filter.arg} options={options} onChange={onArgChange} />
            </span>
        )

    return (
        <HView wrap>
            <span
                className="filter-part filter-property"
                onClick={onClick}
                onContextMenu={onContextMenu}
            >
                <Text>{filter.prop}</Text>
            </span>
            {OperandSelector}
            <span className="filter-part filter-operator">
                <Select value={filter.op} options={ops} onChange={onOpChange} />
            </span>
        </HView>
    )
})

const ChilddefinitionsFilter = observer(function ChilddefinitionsFilter({
    filterstore,
    filter,
    options,
    onClick,
    onContextMenu,
    onAction,
}) {
    const onOpChange = value => {
        onAction && onAction('update_op', filter.key, value)
    }

    return (
        <HView wrap>
            <span
                className="filter-part filter-property"
                onClick={onClick}
                onContextMenu={onContextMenu}
            >
                <Text>{filter.prop}</Text>
            </span>
            <span className="filter-part filter-operator">
                <Select value={filter.op} options={options} onChange={onOpChange} />
            </span>
        </HView>
    )
})

const TypedFilter = observer(function TypedFilter({ ...props }) {
    const { app, data, view, project } = useStore()

    const { filterstore, filter } = props

    const language = view.environment ? view.environment.get('language') : null

    let ops
    let options

    switch (filter.prop) {
        case 'and':
        case 'or':
        case 'not':
            return null
        case 'name':
        case 'label':
        case 'title':
        case 'description':
        case 'unit':
            return <TextFilter textoptions={true} {...props} />
        case 'type':
            options =
                filterstore.doctype === 'asset'
                    ? { file: app.text('file'), image: app.text('image') }
                    : {
                          definition: app.text('definition'),
                          class: app.text('class'),
                          field: app.text('field'),
                      }
            return <SelectFilter {...props} options={options} />
        case 'path':
            return <PathFilter {...props} />
        case 'ext':
            return <TextFilter textoptions={true} {...props} />
        case 'colorspace':
            return (
                <SelectFilter
                    {...props}
                    options={{
                        srgb: app.text('sRGB'),
                        cmyk: app.text('CMYK'),
                        gray: app.text('Gray'),
                        rgb: app.text('RGB'),
                        cmy: app.text('CMY'),
                        cielab: app.text('CIELab'),
                        hcl: app.text('HCL'),
                        hsb: app.text('HSB'),
                        hsl: app.text('HSL'),
                        hwb: app.text('HWB'),
                        lab: app.text('Lab'),
                        log: app.text('Log'),
                        luv: app.text('Luv'),
                        ohta: app.text('OHTA'),
                        rec601luma: app.text('Rec601Luma'),
                        rec601ycbcr: app.text('Rec601YCbCr'),
                        rec709luma: app.text('Rec709Luma'),
                        rec709ycbcr: app.text('Rec709YCbCr'),
                        transparent: app.text('Transparent'),
                        xyz: app.text('XYZ'),
                        ycbcr: app.text('YCbCr'),
                        ycc: app.text('YCC'),
                        yiq: app.text('YIQ'),
                        ypbpr: app.text('YPbPr'),
                        yuv: app.text('YUV'),
                    }}
                />
            )
        case 'is_transparent':
        case 'is_animated':
        case 'is_localized':
        case 'is_required':
        case 'is_committed':
        case 'not_committed':
        case 'is_link':
        case 'is_deleted':
            return <BooleanFilter {...props} />
        case 'size':
        case 'width':
        case 'height':
        case 'aspect_ratio':
            return <NumberFilter {...props} />
        case 'updated_on':
            return <DateTimeFilter {...props} />
        case 'validation':
            {
                let field
                if (filter.arg) {
                    field = data.fields.get(filter.arg)
                    if (!field) {
                        field = data.getFieldByName(filter.arg)
                    }
                }
                const shared_ops = {
                    any: <span className="cc-dimmed">{app.text('<any failure>')}</span>,
                    is_empty: app.text('is_empty'),
                    not_translated: app.text('not_translated'),
                }
                let record_ops = {
                    invalid_input: app.text('invalid_input'),
                    is_required: app.text('is_required'),
                }
                if (field) {
                    switch (field.type) {
                        case 'text':
                            record_ops = {
                                ...record_ops,
                                ...{
                                    min_length: app.text('min_length'),
                                    max_length: app.text('max_length'),
                                },
                            }
                            break
                        case 'textline':
                            record_ops = {
                                ...record_ops,
                                ...{
                                    min_length: app.text('min_length'),
                                    max_length: app.text('max_length'),
                                    restrict_to_values: app.text('restrict_to_values'),
                                },
                            }
                            break
                        case 'textlist':
                        case 'recordlist':
                        case 'imagelist':
                        case 'filelist':
                            record_ops = {
                                ...record_ops,
                                ...{
                                    min_length: app.text('min_items'),
                                    max_length: app.text('max_items'),
                                },
                            }
                            break
                        case 'code':
                        case 'barcode':
                        case 'boolean':
                        case 'record':
                        case 'image':
                        case 'file':
                            // no extra validations
                            break
                        case 'number':
                            record_ops = {
                                ...record_ops,
                                ...{
                                    min_length: app.text('min'),
                                    max_length: app.text('max'),
                                },
                            }
                            break
                        case 'decimal':
                            record_ops = {
                                ...record_ops,
                                ...{
                                    min_length: app.text('min'),
                                    max_length: app.text('max'),
                                    decimals: app.text('decimals'),
                                },
                            }
                            break
                        default:
                            break
                    }
                } else {
                    record_ops = {
                        ...record_ops,
                        ...{
                            min: app.text('min'),
                            max: app.text('max'),
                            decimals: app.text('decimals'),
                            min_items: app.text('min_items'),
                            max_items: app.text('max_items'),
                            min_length: app.text('min_length'),
                            max_length: app.text('max_length'),
                            restrict_to_values: app.text('restrict_to_values'),
                        },
                    }
                }
                ops =
                    filterstore.doctype === 'record'
                        ? {
                              ...shared_ops,
                              ...record_ops,
                          }
                        : shared_ops

                options = new Map()
                options.set(
                    '',
                    <span className="cc-dimmed">{app.text('<any field>')}</span>
                )
                if (filterstore.doctype === 'record') {
                    const _sort = sortSchemaItems(language)
                    const fields = Array.from(data.fields.values())
                    const with_modifications = new Set()
                    fields
                        .filter(field => {
                            if (field.is_working_copy) {
                                with_modifications.add(field.original)
                            }
                            return true
                        })
                        .filter(field => {
                            return !with_modifications.has(field.gid)
                        })
                        .sort(_sort)
                        .forEach(field => {
                            options.set(
                                field.gid,
                                view.schemaworksheet.layoutstore.getItemTitle(
                                    field,
                                    language
                                )
                            )
                        })
                } else if (filterstore.doctype === 'asset') {
                    options.set('title', app.text('title'))
                    options.set('description', app.text('description'))
                } else if (filterstore.doctype === 'schema') {
                    options.set('label', app.text('label'))
                    options.set('initial', app.text('initial'))
                    options.set('values', app.text('values'))
                }
            }
            return <ValidationFilter {...props} ops={ops} options={options} />
        case 'definition':
            return (
                <SelectDefinitionFilter
                    {...props}
                    ops={{
                        is: app.text('is'),
                        is_exactly: app.text('is_exactly'),
                    }}
                />
            )
        case 'classes':
            return <SelectClassFilter {...props} />
        case 'fields':
            return <SelectFieldFilter {...props} />
        case 'channels':
            options = new Map()
            project.orderedchannels.forEach(channelkey => {
                if (channelkey === '__all__') return
                options.set(channelkey, project.channels.get(channelkey))
            })
            return (
                <SelectFilter
                    {...props}
                    ops={{
                        has: app.text('has'),
                    }}
                    options={options}
                />
            )
        case 'childdefinitions':
            if (filterstore.doctype === 'record') {
                return (
                    <ChilddefinitionsFilter
                        {...props}
                        options={{
                            mixed_children: app.text('mixed_children'),
                            foreign_children: app.text('foreign_children'),
                            expected_children: app.text('expected_children'),
                            unexpected_children: app.text('unexpected_children'),
                        }}
                    />
                )
            } else {
                return <SelectDefinitionFilter {...props} />
            }
        case 'field_type':
            return (
                <SelectFilter
                    {...props}
                    options={{
                        text: app.text('text'),
                        textline: app.text('textline'),
                        textlist: app.text('textlist'),
                        record: app.text('record'),
                        recordlist: app.text('recordlist'),
                        boolean: app.text('boolean'),
                        number: app.text('number'),
                        decimal: app.text('decimal'),
                        image: app.text('image'),
                        imagelist: app.text('imagelist'),
                        file: app.text('file'),
                        filelist: app.text('filelist'),
                        code: app.text('code'),
                        barcode: app.text('barcode'),
                        class: app.text('class'),
                        classlist: app.text('classlist'),
                    }}
                />
            )
        case 'value':
            return <ValueFilter {...props} />

        default:
            return (
                <span>
                    <span className="filter-part filter-property">{filter.prop}</span>
                    <span className="filter-part filter-operator">
                        {app.text('not implemented')}
                    </span>
                </span>
            )
    }
})

export const Filter = observer(function Filter({ ...props }) {
    const {
        filterstore,
        filter,
        selectedfilter,
        indent,
        clickfilterhandler,
        contextfilterhandler,
        onAction,
    } = props
    const { app } = useStore()

    const onClick = (e, key, action) => {
        clickfilterhandler && clickfilterhandler(e, key, action)
    }
    const onContext = (e, key, action) => {
        contextfilterhandler && contextfilterhandler(e, key, action)
    }

    const onDrop = (dragitem, dropfilteritem, before_key) => {
        filterstore.moveFilter(dragitem.id, dropfilteritem.key, before_key)
    }

    switch (filter.prop) {
        case 'and':
        case 'or':
        case 'not':
            // handle this later
            break
        default:
            return (
                <DragFilterItem filteritem={filter} filterstore={filterstore}>
                    <FilterItem
                        selected={selectedfilter === filter.key}
                        indent={indent}
                        onClick={e => onClick(e, filter.key, 'filter')}
                        onContextMenu={e => onContext(e, filter.key, 'filter')}
                    >
                        <TypedFilter
                            {...props}
                            onClick={e => onClick(e, filter.key, 'filter')}
                            onContextMenu={e => onContext(e, filter.key, 'filter')}
                            onAction={onAction}
                        />
                    </FilterItem>
                </DragFilterItem>
            )
    }

    let Operands = []
    let LogicFilter
    switch (filter.prop) {
        case 'and':
            LogicFilter = AndFilter
            if (filter.arg) {
                filter.arg.forEach((arg_key, index) => {
                    if (arg_key === null) return
                    const operand_filter = filterstore._filters.get(arg_key)
                    switch (operand_filter.prop) {
                        case 'and':
                        case 'or':
                        case 'not':
                            Operands.push(
                                <DropFilterItem
                                    key={arg_key + '-' + index.toString() + '-group'}
                                    filteritem={filter}
                                    filterstore={filterstore}
                                    before_key={operand_filter.key}
                                    onDrop={onDrop}
                                >
                                    <HView vcenter wrap className="filter-line compact">
                                        <LogicFilter
                                            filterstore={filterstore}
                                            filter={{
                                                key: filter.key,
                                                prop: 'and',
                                                op: '',
                                                arg: [],
                                            }}
                                            clickfilterhandler={clickfilterhandler}
                                            contextfilterhandler={contextfilterhandler}
                                            onAction={onAction}
                                        />
                                    </HView>
                                </DropFilterItem>
                            )
                            Operands.push(
                                <Filter
                                    key={arg_key + '-' + index.toString()}
                                    filterstore={filterstore}
                                    filter={operand_filter}
                                    selectedfilter={selectedfilter}
                                    indent={1}
                                    clickfilterhandler={clickfilterhandler}
                                    contextfilterhandler={contextfilterhandler}
                                    onAction={onAction}
                                />
                            )
                            break
                        default:
                            Operands.push(
                                <DropFilterItem
                                    key={arg_key + '-' + index.toString()}
                                    filteritem={filter}
                                    filterstore={filterstore}
                                    before_key={operand_filter.key}
                                    onDrop={onDrop}
                                >
                                    <HView vcenter wrap className="filter-line">
                                        {index > 0 ? (
                                            <LogicFilter
                                                filterstore={filterstore}
                                                filter={{
                                                    key: filter.key,
                                                    prop: 'and',
                                                    op: '',
                                                    arg: [],
                                                }}
                                                clickfilterhandler={clickfilterhandler}
                                                contextfilterhandler={
                                                    contextfilterhandler
                                                }
                                                onAction={onAction}
                                            />
                                        ) : undefined}
                                        <Filter
                                            filterstore={filterstore}
                                            filter={operand_filter}
                                            selectedfilter={selectedfilter}
                                            clickfilterhandler={clickfilterhandler}
                                            contextfilterhandler={contextfilterhandler}
                                            onAction={onAction}
                                        />
                                    </HView>
                                </DropFilterItem>
                            )
                            break
                    }
                })
            }
            break
        case 'or':
            LogicFilter = OrFilter
            if (filter.arg) {
                filter.arg.forEach((arg_key, index) => {
                    if (arg_key === null) return
                    const operand_filter = filterstore._filters.get(arg_key)
                    switch (operand_filter.prop) {
                        case 'and':
                        case 'or':
                        case 'not':
                            Operands.push(
                                <DropFilterItem
                                    key={arg_key + '-' + index.toString() + '-group'}
                                    filteritem={filter}
                                    filterstore={filterstore}
                                    before_key={operand_filter.key}
                                    onDrop={onDrop}
                                >
                                    <HView vcenter wrap className="filter-line compact">
                                        <LogicFilter
                                            filterstore={filterstore}
                                            filter={{
                                                key: filter.key,
                                                prop: 'or',
                                                op: '',
                                                arg: [],
                                            }}
                                            clickfilterhandler={clickfilterhandler}
                                            contextfilterhandler={contextfilterhandler}
                                            onAction={onAction}
                                        />
                                    </HView>
                                </DropFilterItem>
                            )
                            Operands.push(
                                <Filter
                                    key={arg_key + '-' + index.toString()}
                                    filterstore={filterstore}
                                    filter={operand_filter}
                                    selectedfilter={selectedfilter}
                                    indent={1}
                                    clickfilterhandler={clickfilterhandler}
                                    contextfilterhandler={contextfilterhandler}
                                    onAction={onAction}
                                />
                            )
                            break
                        default:
                            Operands.push(
                                <DropFilterItem
                                    key={arg_key + '-' + index.toString()}
                                    filteritem={filter}
                                    filterstore={filterstore}
                                    before_key={operand_filter.key}
                                    onDrop={onDrop}
                                >
                                    <HView vcenter wrap className="filter-line">
                                        {index > 0 ? (
                                            <LogicFilter
                                                filterstore={filterstore}
                                                filter={{
                                                    key: filter.key,
                                                    prop: 'or',
                                                    op: '',
                                                    arg: [],
                                                }}
                                                clickfilterhandler={clickfilterhandler}
                                                contextfilterhandler={
                                                    contextfilterhandler
                                                }
                                                onAction={onAction}
                                            />
                                        ) : undefined}
                                        <Filter
                                            filterstore={filterstore}
                                            filter={operand_filter}
                                            selectedfilter={selectedfilter}
                                            clickfilterhandler={clickfilterhandler}
                                            contextfilterhandler={contextfilterhandler}
                                            onAction={onAction}
                                        />
                                    </HView>
                                </DropFilterItem>
                            )
                            break
                    }
                })
            }
            break
        case 'not':
            LogicFilter = NotFilter
            if (filter.arg) {
                const operand_filter = filterstore._filters.get(filter.arg)
                switch (operand_filter.prop) {
                    case 'and':
                    case 'or':
                    case 'not':
                        Operands.push(
                            <DropFilterItem
                                key={filter.arg + '-group'}
                                filteritem={filter}
                                filterstore={filterstore}
                                onDrop={onDrop}
                            >
                                <HView vcenter wrap className="filter-line compact">
                                    <LogicFilter
                                        filterstore={filterstore}
                                        filter={{
                                            key: filter.key,
                                            prop: 'not',
                                            op: '',
                                            arg: [],
                                        }}
                                        clickfilterhandler={clickfilterhandler}
                                        contextfilterhandler={contextfilterhandler}
                                        onAction={onAction}
                                    />
                                </HView>
                            </DropFilterItem>
                        )
                        Operands.push(
                            <Filter
                                key={filter.arg}
                                filterstore={filterstore}
                                filter={operand_filter}
                                selectedfilter={selectedfilter}
                                indent={1}
                                clickfilterhandler={clickfilterhandler}
                                contextfilterhandler={contextfilterhandler}
                                onAction={onAction}
                            />
                        )
                        break
                    default:
                        Operands.push(
                            <DropFilterItem
                                key={filter.arg + '-group'}
                                filteritem={filter}
                                filterstore={filterstore}
                                onDrop={onDrop}
                            >
                                <HView vcenter wrap className="filter-line compact">
                                    <LogicFilter
                                        filterstore={filterstore}
                                        filter={{
                                            key: filter.key,
                                            prop: 'not',
                                            op: '',
                                            arg: [],
                                        }}
                                        clickfilterhandler={clickfilterhandler}
                                        contextfilterhandler={contextfilterhandler}
                                        onAction={onAction}
                                    />
                                    <Filter
                                        key={filter.arg}
                                        filterstore={filterstore}
                                        filter={operand_filter}
                                        selectedfilter={selectedfilter}
                                        clickfilterhandler={clickfilterhandler}
                                        contextfilterhandler={contextfilterhandler}
                                        onAction={onAction}
                                    />
                                </HView>
                            </DropFilterItem>
                        )
                        break
                }
                return (
                    <DragFilterItem filteritem={filter} filterstore={filterstore}>
                        <FilterItem selected={selectedfilter === filter.key} indent={0}>
                            {Operands}
                        </FilterItem>
                    </DragFilterItem>
                )
            } else {
                return (
                    <DragFilterItem filteritem={filter} filterstore={filterstore}>
                        <FilterItem selected={selectedfilter === filter.key} indent={0}>
                            <VView>
                                <DropFilterItem
                                    filteritem={filter}
                                    filterstore={filterstore}
                                    onDrop={onDrop}
                                >
                                    <HView wrap vcenter className="filter-line compact">
                                        <LogicFilter
                                            className="cc-Placeholder-inline"
                                            filterstore={filterstore}
                                            filter={{
                                                key: filter.key,
                                                prop: 'x',
                                                op: '',
                                                arg: [],
                                            }}
                                            clickfilterhandler={clickfilterhandler}
                                            contextfilterhandler={contextfilterhandler}
                                            onAction={onAction}
                                        />
                                        <AddFilter
                                            {...props}
                                            onClick={e =>
                                                onClick(
                                                    e,
                                                    filter.key,
                                                    'add-' + filterstore.doctype
                                                )
                                            }
                                            onContextMenu={e =>
                                                onContext(
                                                    e,
                                                    filter.key,
                                                    'add-' + filterstore.doctype
                                                )
                                            }
                                            onAction={onAction}
                                        />
                                    </HView>
                                </DropFilterItem>
                            </VView>
                        </FilterItem>
                    </DragFilterItem>
                )
            }
        default:
    }

    return (
        <DragFilterItem filteritem={filter} filterstore={filterstore}>
            <FilterItem
                selected={selectedfilter === filter.key}
                indent={indent}
                onClick={e => onClick(e, filter.key, filter.prop)}
                onContextMenu={e => onContext(e, filter.key, filter.prop)}
            >
                <VView>
                    {Operands}
                    <DropFilterItem
                        filteritem={filter}
                        filterstore={filterstore}
                        onDrop={onDrop}
                    >
                        <HView
                            vcenter
                            wrap
                            className={
                                'filter-line compact' +
                                (filter.arg.length && filter.arg.length > 0
                                    ? ''
                                    : ' filter-add-filter')
                            }
                        >
                            {filter.arg.length && filter.arg.length > 0 ? (
                                <LogicFilter
                                    className="cc-Placeholder-inline"
                                    filterstore={filterstore}
                                    filter={{
                                        key: filter.key,
                                        prop: 'x',
                                        op: '',
                                        arg: [],
                                    }}
                                    clickfilterhandler={clickfilterhandler}
                                    contextfilterhandler={contextfilterhandler}
                                    onAction={onAction}
                                />
                            ) : (
                                <span
                                    className="filter-part filter-logic cc-dimmed"
                                    onClick={e => onClick(e, filter.key, filter.prop)}
                                    onContextMenu={e =>
                                        onContext(e, filter.key, filter.prop)
                                    }
                                >
                                    <Text>{app.text('add filter')}</Text>
                                </span>
                            )}
                            <AddFilter
                                {...props}
                                onClick={e =>
                                    onClick(e, filter.key, 'add-' + filterstore.doctype)
                                }
                                onContextMenu={e =>
                                    onContext(
                                        e,
                                        filter.key,
                                        'add-' + filterstore.doctype
                                    )
                                }
                                onAction={onAction}
                            />
                        </HView>
                    </DropFilterItem>
                </VView>
            </FilterItem>
        </DragFilterItem>
    )
})

// Autocompleters can autocomplete a fields value based on type and options.

import {
    calculateEAN8Checksum,
    calculateEAN13Checksum,
    calculateUPCAChecksum,
} from '../../utils/barcode'
import { listFromValues } from './utils'

export const autoCompleter = (field, optional_language) => {
    const fieldAutoCompleters = {
        textline: autoCompleterValues,
        textlist: autoCompleterValues, // per line
        barcode: autoCompleterBarcode,
    }
    if (!field || !(field.type in fieldAutoCompleters)) {
        return undefined
    }
    return fieldAutoCompleters[field.type](field, optional_language)
}

const autoCompleterValues = (field, optional_language) => {
    const values = listFromValues(field.options.get('values'), optional_language)
    const autocompletevalues = values
        ? values.filter(value => value && value.trim().length > 0)
        : []
    if (!autocompletevalues.length) return undefined
    const autoComplete = q => {
        const findq = q.trim().toLowerCase()
        let filteredautocompletevalues = autocompletevalues.filter(
            value =>
                !findq.length ||
                (value.toLowerCase().includes(findq) && value.toLowerCase() !== findq)
        )
        if (!filteredautocompletevalues.length) return autocompletevalues
        return filteredautocompletevalues
    }
    return autoComplete
}

const autoCompleterBarcode = (field, optional_language) => {
    const format = field.options.get('format')
    const autocompleteChecksums = {
        'EAN-8': calculateEAN8Checksum,
        'EAN-13': calculateEAN13Checksum,
        'UPC-A': calculateUPCAChecksum,
    }
    if (!(format in autocompleteChecksums)) return undefined

    const autoComplete = q => {
        try {
            const checksum = autocompleteChecksums[format](q)
            return [q + checksum.toString()]
        } catch (error) {
            return []
        }
    }
    return autoComplete
}

//
// AutogenerateLayoutBuilder
//
// For definitions: list all classes and fields
// For classes: list all fields

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'

import { HView, VView } from '../../../../appview'
import { Text } from '../../../../components'
import { ClassLayoutBuilder, FieldLayoutBuilder } from '.'
import { LAYOUTCOMPONENTTYPECONFIG } from '../../../../stores/data/LAYOUTCOMPONENTTYPECONFIG'

export const AutogenerateLayoutBuilder = observer(function AutogenerateLayoutBuilder({
    className,
    component,
    components,
    layout,
    record,
    worksheet,
    active_class,
    visited_gids,
    force_readonly,
}) {
    const { app, data } = useStore()

    let definition = data.definitions.get(record.definition)
    const base_definition =
        definition && definition.original
            ? worksheet.definitions.get(definition.original)
            : null
    if (base_definition) {
        definition = base_definition
    }

    const language = worksheet.environment.get('language')
    const config = LAYOUTCOMPONENTTYPECONFIG['autogenerate']
    const editable =
        'editable' in component ? component.editable : config.editable.default

    const classes = active_class ? [] : definition.classes
    const fields = active_class ? active_class.fields : definition.fields

    let Items = []

    if (!active_class && fields.length) {
        Items.push(
            <Text key={definition.gid + '.fields'} className="ws-section-header">
                {app.text('Fields')}
            </Text>
        )
    }
    let LeftItems = []
    let RightItems = []
    fields.forEach((field_gid, index) => {
        const field = data.fields.get(field_gid)
        if (field) {
            const fieldtypeconfig =
                LAYOUTCOMPONENTTYPECONFIG['field']['typeconfig'][field.type]
            const fieldtypestyle = fieldtypeconfig['style']
                ? fieldtypeconfig['style']['default']
                : undefined
            const fieldtypelayout = fieldtypeconfig['layout']

            const fieldlayoutbuildercomponent = {
                gid: '-not-selectable-' + index,
                field: field.gid,
                placeholder: '',
                editable: editable,
                style: fieldtypestyle,
                width: 250, // image
                columns: 3, // imagelist
            }

            const fieldlayout = (
                <FieldLayoutBuilder
                    key={
                        (active_class ? active_class.gid : definition.gid) +
                        '.' +
                        field.gid +
                        '.' +
                        index
                    }
                    component={fieldlayoutbuildercomponent}
                    components={{}}
                    layout={layout}
                    record={record}
                    worksheet={worksheet}
                    active_class={active_class}
                    active_field={field}
                    visited_gids={visited_gids}
                />
            )
            if (fieldtypelayout === 'right') {
                RightItems.push(fieldlayout)
            } else {
                LeftItems.push(fieldlayout)
            }
        }
    })
    if (LeftItems.length || RightItems.length) {
        Items.push(
            <HView key={'fields'} gap={10}>
                <VView grow>{LeftItems}</VView>
                <VView style={{ flexBasis: 250, maxWidth: 250, minWidth: 250 }}>
                    {RightItems}
                </VView>
            </HView>
        )
    }

    classes.forEach((class_gid, index) => {
        const class_ = data.classes.get(class_gid)
        if (class_) {
            const label = class_.label.get(language) || class_.name
            let sectionclasses = 'ws-section-header'
            if (
                class_.gid === worksheet.selected.classgid &&
                !worksheet.selected.property.fieldgid
            ) {
                sectionclasses += ' ws-selected'
            }
            Items.push(
                <Text
                    key={class_.gid + '.header.' + index}
                    className={sectionclasses}
                    onClick={() =>
                        worksheet.setSelected(
                            worksheet.record,
                            class_.gid,
                            null,
                            class_.gid,
                            null
                        )
                    }
                >
                    {label} <span className="ws-tag">{app.text('class')}</span>
                </Text>
            )

            const classlayoutbuildercomponent = {
                gid: '-not-selectable-' + index,
                class: class_.gid,
                placeholder: '',
                editable: editable,
                width: 250, // image
                columns: 3, // imagelist
            }
            Items.push(
                <ClassLayoutBuilder
                    key={class_.gid + '.' + index}
                    component={classlayoutbuildercomponent}
                    components={{}}
                    layout={layout}
                    record={record}
                    worksheet={worksheet}
                    active_class={class_}
                    visited_gids={visited_gids}
                />
            )
        }
    })

    let attributes = { className: 'layout-component' }
    if (className) attributes['className'] += ' ' + className
    if (component.gid === worksheet.layoutstore.selectedComponentGid) {
        attributes['className'] += ' cc-selected'
    }
    // attributes["onClick"] = e => onClickElement(e, component, layouttreestore)
    return <VView {...attributes}>{Items}</VView>
})

//
// ProjectFieldlistsMenu
//
// Actions on project fieldlists in the project settings

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Menu, MenuItem } from '../components'
import { fieldtitle } from '../stores/data/utils'

export const ClassFieldSubmenu = observer(function ClassFieldSubmenu({
    language,
    prefix,
    classfield,
    onSelectClassField,
}) {
    const { data } = useStore()

    const classgid = classfield.options.get('class')
    const class_ = classgid ? data.classes.get(classgid) : undefined
    if (!class_) return null

    const selectClassField = classfieldgid => {
        onSelectClassField && onSelectClassField(prefix + '.' + classfieldgid)
    }

    const fields = class_.fields
        .map(fieldgid => data.fields.get(fieldgid))
        .filter(field => field !== null)
    const menuitems = fields.map(field => {
        const name = fieldtitle(field, language)
        const classfieldsubmenu = ['class', 'classlist'].includes(field.type) ? (
            <ClassFieldSubmenu
                language={language}
                prefix={prefix + '.' + field.gid}
                classfield={field}
                onSelectClassField={onSelectClassField}
            />
        ) : undefined

        return (
            <MenuItem
                key={field.gid}
                submenu={classfieldsubmenu}
                onClick={e => selectClassField(field.gid)}
            >
                {name}
            </MenuItem>
        )
    })

    return <Menu>{menuitems}</Menu>
})

export const FieldlistMenu = observer(function FieldlistMenu({
    project,
    worksheet,
    fieldlistkey,
    onAction,
}) {
    const { app } = useStore()
    const language = worksheet.environment.get('language')

    const _onAction = (e, action, fieldgid) => {
        onAction && onAction(e, action, fieldlistkey, fieldgid)
    }

    const onSelectClassField = dottedfieldgids => {
        onAction && onAction(null, 'add_fieldlist_field', fieldlistkey, dottedfieldgids)
    }

    const fields = worksheet.getRecordFields(worksheet.record)
    const Fields = fields.map(field => {
        let fieldname = fieldtitle(field, language)
        const classfieldsubmenu = ['class', 'classlist'].includes(field.type) ? (
            <ClassFieldSubmenu
                language={language}
                prefix={field.gid}
                classfield={field}
                onSelectClassField={onSelectClassField}
            />
        ) : undefined

        return (
            <MenuItem
                key={field.gid}
                submenu={classfieldsubmenu}
                onClick={e => _onAction(e, 'add_fieldlist_field', field.gid)}
            >
                {fieldname}
            </MenuItem>
        )
    })
    const FieldsSubmenu = <Menu>{Fields}</Menu>

    return (
        <Menu>
            <MenuItem submenu={FieldsSubmenu}>{app.text('Add field...')}</MenuItem>
        </Menu>
    )
})

export const FieldlistFieldMenu = observer(function FieldlistFieldMenu({
    project,
    worksheet,
    fieldlistkey,
    fieldgid,
    onAction,
}) {
    const { app } = useStore()

    const _onAction = (e, action) => {
        onAction && onAction(e, action, fieldlistkey, fieldgid)
    }

    return (
        <Menu>
            <MenuItem onClick={e => _onAction(e, 'remove_fieldlist_field')}>
                {app.text('Remove field from fieldlist')}
            </MenuItem>
        </Menu>
    )
})

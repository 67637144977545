//
// ProjectScreen
//
// The core of the app, where all the work is done.
//
// A project has multiple workspaces, like PIM, DAM, Import/Export, Settings, etc.
// These workspaces have a shared header, where we keep the app's about box, the
// workspace switcher, project name, and user info (with project switcher and logout).
//
// The workspaces themselves are found in the ../workspaces folder.

import React from 'react'
import { useStore } from '../stores'
import { observer } from 'mobx-react-lite'

import { AppScreen } from './AppScreen'
import {
    LoadingWorkspace,
    BusyWorkspace,
    LoginWorkspace,
    AccountWorkspace,
    SettingsWorkspace,
    SchemaWorkspace,
    PimWorkspace,
    DamWorkspace,
    ImportExportWorkspace,
    NotImplementedWorkspace,
    LabWorkspace,
} from '../workspaces'

export const ProjectScreen = observer(function ProjectScreen(props) {
    const store = useStore()

    let Workspace = <LoadingWorkspace />
    if (store.is_busy) {
        Workspace = <BusyWorkspace />
    } else if (!store.view.user) {
        // deprecated: we should never get here
        Workspace = <LoginWorkspace />
    } else if (store.view.user && store.view.project) {
        switch (store.view.workspace) {
            case 'account':
                Workspace = (
                    <AccountWorkspace
                        grow
                        user={store.user}
                        project={store.project}
                        viewkey={store.userkey + '/account'}
                    />
                )
                break
            case 'settings':
                Workspace = (
                    <SettingsWorkspace
                        grow
                        user={store.user}
                        project={store.project}
                        viewkey={store.projectkey + '/settings'}
                    />
                )
                break
            case 'schema':
                Workspace = (
                    <SchemaWorkspace
                        grow
                        user={store.user}
                        project={store.project}
                        viewkey={store.projectkey + '/def'}
                    />
                )
                break
            case 'pim':
                Workspace = (
                    <PimWorkspace
                        grow
                        user={store.user}
                        project={store.project}
                        viewkey={store.projectkey + '/pim'}
                    />
                )
                break
            case 'dam':
                Workspace = (
                    <DamWorkspace
                        grow
                        user={store.user}
                        project={store.project}
                        viewkey={store.projectkey + '/dam'}
                    />
                )
                break
            case 'importexport':
                Workspace = (
                    <ImportExportWorkspace
                        grow
                        user={store.user}
                        project={store.project}
                        viewkey={store.projectkey + '/io'}
                    />
                )
                break
            case 'lab':
                Workspace = <LabWorkspace grow />
                break
            default:
                Workspace = (
                    <NotImplementedWorkspace grow label={store.view.workspace} />
                )
        }
    }

    return (
        <AppScreen user={store.user} project={store.project}>
            {Workspace}
        </AppScreen>
    )
})

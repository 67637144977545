//
// FileFieldOptions
//
// Field options for type 'file'

import React from 'react'
import { observer } from 'mobx-react-lite'

import { FolderFieldOption } from './FolderFieldOption'

export const FileFieldOptions = observer(function FileFieldOptions({
    field,
    selectModified,
    worksheet,
    onAction,
    setSelected,
    renderkeyprefix,
}) {
    if (!field) return null

    return (
        <>
            <FolderFieldOption
                field={field}
                selectModified={selectModified}
                worksheet={worksheet}
                onAction={onAction}
                setSelected={setSelected}
                renderkeyprefix={renderkeyprefix}
            />
        </>
    )
})

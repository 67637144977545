//
// TextLayoutBuilder
//
// Based on view info from a layout

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'

import { Icon, Text } from '../../../../components'

export const TextLayoutBuilder = observer(function TextLayoutBuilder({
    className,
    component,
    components,
    layout,
    record,
    worksheet,
    active_class,
    visited_gids,
    force_readonly,
}) {
    const { app } = useStore()

    const language = worksheet.environment.get('language')
    let attributes = { className: 'layout-component ws-text' }
    if (className) attributes['className'] += ' ' + className
    if (component.style) attributes['className'] += ' lc-' + component.style
    if (component.gid === worksheet.layoutstore.selectedComponentGid) {
        attributes['className'] += ' cc-selected'
    }

    const text = component.text ? (
        component.text[language]
    ) : (
        <>
            <Icon size="text" name="text" /> {app.text('<not set>')}
        </>
    )
    return <Text {...attributes}>{text}</Text>
})

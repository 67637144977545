//
// useEventListener(eventName, handler)
//
// Returns a ref for use on the element to listen on. Subsequent events will trigger
// the handler.
// E.g.
//
//    const buttonRef = useEventListener('mousedown', mousedownHandler)
//    useGlobalEventListener('mouseup', mouseupHandler)
//
//    return (
//        <div ref={buttonRef} {...other}/>
//    )

import { useRef, useEffect, useCallback } from 'react'

export const useEventListener = (eventName, handler) => {
    const savedHandler = useRef()

    useEffect(() => {
        savedHandler.current = handler
    }, [handler])

    const ref = useCallback(
        element => {
            const isSupported = element && element.addEventListener
            if (!isSupported) return
            const eventListener = event => savedHandler.current(event)
            element.addEventListener(eventName, eventListener)
            return () => {
                element.removeEventListener(eventName, eventListener)
            }
        },
        [eventName]
    )

    return ref
}

export const useRefEventListener = (ref, eventName, handler) => {
    const savedHandler = useRef()

    useEffect(() => {
        savedHandler.current = handler
    }, [handler])

    useEffect(() => {
        const element = ref.current
        const isSupported = element && element.addEventListener
        if (!isSupported) return
        const eventListener = event => savedHandler.current(event)
        element.addEventListener(eventName, eventListener)
        return () => {
            element.removeEventListener(eventName, eventListener)
        }
    }, [ref, eventName])

    return ref
}

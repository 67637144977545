//
// ProjectAPIKeysWorksheet
//
// Project api keys worksheet

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../stores'
import { useMousePopover } from '../../hooks/useMousePopover'
import { useTooltipPopover } from '../../hooks/useTooltipPopover'

import { VView, HView, Spacer } from '../../appview'
import { Header, Property, Item, Icon } from '../../components'
import { ProjectAPIKeyMenu } from '../../menus'
import { ProjectMember, CreateAPIKeyPanel } from '../../panels'

export const ProjectAPIKeysWorksheet = observer(function ProjectAPIKeysWorksheet({
    project,
}) {
    const { app, data, view } = useStore()

    const is_owner = view.user && view.project && view.user.gid === view.project.owner

    const [selectedAPIKey, setSelectedAPIKey] = useState(null)

    const onUpdateAPIKey = (apiuser_gid, changedname) => {
        view.project.updateAPIKey(apiuser_gid, changedname)
    }

    const onDeactivateAPIKey = apiuser_gid => {
        view.project.deactivateAPIKey(apiuser_gid)
    }

    const onReactivateAPIKey = apiuser_gid => {
        view.project.reactivateAPIKey(apiuser_gid)
    }

    const onDeleteAPIKey = apiuser_gid => {
        view.project.deleteAPIKey(apiuser_gid)
    }

    // ContextMenus and Popovers
    const ProjectAPIKeyContextMenu = useMousePopover(ProjectAPIKeyMenu)
    const CreateAPIKeyPopover = useTooltipPopover(CreateAPIKeyPanel)

    const onShowProjectAPIKeyContextMenu = (e, apimember) => {
        setSelectedAPIKey(apimember)
        ProjectAPIKeyContextMenu.onShow(e)
    }

    const onShowCreateAPIKeyPopover = e => {
        if (!is_owner) return
        CreateAPIKeyPopover.onShow(e)
    }

    const onConfirmCreateAPIKey = name => {
        view.project.createAPIKey(name)
        CreateAPIKeyPopover.hide()
    }

    const onCancelCreateAPIKey = () => {
        CreateAPIKeyPopover.hide()
    }

    const onProjectAPIKeyAction = (apiuser_gid, action, p1) => {
        const actions = {
            update: onUpdateAPIKey,
            deactivate: onDeactivateAPIKey,
            reactivate: onReactivateAPIKey,
            delete: onDeleteAPIKey,
        }
        if (!(action in actions)) {
            console.log(
                `onProjectAPIKeyAction: unhandled action '${action}' on '${apiuser_gid}'`
            )
            return
        }
        ProjectAPIKeyContextMenu.hide()
        actions[action](apiuser_gid, p1)
    }

    const members = data.membersForProject(project.gid)
    members.sort((a, b) => {
        const a_owner = a.roles.includes('owner')
        const b_owner = b.roles.includes('owner')
        const a_api = a.roles.includes('api')
        const b_api = b.roles.includes('api')
        if (a_owner !== b_owner) return a_owner ? -1 : 1
        if (a_api !== b_api) return a_api ? 1 : -1
        const a_user = data.users.get(a.user_gid)
        const b_user = data.users.get(b.user_gid)
        if (!a_user) return -1
        if (!b_user) return 1
        return a_user.displayname.localeCompare(b_user.displayname)
    })

    const ApiKeys = members
        .filter(member => member.roles.includes('api'))
        .map(member => (
            <ProjectMember
                key={member.user_gid}
                member={member}
                onClick={e => onShowProjectAPIKeyContextMenu(e, member)}
                onContextMenu={e => onShowProjectAPIKeyContextMenu(e, member)}
            />
        ))

    return (
        <VView className="worksheet worksheet-settings worksheet-project-members">
            <HView>
                <VView grow>
                    <Header>{app.text('API keys')}</Header>
                    <ProjectAPIKeyContextMenu.Panel
                        member={selectedAPIKey}
                        onAction={onProjectAPIKeyAction}
                    />
                    <CreateAPIKeyPopover.Panel
                        onConfirm={onConfirmCreateAPIKey}
                        onCancel={onCancelCreateAPIKey}
                    />
                    <Property label={app.text('keys')}>
                        {ApiKeys}
                        {is_owner ? (
                            <Item
                                onClick={onShowCreateAPIKeyPopover}
                                style={{ marginTop: 5 }}
                                ref={CreateAPIKeyPopover.anchorRef}
                            >
                                <Icon className="itemtype" name="plus" size="text" />
                                <span className="cc-dimmed">
                                    {app.text('Add an API key')}
                                </span>
                            </Item>
                        ) : undefined}
                    </Property>
                </VView>
                <Spacer size={20} />
                <VView grow></VView>
            </HView>
        </VView>
    )
})

//
// DragDropClasslistFieldRow
//
// Drag and Drop wrapper for item in ClassListField in table view

import React, { useState, useRef, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useDrag, useDrop } from 'react-dnd'

import { DragTypes } from './DragTypes'
import { useDebounce } from '../hooks/useDebounce'

export const DragDropClasslistFieldRow = observer(function DragDropClasslistFieldRow({
    field,
    item_index,
    direction,
    disabled,
    onDrop,
    children,
}) {
    const ref = useRef(null)

    const [isoverzone, setIsoverzone] = useState('inside') // "before", "after"
    const [isMousedown, setIsMousedown] = useState(false)

    const debouncedIsoverzone = useDebounce(isoverzone, 50)

    const [{ isDragging }, drag] = useDrag({
        type: DragTypes.CLASSLISTFIELD_ITEM,
        item: {
            type: DragTypes.CLASSLISTFIELD_ITEM,
            id: item_index,
            fieldgid: field.gid,
        },
        canDrag: monitor => {
            if (!(item_index !== undefined && !disabled)) {
                setIsMousedown(false)
            }
            return item_index !== undefined && !disabled
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
        end: (item, monitor) => {
            if (ref.current) {
                setIsMousedown(false)
            }
        },
    })

    const onMouseDown = e => {
        if (e.button === 0) {
            setIsMousedown(true)
        }
    }
    const onMouseUp = e => {
        setIsMousedown(false)
    }

    const canDrop = dragitem => {
        if (!ref.current || !dragitem || !!disabled) {
            return false
        }
        if (dragitem.fieldgid !== field.gid) {
            // only drag/drop within field
            return false
        }
        if (dragitem.id === item_index) {
            // is self
            return false
        }
        return true
    }

    const [{ isOver }, drop] = useDrop({
        accept: DragTypes.CLASSLISTFIELD_ITEM,
        canDrop: dragitem => canDrop(dragitem),

        drop(dragitem, monitor) {
            onDrop(dragitem, item_index, isoverzone)
            return {
                receiver: 'DragDropClasslistFieldRow',
                dragitem: dragitem,
                item_index: item_index,
                dropzone: isoverzone,
            }
        },

        collect: monitor => {
            let isOver = !!monitor.isOver()
            const dragitem = monitor.getItem()
            if (!canDrop(dragitem)) {
                isOver = false
            }
            return {
                isOver: isOver,
            }
        },

        hover(dragitem, monitor) {
            if (!canDrop(dragitem)) {
                return
            }
            if (direction === 'vertical') {
                // determine top or bottom
                const itemBoundingRect = ref.current.getBoundingClientRect()
                // let zoneSize = Math.min(
                //     40,
                //     Math.max(4, Math.ceil(itemBoundingRect.height / 5))
                // )
                // // cannot replace inside same field
                // if (!dragitem.fieldgid || field.gid === dragitem.fieldgid) {
                //     zoneSize = Math.ceil(itemBoundingRect.height / 2)
                // }
                const zoneSize = Math.ceil(itemBoundingRect.height / 2)
                const topZone = zoneSize
                const bottomZone = zoneSize
                const itemTopY = topZone
                const itemBottomY =
                    itemBoundingRect.bottom - itemBoundingRect.top - bottomZone
                const mousePosition = monitor.getClientOffset()
                const itemMouseY = mousePosition.y - itemBoundingRect.top
                if (itemMouseY < itemTopY) {
                    setIsoverzone('top')
                } else if (itemMouseY > itemBottomY) {
                    setIsoverzone('bottom')
                } else {
                    setIsoverzone('inside')
                }
                return
            } else if (direction === 'horizontal') {
                // determine left or right
                const itemBoundingRect = ref.current.getBoundingClientRect()
                // let zoneSize = Math.min(
                //     40,
                //     Math.max(4, Math.ceil(itemBoundingRect.width / 5))
                // )
                // // cannot replace inside same field
                // if (!dragitem.fieldgid || field.gid === dragitem.fieldgid) {
                //     zoneSize = Math.ceil(itemBoundingRect.width / 2)
                // }
                const zoneSize = Math.ceil(itemBoundingRect.width / 2)
                const leftZone = zoneSize
                const rightZone = zoneSize
                const itemLeftX = leftZone
                const itemRightX =
                    itemBoundingRect.right - itemBoundingRect.left - rightZone
                const mousePosition = monitor.getClientOffset()
                const itemMouseX = mousePosition.x - itemBoundingRect.left
                if (itemMouseX < itemLeftX) {
                    setIsoverzone('left')
                } else if (itemMouseX > itemRightX) {
                    setIsoverzone('right')
                } else {
                    setIsoverzone('inside')
                }
                return
            }
            setIsoverzone('inside')
            return
        },
    })

    drag(drop(ref))

    let dragclassname = isOver ? 'drag-over-' + debouncedIsoverzone : ''
    if (isMousedown) dragclassname += ' maybe-start-dragging'

    useEffect(() => {
        // set style later - cloned drag preview has original style
        if (ref && ref.current) {
            ref.current.style.opacity = isDragging ? 0.1 : 1
        }
    }, [isDragging])

    return (
        <tr
            ref={ref}
            className={'droprow ' + dragclassname}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
        >
            {children}
        </tr>
    )
})

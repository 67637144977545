//
// SchemaWorksheetStore
//
// The SchemaWorksheetStore handles interaction with the Schema Worksheet.
// This means access to the current item, either a definition, class, or field.
//
// SIDE-EFFECT: when the current item changes, we update the schematree with that same
//              item, so it is visible and selected

import { makeObservable, observable, computed, action, reaction } from 'mobx'
import { DefinitionData } from '../data/DefinitionData'
import { ClassData } from '../data/ClassData'
import { FieldData } from '../data/FieldData'
import { LayoutStore } from './LayoutStore'

export class SchemaWorksheetStore {
    __init = false
    _key = null
    _rootstore = null
    environment = null
    item = null

    constructor(key, rootstore, environment) {
        makeObservable(this, {
            __init: observable,
            _key: observable,
            _rootstore: observable,
            environment: observable,

            item: observable,

            language: computed,
            datatype: computed,

            fetch: action.bound,
            refetch: action.bound,
            _fetch: action.bound,
            setItem: action.bound,

            syncdataitem_callback: action.bound,
        })

        this._key = key
        this._rootstore = rootstore
        this.environment = environment
        this.item = null
        this.layoutstore = new LayoutStore(null, rootstore)

        this._syncdebounce = null
        this._syncdelay = 100
    }

    loadOnce = () => {
        if (this.__init) return
        const itemgid = this._rootstore.view.loadPerProject(this._key + '/item')
        this.fetch(itemgid).then(() => {
            if (this.item) {
                this._rootstore.view._schematree.setItem(this.item)
                const layout = this.item.layout
                    ? this._rootstore.data.layouts.get(this.item.layout)
                    : null
                this.layoutstore.setLayout(layout)
            }
            reaction(
                () => this.item,
                item => {
                    if (item) {
                        this._rootstore.view._schematree.setItem(item)
                        const layout = item.layout
                            ? this._rootstore.data.layouts.get(item.layout)
                            : null
                        this.layoutstore.setLayout(layout)
                    } else {
                        this.layoutstore.setLayout(null)
                    }
                }
            )
        })
        this._rootstore.api.register_syncdataitem_callback(this.syncdataitem_callback)
        this.__init = true
    }

    fetch(itemgid) {
        if (this.item && itemgid === this.item.gid) {
            return Promise.resolve(true) // nothing to fetch
        }
        return this._fetch(itemgid)
    }

    refetch() {
        if (!this.item) {
            return Promise.resolve(true) // nothing to fetch
        }
        return this._fetch(this.item.gid)
    }

    _fetch(itemgid) {
        if (!itemgid) {
            this._rootstore.view.savePerProject(this._key + '/item', null)
            this.item = null
            return Promise.resolve(true) // nothing to fetch
        }
        if (this._rootstore.data.definitions.has(itemgid)) {
            this.item = this._rootstore.data.definitions.get(itemgid)
            if (this.item) {
                this._rootstore.data.definitions.forEach(item => {
                    if (item.is_base && item.original === this.item.gid) {
                        this.item = item
                    }
                })
            }
        } else if (this._rootstore.data.classes.has(itemgid)) {
            this.item = this._rootstore.data.classes.get(itemgid)
            if (this.item) {
                this._rootstore.data.classes.forEach(item => {
                    if (item.is_base && item.original === this.item.gid) {
                        this.item = item
                    }
                })
            }
        } else if (this._rootstore.data.fields.has(itemgid)) {
            this.item = this._rootstore.data.fields.get(itemgid)
            if (this.item) {
                this._rootstore.data.fields.forEach(item => {
                    if (item.is_base && item.original === this.item.gid) {
                        this.item = item
                    }
                })
            }
        }
        const thisitemgid = this.item ? this.item.gid : null
        this._rootstore.view.savePerProject(this._key + '/item', thisitemgid)
        return Promise.resolve(true)
    }

    setItem(item) {
        const datatype = this.getDatatype(item)
        const itemgid = datatype ? item.gid : null
        this.fetch(itemgid).then(() => {})
    }

    get language() {
        if (!this.environment) return null
        return this.environment.get('language')
    }

    get datatype() {
        if (!this.item) return null
        return this.getDatatype(this.item)
    }

    get datatypeTexts() {
        return {
            definition: this._rootstore.app.text('Definitions'),
            class: this._rootstore.app.text('Classes'),
            field: this._rootstore.app.text('Fields'),
        }
    }

    getDatatype(item) {
        if (!item) return null
        return item instanceof DefinitionData
            ? 'definition'
            : item instanceof ClassData
            ? 'class'
            : item instanceof FieldData
            ? 'field'
            : undefined
    }

    getDatastore(itemgid) {
        return this._rootstore.data.definitions.has(itemgid)
            ? this._rootstore.data.definitions
            : this._rootstore.data.classes.has(itemgid)
            ? this._rootstore.data.classes
            : this._rootstore.data.fields.has(itemgid)
            ? this._rootstore.data.fields
            : undefined
    }

    getItem(itemgid) {
        const datastore = this.getDatastore(itemgid)
        return datastore ? datastore.get(itemgid) : null
    }

    getDefinitionChildDefinitions(definition) {
        if (!definition) return []
        let childdefinitions = []
        definition.childdefinitions.forEach(definitiongid => {
            let childdefinition = this._rootstore.data.definitions.get(definitiongid)
            if (childdefinition) {
                childdefinitions.push(childdefinition)
            }
        })
        return childdefinitions
    }

    getDefinitionClasses(definition) {
        if (!definition) return []
        let classes = []
        definition.classes.forEach(classgid => {
            let class_ = this._rootstore.data.classes.get(classgid)
            if (class_) {
                classes.push(class_)
            }
        })
        return classes
    }

    getAllClasses() {
        if (!this.item) return []
        if (this.datatype === 'definition') {
            return this.getDefinitionClasses(this.item)
        }
        return []
    }

    getAllFields() {
        if (!this.item) return []
        if (this.datatype === 'definition') {
            return this.getDefinitionAllFields(this.item)
        }
        if (this.datatype === 'class') {
            return this.getClassFields(this.item)
        }
        return []
    }

    getAllChildFields() {
        if (!this.item) return []
        let definitiongids = []
        if (this.datatype === 'definition') {
            // combined fields from all child definitions
            definitiongids = this.item.childdefinitions
        }
        if (this.datatype === 'class') {
            // find in which definitions this class is used
            // combined fields from all child definitions of
            // those definitions
            const class_gid = this.item.original ? this.item.original : this.item.gid
            Array.from(this._rootstore.data.definitions.values())
                .filter(definition => {
                    return (
                        definition.is_committed &&
                        definition.classes.includes(class_gid)
                    )
                })
                .forEach(definition => {
                    definitiongids.push(...definition.childdefinitions)
                })
        }
        let fields = []
        let field_gids = new Set()
        let field
        definitiongids.forEach(definitiongid => {
            let definition = this._rootstore.data.definitions.get(definitiongid)
            if (!definition) return
            definition.classes.forEach(classgid => {
                let class_ = this._rootstore.data.classes.get(classgid)
                if (class_) {
                    class_.fields.forEach(fieldgid => {
                        if (!field_gids.has(fieldgid)) {
                            field = this._rootstore.data.fields.get(fieldgid)
                            if (field) {
                                fields.push(field)
                                field_gids.add(fieldgid)
                            }
                        }
                    })
                }
            })
            definition.fields.forEach(fieldgid => {
                if (!field_gids.has(fieldgid)) {
                    field = this._rootstore.data.fields.get(fieldgid)
                    if (field) {
                        fields.push(field)
                        field_gids.add(fieldgid)
                    }
                }
            })
        })
        return fields
    }

    getDefinitionFields(definition) {
        if (!definition) return []
        let fields = []
        definition.fields.forEach(fieldgid => {
            let field = this._rootstore.data.fields.get(fieldgid)
            if (field) {
                fields.push(field)
            }
        })
        return fields
    }

    getDefinitionAllFields(definition) {
        if (!definition) return []
        let fields = []
        let field_gids = new Set()
        let field
        definition.classes.forEach(classgid => {
            let class_ = this._rootstore.data.classes.get(classgid)
            if (class_) {
                class_.fields.forEach(fieldgid => {
                    if (!field_gids.has(fieldgid)) {
                        field = this._rootstore.data.fields.get(fieldgid)
                        if (field) {
                            fields.push(field)
                            field_gids.add(fieldgid)
                        }
                    }
                })
            }
        })
        definition.fields.forEach(fieldgid => {
            if (!field_gids.has(fieldgid)) {
                field = this._rootstore.data.fields.get(fieldgid)
                if (field) {
                    fields.push(field)
                    field_gids.add(fieldgid)
                }
            }
        })
        return fields
    }

    getClassFields(class_) {
        if (!class_) return []
        let fields = []
        class_.fields.forEach(classgid => {
            let field = this._rootstore.data.fields.get(classgid)
            if (field) {
                fields.push(field)
            }
        })
        return fields
    }

    findField(field_gid_or_name) {
        if (this._rootstore.data.fields.has(field_gid_or_name)) {
            return this._rootstore.data.fields.get(field_gid_or_name)
        }
        for (let field of this._rootstore.data.fields.values()) {
            if (field.name === field_gid_or_name) {
                return field
            }
        }
        return undefined
    }

    findFields(field_gids_or_names) {
        let fields = []
        for (let field of this._rootstore.data.fields.values()) {
            if (field_gids_or_names.includes(field.gid)) {
                fields.push(field)
            } else if (field_gids_or_names.includes(field.name)) {
                fields.push(field)
            }
        }
        return field_gids_or_names.map((field_gid_or_name, index) => {
            for (let field of fields) {
                if (
                    field.gid === field_gid_or_name ||
                    field.name === field_gid_or_name
                ) {
                    return field
                }
            }
            return {
                gid: '-not-selectable-' + field_gid_or_name,
                type: 'textline',
                name: field_gid_or_name,
            }
        })
    }

    findFieldNamed(definition, fieldname) {
        if (!definition) {
            return null
        }
        for (let field of this._rootstore.data.fields.values()) {
            if (field.name === fieldname) {
                return field
            }
        }
        return undefined
    }

    schedule_refetch = () => {
        if (this._syncdebounce) {
            clearTimeout(this._syncdebounce)
            this._syncdebounce = null
        }
        this._syncdebounce = setTimeout(() => {
            this.refetch()
            this._syncdebounce = null
        }, this._syncdelay)
    }

    syncdataitem_callback = (syncdataitem, data_before, data_after) => {
        // console.log('SchemaWorksheetStore sync', syncdataitem, data_before, data_after)
        // we're only interested in schema data_types
        // then, we're only interested in updates (of childcount) and deletions
        // for updated records, only for self, when childcount has changed
        // for deletes, only for self -> select non-deleted record from parents
        if (
            !['definitions', 'classes', 'fields', 'layouts'].includes(
                syncdataitem['data_type']
            )
        ) {
            return
        }
        if (syncdataitem['data_type'] === 'definitions') {
            const data = data_after ? data_after : data_before
            if (!data) return
            // don't update for extended classes
            if (data.original && !data.is_base) {
                return
            }
        }
        if (syncdataitem['action'] === 'UPDATE') {
            this.layoutstore.refetch()
        } else if (syncdataitem['data_type'] === 'layouts') {
            return
        } else if (syncdataitem['action'] === 'INSERT') {
            const item = this._rootstore.data[syncdataitem['data_type']].get(
                syncdataitem['data_key']
            )
            if (item && this.item && item.original === this.item.gid) {
                this.setItem(item)
            }
        } else if (syncdataitem['action'] === 'DELETE') {
            if (!this.item) return
            if (syncdataitem['data_key'] !== this.item.gid) return
            if (data_before && data_before.original) {
                const item = this._rootstore.data[syncdataitem['data_type']].get(
                    data_before.original
                )
                this.setItem(item)
            } else {
                const tree = this._rootstore.view._schematree.tree
                const parentitem = tree.findItemById(this.getDatatype(this.item))
                tree.selectItem(parentitem)
            }
        }
    }
}

//
// DragDropDefinitionChildDefinitionItem
//
// Drag and Drop wrapper for Definition Child Definition Item

import React, { useState, useRef, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useDrag, useDrop } from 'react-dnd'

import { DragTypes } from './DragTypes'
import { useDebounce } from '../hooks/useDebounce'

export const DragDropDefinitionChildDefinitionItem = observer(
    function DragDropDefinitionChildDefinitionItem({ itemgid, onDrop, children }) {
        const ref = useRef(null)

        const [isoverzone, setIsoverzone] = useState('item')
        const [isMousedown, setIsMousedown] = useState(false)

        const debouncedIsoverzone = useDebounce(isoverzone, 50)

        const [{ isDragging }, drag] = useDrag({
            type: DragTypes.PIM_DEFINITION,
            item: {
                type: DragTypes.PIM_DEFINITION,
                id: itemgid,
            },
            canDrag: monitor => {
                if (itemgid === 'childdefinition.add') {
                    setIsMousedown(false)
                }
                return itemgid !== 'childdefinition.add'
            },
            collect: monitor => ({
                isDragging: !!monitor.isDragging(),
            }),
            end: (item, monitor) => {
                if (ref.current) {
                    setIsMousedown(false)
                }
            },
        })

        const onMouseDown = e => {
            if (e.button === 0) {
                setIsMousedown(true)
            }
        }
        const onMouseUp = e => {
            setIsMousedown(false)
        }

        const canDrop = dragitem => {
            if (!ref.current || !dragitem) {
                return false
            }
            if (dragitem.type !== DragTypes.PIM_DEFINITION) {
                return false
            }
            if (dragitem.id === itemgid) {
                return false
            }
            return true
        }

        const [{ isOver }, drop] = useDrop({
            accept: [DragTypes.PIM_DEFINITION],
            canDrop: dragitem => canDrop(dragitem),

            drop(dragitem, monitor) {
                onDrop(dragitem, itemgid, isoverzone)
                return {
                    receiver: 'DragDropDefinitionChildDefinitionItem',
                    dragitem: dragitem,
                    dropgid: itemgid,
                    dropzone: isoverzone,
                }
            },

            collect: monitor => {
                let isOver = !!monitor.isOver()
                const dragitem = monitor.getItem()
                if (!canDrop(dragitem)) {
                    isOver = false
                }
                return {
                    isOver: isOver,
                }
            },

            hover(dragitem, monitor) {
                if (!canDrop(dragitem)) {
                    return
                }
                if (itemgid === 'childdefinition.add') {
                    setIsoverzone('inside')
                    return
                }
                // determine top or bottom
                const itemBoundingRect = ref.current.getBoundingClientRect()
                const halfway = (itemBoundingRect.bottom - itemBoundingRect.top) / 2
                const mousePosition = monitor.getClientOffset()
                const itemMouseY = mousePosition.y - itemBoundingRect.top
                if (itemMouseY < halfway) {
                    setIsoverzone('top')
                } else {
                    setIsoverzone('bottom')
                }
            },
        })

        drag(drop(ref))

        let dragclassname = ''
        if (isOver && debouncedIsoverzone === 'top') dragclassname = 'drag-over-top'
        else if (isOver && debouncedIsoverzone === 'bottom')
            dragclassname = 'drag-over-bottom'
        else if (isOver && debouncedIsoverzone === 'inside')
            dragclassname = 'drag-over-inside'
        if (isMousedown) dragclassname += ' maybe-start-dragging'

        useEffect(() => {
            // set style later - cloned drag preview has original style
            if (ref && ref.current) {
                ref.current.style.opacity = isDragging ? 0.1 : 1
            }
        }, [isDragging])

        return (
            <div
                ref={ref}
                className={dragclassname}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
            >
                {children}
            </div>
        )
    }
)

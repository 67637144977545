//
// RecordPinboardPanel
//
// Use to pin records, e.g. while dragging and dropping but you want to scroll in
// between. Can hold more than one record, but shouldn't display more than e.g. 5 at
// a time. And maybe max out at ten.
//
// If we drag-n-drop _from_ here, remove that item.
// We can also directly remove the item with an (x) as inline-action
// perhaps clear pinboard in one go?

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useDrop } from 'react-dnd'
import { useStore } from '../stores'

import { DragTypes } from '../dragdrop/DragTypes'
import { DragRecord } from '../dragdrop/DragRecord'

import { VView, Spacer } from '../appview'
import { Header, Text, TreeItem, Button, Icon } from '../components'
import { maxChars } from '../utils/text'

export const RecordPinboardPanel = observer(function RecordPinboardPanel({
    pinboard,
    selectedRecord,
}) {
    const { app, data } = useStore()

    const [{ isOver, canDrop }, drop] = useDrop({
        accept: [DragTypes.PIM_RECORD, DragTypes.RECORD_SELECTION],
        canDrop: () => pinboard.canAdd(),
        collect: monitor => {
            return {
                isOver: !!monitor.isOver(),
                canDrop: !!monitor.canDrop(),
            }
        },

        drop: item => {
            if (item.type === DragTypes.PIM_RECORD) {
                pinboard.addRecord(item.gid)
            } else if (item.type === DragTypes.RECORD_SELECTION) {
                if (!pinboard.exists(item.gid)) {
                    pinboard.addSelection(
                        item.selection,
                        item.selectionsize,
                        item.isFlat
                    )
                }
            }
            return { receiver: 'RecordPinboardPanel', item: item }
        },
    })

    let classes = 'panel pinboard-panel record-pinboard-panel pinboard-drop'
    if (isOver) {
        classes += ' drag-over-inside'
        if (!canDrop) classes += ' drop-rejected'
    }

    const pinnedcount = pinboard.pinnedItems.length
    const Message = !pinnedcount ? (
        <Text className="ws-helptext">
            {app.text(
                'Drag a record here for easy access. This pinboard can hold up to ' +
                    pinboard.MAX_ITEMS +
                    ' items.'
            )}
        </Text>
    ) : undefined

    const Records = pinboard.pinnedItems.map(item => {
        let name = ''
        let state = ''
        let iconname = ''
        let record = null
        let selection = null

        if (item.type === 'record') {
            record = data.records.get(item.gid)
            if (!record) {
                return null
            }
            name =
                record.localized_title && record.localized_title.length
                    ? record.localized_title
                    : app.text('[empty]')
            state = record.childcount ? 'collapsed' : 'empty'
            iconname = record.is_link ? 'link' : 'bullet'
        } else if (item.type === 'selection') {
            selection = item.selection
            name = app.text('{count} records', { count: item.size })
            state = 'empty'
            iconname = 'selection'
        } else {
            return null
        }

        const action = (
            <div className="inline-actions">
                <Button
                    icon
                    title={app.text('Remove from pinboard')}
                    onClick={() => pinboard.remove(item.gid)}
                >
                    <Icon name="remove" size="text" />
                </Button>
            </div>
        )

        const onDropped = dropresult => {
            if (dropresult && dropresult.receiver !== 'RecordPinboardPanel') {
                pinboard.remove(item.gid)
            }
        }

        return (
            <DragRecord
                key={item.gid}
                gid={item.gid}
                record={record}
                selection={selection}
                selectionsize={item.size}
                selectionisflat={item.isFlat}
                onDropped={onDropped}
            >
                <TreeItem
                    state={state}
                    emptyicon={iconname}
                    selected={
                        selectedRecord && record && selectedRecord.gid === record.gid
                    }
                    indent={0}
                    onClick={() => record && pinboard.selectRecord(record)}
                >
                    <span title={name}>{maxChars(name, 40)}</span>
                    {action}
                </TreeItem>
            </DragRecord>
        )
    })

    return (
        <VView ref={drop} className={classes}>
            <Header>{app.text('Record pinboard')}</Header>
            {Records}
            {Records && !Message ? <Spacer size="5" /> : undefined}
            {Message}
        </VView>
    )
})

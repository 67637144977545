//
// RecordInspectorSwitcher
//
// Switch to a specific inspector based on worksheet selections

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../stores'

import { View } from '../../appview'
import { Text } from '../../components'
import { RecordInspector, ChannelsInspector, FieldsInspector } from './inspectors'

export const RecordInspectorSwitcher = observer(function RecordInspectorSwitcher({
    inspector,
    worksheet,
    viewkey,
}) {
    const { app } = useStore()

    let Inspector = <Text>{app.text('')}</Text>
    if (worksheet.selected.record) {
        switch (inspector) {
            case 'record':
                Inspector = <RecordInspector worksheet={worksheet} viewkey={viewkey} />
                break
            case 'channels':
                Inspector = (
                    <ChannelsInspector worksheet={worksheet} viewkey={viewkey} />
                )
                break
            case 'fields':
                Inspector = <FieldsInspector worksheet={worksheet} viewkey={viewkey} />
                break
            default:
                break
        }
    }

    return <View className="panel properties">{Inspector}</View>
})

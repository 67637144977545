//
// TilesView
//
// A TilesView lays out its children in a grid pattern. You can specify either a number
// of columns (the `columns` property takes a number), or a minimum width for each tile
// (the `tileMinWidth` property) where each row will be filled with the number of tiles
// that fit, growing each one to take up the remaining available space proportionally.
// The `tileMinWidth` takes precedence over the `columns` property. If you specify
// neither, the default is 1 column.
//
// You can override the grid layout behavior by specifying a specific grid columns
// style:
//     <TilesView style={{gridTemplateColumns: '1fr 2fr'}}>
// Note that this won't work if you try it with a custom className and a CSS rule, it
// must be an inline style otherwise the CSS rule is overridden by the inline style
// that is applied from the tileMinWidth or columns property.
//
// You can specify a `gap` between tiles.

import React from 'react'

export const TilesView = React.forwardRef(function TilesView(props, ref) {
    const { className, tileMinWidth, columns, gap, style, ...other } = props
    let classes = 'av-TilesView'
    if (className) classes += ' ' + className
    let styles = {}
    if (tileMinWidth) {
        styles = {
            gridTemplateColumns:
                'repeat(auto-fill, minmax(' + tileMinWidth + 'px, 1fr)',
        }
    } else if (columns) {
        styles = { gridTemplateColumns: 'repeat(' + columns + ', 1fr)' }
    }
    if (gap) {
        styles['gap'] = gap
    }
    if (style) styles = Object.assign({}, styles, style)
    return <div ref={ref} className={classes} style={styles} {...other} />
})

//
// CodeFieldOptions
//
// Field options for type 'code'

import React from 'react'
import { observer } from 'mobx-react-lite'

import { duckField } from '../../../../stores/data/validators'
import { InitialFieldOption } from './InitialFieldOption'

export const CodeFieldOptions = observer(function CodeFieldOptions({
    field,
    selectModified,
    worksheet,
    onAction,
    setSelected,
    renderkeyprefix,
}) {
    if (!field) return null
    const format = field.options.get('format')

    const duckInitialField = duckField(field.type, { format: format })

    return (
        <>
            <InitialFieldOption
                field={field}
                initialField={duckInitialField}
                selectModified={selectModified}
                worksheet={worksheet}
                onAction={onAction}
                setSelected={setSelected}
                renderkeyprefix={renderkeyprefix}
            />
        </>
    )
})

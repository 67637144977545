//
// TreeSchemaFieldMenu & TreeSchemaFieldHeaderMenu
//
// Actions on fields in the TreeNavigator
//
// TreeSchemaFieldHeaderMenu
// - create_field
//
// TreeSchemaFieldMenu
// - commit_field
// - revert_field
// - save_as_new_field
// - delete_field

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Menu, MenuDivider, MenuItem } from '../components'

export const TreeSchemaFieldHeaderMenu = observer(function TreeSchemaFieldHeaderMenu({
    onAction,
}) {
    const { app } = useStore()

    const _onAction = action => {
        onAction && onAction(action)
    }

    return (
        <Menu>
            <MenuItem onClick={() => _onAction('create_field')}>
                {app.text('New field')}
            </MenuItem>
        </Menu>
    )
})

export const TreeSchemaFieldMenu = observer(function TreeSchemaFieldMenu({
    field,
    onAction,
}) {
    const { app } = useStore()

    const _onAction = action => {
        onAction && onAction(action)
    }

    if (!field) return null

    // already committed? can't commit again
    const disableCommit = field.is_committed

    // already committed? can't revert anymore (revert new is same as delete, so
    // does work, but we disable it for clarity -> use delete_field instead)
    const disableRevert = field.is_committed || field.is_new

    // new or committed? can't save as new then
    const disableSaveAsNew = field.is_committed || field.is_new

    // can only copy committed
    const disableCopy = !field.is_committed

    // modified (but not new)? can't delete (but you can revert)
    const disableDelete = field.is_working_copy && !field.is_new

    return (
        <Menu>
            <MenuItem
                disabled={disableCommit}
                onClick={() => _onAction('commit_field')}
            >
                {app.text('Commit field')}
            </MenuItem>
            <MenuItem
                disabled={disableRevert}
                onClick={() => _onAction('revert_field')}
            >
                {app.text('Revert changes')}
            </MenuItem>
            <MenuItem
                disabled={disableSaveAsNew}
                onClick={() => _onAction('save_as_new_field')}
            >
                {app.text('Save as new')}
            </MenuItem>
            <MenuDivider />
            <MenuItem disabled={disableCopy} onClick={() => _onAction('copy_field')}>
                {app.text('Duplicate field')}
            </MenuItem>
            <MenuItem
                disabled={disableDelete}
                onClick={() => _onAction('delete_field')}
            >
                {app.text('Delete field (if unused)')}
            </MenuItem>
        </Menu>
    )
})

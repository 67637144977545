//
// TreeItem
//
// A TreeItem can be used in a tree. It can be empty, but if it is not it can be
// collapsed or expanded. It can be indented.

import React from 'react'
import { Icon } from './Icon'

export const TreeItem = props => {
    const {
        className,
        indent,
        state,
        selected,
        children,
        onExpand,
        onCollapse,
        emptyicon,
        ...other
    } = props
    let classes = 'cc-TreeItem'
    if (className) classes += ' ' + className
    if (indent !== undefined) classes += ' cc-indent' + indent
    if (selected) classes += ' cc-selected'
    let icon = state
    if (state === 'empty') {
        icon = emptyicon || 'bullet'
    }
    const onToggle = e => {
        if (state === 'expanded') {
            onCollapse && onCollapse()
        } else {
            onExpand && onExpand()
        }
        e.preventDefault()
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
    }
    return (
        <div className={classes} {...other} onDoubleClick={onToggle}>
            <Icon
                name={icon}
                size={'text'}
                className="cc-TreeItem-Icon"
                onClick={onToggle}
            />
            {children}
        </div>
    )
}

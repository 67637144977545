//
// DeleteAccountPanel
//
// Use in a popover for deleting an account.

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { VView, HView } from '../appview'
import { Header, Toggle, Text, Menu, MenuItem } from '../components'

export const DeleteAccountPanel = observer(function DeleteAccountPanel({
    onConfirm,
    onCancel,
}) {
    const { app } = useStore()

    const [confirm, setConfirm] = useState(false)

    const _onConfirm = () => {
        onConfirm && onConfirm(confirm)
    }
    const _onCancel = () => {
        onCancel && onCancel()
    }

    return (
        <VView className="actions-panel change-password-panel">
            <div className="inspector cc-danger">
                <Header>{app.text('Delete account')}</Header>
                <VView
                    style={{
                        marginLeft: 5,
                        marginRight: 5,
                        marginBottom: 5,
                    }}
                >
                    <HView vcenter>
                        <Toggle onChange={value => setConfirm(value)} value={confirm} />
                        <Text>{app.text('Confirm account deletion')}</Text>
                    </HView>
                    <Text>
                        {app.text(
                            'When you delete your account, ALL your projects and assets will be deleted, with no way to recover them. You will also be removed from other projects you are participating in.'
                        )}
                    </Text>
                </VView>
            </div>
            <Menu>
                <MenuItem
                    className="cc-danger"
                    disabled={!confirm}
                    onClick={_onConfirm}
                >
                    {app.text('Permanently delete account')}
                </MenuItem>
                <MenuItem onClick={_onCancel}>{app.text('Cancel')}</MenuItem>
            </Menu>
        </VView>
    )
})

//
// DefinitionMenu for RecordInspector
//

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../stores'

import { Menu, MenuItem, MenuDivider } from '../../../components'
import {
    RecordDefinitionAddClassPanel,
    RecordDefinitionAddFieldPanel,
    RecordDefinitionChangePanel,
} from '../../../panels'

export const DefinitionMenu = observer(function DefinitionMenu({
    worksheet,
    onAction,
}) {
    const { app } = useStore()

    const _onAction = (action, parameter) => {
        onAction && onAction(action, parameter)
    }

    return (
        <Menu>
            <MenuItem
                onClick={() => {
                    _onAction('reveal_definition')
                }}
            >
                {app.text('Reveal in Schema workspace')}
            </MenuItem>
            <MenuDivider />
            <MenuItem
                disabled={worksheet.record.is_link}
                className="cc-notice"
                submenu={
                    <RecordDefinitionChangePanel
                        worksheet={worksheet}
                        onChangeDefinition={new_definition_gid => {
                            _onAction('change_definition', new_definition_gid)
                        }}
                        onCancel={() => _onAction('cancel')}
                    />
                }
            >
                {app.text('Change')}
            </MenuItem>
            <MenuDivider />
            <MenuItem
                disabled={worksheet.record.is_link}
                submenu={
                    <RecordDefinitionAddClassPanel
                        onAddClass={class_gid => {
                            _onAction('add_class', class_gid)
                        }}
                    />
                }
            >
                {app.text('Add a class')}
            </MenuItem>
            <MenuItem
                disabled={worksheet.record.is_link}
                submenu={
                    <RecordDefinitionAddFieldPanel
                        onAddField={field_gid => {
                            _onAction('add_field', field_gid)
                        }}
                    />
                }
            >
                {app.text('Add a field')}
            </MenuItem>
        </Menu>
    )
})

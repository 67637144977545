//
// SchemaPinboardPanel
//
// Use to pin definitions, classes, and fields, e.g. while dragging and dropping but
// you want to scroll in between. Can hold more than one item, but shouldn't display
// more than e.g. 5 at a time. And maybe max out at ten.
//
// We can also directly remove the item with an (x) as inline-action
// perhaps clear pinboard in one go?

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useDrop } from 'react-dnd'
import { useStore } from '../stores'

import { DragTypes } from '../dragdrop/DragTypes'
import { DragSchemaItem } from '../dragdrop/DragSchemaItem'
import { DefinitionData } from '../stores/data/DefinitionData'
import { ClassData } from '../stores/data/ClassData'
import { FieldData } from '../stores/data/FieldData'

import { VView, Spacer } from '../appview'
import { Header, Text, TreeItem, Button, Icon } from '../components'
import { maxChars } from '../utils/text'

export const SchemaPinboardPanel = observer(function SchemaPinboardPanel({
    pinboard,
    selectedItem,
}) {
    const { app } = useStore()

    const [{ isOver, canDrop }, drop] = useDrop({
        accept: [DragTypes.PIM_DEFINITION, DragTypes.PIM_CLASS, DragTypes.PIM_FIELD],
        canDrop: () => pinboard.canAdd(),
        collect: monitor => {
            return {
                isOver: !!monitor.isOver(),
                canDrop: !!monitor.canDrop(),
            }
        },

        drop: ({ gid }) => {
            pinboard.add(gid)
            return { receiver: 'SchemaPinboardPanel' }
        },
    })

    let classes = 'panel pinboard-panel schema-pinboard-panel pinboard-drop'
    if (isOver) {
        classes += ' drag-over-inside'
        if (!canDrop) classes += ' drop-rejected'
    }

    const pinnedcount = pinboard.pinnedItems.length
    const Message = !pinnedcount ? (
        <Text className="ws-helptext">
            {app.text(
                'Drag a definition, class, or field here for easy access. This pinboard can hold up to ' +
                    pinboard.MAX_ITEMS +
                    ' items.'
            )}
        </Text>
    ) : undefined

    const Items = pinboard.pinnedItems.map(item => {
        let name = item.name ? item.name : app.text('[empty]')
        const action = (
            <div className="inline-actions">
                <Button
                    icon
                    title={app.text('Remove from pinboard')}
                    onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        pinboard.remove(item.gid)
                    }}
                >
                    <Icon name="remove" size="text" />
                </Button>
            </div>
        )

        const onDrop = dropresult => {
            if (dropresult && dropresult.receiver !== 'SchemaPinboardPanel') {
                pinboard.remove(item.gid)
            }
        }

        const iconname =
            item instanceof DefinitionData
                ? 'definition'
                : item instanceof ClassData
                ? 'class'
                : item instanceof FieldData
                ? 'field'
                : 'empty'

        return (
            <DragSchemaItem key={item.gid} schemaitem={item} onDrop={onDrop}>
                <TreeItem
                    state={'empty'}
                    emptyicon={iconname}
                    selected={selectedItem && selectedItem.gid === item.gid}
                    indent={0}
                    onClick={() => pinboard.selectItem(item)}
                >
                    <span title={name}>{maxChars(name, 40)}</span>
                    {action}
                </TreeItem>
            </DragSchemaItem>
        )
    })

    return (
        <VView ref={drop} className={classes}>
            <Header>{app.text('Schema pinboard')}</Header>
            {Items}
            {Items && !Message ? <Spacer size="5" /> : undefined}
            {Message}
        </VView>
    )
})

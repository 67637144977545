//
// useOnResize()
//
// Returns the windowsize, and listens for changes from the window resize event.
//
//    const [windowwidth, windowheight] = useOnResize()

import { useState, useLayoutEffect } from 'react'

export const useOnResize = () => {
    const [windowsize, setWindowsize] = useState([0, 0])

    useLayoutEffect(() => {
        function updateWindowSize() {
            setWindowsize([window.innerWidth, window.innerHeight])
        }
        window.addEventListener('resize', updateWindowSize)
        updateWindowSize()

        return () => window.removeEventListener('resize', updateWindowSize)
    }, [])

    return windowsize
}

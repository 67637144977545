//
// Colors lab
//
// Show all available colors in all intensities.

import React from 'react'

import { HView, VView } from '../../appview'

import cssvars from '../../../config/sass/variables.scss'

export const Colors = () => {
    const colors = [
        'darkgray',
        'gray',
        'lightgray',
        'red',
        'lightred',
        'green',
        'lightgreen',
        'blue',
        'lightblue',
        'orange',
        'lightorange',
    ]
    const intensities = [1, 2, 3, 4, 5]

    const AllColors = colors.map(color => (
        <div key={color} style={{ verticalAlign: 'middle', marginBottom: 20 }}>
            {intensities.map(intensity => (
                <div
                    key={intensity}
                    style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        textAlign: 'center',
                        marginRight: 4,
                    }}
                >
                    <div
                        style={{
                            backgroundColor: cssvars[color + intensity],
                            width: 50,
                            height: 75,
                            lineHeight: '25px',
                            verticalAlign: 'middle',
                        }}
                    >
                        <span>text</span>
                        <br />
                        <span style={{ color: cssvars.gray2 }}>text</span>
                        <br />
                        <span style={{ color: 'white' }}>text</span>
                    </div>
                    {intensity}
                </div>
            ))}
            <div
                style={{
                    display: 'inline-block',
                    width: 200,
                    verticalAlign: 'middle',
                    marginLeft: 10,
                }}
            >
                <span style={{ fontWeight: '600' }} className="user-select">
                    {color}
                </span>
                <br />
            </div>
        </div>
    ))

    const AllTextColors = colors.map(color => (
        <div key={color} style={{ verticalAlign: 'middle', marginBottom: 20 }}>
            {intensities.map(intensity => (
                <div
                    key={intensity}
                    style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        textAlign: 'center',
                        marginRight: 4,
                    }}
                >
                    <div
                        style={{
                            color: cssvars[color + intensity],
                            width: 50,
                            lineHeight: '25px',
                        }}
                    >
                        text
                    </div>
                    <div
                        style={{
                            color: cssvars[color + intensity],
                            backgroundColor: cssvars.gray3,
                            width: 50,
                            lineHeight: '25px',
                        }}
                    >
                        text
                    </div>
                    <div
                        style={{
                            color: cssvars[color + intensity],
                            backgroundColor: cssvars.darkgray1,
                            width: 50,
                            lineHeight: '25px',
                        }}
                    >
                        text
                    </div>
                    {intensity}
                </div>
            ))}
            <div
                style={{
                    display: 'inline-block',
                    width: 200,
                    verticalAlign: 'middle',
                    marginLeft: 10,
                }}
            >
                <span style={{ fontWeight: '600' }} className="user-select">
                    {color}
                </span>
                <br />
            </div>
        </div>
    ))

    return (
        <HView>
            <VView style={{ padding: 20 }}>
                <h3>Background colors</h3>
                <br />
                {AllColors}
            </VView>
            <VView style={{ padding: 20 }}>
                <h3>Text colors</h3>
                <br />
                {AllTextColors}
            </VView>
        </HView>
    )
}

//
// ProjectSettingsInspector
//
// Project inspector; show owner, members, api keys

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../stores'

import { VView, Divider, Spacer } from '../../appview'
import { Header } from '../../components'
import { ProjectMember } from '../../panels'

export const ProjectSettingsInspector = observer(function ProjectSettingsInspector({
    project,
}) {
    const { app, data, user } = useStore()

    const owner = data.users.get(project.owner)
    const members = data
        .membersForProject(project.gid)
        .filter(member => member.user_gid === user.gid)
    const member =
        project.owner === user.gid ? null : members.length ? members[0] : null

    return (
        <VView grow className="panel members">
            <Header>
                {app.text(project.owner === user.gid ? 'Owner (you)' : 'Owner')}
            </Header>
            <ProjectMember user={owner} />
            {member ? (
                <>
                    <Spacer size={10} />
                    <Divider />
                    <Header>{app.text('You')}</Header>
                    <ProjectMember member={member} />
                </>
            ) : undefined}
        </VView>
    )
})

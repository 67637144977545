//
// ScrollView, HScrollView, VScrollView
//
// A ScrollView fits the available space. If its contents don't fit, you can scroll
// around in this view. A HScrollView limits this to horizontal scrolling, while
// a VScrollView only allows vertical scrolling.

import React from 'react'

export const ScrollView = React.forwardRef(function ScrollView(props, ref) {
    const { grow, className, style, ...other } = props
    let classes = 'av-ScrollView'
    let styles = {}
    if (grow) {
        if (grow !== true) {
            styles = { flexGrow: grow }
        }
    }
    if (className) classes += ' ' + className
    if (style) styles = Object.assign({}, styles, style)
    return <div ref={ref} className={classes} style={styles} {...other} />
})

export const HScrollView = React.forwardRef(function HScrollView(props, ref) {
    const { grow, className, style, ...other } = props
    let classes = 'av-HScrollView'
    let styles = {}
    if (grow) {
        if (grow !== true) {
            styles = { flexGrow: grow }
        }
    }
    if (className) classes += ' ' + className
    if (style) styles = Object.assign({}, styles, style)
    return <div ref={ref} className={classes} style={styles} {...other} />
})

export const VScrollView = React.forwardRef(function VScrollView(props, ref) {
    const { grow, className, style, ...other } = props
    let classes = 'av-VScrollView'
    let styles = {}
    if (grow) {
        if (grow !== true) {
            styles = { flexGrow: grow }
        }
    }
    if (className) classes += ' ' + className
    if (style) styles = Object.assign({}, styles, style)
    return <div ref={ref} className={classes} style={styles} {...other} />
})

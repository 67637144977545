//
// FieldlistLayoutBuilder
//
// Based on view info from a layout

import React from 'react'
import { observer } from 'mobx-react-lite'

import { VView, Divider } from '../../../../appview'
import { FieldLayoutBuilder } from '.'
import { LAYOUTCOMPONENTTYPECONFIG } from '../../../../stores/data/LAYOUTCOMPONENTTYPECONFIG'

export const FieldlistLayoutBuilder = observer(function FieldlistLayoutBuilder({
    className,
    component,
    components,
    layout,
    record,
    worksheet,
    active_class,
    visited_gids,
}) {
    const config = LAYOUTCOMPONENTTYPECONFIG['fieldlist']

    const field_gids = component.fieldlist ? [component.fieldlist] : ['{fieldlist}']

    const fields = worksheet.findFields(field_gids)

    const editable =
        'editable' in component ? component.editable : config.editable.default

    let Fields = []
    fields.forEach((field, index) => {
        const renderkey = field.gid

        if (field_gids.includes('{all}')) {
            Fields.push(<Divider key={'key-' + index} className="ws-margin-bottom" />)
        }

        const fieldtypeconfig =
            LAYOUTCOMPONENTTYPECONFIG['field']['typeconfig'][field.type]
        const fieldtypestyle = fieldtypeconfig['style']
            ? fieldtypeconfig['style']['default']
            : undefined

        const fieldlayoutbuildercomponent = {
            gid: '-not-selectable-' + index,
            field: field.name,
            placeholder: '',
            editable: editable,
            style: fieldtypestyle,
            width: 250, // image
            columns: 3, // imagelist
        }

        Fields.push(
            <FieldLayoutBuilder
                key={renderkey}
                component={fieldlayoutbuildercomponent}
                components={{}}
                layout={layout}
                record={record}
                worksheet={worksheet}
                active_class={active_class}
                visited_gids={visited_gids}
            />
        )
    })

    let attributes = { className: 'layout-component' }
    if (className) attributes['className'] += ' ' + className
    if (component.gid === worksheet.layoutstore.selectedComponentGid) {
        attributes['className'] += ' cc-selected'
    }
    // attributes["onClick"] = e => onClickElement(e, component, layouttreestore)
    return <VView {...attributes}>{Fields}</VView>
})

//
// SchemaContextmenu
//
// Actions on schema items in the TreeNavigator or QueryNavigator
//
// The contextmenu is always triggered on a schema item that is in the selection.

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { Menu, MenuDivider, MenuItem } from '../components'

export const SchemaContextmenu = observer(function SchemaContextmenu({
    schemaitem,
    schemaitemsselection,
    onAction,
}) {
    const { app } = useStore()

    const _onAction = action => {
        onAction && onAction(action)
    }

    const disableCommit = false
    const disableRevert = false
    const disableSaveAsNew = false
    const disableDuplicate = false
    const disableDelete = false

    return (
        <Menu>
            <MenuItem
                disabled={disableCommit}
                onClick={() => _onAction('commit_schema_selection')}
            >
                {app.text('Commit')}
            </MenuItem>
            <MenuItem
                disabled={disableRevert}
                onClick={() => _onAction('revert_schema_selection')}
            >
                {app.text('Revert changes')}
            </MenuItem>
            <MenuItem
                disabled={disableSaveAsNew}
                onClick={() => _onAction('save_as_new_schema_selection')}
            >
                {app.text('Save as new')}
            </MenuItem>
            <MenuDivider />
            <MenuItem
                disabled={disableDuplicate}
                onClick={() => _onAction('copy_schema_selection')}
            >
                {app.text('Duplicate')}
            </MenuItem>
            <MenuItem
                disabled={disableDelete}
                className="cc-danger"
                onClick={() => _onAction('delete_schema_selection')}
            >
                {app.text('Delete (if unused)')}
            </MenuItem>
        </Menu>
    )
})

//
// ContextMenu lab
//
// Show/test context menus

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../stores'

import { HView, VView, Spacer } from '../../appview'
import { useMousePopover } from '../../hooks/useMousePopover'
import {
    Menu,
    MenuDivider,
    MenuItem,
    Text,
    TextInput,
    Property,
    Select,
    Header,
} from '../../components'

export const InspectFieldPanel = observer(function InspectFieldPanel() {
    const { app } = useStore()

    const UpdateSubmenu = (
        <Menu>
            <MenuItem className="cc-notice">{app.text('Yes, apply globally')}</MenuItem>
        </Menu>
    )

    const SaveSubmenu = (
        <VView
            className="layouttreemenu-panel"
            onClick={e => e.nativeEvent.stopImmediatePropagation()}
        >
            <div className="inspector">
                <Header>{app.text('Save as new field')}</Header>
                <Property label={app.text('name')}>
                    <TextInput
                        value="artnr [new]"
                        onClick={e => e.nativeEvent.stopImmediatePropagation()}
                    />
                </Property>
            </div>
            <Menu>
                <MenuItem>{app.text('Save')}</MenuItem>
            </Menu>
        </VView>
    )

    return (
        <VView className="layouttreemenu-panel">
            <div className="inspector">
                <Header>{app.text('Properties')}</Header>
                <Property label={app.text('name')}>
                    <TextInput
                        value="artnr"
                        onClick={e => e.nativeEvent.stopImmediatePropagation()}
                    />
                </Property>
                <Property label={app.text('type')}>
                    <Select
                        value="textline"
                        options={{ textline: 'Text single line' }}
                    />
                </Property>
                <Spacer size={5} />
            </div>
            <Header>{app.text('Actions')}</Header>
            <Menu>
                <MenuItem key="create" submenu={UpdateSubmenu}>
                    {app.text('Commit')}
                </MenuItem>
                <MenuItem key="create" submenu={SaveSubmenu}>
                    {app.text('Save as new field')}
                </MenuItem>
                <MenuDivider key="divider-remove" />
                <MenuItem className="cc-danger">
                    {app.text('Remove from definition')}
                </MenuItem>
            </Menu>
        </VView>
    )
})

export const ContextMenu = observer(function ContextMenu() {
    const { user, app } = useStore()

    const MenuPanel = observer(function MenuPanel(props) {
        const SmallSubmenu = (
            <Menu>
                <MenuItem onClick={() => console.log('Nested Move')}>
                    {app.text('Move')}
                </MenuItem>
                <MenuItem onClick={() => console.log('Nested Copy')}>
                    {app.text('Copy')}
                </MenuItem>
                <MenuItem onClick={() => console.log('Nested Linked copy')}>
                    {app.text('Linked copy')}
                </MenuItem>
            </Menu>
        )

        const LargeSubmenu = (
            <Menu>
                <MenuItem onClick={() => console.log('Nested first')}>
                    {app.text('First')}
                </MenuItem>
                <MenuItem submenu={SmallSubmenu}>
                    {app.text('CONTEXT MENU ITEM')}
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={() => console.log('Nested long text')}>
                    {app.text('Hele lange tekst, misschien wel wat te lang')}
                </MenuItem>
                <MenuItem onClick={() => console.log('Nested some item 1')}>
                    {app.text('Some item 1')}
                </MenuItem>
                <MenuItem onClick={() => console.log('Nested some item 2')}>
                    {app.text('Some item 2')}
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={() => console.log('Nested some item 3')}>
                    {app.text('Some item 3')}
                </MenuItem>
                <MenuItem disabled onClick={() => console.log('Nested another item')}>
                    {app.text('Another item')}
                </MenuItem>
                <MenuItem onClick={() => console.log('Nested and another')}>
                    {app.text('And another')}
                </MenuItem>
            </Menu>
        )

        return (
            <Menu>
                <MenuItem submenu={SmallSubmenu}>
                    {app.text('CONTEXT MENU ITEM')}
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={() => console.log('Long text')}>
                    {app.text('Hele lange tekst, misschien wel wat te lang')}
                </MenuItem>
                <MenuItem submenu={LargeSubmenu}>{app.text('Some item 1')}</MenuItem>
                <MenuItem onClick={() => console.log('Some item 2')}>
                    {app.text('Some item 2')}
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={() => console.log('Some item 3')}>
                    {app.text('Some item 3')}
                </MenuItem>
            </Menu>
        )
    })
    const LabContextMenu = useMousePopover(MenuPanel)
    const InspectFieldContextMenu = useMousePopover(InspectFieldPanel)

    if (!user) return null

    return (
        <HView grow style={{ padding: 20 }}>
            <LabContextMenu.Panel />
            <InspectFieldContextMenu.Panel />
            <style>
                {`
                #root .cc-Menu {
                    max-width: 202px;
                    padding: 5px 0;
                    border: 1px solid #dfdfe1;
                    border-radius: 5px;
                    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
                }
                `}
            </style>
            <VView
                className="actions-panel"
                grow
                style={{ flexBasis: 200, maxWidth: 200, minWidth: 200 }}
            >
                <div>
                    <Text onContextMenu={LabContextMenu.onShow}>
                        {app.text('Context menu (right-click)')}
                    </Text>
                    <Text onClick={LabContextMenu.onShow}>
                        {app.text('Menu (click)')}
                    </Text>
                    <div className="inspector" style={{ padding: '200px 0 50px' }}>
                        <Property
                            label={app.text('artnr')}
                            onContextMenu={InspectFieldContextMenu.onShow}
                        >
                            <TextInput value="CMS 225-945" />
                        </Property>
                    </div>
                </div>
                <Spacer />
                <div>
                    <Text onClick={LabContextMenu.onShow}>
                        {app.text('Menu (click)')}
                    </Text>
                    <Text onContextMenu={LabContextMenu.onShow}>
                        {app.text('Context submenus (right-click)')}
                    </Text>
                </div>
            </VView>
            <VView grow>
                <div>
                    <MenuPanel />
                </div>
            </VView>
            <VView
                className="actions-panel"
                grow
                style={{ flexBasis: 200, maxWidth: 200, minWidth: 200 }}
            >
                <div>
                    <Text onContextMenu={LabContextMenu.onShow}>
                        {app.text('Context menu (right-click)')}
                    </Text>
                    <Text onClick={LabContextMenu.onShow}>
                        {app.text('Menu (click)')}
                    </Text>
                </div>
                <Spacer />
                <div>
                    <Text onClick={LabContextMenu.onShow}>
                        {app.text('Menu (click)')}
                    </Text>
                    <Text onContextMenu={LabContextMenu.onShow}>
                        {app.text('Context submenus (right-click)')}
                    </Text>
                </div>
            </VView>
        </HView>
    )
})

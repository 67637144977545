//
// RecordDefinitionChangePanel
//
// Change a record's definition

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'

import { VView, VScrollView, Spacer, FixedVSpace } from '../appview'
import {
    Header,
    SearchInput,
    Icon,
    Item,
    Property,
    Text,
    Menu,
    MenuItem,
} from '../components'
import { normalize } from '../utils/text'
import { changeDefinitionLostDataFields } from '../stores/data/utils'

export const RecordDefinitionChangePanel = observer(
    function RecordDefinitionChangePanel({
        worksheet,
        alwaysConfirm,
        onChangeDefinition,
        onCancel,
    }) {
        const { app, data, view } = useStore()
        const language = worksheet.environment.get('language')

        const [searchtext, setSearchtext] = useState('')
        const [selecteditem, setSelecteditem] = useState(null)
        let selection_in_results = false // initially

        let searchresultdefinitions = Array.from(data.definitions.entries())
        searchresultdefinitions = searchresultdefinitions.filter(
            ([key, definition]) =>
                !definition.is_working_copy && !definition.is_extended
        )

        if (searchtext.length) {
            const searchwords = normalize(searchtext).split(' ')
            searchresultdefinitions = searchresultdefinitions.filter(
                ([key, definition]) => {
                    // do a match: in label and name, label only current language
                    // first, normalize (validName)
                    // second, split on spaces
                    // then, match all words
                    let definitionlabel = definition.label.get(language)
                    if (!definitionlabel || !definitionlabel.length) {
                        definitionlabel = definition.name
                    }
                    definitionlabel = normalize(definitionlabel)
                    for (const word of searchwords) {
                        if (!word.length) continue
                        if (
                            !definitionlabel.includes(word) &&
                            !definition.name.includes(word)
                        ) {
                            return false
                        }
                    }
                    return true
                }
            )
        }

        searchresultdefinitions.sort((a, b) => {
            const adefinition = a[1]
            const bdefinition = b[1]
            let aname = adefinition.name
            aname = normalize(aname)
            let bname = bdefinition.name
            bname = normalize(bname)
            if (aname === bname) return 0
            return aname > bname ? 1 : -1
        })

        const onSearchinputChange = event => {
            setSearchtext(event.target.value)
        }
        const onSearchinputBlur = event => {
            setSearchtext(event.target.value.trim())
        }

        const _onChangeDefinition = () => {
            onChangeDefinition && selecteditem && onChangeDefinition(selecteditem)
        }

        const _onCancel = () => {
            onCancel && onCancel()
        }

        const searchresults = searchresultdefinitions.length ? (
            searchresultdefinitions.map(([key, definition]) => {
                if (selecteditem === key) selection_in_results = true
                return (
                    <Item
                        key={key}
                        selected={selecteditem === key}
                        onClick={() => setSelecteditem(key)}
                    >
                        {definition.name}
                    </Item>
                )
            })
        ) : (
            <div style={{ padding: '2px 5px' }}>{app.text('No matches')}</div>
        )

        const selecteddefinition = selection_in_results
            ? data.definitions.get(selecteditem)
            : null
        let selecteddefinition_info
        if (selecteddefinition) {
            let definitionname = selecteddefinition.label.get(language)
            if (!definitionname || !definitionname.length) {
                definitionname = selecteddefinition.name
            }
            selecteddefinition_info = (
                <>
                    <Property className="nobreak" label={app.text('name')}>
                        <Text className="user-select">{selecteddefinition.name}</Text>
                    </Property>
                    <Property label={app.text('label')}>
                        <Text className="user-select">{definitionname}</Text>
                    </Property>
                </>
            )
        }

        const definition = data.definitions.get(worksheet.selected.record.definition)

        const lost_fields =
            selecteddefinition && definition && !alwaysConfirm
                ? changeDefinitionLostDataFields(selecteddefinition, definition, data)
                : []

        const ConfirmSubmenu = observer(function ConfirmSubmenu({
            lost_fields,
            onConfirm,
            onCancel,
        }) {
            const { app } = useStore()

            const _onConfirm = () => {
                onConfirm && onConfirm()
            }

            const _onCancel = () => {
                onCancel && onCancel()
            }

            return (
                <VView className="actions-panel recorddefinition-change-panel">
                    <div className="inspector">
                        <Header className="cc-danger">{app.text('Warning')}</Header>
                        <Text>
                            {app.text(
                                `Changing the definition can result in data loss
                                 when fields are no longer available in the new
                                 definition. This data cannot be recovered by
                                 changing back to the previous definition.`
                            )}
                        </Text>
                        {lost_fields.length ? (
                            <>
                                <Text>
                                    {app.text(
                                        `For all records that use this definition,
                                         these fields will be lost:`
                                    )}
                                </Text>
                                <VScrollView className="lost-fields">
                                    {lost_fields.map(field => {
                                        const title =
                                            view.schemaworksheet.layoutstore.getItemTitle(
                                                field,
                                                language
                                            )
                                        return (
                                            <Item key={field.gid}>
                                                <Icon name="field" size="text" />{' '}
                                                {title}
                                            </Item>
                                        )
                                    })}
                                </VScrollView>
                                <FixedVSpace size={5} />
                            </>
                        ) : undefined}
                    </div>
                    <Menu>
                        <MenuItem onClick={_onCancel}>{app.text('Cancel')}</MenuItem>
                        <MenuItem className="cc-danger" onClick={_onConfirm}>
                            {app.text('Yes, change the definition')}
                        </MenuItem>
                    </Menu>
                </VView>
            )
        })

        return (
            <VView className="actions-panel recorddefinition-change-panel">
                <div className="inspector">
                    <Header>{app.text("Change record's definition")}</Header>
                    <Property label={app.text('from')}>
                        <Text className="user-select">
                            {definition && definition.name}
                        </Text>
                    </Property>
                    <VView
                        style={{
                            marginLeft: 5,
                            marginRight: 5,
                            marginBottom: 2,
                        }}
                    >
                        <SearchInput
                            value={searchtext}
                            placeholder={app.text('Search by name or label')}
                            onChange={onSearchinputChange}
                            onBlur={onSearchinputBlur}
                        />
                        <VScrollView
                            className="search-results"
                            style={{ backgroundColor: 'white' }}
                        >
                            {searchresults}
                        </VScrollView>
                        <Spacer size={5} />
                        {selecteddefinition_info}
                    </VView>
                </div>
                <Menu>
                    <MenuItem
                        disabled={!selection_in_results}
                        className={
                            lost_fields.length || alwaysConfirm ? 'cc-notice' : ''
                        }
                        submenu={
                            lost_fields.length || alwaysConfirm ? (
                                <ConfirmSubmenu
                                    lost_fields={lost_fields}
                                    onConfirm={_onChangeDefinition}
                                    onCancel={_onCancel}
                                />
                            ) : undefined
                        }
                        onClick={
                            lost_fields.length || alwaysConfirm
                                ? undefined
                                : e => {
                                      _onChangeDefinition()
                                  }
                        }
                    >
                        {selecteddefinition
                            ? app.text("Change to '{definitionname}'", {
                                  definitionname: selecteddefinition.name,
                              })
                            : app.text('Change definition')}
                    </MenuItem>
                </Menu>
            </VView>
        )
    }
)

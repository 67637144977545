//
// PimWorksheetStore
//
// The PimWorksheetStore handles interaction with the Pim Worksheet. This means access
// to the current record, the path to the root, and the direct children. We also know
// which property is selected, how that property is presented, and if it is the records
// property or perhaps a property of one of the children or path-records.
//
// When the environment language changes, we refetch the data.
// When the environment ordering changes, we refetch the data.
//
// SIDE-EFFECT: when the current record changes, we update the pimtree with that same
//              record, so it is visible and selected

import {
    makeObservable,
    observable,
    computed,
    action,
    reaction,
    runInAction,
} from 'mobx'

import { LayoutStore } from './LayoutStore'
import { difference } from '../../utils/set'

export class PimWorksheetStore {
    __init = false
    _key = null
    _rootstore = null
    environment = null
    record = null
    parent = null
    path = null
    children = null
    selected = null
    sibling_definitions = null
    child_definitions = null
    definitions = null
    classes = null
    fields = null
    layouts = null

    constructor(key, rootstore, environment) {
        makeObservable(this, {
            __init: observable,
            _key: observable,
            _rootstore: observable,
            environment: observable,

            record: observable,
            parent: observable,
            path: observable,
            children: observable,
            selected: observable,
            sibling_definitions: observable,
            child_definitions: observable,

            // data...
            definitions: observable,
            classes: observable,
            fields: observable,
            layouts: observable,

            all_fields: computed,
            definition_fields: computed,

            definition_validation: computed,
            sibling_definitions_validation: computed,
            child_definitions_validation: computed,

            language: computed,
            datatype: computed,
            fetch: action.bound,
            refetch: action.bound,
            _fetch: action.bound,
            setRecord: action.bound,
            setSelected: action.bound,
            setSelectedRecord: action.bound,
            setSelectedClass: action.bound,
            setSelectedProperty: action.bound,

            syncdataitem_callback: action.bound,
        })

        this._key = key
        this._rootstore = rootstore
        this.environment = environment

        this.record = null
        this.parent = null
        this.path = []
        this.children = []
        this.sibling_definitions = []
        this.child_definitions = []
        this.definitions = {}
        this.classes = {}
        this.fields = {}
        this.layouts = {}
        this.layoutstore = new LayoutStore(null, rootstore)
        this.selected = {
            record: null,
            classgid: null,
            property: { fieldgid: null, classgid: null, view: null },
        }

        this._syncdebounce = null
        this._syncdelay = 100
    }

    loadOnce = () => {
        if (this.__init) return
        const recordgid = this._rootstore.view.loadPerProject(this._key + '/rec')
        const selectedrecordgid = this._rootstore.view.loadPerProject(
            this._key + '/selected/rec'
        )
        const selectedclass = this._rootstore.view.loadPerProject(
            this._key + '/selected/class'
        )
        const selectedproperty = this._rootstore.view.loadPerProject(
            this._key + '/selected/field'
        )
        const selectedpropertyclass = this._rootstore.view.loadPerProject(
            this._key + '/selected/field/class'
        )
        const selectedpropertyview = null
        // const selectedpropertyview = this._rootstore.view.loadPerProject(
        //     this._key + "/selected/field/view"
        // )
        this.definitions = this._rootstore.data.definitions
        this.classes = this._rootstore.data.classes
        this.fields = this._rootstore.data.fields
        this.layouts = this._rootstore.data.layouts
        this.fetch(recordgid).then(() => {
            if (this.record) {
                this._rootstore.view._pimtree.setRecord(this.record)
                const definition = this._rootstore.data.definitions.get(
                    this.record.definition
                )
                const layout = definition
                    ? this._rootstore.data.layouts.get(definition.layout)
                    : null
                this.layoutstore.setLayout(layout)
                const selectedrecord =
                    this._rootstore.data.records.get(selectedrecordgid)
                if (!selectedrecord) {
                    this.setSelected(this.record, null, null, null, null)
                } else {
                    this.setSelected(
                        selectedrecord,
                        selectedclass,
                        selectedproperty,
                        selectedpropertyclass,
                        selectedpropertyview
                    )
                }
            }
            reaction(
                () => this.environment.get('language'),
                language => {
                    if (this.record) this._fetch(this.record.gid)
                }
            )
            reaction(
                () => this.environment.get('ordering'),
                ordering => {
                    if (this.children.length) this._fetch(this.record.gid)
                }
            )
            reaction(
                () => this.record,
                record => {
                    if (record) {
                        this._rootstore.view._pimtree.setRecord(record)
                        const definition = this._rootstore.data.definitions.get(
                            this.record.definition
                        )
                        const layout = definition
                            ? this._rootstore.data.layouts.get(definition.layout)
                            : null
                        this.layoutstore.setLayout(layout)
                    }
                }
            )
            reaction(
                () => (this.record ? this.record.definition : null),
                definition_gid => {
                    if (definition_gid) {
                        const definition =
                            this._rootstore.data.definitions.get(definition_gid)
                        const layout = definition
                            ? this._rootstore.data.layouts.get(definition.layout)
                            : null
                        this.layoutstore.setLayout(layout)
                    }
                }
            )
        })
        this._rootstore.api.register_syncdataitem_callback(this.syncdataitem_callback)
        this.__init = true
    }

    fetch(recordgid) {
        if (this.record && recordgid === this.record.gid) {
            return Promise.resolve(true) // nothing to fetch
        }
        return this._fetch(recordgid)
    }

    refetch() {
        if (!this.record) {
            return Promise.resolve(true) // nothing to fetch
        }
        return this._fetch(this.record.gid)
    }

    _fetch(recordgid) {
        if (!recordgid) {
            this._rootstore.view.savePerProject(this._key + '/rec', null)
            return Promise.resolve(true) // nothing to fetch
        }
        return this._rootstore
            ._fetch('/records/worksheet', {
                record: recordgid,
                language: this.environment.get('language'),
                ordering: this.environment.get('ordering'),
            })
            .then(result => {
                runInAction(() => {
                    // store data in store.data.pimrecords
                    // and use it to populate worksheet items?
                    let oldrecordgid
                    if (this.record) oldrecordgid = this.record.gid

                    this.record = this._rootstore.data.records.get(result['record'])
                    this.parent =
                        result['path'].length > 1
                            ? this._rootstore.data.records.get(
                                  result['path'][result['path'].length - 2]
                              )
                            : null
                    this.path.replace(
                        result['path'].map(gid => this._rootstore.data.records.get(gid))
                    )
                    this.children.replace(
                        result['children'].map(gid =>
                            this._rootstore.data.records.get(gid)
                        )
                    )
                    this.sibling_definitions.replace(
                        result['sibling_definitions'].map(gid =>
                            this._rootstore.data.definitions.get(gid)
                        )
                    )
                    this.child_definitions.replace(
                        result['child_definitions'].map(gid =>
                            this._rootstore.data.definitions.get(gid)
                        )
                    )
                    if (!this.record || oldrecordgid !== this.record.gid) {
                        this.setSelected(this.record, null, null, null, null)
                    }
                    const thisrecordgid = this.record ? this.record.gid : null
                    this._rootstore.view.savePerProject(
                        this._key + '/rec',
                        thisrecordgid
                    )
                })
                return result
            })
            .catch(error => {})
    }

    get datatype() {
        // for layout purposes
        if (!this.record) return null
        const definition = this.definitions.get(this.record.definition)
        if (!definition) return null
        if (definition.is_extended) return 'extended'
        return 'definition'
    }

    setRecord(record) {
        // this is external; when we select an item, we need to make sure it appears in
        // the worksheet, and reset the selection
        if (!record) {
            this.setSelected(null, null, null, null, null)
            return
        }
        this.fetch(record.gid).then(() => {
            runInAction(() => {
                this.setSelected(record, null, null, null, null)
            })
        })
    }

    setSelected(record, classgid, fieldgid, fieldclassgid, fieldview) {
        this.setSelectedRecord(record)
        this.setSelectedClass(classgid)
        this.setSelectedProperty(fieldgid, fieldclassgid, fieldview)
    }

    setSelectedRecord(record) {
        if (record !== this.selected.record) {
            this.selected.record = record
            this._rootstore.view.savePerProject(
                this._key + '/selected/rec',
                this.selected.record ? this.selected.record.gid : null
            )
        }
    }

    setSelectedClass(classgid) {
        if (
            classgid !== this.selected.classgid &&
            (classgid === null || this.classes.has(classgid))
        ) {
            this.selected.classgid = classgid
            this._rootstore.view.savePerProject(
                this._key + '/selected/class',
                this.selected.classgid
            )
        }
    }

    setSelectedProperty(fieldgid, fieldclassgid, fieldview) {
        if (this.selected.property.fieldgid !== fieldgid) {
            this.selected.property.fieldgid = fieldgid
            this._rootstore.view.savePerProject(
                this._key + '/selected/field',
                this.selected.property.fieldgid
            )
        }
        if (this.selected.property.classgid !== fieldclassgid) {
            this.selected.property.classgid = fieldclassgid
            this._rootstore.view.savePerProject(
                this._key + '/selected/field/class',
                this.selected.property.classgid
            )
        }
        if (this.selected.property.view !== fieldview) {
            this.selected.property.view = fieldview
            this._rootstore.view.savePerProject(
                this._key + '/selected/field/view',
                this.selected.property.view
            )
        }
    }

    getDefinitionFields(definition) {
        if (!this.record) return []
        let fields = []
        let field
        definition.fields.forEach(fieldgid => {
            field = this.fields.get(fieldgid)
            if (field) {
                fields.push(field)
            }
        })
        return fields
    }

    getRecordFields(record) {
        if (!this.record) return []
        if (!record) return []
        const definition = this.definitions.get(record.definition)
        if (!definition) return []
        let fields = []
        let field_gids = new Set()
        let field
        definition.classes.forEach(classgid => {
            let class_ = this.classes.get(classgid)
            if (class_) {
                class_.fields.forEach(fieldgid => {
                    if (!field_gids.has(fieldgid)) {
                        field = this.fields.get(fieldgid)
                        if (field) {
                            fields.push(field)
                            field_gids.add(fieldgid)
                        }
                    }
                })
            }
        })
        definition.fields.forEach(fieldgid => {
            if (!field_gids.has(fieldgid)) {
                field = this.fields.get(fieldgid)
                if (field) {
                    fields.push(field)
                    field_gids.add(fieldgid)
                }
            }
        })
        return fields
    }

    getNamedFieldsWithClass(definition, fieldnames) {
        let fields = []
        let class_ = undefined
        let field = undefined
        let fieldnamescopy = [...fieldnames]
        const fieldlistindex = fieldnamescopy.indexOf('{fieldlist}')
        if (fieldlistindex !== -1) {
            const fieldlistnames = definition.fieldlists.has(
                this.environment.get('fieldlist')
            )
                ? definition.fieldlists.get(this.environment.get('fieldlist'))
                : []
            fieldnamescopy.splice(fieldlistindex, 1, ...fieldlistnames)
        }
        fieldnamescopy.forEach(fieldname => {
            definition.classes.forEach(classgid => {
                class_ = this.classes.get(classgid)
                if (class_) {
                    class_.fields.forEach(fieldgid => {
                        field = this.fields.get(fieldgid)
                        if (field && ['{all}', fieldgid].includes(fieldname)) {
                            fields.push([field, class_])
                        }
                    })
                }
            })
            definition.fields.forEach(fieldgid => {
                field = this.fields.get(fieldgid)
                if (field && ['{all}', '{custom}', fieldgid].includes(fieldname)) {
                    fields.push([field, null])
                }
            })
        })
        return fields
    }

    get definition_fields() {
        if (!this.record) return []
        const definition = this.definitions.get(this.record.definition)
        if (!definition) return []
        return this.getDefinitionFields(definition)
    }

    get all_fields() {
        if (!this.record) return []
        return this.getRecordFields(this.record)
    }

    get language() {
        if (!this.environment) return null
        return this.environment.get('language')
    }

    get definition_validation() {
        // return 'unexpected', 'foreign' or null
        if (!this.parent || !this.record) return null
        const parentdefinition = this.definitions.get(this.parent.definition)
        if (!parentdefinition) return null
        if (!parentdefinition.childdefinitions.length) {
            return 'unexpected'
        }
        const definition = this.definitions.get(this.record.definition)
        if (!definition) return null
        const definition_gid = definition.is_extended
            ? definition.original
            : definition.gid
        if (!parentdefinition.childdefinitions.includes(definition_gid)) {
            return 'foreign'
        }
        return null
    }

    definitions_validation(parent_definition, child_definitions) {
        // return a list, values (if any) can include 'unexpected', 'foreign',
        // 'mixed', and 'expected'
        let validation = []
        if (!parent_definition.childdefinitions.length) {
            validation.push('unexpected')
        } else {
            const definition_gids = new Set(
                child_definitions.map(definition =>
                    definition.is_extended ? definition.original : definition.gid
                )
            )
            const childdefinition_gids = new Set(parent_definition.childdefinitions)
            if (difference(definition_gids, childdefinition_gids).size) {
                validation.push('foreign')
            }
        }
        if (parent_definition.childdefinitions.length && !child_definitions.length) {
            validation.push('expected')
        }
        if (child_definitions.length > 1) {
            // this is too simple, since we're working with extended definitions, the
            // contents of the definitions can actually be the same and then it
            // shouldn't say 'mixed'.
            // for non-extended records, just check gid
            // for extended, a fair comparison would be the same original, the same
            // classes, the same fields, and the same fieldlists
            // is order important? data-wise, no, but layout-wise, yes... hmmm
            // for now, require exact matches incl ordering
            // if not, we can sort gids before concatenating
            const hashes = new Set(
                child_definitions.map(definition => {
                    if (!definition.is_extended) return definition.gid
                    let hash =
                        definition.original +
                        '#' +
                        definition.classes.join('#') +
                        definition.fields.join('#')
                    const sortedkeys = Array.from(definition.fieldlists.keys()).sort()
                    for (const key of sortedkeys) {
                        hash += '#' + definition.fieldlists.get(key).join('#')
                    }
                    return hash
                })
            )
            if (hashes.size > 1) {
                validation.push('mixed')
            }
        }
        return validation
    }

    get sibling_definitions_validation() {
        // return a list, values (if any) can include 'unexpected', 'foreign' and
        // 'mixed'
        if (!this.parent) return []
        const parent_definition = this.definitions.get(this.parent.definition)
        return this.definitions_validation(parent_definition, this.sibling_definitions)
    }

    get child_definitions_validation() {
        // return a list, values (if any) can include 'unexpected', 'foreign',
        // 'mixed', and 'expected'
        if (!this.record) return []
        const definition = this.definitions.get(this.record.definition)
        return this.definitions_validation(definition, this.child_definitions)
    }

    getAllClasses() {
        if (!this.record) return []
        const definition = this.definitions.get(this.record.definition)
        if (!definition) return []
        let classes = []
        definition.classes.forEach(classgid => {
            let class_ = this._rootstore.data.classes.get(classgid)
            if (class_) {
                classes.push(class_)
            }
        })
        return classes
    }

    getAllFields() {
        if (!this.record) return []
        const definition = this.definitions.get(this.record.definition)
        if (!definition) return []
        let fields = []
        let field_gids = new Set()
        let field
        definition.classes.forEach(classgid => {
            let class_ = this._rootstore.data.classes.get(classgid)
            if (class_) {
                class_.fields.forEach(fieldgid => {
                    if (!field_gids.has(fieldgid)) {
                        field = this._rootstore.data.fields.get(fieldgid)
                        if (field) {
                            fields.push(field)
                            field_gids.add(fieldgid)
                        }
                    }
                })
            }
        })
        definition.fields.forEach(fieldgid => {
            if (!field_gids.has(fieldgid)) {
                field = this._rootstore.data.fields.get(fieldgid)
                if (field) {
                    fields.push(field)
                    field_gids.add(fieldgid)
                }
            }
        })
        return fields
    }

    getAllChildFields() {
        if (!this.record) return []
        const definition = this.definitions.get(this.record.definition)
        if (!definition) return []
        let definitiongids = definition.childdefinitions
        let fields = []
        let field_gids = new Set()
        let field
        definitiongids.forEach(definitiongid => {
            let definition = this._rootstore.data.definitions.get(definitiongid)
            if (!definition) return
            definition.classes.forEach(classgid => {
                let class_ = this._rootstore.data.classes.get(classgid)
                if (class_) {
                    class_.fields.forEach(fieldgid => {
                        if (!field_gids.has(fieldgid)) {
                            field = this._rootstore.data.fields.get(fieldgid)
                            if (field) {
                                fields.push(field)
                                field_gids.add(fieldgid)
                            }
                        }
                    })
                }
            })
            definition.fields.forEach(fieldgid => {
                if (!field_gids.has(fieldgid)) {
                    field = this._rootstore.data.fields.get(fieldgid)
                    if (field) {
                        fields.push(field)
                        field_gids.add(fieldgid)
                    }
                }
            })
        })
        return fields
    }

    getAllFirstChildFields() {
        if (!this.record) return []
        if (!this.children.length) return []
        const firstchild = this.children[0]
        const definition = this.definitions.get(firstchild.definition)
        if (!definition) return []
        let fields = []
        let field_gids = new Set()
        let field
        definition.classes.forEach(classgid => {
            let class_ = this._rootstore.data.classes.get(classgid)
            if (class_) {
                class_.fields.forEach(fieldgid => {
                    if (!field_gids.has(fieldgid)) {
                        field = this._rootstore.data.fields.get(fieldgid)
                        if (field) {
                            fields.push(field)
                            field_gids.add(fieldgid)
                        }
                    }
                })
            }
        })
        definition.fields.forEach(fieldgid => {
            if (!field_gids.has(fieldgid)) {
                field = this._rootstore.data.fields.get(fieldgid)
                if (field) {
                    fields.push(field)
                    field_gids.add(fieldgid)
                }
            }
        })
        return fields
    }

    findField(field_gid_or_name) {
        if (this._rootstore.data.fields.has(field_gid_or_name)) {
            return this._rootstore.data.fields.get(field_gid_or_name)
        }
        for (let field of this._rootstore.data.fields.values()) {
            if (field.name === field_gid_or_name) {
                return field
            }
        }
        return undefined
    }

    findFields(field_gids_or_names) {
        let fields = []
        for (let field of this._rootstore.data.fields.values()) {
            if (field_gids_or_names.includes(field.gid)) {
                fields.push(field)
            } else if (field_gids_or_names.includes(field.name)) {
                fields.push(field)
            }
        }
        return field_gids_or_names.map((field_gid_or_name, index) => {
            for (let field of fields) {
                if (
                    field.gid === field_gid_or_name ||
                    field.name === field_gid_or_name
                ) {
                    return field
                }
            }
            return {
                gid: '-not-selectable-' + field_gid_or_name,
                type: 'text',
                name: field_gid_or_name,
            }
        })
    }

    findFieldNamed(definition, fieldname) {
        if (!definition) {
            if (!this.record) return null
            definition = this.definitions.get(this.record.definition)
        }
        if (!definition) {
            return null
        }
        for (let field of this._rootstore.data.fields.values()) {
            if (field.name === fieldname) {
                return field
            }
        }
        return undefined
    }

    findClassForField(definition, field) {
        if (!definition) {
            if (!this.record) return null
            definition = this.definitions.get(this.record.definition)
        }
        if (!definition) {
            return null
        }
        // first look in the definition.fields for the fields, then
        // in each class
        if (definition.fields.includes(field.gid)) {
            return null
        }
        for (const classgid of definition.classes) {
            const class_ = this._rootstore.data.classes.get(classgid)
            if (class_ && class_.fields.includes(field.gid)) {
                return class_
            }
        }
        return null
    }

    schedule_refetch = () => {
        if (this._syncdebounce) {
            clearTimeout(this._syncdebounce)
            this._syncdebounce = null
        }
        this._syncdebounce = setTimeout(() => {
            this.refetch()
            this._syncdebounce = null
        }, this._syncdelay)
    }

    syncdataitem_callback = (syncdataitem, data_before, data_after) => {
        // console.log('PimWorksheetStore sync', syncdataitem, data_before, data_after)
        // we're only interested in data_type "records" and "definition"
        // for records, we're only interested in updates (of childcount) and deletions
        // for updated records, only for self, when childcount has changed
        // for deletes, only for self -> select non-deleted record from parents
        // for definitions, we're only interested in definition changes for records
        // in the worksheet - then we should refetch those records
        if (!['records', 'definitions'].includes(syncdataitem['data_type'])) return
        if (!this.record) return
        if (syncdataitem['data_type'] === 'definitions') {
            if (this.record.definition === syncdataitem['data_key']) {
                this.schedule_refetch()
                return
            }
            for (const parent of this.path) {
                if (parent.definition === syncdataitem['data_key']) {
                    this.schedule_refetch()
                    return
                }
            }
            for (const child of this.children) {
                if (child.definition === syncdataitem['data_key']) {
                    this.schedule_refetch()
                    return
                }
            }
            return
        }
        if (syncdataitem['action'] === 'UPDATE') {
            if (syncdataitem['data_key'] !== this.record.gid) return
            if (data_before.childcount !== data_after.childcount) {
                this.schedule_refetch()
                return
            }
        } else if (syncdataitem['action'] === 'DELETE') {
            if (data_before && data_before.parent === this.record.gid) {
                this.schedule_refetch()
                return
            }
            if (syncdataitem['data_key'] !== this.record.gid) return
            const path = [...this.path]
            path.reverse()
            for (const crumb of path) {
                if (this._rootstore.data.records.has(crumb.gid)) {
                    this.setRecord(crumb)
                    return
                }
            }
        } else if (syncdataitem['action'] === 'INSERT') {
            if (data_after.parent === this.record.gid) {
                this.schedule_refetch()
                return
            }
        }
    }
}

//
// MenuDivider
//
// A divider between MenuItems

import React from 'react'

export const MenuDivider = React.forwardRef(({ className, ...other }, ref) => {
    let classes = 'cc-MenuItem cc-divider'
    if (className) classes += ' ' + className

    return <div ref={ref} className={classes} {...other} />
})

// Validators get a text value from an input field, and the field options
// They determine if the input satisfies the criteria.
// A true or false verdict is returned.
// Each validator starts with the isValid prefix
//
// We have basic validators, which just check the type (but e.g. barcode type has a
// format option that must be used too).
//
// If options is used in a validator, the second parameter name should be named options
//
// This file should evolve in parallel to api/src/models/pim/record/validators.py
import {
    VALIDATION,
    ValidationResult,
    isValidText,
    isValidTextline,
    isValidTextlist,
    isValidUsername,
    isValidEmail,
    isValidPassword,
    isValidCode,
    isValidBarcode,
    isValidBoolean,
    isValidNumber,
    isValidDecimal,
    isValidRecord,
    isValidRecordlist,
    isValidImage,
    isValidImagelist,
    isValidFile,
    isValidFilelist,
    isValidFieldpicker,
    isValidClass,
    isValidClasslist,
} from '../../utils/validation'
import { listFromValues } from './utils'
import { test_true } from '../../utils/utils'
const sha1 = require('js-sha1')

export const duckField = (fieldtype, options = {}, is_required = false) => {
    return {
        type: fieldtype,
        is_required: is_required,
        options: new Map(Object.entries(options)),
    }
}

export const validateUsernameIsNotEmail = value => {
    // only validate if we have a value
    if (value === null || value === undefined || value.toString().trim().length === 0) {
        return new ValidationResult(VALIDATION.NA)
    }

    value = value.toString().trim()
    if (value.includes('@')) {
        return new ValidationResult(
            VALIDATION.ERROR,
            'Username should not be en email address'
        )
    }

    return new ValidationResult(VALIDATION.SUCCESS)
}

export const validateUsername = (value, original_value) => {
    // only validate if we have a value
    if (value === null || value === undefined || value.toString().trim().length === 0) {
        return new ValidationResult(VALIDATION.NA)
    }
    if (
        original_value !== null &&
        original_value !== undefined &&
        original_value === value.toString().trim()
    ) {
        return new ValidationResult(VALIDATION.SUCCESS)
    }

    value = value.toString().trim()
    if (!isValidUsername(value)) {
        return new ValidationResult(VALIDATION.ERROR, 'Username unavailable')
    }
    return isUsernameAvailable(value)
        .then(result => {
            if (!result) {
                return new ValidationResult(VALIDATION.ERROR, 'Username unavailable')
            } else {
                return new ValidationResult(VALIDATION.SUCCESS)
            }
        })
        .catch(() => {
            return new ValidationResult(
                VALIDATION.ERROR,
                'Username checking service is down'
            )
        })
}

export const isUsernameAvailable = value => {
    value = value.toString().trim() // just in case
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: value }),
    }
    return window
        .fetch('//' + window.location.host + '/api/username', options)
        .then(response => {
            if (!response.ok) {
                throw new Error(response.status + ' ' + response.statusText)
            }
            return response.json()
        })
        .then(response => {
            if (!response['success']) {
                throw new Error(
                    response['result']['code'] + ' ' + response['result']['message']
                )
            }
            return response['result']
        })
        .then(result => {
            return result['status'] === 'available'
        })
}

export const validateEmail = value => {
    // only validate if we have a value
    if (value === null || value === undefined || value.toString().trim().length === 0) {
        return new ValidationResult(VALIDATION.NA)
    }
    value = value.toString().trim()
    if (!isValidEmail(value)) {
        return new ValidationResult(VALIDATION.ERROR, 'Invalid email address format')
    }
    return new ValidationResult(VALIDATION.SUCCESS)
}

export const validatePassword = value => {
    // only validate if we have a value
    if (value === null || value === undefined || value.toString().trim().length === 0) {
        return new ValidationResult(VALIDATION.NA)
    }
    value = value.toString().trim()
    if (!isValidPassword(value)) {
        return new ValidationResult(
            VALIDATION.ERROR,
            'Password length must be between 10 and 100 characters'
        )
    }
    return isPwnedPassword(value)
        .then(frequency => {
            if (frequency > 0) {
                return new ValidationResult(
                    VALIDATION.ERROR,
                    'Password is common, or found in a data breach; please choose a different one'
                )
            } else {
                return new ValidationResult(VALIDATION.SUCCESS)
            }
        })
        .catch(() => {
            return new ValidationResult(
                VALIDATION.ERROR,
                'Password checking service is down'
            )
        })
}

export const isPwnedPassword = value => {
    value = value.toString().trim() // just in case
    const hashedvalue = sha1(value).toUpperCase()
    const first5 = hashedvalue.slice(0, 5)
    const remaining = hashedvalue.slice(5)
    return window
        .fetch('https://api.pwnedpasswords.com/range/' + first5, {
            headers: { 'Add-Padding': true },
        })
        .then(response => response.text())
        .then(body => body.split('\n'))
        .then(lines => {
            for (const line of lines) {
                let [suffix, frequency] = line.split(':')
                if (suffix === remaining) {
                    return parseInt(frequency.trim())
                }
            }
            return 0
        })
}

export const validateField = (value, field, optional_language) => {
    const fieldvalidators = {
        text: isValidText,
        textline: isValidTextline,
        textlist: isValidTextlist,
        textlistline: isValidText, // to allow paste of 'line 1\nline 2\nline3'
        code: isValidCode,
        barcode: isValidBarcode,
        boolean: isValidBoolean,
        number: isValidNumber,
        decimal: isValidDecimal,
        record: isValidRecord,
        recordlist: isValidRecordlist,
        image: isValidImage,
        imagelist: isValidImagelist,
        file: isValidFile,
        filelist: isValidFilelist,
        fieldpicker: isValidFieldpicker,
        class: isValidClass,
        classlist: isValidClasslist,
    }
    if (!field) {
        return new ValidationResult(VALIDATION.NA)
    }
    if (!(field.type in fieldvalidators)) {
        return new ValidationResult(VALIDATION.NA)
    }
    if (value === null || value === undefined || value.toString().trim().length === 0) {
        if (field.is_required) {
            return new ValidationResult(VALIDATION.REPORT, 'Required field')
        }
        return new ValidationResult(VALIDATION.SUCCESS)
    }
    if (!fieldvalidators[field.type](value, field.options, optional_language)) {
        if (field.options.has('format')) {
            return new ValidationResult(
                VALIDATION.ERROR,
                "Invalid input for type '{format} {type}'",
                { type: field.type, format: field.options.get('format') }
            )
        } else {
            return new ValidationResult(
                VALIDATION.ERROR,
                "Invalid input for type '{type}'",
                { type: field.type }
            )
        }
    }
    return validateFieldOptions(value, field, optional_language)
}

export const validateFieldOptions = (value, field, optional_language) => {
    let messages = []
    if (['number', 'decimal'].includes(field.type)) {
        if (field.options.has('min')) {
            if (!satisfiesMinOption(value, field.options.get('min'))) {
                messages.push('Below minimum value {min}')
            }
        }
        if (field.options.has('max')) {
            if (!satisfiesMaxOption(value, field.options.get('max'))) {
                messages.push('Exceeds maximum value {max}')
            }
        }
    }
    // if (['decimal'].includes(field.type) && field.options.has('decimals')) {
    //     if (!satisfiesDecimalsOption(value, field.options.get('decimals'))) {
    //         messages.push('Number of decimal places should be {decimals}')
    //     }
    // }
    if (['textlist', 'recordlist', 'imagelist', 'filelist'].includes(field.type)) {
        if (field.options.has('min_items')) {
            if (!satisfiesMinItemsOption(value, field.options.get('min_items'))) {
                messages.push('Below minimum items {min_items}')
            }
        }
        if (field.options.has('max_items')) {
            if (!satisfiesMaxItemsOption(value, field.options.get('max_items'))) {
                messages.push('Exceeds maximum items {max_items}')
            }
        }
    }
    if (['classlist'].includes(field.type)) {
        if (field.options.has('min_items')) {
            const min_items = field.options.get('min_items')
            if (min_items && value.length < parseInt(min_items, 10)) {
                messages.push('Below minimum items {min_items}')
            }
        }
        if (field.options.has('max_items')) {
            const max_items = field.options.get('max_items')
            if (max_items && value.length > parseInt(max_items, 10)) {
                messages.push('Exceeds maximum items {max_items}')
            }
        }
    }
    if (['textlist'].includes(field.type)) {
        if (field.options.has('allow_duplicates')) {
            if (
                !satisfiesAllowDuplicateItemsOption(
                    value,
                    field.options.get('allow_duplicates')
                )
            ) {
                messages.push('No duplicates allowed')
            }
        }
    }
    if (['text', 'textline'].includes(field.type)) {
        if (field.options.has('min_length')) {
            if (!satisfiesMinLengthOption(value, field.options.get('min_length'))) {
                messages.push('Below minimum length {min_length}')
            }
        }
        if (field.options.has('max_length')) {
            if (!satisfiesMaxLengthOption(value, field.options.get('max_length'))) {
                messages.push('Exceeds maximum length {max_length}')
            }
        }
    }
    if (['textline', 'textlistline'].includes(field.type)) {
        if (field.options.has('values')) {
            if (
                !satisfiesValuesOption(
                    value,
                    field.options.get('values'),
                    field.options.get('restrict_to_values'),
                    optional_language
                )
            ) {
                messages.push('Outside allowed values')
            }
        }
    }
    // Disable textlist per-line validation: the UI already checks per line in the
    // TextListInput component
    // if (['textlist'].includes(field.type)) {
    //     if (field.options.has('values')) {
    //         let satisfied = true
    //         const valuelist = value.split ? value.split('\n') : value
    //         valuelist.forEach(value => {
    //             if (
    //                 !satisfiesValuesOption(
    //                     value,
    //                     field.options.get('values'),
    //                     field.options.get('restrict_to_values'),
    //                     optional_language
    //                 )
    //             ) {
    //                 satisfied = false
    //             }
    //         })
    //         if (!satisfied) {
    //             messages.push('Outside allowed values')
    //         }
    //     }
    // }
    if (messages.length) {
        return new ValidationResult(
            VALIDATION.REPORT,
            messages.join('\n'),
            Object.fromEntries(field.options)
        )
    }
    return new ValidationResult(VALIDATION.SUCCESS)
}

const normalizeValueOption = (value, option) => {
    if (Array.isArray(value)) {
        value = value
            .map(line => line.trim())
            .filter(line => line.length > 0)
            .join('\n')
    }
    value = value === undefined || value === null ? '' : value.toString().trim()
    option = option === undefined || option === null ? '' : option.toString().trim()
    return [value, option]
}

// options

export const satisfiesMinOption = (value, option) => {
    ;[value, option] = normalizeValueOption(value, option)
    if (!option.length || !value.length) return true // always allow if option or value isn't set
    const floatValue = parseFloat(value)
    const floatOption = parseFloat(option)
    return floatValue >= floatOption
}

export const satisfiesMaxOption = (value, option) => {
    ;[value, option] = normalizeValueOption(value, option)
    if (!option.length || !value.length) return true // always allow if option or value isn't set
    const floatValue = parseFloat(value)
    const floatOption = parseFloat(option)
    return floatValue <= floatOption
}

export const satisfiesDecimalsOption = (value, option) => {
    ;[value, option] = normalizeValueOption(value, option)
    if (!option.length || !value.length) return true // always allow if option or value isn't set
    const splitvalue = value.split('.')
    const valueDecimals = splitvalue.length === 1 ? 0 : splitvalue.pop().length
    const intOption = parseInt(option, 10)
    return valueDecimals === intOption
}

export const satisfiesMinItemsOption = (value, option) => {
    ;[value, option] = normalizeValueOption(value, option)
    if (!option.length) return true // always allow if option isn't set
    const splitvalue = value.split('\n')
    const intOption = parseInt(option, 10)
    return splitvalue.length >= intOption
}

export const satisfiesMaxItemsOption = (value, option) => {
    ;[value, option] = normalizeValueOption(value, option)
    if (!option.length) return true // always allow if option isn't set
    const splitvalue = value.split('\n')
    const intOption = parseInt(option, 10)
    return splitvalue.length <= intOption
}

export const satisfiesAllowDuplicateItemsOption = (value, option) => {
    ;[value, option] = normalizeValueOption(value, option)
    if (!option.length) return true // always allow if option isn't set
    const boolOption = test_true(option)
    if (boolOption) return true // no checking for duplicates
    const splitvalue = value.split('\n')
    return new Set(splitvalue).size === splitvalue.length
}

export const satisfiesMinLengthOption = (value, option) => {
    ;[value, option] = normalizeValueOption(value, option)
    if (!option.length) return true // always allow if option isn't set
    const intOption = parseInt(option, 10)
    return value.length >= intOption
}

export const satisfiesMaxLengthOption = (value, option) => {
    ;[value, option] = normalizeValueOption(value, option)
    if (!option.length) return true // always allow if option isn't set
    const intOption = parseInt(option, 10)
    return value.length <= intOption
}

export const satisfiesValuesOption = (
    value,
    valuesoption,
    restricttovaluesoption,
    language
) => {
    value = value === undefined || value === null ? '' : value.toString().trim()
    const values =
        valuesoption === undefined || valuesoption === null
            ? []
            : listFromValues(valuesoption, language).map(optionvalue =>
                  optionvalue.trim().toLowerCase()
              )
    restricttovaluesoption =
        restricttovaluesoption === undefined || restricttovaluesoption === null
            ? false
            : test_true(restricttovaluesoption)
    if (!value.length || !values.length) return true // always allow if option or value isn't set
    if (!restricttovaluesoption) return true // always allow if no restriction
    return values.includes(value.toLowerCase())
}
